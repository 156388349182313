import React from "react";

function privacyPolicyText(cookiesPolicyLink) {
    return(
        <div><span>
    By reading this Privacy Policy, the user is informed about the way in which TIDI collects, processes and protects the personal data provided to it through the platform (hereinafter, the "
    <b>Plataform</b>").<br/><br/>
    Beforehand, the user is provided with a glossary of terms used throughout the Privacy Policy, such as the following:<br/>
<ul className='ulItem'>
    <li><b>Applicable law:</b> refers to the Federal Act on Data Protection (hereinafter DPA) and Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (hereinafter GDPR), as well as any other legislation on the protection of personal data that may be applicable.<br/><br/></li>
    <li><b>Personal data:</b> any information relating to an identified or identifiable natural person, in accordance with el artículo 4 RGPDthe applicable regulations.<br/><br/></li>
    <li><b>Data processor:</b> natural or legal person who processes personal data on behalf of the CONTROLLER. For the purposes of this contract, the SUPPLIER and the companies with which it must subcontract for the provision of the contracted services shall be considered the Processor.<br/><br/></li>
    <li><b>Data subject:</b> The identified or identifiable natural person who is the owner of the personal data. For the purposes of this contract, the data subject is considered to be the natural person provider and the contact persons of the legal person provider, as well as the end user-consumer.<br/><br/></li>
    <li><b>Platform:</b> This is the mixed technological CRM through which TIDI provides its services to third parties.<br/><br/></li>
    <li><b>Supplier-client:</b> This is the natural or legal person who receives the provision of the service provided by the Data Controller and, in turn, is constituted as a provider or seller of services to third parties.<br/><br/></li>
    <li><b>Controller:</b> the legal entity that determines the purposes and means of processing. For the purposes of this document, TIDI shall be considered as the CONTROLLER OF THE PROCESSING.<br/><br/></li>
    <li><b>Processing:</b> any operation or set of operations which is performed upon personal data or sets of personal data, whether or not by automatic means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.<br/><br/></li>
    <li><b>End-user-consumer:</b> This is the natural person who receives the service provided by the Data Controller and, in turn, becomes the purchaser of the goods and/or services from the provider or seller. <br/><br/></li>
</ul>
    In accordance with the applicable regulations, the data subject is hereby informed of the processing of his or her personal data.<br/><br/>

<br/><b>1. IDENTITY OF THE CONTROLLER</b><br/><br/>
    The Controller of the processing of your personal data is: <br/><br/>
    The TIDI Systems GmbH (hereinafter, the "<b>Controller</b>" or "<b>TIDI</b>").<br/>
    Registered office: Frohbühlstrasse 3, 8052 Zürich, Switzerland<br/>
    TFN: CHE-389.068.509<br/>
    E-mail: datos@tidisystems.com<br/><br/>

<br/><b>2. THE NEED TO PROCESS YOUR PERSONAL DATA</b><br/><br/>
    Registration and access to the Platform require the interested party to provide certain personal data, in addition to certain functionalities of the website that depend on the use of Cookies, as informed in the {cookiesPolicyLink}.<br/><br/>
    If you do not provide your personal data, you will not be able to create a user profile on the Platform and, therefore, you will not be able to access the Platform or any of its functionalities, or to contract or use the services offered by TIDI.<br/><br/>

<br/><b>3. PURPOSE AND LEGITIMACY OF THE PROCESSING OF PERSONAL DATA</b><br/><br/>
    TIDI will process your personal data in a lawful, fair and transparent manner in the context of your use of the Platform for the following purposes and in accordance with the following grounds of legitimacy:<br/>
<br/><b>3.1. If you are a supplier-customer or a TIDI supplier-customer's employee</b><br/><br/>
    If you use TIDI as a supplier-customer of TIDI or if you are an employee of a supplier-customer of TIDI (i.e. in the role of a seller of products and services), TIDI will process the following personal data:<br/>
        <ul className='ulItem'>
            <li>Name of representative or individual entrepreneur</li>
            <li>TIN/TFN</li>
            <li>E-mail address</li>
            <li>Telephone number</li>
            <li>Address, province, region and country </li>
        </ul>

        TIDI will also process the following personal data relating to the employee of the supplier who has been involved in the sales transaction in connection with which the corresponding receipt or ticket is generated:<br/>
        <ul className='ulItem'>
            <li>Treatment (Mr, Mrs)</li>
            <li>Name and surname</li>
            <li>Professional role (manager, salesperson, etc. )</li>
            <li>Corporate e-mail</li>
            <li>Other information, if any, that the supplier has included on his own initiative on the receipt or ticket.</li>
        </ul>

        Thus, the purposes of the processing of your personal data are:

        <ol className='ulItem' type="a">
            <li><b>Execute, carry out and manage the provision of our services</b> (ticketing and payment management).<br/><br/>
            Specifically, the purposes consist of the creation and management of your user profile, the management of the two-factor authentication system (required in the application), as well as the knowledge of the company, the employee and the shop responsible for the sale/exchange/return of the product for the generation of the ticket/invoice.<br/><br/>
            The legitimate basis for the processing of data for this purpose is therefore the <b>execution of the contractual relationship</b> that we have with you. The processing of your personal data is necessary to achieve the purposes described above, so that if you do not provide them, it will not be possible to carry out the corresponding contractual relationship.<br/><br/></li>

            <li><b>Produce statistical and market analyses to assist TIDI in its decision making.</b>.<br/><br/>
            Specifically, the purpose of the processing of personal data is to acquire knowledge of the use made of the Platform by users, to carry out consumer studies for internal decision-making purposes, and to publish or make these studies available to third parties in aggregate form.<br/><br/>
            The legitimate basis for processing data for this purpose is the <b>legitimate interest</b> of TIDI. In particular, TIDI's legitimate interest is to know our customers and, ultimately, to analyse and improve the quality of our services, taking into account the aggregation of the relevant information. This processing is suitable for TIDI to be able to adapt its operations and the products and services offered to users and therefore contributes to improving the quality of the services provided. In any case, at any time and completely free of charge, the user may object to this particular processing by sending an e-mail to datos@tidisystems.com.<br/><br/></li>

            <li>Carry out checks and controls for <b>fraud</b> detection and <b>prevention</b>.<br/><br/>
            In order to avoid anomalous practices that may cause economic and reputational damage to TIDI, data will be processed to prevent fraud in recruitment, in accordance with its legitimate interests in fraud prevention. This processing is necessary to avoid possible negative economic consequences and possible legal breaches resulting from the actions of customers. To this end, and in accordance with TIDI's internal policies, we analyse the activities carried out by the user, such as the pre-existence of irregular activities, (by consulting common files for the assessment of capital and creditworthiness or common files for the management and prevention of fraud). In order to avoid any type of damage or negative consequence for the user, appropriate technical and organisational measures have been adopted to reinforce the confidentiality and security of your information.<br/><br/></li>
        </ol>

<b>3.2. If you are an end-user-consumer</b><br/><br/>
    If you use TIDI as an end-user-consumer (i.e. in the role of purchaser or end-consumer of goods or services), TIDI will process the following categories of personal data:<br/>
        <ul className='ulItem'>
            <li>User name (nickname)</li>
            <li>E-mail address</li>
            <li>Encrypted password</li>
            <li>Gender</li>
            <li>Date of birth</li>
            <li>Postcode</li>
            <li>Telephone number</li>
            <li>Credit card for payments</li>
            <li>IP address </li>
        </ul>

        Thus, the purposes of the processing of your personal data are:

        <ol className='ulItem' type="a">
            <li><b>Execute, carry out and manage the provision of our</b> (payment ticket management) <b>services</b>.<br/><br/>
            Specifically, the purposes consist of creating and managing your user profile, generating the corresponding tickets and invoices and sending them by e-mail (or, where appropriate, by SMS), and managing the two-factor authentication system (required in the application).<br/><br/>
            The legitimate basis for the processing of data for this purpose is, therefore, the <b>execution of the contractual relationship</b> maintained with the user. The processing of your personal data is necessary to achieve the purposes described above, so if you do not provide them, it will not be possible to carry out the corresponding contractual relationship.<br/><br/></li>

            <li>On the other hand, the processing of some of the categories of data described (gender, date of birth and postcode) is for the purpose of <b>statistical and market analysis</b> by TIDI in order to gain knowledge of the use made of the Platform, to carry out consumer studies for internal decision-making, and to publish or make these studies available to third parties in aggregate form.<br/><br/>
            The legitimate basis for processing data for this purpose is the <b>legitimate interest</b> of TIDI. In particular, TIDI's legitimate interest is to know our customers and, ultimately, to analyse and improve the quality of our services, taking into account the aggregation of the relevant information. This processing is suitable for TIDI to be able to adapt its operations and the products and services offered to users and therefore contributes to improving the quality of the services provided. In any case, at any time and completely free of charge, you may object to this particular processing by sending an e-mail to datos@tidisystems.com. <br/><br/></li>
        </ol>

<b>4. RETENTION PERIOD OF YOUR PERSONAL DATA</b><br/><br/>
    The data will be kept for as long as your relationship with TIDI is maintained, i.e. for as long as your TIDI profile remains active. From the moment you decide to close or cancel your TIDI account definitively, your personal data will be kept, duly blocked, for the time necessary to deal with any liabilities that may arise, in accordance with the different retention periods established, in each case, in the applicable regulations.<br/><br/>

<br/><b>5. COMMUNICATION OF PERSONAL DATA AND INTERNATIONAL TRANSFERS</b><br/><br/>
    Personal data will be shared with the end user-consumer corresponding to each of the sales transactions in connection with which the ticket is generated. Otherwise, personal data will not be shared with other third parties unless this is necessary for the fulfilment of legal obligations.<br/><br/>
    Moreover, TIDI, in relation to users located within the European Economic Area (hereinafter the "EEA"), as a service provider located outside the EEA, is entitled to process your personal data in accordance with the European Commission's Adequacy Decision of 26 July 2000 concerning the adequate level of protection of personal data in Switzerland. <br/><br/>
    Furthermore, in the event that TIDI uses service providers (e.g. web hosting service providers) who may have access to your personal data in order to provide the service and who are located outside Switzerland and the EEA, TIDI guarantees that, prior to such processing, all necessary measures will be taken in order to ensure, at all times, the security and protection of your personal data, in accordance with the applicable regulations. <br/><br/>

<br/><b>6. EXERCISE OF RIGHTS</b><br/><br/>
    The data subject may send a letter to the e-mail address indicated in the header of this policy with the reference "Data Protection", at any time and free of charge, to:<br/>
        <ul className='ulItem'>
            <li>To revoke the consents granted, without prejudice to the lawfulness of the processing previously carried out.</li>
            <li><b>Access</b> your personal data. </li>
            <li><b>Rectify</b> inaccurate or incomplete data.</li>
            <li>Request the <b>deletion</b> of your data when, among other reasons, the data are no longer necessary for the purposes for which they were collected. </li>
            <li>Obtain from the Data Controller the <b>limitation of</b> the processing of the data when any of the conditions provided for in the regulations are met.</li>
            <li><b>To object</b> to the processing of their personal data, in accordance with the applicable regulations.</li>
            <li>Request the <b>portability </b> of your data. </li>
        </ul>
    Likewise, if the data subject considers that the Controller has infringed any of his or her rights in relation to the applicable data protection regulations, he or she may lodge a complaint with the competent supervisory authority.<br/><br/>

<br/><b>7. SECURITY MEASURES</b><br/><br/>
    TIDI will treat the data subject's data at all times in the strictest confidence, in accordance with the provisions of the applicable regulations.<br/><br/>
    To this end, TIDI will adopt the necessary technical and organisational measures to guarantee the security of your data and prevent its alteration, loss, unauthorised processing or access, taking into account the state of technology, the nature of the data stored and the risks to which they are exposed. <br/><br/>

<br/><b>8. RESPONSIBILITY OF THE DATA SUBJECT</b><br/><br/>
    The interested party:<br/>
        <ul className='ulItem'>
            <li>You guarantee that you are of legal age and that the data you provide to TIDI, both personal data and information that, where appropriate, refers to the company you represent or for which you work, are true, accurate, complete and up to date. To this effect, the interested party is responsible for the veracity of all the data provided and undertakes to keep all the information provided suitably updated, in such a way that it corresponds at all times to his or her real situation.</li>
            <li>You guarantee that, in the event that you provide information from third parties, you have informed them of the aspects contained in this document. You also guarantee that you have obtained their express authorisation to provide their data to TIDI for the aforementioned purposes.</li>
            <li>You will be liable for any false or inaccurate information that you provide through the Platform and for any direct or indirect damages that this may cause to TIDI or third parties.</li>
        </ul>
    <div className='AlignToRight'> Last update: September 2021. </div><br/><br/>

</span></div>)}

let privacyPolicyTitle = <span><b>PRIVACY POLICY</b></span>

export {privacyPolicyText, privacyPolicyTitle};