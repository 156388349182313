import React from "react";
import {Button, Form, Table} from 'react-bootstrap';
import Dropdown from "react-bootstrap/Dropdown";
import {Link, Redirect} from 'react-router-dom';
import "../functions/Session_cookies";
import "./LandingPage.css";
import {get_my_history} from "../functions/My_history";
import {Create_json_suboperation_QR} from "../functions/Suboperation_QR"
import {Create_json_sales} from "../functions/Sales_submit";
import {NewChange} from "../content/New_Changes";
import {NewReturn} from "../content/New_Returns";
import {get_my_profile} from "../functions/My_profile";
// import QR_code from '../pictures/QR_code.png';
import QR_code from '../pictures/Blue_QR.png';
import Renderizar from './LogIn';
import file from "../content/Profile_Attributes";
import "../common/common.css";
import {Resend_QR} from "../functions/Resend_QR";
import {
	clearCookiesOnLogOut,
	email_validation,
	getCookie,
	remove_profile_and_operations_from_localstorage,
	validate_float_or_empty,
	validate_integers,
	validate_positive_integers,
	validate_integers_or_null,
	validate_number, validate_number_non_empty_non_Null,
	validate_string_is_not_empty_or_null, email_validation_or_empty,
	wrapper_send_all_cookies_after_cookies_preferences_made, parseDateTimeFromPython, calculatorOfSinglePrice,
	calculatorOfSingleRowTotalsHistoricOperationsTable, calculateTaxesInSuboperation, reformat_datetime_to_US_format,
	validate_pure_float, getUserType, isUserACompany, isUserAPSI, isUserAnIndividual, format_email_address
} from "../common/utilities";
import {create_initial_JWT_api} from "../functions/JWT";
import {get_analytics_acceptance_cookie, set_analytics_acceptance_cookie} from "../functions/Permanent_cookies";
import {privacyPolicyText, privacyPolicyTitle} from "../texts/Privacy_Policy";
import {cookiesDetailText, cookiesDetailTitle} from "../texts/Cookies_details";
import {analyticsCookiesText, cookiesSummaryTitle, necessaryCookiesText} from "../texts/Cookies_summary";
import {legalNoticeText, legalNoticeTitle} from "../texts/Legal_notice";
import {licensesTitle, licensesIntroText, renderLicensesButtons} from "../texts/Licenses";
import {decideWhichGTCToDisplay} from "../texts/texts_utilities";
import {loadingPictureCode} from "../common/ajax-loader";
import {licenseMITText} from "../texts/License_MIT";
import {licenseBSD2ext} from "../texts/License_BSD_2_Clause";
import {licenseBSD3Text} from "../texts/License_BSD_3_Clause";
import {licenseHPNDText} from "../texts/License_HPND";
import {licenseISCText} from "../texts/License_ISC";
import {licensePostgreSQLText} from "../texts/License_PostgreSQL";
import {licensePythonSFText} from "../texts/License_Python_Software_Foundation";
import {licenseUnlicenseText} from "../texts/License_The_Unlicense";
import {licenseApache2Text} from "../texts/License_Apache2_0";
import {licenseZopePLText} from "../texts/License_Zope_Public_License";
import {analyticsCookiesHeader, analyticsCookiesTable, technicalCookiesTable} from "../texts/Cookies_tables";
import {disclaimerNewCompaniesInPSI} from "../texts/DisclaimersPSI";
import {PSI_Relations_request} from "../functions/PSI_relations";
import {get_my_relations} from "../functions/Retrieve_relations";
import {updateClientInOperation} from "../functions/Update_client_in_operation";


export class RenderMyArea extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Page: "History",
			PreparationStatus: "0",
			PrimaryStatus: "-2",
			SecondaryStatus: "0",
			TerciaryStatus: "2",
			History_data: [],
			Copy_history_data: [],
			Filters: [],
			Operations: [
				{
					id: 0, Suboperation_type: "1", Date: '27/02/2021 13:34', Employee: 'Pepe', Shop: 'La Feria',
					Changes: null, ID_Provider_Operation: "65", Returns: null, Sale: 'Paracetamol', Info: 'true',
					QR: 'true'
				},
				{
					id: 0, Suboperation_type: "1", Date: '27/02/2021 13:34', Employee: 'Pepe', Shop: 'La Feria',
					Changes: null, ID_Provider_Operation: "65", Returns: null, Sale: 'Paracetamol', Info: 'true',
					QR: 'true'
				},
				{
					id: 0, Suboperation_type: "1", Date: '27/02/2021 13:34', Employee: 'Pepe', Shop: 'La Feria',
					Changes: null, ID_Provider_Operation: "65", Returns: null, Sale: 'Paracetamol', Info: 'true',
					QR: 'true'
				}
			],
			PrimaryCode: {IsUserSignedIn: "-2", JWT: "-1", Load: "0", RenderHistorical: "1", DataPreparation: "2"},
			SecondaryCode: {None: "0", Sale: "11", Change: "12", Return: "13", NewCompany: "50", NewEmail: "51"},
			TerciaryCode: {None: "0", MissingData: true, Insufficient: "1", Sufficient: "2"},
			SaleHeaders: [{
				No: "0", Product: 0, Brand: 0, Type: 0, Subtype: 0, Reference: 0, Price: 0,
				'Price w/o VAT': 'PricewoVAT', 'VAT (%)': 0, Units: 0, Promotion: 0, 'Discount (%)': 0
			}],
			SaleContent: [{
				Change_index: "1", Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null,
				PricewoVAT: 'PricewoVAT', VAT: null, Units: null, Promotion: null, Discount: null
			}],
			ChangeHeaders: [{
				No: "0", Product: 0, Brand: 0, Type: 0, Subtype: 0, Reference: 0, Price: 0,
				'Price w/o VAT': 'PricewoVAT', 'VAT (%)': 0, Units: 0, Promotion: 0, 'Discount (%)': 0
			}],
			ChangeContent: [{
				No: 1, Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null,
				Price_without_VAT: null, VAT: null, Units: null, Promotion: null, Discount: null, UnitsChange: null, Reason: null
			}],
			ReturnHeaders: {Product: 0, Brand: 0},
			saleHeaders2IamCompany: {
				ID: "0", Sale: "0", Change: "0", Return: "0", Items: "0", Client: "0", Employee: "0", Shop: "0",
				Price: "0", Date: "0", 'Info': '0', QR: "0"
			},
			saleHeaders2IamClient: {
				ID: "0", Sale: "0", Change: "0", Return: "0", Items: "0", Provider: "0", Employee: "0", Shop: "0",
				Price: "0", Date: "0", 'Info': '0', QR: "0"
			},
			newCompaniesHeader: {
				'ID': null, 'Company name': null, 'Fiscal address': null, 'TFN': null, 'Email address': null,
				'Joining field': null
			},
			NoOfRows: 1,
			RoIndex: 0,
			NewSales: [{
				No: 1, Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null, PricewoVAT: null,
				VAT: null, Units: null, Promotion: null, Discount: null
			}],
			NewSale: [{
				No: 1, Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null, PricewoVAT: null,
				VAT: null, Units: null, Promotion: null, Discount: null
			}],
			NewCompanies: [{
				No: 1, CompanyName: null, FiscalAddress: null, CompanyTFN: null, CompanyEmail: null, JoiningField: null
			}],
			NewCompany: [{
				No: 1, CompanyName: null, FiscalAddress: null, CompanyTFN: null, CompanyEmail: null, JoiningField: null
			}],
			OrderSummaryHeaders: [{Product: "0", Units: 'Units.', Disc: 'Disc.', Total: 0}],
			OrderSummaryContent: [{Product: 'N/A', 'Units.': 'N/A', 'Disc.': 'N/A', Total: 'N/A'}],
			OrderTicketHeader: [{'Order_summary':'Order summary', 'Date': null}, {'Order_summary':'Order summary', 'Date': null}, {'Order_summary':'Order summary', 'Date': null}],
			ClientDataHeader: {'Client_data': 'Client data'},
			ShippingNewSale: null,
			FilterHeader: {Filters: 'Filters'},
			Client: [{Email_address: null}],
			New_ID_Provider_Operation: 0,
			ChooseChangesHeaders: [{No: 0, Units: 0, 'Reason for change': null}],
			ChooseReturnsHeaders: [{No: 0, Units: 0, 'Reason for return': null}],
			ChooseChangesContent: [{Choose_change_index: 1, Units: 0, Reason: null}],
			ChooseChangesFixed: [{Choose_change_index: 1, Units: 0, Reason: null}],
			Profile: [],
			Employee_ID: null,
			EmployeeChosen: ' - All employees - ',
			ShopChosen: ' - All shops - ',
			EmployeeChosenTable: ' - Choose an employee - ',
			ShopChosenTable: ' - Choose a shop - ',
			Shop_ID: null,
			ID_info: [],
			NoOfRows_Sale: 1,
			missingCrucialData: false,
			Operation_types: ['Sale', 'Change', 'Return'],
			Operation_types_codes: {Sale: "1", Change: "2", Return: "3"},
			OperationChosen: ' - All types - ',
			PopUpMoreInfo: null,
			PreRunOps: "1",
			WaitForDate: "0",
			showSalesQRCode: "0",
			showSalesQRCodeCode: {None: "0", Sale: "1", Change: "2", Return: "3", Historical: "10"},
			DropDownAttribute: null,
			OKRetrieveMyProfile: "1",
			SVG_PNG: null,
			ErrorMessage: null,
			OperationsErrors: {Sales: [{Product: true, Brand: false, Type: false, Subtype: false, Reference: true, Price: true,
					PricewoVAT: true, VAT: true, Units: true, Promotion: false, Discount: false}],
				ChooseChange: [{Units: 0, Reason: null, UnitsBiggerThanExpected: false}],
				Shipping: true,
				ClientEmail: true},
			HighLevelErrors: {
				Ship_ping: false,
				Client_Email: false,
				OldSaleID: false,
				Sales: {Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false},
				ChooseChange: {Units: true, Reason: true, UnitsBiggerThanExpected: false, CharactersInUnits: false, AllUnits: false},
				ClientConfirmsSendingByEmail_Email: false
			},
			Disabling: {Sales: [{Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false}]},
			PressedEndButton: false,
			IDInputIsDisabled: false,
			PressedEndButtonWithoutID: false,
			NewEmailAddressQR: '',
			whichLegalTextIsRequired: null,
			CookiesAccepted: {Technical: true, Analytics: get_analytics_acceptance_cookie()==='true'},
			CookiesDetailsRequired: false,
			URL_LogIn: "./LogIn",
			WaitingForRequest: false,
			ObscurerQRReceived: false,
			Props: this.props,
			licenseText: null,
			Non_existing_ID: false,
			Empty_ID: false,
			IDRequired: null,
			analyticsCookiesHeader: analyticsCookiesHeader,
			analyticsCookiesBody: analyticsCookiesTable,
			technicalCookiesBody: technicalCookiesTable,
			errorMessageOnButton: false,
			PSIProvidersTableHeader: {'ID': null, 'Accepted': null, 'Pending': null, 'Rejected': null, 'Eliminated': null,
				'Company': null, 'Fiscal address': null, 'Email address': null, 'Joining field': null, 'Invited on': null,
				'Last email on': null, 'On-boarded': null},
			ClientConfirmsSendingByEmailEmail: '',
			Operation_ID: null,
			DoesClientWantTheEmail: false,
			wishForUpdatingClientID: false,
			wishForResendQR: false,
			sendButtonPressed: null
		};
	}

	OKRetrieveMyProfile = () => {

		// let Profile_output = this.state.Profile_retrieved;
		let Profile_output = localStorage.getItem('Profile_retrieved');
		console.log(Profile_output);

		if (this.state.OKRetrieveMyProfile === "1") {
			this.setState(
				Object.assign(this.state.Profile, {Company: Profile_output.Company}), () =>
					this.setState({OKRetrieveMyProfile: "2"}));
		}

		// if (this.state.OKRetrieveMyProfile === "2") {
		//
		// 	console.log('holi');
		//
		// 	this.setState(
		// 		Object.assign(this.state.Profile, {Employees: Profile_output.Employees}), () =>
		// 			this.setState({OKRetrieveMyProfile: "3"}));
		//
		// }
		//
		// if (this.state.OKRetrieveMyProfile === "3") {
		//
		// 	console.log('adiosito');
		//
		// 	this.setState(
		// 		Object.assign(this.state.Profile, {Shops: Profile_output.Shops}), () =>
		// 			this.setState({OKRetrieveMyProfile: "4"}));
		// 	this.setState({PrimaryStatus : this.state.PrimaryCode.RenderHistorical});
		// }


		// console.log(Profile_output);

		// this.setState({prueba2: Profile_output.Shops}, this.setState({prueba1: Profile_output.Employees},
		// 	this.setState({prueba3: Profile_output.Company} )));

		// this.setState(
		// 	Object.assign(this.state.Profile, {Company: Profile_output.Company}), () =>
		// this.setState(
		// 	Object.assign(this.state.Profile, {Employees: Profile_output.Employees}), () =>
		// 	this.setState(
		// 		Object.assign(this.state.Profile, {Shops: Profile_output.Shops})
		// 	),
		// 	() => this.setState({PreRunOps: "3"}, () => this.changeStatusTo1()
		// )
		// ));





		// this.setState(
		// 	Object.assign(this.state.Profile, {Shops: Profile_output.Shops})
		// );
		//
		// this.setState(
		// 	Object.assign(this.state.Profile, {Company: Profile_output.Company})
		// );
		//
		// this.setState(
		// 	Object.assign(this.state.Profile, {Person: Profile_output.Person})
		// );
		// //console.log(Profile_output.Company);
		//
		// if (isUserACompany()) {
		// 	if (Profile_output.Company.TFN === undefined || Profile_output.Company.TFN === null
		// 		|| Profile_output.Company.TFN === "0") {
		// 		this.setState({missingCrucialData: true})
		// 		this.setState({
		// 			TerciaryStatus: this.state.TerciaryCode.MissingData
		// 		})
		// 	}
		// }
		// this.setState({PreRunOps: "2"});

		return null;
	}

	RetrieveMyProfile = async () => {
		// console.log(1);
		var retorno = '';

		let Profile = function () {
			return get_my_profile().then(json => {
				return json
			});
		}

		let profile1 = Profile();

		retorno = profile1.then((response) => {
				// console.log(response);
				if (response.contenido.Retrieve_my_profile_output.Status === "OK") {
					//console.log('Status OK');
					const Profile_output = response.contenido.Retrieve_my_profile_output.Profile_data;

					// console.log(Profile_output);



					localStorage.setItem('Profile_retrieved', JSON.stringify(Profile_output));
					localStorage.setItem('PUN', Profile_output.Update_number);


					// this.checkErrorMessageOnButton();


					// console.log('Saliendo de RetrieveMyProfile');
					retorno = Profile_output;

					return retorno;
				} else {
					console.log('Status NOK');

					this.setState({ErrorMessage: 'Retrieve_my_profile_1'});

					retorno = null;
					return retorno;
				}
			}
		)

		return await retorno;
	}

	RetrieveMyRelations = () => {
		// TODO: Martín - completar
		var retorno = '';

		let Relations = function () {
			return get_my_relations().then(json => {
				return json
			});
		}

		let relations = Relations();

		// retorno = relations.then((response) => {
		// 		// console.log(response);
		// 		if (response.contenido.Retrieve_my_history_output.Status === "OK") {
		// 			// console.log('hola');
		// 			//console.log('Status OK');
		// 			var Operations_output = response.contenido.Retrieve_my_history_output.Operations;
		//
		// 			localStorage.setItem('Operations_output', JSON.stringify(Operations_output));
		// 			// this.setState({History_data: Operations_output})
		// 			// retorno = Operations_output;
		//
		// 			// console.log('Saliendo de RetrieveMyHistory')
		//
		// 			return Operations_output;
		// 		} else {
		// 			console.log('Status NOK');
		//
		// 			this.setState({ErrorMessage: 'Retrieve_my_historic_operations_1'});
		//
		// 			retorno = null;
		// 			return retorno;
		// 		}
		// 	}
		// )
		//
		// await retorno;
		//
		// // console.log(esp)
		// // this.setState({PrimaryStatus:  this.state.PrimaryCode.DataPreparation});

		return 'Test';
	}

	RetrieveMyHistory = async (props) => {
		// console.log(2);

		var retorno = '';

		let History = function () {
			return get_my_history().then(json => {
				return json
			});
		}

		let userHistory = History();

		retorno = userHistory.then((response) => {
				// console.log(response);
				if (response.contenido.Retrieve_my_history_output.Status === "OK") {
					// console.log('hola');
					//console.log('Status OK');
					var Operations_output = response.contenido.Retrieve_my_history_output.Operations;

					localStorage.setItem('Operations_output', JSON.stringify(Operations_output));
					// this.setState({History_data: Operations_output})
					// retorno = Operations_output;

					// console.log('Saliendo de RetrieveMyHistory')

					return Operations_output;
				} else {
					console.log('Status NOK');

					this.setState({ErrorMessage: 'Retrieve_my_historic_operations_1'});

					retorno = null;
					return retorno;
				}
			}
		)

		await retorno;

		// console.log(esp)
		// this.setState({PrimaryStatus:  this.state.PrimaryCode.DataPreparation});

		return retorno;
	}


	errorWhenLoading = () => {

		if (this.state.ErrorMessage === 'Retrieve_my_historic_operations_1' || this.state.ErrorMessage === 'Retrieve_my_profile_1') {
			return (
				<div className='errorWhenLoading'>
					<div className='errorWhenLoadingText'> An error occurred. Please, contact support  </div>
				</div>
			)
		} else {
			return null;
		}
	}

	ReadEmployeesAndShops = (Operations) => {
		// console.log(Operations);

		var Employees = [];

		var Shops = [];
		for (let step = 0; step < Operations.length; step++) {
			let employeeName = Operations[step].Suboperations

			Operations[step].Suboperations.forEach(Suboperation => {
				var this_employee = null
				var this_shop = null
				if (Suboperation.Employee.Name !== null) {
					// console.log(Suboperation.Employee)
					this_employee = {Name: Suboperation.Employee.Name, Surname: Suboperation.Employee.Surname,
						Treatment: Suboperation.Employee.Surname, Email_address: format_email_address(Suboperation.Employee.Email), Role: Suboperation.Employee.Role}
					Employees.push(this_employee)
				}
				if (Suboperation.Shop.Name !== null) {
					this_shop = {Name: Suboperation.Shop.Name, Address: Suboperation.Shop.Address, Country: Suboperation.Shop.Country,
						Region: Suboperation.Shop.Region, Province: Suboperation.Shop.Province, Phone_number: Suboperation.Shop.Phone_number};
					Shops.push(this_shop)
				}

			})
		}
		// console.log(Employees)
		// console.log(Shops)

		// The following addition is only for ease of use.


		return [Employees, Shops];
	}

	OrganizeProviders = () => {
		// TODO Martin - completar
		this.prepareHeaderHistoricalDataTable()
		return null;
	}



	OrganizeOperation() {
		// console.log(3);

		var Operations = this.state.History_data;
		// console.log(Operations);

		var Operations_list = [];
		var Operation_id = 0;
		var couple = {Operations_list, Operation_id};

		Operations.forEach(Operation => {
				// console.log(Operation);
				var lista = this.GetSuboperationsForEachOperation(Operation, couple);
				lista[0].forEach(list => {
						couple.Operation_id = lista[1];

					}
				)
			}
		)
		// console.log(Operations_list);


		Operations_list = this.sortByDate(Operations_list);
		// console.log(Operations_list)

		this.setState({HistoricalOperationsArray: Operations_list},
			() => {this.setState({Copy_history_data: Operations_list},
				() => {this.GetLatestID_Provider_Operation(Operations)
				} )
			} );

		return Operations_list;

	}



	sortByDate = (operationsList) => {
		// console.log(operationsList)

		for (let step = 0; step < operationsList.length; step++) {
			operationsList[step]['DateInUSFormat'] = reformat_datetime_to_US_format(operationsList[step].Date);
		}

		function compare( a, b ) {
			if ( Date.parse(a['DateInUSFormat']) < Date.parse(b['DateInUSFormat']) ){
				return 1;
			}
			if (  Date.parse(a['DateInUSFormat']) > Date.parse(b['DateInUSFormat'])  ){
				return -1;
			}
			return 0;
		}

		let sorted = operationsList.sort(compare);
		// console.log(sorted)
		return sorted;
	}

	GetLatestID_Provider_Operation = (Operations) => {

		var array = [];

		Operations.forEach(Operation => {
			array.push(+Operation.ID_Provider_Operation);
		})

		let max = Math.max(...array);

		if (max === -10) {
			max = 0;
		}

		const new_value = +max + 1;

		this.setState({New_ID_Provider_Operation: new_value}, () => {this.prepareHeaderHistoricalDataTable()});

		return null;

	}

	GetSuboperationsForEachOperation(Operation, Input_list) {
		// console.log(Operation);

		var Suboperations = Operation.Suboperations;
		var ID_Provider_Operation = Operation.ID_Provider_Operation;
		var Latest_date = Operation.Latest_date;
		var QR = Operation.QR;
		// console.log(QR);


		var Operation_list_aux = Input_list.Operations_list;
		var Operation_id_aux = Input_list.Operation_id;

		// console.log(Operation);

		Suboperations.forEach(Suboperation => {

				var suboperation_dict = {};
				// console.log(Suboperation)


				Operation_id_aux += 1;
				suboperation_dict['Operation_id'] = Operation_id_aux;

				suboperation_dict['ID'] = ID_Provider_Operation;
				suboperation_dict['Operation_ID'] = Suboperation.Operation_ID;
				// console.log(suboperation_dict['ID']);
				suboperation_dict['Sale'] = null;
				suboperation_dict['Suboperation_type'] = Suboperation.Suboperation_type;
				suboperation_dict['Change'] = null;
				suboperation_dict['Return'] = null;
				suboperation_dict['Items'] = '';

				var Client = Suboperation.Client;
				var Provider = Suboperation.Provider;
				let employee_name = Suboperation.Employee.Name;
				if (employee_name === null) { employee_name = ''}
				let employee_surname = ' ' + Suboperation.Employee.Surname;
				if (Suboperation.Employee.Surname === null) { employee_surname = ''}

				let shop_name = Suboperation.Shop.Name;
				if (shop_name === null) { shop_name = ''}

				suboperation_dict['Employee'] = employee_name + employee_surname;
				suboperation_dict['Shop'] = shop_name;
				suboperation_dict['Price'] = null;
				suboperation_dict['Shipping'] = Suboperation.Shipping;
				// suboperation_dict['QR'] = QR;
				// console.log(QR);

				if (!isUserACompany()) {
					// The user is a client and he/she wants the providers, not the clients.
					suboperation_dict['Provider'] = Provider.Name;
					suboperation_dict['Provider_ID'] = Provider.ID;
					suboperation_dict['Counterparty'] = Provider.Name;
					this.setState({Counterparty: 'Provider'});
				} else {
					suboperation_dict['Client'] = Client.Email;
					suboperation_dict['Client_ID'] = Client.ID;
					suboperation_dict['Counterparty'] = Client.Email;
					this.setState({Counterparty: 'Client'});
				}

				var datetime = Suboperation.Date;
				var datetime2 = parseDateTimeFromPython(datetime);
				suboperation_dict['Date'] = datetime2;

				// Correct the ID in case the row is empty (the user has just created the account)
				if (suboperation_dict['ID'] === -10) {suboperation_dict['Date'] = '26/06/1996'}
				suboperation_dict['QR'] = QR;
				suboperation_dict['QR'] = QR;
				suboperation_dict['Info'] = {};

				suboperation_dict['Info']['Changes_info'] = Suboperation.Changes;
				suboperation_dict['Info']['Returns_info'] = Suboperation.Returns;
				suboperation_dict['Info']['Sales_info'] = Suboperation.Sales;


				// Iterate over Sales to get the items only --> These items will be rendered to the table directly (no extra information).
				var Items = '';
				var Price_positive = 0;
				var Price_negative = 0;
				var product = '';
				var units = null;


				let all_items = '';

				// console.log(ID_Provider_Operation);
				// console.log(Suboperation);
				let suboperation_currency = '';
				if (Suboperation.Suboperation_type === "1") {
					suboperation_dict['Sale'] = 'true';
					// console.log(Suboperation);


					suboperation_currency = Suboperation.Sales.Sale_data[0].Product_data.Currency;

					all_items = all_items + 'Sold: ';

					let lenghti = Suboperation.Sales.Sale_data.length;
					// console.log(lenghti);

					let n = 0;
					// Iterative over the Units to get the price and the ítems
					Suboperation.Sales.Sale_data.forEach(Sale_data_ind => {

						units = Sale_data_ind.Units;

						product = units + 'x' + Sale_data_ind.Product_data.Product;

						all_items = all_items + product;

						if (n !== +lenghti-1){
							all_items = all_items + ', ';
						}

						var amount = +calculatorOfSingleRowTotalsHistoricOperationsTable(Sale_data_ind.Product_data.Price,
							Sale_data_ind.Product_data.Price_without_VAT, Sale_data_ind.Product_data.VAT,
							units, Sale_data_ind.Discount);
						Price_positive += parseFloat(amount);
						n += 1;
					})
					// console.log(all_items);

					// const product_ = product.substring(0, product.length - 2);
					Items = all_items;


				} else {

					if (Suboperation.Suboperation_type === "2") {
						suboperation_dict['Change'] = 'true';

						suboperation_currency = Suboperation.Changes.Change_data[0].Product_data.Currency;

						all_items = all_items + 'Sold: ';

						let lenghti = Suboperation.Sales.Sale_data.length;
						// console.log(lenghti);

						let n = 0;
						// Iterative over the Units to get the price and the ítems SALE
						Suboperation.Sales.Sale_data.forEach(Sale_data_ind => {

							units = Sale_data_ind.Units;

							product = units + 'x' + Sale_data_ind.Product_data.Product;

							all_items = all_items + product;

							let lenghti = Suboperation.Sales.Sale_data.length;

							if (n !== +lenghti-1){
								all_items = all_items + ', ';
							}

							n += 1;

							var amountsale = +calculatorOfSingleRowTotalsHistoricOperationsTable(Sale_data_ind.Product_data.Price,
								Sale_data_ind.Product_data.Price_without_VAT, Sale_data_ind.Product_data.VAT,
								units, Sale_data_ind.Discount);
							Price_positive += parseFloat(amountsale);

						})

						lenghti = Suboperation.Changes.Change_data.length;
						// console.log(lenghti);

						n = 0;

						all_items = all_items + '; Returned: ';

						// Iterative over the Units to get the price and the ítems CHANGE
						Suboperation.Changes.Change_data.forEach(Change_data_ind => {

							units = Change_data_ind.Units;

							product = units + 'x' + Change_data_ind.Product_data.Product;

							all_items = all_items + product;

							let lenghti = Suboperation.Changes.Change_data.length;

							if (n !== +lenghti-1){
								all_items = all_items + ', ';
							}

							n += 1;
							// var amount = Change_data_ind.Product_data.Price;
							// if (amount === null) { amount = 0} else {
							// 	amount = Change_data_ind.Product_data.Price.replace(',', '.');}

							var amount = +calculatorOfSingleRowTotalsHistoricOperationsTable(Change_data_ind.Product_data.Price,
								Change_data_ind.Product_data.Price_without_VAT, Change_data_ind.Product_data.VAT,
								units, Change_data_ind.Discount);
							Price_negative -= parseFloat(amount);
						})

						// const product_ = product.substring(0, product.length - 2);
						Items = all_items;


					} else {
						//In this case, Suboperation.Sales === "3"
						suboperation_dict['Return'] = 'true';

						suboperation_currency = Suboperation.Returns.Return_data[0].Product_data.Currency;

						all_items = all_items + 'Returned: ';
						// console.log(lenghti);

						let n = 0;

						// Iterative over the Units to get the price and the ítems
						Suboperation.Returns.Return_data.forEach(Return_data_ind => {

							units = Return_data_ind.Units;

							product = units + 'x' + Return_data_ind.Product_data.Product;

							all_items = all_items + product;

							let lenghti = Suboperation.Returns.Return_data.length;

							if (n !== +lenghti-1){
								all_items = all_items + ', ';
							}

							n += 1;

							// var amount = Return_data_ind.Product_data.Price;
							// if (amount === null) { amount = 0} else {
							// 	amount = Return_data_ind.Product_data.Price.replace(',', '.');}

							var amount = +calculatorOfSingleRowTotalsHistoricOperationsTable(Return_data_ind.Product_data.Price,
								Return_data_ind.Product_data.Price_without_VAT, Return_data_ind.Product_data.VAT,
								units, Return_data_ind.Discount);

							// var amount = Return_data_ind.Product_data.Price.replace(',', '.');
							Price_negative -= parseFloat(amount);
						})

						// const product_ = product.substring(0, product.length - 2);
						Items = all_items;
						// Items = product_;
					}
				}

				if (suboperation_dict['ID'] === -10) {Price_positive = 0; Price_negative = 0}
				var price = parseFloat(Price_positive) + parseFloat(Price_negative) + +Suboperation.Shipping;

				suboperation_dict['Price'] = price.toFixed(2);
				suboperation_dict['Items'] = Items;
				suboperation_dict['Units'] = units;

				var datetime3 = parseDateTimeFromPython(Latest_date);
				suboperation_dict['Latest_date'] = datetime3;
				suboperation_dict['Currency'] = suboperation_currency;

				// Correct the ID in case the row is empty (the user has just created the account)
				if (suboperation_dict['ID'] === -10) {suboperation_dict['ID'] = '0'}

				Operation_list_aux.push(suboperation_dict);
			}
		)
		return [Operation_list_aux, Operation_id_aux];
	}

	clickOnOperationTypeDropdown = (event, field) => {
		// console.log(event.currentTarget);

		// console.log(field);


		// Close the dropdown in case it's opened
		if (this.state.DropDownAttribute !== null) {
			this.setState({DropDownAttribute: null});
		}

		const value = event.currentTarget.getAttribute("Value");

		if (field === 'Operation') {

			var operation_type_code = 0;
			if (value === 'Sale') {
				operation_type_code = (this.state.Operation_types_codes.Sale);
			}
			if (value === 'Change') {
				operation_type_code = (this.state.Operation_types_codes.Change);
			}
			if (value === 'Return') {
				operation_type_code = (this.state.Operation_types_codes.Return);
			}

			// console.log(operation_type_code);
			this.setState({OperationChosen: value});
			this.setOperation(operation_type_code);
		}
		if (field === 'ShopTable') {
			this.setState({ShopChosenTable: value});
			const shop_id = event.currentTarget.getAttribute("Shop_ID");
			const shop_name = event.currentTarget.getAttribute("Shop_name");
			this.setState({Shop_ID: shop_id});
			this.setState({ShopChosenTable: shop_name});
		}
		if (field === 'EmployeeTable') {
			const employee_id = event.currentTarget.getAttribute("Employee_ID");
			const employee_name = event.currentTarget.getAttribute("Employee_name");
			this.setState({Employee_ID: employee_id});
			this.setState({EmployeeChosenTable: employee_name});
		}
		if (field === 'Shop') {
			this.setState({ShopChosen: event.currentTarget.getAttribute("Shop_name")});
			this.setState({Shop_to_filter: event.currentTarget.getAttribute("Shop_name")});
		}
		if (field === 'Employee') {
			this.setState({EmployeeChosen: event.currentTarget.getAttribute("Employee_name")});
			this.setState({Employee_to_filter: event.currentTarget.getAttribute("Employee_name")});
		}

		// console.log(this.state.Employee_ID);

		return null;
	}


	filterHistoricalData = () => {
		let inputsArrow = 'dropDownInMyAreaFilterTable';


		let buttonFilterEmployees =
			<div>
				<div className='filterFieldName'>Employee</div>
				<Button className='filterFieldFieldButton' block size="lg"
						onClick={(e) =>
							this.activateDropDown('Employee')}
				>
					<div className='renderFilterEmployee'>{this.state.EmployeeChosen}</div>
					<img className={inputsArrow} alt=""/>
				</Button>
				{this.DropDownAttribute('Employee', null, null, null, true)}
			</div>


		let buttonFilterShops =
			<div>
				<div className='filterFieldName'>Shop</div>
				<Button className='filterFieldFieldButton' block size="lg"
						onClick={(e) =>
							this.activateDropDown('Shop')}
				> <div className='renderFilterEmployee' >{this.state.ShopChosen}</div>
					<img className={inputsArrow} alt=""/>
				</Button>
				{this.DropDownAttribute('Shop', null, null, null , true)}
			</div>


		return (
			<div className='filterHistoricalData'>
				<table className='renderFiltersHeader'>
					<tr>{this.renderFiltersHeader()}</tr>
				</table>

				{this.displayErrorMessageIDInFilters()}

				<div className='filterFieldName'>ID</div>
				<input className="filterFieldField"
					   type="text"
					   value={this.state.ID_to_filter}
					   onChange={(e) => this.setID(e.target.value)}
				/>

				<div className='filterFieldName'>Operation type</div>
				<Button className='filterFieldFieldButton' block size="lg"
						onClick={(e) =>
							this.activateDropDown('OperationType')}
				> <div className='renderFilterOperationType' >{this.state.OperationChosen}</div>
					<img className={inputsArrow} alt=""/>
				</Button>
				{this.DropDownAttribute('OperationChosen', null, null, null )}


				<div className='filterFieldName'>Items</div>
				<input className="filterFieldField"
					   type="text"
					   value={this.state.Items_to_filter}
					   onChange={(e) => this.setItems(e.target.value)}
				/>

				<div className='filterFieldName'>{this.state.Counterparty}</div>
				<input className="filterFieldField"
					   type="text"
					   value={this.state.Counterparty_to_filter}
					   onChange={(e) => this.setCounterparty(e.target.value)}
				/>

				{buttonFilterEmployees}

				{buttonFilterShops}


				<div className='filterFieldName'>Date</div>
				<input className="filterFieldFieldDate"
					   type="Date"
					   value={this.state.Date_to_filter_to_show}
					   onChange={(e) => this.setDate(e.target.value)}
				/>

				<div className='ButtonsFilter'>
					<Button className="Clear_button" block size="lg" onClick={this.clearFiltersOnHistoricalTable}>
						{'Clear'}
					</Button>

					<Button className="Filter_button" block size="lg"
							onClick={(e) => this.checkFiltersCanBeApplied()}>
						{'Filter'}
					</Button>
				</div>

				{this.messageFiltersCannotBeApplied()}

			</div>
		)
	}

	displayErrorMessageIDInFilters = () => {
		if (this.state.ErrorMessage === 'WrongIDToFilter') {
			return (
				<div className='displayErrorMessageIDInFilters'> Only digits</div>
			)
		}
		else { return null; }
	}

	DropDownAttribute = (attribute, profile, table, row_index, filterTable) => {
		// console.log(row_index);
		// console.log(this.state.Profile)

		var Attributes = [];
		var field = null;

		if (attribute === 'OperationChosen' && this.state.DropDownAttribute === 'OperationType') {
			Attributes = file.OperationsChosen;
			field = 'Operation';
		}
		if (attribute === 'Shop' && this.state.DropDownAttribute === 'Shop') {
			Attributes = this.renderDropDownShops();
			field = 'Shop';
			let shop_name = 'Provide your shops data on My account tab.'
			if (!isUserACompany()) {
				shop_name = "You don't have any shops name"
			}
			if (Attributes.length === 0) {Attributes = [{'Shop_name': shop_name, 'Shop_ID': null}]}
		}
		if (attribute === 'Employee' && this.state.DropDownAttribute === 'Employee') {
			Attributes = this.renderDropDownEmployees();
			field = 'Employee';
			let employee_name = 'Provide your employees data on My account tab.'
			if (!isUserACompany()) {
				employee_name = "You don't have any employees name"
			}
			if (Attributes.length === 0) {Attributes = [{'Employee_name': employee_name, 'Employee_ID': null}]}
		}
		if (attribute === 'ShopTable' && this.state.DropDownAttribute === 'ShopTable') {
			Attributes = this.renderDropDownShops();
			field = 'ShopTable';
			if (Attributes.length === 0) {Attributes = [{'Shop_name': 'Provide your shops data on My account tab.', 'Shop_ID': null}]}
		}
		if (attribute === 'EmployeeTable' && this.state.DropDownAttribute === 'EmployeeTable') {
			Attributes = this.renderDropDownEmployees();
			field = 'EmployeeTable';
			if (Attributes.length === 0) {Attributes = [{'Employee_name': 'Provide your employees data on My account tab.', 'Employee_ID': null}]}
		}
		// console.log(field);



		var array2 = [];

		Attributes.forEach(Attribute => {
			let attribute_to_show = null;
			if (attribute === 'OperationChosen' && this.state.DropDownAttribute === 'OperationType') {
				attribute_to_show = Attribute
			}
			if (attribute === 'Shop' && this.state.DropDownAttribute === 'Shop') {
				attribute_to_show = Attribute.Shop_name
				console.log(attribute_to_show)
			}
			if (attribute === 'Employee' && this.state.DropDownAttribute === 'Employee') {
				attribute_to_show = Attribute.Employee_name
				console.log(attribute_to_show)
			}
			if (attribute === 'ShopTable' && this.state.DropDownAttribute === 'ShopTable') {
				attribute_to_show = Attribute.Shop_name
				console.log(attribute_to_show)
			}
			if (attribute === 'EmployeeTable' && this.state.DropDownAttribute === 'EmployeeTable') {
				attribute_to_show = Attribute.Employee_name;
				console.log(attribute_to_show)
			}
			array2.push(
				<div className=''>
					<Dropdown.Item eventKey="1" className='dropDownOperations' Value={Attribute} Shop_name={Attribute.Shop_name}
								   Shop_ID={Attribute.Shop_ID} Employee_name={Attribute.Employee_name} Employee_ID={Attribute.Employee_ID}
								   disabled={this.mustDropdownBeDisabled(attribute)}
								   onClick={(e) => this.clickOnOperationTypeDropdown(e, field)}> {attribute_to_show} </Dropdown.Item>
				</div>
			)
		})

		if (field === 'Shop') {
			let div = 'containerDropDownShops';
			return (<div className={div}>{array2} </div>);
		}
		if (field === 'Employee') {
			let div = 'containerDropDownShops';
			return (<div className={div}>{array2} </div>);
		}
		if (field === 'ShopTable') {
			let div = 'containerDropDownShopsFilterTAble'
			return (<div className={div}>{array2} </div>);
		}
		if (field === 'EmployeeTable') {
			let div = 'containerDropDownShopsFilterTAble';
			// if (Attributes === []) {array2 = 'Provide your employee data on My account tab.'}
			return (<div className={div}>{array2} </div>);
		}
		if (field === 'Operation') {
			return (<div className='dropdownOperationChosen'>{array2} </div>);
		}
		else {
			return null;
		}
	}

	mustDropdownBeDisabled = (field) => {
		console.log(this.renderDropDownEmployees().length);

		if (field === 'Shop') {return this.renderDropDownShops().length === 0;}
		if (field === 'Employee') {return this.renderDropDownEmployees().length === 0;}
		if (field === 'ShopTable') {return this.renderDropDownShops().length === 0;}
		if (field === 'EmployeeTable') {return this.renderDropDownEmployees().length === 0;}

		return false;
	}

	messageFiltersCannotBeApplied = () => {

		if (this.state.ErrorMessage === 'WrongIDToFilter' && this.state.FilterButtonClicked === true) {
			return <div className='messageFiltersCannotBeApplied'> Check the errors above </div>;
			// return null;
		} else {return null;}
	}

	checkFiltersCanBeApplied = () => {

		this.setState({FilterButtonClicked: true});

		if (this.state.ErrorMessage !== 'WrongIDToFilter') {
			this.applyFiltersOnHistoricalTable();

		} else {return null;}
	}

	applyFiltersOnHistoricalTable = () => {
		// Filter by ID
		let partial_table = this.applyIDFilter();
		let id_filtered = partial_table();
		//console.log(id_filtered);

		let partial_table_2 = this.applyFilterSubstringItemsOnHistorical(id_filtered);
		let items_filtered = partial_table_2();
		//console.log(items_filtered);

		let partial_table_3 = this.applyFilterSubstringClientOnHistorical(items_filtered);
		let client_filtered = partial_table_3();
		//console.log(client_filtered);

		let partial_table_4 = this.applyFilterSubstringEmployeeOnHistorical(client_filtered);
		let employee_filtered = partial_table_4();
		// console.log(employee_filtered);

		let partial_table_5 = this.applyFilterSubstringShopOnHistorical(employee_filtered);
		let shop_filtered = partial_table_5();
		// console.log(shop_filtered);

		let partial_table_6 = this.applyFilterSubstringDateOnHistorical(shop_filtered);
		let date_filtered = partial_table_6();
		// console.log(date_filtered);

		let partial_table_7 = this.applyFilterOperationOnHistorical(date_filtered);
		let operation_filtered = partial_table_7();
		// console.log(operation_filtered);

		this.setState({HistoricalOperationsArray: operation_filtered});


		return null;
	}

	clearFiltersOnHistoricalTable = () => {

		this.setState({FilterButtonClicked: false});
		// console.log(this.state.Copy_history_data);
		this.clearFilters();
		this.setState({HistoricalOperationsArray: this.state.Copy_history_data})

		let op = file.OperationsChosen;
		this.setState({OperationChosen: op[0]});
		// var frm = document.getElementById('filterForm');
		// frm.reset();
		return null;
	}

	setID(event) {

		console.log(event);
		// Check if there's any error on the ID introduced
		if (validate_integers_or_null(event) === false) {
			this.setState({ErrorMessage: 'WrongIDToFilter'});
		} else {
			this.setState({FilterButtonClicked: false});
			this.setState({ErrorMessage: null});
		}

		this.setState({ID_to_filter: event});
		return null;

	}

	setOperation(parameter) {
		this.setState({Operation_to_filter: parameter})
		return null;
	}

	setItems(event) {
		this.setState({Items_to_filter: event})
		return null;
	}

	setCounterparty(event) {
		this.setState({Counterparty_to_filter: event})
		return null;
	}

	setEmployee(event) {
		this.setState({Employee_to_filter: event})
		return null;
	}

	setShop(event) {
		this.setState({Shop_to_filter: event})
		return null;
	}

	setDate(event) {
		console.log(event);
		var new_date = event.substring(8, 10) + '/' + event.substring(5, 7) + '/' + event.substring(0, 4);
		this.setState({Date_to_filter: new_date})
		this.setState({Date_to_filter_to_show: event})
		return null;
	}

	clearFilters = () => {

		this.setState({ID_to_filter: ''});
		this.setState({Operation_to_filter: 0});
		this.setState({OperationChosen: ' - All types - '});
		this.setState({Items_to_filter: ''});
		this.setState({Counterparty_to_filter: ''});
		this.setState({EmployeeChosen: ' - All employees - '});
		this.setState({ShopChosen: ' - All shops - '});
		this.setState({Employee_to_filter: ''});
		this.setState({Shop_to_filter: ''});
		this.setState({Date_to_filter: ''});
		this.setState({Date_to_filter_to_show: ''});

		return null;
	}

	applyIDFilter = () => {
		const ID_to_filter = this.state.ID_to_filter;
		var sub_table = [];
		let retorno = () => {
			if (ID_to_filter === undefined || ID_to_filter === '') {
				this.setState({
					HistoricalOperationsArray: this.state.Copy_history_data
				});
				return this.state.Copy_history_data;
			} else {
				var Operations = this.state.Copy_history_data;
				// console.log(Operations);
				Operations.forEach(Operation => {
					//console.log(Operation);
					if (Operation.ID === +ID_to_filter) {
						// console.log(Operation)

						var partial_table = Operations.filter(function (elem) {
							//Return the sub-table that fulfils the condition written below:
							return (elem.ID === +ID_to_filter)
						});

						this.setState({
							HistoricalOperationsArray: partial_table
						});
						sub_table = partial_table;
						return partial_table;
					}
					return null;
				})
				return sub_table;
			}
		}
		return retorno;
	}


	applyFilterSubstringItemsOnHistorical = (sub_table) => {
		const Items_to_filter = this.state.Items_to_filter;
		// console.log(Items_to_filter);
		var sub_table_2 = [];
		let retorno = () => {
			if (Items_to_filter === undefined || Items_to_filter === '' || Items_to_filter === null) {
				this.setState({
					HistoricalOperationsArray: this.state.Copy_history_data
				});
				return sub_table;
			} else {
				var Operations = sub_table;
				//console.log(Operations);
				Operations.forEach(Operation => {
					//console.log(Operation);
					if (Operation.Items.includes(Items_to_filter)) {
						//console.log(Operation);
						var partial_table = Operations.filter(function (elem) {

							//Return the sub-table that fulfils the condition written below:
							return (elem.Items.includes(Items_to_filter))
						});

						//console.log(partial_table);
						sub_table_2 = partial_table;
						return partial_table;
					}
					return null;
				})
				return sub_table_2;
			}

		}
		return retorno;
	}

	applyFilterSubstringClientOnHistorical = (sub_table_2) => {
		//console.log(sub_table_2);
		const Counterparty_to_filter = this.state.Counterparty_to_filter;
		var sub_table_3 = [];
		let retorno = () => {
			if (Counterparty_to_filter === undefined || Counterparty_to_filter === '') {
				//this.setState({
				//	HistoricalOperationsArray: this.state.Copy_history_data
				//});
				return sub_table_2;
			} else {
				var Operations = sub_table_2;
				console.log(Operations);
				Operations.forEach(Operation => {
					//console.log(Operation);
					if (Operation.Counterparty.includes(Counterparty_to_filter)) {
						//console.log(Operation);
						var partial_table = Operations.filter(function (elem) {

							//Return the sub-table that fulfils the condition written below:
							return (elem.Counterparty.includes(Counterparty_to_filter))
						});

						//console.log(partial_table);
						sub_table_3 = partial_table;
						return partial_table;
					}
					return null;
				})
				return sub_table_3;
			}

		}
		return retorno;
	}

	applyFilterSubstringEmployeeOnHistorical = (sub_table_3) => {
		const Employee_to_filter = this.state.Employee_to_filter;
		var sub_table_4 = [];
		let retorno = () => {
			if (Employee_to_filter === undefined || Employee_to_filter === '') {
				// this.setState({
				// 	HistoricalOperationsArray: this.state.Copy_history_data
				// });
				return sub_table_3;
			} else {
				var Operations = sub_table_3;
				// console.log(Operations);
				Operations.forEach(Operation => {
					// console.log(Operation);
					// console.log(Operation.Employee.includes(Employee_to_filter));
					if (Operation.Employee.includes(Employee_to_filter)) {
						// console.log(Operation);
						var partial_table = Operations.filter(function (elem) {
							// console.log(elem.Employee.includes(Employee_to_filter));
							//Return the sub-table that fulfils the condition written below:
							return (elem.Employee.includes(Employee_to_filter))
						});

						// console.log(partial_table);
						sub_table_4 = partial_table;
						return partial_table;
					}
					return null;
				})
				return sub_table_4;
			}

		}
		return retorno;
	}

	applyFilterSubstringShopOnHistorical = (sub_table_4) => {
		const Shop_to_filter = this.state.Shop_to_filter;
		console.log(Shop_to_filter);
		var sub_table_5 = [];
		let retorno = () => {
			if (Shop_to_filter === undefined || Shop_to_filter === '') {
				// this.setState({
				// 	HistoricalOperationsArray: this.state.Copy_history_data
				// });
				return sub_table_4;
			} else {
				var Operations = sub_table_4;
				console.log(Operations);
				Operations.forEach(Operation => {
					console.log(Operation);
					if (Operation.Shop.includes(Shop_to_filter)) {
						//console.log(Operation);
						var partial_table = Operations.filter(function (elem) {

							//Return the sub-table that fulfils the condition written below:
							return (elem.Shop.includes(Shop_to_filter))
						});

						//console.log(partial_table);
						sub_table_5 = partial_table;
						return partial_table;
					}
					return null;
				})
				return sub_table_5;
			}

		}
		return retorno;
	}

	applyFilterSubstringDateOnHistorical = (sub_table_5) => {
		const Date_to_filter = this.state.Date_to_filter;
		var sub_table_6 = [];
		let retorno = () => {
			if (Date_to_filter === undefined || Date_to_filter === '') {
				// this.setState({
				// 	HistoricalOperationsArray: this.state.Copy_history_data
				// });
				return sub_table_5;
			} else {
				var Operations = sub_table_5;
				// console.log(Operations);
				Operations.forEach(Operation => {
					// console.log(Operation);
					if (Operation.Date.includes(Date_to_filter)) {
						// console.log(Operation);
						var partial_table = Operations.filter(function (elem) {

							//Return the sub-table that fulfils the condition written below:
							return (elem.Date.includes(Date_to_filter))
						});

						// console.log(partial_table);
						sub_table_6 = partial_table;
						return partial_table;
					}
					return null;
				})
				return sub_table_6;
			}

		}
		return retorno;
	}

	applyFilterOperationOnHistorical = (sub_table_6) => {
		//console.log(this.state);
		const Operation_to_filter = this.state.Operation_to_filter;
		// console.log(Operation_to_filter);

		var sub_table_7 = [];
		let retorno = () => {
			if (Operation_to_filter === undefined || Operation_to_filter === '' || Operation_to_filter === 0) {
				// this.setState({
				// 	HistoricalOperationsArray: this.state.Copy_history_data
				// });
				return sub_table_6;
			} else {
				var Operations = sub_table_6;
				// console.log(Operations);
				Operations.forEach(Operation => {
					// console.log(Operation);
					if (Operation.Suboperation_type === Operation_to_filter) {

						var partial_table = Operations.filter(function (elem) {
							//Return the sub-table that fulfils the condition written below:
							return (elem.Suboperation_type === Operation_to_filter)
						});

						this.setState({
							HistoricalOperationsArray: partial_table
						});
						sub_table_7 = partial_table;
						return partial_table;
					}
					return null;
				})
				return sub_table_7;
			}
		}
		return retorno;
	}

	prepareHeaderHistoricalDataTable = () => {
		// Prepare the header of the historical table

		// var sub_dictionary = (({ ID, Sale, Change, Return, Items, Client, Employee, Shop, Price, Date, QR }) => ({ ID, Sale, Change, Return, Items, Client, Employee, Shop, Price, Date, QR }))(data);
		const sub_dictionary1 = this.state.saleHeaders2IamCompany;
		const sub_dictionary2 = this.state.saleHeaders2IamClient;

		var newHeader = null;

		if (isUserACompany()) {

			//In this case, the user is a company

			// First substitute the Price by the Price (CCY). This will be on the historical table's header.
			newHeader = this.renameThePriceWithCurrencyInHistoricalTableHeader(sub_dictionary1);

			this.setState({HistoricalTableHeader: newHeader}, () => {this.changeStatusTo1()});

		}
		if (isUserAPSI()){

			newHeader = this.state.PSIProvidersTableHeader;

			this.setState({HistoricalTableHeader: newHeader}, () => {this.changeStatusTo1()});

		}
		if (isUserAnIndividual()) {
			//In this case, the user is a client
			//console.log('Soy un cliente');
			newHeader = this.renameThePriceWithCurrencyInHistoricalTableHeader(sub_dictionary2);

			this.setState({HistoricalTableHeader: newHeader}, () => {this.changeStatusTo1()});

		}

		return null;
	}

	renameThePriceWithCurrencyInHistoricalTableHeader = (header) => {


		if (isUserACompany()) {
			let ccy = this.state.Profile.Company.Currency;
			if (ccy !== null) {
				ccy = ' (' + ccy + ')';
			} else {
				ccy = '';
			}
			const newPrice = 'Price' + ccy;

			const newHeader = {};
			// delete Object.assign(newHeader, header, {[newPrice]: header['Price'] })['Price'];

			newHeader['ID'] = header['ID'];
			newHeader['Sale'] = header['Sale'];
			newHeader['Change'] = header['Change'];
			newHeader['Return'] = header['Return'];
			newHeader['Items'] = header['Items'];
			newHeader['Client'] = header['Client'];
			newHeader['Employee'] = header['Employee'];
			newHeader['Shop'] = header['Shop'];
			newHeader[newPrice] = header['Price'];
			newHeader['Date'] = header['Date'];
			newHeader['Info'] = header['Info'];
			newHeader['QR'] = header['QR'];

			return newHeader;

		} else {
			// TODO: Martín - We are supposing that there is only one currency common to all operations!!

			// Take the currency from the correct suboperations
			let sales_info = this.state.HistoricalOperationsArray[0].Info.Sales_info;
			let changes_info = this.state.HistoricalOperationsArray[0].Info.Changes_info;
			let returns_info = this.state.HistoricalOperationsArray[0].Info.Returns_info;

			let ccy = null;
			if (sales_info !== null && sales_info !== undefined) {
				ccy = this.state.HistoricalOperationsArray[0].Info.Sales_info.Sale_data[0].Product_data.Currency;
			}
			if (changes_info !== null && changes_info !== undefined) {
				ccy = this.state.HistoricalOperationsArray[0].Info.Changes_info.Change_data[0].Product_data.Currency;
			}
			if (returns_info !== null && returns_info !== undefined) {
				ccy = this.state.HistoricalOperationsArray[0].Info.Returns_info.Return_data[0].Product_data.Currency;
			}

			// Currently taking the currency always from the first operation.
			// console.log(this.state.HistoricalOperationsArray[0].Info.Sales_info.Sale_data[0].Product_data.Currency);
			// let ccy = 0;
			if (ccy !== null && ccy !== '' && ccy !== ' ') {
				ccy = ' (' + ccy + ')';
			} else {
				ccy = '';
			}
			const newPrice = 'Price' + ccy;
			// const newPrice = 'Price';

			const newHeader = {};
			// delete Object.assign(newHeader, header, {[newPrice]: header['Price'] })['Price'];

			newHeader['ID'] = header['ID'];
			newHeader['Sale'] = header['Sale'];
			newHeader['Change'] = header['Change'];
			newHeader['Return'] = header['Return'];
			newHeader['Items'] = header['Items'];
			newHeader['Provider'] = header['Provider'];
			newHeader['Employee'] = header['Employee'];
			newHeader['Shop'] = header['Shop'];
			newHeader[newPrice] = header['Price'];
			newHeader['Date'] = header['Date'];
			newHeader['Info'] = header['Info'];
			newHeader['QR'] = header['QR'];

			return newHeader;
		}
	}


	renderHistoricalTableHeader = () => {

		if (!isUserAPSI()) {

			var first_line = this.state.HistoricalTableHeader;
			//console.log(first_line);

			if (first_line === undefined) {
				return null;
			} else {

				let header = Object.keys(first_line)
				return header.map((key, index) => {
					return <th key={index}>{key}</th>
				})
			}
		} else {

			var first_line = this.state.HistoricalTableHeader;
			//console.log(first_line);

			if (first_line === undefined) {
				return null;
			} else {

				let header = Object.keys(first_line)
				return header.map((key, index) => {
					return <th key={index}>{key}</th>
				})
			}
		}
	}

	renderFiltersHeader = () => {

		var first_line = this.state.FilterHeader;

		if (first_line === undefined) {
			return null;
		} else {
			let header = Object.keys(first_line)
			return header.map((key, index) => {
				return <th key={index}>{key}</th>
			})
		}
	}


	buttonMoreInformationFromHistoricalTable = (parameter, Operation_id) => {
		return (
			<Button className="MoreInfoButton" block size="lg"
					onClick={() => this.buttonMoreInformationClicked(parameter, Operation_id)}>
				{'More'}
			</Button>
		)
	}

	buttonMoreInformationClicked = (parameter, Operation_id) => {
		// console.log(parameter);
		// console.log(Operation_id);
		if (parameter === 'Sale') {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Sale});
		}
		if (parameter === 'Change') {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Change});
		}
		if (parameter === 'Return') {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Return});
		}
		this.setState({PopUpMoreInfo: Operation_id});
		this.setState({PopUpMoreInfoOperation: parameter});
		return null;
	}


	renderHistoricalTableData() {

		if (!isUserAPSI()) {
			// console.log(this.state.HistoricalOperationsArray)
			return this.state.HistoricalOperationsArray.map((Operation, index) => {
					const {
						Operation_id, ID, Sale, Change, Return, Items, Counterparty, Employee, Shop, Price, Date,
						Currency, Operation_ID
					} = Operation //destructuring
					// let newPrice = Price + '(' + Currency + ')';
					// console.log(Currency)
					const newPrice = Price;

					if (Sale === 'true') {
						// ccy = Relevant_Operation.Info.Sales_info.Sale_data[0].Product_data.Currency;
						return (
							<tr key={Operation_id} placeholder='Hola'>
								<td>{ID}</td>
								<td>
									<div className='Flag_sale'></div>
								</td>
								<td>{Change}</td>
								<td>{Return}</td>
								<td title={Items}>{Items}</td>
								<td title={Counterparty}>{Counterparty}</td>
								<td title={Employee}>{Employee}</td>
								<td title={Shop}>{Shop}</td>
								<td title={newPrice}>{newPrice}</td>
								<td title={Date}>{Date}</td>
								<td>{this.buttonMoreInformationFromHistoricalTable('Sale', Operation_id)}</td>
								<td>{this.showQRCodeOfHistoricOperation(index, ID, Operation_ID)}</td>
							</tr>
						)
					} else {
						if (Change === 'true') {
							// if (Relevant_Operation.Suboperation_type === "2") {
							// 	ccy = Relevant_Operation.Info.Changes_info.Change_data[0].Product_data.Currency;
							// }
							return (
								<tr key={Operation_id}>
									<td>{ID}</td>
									<td>{Sale}</td>
									<td>
										<div className='Flag_change'></div>
									</td>
									<td>{Return}</td>
									<td title={Items}>{Items}</td>
									<td title={Counterparty}>{Counterparty}</td>
									<td title={Employee}>{Employee}</td>
									<td title={Shop}>{Shop}</td>
									<td title={newPrice}>{newPrice}</td>
									<td title={Date}>{Date}</td>
									<td>{this.buttonMoreInformationFromHistoricalTable('Change', Operation_id)}</td>
									<td>{this.showQRCodeOfHistoricOperation(index, ID)}</td>
								</tr>
							)
						} else {
							// if (Relevant_Operation.Suboperation_type === "3") {
							// 	ccy = Relevant_Operation.Info.Returns_info.Return_data[0].Product_data.Currency;
							// }
							return (
								<tr key={Operation_id}>
									<td>{ID}</td>
									<td>{Sale}</td>
									<td>{Change}</td>
									<td>
										<div className='Flag_return'></div>
									</td>
									<td title={Items}>{Items}</td>
									<td title={Counterparty}>{Counterparty}</td>
									<td title={Employee}>{Employee}</td>
									<td title={Shop}>{Shop}</td>
									<td title={newPrice}>{newPrice}</td>
									<td title={Date}>{Date}</td>
									<td>{this.buttonMoreInformationFromHistoricalTable('Return', Operation_id)}</td>
									<td>{this.showQRCodeOfHistoricOperation(index, ID)}</td>
								</tr>
							)
						}
					}

				}
			)
		} else {
			return null;
		}
	}

	showQRCodeOfHistoricOperation = (index, ID_Provider_Operation, Operation_ID) => {


		// return this.state.HistoricalOperationsArray.map((Operation, index) => {
		// 	const {
		// 		Operation_id, ID, Sale, Change, Return, Items, Counterparty, Employee, Shop, Price, Date
		// 	} = Operation //destructuring

		return (
			// <div>
			// 	<img className='showQRCodeimage' src={QR_code} />
			<Button className="showQRCodeOfHistoricOperation" block size="lg" src={QR_code}
					onClick={() => this.retrieveQRFromSuboperation(index, ID_Provider_Operation, Operation_ID)}>
				<img className='showQRCodeimage' src={QR_code}  alt="" />
			</Button>
			// </div>
		)
	}

	retrieveQRFromSuboperation = (index, ID_Provider_Operation, Operation_ID) => {
		// This function ...

		let suboperation = this.state.HistoricalOperationsArray[index];
		console.log(suboperation);
		console.log(this.state)
		console.log(this.state.QRs_Operation_ID)
		console.log(Operation_ID)

		let ID_Provider_operation = suboperation.ID;

		let Provider_ID = null;
		if (suboperation.Provider_ID !== undefined ) {
			Provider_ID = suboperation.Provider_ID;
		}

		let Client_ID = null;
		if (suboperation.Client_ID !== undefined ) {
			Client_ID = suboperation.Client_ID;
		}

		let Sale_ID = null;
		if (suboperation.Info.Sales_info !== null ) {
			Sale_ID = suboperation.Info.Sales_info.ID;
		}

		let Change_ID = null;
		if (suboperation.Info.Changes_info !== null ) {
			Change_ID = suboperation.Info.Changes_info.ID;
		}

		let Return_ID = null;
		if (suboperation.Info.Returns_info !== null ) {
			Return_ID = suboperation.Info.Returns_info.ID;
		}


		// Render the loading icon
		this.setState({WaitingForRequest: true});

		// Store the ID_Provider_Operation in State - this attribute resets itself every time the user clicks on the
		// QR button - no need for resetting it after finishing the corresponding request.
		this.setState({Requested_ID_Provider_Operation: ID_Provider_operation});
		console.log(ID_Provider_operation)

		let Profile = function () {
			return Create_json_suboperation_QR(ID_Provider_operation, Sale_ID, Change_ID, Return_ID, Client_ID, Provider_ID).then(json => {
				return json
			});
		}

		let profile1 = Profile()

		profile1.then((response) => {
			if (response.contenido.Suboperations_QR_output.Status === "OK") {
				//console.log('Status OK');

				console.log(response.contenido.Suboperations_QR_output)
				this.setState({NewEmailAddressQR: format_email_address(response.contenido.Suboperations_QR_output.Client_email)});

				this.showQRCodeOfHistoricOperationButton(response.contenido.Suboperations_QR_output.QR_PNG,
					response.contenido.Suboperations_QR_output.Operation_ID);


				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				return null;
			} else {
				console.log('Status NOK');

				if (response.contenido.Suboperations_QR_output.Error_code === 'Suboperations_QR_1') {
					this.setState({ErrorMessage: 'Suboperations_QR_1'}, () => {this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.Historical})});
				}

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				return null;
			}
		})

		return null;

	}


	showQRCodeOfHistoricOperationButton = (qr_png, Operation_ID) => {

		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.Historical}, () => {this.setState({
			QR_Code_from_suboperation: qr_png })} )

		this.setState({QRs_Operation_ID: Operation_ID});

		return null;
	}


	showQRCode = () => {

		let qr_code = this.state.QR_Code_from_suboperation;

		let png_src = "data:image/png;base64," + qr_code;

		if (isUserACompany()) {
			return (
				<div className='showQRCode'>
					<img className='showQRCodeimage2' src={png_src} alt=""/>
				</div>
			)
		} else {
			return (
				<div className='showQRCode'>
					<img className='showQRCodeimage3' src={png_src} alt=""/>
				</div>
			)
		}
	}


	renderSaleTableHeader = () => {
		var first_line = this.state.SaleHeaders[0];
		// console.log(first_line);

		first_line = this.renameThePriceWithCurrencyInSaleTableHeader(first_line);

		if (first_line === undefined) {
			return null;
		} else {
			let header = Object.keys(first_line)

			return header.map((key, index__) => {
				return <th key={index__}>{key}</th>
			})
		}
	}

	renderNewCompaniesTableHeader = () => {
		var first_line = this.state.newCompaniesHeader;
		// console.log(first_line);

		if (first_line === undefined) {
			return null;
		} else {
			let header = Object.keys(first_line)

			return header.map((key, index__) => {
				return <th key={index__}>{key}</th>
			})
		}
	}

	renameThePriceWithCurrencyInSaleTableHeader = (header) => {
		var ccy = null;
		if (isUserACompany()) {
			ccy = this.state.Profile.Company.Currency;
		} else {
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			ccy = Suboperation_data.Info.Sales_info.Sale_data[0].Product_data.Currency;
		}

		// Eliminate the currency header in case it's missing
		let newPrice = 'Price (' + ccy + ')';

		if (ccy === null) {
			newPrice = 'Price';
		}
		let newPriceWoVat = 'Price w/o VAT (' + ccy + ')';
		if (ccy === null) {
			newPriceWoVat = 'Price w/o VAT';
		}

		const newHeader = {};

		newHeader['No'] = header['No'];
		newHeader['Product'] = header['Product'];
		newHeader['Brand'] = header['Brand'];
		newHeader['Type'] = header['Type'];
		newHeader['Subtype'] = header['Subtype'];
		newHeader['Reference'] = header['Reference'];
		newHeader[newPrice] = header['Price'];
		newHeader[newPriceWoVat] = header['Price w/o VAT'];
		newHeader['VAT (%)'] = header['VAT'];
		newHeader['Units'] = header['Units'];
		newHeader['Promotion'] = header['Promotion'];
		newHeader['Discount (%)'] = header['Discount (%)'];

		return newHeader;
	}

	decisionMakingHowManyRowsForNewCompanies = () => {

		const NoOfRows = this.state.NoOfRows;

		var array = [];
		for (let step = 0; step < NoOfRows; step++) {
			//console.log(step);
			array.push(this.renderNewCompaniesTableData(step));
		}
		//console.log(array);
		return array;
	}


	renderNewCompaniesTableData = (row_index) => {

		// if (this.state.PopUpMoreInfo !== null) {
		// 	// console.log(this.state.PopUpMoreInfo);
		// 	const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
		//
		// 	//console.log(Suboperation_data.Info.Sales_info.Sale_data);
		// 	return Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {
		//
		// 		const {Product_data, Units, Promotion, Discount} = row;
		//
		// 		return (
		// 			<tr>
		// 				<td>{index+1}</td>
		// 				<td title={Product_data.Product}> {Product_data.Product} </td>
		// 				<td title={Product_data.Brand}> {Product_data.Brand} </td>
		// 				<td title={Product_data.Product_Type}> {Product_data.Product_Type} </td>
		// 				<td title={Product_data.Product_Subtype}> {Product_data.Product_Subtype} </td>
		// 				<td title={Product_data.Sellers_ID_Reference}> {Product_data.Sellers_ID_Reference} </td>
		// 				<td title={Product_data.Price}> {Product_data.Price} </td>
		// 				<td title={Product_data.Price_without_VAT}> {Product_data.Price_without_VAT} </td>
		// 				<td title={Product_data.VAT}> {Product_data.VAT} </td>
		// 				<td title={Product_data.Units}> {Units} </td>
		// 				<td title={Product_data.Promotion}> {Promotion} </td>
		// 				<td title={Product_data.Discount}> {Discount} </td>
		// 			</tr>
		// 		)
		// 	})
		// } else {
			const new_company_index = +row_index + 1;

			return (
				<tr key={new_company_index} data-rowid={new_company_index} onClick={this.clickOnTableRow}
					onChange={(e) => this.changeOnTableRow(e)}>
					<td>{new_company_index}</td>
					<td>
						{this.inputNewCompaniesTablefunction('CompanyName', new_company_index)}
					</td>
					<td>
						{this.inputNewCompaniesTablefunction('FiscalAddress', new_company_index)}
					</td>
					<td>
						{this.inputNewCompaniesTablefunction('CompanyTFN', new_company_index)}
					</td>
					<td>
						{this.inputNewCompaniesTablefunction('CompanyEmail', new_company_index)}
					</td>
					<td>
						{this.inputNewCompaniesTablefunction('JoiningField', new_company_index)}
					</td>
				</tr>
			)
		// }
	}


	decisionMakingHowManyRowsForSale = () => {
		const NoOfRows = this.state.NoOfRows;

		var array = [];
		for (let step = 0; step < NoOfRows; step++) {
			//console.log(step);
			array.push(this.renderSaleTableData(step));
		}
		//console.log(array);
		return array;
	}

	getSuboperationFromOperation_ID = (row_index) => {

		const Ops = this.state.HistoricalOperationsArray;

		var resultado = null;
		Ops.forEach(holi => {
			if (holi.Operation_id === row_index) {
				resultado = holi;
				return null;
			}
		})

		return resultado;
	}

	renderSaleTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {
			// console.log(this.state.PopUpMoreInfo);
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			//console.log(Suboperation_data.Info.Sales_info.Sale_data);
			return Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {

				const {Product_data, Units, Promotion, Discount} = row;

				return (
					<tr>
						<td>{index+1}</td>
						<td title={Product_data.Product}> {Product_data.Product} </td>
						<td title={Product_data.Brand}> {Product_data.Brand} </td>
						<td title={Product_data.Product_Type}> {Product_data.Product_Type} </td>
						<td title={Product_data.Product_Subtype}> {Product_data.Product_Subtype} </td>
						<td title={Product_data.Sellers_ID_Reference}> {Product_data.Sellers_ID_Reference} </td>
						<td title={Product_data.Price}> {Product_data.Price} </td>
						<td title={Product_data.Price_without_VAT}> {Product_data.Price_without_VAT} </td>
						<td title={Product_data.VAT}> {Product_data.VAT} </td>
						<td title={Product_data.Units}> {Units} </td>
						<td title={Product_data.Promotion}> {Promotion} </td>
						<td title={Product_data.Discount}> {Discount} </td>
					</tr>
				)
			})
		} else {
			const sale_index = +row_index + 1;
			return this.state.SaleHeaders.map((header, index) => {

					return (
						<tr key={sale_index} data-rowid={sale_index} onClick={this.clickOnTableRow}
							onChange={(e) => this.changeOnTableRow(e)}>
							<td>{sale_index}</td>
							<td>
								{this.inputSaleTablefunction('Product', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('Brand', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('Type', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('Subtype', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('Reference', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('Price', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('PricewoVAT', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('VAT', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('Units', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('Promotion', sale_index)}
							</td>
							<td>
								{this.inputSaleTablefunction('Discount', sale_index)}
							</td>
						</tr>
					)
				}
			)
		}
	}

	clickOnTableRow = (event) => {
		const id = event.currentTarget.getAttribute("data-rowid");
		this.setState({RoIndex: id});
		return id;
	}

	changeOnTableRow = (event) => {

		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}


	inputNewCompaniesTablefunction = (field_name, company_index) => {

		let classN = 'inputSaleTable';
		// let field_name = field.data.valueOf();
		// let index = +field.indice.valueOf() -1;
		let index = +company_index -1;


		// Check if the input field must be red (due to an error in the input introduced).
		// if (this.state.PressedEndButton === true &&
		// 	(index+1 !== this.state.NewCompanies.length || this.state.NewCompanies.length === 1) ) {
		// 	// After pressing the Button, either:
		// 	// a) This row is not the final one
		// 	// b) It is the final but the table has only one row
		//
		// 	// console.log(this.state.OperationsErrors.Sales);
		//
		// 	if (this.state.OperationsErrors.Sales[index][field_name] === true) {
		// 		classN = 'inputSaleTable2';
		// 	}
		// 	if (this.state.OperationsErrors.Sales[index]['Reference'] === true && field_name === 'Reference') {
		// 		classN = 'inputSaleTable2';
		// 	}
		// 	//If the error appears on Price field, both Price and PriceWOVAT must be in red. This means an additional check for PriceWOVAT
		// 	if (this.state.OperationsErrors.Sales[index]['Price'] === true && field_name === 'PricewoVAT') {
		// 		classN = 'inputSaleTable2';
		// 	}
		// 	if (this.state.OperationsErrors.Sales[index]['PricewoVAT'] === true && field_name === 'Price') {
		// 		classN = 'inputSaleTable2';
		// 	}
		// }

		return (
			<input
				type="text"
				className={classN}
				// disabled = {this.disableInputField(+index, field_name)}
				onChange={(e) => this.handleNewCompaniesItem(e.target.value, field_name, index)}
			/>
		)
	}


	inputSaleTablefunction = (field_name, sale_index) => {

		let classN = 'inputSaleTable';
		// let field_name = field.data.valueOf();
		// let index = +field.indice.valueOf() -1;
		let index = +sale_index -1;

		// if (index === 0) {
		// 	// console.log(field_name);
		// 	// console.log(this.state.OperationsErrors.Sales[index]['Price'])
		// 	// console.log(this.state.OperationsErrors.Sales[index].Price)
		// 	// console.log(field_name === 'PricewoVAT');
		// 	// console.log(this.state.OperationsErrors.Sales[index]['Price'] === true);
		// }

		// Check if the input field must be red (due to an error in the input introduced).
		if (this.state.PressedEndButton === true &&
			(index+1 !== this.state.NewSales.length || this.state.NewSales.length === 1) ) {
			// After pressing the Button, either:
			// a) This row is not the final one
			// b) It is the final but the table has only one row

			// console.log(this.state.OperationsErrors.Sales);

			if (this.state.OperationsErrors.Sales[index][field_name] === true) {
				classN = 'inputSaleTable2';
			}
			if (this.state.OperationsErrors.Sales[index]['Reference'] === true && field_name === 'Reference') {
				classN = 'inputSaleTable2';
			}
			//If the error appears on Price field, both Price and PriceWOVAT must be in red. This means an additional check for PriceWOVAT
			if (this.state.OperationsErrors.Sales[index]['Price'] === true && field_name === 'PricewoVAT') {
				classN = 'inputSaleTable2';
			}
			if (this.state.OperationsErrors.Sales[index]['PricewoVAT'] === true && field_name === 'Price') {
				classN = 'inputSaleTable2';
			}
		}

		return (
			<input
				type="text"
				className={classN}
				disabled = {this.disableInputField(+index, field_name)}
				onChange={(e) => this.handleSaleItem(e.target.value, field_name, index)}
			/>
		)
	}


	disableInputField = (index, field_name) => {
		// Disables a price field in case it's required.
		// console.log(this.state.Disabling);
		return this.state.Disabling.Sales[index][field_name]
	}


	checkIsRowTotallyEmpty = (index) => {

		let checkProduct = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Product);
		let checkBrand = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Brand);
		let checkType = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Type);
		let checkSubtype = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Subtype);
		let checkReference = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Reference);
		let checkPrice = this.state.Disabling.Sales[index].Price;
		let checkPricewoVAT = this.state.Disabling.Sales[index].PricewoVAT;
		let checkPricesWrapper = checkPrice || checkPricewoVAT;
		let checkVAT = !validate_string_is_not_empty_or_null(this.state.NewSales[index].VAT);
		let checkUnits = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Units);
		let checkPromotion = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Promotion);
		let checkDiscount = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Discount);

		// console.log(checkProduct)
		// console.log(checkBrand)
		// console.log(checkPricesWrapper)
		// console.log(
		// 	checkProduct && checkBrand && checkType && checkSubtype && checkReference && checkVAT && checkUnits &&
		// 	checkPromotion && checkDiscount && !checkPricesWrapper
		// )

		return checkProduct && checkBrand && checkType && checkSubtype && checkReference && checkVAT && checkUnits &&
			checkPromotion && checkDiscount && !checkPricesWrapper;

	}


	evaluateSalesTableBasedOnState = (index, field) => {

		let check_empty_row = this.checkIsRowTotallyEmpty(index);
		console.log(validate_string_is_not_empty_or_null(this.state.NewSales[index].Product))
		console.log(check_empty_row);

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {Product: false});
		} else {
			if (validate_string_is_not_empty_or_null(this.state.NewSales[index].Product)) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Product: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Product: true});
			}
		}

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {Reference: false});
		} else {
			if (validate_string_is_not_empty_or_null(this.state.NewSales[index].Reference)) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Reference: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Reference: true});
			}
		}

		if (check_empty_row && index !== 0) {
			console.log('soy mudo')
			Object.assign(this.state.OperationsErrors['Sales'][index], {Price: false});
			// Also assign to PricewoVAT that it's written, so that the red input field does not come into action
			Object.assign(this.state.OperationsErrors['Sales'][index], {PricewoVAT: false});
		} else {

			if ((validate_number_non_empty_non_Null(this.state.NewSales[index].Price) || validate_number_non_empty_non_Null(this.state.NewSales[index].PricewoVAT))
			) {
				// console.log('soy bobo')
				Object.assign(this.state.OperationsErrors['Sales'][index], {Price: false});
				// Also assign to PricewoVAT that it's written, so that the red input field does not come into action
				Object.assign(this.state.OperationsErrors['Sales'][index], {PricewoVAT: false});
			} else {
				// console.log('soy true')
				Object.assign(this.state.OperationsErrors['Sales'][index], {Price: true});
				Object.assign(this.state.OperationsErrors['Sales'][index], {PricewoVAT: true});
			}
		}

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {VAT: false});
		} else {
			if (validate_number(this.state.NewSales[index].VAT) && this.state.NewSales[index].VAT !== null) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {VAT: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {VAT: true});
			}
		}

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {Units: false});
		} else {
			if (validate_positive_integers(this.state.NewSales[index].Units)) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Units: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Units: true});
			}
		}

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {Discount: false});
		} else {
			if (validate_float_or_empty(this.state.NewSales[index].Discount)) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Discount: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Discount: true});
			}
		}



		return null;


	}


	evaluateInputInSales = (field, index, table, input) => {

		// let check_empty_row = this.checkIsRowTotallyEmpty(index);
		// console.log(check_empty_row);

		if (field === 'Shipping') {
			// console.log(input);

			if (validate_number(input)) {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: false }), () => this.validateSaleRequestInput()
				);

				// Object.assign(this.state.OperationsErrors, {[field]: false});
			} else {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: true }), () => this.validateSaleRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: true});
			}
		}

		if (field === 'ClientEmail') {
			if (email_validation(input)) {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: false }), () => this.validateSaleRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: false});
			} else {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: true }), () => this.validateSaleRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: true});
			}
		}


		if (table !== null) {
			this.evaluateSalesTableBasedOnState(index, field);
			let table_name = table.valueOf();
			let field_name = field.valueOf();

			if (field_name === 'Product') {
				// console.log(validate_string_is_not_empty_or_null(input))
				// console.log(check_empty_row)
				// if (validate_string_is_not_empty_or_null(input) && !check_empty_row) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'Reference') {
				// if (validate_string_is_not_empty_or_null(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'Price') {
				// if (validate_number(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// 	// Also assign to PricewoVAT that it's written, so that the red input field does not come into action
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {PricewoVAT: false});
				// } else {
				// 	// if
				// 	// (this.state.OperationsErrors[table_name][index]['PricewoVAT'] === true ||
				// 	// 	(
				// 	// 		(input === ''
				// 			//|| validate_characters(input)) && this.state.NewSales[index]['PricewoVAT'] === '')
				// 	// ) {
				// 		Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// 		Object.assign(this.state.OperationsErrors[table_name][index], {PricewoVAT: true});
				// 	// } else {
				// 	// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// 	// 	Object.assign(this.state.OperationsErrors[table_name][index], {PricewoVAT: false});
				// 	// }
				// }
			}
			if (field_name === 'PricewoVAT') {
				// if (validate_characters(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
				// if (validate_number(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// 	// Also assign to Price that it's written, so that the red input field does not come into action
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {Price: false});
				// } else {
				// 	// if (
				// 	// 	//this.state.OperationsErrors[table_name][index]['Price'] === true ||
				// 	// 	input === ''// && this.state.NewSales[index]['Price'] === '')
				// 	// ) {
				// 		Object.assign(this.state.OperationsErrors[table_name][index], {Price: true});
				// 		Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// 	// } else {
				// 	// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// 	// 	Object.assign(this.state.OperationsErrors[table_name][index], {PricewoVAT: false});
				// 	// }
				// }
			}
			if (field_name === 'VAT') {
				// if (validate_number(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'Units') {
				// if (validate_integers(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'Discount') {
				// console.log(this.state.OperationsErrors);
				// if (validate_float_or_empty(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}


		}

		// this.state.ErrorMessage;
		// console.log(this.state.OperationsErrors);
		return null;
	}


	handleNewCompaniesItem = (e, field, indice) => {
		const Row_JS_index = +indice;

		//console.log(this.state.NewCompanies[Row_JS_index]);

		// this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);

		if (field === 'CompanyName') {
			console.log(e);
			this.setState(
				Object.assign(this.state.NewCompanies[Row_JS_index], {CompanyName: e})
				// , () => {
				// 	this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				// 	this.validateSaleRequestInput(Row_JS_index)
				// }
			);
		}

		if (field === 'FiscalAddress') {
			this.setState(
				Object.assign(this.state.NewCompanies[Row_JS_index], {FiscalAddress: e})
				// , () => {
				// 	this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				// 	this.validateSaleRequestInput(Row_JS_index)
				// }
			);
		}
		if (field === 'CompanyTFN') {
			this.setState(
				Object.assign(this.state.NewCompanies[Row_JS_index], {CompanyTFN: e})
				// , () => {
				// 	this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				// 	this.validateSaleRequestInput(Row_JS_index)
				// }
			);
		}
		if (field === 'CompanyEmail') {
			this.setState(
				Object.assign(this.state.NewCompanies[Row_JS_index], {CompanyEmail: format_email_address(e) })
				// , () => {
				// 	this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				// 	this.validateSaleRequestInput(Row_JS_index)
				// }
			);
		}
		if (field === 'JoiningField') {
			console.log(e)
			this.setState(
				Object.assign(this.state.NewCompanies[Row_JS_index], {JoiningField: e})
				// , () => {
				// 	this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				// 	this.validateSaleRequestInput(Row_JS_index)
				// }
			);
		}
		//console.log(this.state.NewCompanies);

		//Check if a new row should be added to the table
		this.addOneMoreRowToNewCompaniesTable();
		return null;
	}


	handleSaleItem = (e, field, indice) => {

		// const Row_JS_index = +indice - 1;
		const Row_JS_index = +indice;

		//console.log(this.state.NewSales[Row_JS_index]);

		// this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);

		if (field === 'Product') {
			console.log(e);
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Product: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);

		}

		if (field === 'Brand') {
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Brand: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			//console.log(this.state.NewSales);
		}
		if (field === 'Type') {
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Type: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			//console.log(this.state.NewSales);
		}
		if (field === 'Subtype') {
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Subtype: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			//console.log(this.state.NewSales);
		}
		if (field === 'Reference') {
			console.log(e)
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Reference: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			//console.log(this.state.NewSales);
		}
		if (field === 'Price') {
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Price: e}), () => {
					this.setState({LastChanged: 'Price'});
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			// Disable PricewoVAT in this row
			if (e !== '') {
				this.setState(
					Object.assign(this.state.Disabling.Sales[Row_JS_index], {PricewoVAT: true})
				);
			} else {
				this.setState(
					Object.assign(this.state.Disabling.Sales[Row_JS_index], {PricewoVAT: false})
				);
			}
			//console.log(this.state.NewSales);
		}
		if (field === 'PricewoVAT') {
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {PricewoVAT: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			// Disable PricewoVAT in this row
			if (e !== '') {
				this.setState(
					Object.assign(this.state.Disabling.Sales[Row_JS_index], {Price: true})
				);
			} else {
				this.setState(
					Object.assign(this.state.Disabling.Sales[Row_JS_index], {Price: false})
				);
			}
			//console.log(this.state.NewSales);
		}
		if (field === 'VAT') {
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {VAT: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			//console.log(this.state.NewSales);
		}
		if (field === 'Units') {
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Units: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			//console.log(this.state.NewSales);
		}
		if (field === 'Promotion') {
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Promotion: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			//console.log(this.state.NewSales);
		}
		if (field === 'Discount') {
			//console.log(this.state.NewSales);
			this.setState(
				Object.assign(this.state.NewSales[Row_JS_index], {Discount: e}), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);
			//console.log(this.state.NewSales);
		}

		//Check if a new row should be added to the table
		this.addOneMoreRowToSalesTable();
		return null;
	}


	addOneMoreRowToSalesTable = () => {

		const Row_index = this.state.RoIndex;

		const NoOfRows = this.state.NoOfRows;

		if (+NoOfRows === +Row_index) {
			// If this is the last row on the table and something has been written, add a new row to the table and to the state.
			var current_rows = this.state.NewSales;
			const new_row = this.state.NewSale[0];
			const clone = Object.assign({}, new_row);
			current_rows.push(clone);
			this.setState(
				Object.assign(this.state.NewSales[+Row_index], {No: +Row_index + 1})
			);

			this.setState({NoOfRows: +NoOfRows + 1});

			// Add one more row to the Errors array:
			const new_sale_error = {Product: true, Brand: false, Type: false, Subtype: false, Reference: true, Price: true,
				PricewoVAT: true, VAT: true, Units: true, Promotion: false, Discount: false};

			this.setState(
				Object.assign(this.state.OperationsErrors, {
					Sales: this.state.OperationsErrors.Sales.concat(new_sale_error)
				})
			);

			const new_disabling = {Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
				PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false};
			this.setState(
				Object.assign(this.state.Disabling, {
					Sales: this.state.Disabling.Sales.concat(new_disabling)
				})
			);


		}
		return null;
	}

	addOneMoreRowToNewCompaniesTable = () => {

		const Row_index = this.state.RoIndex;

		const NoOfRows = this.state.NoOfRows;

		if (+NoOfRows === +Row_index) {
			// If this is the last row on the table and something has been written, add a new row to the table and to the state.
			var current_rows = this.state.NewCompanies;
			const new_row = this.state.NewSale[0];
			const clone = Object.assign({}, new_row);
			current_rows.push(clone);
			this.setState(
				Object.assign(this.state.NewCompanies[+Row_index], {No: +Row_index + 1})
			);

			this.setState({NoOfRows: +NoOfRows + 1});

			// // Add one more row to the Errors array:
			// const new_sale_error = {Product: true, Brand: false, Type: false, Subtype: false, Reference: true, Price: true,
			// 	PricewoVAT: true, VAT: true, Units: true, Promotion: false, Discount: false};
			//
			// this.setState(
			// 	Object.assign(this.state.OperationsErrors, {
			// 		Sales: this.state.OperationsErrors.Sales.concat(new_sale_error)
			// 	})
			// );

			// const new_disabling = {Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
			// 	PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false};
			// this.setState(
			// 	Object.assign(this.state.Disabling, {
			// 		Sales: this.state.Disabling.Sales.concat(new_disabling)
			// 	})
			// );


		}
		return null;
	}

	decideAnyPopupOnArrival = () => {

		if (this.state.Props.location.popup === 2) {
			return [
				this.Obscurer(),
				<div className='decideAnyPopupOnArrival'>
					{this.ArrivalPopupText(this.state.Props.location.popup)}
					{this.ArrivalPopupCloseButton()}
				</div>
			]
		} else {
			return null
		}
	}

	ArrivalPopupText = (code) => {

		if (code === 2) {
			//You can return and modify your information at any time.
			let message = <div>{'Please, note that you may modify your profile data at any time on the My account section (see ' +
			'navigation bar above).'}</div>
			return [
				<div className='ArrivalPopupText'>
					{'Please, note that you may modify your profile data at any time on the My account section (see ' +
					'navigation bar above).'}
				</div>
			]
		}
	}

	ArrivalPopupCloseButton = () => {
		return (
			<Button className="ArrivalPopupCloseButton" block size="lg" onClick={this.PopUpMissingDataAccepted}>
				{'I understand'}
			</Button>
		)
	}

	PreRunOperations = async () => {


		let check_Profile =  await this.RetrieveMyProfile();
		// console.log(check_Profile);

		let check_History = null;
		if (!isUserAPSI()) {
			check_History = await this.RetrieveMyHistory();
		} else {
			check_History = await this.RetrieveMyRelations();
		}

		//Change to Data Preparation
		if (check_Profile !== null && check_History !== null) {
			this.setState({PrimaryStatus: this.state.PrimaryCode.DataPreparation});
		}

		return null;

	}

	changeStatusTo1 = () => {
		if (this.state.PrimaryStatus !== this.state.PrimaryCode.RenderHistorical) {
			this.setState({PrimaryStatus: this.state.PrimaryCode.RenderHistorical});
			return null;
		} else {
			return null;
		}
	}

	changeStatusToLoad = () => {
		if (this.state.PrimaryStatus !== this.state.PrimaryCode.Load) {
			this.setState({PrimaryStatus: this.state.PrimaryCode.Load});
			return null;
		} else {
			return null;
		}
	}

	setScreenDatetime = () => {
		const Screen_datetime = new Date();

		const SaleDateString = Screen_datetime.toString();
		const Month = Screen_datetime.getMonth() + 1;
		// const month = SaleDateString.substr(4, 3);
		const day = SaleDateString.substr(8, 2);
		const year = SaleDateString.substr(11, 4);
		const hour = SaleDateString.substr(16, 2);
		const minutes = SaleDateString.substr(19, 2);
		const seconds = SaleDateString.substr(22, 2);
		const dateFormatDesired = day + '-' + Month + '-' + year + ' ' + hour + ':' + minutes + ':' + seconds;

		return dateFormatDesired;
	}

	changeSecondaryStatusToSale = () => {
		// This function

		this.checkErrorMessageOnButton();

		const ScreenDatetime = this.setScreenDatetime();
		this.setState({ScreenDatetime: ScreenDatetime}, () => {this.changeSecondaryStatusToSalePerform()});
	}

	changeSecondaryStatusToSalePerform = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.Sale) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Sale});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToNewCompany = () => {

		this.checkErrorMessageOnButton();

		const ScreenDatetime = this.setScreenDatetime();
		this.setState({ScreenDatetime: ScreenDatetime}, () => {this.changeSecondaryStatusToNewCompanyPerform()});
	}

	changeSecondaryStatusToNewCompanyPerform = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.NewCompany) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.NewCompany});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToChange = () => {
		//console.log(this.state);
		const ScreenDatetime = this.setScreenDatetime();
		this.setState({ScreenDatetime: ScreenDatetime}, () => {this.changeSecondaryStatusToChangePerform()});
	}

	changeSecondaryStatusToChangePerform = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.Change) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Change});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToReturn = () => {
		//console.log(this.state);
		const ScreenDatetime = this.setScreenDatetime();
		this.setState({ScreenDatetime: ScreenDatetime}, this.changeSecondaryStatusToReturnPerform());
	}

	changeSecondaryStatusToReturnPerform = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.Return) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Return});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToNone = () => {
		// console.log(this.state.SecondaryStatus);
		console.log('holi')
		const ScreenDatetime = new Date();
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.None) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.None});
			this.setState({ScreenDatetime: ScreenDatetime});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToNoneInChild = () => {
		//console.log(this.state.SecondaryStatus);
		const ScreenDatetime = new Date();
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.None) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.None});
			this.setState({ScreenDatetime: ScreenDatetime});
			this.closeNewSuboperationPopUp();
			return null;
		} else {
			return null;
		}
	}

	printTest = () => {
		return null;
	}

	regenerating_jwt = () => {

		// let Rend = new Renderizar();
		// Rend.start_jwt_creation_and_regeneration();
		return null;
	}


	wrapperPreRun = () => {

		if (this.state.ErrorMessage !== 'Retrieve_my_historic_operations_1' && this.state.ErrorMessage !== 'Retrieve_my_profile_1') {
			this.PreRunOperations();
		}
		return null;
	}


	buttons = () => {

		return (
			<div className="ButtonsEnv">
				{this.newSaleButton()}
				{this.modifyCompany()}
				{this.removeCompany()}
				{this.sendEmails2CompaniesButton()}
				{this.newChangeButton()}
				{this.newReturnButton()}
				{this.updateButton()}
			</div>
		)
	}

	newSaleButton = () => {
		if (isUserACompany()) {
			return (
				<Button className="NewSaleButton" block size="lg" type="submit"
						onClick={() => {this.changeSecondaryStatusToSale()}}>
					{'New Sale'}
				</Button>
			)
		}
		if (isUserAPSI()) {
			return (
				<Button className="NewSaleButton" block size="lg" type="submit"
						onClick={() => {this.changeSecondaryStatusToNewCompany()}}>
					{'Add Company'}
				</Button>
			)
		}
		if (isUserAnIndividual()) {
			return null;
		}
	}


	modifyCompany = () => {
		if (isUserAPSI()) {
			return (
				<Button className="modifyCompany" block size="lg" type="submit"
						onClick={() => {this.changeSecondaryStatusToNewCompany()}}>
					{'Modify Company'}
				</Button>
			)
		} else{
			return null;
		}
	}

	removeCompany = () => {
		if (isUserAPSI()) {
			return (
				<Button className="removeCompany" block size="lg" type="submit"
						onClick={() => {this.changeSecondaryStatusToNewCompany()}}>
					{'Remove Company'}
				</Button>
			)
		} else{
			return null;
		}
	}

	sendEmails2CompaniesButton = () => {
		if (isUserAPSI()) {
			return (
				<Button className="sendEmails2CompaniesButton" block size="lg" type="submit"
						onClick={() => {this.changeSecondaryStatusToNewCompany()}}>
					{'Send reminders'}
				</Button>
			)
		} else{
			return null;
		}
	}

	newChangeButton = () => {
		if (isUserACompany()) {
			return (
				<Button className="NewChangeButton" block size="lg" type="submit"
						onClick={this.changeSecondaryStatusToChange}>
					{'New Change'}
				</Button>
			)
		} else {
			return null;
		}
	}

	renderNewChange = () => {
		console.log('');
		return null;
	}

	newReturnButton = () => {
		if (isUserACompany()) {
			return (
				<Button className="NewReturnButton" block size="lg" type="submit"
						onClick={this.changeSecondaryStatusToReturn}>
					{'New Return'}
				</Button>
			)
		} else {
			return null;
		}
	}

	updateButton = () => {
		if (isUserACompany()) {
			return (
				<Button className="UpdateButton" block size="lg" type="submit"
						onClick={this.changeStatusToLoad}>
					{'Update'}
				</Button>
			)
		}
		if (isUserAPSI()) {
			return (
				<Button className="UpdateButtonPSI" block size="lg" type="submit"
						onClick={this.changeStatusToLoad}>
					{'Update'}
				</Button>
			)
		}else {
			return null;
		}
	}



	HeaderLandingPage = () => {

		return (
			<div className='HeaderLandingPageMyArea'>
				{this.LogoLandingPage()}
				{this.navigationButtons()}
				{this.welcomeName()}
				{this.LogOutButton()}
			</div>
		)
	}

	welcomeName = () => {
		// console.log(this.state.Profile);

		if (isUserACompany()) {
			return (
				<div className='welcomeName'>
					<span className='welcomeNameSpan'>Hi, {this.state.Profile.Company.Name}!</span>
				</div>
			)

		} else {
			if (isUserAPSI()) {
				return (
					<div className='welcomeName'>
						<span className='welcomeNameSpan'>Hi, {this.state.Profile.Company.Name}!</span>
					</div>
				)
			} else
				{
					return (
						<div className='welcomeName'>
							<span>Hi, {this.state.Profile.Person.Nickname}!</span>
						</div>
					)
				}
		}
	}

	navigationButtons = () => {
		return(
			<div className='navigationButtons'>
				{this.HistoricalLandingPage()}
				{this.ButtonLandingPage()}
			</div>
		)
	}


	LogoLandingPage() {
		return (
			<span className='LogoLandingPageMyArea'>
				{'TIDI'}
			</span>
		)
	}

	HistoricalLandingPage =()=> {
		return (
			<Button className='HistoricalLandingPageMyArea' block size="lg" >
				{'My history'}
			</Button>
		)
	}

	ButtonLandingPage =()=> {
		return (
			<Link to='/MyProfile'>
				<Button className='MyProfileLandingPageMyArea' block size="lg" >
					{'My account'}
				</Button>
			</Link>
		)
	}


	LogOutButton = () => {
		return (
			<Link to='/LogIn'>
				<Button className="LogOutButtonMyArea" block size="lg" onClick = {() => clearCookiesOnLogOut()}>
					{'Log out'}
				</Button>
			</Link>
		)
	}







	AddNewCompanyWindow = () => {

		return (
			<div className='containerOfSale'>
				{this.CloseButton()}
				{this.NewCompanyMessage()}
				{this.ErrorMessagesInSaleWindow()}
				{this.NewCompaniesTablePlusDisclaimer()}
			</div>
		)
	}


	renderSaleSubwindow = () => {

		let div = null;

		if (this.state.PopUpMoreInfo !== null) {
			div = <div className='NewOperationUpperDataContainerNoBorder'>
				{this.NewSaleID()}
				{this.NewSaleEmployee()}
				{this.NewSaleShop()}
			</div>;
		}

		else {
			div = <div className='NewOperationUpperDataContainer'>
				{this.NewSaleID()}
				{this.NewSaleEmployee()}
				{this.NewSaleShop()}
			</div>;
		}

		return (
			<div className='containerOfSale'>
				{this.CloseButton()}
				{this.NewSaleMessage()}
				{this.ErrorMessagesInSaleWindow()}
				{div}
				{this.NewSaleDate()}
				{this.SaleTablePlusShippingPlusTicket()}
			</div>
		)
	}

	checkErrorMessageOnButton = () => {

		// console.log(this.checkHighLevelErrors());
		if ( (this.state.PressedEndButton === true && this.checkHighLevelErrors() === true)) {
			this.setState({errorMessageOnButton: true});
		} else {
			this.setState({errorMessageOnButton: false});
		}

		return null;
	}

	SaleTablePlusShippingPlusTicket = () => {
		return (
			<div className = 'SaleTablePlusShippingPlusTicket'>
				{this.SaleTablePlusShippingPlusTicketEffective()}
			</div>
		)
	}

	SaleTablePlusShippingPlusTicketEffective = () => {
		return [
			this.wrapperRenderSaleTable(),
			this.NewSaleShipping(),
			this.NewSaleTicket()
		]
	}


	NewCompaniesTablePlusDisclaimer = () => {
		return (
			<div className = 'NewCompaniesTablePlusDisclaimer'>
				{this.disclaimerNewCompaniesInPSIContainer()}
				{this.wrapperRenderNewCompaniesTable()}
				<div className="CountAndEmails">
					{this.countOfNewCompanies()}
					{this.buttonConfirmAndEmailsPSI()}
				</div>
			</div>
		)
	}

	disclaimerNewCompaniesInPSIContainer = () => {
		return(
			<div className='disclaimerNewCompaniesInPSIContainer'>
				{disclaimerNewCompaniesInPSI}
			</div>
		)
	}

	countOfNewCompanies = () => {

		let count = +this.state.NoOfRows -1;
		let count_companies = count.toString() + ' companies';
		if (count === 1) {
			count_companies = count.toString() + ' company';
		}

		return(
			<div className="countOfNewCompanies">
				{count_companies} will receive your request to connect.
			</div>
		)
	}


	buttonConfirmAndEmailsPSI = () => {
		// console.log(this.state.PopUpMoreInfo);
		// if (this.state.PopUpMoreInfo !== null) {
		// 	return null;
		// } else {
			return (
				<div className='buttonConfirmAndEmailsPSI'>
					{this.buttonConfirmCompanies()}
				</div>
			)
		// }
	}

	CloseButton = () => {

		return (
			<div className='CloseButton'>
				<Button className="CloseButtonButton" block size="lg" onClick={this.closeNewSuboperationPopUp}>
					<div className='CloseButtonButtonText'> X </div>
				</Button>
			</div>
		)
	}

	NewSaleTicket = () => {
		return (
			<div className='NewSaleTicket'>
				{this.NewSaleTicketSummary()}
				{/*{this.NewSaleTicketClient()}*/}
				{this.NewSaleTicketButton()}
			</div>
		)
	}

	NewSaleTicketSummary = () => {
		return (
			<div className='NewSaleTicketSummary'>
				{this.NewSaleSummaryHeader()}
				{this.NewSaleSummaryTable()}
				{this.NewSaleSummaryTotals()}
			</div>
		)
	}

	NewSaleTicketClient = () => {
		return (
			<div className='NewSaleTicketClient'>
				<div className='NewChangeTicketClientHeader'>
					{this.NewSaleClientDataHeader()}
				</div>
				{this.NewSaleClientDataEmail()}
			</div>
		)
	}

	NewSaleTicketButton = () => {
		// console.log(this.state.PopUpMoreInfo);
		if (this.state.PopUpMoreInfo !== null) {
			return null;
		} else {
			return (
				<div className='NewSaleTicketButton'>
					{this.buttonScanAndPay()}
				</div>
			)
		}
	}

	NewSaleSummaryHeader = () => {
		return (
			<div>
				<table className='NewSaleSummaryHeader'>
					<tr>{this.renderTicketHeader()}</tr>
				</table>
			</div>
		)
	}

	renderTicketHeader = () => {
		let first_line = this.state.OrderTicketHeader[0];

		if (this.state.PopUpMoreInfo !== null) {
			// console.log(this.state.PopUpMoreInfo);
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			return [
				<th>{first_line.Order_summary}</th>,
				<th>{Suboperation_data.Info.Sales_info.Sale_data[0].Product_data.Screen_datetime}</th>
			]

		} else {
			return [
				<th>{first_line.Order_summary}</th>,
				<th>{this.state.ScreenDatetime}</th>
			]
		}
	}


	NewSaleSummaryTable = () => {
		return (
			<div className='NewSaleSummaryTable'>
				<table className='SummarySaleTable'>
					<div className='SummarySaleTableHeaderEndLine'>  </div>
					<thead className='SummarySaleTableHeader'>
					<tr>{this.renderSummaryTableHeader()}</tr>
					</thead>
					<tbody className='SummarySaleTableBody'>
					{this.decisionDrivenHowManyRowsInSummary()}
					</tbody>
				</table>
				<div className='SummarySaleTableBodyEndLine'>  </div>
			</div>
		)
	}


	NewSaleSummaryTotals = () => {
		return (
			<div className='NewSaleSummaryTotals'>
				<table className='NewSaleSummaryTotalsTable'>
					<tbody className='NewSaleSummaryTotalsTableBody'>
					<td>{this.subtotalFirstColumn()}</td>
					<td>{this.subtotalSecondColumn()}</td>
					</tbody>
				</table>
			</div>
		)
	}

	subtotalFirstColumn = () => {

		var ccy = null;
		if (isUserACompany()) {
			ccy = this.state.Profile.Company.Currency;
		} else {
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			ccy = Suboperation_data.Info.Sales_info.Sale_data[0].Product_data.Currency;
		}

		// Eliminate the currency header in case it's missing
		let total = 'Total (' + ccy + ')'

		if (ccy === null) {
			total = 'Total';
		}

		return ([
			<tr> <td> {'Subtotal'} </td> </tr >,
			<tr > <td> {'Shipping'} </td> </tr >,
			<tr > <td> </td> </tr >,
			<tr > <td> {total}</td> </tr >,
			<tr > <td> {'Taxes'}</td> </tr >
		])
	}

	subtotalSecondColumn = () => {
		return [
			<tr><td title={this.calculateWriteSubtotal()}> {this.calculateWriteSubtotal()} </td></tr >,
			<tr><td title={this.writeShippingNumber()}> {this.writeShippingNumber()} </td></tr >,
			<tr > <td> </td> </tr >,
			<tr><td title={this.writeTotalNumber()}> {this.writeTotalNumber()} </td></tr >,
			<tr><td title={this.writeTaxesNumber()}> {this.writeTaxesNumber()} </td></tr >
		]
	}


	calculateWriteSubtotal = () => {
		var array = [];
		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			console.log(Suboperation_data);
			Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {

				const {Product_data, Discount, Units} = row;
				array.push(calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount));

				return null;
			})

		} else {
			var sale_data = this.state.NewSales;

			sale_data.map((header, index) => {

				const {Price, PricewoVAT, VAT, Units, Discount} = header //destructuring
				// console.log(header);
				array.push(calculatorOfSinglePrice(Price, PricewoVAT, VAT, Units, Discount));

				return null;
			})
		}

		const new_array = array.map((i) => Number(i));
		const pre_rounded = new_array.reduce((a, b) => a + b, 0);

		const rounded = Math.round((pre_rounded + Number.EPSILON) * 100) / 100;
		return rounded.toFixed(2);
	}


	writeShippingNumber = () => {
		if (this.state.PopUpMoreInfo !== null) {
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			console.log(Suboperation_data)

			const rounded = Math.round((+Suboperation_data.Shipping + Number.EPSILON) * 100) / 100;
			return rounded.toFixed(2);
		} else {
			const rounded = Math.round((Number(this.state.ShippingNewSale) + Number.EPSILON) * 100) / 100;
			return rounded.toFixed(2);
		}
	}



	writeTotalNumber = () => {
		const subtotal = this.calculateWriteSubtotal();
		const shipping = this.writeShippingNumber();

		let total = +subtotal + +shipping;

		const rounded = Math.round((total + Number.EPSILON) * 100) / 100;
		return rounded.toFixed(2);

	}


	writeTaxesNumber = () => {
		var array = [];
		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			console.log(Suboperation_data);
			Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {

				const {Product_data, Units} = row;
				// console.log(row);
				array.push(calculateTaxesInSuboperation(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units));

				return null;
			})

		} else {
			var sale_data = this.state.NewSales;

			sale_data.map((header, index) => {

				const {Price, PricewoVAT, VAT, Units} = header //destructuring
				// console.log(header);
				array.push(calculateTaxesInSuboperation(Price, PricewoVAT, VAT, Units));

				return null;
			})
		}

		// console.log(array);
		const new_array = array.map((i) => Number(i));
		const pre_rounded =  new_array.reduce((a, b) => a + b, 0)

		const rounded = Math.round((pre_rounded + Number.EPSILON) * 100) / 100;
		return rounded.toFixed(2);
	}


	currencyMessage = () => {
		return (
			<div className='currencyMessage'>
				<span>
					{'*All quantities are shown in ' + this.state.Profile.Company.Currency}
				</span>
			</div>
		)
	}


	buttonScanAndPay = () => {
		return (
			<div className='buttonScanAndPay'>
				{this.errorMessageOnButton()}
				<Button className="buttonScanAndPayButton" block size="lg" onClick={this.wrapperButtonScanAndPay2Operations}
						disabled={this.state.missingCrucialData === true}
				>
					{'Scan & pay'}
				</Button>
			</div>
		)
	}

	buttonConfirmCompanies = () => {
		return (
			<div className='buttonConfirmCompaniesDiv'>
				{this.errorMessageOnButton()}
				<Button className="buttonConfirmCompanies" block size="lg" onClick={this.wrapperButtonConfirmAndSendEmails}
						disabled={this.state.missingCrucialData === true}
				>
					{'Confirm & send emails'}
				</Button>
			</div>
		)
	}

	errorMessageOnButton = () => {
		if (this.state.missingCrucialData === true) {
			return <div className='errorMessageOnButton'> Please, provide the necessary information on My account. </div>
		}
		if (this.state.errorMessageOnButton === true) {
			return <div className='errorMessageOnButton'> Please, check the errors above before continuing. </div>
		} else { return null; }
	}


	actionsAfterClosingNewSuboperationPopup = () => {

		// Reset the objects changed during the Sales process
		this.setState({
			NewSales: [{ No: 1, Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null,
				PricewoVAT: null, VAT: null, Units: null, Promotion: null, Discount: null}]});

		this.setState({SVG_PNG: null});
		this.setState({ErrorMessage: null});
		this.setState({OperationsErrors: {Sales: [{Product: true, Brand: false, Type: false, Subtype: false, Reference: true, Price: true,
					PricewoVAT: true, VAT: true, Units: true, Promotion: false, Discount: false}],
				ChooseChange: [{Units: 0, Reason: null}],
				Shipping: true,
				ClientEmail: true}});
		this.setState({HighLevelErrors: {
				Ship_ping: false,
				Client_Email: false,
				OldSaleID: false,
				Sales: {Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false},
				ChooseChange: {Units: false, Reason: false},
				ClientConfirmsSendingByEmail_Email: false
			}});
		this.setState({Disabling: {Sales: [{Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false}]}});
		this.setState({PressedEndButton: false});
		this.setState({errorMessageOnButton: false});

		Object.assign(this.state.Client[0], {Email_address: ''})
		this.setState({NoOfRows: 1});
		this.setState({ShippingNewSale: 0});
		this.setState({EmployeeChosenTable: ' - Choose an employee - '});
		this.setState({ShopChosenTable: ' - Choose a shop - '});
		this.setState({Employee_ID: null});
		this.setState({Shop_ID: null});
		this.setState({EmployeeChosen: ' - All employees - '});
		this.setState({ShopChosen: ' - All shops - '});
		this.setState({PopUpMoreInfo: null});
		this.setState({ObscurerQRReceived: false});
		this.setState({DropDownAttribute: null});
		this.setState({Operation_ID: null});

		// Antes aquí había un cambio del SecondaryStatus a None.

		return null;
	}


	closeNewSuboperationPopUp = () => {

		this.actionsAfterClosingNewSuboperationPopup();

		//Return to LandingPage view only
		this.changeSecondaryStatusToNone();
		return null;
	}


	closeNewSuboperationPopUpAfterQRIsSent = () => {

		// console.log(this.state.TerciaryStatus)
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.None});
		this.setState({ClientConfirmsSendingByEmailEmail: null})

		this.resetButtonsOnResendQR();

		// this.setState({ObscurerQRReceived: false});

		this.setState({ObscurerQRReceived: false},
			()=> {this.setState({SecondaryStatus: this.state.SecondaryCode.None},
			() => { this.setState({PrimaryStatus: this.state.PrimaryCode.Load})})
		});

		return null;
	}


	checkHighLevelErrors = () => {
		// Checks if there are any high level errors

		let highLevelErrorsArray = this.state.HighLevelErrors;
		let highLevelErrorsSalesArray = highLevelErrorsArray.Sales;

		return highLevelErrorsSalesArray.Product === true || highLevelErrorsSalesArray.Reference === true ||
			highLevelErrorsSalesArray.Price === true ||
			highLevelErrorsSalesArray.PricewoVAT || highLevelErrorsSalesArray.VAT ||
			highLevelErrorsSalesArray.Units || highLevelErrorsSalesArray.Discount ||
			highLevelErrorsArray.Ship_ping;
	}


	ErrorMessagesInSaleWindow = (field) => {
		// Displays the correct error message in the Sale Window

		let anyErrorCheck = this.checkHighLevelErrors();
		let errorTitle = 'Please, check the following fields before finishing the transaction:';
		let errorMessages = '';
		let highLevelErrorsArray = this.state.HighLevelErrors;
		// console.log(highLevelErrorsArray)

		if (this.state.PressedEndButton === true && anyErrorCheck === true) {
			// console.log(highLevelErrorsArray);

			let errorTitle = 'Please, check the following fields before finishing the transaction:';
			// console.log(errorMessages);

			let errorMessages = '';

			if (highLevelErrorsArray.Sales.Product === true) {
				const thisError = '\n - Products involved, i.e. trousers, chewing gums,...';
				errorMessages += thisError;
				// errorMessages.insertAdjacentHTML('beforeend', thisError);
			}

			if (highLevelErrorsArray.Sales.Reference === true) {
				const thisError = '\n - Reference must be provided';
				errorMessages += thisError;
			}
			// console.log(highLevelErrorsArray.Sales);
			if (highLevelErrorsArray.Sales.Price === true || highLevelErrorsArray.Sales.PricewoVAT === true) {
				const thisError = '\n - Price or Price without VAT, i.e. 1.35, 62.5';
				errorMessages += thisError;
			}
			// if (highLevelErrorsArray.Sales.PricewoVAT === true) {
			// 	const thisError = '\n - Price without VAT';
			// 	errorMessages += thisError;
			// }
			if (highLevelErrorsArray.Sales.VAT === true) {
				const thisError = '\n - VAT of your products, i.e. 7, 21.5,...';
				errorMessages += thisError;
			}
			if (highLevelErrorsArray.Sales.Units === true) {
				const thisError = '\n - Units of your products, i.e. 1, 5,...';
				errorMessages += thisError;
			}

			if (highLevelErrorsArray.Sales.Discount === true) {
				const thisError = '\n - Discount made (in %), i.e. 10, 60.25,...';
				errorMessages += thisError;
			}
			if (highLevelErrorsArray.Ship_ping === true) {
				const thisError = "\n - Shipping cost, i.e. 5, 17.25,...";
				errorMessages += thisError;
			}

			return (
				// <div className='ErrorMessagesInSaleWindow'> {errorMessages.split('\n').map(str =>
				// 	<p className='ErrorMessagesInSaleWindowText'>{str}</p>)} </div>

				<div className='ErrorMessagesInChangeWindowContainer'>
					<div className='ErrorMessagesInChangeWindowTitle'> {errorTitle} </div>
					<div className='ErrorMessagesInSaleWindow'> {errorMessages.split('\n').map(str =>
						<p className='ErrorMessagesInChangeWindowTextSales'>{str}</p>)} </div>
				</div>
			)

		} else {

			if (this.state.PressedEndButton === false && this.checkMustPricesBeChecked()) {
				console.log('dentro')

				const thisError = '\n - Price or Price without VAT, i.e. 1.35, 62.5';
				errorMessages += thisError;
				return (

					<div className='ErrorMessagesInChangeWindowContainer'>
						<div className='ErrorMessagesInChangeWindowTitle'> {errorTitle} </div>
						<div className='ErrorMessagesInSaleWindow'> {errorMessages.split('\n').map(str =>
							<p className='ErrorMessagesInChangeWindowTextSales'>{str}</p>)} </div>
					</div>
				)
			}  else {return null}
		}
	}


	checkMustPricesBeChecked = () => {
		// console.log(this.state.LastChanged)

		if (this.state.LastChanged === 'Price' || this.state.LastChanged === 'PricewoVAT') {
			// Checks whether Price or PricewoVAT has been introduced with errors or not

			let salesArray = this.state.NewSales;
			let operationsErrors = this.state.OperationsErrors.Sales;

			let prices = salesArray.map(value => value.Price);
			let pricesWoVAT = salesArray.map(value => value.PricewoVAT);
			let pricesErrors = operationsErrors.map(value => value.Price);
			let pricesWoVATErrors = operationsErrors.map(value => value.PricewoVAT);

			// let countTrueInProducts = prices.filter(x => x === '' || x === null).length;
			// console.log(countTrueInProducts)

			for (let step = 0; step < +salesArray.length - 1; step++) {
				// console.log(prices[step]);
				// console.log(pricesWoVAT[step]);
				// console.log(validate_pure_float(prices[step]))
				// console.log(validate_pure_float(pricesWoVAT[step]))
				// console.log(validate_number(prices[step]))
				// console.log(validate_number(pricesWoVAT[step]))
				// console.log(validate_number_non_empty_non_Null(prices[step]))
				// console.log(validate_number_non_empty_non_Null(pricesWoVAT[step]))
				// Check Price or PricewoVAT has been written
				if (!(validate_pure_float(prices[step]) &&
					validate_pure_float(pricesWoVAT[step]) ) ) {
					// Check there is some error on the price or priceWoVAT written
					if (pricesErrors[step] === true || pricesWoVATErrors[step] === true) {
						// console.log(true)
						return true;
					}
				}
			}
			// console.log(false)
			return false;
		} else {
			return false;
		}

	}


	validateSaleRequestInput = (index) => {


		// Evaluates if there exists any error on the data provided by the user and changes the state to show the error
		// messages.

		let isThereAnyErrorInScreen = false;
		let errorsArray = this.state.OperationsErrors;
		// console.log(errorsArray);

		let products = errorsArray.Sales.map(value => value.Product);
		let reference = errorsArray.Sales.map(value => value.Reference);
		let price = errorsArray.Sales.map(value => value.Price);
		let priceWOVAT = errorsArray.Sales.map(value => value.PricewoVAT);
		let vat = errorsArray.Sales.map(value => value.VAT);
		let units = errorsArray.Sales.map(value => value.Units);
		let discounts = errorsArray.Sales.map(value => value.Discount);
		console.log(reference)

		let countTrueInProducts = products.filter(x => x === true).length;
		let countTrueInReference = reference.filter(x => x === true).length;
		let countTrueInPrices = price.filter(x => x === true).length;
		let countTrueInPriceWOVATs = priceWOVAT.filter(x => x === true).length;
		let countTrueInVAT = vat.filter(x => x === true).length;
		let countTrueInUnits = units.filter(x => x === true).length;
		let countTrueInDiscounts = discounts.filter(x => x === true).length;
		console.log(countTrueInReference)


		if ( (countTrueInProducts === 1 && this.state.NewSales.length === 1) || countTrueInProducts > 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Product: true }), () => {this.checksAfterInputValidation()}
			);
		} if (!((countTrueInProducts === 1 && this.state.NewSales.length === 1 ) || countTrueInProducts > 1)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Product: false }), () => {this.checksAfterInputValidation()}
			);
		}

		if   (  (countTrueInReference === 1 && this.state.NewSales.length === 1) || countTrueInReference > 1) {
			isThereAnyErrorInScreen = true;
			console.log('set to True')
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Reference: true }), () => { this.checksAfterInputValidation()}
			);
		} if (! ((countTrueInReference === 1 && this.state.NewSales.length === 1) || countTrueInReference > 1) ) {
			console.log('set to False')
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Reference: false }), () => {this.checksAfterInputValidation()}
			);
		}

		if   (  (countTrueInPrices === 1 && this.state.NewSales.length === 1) || countTrueInPrices > 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Price: true }), () => {this.checksAfterInputValidation('Price')}
			);
		} if (! ((countTrueInPrices === 1 && this.state.NewSales.length === 1) || countTrueInPrices > 1) ) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Price: false }), () => {this.checksAfterInputValidation('Price')}
			);
		}

		if ( (countTrueInPriceWOVATs === 1 && this.state.NewSales.length === 1) || countTrueInPriceWOVATs > 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {PricewoVAT: true }), () => {this.checksAfterInputValidation('PricewoVAT')}
			);
		} if ( !((countTrueInPriceWOVATs === 1 && this.state.NewSales.length === 1) || countTrueInPriceWOVATs > 1) ) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {PricewoVAT: false }), () => {this.checksAfterInputValidation('PricewoVAT')}
			);
		}


		if ( (countTrueInVAT === 1 && this.state.NewSales.length === 1) || countTrueInVAT > 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {VAT: true }), () => {this.checksAfterInputValidation()}
			);
		} if ( !((countTrueInVAT === 1 && this.state.NewSales.length === 1) || countTrueInVAT > 1)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {VAT: false }), () => {this.checksAfterInputValidation()}
			);
		}

		if ( (countTrueInUnits === 1 && this.state.NewSales.length === 1) || countTrueInUnits > 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Units: true }), () => {this.checksAfterInputValidation()}
			);
		} if ( !((countTrueInUnits === 1 && this.state.NewSales.length === 1) || countTrueInUnits > 1)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Units: false }), () => {this.checksAfterInputValidation()}
			);
		}
		if ( (countTrueInDiscounts === 1 && this.state.NewSales.length === 1) || countTrueInDiscounts >= 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Discount: true }), () => {this.checksAfterInputValidation()}
			);
		} if ( !((countTrueInDiscounts === 1 && this.state.NewSales.length === 1) || countTrueInDiscounts >= 1)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Discount: false }), () => {this.checksAfterInputValidation()}
			);
		}

		// console.log(this.state.HighLevelErrors);
		// console.log(errorsArray);
		if (errorsArray.Shipping === true) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors, {Ship_ping: true }), () => this.checksAfterInputValidation()
			);
		}else {
			this.setState(
				Object.assign(this.state.HighLevelErrors, {Ship_ping: false }), () => this.checksAfterInputValidation()
			);
		}

		return isThereAnyErrorInScreen;

	}

	checksAfterInputValidation = (field) => {

		this.checkErrorMessageOnButton();
		this.ErrorMessagesInSaleWindow(field);
		return null;
	}

	wrapperButtonScanAndPay2Operations = () => {
		// TODO: definir qué sucede cuando no se ha seleccionado ningún artículo para cambiar
		// console.log(this.state.Employee_ID)
		// console.log(this.state.Shop_ID)

		this.setState({PressedEndButton: true}, () => {
			this.checkErrorMessageOnButton();
			console.log(this.state.ShippingNewSale);

			let check = this.validateSaleRequestInput();

			if (!check === true) {
				this.buttonScanAndPaySubmit();
			}

			return null;
		});

		return null;
	}

	buttonScanAndPaySubmit = () => {

		// Display the new background pop-up - only waiting for the QR code.
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.Sale});

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		let ExecuteSale = () => {
			return Create_json_sales(this.state.New_ID_Provider_Operation, this.state.NewSales, this.state.Client, this.state.ShippingNewSale, this.state.Employee_ID, this.state.Shop_ID, this.state.ScreenDatetime, this.state.Profile.Company.Currency).then(json => {
				return json
			});
		}

		let execute_Sale = ExecuteSale()

		execute_Sale.then((response) => {
				console.log(response);
				if (response.contenido.Sales_output.Status === "OK") {
					this.setState({SVG: response.contenido.Sales_output.QR})
					this.setState({SVG_PNG: response.contenido.Sales_output.QR_PNG})

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// Render the QR's obscurer when the QR code is received.
					this.setState({ObscurerQRReceived: true});
					this.setState({Obscurer: true});

					// Store in state the Operation_ID
					this.setState({Operation_ID: response.contenido.Sales_output.Operation_ID})

					return null;
				} else {
					console.log('Status NOK');

					this.setState({ErrorMessage: 'SaleCouldNotBeExecuted'});

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					this.setState({ObscurerQRReceived: true});

					return null;
				}


			}
		)

		this.actionsAfterClosingNewSuboperationPopup();

		return null;

	}



	wrapperButtonConfirmAndSendEmails = () => {
		// TODO: definir qué sucede cuando no se ha seleccionado ningún artículo para cambiar
		// console.log(this.state.Employee_ID)
		// console.log(this.state.Shop_ID)

		// this.setState({PressedEndButton: true}, () => {
		// 	this.checkErrorMessageOnButton();
		// 	console.log(this.state.ShippingNewSale);
		//
		// 	let check = this.validateSaleRequestInput();
		//
		// 	if (!check === true) {
				this.buttonConfirmAndEmailsSubmit();
		// 	}
		//
		// 	return null;
		// });

		return null;
	}



	buttonConfirmAndEmailsSubmit = () => {

		// // Display the new background pop-up - only waiting for the QR code.
		// this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.Sale});

		// // Render the loading icon
		// this.setState({WaitingForRequest: true});

		let Execute_confirm_and_sendEmails = () => {
			return PSI_Relations_request(this.state.NewCompanies, true, false, false).then(json => {
				return json
			});
		}

		let execute_confirm_and_sendEmails = Execute_confirm_and_sendEmails()

		execute_confirm_and_sendEmails.then((response) => {
				console.log(response);
				// if (response.contenido.Sales_output.Status === "OK") {
					// this.setState({SVG: response.contenido.Sales_output.QR})
					// this.setState({SVG_PNG: response.contenido.Sales_output.QR_PNG})
					//
					// //Remove the loading icon
					// this.setState({WaitingForRequest: false});
					//
					// // Render the QR's obscurer when the QR code is received.
					// this.setState({ObscurerQRReceived: true});
					// this.setState({Obscurer: true});

				// 	return null;
				// } else {
					// console.log('Status NOK');
					//
					// this.setState({ErrorMessage: 'SaleCouldNotBeExecuted'});
					//
					// //Remove the loading icon
					// this.setState({WaitingForRequest: false});
					//
					// this.setState({ObscurerQRReceived: true});

					// return null;
				// }
			return null;
			}
		)

		return null;

	}

	SalesQRCodeWindow = () => {

		// console.log('holi');

		if (this.state.showSalesQRCode === this.state.showSalesQRCodeCode.Sale) {

			let error_message_resendQR = null;
			// console.log(this.state.ErrorMessage)
			if (this.state.ErrorMessage === 'SaleCouldNotBeExecuted') {
				error_message_resendQR = <div className='errorSendingOperationQR'> An error occurred. Please, contact support </div>
			}
			//QR code of a new Sale
			return (
				<div className='SalesQRCodeWindowSale'>
					<div className='SalesQRCodeWindowText'> Your client may scan the following QR to receive his/her
						TIDI on the phone </div>
					{error_message_resendQR}
					{this.render_svg(this.state.SVG_PNG)}
					<div className='SalesQRCodeWindowText2'> Or you may send the TIDI to his/her email address </div>
					{this.messageWrongEmailWhenResendingQRCode()}
					<div className='resendEmail'>
						<input className={'EmailAddresInputAfterOperationCompletion'} type='email'
							   value={this.state.ClientConfirmsSendingByEmailEmail}
							   onChange={(e) => this.validateEmailAddressUpdateClient2Operation(e.target.value)}/>
					</div>
					{this.buttonCloseQRMessageAfterOperationCompleted()}
				</div>
			)
		}

		if (this.state.showSalesQRCode === this.state.showSalesQRCodeCode.Historical) {

			console.log(this.state)
			console.log(this.state.QRs_Operation_ID)

			//QR code of a historic Operation

			let text_div = null;

			if (isUserACompany()) {
				text_div = <div className='SalesQRCodeWindowText'> This is the QR code of this operation. You may send it to another
					e-mail address.</div>;
			} else {
				text_div = <div className='SalesQRCodeWindowText'> This is the QR code of this operation. You may use it for changes or returns. </div>;
			}

			let error_message_resendQR = null;
			console.log(this.state.ErrorMessage)
			if (this.state.ErrorMessage === 'EmailCouldNotBeSent') {
				error_message_resendQR = <div className='errorResendQR'> An error occurred. Please, contact support </div>
			}
			if (this.state.ErrorMessage === 'Suboperations_QR_1') {
				console.log('aquí')
				error_message_resendQR = <div className='errorResendQR'> An error occurred. Please, contact support </div>
			}
			if ((this.state.ErrorMessage === 'InvalidEmail' || this.state.NewEmailAddressQR === '') && this.state.sendButtonPressed === true) {
				console.log('aquí')
				error_message_resendQR = <div className='errorResendQR'> Invalid e-mail address </div>
			}

			return (
				<div className='SalesQRCodeWindow'>
					{text_div}
					{this.showQRCode()}
					{this.resendEmail()}
					{error_message_resendQR}
					{this.chooseUpdateClientEmail()}
					{this.resendEmailAndCloseButtonsProvider()}
					{this.buttonCloseQRCodeClient()}
				</div>
			)
		}

		if (this.state.showSalesQRCode === this.state.showSalesQRCodeCode.None) {
			return null;
		}

	}


	validateEmailAddressUpdateClient2Operation = (value) => {

		this.setState({ClientConfirmsSendingByEmailEmail: format_email_address(value) })
		let validation_of_clientemail = email_validation_or_empty(value);
		console.log(validation_of_clientemail)
		this.setState(
				Object.assign(this.state.HighLevelErrors, {ClientConfirmsSendingByEmail_Email: !validation_of_clientemail })
			);
		return null;
	}


	resendEmail = () => {
		if (isUserACompany()) {
			// this.validateEmailAddressForQR();

			let div = 'resendEmailInput';
			if ((this.state.ErrorMessage === 'InvalidEmail' || this.state.NewEmailAddressQR === '') && this.state.sendButtonPressed === true) {
				div = 'resendEmailInput2'
			}
			return [
				<div className='resendEmailEmail'> E-mail address</div>,
				this.messageWrongEmailWhenResendingQRCode(),
				<div className='resendEmail'>
					<input className={div} type='email'
						   value={this.state.NewEmailAddressQR}
						   onChange={(e) => this.sendQRToNewEmailAddress(e.target.value)}/>
				</div>
			]
		}
		else { return null; }
	}


	chooseUpdateClientEmail = () => {

		if (isUserACompany()) {
			var buttonChosenYes = 'chooseUpdateClientEmailButtonYes';
			var buttonChosenNo = 'chooseUpdateClientEmailButtonNo';
			var errorMessageChosenButton = null;
			if (this.state.sendButtonPressed === true && this.state.wishForUpdatingClientID === false && this.state.wishForResendQR === false) {
				buttonChosenYes = 'chooseUpdateClientEmailButtonUnchosen';
				buttonChosenNo = 'chooseUpdateClientEmailButtonUnchosen';
				errorMessageChosenButton =
					<div className='errorMessageChosenButton'> Please, choose your desired action</div>
			}
			if (this.state.wishForUpdatingClientID === true) {
				buttonChosenYes = 'chooseUpdateClientEmailButtonChosen';
				buttonChosenNo = 'chooseUpdateClientEmailButtonNo';
			}
			if (this.state.wishForResendQR === true) {
				buttonChosenYes = 'chooseUpdateClientEmailButtonYes';
				buttonChosenNo = 'chooseUpdateClientEmailButtonChosen';
			}
			return (
				<div className='chooseUpdateClientEmail'>
					<div className='chooseUpdateClientEmailMessage'> Do you want to update the client of this row to
						this e-mail address?
					</div>
					<button className={buttonChosenYes} onClick={() => this.wishForUpdatingClientID(true)}> Yes</button>
					<button className={buttonChosenNo} onClick={() => this.wishForUpdatingClientID(false)}> Just
						resend
					</button>
					{errorMessageChosenButton}
				</div>
			)
		}
		else {return null;}
	}

	wishForUpdatingClientID = (value) => {
		// this.setState({clicked: true})
		this.setState({sendButtonPressed: true});
		if (value === true) {
			this.setState({wishForUpdatingClientID: true})
			this.setState({wishForResendQR: false})
		} else {
			this.setState({wishForUpdatingClientID: false})
			this.setState({wishForResendQR: true})
		}
		return null
	}


	messageWrongEmailWhenResendingQRCode = () => {
		// console.log(this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email)
		// console.log(this.state.sendButtonPressed)

		if ((this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === true) && this.state.sendButtonPressed === true) {
			return <div className='messageWrongEmailWhenSendingQRCodeAtEndOfOperation'> Invalid e-mail address</div>
		} else {
			return null;
		}
	}


	sendQRToNewEmailAddress = (e) => {

		console.log('holi');

		this.setState({NewEmailAddressQR: format_email_address(e) }, () => this.validateEmailAddressForQR());

		return null;
	}


	validateEmailAddressForQR = () => {

		console.log( email_validation(this.state.NewEmailAddressQR) );

		if (!email_validation(this.state.NewEmailAddressQR)) {
			this.setState({ErrorMessage: 'InvalidEmail'});
		} else {
			this.setState({ErrorMessage: null});
		}
		return null
	}

	resendEmailAndCloseButtonsProvider = () => {

		if (isUserACompany()) {
			return [
				this.missingCrucialInformationDummyQR(),
				<div className='resendEmailAndCloseButtonsProvider'>
					<div className='resendEmailButton'>
						<Button className="resendEmailButtonButton" block size="lg" disabled={this.state.missingCrucialData} onClick={() => {
							this.validationResendQR()
						}}>
							{'Send'}
						</Button>
					</div>
					<div className='buttonCloseHistoricalQRCode'>
						<Button className="buttonCloseQRCodeButton" block size="lg" onClick={() => {this.buttonCloseQRCodeSendToNewAddress()}}>
							{'Close'}
						</Button>
					</div>
				</div>
			]
		}
		else {return null;}
	}

	missingCrucialInformationDummyQR = () => {
		if (this.state.missingCrucialData === true) {
			return <div className='missingCrucialInformationDummyQR'> Please, provide the necessary information on My account before sending communications. </div>
		} else {return null}
	}


	validationResendQR = () => {

		this.setState({sendButtonPressed: true});

		if (this.state.ErrorMessage === 'InvalidEmail' || this.state.NewEmailAddressQR === '') {

		} else {
			if (this.state.wishForUpdatingClientID === true) {
				this.updateClientIDInOperationsRow(this.state.NewEmailAddressQR);
			}
			if (this.state.wishForResendQR === true) {
				this.resendQRFunctionWrapper();
			}
		}
		return null;
	}




	resendQRFunctionWrapper = () => {

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		// Uncomment the following lines when the response is required for some check.
		console.log(this.state.QRs_Operation_ID)
		console.log(this.state)



		let ResendingQR = () => {
			return Resend_QR(this.state.QRs_Operation_ID, this.state.Requested_ID_Provider_Operation, this.state.NewEmailAddressQR);
		}

		let resendingQR = ResendingQR()

		resendingQR.then((response) => {
				// console.log(response);
				if (response.contenido.Resend_QR_data_output.Status === "OK") {
					// this.setState({SVG: response.contenido.Resend_QR_data_input.QR})
					// this.setState({SVG_PNG: response.contenido.Resend_QR_data_input.QR_PNG})
					// this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.Sale});

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					this.buttonCloseQRCodeSendToNewAddress();
					this.resetButtonsOnResendQR();

					return null;
				} else {
					let error_code = response.contenido.Resend_QR_data_output.Error_code;
					if (error_code === 'Resend_QR_1' || error_code === 'Resend_QR_2' || error_code === 'Resend_QR_3') {
						this.setState({ErrorMessage: 'EmailCouldNotBeSent'})
					}

					this.setState({WaitingForRequest: false});

					console.log('Status NOK');
					return null;
				}
			}
		)

		return null;

	}

	render_svg = (SVG_file) => {

		if (SVG_file !== null) {

			let png_src = "data:image/png;base64," + SVG_file;

			return (
				document.getElementsByName('SalesQRCodeWindow').innerHTML =
					<img className='QRImage' src={png_src} alt=''/>
			)
		} else {
			return <div className='QRImage'/>;
		}
	}


	buttonCloseQRCodeClient = () => {

		if (!isUserACompany()) {
			return (
				<div className='buttonCloseQRCode'>
					<Button className="buttonCloseQRCodeButton" block size="lg" onClick={this.buttonCloseQRCodeSubmitParticular}>
						{'Close'}
					</Button>
				</div>
			)
		} else {return null;}
	}

	// buttonCloseQRCode = () => {
	// 	return (
	// 		<div className='buttonCloseQRCode'>
	// 			<Button className="buttonCloseQRCodeButton" block size="lg" onClick={this.buttonCloseQRCodeSubmit}>
	// 				{'Close'}
	// 			</Button>
	// 		</div>
	// 	)
	// }

	buttonCloseQRMessageAfterOperationCompleted = () => {

		let client_email_to_send_qr = this.state.ClientConfirmsSendingByEmailEmail;
		let title = 'User has already scanned the QR';
		if (client_email_to_send_qr !== null && client_email_to_send_qr !== '') {
			title = 'Send QR by email & close'
		}
		return (
			<div className='buttonCloseQRCodeSale'>
				<Button className="buttonCloseQRCodeButton" block size="lg" onClick={this.closeQRMessageAfterOperationCompletedButtonAction}>
					{title}
				</Button>
			</div>
		)
	}


	buttonCloseQRCodeSendToNewAddress = () => {

		this.setState({sendButtonPressed: null});
		this.setState({wishForUpdatingClientID: false});
		this.setState({wishForResendQR: false});
		this.setState({sendButtonPressed: null});
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.None});

		this.closeNewSuboperationPopUp();

		// // Update the TIDI number
		// this.setState({New_ID_Provider_Operation: +this.state.New_ID_Provider_Operation+1});

		return null;
	}

	buttonCloseQRCodeSubmit = () => {

		this.setState({sendButtonPressed: null});
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.None});
		this.setState({NewEmailAddressQR: null});

		this.closeNewSuboperationPopUp();

		// // Update the TIDI number
		// this.setState({New_ID_Provider_Operation: +this.state.New_ID_Provider_Operation+1});

		// Update the historical table view.
		this.changeStateToUpdateTheHistoricalTable();


		return null;
	}


	buttonCloseQRCodeSubmitParticular = () => {

		this.setState({sendButtonPressed: null});
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.None});
		this.setState({NewEmailAddressQR: null});

		this.closeNewSuboperationPopUp();

		// // Update the TIDI number
		// this.setState({New_ID_Provider_Operation: +this.state.New_ID_Provider_Operation+1});

		// // Update the historical table view.
		// this.changeStateToUpdateTheHistoricalTable();


		return null;
	}


	closeQRMessageAfterOperationCompletedButtonAction = () => {

		this.setState({sendButtonPressed: true});
		console.log(this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email)

		if (this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === false) {
			// The email address introduced fulfils the format required

			if (this.state.ClientConfirmsSendingByEmailEmail !== null && this.state.ClientConfirmsSendingByEmailEmail !== '') {
				// Perform the web service to update the client's email address in the Operations row
				this.updateClientIDInOperationsRow(this.state.ClientConfirmsSendingByEmailEmail);
			} else {
				// User has scanned the TIDI (or not received it in any way) --> Refresh the page.
				this.closeNewSuboperationPopUpAfterQRIsSent();
			}
		}

		return null;
	}


	resetButtonsOnResendQR = () => {
		console.log('holi')
		this.setState({wishForResendQR: false})
		this.setState({wishForUpdatingClientID: false})
		this.setState({sendButtonPressed: null});
		return null;

	}


	updateClientIDInOperationsRow = (Email_address) => {

		this.setState({WaitingForRequest: true});

		let operation_ID = this.state.Operation_ID;
		let ID_Provider_operation = this.state.New_ID_Provider_Operation;

		if (operation_ID === null) {
			operation_ID = this.state.QRs_Operation_ID;
			ID_Provider_operation = this.state.Requested_ID_Provider_Operation;
		}
		console.log(this.state.Operation_ID)
		console.log(this.state.QRs_Operation_ID)
		console.log(operation_ID)

		let SendingQRByEmail = () => {
			return updateClientInOperation(operation_ID, ID_Provider_operation, Email_address, true);
		}

		let sendingQRByEmail = SendingQRByEmail()

		sendingQRByEmail.then((response) => {
				// console.log(response);
				if (response.contenido.Update_client_in_operation_output.Status === "OK") {

					this.resetButtonsOnResendQR();

					// Reset the email address to send the email to
					this.setState({ClientConfirmsSendingByEmailEmail: ''})

					// this.buttonCloseQRCodeSendToNewAddress();
				} else {
					// TODO Martín - terminar tratamiento de errores
					// let error_code = response.contenido.Resend_QR_data_output.Error_code;
					// if (error_code === 'Resend_QR_1' || error_code === 'Resend_QR_2' || error_code === 'Resend_QR_3') {
					// 	this.setState({ErrorMessage: 'EmailCouldNotBeSent'})
					// }

					console.log('Status NOK');
				}

			//Remove the loading icon
			this.setState({WaitingForRequest: false});

			// // Close this suboperation popup
			this.closeNewSuboperationPopUpAfterQRIsSent();

			return null;
			}
		)
		return null;
	}


	changeStateToUpdateTheHistoricalTable = () => {

		// Update the historical table - hence repeat all requests to Back-end
		this.setState({PrimaryStatus: this.state.PrimaryCode.Load}
		);
		return null;
	}


	renderSummaryTableHeader = () => {
		var first_line = this.state.OrderSummaryHeaders[0];

		if (first_line === undefined) {
			return null;
		} else {
			let header = Object.keys(first_line)
			return header.map((key, index_2) => {
				return <th key={index_2}>{key}</th>
			})
		}
	}


	decisionDrivenHowManyRowsInSummary = () => {
		// In this function, the number of rows is defined automatically by decisionMakingHowManyRowsForSale
		//const NoOfRows = this.state.NoOfRows;
		//console.log(this.state.NewSales);
		var array = [];
		for (let step = 0; step < 1; step++) {
			array.push(this.renderSummaryTableData(step));
		}
		//console.log(array);
		return array;
	}


	renderSummaryTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			//console.log(Suboperation_data);
			return Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {

				var {Product_data, Discount, Units} = row;

				var DiscountPerc = null;

				if (Discount !== "" && Discount !== null) {
					DiscountPerc = Discount + ' %';
				} else {
					DiscountPerc = null;
				}

				return (
					<tr key={index}>
						<td title={Product_data.Product}>{Product_data.Product}</td>
						<td title={Units}>{Units}</td>
						<td title={DiscountPerc}>{DiscountPerc}</td>
						<td title={calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount)}>
							{calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount)}
						</td>
					</tr>
				)

			})

		} else {
			var sale_data = this.state.NewSales;

			return sale_data.map((header, index) => {

					var {No, Price,  PricewoVAT, VAT, Product, Units, Discount} = header //destructuring

					var DiscountPerc = null;

					if (Discount !== "" && Discount !== null) {
						DiscountPerc = Discount + ' %';
					} else {
						DiscountPerc = null;
					}

					return (
						<tr key={No} data-rowid={'Noo'}>
							<td title={Product}>{Product}</td>
							<td title={Units}>{Units}</td>
							<td title={DiscountPerc}>{DiscountPerc}</td>
							<td title={calculatorOfSinglePrice(Price, PricewoVAT, VAT, Units, Discount)}>
								{calculatorOfSinglePrice(Price, PricewoVAT, VAT, Units, Discount)}
							</td>
						</tr>
					)
				}
			)
		}
	}
	//[Price]*{Units]*(100-{Discount})/100}

	calculateSummaryTotal = (Price, Units, Discount, index) => {
		const price = Price * Units * (100 - Discount) / 100;
		const rounded = Math.round((price + Number.EPSILON) * 100) / 100;

		return rounded.toFixed(2);
	}


	inputSummaryTablefunction = () => {
		return null;
	}

	NewSaleClientDataHeader = () => {
		let first_line = this.state.ClientDataHeader['Client_data'];

		return (
			<div className= 'NewChangeClientDataHeader'>
				<span> {first_line} </span>
			</div>
		)
	}

	NewSaleClientDataEmail = () => {
		return (
			<div className='NewChangeClientDataEmail'>
				{this.NewSaleClientDataMessageEmail()}
				{this.NewSaleClientDataFormEmail()}
			</div>
		)
	}


	NewSaleClientDataMessageEmail = () => {
		return (
			<span className='NewSaleClientDataMessageEmail'> {'E-mail address'} </span>
		)
	}

	NewSaleClientDataFormEmail = () => {

		if (this.state.PopUpMoreInfo !== null) {

			if (isUserACompany()) {

				const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
				console.log(Suboperation_data);
				return (
					<div className="NewChangeClientDataFormEmailPrevious" title={Suboperation_data.Client}> {Suboperation_data.Client}</div>
				)
			} else {
				return (
					<div className="NewChangeClientDataFormEmailPrevious" title={this.state.Profile.Person.Email} > {this.state.Profile.Person.Email}</div>
				)
			}
		}

		else {

			// The input field in case there's no error
			let classN = 'NewSaleClientDataFormEmail';
			// Check if the input field must be red (due to an error in the input introduced).

			if (this.state.PressedEndButton === true && this.state.OperationsErrors.ClientEmail === true ) {
				classN = 'NewSaleClientDataFormEmail2';
			}

			return (
				<Form.Control
					className={classN}
					autoFocus
					type="email"
					onChange={(e) => this.setEmail(e.target.value)}
				/>
			)
		}
	}

	setEmail(event) {

		this.evaluateInputInSales('ClientEmail', null, null, event);
		// Sets the e-mail address of the client to the State
		this.setState(
			Object.assign(this.state.Client[0], {Email_address: format_email_address(event) })
		);
		return null;
	}


	NewSaleMessage = () => {

		if (this.state.PopUpMoreInfo !== null) {
			return (
				<div className='NewSaleMessage'>
			<span>
				{'Previous Sale'}
			</span>
				</div>
			)

		} else {
			return (
				<div className='NewSaleMessage'>
			<span>
				{'New Sale'}
			</span>
				</div>
			)
		}
	}

	NewCompanyMessage = () => {

		// if (this.state.PopUpMoreInfo !== null) {
		// 	return (
		// 		<div className='NewSaleMessage'>
		// 	<span>
		// 		{'Previous Sale'}
		// 	</span>
		// 		</div>
		// 	)
		//
		// } else {
			return (
				<div className='NewSaleMessage'>
			<span>
				{'New Company'}
			</span>
				</div>
			)
		// }
	}

	NewSaleID = () => {

		if (this.state.PopUpMoreInfo !== null) {
			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);

			return (
				<div className='NewSaleID'>
					<span className='NewSaleIDIDOld'>
						{'ID'}
					</span>
					<span className='NewSaleIDNo2'>
						{Relevant_Operation.ID}
					</span>
				</div>
			)

		} else {
			return (
				<div className='NewSaleID'>
					<span className='NewSaleIDID'>
						{'ID'}
					</span>

					<div className='verticalSeparatorFirstBoxes'> </div>

					<div className='NewSaleIDNo'>
						<div className='NewSaleIDNoID'> {this.state.New_ID_Provider_Operation} </div>
					</div>
					<div className='horizontalSeparatorFirstBoxes'> </div>
				</div>
			)
		}
	}


	renderDropDownEmployees = () => {
		var array1 = [];
		var array_id = [];

		const Employees = this.state.Profile.Employees;

		console.log(Employees);

		//Prepare the required fields to be displayed
		Employees.forEach(Employee => {
			let employee_name = Employee.Name;
			if (employee_name === null) { employee_name = ''}
			let employee_surname = ' ' + Employee.Surname;
			if (Employee.Surname === null) { employee_surname = ''}

			var Employee_identity = employee_name + employee_surname;
			var Employee_ID = Employee.ID
			if (Employee_identity !== 'null null') {
				array1.push(Employee_identity);
				array_id.push(Employee_ID);
			}
		})

		//Get the set of elements
		var set_Employees = array1.filter((item, i, ar) => ar.indexOf(item) === i);
		var set_Employee_IDs = array_id.filter((item, i, ar) => ar.indexOf(item) === i);
		// console.log(set_Employee_IDs);

		var array_joined = [];

		//Join the names and the IDs
		for (let i = 0; i < set_Employee_IDs.length; i++) {
			array_joined.push({Employee_name: set_Employees[i], Employee_ID: set_Employee_IDs[i]});
		}
		// console.log(array_joined);

		return (array_joined);
	}


	clickOnEmployeeNameDropdown = (event) => {
		console.log(event.currentTarget);
		const employee_id = event.currentTarget.getAttribute("employeeID");
		const employee_name = event.currentTarget.getAttribute("employeeName");
		//console.log(this.state.Profile.Employees);

		//console.log(this.state.Employee_ID);
		this.setState({Employee_ID: employee_id});
		this.setState({EmployeeChosen: employee_name});

		return null;
	}

	renderDropDownShops = () => {
		var array1 = [];
		var array_id = [];

		const Shops = this.state.Profile.Shops;
		// console.log(Shops);

		//Prepare the required fields to be displayed
		Shops.forEach(Shop => {
			var Shop_identity = Shop.Name;
			var Shop_ID = Shop.ID;
			if (Shop_identity !== null) {
				array1.push(Shop_identity);
				array_id.push(Shop_ID);
			}
		})

		//Get the set of elements
		var set_Shops = array1.filter((item, i, ar) => ar.indexOf(item) === i);
		var set_Shops_IDs = array_id.filter((item, i, ar) => ar.indexOf(item) === i);

		// console.log(set_Shops)
		// console.log(set_Shops_IDs)

		var array_joined = [];

		//Join the names and the IDs
		for (let i = 0; i < set_Shops_IDs.length; i++) {
			array_joined.push({Shop_name: set_Shops[i], Shop_ID: set_Shops_IDs[i]});
		}

		// Return only the names of the Shops
		// console.log(set_Shops);

		return(array_joined);

		// var array2 = [];
		//
		// array_joined.forEach(array_joined_sub => {
		// 	array2.push(
		// 		<div className='elementInShopDropDown'>
		// 			<Dropdown.Item eventKey="1" className='dropDownIndividual`Shop'
		// 						   shopName={array_joined_sub.Shop_name} shopID={array_joined_sub.Shop_ID}
		// 						   onClick={(e) => this.clickOnShopNameDropdown(e)}>{array_joined_sub.Shop_name}</Dropdown.Item>
		// 		</div>
		// 	)
		// })
		//
		// return array2

		// return array_joined;
	}


	clickOnShopNameDropdown = (event) => {
		const shop_ID = event.currentTarget.getAttribute("shopID");
		const Shop_name = event.currentTarget.getAttribute("shopName");

		//console.log(this.state.Shop_ID);
		this.setState({Shop_ID: shop_ID});
		this.setState({ShopChosen: Shop_name})

		return null;
	}


	NewSaleShop = () => {
		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);
			return (
				<div className='NewSaleShop'>
					<span className='NewSaleShopShopOld'>
						{'Shop'}
					</span>
					<span className='NewSaleShopShopNo' title={Relevant_Operation.Shop}>
						{Relevant_Operation.Shop}
					</span>
				</div>
			)
		} else {
			let inputsArrow = 'dropDownInMyProfileNewSale';

			return (
				<div className='NewSaleShop'>
				<span className='NewSaleShopShop'>
					{'Shop'}
				</span>

					<div className='verticalSeparatorFirstBoxes'> </div>

					<Button className='NewSaleShopButton' block size="lg"
							onClick={(e) =>
								this.activateDropDown('ShopTable')}
					> <div className='renderMyCompanyRightInputDropdownText' >{this.state.ShopChosenTable}</div>
						<img className={inputsArrow} alt=""/>
					</Button>
					{this.DropDownAttribute('ShopTable', null, null, null, null )}
				</div>
			)
		}
	}


	NewSaleEmployee = () => {

		if (this.state.PopUpMoreInfo !== null) {
			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);
			return (
				<div className='NewSaleEmployee'>
					<span className='NewSaleEmployeeEmployeeOld'>
						{'Employee'}
					</span>
					<span className='NewSaleEmployeeEmployeeNo' title={Relevant_Operation.Employee}>
						{Relevant_Operation.Employee}
					</span>
				</div>
			)

		} else {
			let inputsArrow = 'dropDownInMyProfileNewSale';
			return (
				<div className='NewSaleEmployee'>
					<span className='NewSaleEmployeeEmployee'>
						{'Employee'}
					</span>
					<div className='verticalSeparatorFirstBoxes'> </div>
					<Button className='NewSaleEmployeeButton' block size="lg"
							onClick={(e) =>
								this.activateDropDown('EmployeeTable')}
					> <div className='renderMyCompanyRightInputDropdownText' >{this.state.EmployeeChosenTable}</div>
						<img className={inputsArrow} alt=""/>
					</Button>
					{this.DropDownAttribute('EmployeeTable', null, null, null, false)}
					<div className='horizontalSeparatorSecondBoxes'> </div>

				</div>
			)
		}
	}

	activateDropDown = (field) => {

		if (field === 'Employee') {
			if (this.state.DropDownAttribute === 'Employee') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'Employee'});
			}
		}
		if (field === 'Shop') {
			if (this.state.DropDownAttribute === 'Shop') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'Shop'});
			}
		}
		if (field === 'EmployeeTable') {
			if (this.state.DropDownAttribute === 'EmployeeTable') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'EmployeeTable'});
			}
		}
		if (field === 'ShopTable') {
			if (this.state.DropDownAttribute === 'ShopTable') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'ShopTable'});
			}
		}
		if (field === 'OperationType') {
			if (this.state.DropDownAttribute === 'OperationType') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'OperationType'});
			}
		}
		// console.log(this.state.DropDownAttribute);
		//
		// this.resetDropDownAttributeInState();

		return null;
	}

	resetDropDownAttributeInState = () => {

		console.log(this.state.DropDownAttribute);
		if (this.state.DropDownAttribute !== null) {
			this.setState({DropDownAttribute: null});
		}

		return null;
	}

	NewSaleDate = () => {
		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);
			return (
				<div className='NewSaleDatePrevious'>
					<span> {Relevant_Operation.Info.Sales_info.Sale_data[0].Product_data.Screen_datetime} </span>
				</div>
			)
		} else {
			return (
				<div className='NewSaleDate'>
				<span className='NewSaleDateDate'>
					{this.state.ScreenDatetime}
				</span>
				</div>
			)
		}
	}

	NewSaleShipping = () => {

		//Need for showing Shipping in previous Sale
		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			console.log(Relevant_Operation);

			let ccy = null;
			if (isUserACompany()) {
				ccy =this.state.Profile.Company.Currency;
			} else {
				if (Relevant_Operation.Suboperation_type === "1") {
					ccy = Relevant_Operation.Info.Sales_info.Sale_data[0].Product_data.Currency;
				}
				if (Relevant_Operation.Suboperation_type === "2") {
					ccy = Relevant_Operation.Info.Changes_info.Change_data[0].Product_data.Currency;
				}
				if (Relevant_Operation.Suboperation_type === "3") {
					ccy = Relevant_Operation.Info.Returns_info.Return_data[0].Product_data.Currency;
				}
			}

			// Eliminate the currency header in case it's missing
			let shipping_name = 'Shipping (' + ccy +')   ';
			console.log(ccy);
			if (ccy === null) {
				shipping_name = 'Shipping';
			}
			return[
				<div className='NewSaleShippingPrevious'>
					<div className='NewSaleShippingNamePrevious'>{shipping_name} </div>
					<div className='NewSaleShippingValuePrevious' title={Relevant_Operation.Shipping}> {Relevant_Operation.Shipping} </div>

				</div>
			]
		} else {

			let classN = 'NewSaleShippingValue';
			// Check if the input field must be red (due to an error in the input introduced).
			if (this.state.PressedEndButton === true && this.state.OperationsErrors.Shipping === true ) {
				classN = 'NewSaleShippingValue2';
			}

			let ccy =this.state.Profile.Company.Currency;
			// Eliminate the currency header in case it's missing
			let shipping_name = 'Shipping (' + ccy +')   ';

			if (ccy === null) {
				shipping_name = 'Shipping';
			}

			// const shipping = 'Shipping (' + ccy + ')'
			return (
				<div className='NewSaleShipping'>
					<label className="NewSaleShippingName">{shipping_name}</label>
					<input
						className={classN}
						autoFocus
						type="text"
						onChange={(e) => this.setShipping(e.target.value)}
					/>
				</div>
			)
		}
	}

	setShipping(event) {

		this.evaluateInputInSales('Shipping', null, null, event);
		console.log(event);
		console.log(this.state.ShippingNewSale);

		this.setState({ShippingNewSale: event});
		return null;
	}

	PopUpMissingData = () => {
		return (
			<div className='PopUpMissingData'>
				<div className='PopUpMissingDataInside'>
					{'Please, note that some information critical to the correct behaviour of this application and to ensure ' +
					'the expected security to your customers is still pending for submission.\r\n' +
					'You can navigate this tool but please provide this information on My account tab before using it on your ' +
					'daily commercial activity.'}
					{this.missingName()}
					{this.missingCountry()}
					{this.missingRegion()}
					{this.missingProvince()}
					{this.missingAddress()}
					{this.missingPostal_code()}
					{this.missingTFN()}
					{this.missingCurrency()}
					{this.missingSector()}
					{this.missingSubsector()}
				</div>
				<Button className="UnderstandMissingInformation" block size="lg" onClick={this.PopUpMissingDataAccepted}>
					{'I understand'}
				</Button>
			</div>
		)
	}

	missingTFN = () => {
		if (this.state.Company.TFN === undefined || this.state.Company.TFN === null
			|| this.state.Company.TFN === "0" || this.state.Company.TFN === " " || this.state.Company.TFN === "") {
			return (
				<div>
					{' - TFN is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingName = () => {
		if (this.state.Company.Name === undefined || this.state.Company.Name === null
			|| this.state.Company.Name === "" || this.state.Company.Name === " ") {
			return (
				<div>
					{' - Name is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingAddress = () => {
		if (this.state.Company.Address === undefined || this.state.Company.Address === null
			|| this.state.Company.Address === "" || this.state.Company.Address === " ") {
			return (
				<div>
					{' - Address is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingCountry = () => {
		if (this.state.Company.Country === undefined || this.state.Company.Country === null
			|| this.state.Company.Country === "" || this.state.Company.Country === " ") {
			return (
				<div>
					{' - Country is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingCurrency = () => {
		if (this.state.Company.Currency === undefined || this.state.Company.Currency === null
			|| this.state.Company.Currency === "" || this.state.Company.Currency === " ") {
			return (
				<div>
					{' - Currency is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingPostal_code = () => {
		if (this.state.Company.Postal_code === undefined || this.state.Company.Postal_code === null
			|| this.state.Company.Postal_code === "" || this.state.Company.Postal_code === " ") {
			return (
				<div>
					{' - Postal code is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingProvince = () => {
		if (this.state.Company.Province === undefined || this.state.Company.Province === null
			|| this.state.Company.Province === "" || this.state.Company.Province === " ") {
			return (
				<div>
					{' - Province is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingRegion = () => {
		if (this.state.Company.Region === undefined || this.state.Company.Region === null
			|| this.state.Company.Region === "" || this.state.Company.Region === " ") {
			return (
				<div>
					{' - Region is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingSector = () => {
		if (this.state.Company.Sector === undefined || this.state.Company.Sector === null
			|| this.state.Company.Sector === "" || this.state.Company.Sector === " ") {
			return (
				<div>
					{' - Sector is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}

	missingSubsector = () => {
		if (this.state.Company.Subsector === undefined || this.state.Company.Subsector === null
			|| this.state.Company.Subsector === "" || this.state.Company.Subsector === " ") {
			return (
				<div>
					{' - Subsector is missing.'}
				</div>
			)
		} else {
			return null;
		}
	}


	PopUpMissingDataAccepted = () => {

		// Remove the props state if the user has come from SignUp
		this.setState(
			Object.assign(this.state.Props.location, {popup: null}));
		// The user has acknowledged he has to provide further information before operating, if insufficient info.
		this.setState({TerciaryStatus: this.state.TerciaryCode.None});

		return null;
	}




	wrapperRenderHistoricTable = () => {

		let HistoricalOperationsTable = 'HistoricalOperationsTable';
		let HistoricalOperationsTableHeader = 'HistoricalOperationsTableHeader';
		let HistoricalOperationsTableTBody = 'HistoricalOperationsTableTBody';
		if (isUserAPSI()) {
			HistoricalOperationsTable = 'HistoricalOperationsTablePSI';
			HistoricalOperationsTableHeader = 'HistoricalOperationsTableHeaderPSI';
			HistoricalOperationsTableTBody = 'HistoricalOperationsTableTBodyPSI';
		}

		let parentDiv = document.getElementsByClassName('BackgroundMyArea');
		// console.log(parentDiv.clientHeight)
		let tableHeight = 0;

		if (parentDiv.length > 0) {
			console.log(parentDiv[0].clientHeight)
			tableHeight = parentDiv[0].clientHeight - 260;
		}

		return (
			<div className='HistoricalOperationsDiv' style={{height: tableHeight}}>
				{this.filterHistoricalData()}
				<Table className={HistoricalOperationsTable}>
					<thead className={HistoricalOperationsTableHeader}>
					<tr>{this.renderHistoricalTableHeader()}</tr>
					</thead>
					<tbody className={HistoricalOperationsTableTBody}>
					{this.renderHistoricalTableData()}
					</tbody>
				</Table>
			</div>
		)
	}

	wrapperRenderSaleTable = () => {
		return(
			<div className='SaleTableContainer' >
				<table className='SaleTable'>
					<thead className='SaleTableHeader'>
					<tr>{this.renderSaleTableHeader()}</tr>
					</thead>
					<tbody className='SaleTableBody'>
					{this.decisionMakingHowManyRowsForSale()}
					</tbody>
				</table>
			</div>
		)
	}

	wrapperRenderNewCompaniesTable = () => {

		return(
			<div>
				<table className='NewCompaniesTable'>
					<thead className='NewCompaniesTableHeader'>
						<tr>{this.renderNewCompaniesTableHeader()}</tr>
					</thead>
					<tbody className='NewCompaniesTableBody'>
						{this.decisionMakingHowManyRowsForNewCompanies()}
					</tbody>
				</table>
			</div>
		)
	}


	Obscurer = () => {
		return (
			<div className='Obscurer'>
				{' '}
			</div>
		)
	}


	dataPreparationWrapper = () => {


		let Profile_retrieved = JSON.parse(localStorage.getItem("Profile_retrieved"));
		// console.log(Profile_retrieved);
		let YY = JSON.parse(localStorage.getItem("Operations_output"));
		// console.log(YY);

		let judgeInsufficientInformation = this.JudgeInsufficientInformation(Profile_retrieved);


		if (isUserACompany()) {
			this.setState(
				Object.assign(this.state.Profile, {Company: Profile_retrieved.Company}),
				() => {

					this.setState(
						Object.assign(this.state.Profile, {Employees: Profile_retrieved.Employees}), () => {

							this.setState(
								Object.assign(this.state.Profile, {Shops: Profile_retrieved.Shops}), () => {

									this.setState(
										Object.assign(this.state, {TerciaryStatus: judgeInsufficientInformation}), () => {

											this.setState({History_data: YY}, () => {

												this.OrganizeOperation()
											})

										})
								})
						})
				})
			;
		}

		else {
			if (isUserAPSI()) {
				this.setState(
					Object.assign(this.state.Profile, {Company: Profile_retrieved.Company}),
					() => {
							this.setState(
								Object.assign(this.state, {TerciaryStatus: judgeInsufficientInformation}), () => {

									this.setState({History_data: YY}, () => {
										this.OrganizeProviders()
									})
								})
						}) ;

			} else {
				let Operations = YY;

				let readEmployeesAndShops = this.ReadEmployeesAndShops(Operations);
				// console.log(readEmployeesAndShops);
				this.setState(
					Object.assign(this.state.Profile, {Person: Profile_retrieved.Person}),
					() => {

						this.setState(
							Object.assign(this.state.Profile, {Employees: readEmployeesAndShops[0]}), () => {

								this.setState(
									Object.assign(this.state.Profile, {Shops: readEmployeesAndShops[1]}),
									() => {
										this.setState({History_data: YY}, () => {
											this.OrganizeOperation()
										})
									})
							})
					})
				;
			}
		}

		return null;
	}



	JudgeInsufficientInformation = (profile) => {
		// Judges if any special popup should be provided when user opens the current webpage


		// TerciaryCode: {None: "0", MissingData: true, Insufficient: "1", Sufficient: "2"},

		// console.log(profile);
		if (isUserACompany()) {
			let company = profile.Company;
			if (company.Address === '' || company.Address === null || company.Country === '' || company.Country === null ||
				company.Currency === '' || company.Currency === null || company.Name === '' || company.Name === null ||
				// company.Phone_number === '' || company.Phone_number === null || company.Phone_prefix === '' || company.Phone_prefix === null ||
				company.Postal_code === '' || company.Postal_code === null || company.Province === '' || company.Province === null ||
				company.Region === '' || company.Region === null || company.Sector === '' || company.Sector === null ||
				company.Subsector === '' || company.Subsector === null || company.TFN === '' || company.TFN === null) {

				// Set the Sale button to disabled:
				this.setState({missingCrucialData: true});

				return this.state.TerciaryCode.Insufficient;
			} else {
				return this.state.TerciaryCode.None;
			}
		} else {
			this.setState({TerciaryStatus: this.state.TerciaryCode.None});
			return this.state.TerciaryCode.None;
		}



	}


	TimerToJWTRenewal2(expiration) {
		var timer = 0;
		// console.log(expiration === '870');

		if (expiration !== null && +expiration !== 0) {
			timer = expiration;
		} else {
			timer = getCookie('jwt_expiration');
		}

		// console.log(timer);

		function CallToRegenerateJWT() {
			let Rend = new Renderizar();
			console.log('Nueva llamada en LandingPage');
			Rend.regenerate_initial_jwt();
			return null;
		}

		var interval = setInterval(CallToRegenerateJWT, +timer * 1000);
		// console.log(interval)

		// this.setState({JWTIntervalID: interval});

		return interval;
	}

	intermediateJWT = async () => {

		await this.start_jwt_creation_and_regeneration();
		// console.log('intermediateJWT');
		return null;
	}


	start_jwt_creation_and_regeneration  = async () => {

		let initial_JWT = function () {
			return create_initial_JWT_api().then(json => {
				return json
			});
		}

		let initialjwt =  initial_JWT();

		let jwtExecution = initialjwt.then((response) => {

				return null;
			}
		)

		await jwtExecution;

		// this.setState({PrimaryStatus: this.state.PrimaryCode.Load});

		// this.get_expiration_cookie();

		// console.log('start_jwt_creation_and_regeneration');

		// console.log(window.localStorage.getItem('Interval_ID')  );

		// If the JWT is not already continuously recreated, start the process now.
		if (window.localStorage.getItem('Interval_ID') !== null ) {
			// console.log(window.localStorage.getItem('Interval_ID'))
			clearInterval(window.localStorage.getItem('Interval_ID'))
			localStorage.removeItem('Interval_ID')
		}

		let jwtIntervalID = this.TimerToJWTRenewal2(getCookie('jwt_expiration'));

		localStorage.setItem('Interval_ID', jwtIntervalID);

		this.setState({PrimaryStatus : this.state.PrimaryCode.Load});
		// console.log('this.state.PrimaryCode')

		return null;
	}


	legalTextContainers = () => {

		let divCookiesSelection = 'legalTextContainers22';
		let divPrivacyPolicy = 'legalTextContainers32';
		let divGTC = 'legalTextContainers42';

		let TIDIName = '© 2021 The TIDI Systems GmbH. All rights reserved.'

		return (
			<div className='legalTextContainers'>
				<Button className="legalTextContainers1"  onClick={() => {
					this.requiringSomeLegalText('legalNotice')
				}}>
					{'Legal notice'}
				</Button>
				<Button className={divCookiesSelection} block size="lg" onClick={() => {
					this.requiringSomeLegalText('cookiesSelection')
				}}>
					{'Cookies selection'}
				</Button>
				<Button className={divPrivacyPolicy} block size="lg" onClick={() => {
					this.requiringSomeLegalText('privacyPolicy')
				}}>
					{'Privacy policy'}
				</Button>
				<Button className={divGTC} block size="lg" onClick={() => {
					this.requiringSomeLegalText('GTC')
				}}>
					{'General terms and conditions'}
				</Button>

				<Button className='ContactSupport' block size="lg" onClick={() => {
					this.requiringSomeLegalText('ContactSupport');
					this.setState({ErrorMessage: null});
				}}>
					{'Contact support'}
				</Button>


				<Button className='licensesButton' block size="lg" onClick={() => {
					this.requiringSomeLegalText('Licenses')
				}}>
					{'Third-party open source licenses'}
				</Button>

				<Button className='TIDIName' block size="lg"> {TIDIName} </Button>
			</div>
		)
	}


	requiringSomeLegalText = (legalText) => {

		this.setState({whichLegalTextIsRequired: legalText})

		return null;

	}

	showLegalText = () => {

		let legalText = this.state.whichLegalTextIsRequired;
		let thisDiv = 'showLegalTextBig';
		if (legalText === 'cookiesSelection') {
			thisDiv = 'showLegalTextSmall'
		}
		if (legalText === 'ContactSupport') {
			thisDiv = 'showLegalTextDeciderTitleDivSupportLandingPage'
		}

		if (legalText !== null) {

			return [
				this.Obscurer(),
				<div className={thisDiv}>
					{this.showLegalTextDecider()}
					{this.buttonCloseLegalText()}
				</div>
			]
		}
		return null;
	}


	showLegalTextDecider = () => {
		let legalText = this.state.whichLegalTextIsRequired;

		var showLegalTextDeciderTitle = null;
		var showLegalTextDeciderText = null;
		var showLegalTextDeciderTitleDiv = 'showLegalTextDeciderTitle';
		var showLegalTextDeciderTextDiv = 'showLegalTextDeciderTextMyArea';
		var showLegalTextDeciderTextMyAreaTextInterior = 'showLegalTextDeciderTextMyAreaText';

		if (legalText === 'legalNotice') {
			let buttonPrivacy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'})
			}}>
				{'Privacy policy'}
			</span>;

			let buttonCookiesPolicy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>;
			showLegalTextDeciderTitle = legalNoticeTitle;
			showLegalTextDeciderText = legalNoticeText(buttonPrivacy, buttonCookiesPolicy);
		}
		if (legalText === 'cookiesSelection') {
			showLegalTextDeciderTitle = this.cookiesMenuChangePreferencesTitle();
			// showLegalTextDeciderText = 'text';
			showLegalTextDeciderText = this.CookiesMenuChangePreferences();
		}
		if (legalText === 'cookiesDetail') {
			showLegalTextDeciderTitle = 'Cookies';
			showLegalTextDeciderText = 'cookies text';
		}
		if (legalText === 'privacyPolicy') {
			showLegalTextDeciderTitle = privacyPolicyTitle;
			let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policys'}
			</span>
			showLegalTextDeciderText = privacyPolicyText(privacyPolicyButton1);
		}
		if (legalText === 'GTC') {
			let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>
			let licensesButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'Licenses'})
			}}>
				{'Open Source licenses'}
			</span>
			let buttonPrivacy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'})
			}}>
				{'Privacy policy'}
			</span>

			showLegalTextDeciderTitle = decideWhichGTCToDisplay(licensesButton1, privacyPolicyButton1, buttonPrivacy).GTCTitle;
			showLegalTextDeciderText = decideWhichGTCToDisplay(licensesButton1, privacyPolicyButton1, buttonPrivacy).GTCText;
		}
		if (legalText === 'Licenses') {
			showLegalTextDeciderTitle = licensesTitle;
			showLegalTextDeciderText = this.allLicenseTextsButtons();
		}
		if (legalText === 'ContactSupport') {
			showLegalTextDeciderTitleDiv = 'showLegalTextDeciderTitleDivSupportTitle';
			showLegalTextDeciderTextDiv = 'showLegalTextDeciderTextDivSupport';
			showLegalTextDeciderTitle = <b>CONTACT SUPPORT</b>;
			showLegalTextDeciderText = 'We are more than glad to give you a hand! Please, write an e-mail to hola@tidisystems.com to reach out for help :)';
			showLegalTextDeciderTextMyAreaTextInterior = 'showLegalTextDeciderTextDivSupportInterior';
		}


		return (
			<div className='showLegalTextDecider'>
				<div className={showLegalTextDeciderTitleDiv}>{showLegalTextDeciderTitle}</div>
				<div className={showLegalTextDeciderTextDiv}>
					<div className={showLegalTextDeciderTextMyAreaTextInterior}>{showLegalTextDeciderText}</div>
				</div>
			</div>
		)
	}

	tableAnalyticsCookies = (type) => {
		return(
			<div className='tableAnalyticsCookies'>
				<Table  className='tableAnalyticsCookiesTable'>
					<thead className='tableAnalyticsCookiesTableHeader'>
					<tr>{this.rendertableAnalyticsCookiesTableHeader()}</tr>
					</thead>
					<tbody className='tableAnalyticsCookiesTableTBody'>
					{this.rendertableAnalyticsCookiesTableData(type)}
					</tbody>
				</Table>
			</div>
		)
	}

	rendertableAnalyticsCookiesTableHeader = () => {

		var first_line = this.state.analyticsCookiesHeader[0];

		if (first_line === undefined) {
			return null;
		} else {
			console.log(first_line)
			let header = Object.values(first_line)
			return header.map((key, index) => {
				return <th key={index}>{key}</th>
			})
		}
	}


	rendertableAnalyticsCookiesTableData = (type) => {

		let tableCookiesBody = Object.values(this.state.analyticsCookiesBody);
		if (type === 'technical') {
			console.log('hol')
			tableCookiesBody = Object.values(this.state.technicalCookiesBody);
		}
		return tableCookiesBody.map((eachRow, index) => {
			let values = Object.values(eachRow);
			const [
				ID, Name, Provider, Goal, Duration
			] = values //destructuring

			return (
				<tr key={ID}>
					<td>{Name}</td>
					<td>{Provider}</td>
					<td>{Goal}</td>
					<td>{Duration}</td>
				</tr>
			)
		})
	}

	changeStateLandingPageForLicenses = (value) => {

		this.setState({licenseText: value});

		return null;

	}


	allLicenseTextsButtons = () => {
		// Returns all buttons of licenses texts

		let licensesButtonsClass = new renderLicensesButtons;
		let licensesButtons = licensesButtonsClass.buttonObjects(this.changeStateLandingPageForLicenses);

		let licenseIntroText = <div className='allLicenseTextsButtonsIntro'>{licensesIntroText}</div>;
		let licensesButtonMIT = licensesButtons['licensesButtonMIT'];
		let licensesButtonBSD2 = licensesButtons['licensesButtonBSD2'];
		let licensesButtonApache2 = licensesButtons['licensesButtonApache2'];
		let licensesButtonBSD3 = licensesButtons['licensesButtonBSD3'];
		let licensesButtonHPND = licensesButtons['licensesButtonHPND'];
		let licensesButtonISC = licensesButtons['licensesButtonISC'];
		let licensesButtonPostgreSQL = licensesButtons['licensesButtonPostgreSQL'];
		let licensesButtonPython = licensesButtons['licensesButtonPython'];
		let licensesButtonUnlicense = licensesButtons['licensesButtonUnlicense'];
		let licensesButtonZope = licensesButtons['licensesButtonZope'];

		let allLicenseButtons = <div className='allLicenseTextsButtonsButtons'>
			{licensesButtonApache2}
			{licensesButtonBSD2}
			{licensesButtonBSD3}
			{licensesButtonHPND}
			{licensesButtonISC}
			{licensesButtonMIT}
			{licensesButtonPostgreSQL}
			{licensesButtonPython}
			{licensesButtonUnlicense}
			{licensesButtonZope}
		</div>
		let licenseAnyText = <div className='allLicenseTextsButtonsText'>{this.decideLicenseText()}</div>;

		return <div className='allLicenseTextsButtons'>
			{licenseIntroText}
			{allLicenseButtons}
			{licenseAnyText}
		</div>
	}


	decideLicenseText = () => {

		let text = this.state.licenseText;
		let Text = null;
		if (text === 'MIT') {
			Text = licenseMITText;
		}
		if (text === 'BSD2') {
			Text = licenseBSD2ext;
		}
		if (text === 'Apache2') {
			Text = licenseApache2Text;
		}
		if (text === 'BSD3') {
			Text = licenseBSD3Text;
		}
		if (text === 'HPND') {
			Text = licenseMITText;
		}
		if (text === 'ISC') {
			Text = licenseISCText;
		}
		if (text === 'PostgreSQL') {
			Text = licensePostgreSQLText;
		}
		if (text === 'Python') {
			Text = licensePythonSFText;
		}
		if (text === 'Unlicense') {
			Text = licenseUnlicenseText;
		}
		if (text === 'Zope') {
			Text = licenseZopePLText;
		}

		return Text;
	}


	showCookiesDetails = () => {

		if (this.state.CookiesDetailsRequired === true) {

			var showLegalTextDeciderTitle = cookiesDetailTitle;

			let tablaTecnicas = this.tableAnalyticsCookies('technical');
			let tablaAnaliticas = this.tableAnalyticsCookies('analytics');

			let buttonCookiesSummary = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'cookiesSelection'}, () => {
					this.setState({CookiesDetailsRequired: false})
				})
			}}>
                    {'Cookies configurator'}
                </span>;
			let buttonPrivacyPolicy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'}, () => {
					this.setState({CookiesDetailsRequired: false})
				})
			}}>
                        {'Privacy policy'}
                    </span>;
			var showLegalTextDeciderText = cookiesDetailText(buttonCookiesSummary, buttonPrivacyPolicy, tablaTecnicas, tablaAnaliticas);

			return (
				<div className='showLegalTextBig'>
					<div className='showLegalTextDecider'>
						<div className='showLegalTextDeciderTitle'>{showLegalTextDeciderTitle}</div>
						<div className='showLegalTextDeciderTextMyArea'><div className='showLegalTextDeciderTextMyAreaText'> {showLegalTextDeciderText}</div></div>
					</div>
					<Button className="showCookiesDetailsCloseButton" onClick={() => {
						this.setState({CookiesDetailsRequired: false})
					}}>
						{'X'}
					</Button>
				</div>
			)
		} else {
			return null;
		}
	}



	CookiesPolicyTextContainerClose = () => {
		return (
			<Button className="CookiesPolicyTextContainerClose" onClick = {() => {this.CookiesPolicyTextContainerCloseFunction()}}>
				{'X'}
			</Button>
		)
	}

	CookiesPolicyTextContainerCloseFunction = () => {

		this.setState({CookiesDetailsRequired: false});
		return null;

	}


	buttonCloseLegalText = () => {
		var buttonCloseLegalText = 'buttonCloseLegalText';
		if (this.state.whichLegalTextIsRequired === 'ContactSupport') {
			buttonCloseLegalText = 'buttonCloseLegalTextSupport';
		}
		return(
			<div className={buttonCloseLegalText}>
				<Button className="buttonCloseLegalTextButton" block size="lg" onClick={() => {
					this.setState({whichLegalTextIsRequired: null});
					this.setState({licenseText: null})
				}}>
					{'X'}
				</Button>
			</div>
		)
	}

	CookiesMenuChangePreferences = () => {

		return [
			<div className='CookiesMenuChangePreferencesMyArea'>
				{this.cookiesMenuChangePreferencesMenu()}
				{this.buttonCookiesDetail()}
				{this.buttonAcceptCookies()}
			</div>
		]
	}


	buttonCookiesDetail = () => {
		return(
			<Button className="buttonCookiesDetailMyArea" onClick = {() => {this.setState({CookiesDetailsRequired: true})} } >
				{'Cookies policy'}
			</Button>
		)
	}

	cookiesMenuChangePreferencesTitle = () => {
		return(<div className='cookiesMenuChangePreferencesTitleMyArea'>{cookiesSummaryTitle}</div>)
	}

	cookiesMenuChangePreferencesMenu = () => {
		console.log(this.state.CookiesAccepted);
		return (
			<div className='cookiesMenuChangePreferencesMenuLandingPage'>
				<div className='cookiesMenuChangePreferencesMenuAreaLandingPage'>
					<li className='cookiesMenuChangePreferencesMenuItemMyArea'> Necessary cookies</li>
					<div className='cookiesMenuChangePreferencesMenuSpanMyArea'> {necessaryCookiesText}</div>
					<input className='toggleCookiesTechnicalMyArea' type='checkbox' defaultChecked={true} disabled={true} onChange={(event) => {this.cookiesToggleButtonLandingPage(event.target)}}/>
				</div>
				<div className='cookiesMenuChangePreferencesMenuAreaLandingPage'>
					<li className='cookiesMenuChangePreferencesMenuItemMyArea'> Analytics cookies</li>
					<div className='cookiesMenuChangePreferencesMenuSpanMyArea'> {analyticsCookiesText}</div>
					<input className='toggleCookiesMyArea' type='checkbox' defaultChecked={this.state.CookiesAccepted.Analytics} onChange={(event) => {this.cookiesToggleButtonLandingPage(event.target, 'Analytical')}}/>
				</div>
			</div>
		)
	}


	cookiesToggleButtonLandingPage  = (e, cookiesType) => {


		if (cookiesType === 'Analytical') {
			if (this.state.CookiesAccepted['Analytics'] === true) {
				this.setState(
					Object.assign(this.state.CookiesAccepted, {Analytics: false}));
			} else {
				this.setState(
					Object.assign(this.state.CookiesAccepted, {Analytics: true}));
			}
		}

		return null;
	}


	buttonAcceptCookies = () => {

		return(
			<div className='buttonAcceptCookiesDivMyArea'>
				<Button className="ContainerCookiesPolicyButtonRejectAllCMPMyArea" onClick = {() => {this.ContainerCookiesPolicyFunction('Reject')}}>
					{'Decline all'}
				</Button>
				<Button className="buttonAcceptCookiesMyArea" onClick = {() => {this.manageCookiesPreferences()}}>
					{'Accept and close'}
				</Button>
			</div>
		)
	}

	ContainerCookiesPolicyFunction = (cookiesCode) => {

		if (cookiesCode === 'Accept') {
			this.setState(
				Object.assign(this.state.CookiesAccepted, {Analytics: true}), () => {this.manageCookiesPreferences()});


			this.setState({CookiesHaveBeenAccepted: true});
			return null;
		}

		if (cookiesCode === 'Reject') {
			this.setState(
				Object.assign(this.state.CookiesAccepted, {Analytics: false}), () => {this.manageCookiesPreferences()});

			this.setState({CookiesHaveBeenAccepted: true});
			return null;
		}

		if (cookiesCode === 'ChangePreferences') {
			this.setState({CookiesMenuChangePreferences: true});
			return null;
		}

		if (cookiesCode === 'CookiesPolicyRequired') {
			this.setState({CookiesPolicyRequired: true});
			return null;
		}

	}


	manageCookiesPreferences = () => {
		// This function closes the dialogs of the cookies acceptance, retrieves the cookies information in case of
		// acceptance and sends the information to Python.
		console.log(this.state.CookiesAccepted);


		if (this.state.CookiesAccepted['Analytics'] === true) {
			// Assign the localStorage variable's value
			set_analytics_acceptance_cookie(true);
			// Retrieve the user's geography and store it in DB.
			wrapper_send_all_cookies_after_cookies_preferences_made();

		} else {
			// Assign the localStorage variable's value
			set_analytics_acceptance_cookie(false);
			// Store in DB that the user has declined analytics cookies.
			wrapper_send_all_cookies_after_cookies_preferences_made();
		}

		// Close the cookies dialog.
		this.setState({whichLegalTextIsRequired: null})

		return null;
	}


	takeUserBackToLogInIfApplicable = () => {

		let UserID = localStorage.getItem('User_ID');
		let Company = getUserType();

		if (UserID === null || UserID === undefined || Company === null || Company === undefined) {
			// Change to LandingPage
			let URL_LP = this.state.URL_LogIn;
			return <Redirect to={URL_LP}/>;
		} else {
			this.setState({PrimaryStatus: this.state.PrimaryCode.JWT})
		}
		return null;
	}



	WaitingForRequest = () => {

		if (this.state.WaitingForRequest === true) {
			return (
				<div className='loading'>
					<img className="loading-image" src={loadingPictureCode} />
				</div>
			)
		} else {return null;}
	}


	ObscurerQRReceived = () => {

		if (this.state.ObscurerQRReceived === true) {
			console.log('all crazy');
			return (
				<div className='ObscurerQRReceived'>
					{' '}
				</div>
			)
		} else {return null;}
	}


	render() {

		// console.log(this.state)
		if (this.state.Page==="History") {

			if (this.state.PrimaryStatus === this.state.PrimaryCode.IsUserSignedIn){
				// Evaluate if user should be redirected to LogIn page.
				return [<div className='BackgroundMyArea'/>,this.takeUserBackToLogInIfApplicable()];
			}

			if (this.state.PrimaryStatus === this.state.PrimaryCode.JWT){

				this.intermediateJWT();
				return <div className='BackgroundMyArea'/>;
			}

			if (this.state.PrimaryStatus === this.state.PrimaryCode.Load){
				// this.regenerating_jwt();

				// window.localStorage.setItem('Page', 'LandingPage');
				return[
					<div className='BackgroundMyArea'/>,
					<div className = "My_area_general_container">
						<this.wrapperPreRun/>
						{this.errorWhenLoading()}
					</div>
				]
			}


			if (this.state.PrimaryStatus === this.state.PrimaryCode.DataPreparation){
				// console.log('holi');
				return[
					<div className='BackgroundMyArea'/>,
					<div className = "My_area_general_container">
						{this.dataPreparationWrapper()}
					</div>
				]
			}

			if (this.state.PrimaryStatus === this.state.PrimaryCode.RenderHistorical && this.state.SecondaryStatus === this.state.SecondaryCode.None
				&& this.state.TerciaryStatus === this.state.TerciaryCode.Insufficient) {


				// // Remove the 2 localStorage variables created earlier
				remove_profile_and_operations_from_localstorage();

				return [
					<div className='BackgroundMyArea'/>,
					<div className="My_area_general_container">
						{this.HeaderLandingPage()}
						{this.buttons()}
						{this.wrapperRenderHistoricTable()}
						{this.PopUpMissingData()}
						{this.Obscurer()}
						{this.legalTextContainers()}
					</div>
				]
			}

			if (this.state.PrimaryStatus === this.state.PrimaryCode.RenderHistorical && this.state.SecondaryStatus === this.state.SecondaryCode.None
				&& this.state.TerciaryStatus === this.state.TerciaryCode.None && this.state.showSalesQRCode !== this.state.showSalesQRCodeCode.Sale){

				// // Remove the 2 localStorage variables created earlier
				remove_profile_and_operations_from_localstorage();
				// console.log('quí toy')
				// console.log(window.screen.availHeight)
				// console.log(window.screen.height)

				let parentDiv = document.getElementsByClassName('BackgroundMyArea');
				// console.log(parentDiv.clientHeight)

				if (parentDiv.length > 0) {
					console.log(parentDiv[0].clientHeight)

				}
				return[
					<div className='BackgroundMyArea' id="backgroundIDIdentifier"/>,
					this.WaitingForRequest(),
					this.ObscurerQRReceived(),
					<div className = "My_area_general_container">
						{this.HeaderLandingPage()}
						{this.buttons()}
						{this.wrapperRenderHistoricTable()}
						{this.SalesQRCodeWindow()}
						{this.decideAnyPopupOnArrival()}
						{this.legalTextContainers()}
						{this.showLegalText()}
						{this.showCookiesDetails()}
					</div>
				]
			}
			if (this.state.PrimaryStatus === this.state.PrimaryCode.RenderHistorical && this.state.SecondaryStatus === this.state.SecondaryCode.Sale
				&& this.state.showSalesQRCode === this.state.showSalesQRCodeCode.None){

				// // Remove the 2 localStorage variables created earlier
				remove_profile_and_operations_from_localstorage();

				return[
					<div className='BackgroundMyArea'/>,
					this.WaitingForRequest(),
					this.ObscurerQRReceived(),
					<div className = "My_area_general_container">
						{this.HeaderLandingPage()}
						{this.buttons()}
						{this.wrapperRenderHistoricTable()}
						{this.renderSaleSubwindow()}
						{this.Obscurer()}
						{this.legalTextContainers()}
						{this.showLegalText()}
						{this.showCookiesDetails()}
					</div>
				]
			}

			if (this.state.PrimaryStatus === this.state.PrimaryCode.RenderHistorical && this.state.SecondaryStatus === this.state.SecondaryCode.Sale
				&& this.state.showSalesQRCode === this.state.showSalesQRCodeCode.Sale){

				// // Remove the 2 localStorage variables created earlier
				remove_profile_and_operations_from_localstorage();

				return[
					<div className='BackgroundMyArea'/>,
					this.WaitingForRequest(),
					this.ObscurerQRReceived(),
					<div className = "My_area_general_container">
						{this.HeaderLandingPage()}
						{this.buttons()}
						{this.wrapperRenderHistoricTable()}
						{this.renderSaleSubwindow()}
						{this.SalesQRCodeWindow()}
						{this.Obscurer()}
						{this.legalTextContainers()}
						{this.showLegalText()}
						{this.showCookiesDetails()}
					</div>
				]
			}
			if (this.state.PrimaryStatus === this.state.PrimaryCode.RenderHistorical && this.state.SecondaryStatus === this.state.SecondaryCode.None
				&& this.state.showSalesQRCode === this.state.showSalesQRCodeCode.None){

				// // Remove the 2 localStorage variables created earlier
				remove_profile_and_operations_from_localstorage();

				return[
					<div className='BackgroundMyArea'/>,
					this.WaitingForRequest(),
					this.ObscurerQRReceived(),
					<div className = "My_area_general_container">
						{this.HeaderLandingPage()}
						{this.buttons()}
						{this.wrapperRenderHistoricTable()}
						{this.SalesQRCodeWindow()}
						{this.Obscurer()}
						{this.legalTextContainers()}
						{this.showLegalText()}
						{this.showCookiesDetails()}
					</div>
				]
			}

			if (this.state.PrimaryStatus === this.state.PrimaryCode.RenderHistorical && this.state.SecondaryStatus === this.state.SecondaryCode.Change){

				console.log('quí toy')

				return[
					<div className='BackgroundMyArea'/>,
					this.WaitingForRequest(),
					this.ObscurerQRReceived(),
					<div className = "My_area_general_container">
						{this.HeaderLandingPage()}
						{this.buttons()}
						{this.wrapperRenderHistoricTable()}
						<NewChange data={[this.state, this.changeSecondaryStatusToNoneInChild,
							this.changeStateToUpdateTheHistoricalTable, this.ObscurerQRReceived]}/>
						{this.Obscurer()}
						{this.legalTextContainers()}
						{this.showLegalText()}
						{this.showCookiesDetails()}
					</div>
				]
			}
			if (this.state.PrimaryStatus === this.state.PrimaryCode.RenderHistorical && this.state.SecondaryStatus === this.state.SecondaryCode.Return){

				console.log('quí toy')

				return[
					<div className='BackgroundMyArea'/>,
					this.WaitingForRequest(),
					this.ObscurerQRReceived(),
					<div className = "My_area_general_container">
						{this.HeaderLandingPage()}
						{this.buttons()}
						{this.wrapperRenderHistoricTable()}
						<NewReturn data={[this.state, this.changeSecondaryStatusToNoneInChild,
							this.changeStateToUpdateTheHistoricalTable, this.ObscurerQRReceived]}/>
						{this.Obscurer()}
						{this.legalTextContainers()}
						{this.showLegalText()}
						{this.showCookiesDetails()}
					</div>
				]
			}
			if (this.state.PrimaryStatus === this.state.PrimaryCode.RenderHistorical && this.state.SecondaryStatus === this.state.SecondaryCode.NewCompany) {

				console.log('quí toy')

				return[
					<div className='BackgroundMyArea'/>,
					this.WaitingForRequest(),
					this.ObscurerQRReceived(),
					<div className = "My_area_general_container">
						{this.HeaderLandingPage()}
						{this.buttons()}
						{this.wrapperRenderHistoricTable()}
						{this.AddNewCompanyWindow()}
						{this.Obscurer()}
						{this.legalTextContainers()}
						{this.showLegalText()}
						{this.showCookiesDetails()}
					</div>
				]
			}
		}
	}
}


export default RenderMyArea;


