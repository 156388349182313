import {postData} from '../apis/rest_api.js'
import {return_null_if_empty, getUserType} from '../common/utilities.js'
import {get_Session_ID_cookies} from "./Session_cookies"

export async function Create_json_return(ID_Provider_Operation, ReturnContent, Client_info, Shipping, Employee_ID,
										 Shop_ID, ScreenDatetime, Currency) {
	
	var ID = 3;     
	// console.log(ID_Provider_Operation);
	// console.log(ReturnContent);
	// console.log(Client_info);
	// console.log(Shipping);
	// console.log(Employee_ID);
	// console.log(Shop_ID);
	
	
	// Detect which products actually require a Return.
	var effectiveReturns = [];
	
	ReturnContent.forEach(Return => {
		if (Return.Units_to_change !== 0 && Return.Units_to_change !== null && Return.Units_to_change !== "") {
			effectiveReturns.push(Return);
		}
	})
	
	var objeto_json_returns = create_subJsons_with_returns(effectiveReturns, ScreenDatetime, Currency);

	// Removing the client data as the client email will be passed by another service after 2nd release.
	var objeto_json_client = {
		// "Email": Client_info.Email
		"Email": null
	}
	
	var objeto_json_shop = {
		"Shop_ID": Shop_ID
	};
	var objeto_json_employee = {
		"Employee_ID": Employee_ID
	};
	
	var objeto_json_contenido = { 
		"ID_Provider_Operation": ID_Provider_Operation,
		"Returns": objeto_json_returns,
		"Client": objeto_json_client,
		"Employee": objeto_json_employee,
		"Shop": objeto_json_shop,
		"Shipping": Shipping
	};
	
	var objeto_json = {
		"ID": ID,
		"contenido": {
			"Return_input": objeto_json_contenido,
			"User_type": getUserType(),
			"User_ID": +localStorage.getItem('User_ID')
		},
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};
	
	console.log(objeto_json);
	
	let response = await postData(objeto_json);

	//console.log(response);

	return response;
	// return null;
	
}

function create_subJsons_with_returns(ReturnContent, ScreenDatetime, Currency) {
	
	var list = [];
	
	ReturnContent.forEach(Return => {
		
			var return_json = {
			"Units": return_null_if_empty(Return.Units_to_change),
			"Reason": return_null_if_empty(Return.Reason),
			"Return_data": {
				"Sellers_ID_Reference": return_null_if_empty(Return.Reference),
				"Purchasers_ID_Reference": null,
				"Product": return_null_if_empty(Return.Product),
				"Brand": return_null_if_empty(Return.Brand),
				"Product_Type": return_null_if_empty(Return.Type),
				"Product_Subtype": return_null_if_empty(Return.Subtype),
				"Price": return_null_if_empty(Return.Price),
				"Price_without_VAT": return_null_if_empty(Return.Price_without_VAT),
				"VAT": return_null_if_empty(Return.VAT),
				"Currency": Currency,
				"ScreenDatetime": return_null_if_empty(ScreenDatetime)
					},
				"Discount": return_null_if_empty(Return.Discount),
				"Promotion": return_null_if_empty(Return.Promotion)
			};
			
			list.push(return_json);
		})
		
	return list;
}
