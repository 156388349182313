import $ from 'jquery';

function get_user_location_Abstract() {
	var values;
	$.getJSON("https://ipgeolocation.abstractapi.com/v1/?api_key=efb8e15488bc4b41a4a39912766faeb5&ip_address=178.197.227.88", function(data) {
		console.log(values);
		return values;
		}
	);
}

async function get_user_location_IPFind() {
	var res = $.getJSON("https://ipfind.co/?ip=178.197.227.88&auth=ceb9f21b-61d9-4229-a648-c41a61e20c19", function(result){
	return result
});
var result = await res;
	console.log(result);
return result
}

export {get_user_location_IPFind, get_user_location_Abstract};