import {postData} from "../apis/rest_api"
import {get_Session_ID_cookies} from "./Session_cookies"
import {getUserType} from  "../common/utilities"

async function get_my_history() {
	var ID = 151;

	var objeto_json = {
		"ID": ID,
		"contenido": {
			"User_type": getUserType(),
			"User_ID": +localStorage.getItem('User_ID')
		},
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};
	
	//console.log(objeto_json);

	let response_Retrieve_my_history = await postData(objeto_json);
	// console.log(response_Retrieve_my_history);

	return response_Retrieve_my_history;

}


export {get_my_history};