
// Headers below
var NameAnalyticsCookiesHeader = 'Name'
var ProviderAnalyticsCookiesHeader = 'Provider'
var GoalAnalyticsCookiesHeader = 'End'
var DurationAnalyticsCookiesHeader = 'Duration'


// Analytical cookies below
var IDAnalyticsCookies1 = 1
var NameAnalyticsCookies1 = 'User_country_code'
var ProviderAnalyticsCookies1 = 'IPFind'
var GoalAnalyticsCookies1 = "User's geographical location (country)";
var DurationAnalyticsCookies1 = '24 months'

var IDAnalyticsCookies2 = 2
var NameAnalyticsCookies2 = 'User_region'
var ProviderAnalyticsCookies2 = 'IPFind'
var GoalAnalyticsCookies2 = "User's geographical location (region)";
var DurationAnalyticsCookies2 = '24 months'








// Technical cookies below
var IDTechnicalCookies1 = 1
var NameTechnicalCookies1 = 'Interval_ID'
var ProviderTechnicalCookies1 = 'Us'
var GoalTechnicalCookies1 = "Time interval's identification to relaunch the authorization request before current authorization expiration."
var DurationTechnicalCookies1 = 'Sine die'

var IDTechnicalCookies2 = 2
var NameTechnicalCookies2 = 'IP_Address'
var ProviderTechnicalCookies2 = 'Us'
var GoalTechnicalCookies2 = "unambiguous identification of users navigating in the web/platform"
var DurationTechnicalCookies2 = 'Sine die'


var IDTechnicalCookies3 = 3
var NameTechnicalCookies3 = 'User_type'
var ProviderTechnicalCookies3 = 'Us'
var GoalTechnicalCookies3 = "To classify users among as individuals, suppliers and software-providing suppliers. To discriminate the content to render to the user depending on this role."
var DurationTechnicalCookies3 = 'Sine die'

var IDTechnicalCookies4 = 4
var NameTechnicalCookies4 = 'Session_ID'
var ProviderTechnicalCookies4 = 'Us'
var GoalTechnicalCookies4 = 'Session identification'
var DurationTechnicalCookies4 = 'Sine die'

var IDTechnicalCookies5 = 5
var NameTechnicalCookies5 = 'PUN'
var ProviderTechnicalCookies5 = 'Us'
var GoalTechnicalCookies5 = "To identify the historical version of the user's profile"
var DurationTechnicalCookies5 = 'Sine die'

var IDTechnicalCookies6 = 6
var NameTechnicalCookies6 = 'Analytics_acceptance'
var ProviderTechnicalCookies6 = 'Us'
var GoalTechnicalCookies6 = "To discriminate if the user has accepted/declined that analytical cookies of his/her navigation are stored."
var DurationTechnicalCookies6 = 'Sine die'

var IDTechnicalCookies7 = 7
var NameTechnicalCookies7 = 'User_ID'
var ProviderTechnicalCookies7 = 'Us'
var GoalTechnicalCookies7 = "To store the numerical part of the user's identification. After combination with the cookie 'User_type', this cookie unambigously identifies the user in the platform."
var DurationTechnicalCookies7 = 'Sine die'

var IDTechnicalCookies8 = 8
var NameTechnicalCookies8 = 'Concatenation User_ID + User_type'
var ProviderTechnicalCookies8 = 'Us'
var GoalTechnicalCookies8 = "To safely and constantly identify the user."
var DurationTechnicalCookies8 = "Less than 15 minutes after browser's close."

var IDTechnicalCookies9 = 9
var NameTechnicalCookies9 = 'jwt_expiration'
var ProviderTechnicalCookies9 = 'Us'
var GoalTechnicalCookies9 = "To determine every how many seconds the user's identification must be renewed."
var DurationTechnicalCookies9 = "Until browser's close"

var IDTechnicalCookies10 = 10
var NameTechnicalCookies10 = 'Profile_retrieved'
var ProviderTechnicalCookies10 = 'Us'
var GoalTechnicalCookies10 = "To temporarily store the user's profile to render it in screen."
var DurationTechnicalCookies10 = '1 second'

var IDTechnicalCookies11 = 11
var NameTechnicalCookies11 = 'Operations_output'
var ProviderTechnicalCookies11 = 'Us'
var GoalTechnicalCookies11 = "To temporarily store all the historic operations."
var DurationTechnicalCookies11 = '1 second'

var IDTechnicalCookies12 = 12
var NameTechnicalCookies12 = 'Received_via'
var ProviderTechnicalCookies12 = 'Us'
var GoalTechnicalCookies12 = "To discriminate what type of device and under which way the user stablishes the connection to the front-end to display the content adequately."
var DurationTechnicalCookies12 = 'Sine die'

var IDTechnicalCookies13 = 13
var NameTechnicalCookies13 = 'Mobile'
var ProviderTechnicalCookies13 = 'Us'
var GoalTechnicalCookies13 = "To discriminate if the user expects mobile-device-like content."
var DurationTechnicalCookies13 = 'Sine die'





var analyticsCookiesHeader = [{NameAnalyticsCookiesHeader, ProviderAnalyticsCookiesHeader, GoalAnalyticsCookiesHeader, DurationAnalyticsCookiesHeader}]
var analyticsCookiesTable = [{IDAnalyticsCookies1, NameAnalyticsCookies1, ProviderAnalyticsCookies1, GoalAnalyticsCookies1, DurationAnalyticsCookies1},
    {IDAnalyticsCookies2, NameAnalyticsCookies2, ProviderAnalyticsCookies2, GoalAnalyticsCookies2, DurationAnalyticsCookies2}]
var technicalCookiesTable = [{IDTechnicalCookies1, NameTechnicalCookies1, ProviderTechnicalCookies1, GoalTechnicalCookies1, DurationTechnicalCookies1},
    {IDTechnicalCookies2, NameTechnicalCookies2, ProviderTechnicalCookies2, GoalTechnicalCookies2, DurationTechnicalCookies2},
    {IDTechnicalCookies3, NameTechnicalCookies3, ProviderTechnicalCookies3, GoalTechnicalCookies3, DurationTechnicalCookies3},
    {IDTechnicalCookies4, NameTechnicalCookies4, ProviderTechnicalCookies4, GoalTechnicalCookies4, DurationTechnicalCookies4},
    {IDTechnicalCookies5, NameTechnicalCookies5, ProviderTechnicalCookies5, GoalTechnicalCookies5, DurationTechnicalCookies5},
    {IDTechnicalCookies6, NameTechnicalCookies6, ProviderTechnicalCookies6, GoalTechnicalCookies6, DurationTechnicalCookies6},
    {IDTechnicalCookies7, NameTechnicalCookies7, ProviderTechnicalCookies7, GoalTechnicalCookies7, DurationTechnicalCookies7},
    {IDTechnicalCookies8, NameTechnicalCookies8, ProviderTechnicalCookies8, GoalTechnicalCookies8, DurationTechnicalCookies8},
    {IDTechnicalCookies9, NameTechnicalCookies9, ProviderTechnicalCookies9, GoalTechnicalCookies9, DurationTechnicalCookies9},
    {IDTechnicalCookies10, NameTechnicalCookies10, ProviderTechnicalCookies10, GoalTechnicalCookies10, DurationTechnicalCookies10},
    {IDTechnicalCookies11, NameTechnicalCookies11, ProviderTechnicalCookies11, GoalTechnicalCookies11, DurationTechnicalCookies11},
    {IDTechnicalCookies12, NameTechnicalCookies12, ProviderTechnicalCookies12, GoalTechnicalCookies12, DurationTechnicalCookies12},
    {IDTechnicalCookies13, NameTechnicalCookies13, ProviderTechnicalCookies13, GoalTechnicalCookies13, DurationTechnicalCookies13}
]

export {analyticsCookiesHeader, analyticsCookiesTable, technicalCookiesTable}