import {nonAuthPostData} from "../apis/rest_api";
import {get_Session_ID_cookies} from "./Session_cookies"
import {getUserType} from "../common/utilities"

async function create_json_sign_up_account(Email, Contra, Verification_code, New_code) {
	var ID = 51;

	var objeto_json_info_sign_up = {
		"Email": Email,
		"Contra": Contra,
		"User_type": getUserType(),
		"Verification_code": Verification_code,
		"New_code": New_code,
		"User_ID": localStorage.getItem('User_ID')
	};
	var objeto_json_contenido = { 
		"Sign_up_input": objeto_json_info_sign_up
	};
	var objeto_json = {
		"ID": ID,
		"contenido": objeto_json_contenido,
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};

	console.log(objeto_json);
	let response_sign_up = await nonAuthPostData(objeto_json);
	
	console.log(response_sign_up);
	if(response_sign_up['contenido']['Sign_up_output']['Error_code'] === 'Sign_up_1') {
		console.log('Ya existe una cuenta asociada a este dirección de correo electrónico');
	} else {

	}
	return response_sign_up;
	
}

export {create_json_sign_up_account};