import React from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Link, Redirect } from 'react-router-dom';
import "./LogIn.css";
import {create_json_log_in_account} from "../functions/Log_in_functions.js";
import {create_initial_JWT_api, regenerate_JWT} from "../functions/JWT";
import {
	email_validation,
	validate_new_password,
	validate_integers_or_null,
	getCookie,
	are_cookies_preferences_made,
	validate_string_is_not_empty_or_null,
	wrapper_send_all_cookies_after_cookies_preferences_made,
	validate_password_format,
	dont_remember_me_anymore,
	removeUserTypeAndUserID, getUserType, fixUserType, fixUserTypeToCompanyOnOpeningSignUp, format_email_address
} from "../common/utilities";
import {wrapper_send_session_cookie, wrapper_create_all_cookies_on_opening_tab, get_Session_ID_cookies
} from "../functions/Session_cookies";
import {wrapper_send_permanent_cookie, set_analytics_acceptance_cookie} from "../functions/Permanent_cookies";
import {cookiesDetailText, cookiesDetailTitle} from "../texts/Cookies_details";
import {necessaryCookiesText, analyticsCookiesText, cookiesSummaryTitle, cookiesSummaryNecessaryTitle,
	cookiesSummaryAnalyticsTitle, cookiesBannerText, cookiesSummaryTitleExplanation} from "../texts/Cookies_summary";
import {legalNoticeText, legalNoticeTitle} from "../texts/Legal_notice";
import {Table} from "react-bootstrap";
import {analyticsCookiesHeader, analyticsCookiesTable, technicalCookiesTable} from "../texts/Cookies_tables";
import {loadingPictureCode} from "../common/ajax-loader";
import {licensesIntroText, licensesTitle, renderLicensesButtons} from "../texts/Licenses";
import {licenseMITText} from "../texts/License_MIT";
import {licenseBSD2ext} from "../texts/License_BSD_2_Clause";
import {licenseApache2Text} from "../texts/License_Apache2_0";
import {licenseBSD3Text} from "../texts/License_BSD_3_Clause";
import {licenseISCText} from "../texts/License_ISC";
import {licensePostgreSQLText} from "../texts/License_PostgreSQL";
import {licensePythonSFText} from "../texts/License_Python_Software_Foundation";
import {licenseUnlicenseText} from "../texts/License_The_Unlicense";
import {licenseZopePLText} from "../texts/License_Zope_Public_License";
import {privacyPolicyText, privacyPolicyTitle} from "../texts/Privacy_Policy";
import {decideWhichGTCToDisplay} from "../texts/texts_utilities";
import EyeSlashed from "../pictures/EyeSlashed.png";
import EyeOpened from "../pictures/EyeOpened.png";


// async function validateForm() {
// 	return await Email.length > 0 && Password.length > 0;
// }

function SignUpButtonToRedirect() {
	return (
		<div className="SignUpButtonInLogIn">
			<div className='NewHere'>{'New here? Sign up for free!'}</div>
			{/*<Link to='/SignUp'>*/}
			<Link to={{pathname:'/SignUp', Coming_from_Log_In: true}}>
				<Button className="logInButton_Sign_up" block size="lg" onClick={() => {
					// Fix the user_ID = 200 (company) for this action
					fixUserTypeToCompanyOnOpeningSignUp()
				}}>
				  {'Sign up'}
				</Button>
			</Link>
		</div>
	);
}




export default class Renderizar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Need: "-1",
			// Need: "3",
			NeedCodes: {Remembered: "-1", Landing: "0", ForgotPassword: "1", ValidateCode: "2", NewContra: "3", JWT: "4", LandingPage: "5"},
			Email: null,
			Password: null,
			Verification_code: null,
			Reset: false,
			NewContra: null,
			LP: "./MyArea",
			SU: "./SignUp",
			ChangePage: false,
			ChangePageCode: {LandingPage: 'LandingPage', SignUp: 'SignUp'},
			PageCodes: {Welcome: 0, ForgotYourEmail: 1, CodeSent: 2, ResetCode: 3, ResetContra: 4, ResetDone: 5},
			Need_for: ['Code_validation'],
			RememberMe: false,
			CookiesDetailsRequired: false,
			Page: 'A',
			ErrorMessage: null,
			CookiesAccepted: {Technical: true, Analytics: false},
			CookiesMenuChangePreferences: false,
			CookiesHaveBeenAccepted: false,
			whichLegalTextIsRequired: null,
			ObscurerRequired: false,
			analyticsCookiesHeader: analyticsCookiesHeader,
			analyticsCookiesBody: analyticsCookiesTable,
			technicalCookiesBody: technicalCookiesTable,
			WaitingForRequest: false,
			passwordTypes: {input1: 'password', input2: 'password', input3:'password'}
		};

	}


	TimerToJWTRenewal2(expiration) {
		var timer = 0;
		// console.log(expiration === '870');

		if (expiration !== null && +expiration !== 0) {
			timer = expiration;
		} else {
			timer = getCookie('jwt_expiration');
		}

		// console.log(timer);

		function CallToRegenerateJWT() {
			let Rend = new Renderizar();
			Rend.regenerate_initial_jwt();
			return null;
		}
		// if (localStorage.getItem('Page') === 'LogIn'  ) {
		// if (this.state.Page === 'A'){
		var interval = setInterval(CallToRegenerateJWT, +timer * 1000);
		// }
		console.log('hola');
		// this.setState({JWTIntervalID: interval});

		return interval;
	}

	Redirect_to_LandingPage = () => {
		let URL_LP = this.state.LP;
		console.log(URL_LP);
		console.log(this.state);
		return <Redirect to={URL_LP}/>
	}

	HR() {
		return (
			<div className='Horizontal_lines_container'>
				<hr className="HR_line"/>
			</div>
		)
	}

	Redirect_to_SignUp = (data) => {
		console.log(data);
		let URL_SU = this.state.SU;
		console.log(URL_SU);
		return <Redirect to={{pathname: URL_SU,
			props: data,
			Need_for: this.state.Need_for[0]}}/>
	}

	setUserID = (user_ID) => {
		//console.log(user_ID);
		localStorage.setItem('User_ID', user_ID);

		return null;
	}

	setUserType = (user_type) => {
		// console.log(user_type);
		fixUserType(user_type);

		return null;
	}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Functions from LogIn when the user needs to change the password
	LogInStepResetContra = () => {
		return (
			<div className="Login_form2">
				<Form.Label className= "Log_in_field_name">New password</Form.Label>
					{/*<Form.Control*/}
					{/*	className="Log_in_input_field2"*/}
					{/*	autoFocus*/}
					{/*	type="password"*/}
					{/*	onChange={(e) => this.changingNewContra(e.target.value, 'First')}*/}
					{/*/>*/}
				<div className='PasswordContainerWithEyeIcon2'>
					<Form.Control
						className="Log_in_input_field2"
						type={this.state.passwordTypes.input2}
						disabled={this.state.WaitingForRequest === true}
						onChange={(e) => this.changingNewContra(e.target.value, 'First')}
					/>
					<img className="eyePassword" src={this.decideEyeIconToShow(this.state.passwordTypes.input2)} alt={''}
						 onClick={() => this.switchTypePasswordToShowContent('input2') }/>
				</div>

					<Form.Label className= "Log_in_field_name">Repeat your new password</Form.Label>
					{/*<Form.Control*/}
					{/*	className="Log_in_input_field2"*/}
					{/*	autoFocus*/}
					{/*	type="password"*/}
					{/*	onChange={(e) => this.changingNewContra(e.target.value, 'Repeat')}*/}
					{/*/>*/}
				<div className='PasswordContainerWithEyeIcon2'>
					<Form.Control
						className="Log_in_input_field2"
						type={this.state.passwordTypes.input3}
						disabled={this.state.WaitingForRequest === true}
						onChange={(e) => this.changingNewContra(e.target.value, 'Repeat')}
					/>
					<img className="eyePassword" src={this.decideEyeIconToShow(this.state.passwordTypes.input3)} alt={''}
						 onClick={() => this.switchTypePasswordToShowContent('input3') }/>
				</div>

				{this.messageNewPasswordToBeTheSame()}
				{this.incorrectFormatOfNewPassword()}

				<div className='ContainerLoginResetPasswordButton'>
					<Button className="Log_in_button" block size="lg" onClick={ () => this.buttonResetMyContra()}>
						{'Save'}
					</Button>
				</div>

			</div>
		)
	}

	changingNewContra = (event, type) =>{
		if (type === 'First') {
			this.setState({NewContra: event})
		}
		if (type === 'Repeat') {
			this.setState({NewContraRepeat: event})
		}
		this.setState({Verification_code: null})
		return null;
	}


	messageNewPasswordFormat = () => {
		return <div className='messageNewPasswordFormat'>Password must contain 8-16 characters, 1 digit and one special symbol: !@#$%^&*</div>
	}


	incorrectFormatOfNewPassword = () => {
		// Renders the message in case the new password's format is incorrect
		if (this.state.ErrorMessage === 'NewPasswordFormatIncorrect') {
			return <div className='messageNewPasswordToBeTheSame'> Please, check the format of the new password chosen.</div>
		} else {return null}
	}



	buttonResetMyContra = () => {
		// Validate the previous checks to new password setting

		//Correct format of new password
		let check_format_new_password = validate_password_format(this.state.NewContra);
		let check_contras = validate_new_password(this.state.NewContra, this.state.NewContraRepeat)

		if (!check_format_new_password) {
			this.setState({ErrorMessage: 'NewPasswordFormatIncorrect'})
		} else {
			this.setState({ErrorMessage: null}, () => {
				// Check both passwords are the same
				if (check_contras === false) {
					this.setState({ErrorMessage: 'NewPasswordIsNotTheSame'})
				}
			})

		}



		if (check_format_new_password && check_contras) {
			this.setState({ErrorMessage: null})
			this.ResetMyContra();
		}

		return null;
	}



	ResetMyContra = () => {

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		const estado = this.state;

		// If yes, then perform the request
		let AuthUser = function () {
			return create_json_log_in_account({estado}).then(json => {
				return json
			});
		}

		let userToken = AuthUser();

		// Once the response is received
		userToken.then((response) => {
			console.log(response) // "Some User token"
			let Status = response.contenido.Log_in_output.Status;
			if (Status === 'OK') {
				//console.log('Status = OK');

				// Set the User_ID
				this.setUserID(response.contenido.Log_in_output.User_ID);

				this.setUserType(response.contenido.Log_in_output.User_type);

				// Change to LandingPage
				// this.setState({Need: this.state.NeedCodes.LandingPage} , () => this.setState({ChangePage: this.state.ChangePageCode.LandingPage}));

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				if (this.state.ChangePage !== this.state.ChangePageCode.JWT) {
					this.setState({ChangePage: this.state.ChangePageCode.JWT}, () => this.wrapperTakeUserToLandingPageWithJWT());
				}

				this.setState({WaitingForRequest: false});
				return null;
			} else {
				console.log('Error handling');
				this.setState({WaitingForRequest: false});
			}
		})

		return null;

	}


	messageNewPasswordToBeTheSame = () => {

		if (this.state.ErrorMessage === 'NewPasswordIsNotTheSame') {
			return <div className='messageNewPasswordToBeTheSame'> Please, introduce the same password on both fields</div>
		} else {
			return null;
		}

	}





////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Functions from LogIn when the user has to send the validation code to validate it on the server.

	preValidateMyCode = () => {

		if (this.state.Verification_code === null || this.state.Verification_code === '') {
			this.setState({ErrorMessage: 'EmptyVerificationCode'})

		} else {
			this.setState({New_code: null}, () => {
				this.setState({Password: null}, () => {
					this.ValidateMyCode()
				})
			})
		}

		return null;
	}


	ValidateMyCode = (event) => {
		console.log(this.state)
		const estado = this.state;

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		let AuthUser = () => {
			return create_json_log_in_account({estado}).then(json => {return json}) ;
		}

		let userToken = AuthUser();

		// Once the response is received
		userToken.then((response) => {
			console.log(response) // "Some User token"
			let Status = response.contenido.Log_in_output.Status;
			if (Status === 'OK') {
				console.log('Status = OK');

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				this.setState({Need: "3"});
			} else {

				if (response.contenido.Log_in_output.Error_code === 'Log_in_5') {
					this.setState({ErrorMessage: 'IncorrectVerificationCode'})
				}

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				// Generic exception
				if (response.contenido.Log_in_output.Error_code === 'Log_in_6' || response.contenido.Log_in_output.Error_code === 'Log_in_4') {
					this.setState({ErrorMessage: 'GenericError'})
				}

			}
		})

		//event.preventDefault();
		return null;

	}

	messageIncorrectVerificationCode = () => {

		if (this.state.ErrorMessage === 'EmptyVerificationCode') {
			return <div className='messageIncorrectVerificationCode'> Verification code is empty</div>
		}

		if (this.state.ErrorMessage === 'StringInVerificationCode') {
			return <div className='messageIncorrectVerificationCode'> Please, introduce only digits</div>
		}

		if (this.state.ErrorMessage === 'IncorrectVerificationCode') {
			return <div className='messageIncorrectVerificationCode'> Incorrect verification code</div>
		} else {return null;}
	}

	LoginSendValidationCode = () => {
		return (
			<div className="Login_form2">
				<Form.Label className= "Log_in_field_name">Verification code</Form.Label>
				<br/>
				<Form.Control
					className="Log_in_input_field2"
					autoFocus
					type="text"
					onChange={(e) => this.changingVerificationCode(e.target.value)}
				/>
				{this.messageIncorrectVerificationCode()}

				<div className='ContainerLoginIForgotMyPasswordButton'>
					<Button className="Log_in_button" block size="lg" onClick={ () => this.preValidateMyCode()}>
						{'Validate code'}
					</Button>
				</div>
			</div>
		);
	}

	sendAgainButtonLogIn = () => {
			return(
				<Button className="sendAgainButtonLogIn" block size="lg" type="submit" onClick={(e) => this.changeStatusInNewCode()}>
					{'Send again'}
				</Button>
			)
		}


	changeStatusInNewCode = () => {
		this.setState({New_code: true}, () => {
			this.setState({Password: null}, () => {
				this.setState({Verification_code: null}, () => {
					this.setState({Reset: true}, () => {
						this.sendAgainAction()
					})
				})
			})
		})
	}


	sendAgainAction = () => {


		// Request a new code verification code
		console.log(this.state)


		// Render the loading icon
		this.setState({WaitingForRequest: true});
		this.setState({Password: null});

		const estado = this.state;

		let AuthUser = function () {
			return create_json_log_in_account({estado}).then(json => {
				return json
			});
		}

		let userToken = AuthUser();

		// Once the response is received
		userToken.then((response) => {
			console.log(response) // "Some User token"
			let Status = response.contenido.Log_in_output.Status;
			if (Status === 'OK') {
				console.log('Status = OK');

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				this.setState({Need: "2"});
				return null;
			} else {

				if (response.contenido.Log_in_output.Error_code === 'Log_in_3') {
					this.setState({ErrorMessage: 'EmailAddressDoesNotExist'})
				}

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				// Generic exception
				if (response.contenido.Log_in_output.Error_code === 'Log_in_6' || response.contenido.Log_in_output.Error_code === 'Log_in_4') {
					this.setState({ErrorMessage: 'GenericError'})
				}
			}
		})

		//event.preventDefault();
		return null;
	}


	changingVerificationCode = (event) =>{

		if (validate_integers_or_null(event) === true) {
			this.setState({Verification_code: event});
			this.setState({Reset: false});
			this.setState({ErrorMessage: null});

		} else {
			this.setState({ErrorMessage: 'StringInVerificationCode'});
		}
		return null;
	}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Functions from LogIn when the user has forgotten his/her password

	requestForValidationVerificationCode = () => {
		//console.log(this.state.Email);

		// Check if the input provided is an actual email address. If yes, perform the request

		let check_email = this.Email_only_validation(this.state.Email);

		if (check_email === true) {

			// Render the loading icon
			this.setState({WaitingForRequest: true});
			this.setState({Password: null});

			const estado = this.state;

			let AuthUser = function () {
				return create_json_log_in_account({estado}).then(json => {
					return json
				});
			}

			let userToken = AuthUser();

			// Once the response is received
			userToken.then((response) => {
				console.log(response) // "Some User token"
				let Status = response.contenido.Log_in_output.Status;
				if (Status === 'OK') {
					console.log('Status = OK');

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					this.setState({Need: "2"});

					// Reset the property Reset
					this.setState({Reset: false});
					return null;
				} else {

					if (response.contenido.Log_in_output.Error_code === 'Log_in_3') {
						this.setState({ErrorMessage: 'EmailAddressDoesNotExist'})
					}

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// Generic exception
					if (response.contenido.Log_in_output.Error_code === 'Log_in_6' || response.contenido.Log_in_output.Error_code === 'Log_in_4') {
						this.setState({ErrorMessage: 'GenericError'})
					}
				}
			})

			//event.preventDefault();
			return null;
		} else {
			this.setState({ErrorMessage: 'InvalidEmail'})
			return null;
		}
	}

	messageIForgotMyPassword = () => {
		if (this.state.Need === "1") {
			return (
				<div className='messageIForgotMyPassword'>
					{'Please, enter your e-mail address to recover your password. If it is linked to any registered account, you will receive a verification code.'}
				</div>
			)
		}
		if (this.state.Need === "2") {
			return (
				<div className='messageIForgotMyPassword'>
					{'We have sent a verification code to your e-mail address. Please introduce it below.'}
				</div>
			)
		}
		if (this.state.Need === "3") {
			return (
				<div className='messageIForgotMyPassword'>
					{'For security reasons, you must choose a new password to replace the old one.'}
				</div>
			)
		}
	}


	messageEmailAddressDoesNotExist = () => {

		if (this.state.ErrorMessage === 'InvalidEmail') {
			return <div className='messageEmailAddressDoesNotExist'> Invalid email </div>
		}

		if (this.state.ErrorMessage === 'EmailAddressDoesNotExist') {
			return <div className='messageEmailAddressDoesNotExist'> No account is linked to this e-mail address </div>
		} else { return null; }
	}

	LoginIForgotMyPassword = () => {
		return (
			<div className="Login_form2">
				<Form.Label className= "Log_in_field_name">Email</Form.Label>
				<input
					className="Log_in_input_field2"
					autoFocus
					type="email"
					onChange={(e) => this.setEmail(e.target.value) }
				/>

				{this.messageEmailAddressDoesNotExist()}

				<div className='ContainerLoginIForgotMyPasswordButton'>
					<Button className="LoginIForgotMyPasswordButton" block size="lg"  onClick={() => this.requestForValidationVerificationCode()}>
						{'Send code'}
					</Button>
				</div>
			</div>
		);
	}


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Functions to perform the first landing page of LogIn:
	changeStateToForgot = () => {
		console.log(this.props);
		this.props.forgot();
		return null;
	}

	redirecting = () => {
		console.log(this.props);
		//return null;
		//return this.props.red(this.props);
		return this.props.red();
	}


	setEmail = (e) => {
		this.setState({Email: format_email_address(e)});
	}

	setPassword = (e) => {
		this.setState({Password: e});
	}

	//  validateForm = async () => {
	// 	return await Email.length > 0 && Password.length > 0;
	// }


	Email_and_password_validation = () => {
		// Checks if the e-mail address provided is valid or not. Returns True if successful
		let val = email_validation(this.state.Email);
		let val2 = validate_string_is_not_empty_or_null(this.state.Password);

		if (val === false){
			this.setState({ErrorMessage: 'InvalidEmail'});
		} else {
			if (val2 === false){
				this.setState({ErrorMessage: 'EmptyPassword'});
			} else {

			}

		}
		return val && val2;
	}

	Email_only_validation = () => {
		// Checks if the e-mail address provided is valid or not. Returns True if successful
		let val = email_validation(this.state.Email);

		if (val === false){
			this.setState({ErrorMessage: 'InvalidEmail'});
		}

		return val;
	}

	wrapperLogInCookies = () => {
		// Updates the permanent and session cookies with the user_id and the UserType boolean

		// Update the permanent cookie with the user_id and the UserType
		wrapper_send_permanent_cookie();

		// Update the session cookie with the user_id and the UserType
		wrapper_send_session_cookie(null, false, false, false);

		return null;
	}


	submitLogIn = (event) => {
		// console.log('lol')

		// Check first if the e-mail address introduced is valid. If yes, perform the request.
		let email_validation = this.Email_and_password_validation();

		if (email_validation === true) {
			// Render the loading icon
			this.setState({WaitingForRequest: true});

			const estado = this.state;

			//Calling the API
			let AuthUser = () => {
				return create_json_log_in_account({estado}).then(json => {
					return json
				});
			}

			let userToken = AuthUser()
			//console.log(userToken) // Promise { <pending> }

			// Once the response is received
			userToken.then((response) => {
				//console.log(response); // "Some User token"
				if (response.contenido.Log_in_output.Status === "OK") {
					//console.log('Status OK');

					// Set the flag UserType
					this.setUserType(response.contenido.Log_in_output.User_type);

					// Set the User_ID
					this.setUserID(response.contenido.Log_in_output.User_ID);

					// Update session and permanent cookies with the user_id and UserType boolean.
					this.wrapperLogInCookies();

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// Change to LandingPage
					if (this.state.ChangePage !== this.state.ChangePageCode.JWT) {
						this.setState({ChangePage: this.state.ChangePageCode.JWT}, () => this.wrapperTakeUserToLandingPageWithJWT());
					}


					// Write the cookie relating IP Address and User
					return null;
				} else {
					console.log('Status NOK');

					//Remove the loading icon
					this.setState({WaitingForRequest: false});


					// If the user has failed in the password or e-mail account provided
					if (response.contenido.Log_in_output.Error_code === 'Log_in_1' ||
						response.contenido.Log_in_output.Error_code === 'Log_in_3') {
							this.setState({ErrorMessage: 'IncorrectEmailOrPassword'})
							return null;
						}

					// If the user has not validated the account, take him/her to the code validation screen
					// This screen is on SignUp servce.
					if (response.contenido.Log_in_output.Error_code === 'Log_in_2') {
						this.setState({UserType: response.contenido.Log_in_output.User_type});
						this.setState({ChangePage: this.state.ChangePageCode.SignUp});
						return null;
					}

					// If OtherException has happened
					if (response.contenido.Log_in_output.Error_code === 'Log_in_4') {

						this.setState({UserType: response.contenido.Log_in_output.User_type});
						this.setState({ChangePage: this.state.ChangePageCode.SignUp});
						return null;

					}

					// Generic exception
					if (response.contenido.Log_in_output.Error_code === 'Log_in_6' || response.contenido.Log_in_output.Error_code === 'Log_in_4') {
						this.setState({ErrorMessage: 'GenericError'})
					}

					return null;
				}
			})

			// event.preventDefault();
			return null;

		} else {
			return null;
		}
	}

	messageEmailOrPasswordIsIncorrect = () => {
		if (this.state.ErrorMessage === 'IncorrectEmailOrPassword') {
			return <div className='IncorrectEmailOrPassword'> The email or password provided is not correct</div>
		} else {
			return null;
		}
	}



	LogInTitle = () => {

		return (
			<div className="Log_in_title_container">
				<span className='Log_in_title_text'>{'TIDI'}</span>
			</div>
		);
	}

	// LogInTitleBackButton = () => {
	// 	console.log('landing')
	//
	// 	return (
	// 		<div className="Log_in_title_container">
	// 			<Button className="Back_button" block size="lg" onClick={() => this.deciderWhichScreenToReturnTo()}>
	// 				{'Back'}
	// 			</Button>
	// 		</div>
	// 	);
	// }

	deciderWhichScreenToReturnTo = () => {

		if (this.state.Need === "1") {
			// From forgot password to LogIn
			this.setState({Email: null});
			this.setState({Password: null});
			this.setState({Need: this.state.NeedCodes.Landing});
			return null;
		}
		if (this.state.Need === "2") {
			//From Introduce verification code to forgot password
			this.setState({Email: null});
			this.setState({Reset: true});
			this.setState({Need: this.state.NeedCodes.ForgotPassword});
			removeUserTypeAndUserID();
			return null;
		}
		if (this.state.Need === "3") {
			// From new password to verification code
			this.setState({New_code: null});
			this.setState({Password: null})
			this.setState({Need: this.state.NeedCodes.ValidateCode});
			this.setState({Verification_code: null});
			return null;
		}

		if (this.state.Need === "0") {
			return null;
		}
	}

	messageInvalidAddress = () => {

		if (this.state.ErrorMessage === 'InvalidEmail') {
			return <div className='InvalidEmailMessage'> Invalid email </div>
		}
		else {
			return null;
		}
	}

	noPasswordIsProvided = () => {

		if (this.state.ErrorMessage === 'EmptyPassword') {
			return <div className='noPasswordIsProvided'> Password not provided </div>
		}
		else {
			return null;
		}
	}


	LogInFirstPage = () => {
		return [
			<div className="Login_form">
				<div className="Log_in_field_name">Email</div>
				<input
					className="Log_in_input_field"
					// autoFocus
					type="email"
					onChange={(e) => this.setEmail(e.target.value)}
				/>

				<Form.Label className="Log_in_field_name">Password</Form.Label>
				{/*<Form.Control*/}
				{/*	className="Log_in_input_field"*/}
				{/*	type="password"*/}
				{/*	onChange={(e) => this.setPassword(e.target.value)}*/}
				{/*/>*/}

				<div className='Log_in_input_field3'>
					<Form.Control
						className="Log_in_input_field"
						type={this.state.passwordTypes.input1}
						disabled={!are_cookies_preferences_made() || this.state.WaitingForRequest === true}
						onChange={(e) => this.setPassword(e.target.value)}
					/>
					<img className="eyePassword" src={this.decideEyeIconToShow(this.state.passwordTypes.input1)} alt={''}
						 onClick={() => this.switchTypePasswordToShowContent('input1') }/>
				</div>
				{this.messageInvalidAddress()}
				{this.noPasswordIsProvided()}
				{this.messageEmailOrPasswordIsIncorrect()}


			</div>
			,
			<div className='rememberMeCheckboxContainer'>
				<input type="checkbox"
					   // className='rememberMeCheckbox'
					   defaultChecked={this.state.RememberMe} onChange={(e) => this.handleCheckboxRememberMe(e.target.checked)}/>
				<span className='rememberMeCheckboxText'> Remember me</span>
			</div>
		];
	}


	decideEyeIconToShow = (state) => {
		// console.log(state)
		if (state === 'password') {return EyeSlashed}
		else {return EyeOpened}
	}


	switchTypePasswordToShowContent = (whichInput) => {
		let newType = 'text'
		console.log(this.state.passwordTypes)

		if (whichInput === 'input1') {
			if (this.state.passwordTypes.input1 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input1: newType}))
		}
		if (whichInput === 'input2') {
			if (this.state.passwordTypes.input2 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input2: newType}))
		}
		if (whichInput === 'input3') {
			if (this.state.passwordTypes.input3 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input3: newType}))
		}if (whichInput === 'input4') {
			if (this.state.passwordTypes.input4 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input4: newType}))
		}
		if (whichInput === 'input5') {
			if (this.state.passwordTypes.input5 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input5: newType}))
		}

		return null;
	}

	logInButton = () => {
		return[
				<div className='blankSpaceLogInScreen'/>,

				<div className='logInButtonContainer'>
				<Button className="logInButton" block size="lg" onClick={() => this.submitLogIn()}>
					{'Log in'}
				</Button>
			</div>,

				<span className='Log_in_forgot' style={{color: 'blue', cursor: 'pointer'}}
					  onClick={() => this.clickOnForgotMyPassword()}>{'Forgot your password?'}</span>
		]
	}

	handleCheckboxRememberMe = (event) => {
		localStorage.setItem('RememberMe', event);
		console.log(event);
		this.setState({RememberMe: event});
		return null;
	}


	isUserAlreadyRememberedWithCredentials = () => {

		let rememberMe = localStorage.getItem('RememberMe');
		let UserID = localStorage.getItem('User_ID');
		let User_type = getUserType();

		// console.log(rememberMe);
		// console.log(UserID);
		// console.log(User_type);

		// console.log(rememberMe === 'true');

		if (rememberMe === 'true' && UserID !== null && UserID !== undefined && User_type !== null && User_type !== undefined) {
			// Change to LandingPage
			this.wrapperTakeUserToLandingPageWithJWT();
		} else {
			this.setState({Need: this.state.NeedCodes.Landing})
		}
		return null;
	}

	clickOnForgotMyPassword = () => {
		this.setState({Reset: true});
		this.setState({Need: this.state.NeedCodes.ForgotPassword});
		this.setState({Password: null});
		removeUserTypeAndUserID();
		return null;
	}


	renderLogInClass = () => {
		return [
			<div>
				{/*<this.LogInFirstPage data={this.props.data} forgot={this.changeStateToForgot} state={this.state}*/}
				{/*					 red={this.redirecting}/>*/}
				{this.LogInFirstPage()}
			</div>
		]
	}


	start_jwt_creation_and_regeneration  = async () => {
		console.log('holi');

		let initial_JWT = function () {
			return create_initial_JWT_api().then(json => {
				return json
			});
		}

		let initialjwt =  initial_JWT();

		let jwtExecution = initialjwt.then((response) => {
			// The following line is the one that makes React change to LandingPage --> Will be passed to the Render.
			this.setState({ChangePage: this.state.ChangePageCode.LandingPage}, () => {console.log(this.state.Need)});
			return null;
			}
		)

		await jwtExecution;

		// this.get_expiration_cookie();

		// If the JWT is not already continuously recreated, start the process now.
		if (window.localStorage.getItem('Interval_ID') ) {
			let jwtIntervalID = this.TimerToJWTRenewal2(getCookie('jwt_expiration'));

			localStorage.setItem('Interval_ID', jwtIntervalID);

		}

		return null;
	}

	regenerate_initial_jwt = () => {
		console.log('New JWT');
		regenerate_JWT().then(json => {return json}) ;
		// JWT_expiration_cookie().then(json => {return json});
		return null;
	}

	// get_expiration_cookie = () => {
	//
	// 	let JWT_created = () => {
	// 		return JWT_expiration_cookie().then(json => {
	// 			return json
	// 		});
	// 	}
	// 	// console.log('hola');
	//
	// 	let userToken = JWT_created()
	//
	// 	// Once the response is received
	// 	userToken.then((response) => {
	// 		// TimerToJWTRenewal(response.jwt_expiration);
	// 		TimerToJWTRenewal(getCookie('jwt_expiration'));
	// 		// getCookie('jwt_expiration');
	// 		return null;
	//
	// 	})
	// }




	ContainerCookiesPolicy = () => {

		if (!are_cookies_preferences_made()) {
			if (this.state.CookiesHaveBeenAccepted !== true) {
				return [
					<div className='ContainerCookiesPolicy'>
						{this.ContainerCookiesPolicyMessage()}
						{this.ContainerCookiesPolicyButton()}
					</div>
				]
			}else {
				return null;
			}
		}else {
			return null;
		}
	}



	ContainerCookiesPolicyMessage = () => {


		let cookiesCMPRequest = <span className='buttonToRedirectInTexts'
			  onClick={() => {this.ContainerCookiesPolicyFunction('ChangePreferences')}}>{'here'}</span>

		let cookiesPolicyDetailsRequest = <span className='buttonToRedirectInTexts'
										 onClick={() => {this.ContainerCookiesPolicyFunction('CookiesDetailsRequired')}}>{'here'}</span>

		return (
			<span className="ContainerCookiesPolicyMessage">{cookiesBannerText(cookiesCMPRequest, cookiesPolicyDetailsRequest)}</span>
		)
	}

	Cookies_policy_required = () => {

		// console.log( this.state.CookiesDetailsRequired );
		this.setState({CookiesDetailsRequired: true});
		return null;
	}

	ContainerCookiesPolicyButton = () => {
		return (
			<div className='ContainerCookiesPolicyButtonPrevious'>
				<Button className="ContainerCookiesPolicyButtonAgreeAndClose" onClick = {() => {this.ContainerCookiesPolicyFunction('Accept')}}>
					{'Accept'}
				</Button>
			</div>
		)
	}

	ContainerCookiesPolicyFunction = (cookiesCode) => {

		if (cookiesCode === 'Accept') {
			this.setState(
				Object.assign(this.state.CookiesAccepted, {Analytics: true}), () => {this.manageCookiesPreferences()});


			this.setState({CookiesHaveBeenAccepted: true});
			return null;
		}

		if (cookiesCode === 'Reject') {
			this.setState(
				Object.assign(this.state.CookiesAccepted, {Analytics: false}), () => {this.manageCookiesPreferences()});

			this.setState({CookiesHaveBeenAccepted: true});
			return null;
		}

		if (cookiesCode === 'ChangePreferences') {
			this.setState({CookiesMenuChangePreferences: true});
			return null;
		}

		if (cookiesCode === 'CookiesDetailsRequired') {
			this.setState({CookiesDetailsRequired: true});
			return null;
		}

	}

	CookiesMenuChangePreferences = () => {

		if (this.state.CookiesMenuChangePreferences === true) {

			return [
				<div className='CookiesMenuChangePreferences'>
					{this.cookiesMenuChangePreferencesTitle()}
					{this.cookiesMenuChangePreferencesMenu()}
					{this.cookiesMenuChangePreferencesClose()}
					<div className='buttonAcceptCookiesDiv'>
						{this.buttonCookiesDetail()}
						{this.buttonAcceptCookies()}
					</div>
				</div>
			]
		} else {
			return null;
		}
	}

	buttonCookiesDetail = () => {
		return(
			<Button className="buttonCookiesDetail" onClick = {() => {this.setState({CookiesDetailsRequired: true})} } >
			{/*<Button className="buttonCookiesDetail" onClick = {() => {this.setState({whichLegalTextIsRequired: 'legalNotice'})}} >*/}

				{/*{() => {this.setState({whichLegalTextIsRequired: 'legalNotice'})}}*/}
				{'Cookies policy'}
			</Button>
		)
	}


	showCookiesDetails = () => {

		if (this.state.CookiesDetailsRequired === true) {
			// CookiesPolicyRequired
			// CookiesDetailsRequired

			var showLegalTextDeciderTitle = cookiesDetailTitle;

			let tablaTecnicas = this.tableAnalyticsCookies('technical');
			let tablaAnaliticas = this.tableAnalyticsCookies('analytics');

			let buttonCookiesSummary = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'cookiesSelection'}, () => {
					this.setState({CookiesDetailsRequired: false})
				})
			}}>
                    {'Cookies configurator'}
                </span>;

			let buttonPrivacyPolicy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'}, () => {
					this.setState({CookiesDetailsRequired: false})
				})
			}}>
                        {'Privacy policy'}
                    </span>;
			var showLegalTextDeciderText = cookiesDetailText(buttonCookiesSummary, buttonPrivacyPolicy, tablaTecnicas, tablaAnaliticas);

			return (
				<div className='showLegalTextBigProfileInLogIn'>
					<div className='showLegalTextDecider'>
						<div className='showLegalTextDeciderTitle'>{showLegalTextDeciderTitle}</div>
						<div className='showLegalTextDeciderTextMyArea'>
							<div className='showLegalTextDeciderTextMyAreaText'> {showLegalTextDeciderText}</div>
						</div>
					</div>

					<Button className="buttonCloseLegalTextButton" onClick={() => {
						this.setState({CookiesDetailsRequired: false})
					}}>
						<div className='buttonCloseLegalTextButtonText'>{'X'}</div>
					</Button>
				</div>
			)
		} else {
			return null;
		}
	}


	buttonAcceptCookies = () => {

		// this.setState({CookiesDetailsRequired: true});

		return[
			<div className='buttonAcceptCookiesContainer'>
				<Button className="ContainerCookiesPolicyButtonRejectAllCMP" onClick = {() => {this.ContainerCookiesPolicyFunction('Reject')}}>
					{'Decline all'}
				</Button>
				<Button className="buttonAcceptCookies" onClick = {() => {this.manageCookiesPreferences()}}>
					{'Accept and close'}
				</Button>
			</div>
		]
	}

	manageCookiesPreferences = () => {
		// This function closes the dialogs of the cookies acceptance, retrieves the cookies information in case of
		// acceptance and sends the information to Python.


		if (this.state.CookiesAccepted['Analytics'] === true) {
			// Assign the localStorage variable's value
			set_analytics_acceptance_cookie(true);
			// Retrieve the user's geography and store it in DB.
			wrapper_send_all_cookies_after_cookies_preferences_made();

		} else {
			// Assign the localStorage variable's value
			set_analytics_acceptance_cookie(false);
			// Store in DB that the user has declined analytics cookies.
			wrapper_send_all_cookies_after_cookies_preferences_made();
		}


		// Close the cookies dialogs.
		this.setState({CookiesMenuChangePreferences: false});
		this.setState({CookiesHaveBeenAccepted: true});


		return null;
	}

	cookiesMenuChangePreferencesTitle = () => {
		return [
			<div className='cookiesMenuChangePreferencesTitle'>{cookiesSummaryTitle}</div>
			// <div className='cookiesMenuChangePreferencesTitleSubtitle'>{cookiesSummaryTitleExplanation}</div>
	]
	}

	cookiesMenuChangePreferencesMenu = () => {
		return (
			<div className='cookiesMenuChangePreferencesMenu'>
				<div className='cookiesMenuChangePreferencesMenuArea'>
					<li className='cookiesMenuChangePreferencesMenuItem'> {cookiesSummaryNecessaryTitle}</li>
					<div className='cookiesMenuChangePreferencesMenuSpan'> {necessaryCookiesText} </div>
					<input className='toggleCookiesTechnical' type='checkbox' defaultChecked={this.state.CookiesAccepted['Technical']} disabled={true} onChange={(event) => {this.cookiesToggleButton(event.target)}}/>
				</div>

				<div className='cookiesMenuChangePreferencesMenuArea'>
					<li className='cookiesMenuChangePreferencesMenuItem'> {cookiesSummaryAnalyticsTitle}</li>
					<div className='cookiesMenuChangePreferencesMenuSpan'> {analyticsCookiesText}</div>
					<input className='toggleCookies' type='checkbox' defaultChecked={this.state.CookiesAccepted['Analytics']} onChange={(event) => {this.cookiesToggleButton(event.target, 'Analytical')}}/>
				</div>
			</div>
		)
	}



	cookiesToggleButton  = (e, cookiesType) => {

		if (cookiesType === 'Analytical') {
			if (this.state.CookiesAccepted['Analytics'] === true) {
					this.setState(
						Object.assign(this.state.CookiesAccepted, {Analytics: false}));
			} else {
				this.setState(
					Object.assign(this.state.CookiesAccepted, {Analytics: true}));
			}
		}

		return null;
	}

	cookiesMenuChangePreferencesClose = () => {
		return (
			<Button className="buttonCloseLegalTextButton" onClick={() => {this.CookiesMenuChangeContainerCloseFunction()}}>
				<div className='buttonCloseLegalTextButtonText'>{'X'}</div>
			</Button>
		)
	}

	CookiesMenuChangeContainerCloseFunction = () => {

		this.setState({CookiesDetailsRequired: false});
		this.setState({CookiesMenuChangePreferences: false});
		return null;

	}


	CookiesPolicyTitle = () => {
		return (
			<span className='CookiesPolicyTitle'> {cookiesDetailTitle} </span>
		)
	}

	CookiesPolicyText = () => {
		return (
			<span className='CookiesPolicyText'><div className='showLegalTextDeciderTextMyAreaText'> {cookiesDetailText}</div> </span>
		)
	}

	CookiesPolicyTextContainerClose = () => {
		return (
			<Button className="CookiesPolicyTextContainerCloseButton" onClick = {() => {this.CookiesPolicyTextContainerCloseFunction()}}>
				{'X'}
			</Button>
		)
	}

	CookiesPolicyTextContainerCloseFunction = () => {

		this.setState({CookiesDetailsRequired: false});
		// this.setState({CookiesMenuChangePreferences: false});
		return null;

	}

	LogInTitleBackButton = () => {
		if (this.state.Need === "0" || (this.state.Need === "3" && this.state.checkboxCompany === false)) {
			return (
				<div className="Log_in_back_title_container"/>
			);
		} else {
			return (
				<div className="Log_in_back_title_container">
					<Button className="Back_button_log_in" block size="lg"
							onClick={() => this.deciderWhichScreenToReturnTo()}>
						{'Back'}
					</Button>
				</div>
			);
		}
	}


	wrapperTakeUserToLandingPageWithJWT = async () => {

		// console.log('wrapperTakeUserToLandingPageWithJWT');
		// this.start_jwt_creation_and_regeneration();
		this.setState({ChangePage: this.state.ChangePageCode.LandingPage}, () => {console.log(this.state.Need)});

		// this.Redirect_to_LandingPage()

		return null;
	}


	ObscurerLogIn = () => {

		if (!are_cookies_preferences_made() || this.state.ObscurerRequired === true || this.state.whichLegalTextIsRequired !== null) {
			return (
				<div className='ObscurerLogIn'>
					{' '}
				</div>
			)
		} else {return null;}
	}




	buttonCloseLegalText = (legalText) => {

		return(
			<Button className="buttonCloseLegalTextButton" onClick={() => {this.setState({whichLegalTextIsRequired: null});
				// Make the obscurer appear
				this.setState({ObscurerRequired: false})}}>
				<div className='buttonCloseLegalTextButtonText'>{'X'}</div>
			</Button>
		)
	}


	genericErrorWhenConnectingToPythonInUserRequest = () => {
		if (this.state.ErrorMessage === 'GenericError') {
			console.log('holi')
			return (
				<div className='errorWhenLoadingLogIn'>
					<div className='errorWhenLoadingLogInText'> An error occurred. Please, contact support  </div>
				</div>
			)
		} else {
			return null;
		}
	}

	WaitingForRequest = () => {

		if (this.state.WaitingForRequest === true) {
			return (
				<div className='loading'>
					<img className="loading-image" src={loadingPictureCode} />
				</div>
			)
		} else {return null;}
	}


	legalTextContainers = () => {

		let divCookiesSelection = 'legalTextContainers22';
		let divGTC = 'legalTextContainers42';

		let TIDIName = '© 2021 The TIDI Systems GmbH. All rights reserved.'

		return (
			<div className='legalTextContainers'>
				{/*<div className="legalTextContainersColumn">A</div>*/}
				{/*<div className="legalTextContainersColumn">B</div>*/}
				{/*<div className="legalTextContainersColumn">C</div>*/}
				{/*<div className="legalTextContainersColumn">D</div>*/}

				<div className='legalTextContainersColumn'>
				<Button className="legalTextContainers1" block size="lg" onClick={() => {
					this.requiringSomeLegalText('legalNotice')
				}}>
					{'Legal notice'}
				</Button>
				</div>

				<div className='legalTextContainersColumn'>
					<Button className='licensesButtonLogIn' block size="lg" onClick={() => {
						this.requiringSomeLegalText('Licenses')
					}}>
						{'Third-party open source licenses'}
					</Button>
				</div>

				<div className='legalTextContainersColumn'>
					<Button className='ContactSupportLogIn' block size="lg" onClick={() => {
						this.requiringSomeLegalText('ContactSupport')
					}}>
						{'Contact support'}
					</Button>
					</div>

				<div className='legalTextContainersColumn'>
					<Button className='TIDINameLogIn' block size="lg"> {TIDIName} </Button>
				</div>
			</div>
		)
	}


	requiringSomeLegalText = (legalText) => {

		this.setState({whichLegalTextIsRequired: legalText})

		return null;

	}

	showLegalText = () => {

		let legalText = this.state.whichLegalTextIsRequired;
		let thisDiv = 'showLegalTextBigLogIn';
		// console.log(legalText)
		if (legalText === 'ContactSupport') {
			thisDiv = 'showLegalTextDeciderTitleDivSupportLogIn'
		}

		if (legalText !== null) {

			return [
				this.ObscurerLogIn(),
				<div className={thisDiv}>
					{this.showLegalTextDecider()}
					{this.buttonCloseLegalText(legalText)}
				</div>
			]
		}
		return null;
	}


	showLegalTextDecider = () => {
		let legalText = this.state.whichLegalTextIsRequired;

		var showLegalTextDeciderTitle = null;
		var showLegalTextDeciderText = null;
		var showLegalTextDeciderTextDiv = 'showLegalTextDeciderTextLogIn';
		var showLegalTextDeciderTextMyAreaTextInterior = 'showLegalTextDeciderTextMyAreaTextLogIn';

		if (legalText === 'legalNotice') {

			let buttonPrivacy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'})
			}}>
				{'Privacy policy'}
			</span>;

			let buttonCookiesPolicy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>;
			showLegalTextDeciderTitle = legalNoticeTitle;
			showLegalTextDeciderText = legalNoticeText(buttonPrivacy, buttonCookiesPolicy);
		}
		if (legalText === 'cookiesSelection') {
			showLegalTextDeciderTitle = this.CookiesMenuChangePreferences();
			// showLegalTextDeciderText = 'text';
			// showLegalTextDeciderText = this.CookiesMenuChangePreferences();
		}
		if (legalText === 'cookiesDetail') {
			showLegalTextDeciderTitle = 'Cookies';
			showLegalTextDeciderText = 'cookies text';
		}
		if (legalText === 'privacyPolicy') {
			showLegalTextDeciderTitle = privacyPolicyTitle;
			let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>
			showLegalTextDeciderText = privacyPolicyText(privacyPolicyButton1);
		}
		if (legalText === 'GTC') {
			let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>
			let licensesButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'Licenses'})
			}}>
				{'Open Source licenses'}
			</span>
			let buttonPrivacy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'})
			}}>
				{'Privacy policy'}
			</span>

			showLegalTextDeciderTitle = decideWhichGTCToDisplay(licensesButton1, privacyPolicyButton1, buttonPrivacy).GTCTitle;
			showLegalTextDeciderText = decideWhichGTCToDisplay(licensesButton1, privacyPolicyButton1, buttonPrivacy).GTCText;
		}
		if (legalText === 'Licenses') {
			showLegalTextDeciderTitle = licensesTitle;
			showLegalTextDeciderText = this.allLicenseTextsButtons();
		}
		if (legalText === 'ContactSupport') {
			// showLegalTextDeciderTextDiv = 'showLegalTextDeciderTextDivSupport';
			showLegalTextDeciderTitle = <b>CONTACT SUPPORT</b>;
			showLegalTextDeciderText = 'We are more than glad to give you a hand! Please, write an e-mail to hola@tidisystems.com to reach out for help :)';
			showLegalTextDeciderTextMyAreaTextInterior = 'showLegalTextDeciderTextDivSupportInterior';
		}


		return (
			<div className='showLegalTextDeciderLogIn'>
				<div className='showLegalTextDeciderTitle'>{showLegalTextDeciderTitle}</div>
				<div className={showLegalTextDeciderTextDiv}>
					<div className={showLegalTextDeciderTextMyAreaTextInterior}><div className={showLegalTextDeciderTextMyAreaTextInterior}>{showLegalTextDeciderText}</div></div>
				</div>
			</div>
		)
	}

	tableAnalyticsCookies = (type) => {
		return(
			<div className='tableAnalyticsCookies'>
				<Table  className='tableAnalyticsCookiesTable'>
					<thead className='tableAnalyticsCookiesTableHeader'>
					<tr>{this.rendertableAnalyticsCookiesTableHeader()}</tr>
					</thead>
					<tbody className='tableAnalyticsCookiesTableTBody'>
					{this.rendertableAnalyticsCookiesTableData(type)}
					</tbody>
				</Table>
			</div>
		)
	}

	rendertableAnalyticsCookiesTableHeader = () => {

		var first_line = this.state.analyticsCookiesHeader[0];

		if (first_line === undefined) {
			return null;
		} else {
			// console.log(first_line)
			let header = Object.values(first_line)
			return header.map((key, index) => {
				return <th key={index}>{key}</th>
			})
		}
	}


	rendertableAnalyticsCookiesTableData = (type) => {

		let tableCookiesBody = Object.values(this.state.analyticsCookiesBody);
		if (type === 'technical') {
			console.log('hol')
			tableCookiesBody = Object.values(this.state.technicalCookiesBody);
		}
		return tableCookiesBody.map((eachRow, index) => {
			let values = Object.values(eachRow);
			const [
				ID, Name, Provider, Goal, Duration
			] = values //destructuring

			return (
				<tr key={ID}>
					<td>{Name}</td>
					<td>{Provider}</td>
					<td>{Goal}</td>
					<td>{Duration}</td>
				</tr>
			)
		})
	}


	changeStateLandingPageForLicenses = (value) => {

		this.setState({licenseText: value});

		return null;

	}

	allLicenseTextsButtons = () => {
		// Returns all buttons of licenses texts

		let licensesButtonsClass = new renderLicensesButtons();
		let licensesButtons = licensesButtonsClass.buttonObjects(this.changeStateLandingPageForLicenses);

		let licenseIntroText = <div className='allLicenseTextsButtonsIntro'>{licensesIntroText}</div>;
		let licensesButtonMIT = licensesButtons['licensesButtonMIT'];
		let licensesButtonBSD2 = licensesButtons['licensesButtonBSD2'];
		let licensesButtonApache2 = licensesButtons['licensesButtonApache2'];
		let licensesButtonBSD3 = licensesButtons['licensesButtonBSD3'];
		let licensesButtonHPND = licensesButtons['licensesButtonHPND'];
		let licensesButtonISC = licensesButtons['licensesButtonISC'];
		let licensesButtonPostgreSQL = licensesButtons['licensesButtonPostgreSQL'];
		let licensesButtonPython = licensesButtons['licensesButtonPython'];
		let licensesButtonUnlicense = licensesButtons['licensesButtonUnlicense'];
		let licensesButtonZope = licensesButtons['licensesButtonZope'];

		let allLicenseButtons = <div className='allLicenseTextsButtonsButtons'>
			{licensesButtonApache2}
			{licensesButtonBSD2}
			{licensesButtonBSD3}
			{licensesButtonHPND}
			{licensesButtonISC}
			{licensesButtonMIT}
			{licensesButtonPostgreSQL}
			{licensesButtonPython}
			{licensesButtonUnlicense}
			{licensesButtonZope}
		</div>
		let licenseAnyText = <div className='allLicenseTextsButtonsText'>{this.decideLicenseText()}</div>;

		return <div className='allLicenseTextsButtons'>
			{licenseIntroText}
			{allLicenseButtons}
			{licenseAnyText}
		</div>
	}

	decideLicenseText = () => {

		let text = this.state.licenseText;
		let Text = null;
		if (text === 'MIT') {
			Text = licenseMITText;
		}
		if (text === 'BSD2') {
			Text = licenseBSD2ext;
		}
		if (text === 'Apache2') {
			Text = licenseApache2Text;
		}
		if (text === 'BSD3') {
			Text = licenseBSD3Text;
		}
		if (text === 'HPND') {
			Text = licenseMITText;
		}
		if (text === 'ISC') {
			Text = licenseISCText;
		}
		if (text === 'PostgreSQL') {
			Text = licensePostgreSQLText;
		}
		if (text === 'Python') {
			Text = licensePythonSFText;
		}
		if (text === 'Unlicense') {
			Text = licenseUnlicenseText;
		}
		if (text === 'Zope') {
			Text = licenseZopePLText;
		}

		return Text;
	}


	render() {

		// console.log('lolcito')

		// if (this.state.Need === this.state.NeedCodes.LandingPage) {
		if (this.state.ChangePage === this.state.ChangePageCode.JWT) {
			// The user has validated his/her account and now needs to see the LandingPage
			return <div className='BackgroundLogIn'> </div>

		}
		if (this.state.ChangePage === this.state.ChangePageCode.LandingPage) {
			// The user has validated his/her account and now needs to see the LandingPage
			return [this.Redirect_to_LandingPage()];
		}

		if (this.state.ChangePage === this.state.ChangePageCode.SignUp) {
			// The user has validated his/her account and now needs to see the LandingPage
			return (
				<div className='BackgroundLogIn'>
					<this.Redirect_to_SignUp data={[this.state.Email, this.state.UserType] }/>
				</div>
			)
		}

		if (this.state.Need === this.state.NeedCodes.Remembered) {
			this.isUserAlreadyRememberedWithCredentials();
			return <div className='BackgroundLogIn'/>
		}

		if (this.state.Need === this.state.NeedCodes.Landing) {
			get_Session_ID_cookies();
			wrapper_create_all_cookies_on_opening_tab();

			// The user is on the first sub-page of LogIn. The (email, password) tuple is not validated yet.
				// window.localStorage.setItem('Page', 'LogIn');
				return [
					<div className='BackgroundLogIn'/>,
						this.WaitingForRequest(),
						this.ObscurerLogIn(),
						<div className="Log_in_general_container">
							{this.LogInTitleBackButton()}
							{this.LogInTitle()}
							{this.LogInFirstPage()}
							{this.logInButton()}
							{this.HR()}
							{SignUpButtonToRedirect()}
						</div>
						,
						<this.ContainerCookiesPolicy/>,
					<this.showCookiesDetails/>,
						<this.CookiesMenuChangePreferences/>,
						this.genericErrorWhenConnectingToPythonInUserRequest(),
						this.legalTextContainers(),
						this.showLegalText()
				]
		}
		if (this.state.Need === this.state.NeedCodes.ForgotPassword) {
			// The user has signalled he/she has forgotten the password. This screen requires the e-mail address to
			// send a validation code to the e-mail account.
			return [
				<div className='BackgroundLogIn'/>,
						this.WaitingForRequest(),
						<div className="Log_in_general_container">
							{this.LogInTitleBackButton()}
							{this.LogInTitle()}
							{this.messageIForgotMyPassword()}
							{this.LoginIForgotMyPassword()}
						</div>,
						this.genericErrorWhenConnectingToPythonInUserRequest()
			]
		}
		if (this.state.Need === this.state.NeedCodes.ValidateCode) {
			// The user has to introduce the validation code in the screen
			return [
				<div className='BackgroundLogIn'/>,
						this.WaitingForRequest(),
						<div className="Log_in_general_container">
							{this.LogInTitleBackButton()}
							{this.LogInTitle()}
							{this.messageIForgotMyPassword()}
							{this.LoginSendValidationCode()}
							{this.sendAgainButtonLogIn()}
						</div>,
						this.genericErrorWhenConnectingToPythonInUserRequest()
			]
		}
		if (this.state.Need === this.state.NeedCodes.NewContra) {
			// Screen where the user needs to change his/her password
			return [
				<div className='BackgroundLogIn'/>,
						this.WaitingForRequest(),
						<div className="Log_in_general_container">
							{this.LogInTitleBackButton()}
							{this.LogInTitle()}
							{this.messageIForgotMyPassword()}
							{this.LogInStepResetContra()}
							{this.messageNewPasswordFormat()}
						</div>,
						this.genericErrorWhenConnectingToPythonInUserRequest()
			]
		}
	}
}

