import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

import LogIn from './pages/LogIn'
import LandingPage from './pages/LandingPage'
import MyProfile from './pages/MyProfile'
import SignUp from './pages/SignUp'
import Access from './pages/Access'
import QR_code from './pictures/Blue_QR.png';
import {isDeviceAMobile, classify_received_via} from "./common/utilities"

require('dotenv').config()

isDeviceAMobile();
classify_received_via();

export default function App() {

    <meta name="viewport" content="width=device-width, initial-scale=1.0"> </meta>

    const meta = {
        title: 'TIDI',
        // description: 'I am a description, and I can create multiple tags',
        description: 'Soy una descripción',
        canonical: 'https://www.tidisystems.com',
        image: QR_code,
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'react,meta,document,html,tags'
            }
        }
    }

    return (

        window.location.replace(`https://www.tidisystems.com`)

    //     // <meta name="viewport" content="width=device-width, initial-scale=1.0"> </meta>
    // <DocumentMeta {...meta}>
    //
    //     <Switch>
    //
    //         <Route exact path='/' component={Access} />
    //
    //         <Route exact path='/LogIn' component={LogIn} />
    //
    //         <Route path='/MyArea' component={LandingPage} />
    //
    //         <Route path='/MyProfile' component={MyProfile} />
    //
    //         <Route path='/SignUp' component={SignUp} />
    //
    //     </Switch>
    // </DocumentMeta>

    )

}

