import {generalTermsAndConditionsTitleProvider, generalTermsAndConditionsTextProvider}
    from "./General_terms_and_conditions_provider";
import {generalTermsAndConditionsTextParticular, generalTermsAndConditionsTitleParticular
    } from "./General_terms_and_conditions_particular";
import {isUserAnIndividual} from "../common/utilities";


function decideWhichGTCToDisplay(buttonLicenses, buttonCookiesPolicy, buttonPrivacy) {

    let GTCTitle = generalTermsAndConditionsTitleProvider;
    let GTCText = generalTermsAndConditionsTextProvider(buttonLicenses, buttonCookiesPolicy);

    if (isUserAnIndividual()) {

        GTCTitle = generalTermsAndConditionsTitleParticular;
        GTCText = generalTermsAndConditionsTextParticular(buttonLicenses, buttonCookiesPolicy, buttonPrivacy);

    }

    return {'GTCTitle': GTCTitle, 'GTCText': GTCText}

}

export {decideWhichGTCToDisplay};