function cookiesBannerText (buttonInputCMP, buttonInputDetails) {
    return (<span>We use our own, session or persistent cookies and similar technologies to operate our platform securely. We also use cookies to measure
        and obtain data on your browsing habits and to obtain analytical information on the geographical origin of our users.
        Click {buttonInputDetails} for more information. You can accept all cookies by clicking the "Accept" button or configure or reject their use by clicking {buttonInputCMP}.</span>)
}


var necessaryCookiesText = <span> These cookies are necessary for the Platform to function and cannot be disabled. </span>

var analyticsCookiesText = <span> With these cookies we can analyse your browsing on our Platform to obtain analytical information about the geographical origin of our users. </span>

var cookiesSummaryTitle = <b>COOKIES CONFIGURATOR</b>
var cookiesSummaryTitleExplanation = <span>Here you can set your cookies: </span>

var cookiesSummaryNecessaryTitle = <span> Technical cookies </span>
var cookiesSummaryAnalyticsTitle = <span> Analytical cookies </span>

export {necessaryCookiesText, analyticsCookiesText, cookiesSummaryTitle, cookiesSummaryNecessaryTitle,
    cookiesSummaryAnalyticsTitle, cookiesBannerText, cookiesSummaryTitleExplanation}