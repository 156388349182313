import "./textx.css";
import React from "react";

function legalNoticeText(buttonPrivacy, buttonCookiesPolicy) {
    return(
    <div><span>Access, browsing and use of the platform made available by the owner (hereinafter, the "
    <b>Plataform</b>
    ") implies the knowledge and acceptance of the provisions of this Legal Notice, the terms of which have the same validity and effectiveness as any written and signed contract. <br/><br/>
    Observance of and compliance with the provisions of the Legal Notice shall be enforceable against any person accessing, browsing or using the Platform. If you do not agree to the terms set out above, do not access, browse or use any page of the Platform. <br/><br/>
    By using the Platform you agree, therefore, to use the services and contents in accordance with current legislation and with this Legal Notice, and you acknowledge and accept that the use of the contents and/or services offered by its owner shall be at your sole risk and/or responsibility.  <br/><br/>
<br/><b>1. COMPANY DETAILS</b><br/><br/>
    Proprietor: The TIDI Systems GmbH (hereinafter "<b>TIDI</b>").<br/>
    Registered office: Frohbühlstrasse 3, 8052 Zürich, Switzerland.<br/>
    TFN: CHE-389.068.509<br/>
    Public Register: Commercial Register of Zurich, registration number CHE-1005265706.<br/>
    Phone: +41 79 109 19 23.<br/>
    E-mail: hola@tidisystems.com<br/><br/>

<br/><b>2. PURPOSE AND SCOPE</b><br/><br/>
    This Legal Notice regulates access, browsing and use of the Platform. The use of certain services offered on the Platform shall also be governed, where appropriate, by the specific conditions provided in each case, which shall complement this document.<br/><br/>
    The provision of the Platform service is limited to the time at which the user is connected to the Platform or to any of the services provided through it.<br/><br/>
    TIDI reserves the right to make, at any time and without prior notice, any modification or update of the contents, of this Legal Notice and, in general, of any elements that make up the design and configuration of its Platform. <br/><br/>

<br/><b>3. SERVICES AND CONTENTS OF THE PLATFORM</b><br/><br/>
    The Platform includes information for the general public about the services offered by TIDI.<br/><br/>
    TIDI reserves the right to decide at any time what content is shown to the user, and may remove it and add new content at any time. Any changes in this regard will be published visibly on the Platform, indicating the date of the last update at the bottom of the document. If you continue to make use of the services provided on the Platform, once the Legal Notice has been modified, it will be understood that you accept said modifications.<br/><br/>

<br/><b>4. INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS</b><br/><br/>
    TIDI is the owner or, where applicable, has the corresponding licences for the exploitation rights of the intellectual property of the Platform, as well as the intellectual and industrial property rights over all the information, materials and content, including, among others, source code, designs, navigation structure, images, graphics, databases, music, sound, photographs, videos and texts written in any language, also including programming codes.<br/><br/>
    Under no circumstances shall access, browsing and use of the Platform by the user imply any waiver, transmission, licence or total or partial transfer of any intellectual or industrial property rights by TIDI. The unregistered user only has the right to view the Platform in a strictly domestic environment or in connection with his/her professional activity. The rights of use that the user obtains once registered are specified in the corresponding Customer or Supplier Terms and Conditions, depending on their profile.<br/><br/>
    No content of the Platform may be modified, copied, distributed, framed, reproduced, republished, downloaded, extracted, displayed, published, transmitted or sold in any way or by any means, in whole or in part, without the prior written consent of TIDI. Any other use of the content of the Platform is also strictly prohibited, with the sole exception of strictly domestic use.<br/><br/>
    The use, without prior consent, of any element of the Platform that is subject to protection in accordance with current legislation on intellectual or industrial property is expressly prohibited. In particular, trademarks, trade names, business signs, works, names, logos, slogans or any type of distinctive sign belonging to TIDI may not be used.<br/><br/>
    Unauthorised use of the information contained in the Platform, as well as any type of infringement of TIDI's intellectual or industrial property rights, will give rise to the legally established responsibilities. <br/><br/>

<br/><b>5. LINKS</b><br/><br/>
<b>5.1. Links from other sites to the Platform</b><br/><br/>
    TIDI does not authorise the establishment of a link to this Platform from other web pages that contain materials, information or contents that are illicit, illegal, degrading, obscene and, in general, that contravene the law, morality or public order, or generally accepted social norms.<br/><br/>
    TIDI has neither the power nor the human and technical means to know, control or approve all the information, content, products or services provided by other websites that have established links to the Platform.<br/><br/>
    TIDI assumes no responsibility whatsoever for any aspect relating to the websites that establish such a link to this Platform; specifically, including but not limited to, their operation, access, data, information, files, quality and reliability of their products and services, their own links and/or any of their contents, in general.<br/><br/>
    In any case, TIDI reserves the right to prohibit links to the Platform and to demand their removal when they do not comply with the conditions required in this section. <br/><br/>
<b>5.2. Links from the Platform to other websites</b><br/><br/>
    In the event that links to websites outside TIDI are displayed on the Platform by means of different links, buttons, banners or embedded content, TIDI informs that these are directly managed by the owners of the respective websites, TIDI not having the human or technical means to know in advance and/or control and/or approve all the information, content, products or services provided by other websites to which links may be established from the Platform, so TIDI assumes no responsibility whatsoever for any aspect relating to the linked website.<br/><br/>
    In this regard, if the user has effective knowledge that the activities carried out through such third party websites are illegal or contravene morality and/or public order, he/she must immediately inform TIDI so that the link to access them can be disabled, which will be carried out as soon as possible.<br/><br/>
    In any case, the publication of any link from the Platform to another website does not imply that there is any kind of relationship, collaboration or dependence between TIDI and the party responsible for that website.<br/><br/>

<br/><b>6. CONDITIONS OF USE OF THE PLATFORM</b><br/><br/>
Access to or use of the Platform for illegal or unauthorised purposes, with or without economic purpose, and more specifically, by way of example, but not limited to, is prohibited, and the consequences thereof shall be the sole responsibility of the user:<br/>
<ul className='ulItem'>
    <li>Use the Platform in any way that may cause damage, interruptions, inefficiencies or defects in its functionality or in the computer of a third party.</li>
    <li>Use the Platform for the transmission, installation or publication of any viruses, malicious code or other harmful programs or files.</li>
    <li>Use the Platform to collect personal data of other users, except as expressly provided in the applicable Terms and Conditions.</li>
    <li>Use the Platform for advertising or commercial exploitation activities.</li>
    <li>Registering or communicating with TIDI through the Platform under a false identity, providing false data or information, impersonating third parties or performing any other action that may mislead other users as to the identity of the origin of a message.</li>
    <li>Unauthorised access to any section of the Platform, other systems or networks connected thereto, any TIDI server or services offered through the Platform, by hacking or forgery, password mining or any other illegitimate means.</li>
    <li>Breach, or attempt to breach, the security or authentication measures of the Platform or any network connected thereto, or the security or protection measures inherent in the content offered on the Platform.</li>
</ul>

Failure by the user to comply with any of these conditions of use may lead to the adoption by TIDI of the appropriate measures protected by law and in the exercise of its rights or obligations.<br/><br/>
TIDI may prevent access to the Platform or the possibility of participating in the spaces provided therein to users who do not comply with these conditions, without the possibility of any compensation for damages caused.<br/><br/>
TIDI cannot control, prior to publication, what use will be made by others of the content that may be published on the Platform and therefore cannot be held responsible for it.<br/><br/>
Nevertheless, TIDI reserves the right to supervise and/or moderate any content published by users and, in the event that any of this content violates the provisions of this Legal Notice or the {buttonPrivacy}, to delete it, in whole or in part.<br/><br/>
In particular, it is strictly forbidden to publish content: <br/>
<ul className='ulItem'>
    <li>That may be considered as a violation in any way of the fundamental rights to honour, to personal and family privacy or to one's own image of third parties and, in particular, of minors, or of any other fundamental rights and freedoms;</li>
    <li>Including or linking to photographs that collect images or personal data of third parties without having obtained the appropriate consent of the owners;</li>
    <li>Infringing the secrecy of communications or infringing intellectual and industrial property rights or the rules governing the protection of personal data;</li>
    <li>Contain any material or information that is unlawful, racist, obscene, pornographic, abusive, defamatory, misleading, fraudulent or otherwise contrary to morality or public order;</li>
    <li>Containing "spam" and/or links to sites unrelated to the space concerned;</li>
    <li>That include advertising or commercial communications, for the broadcasting of messages for advertising purposes or for the collection of data for the same purpose, and especially any type of information, element or content that constitutes unlawful or unfair advertising.</li>
</ul>
Failure by the user to comply with any of these conditions of use may lead to the adoption by TIDI of the appropriate measures protected by law and in the exercise of its rights or obligations.<br/><br/>
TIDI may prevent access to the Platform, cancel the profile or prevent the possibility of participating in the spaces provided therein to users or members who breach these conditions, without the possibility of any compensation for damages caused. <br/><br/>


<br/><b>7. LIABILITIES AND WARRANTIES</b><br/><br/>
    TIDI cannot guarantee the reliability, usefulness, timeliness or accuracy of all information and/or services on the Platform.<br/><br/>
    Consequently, TIDI does not guarantee and is not responsible for: (i) the veracity and continuity of the contents of the Platform; (ii) the absence of errors in said contents; (iii) the absence of viruses and/or other harmful components in the Platform or in the server that supplies it; (iv) the damage caused to the computer equipment of users or third parties during the provision of the Platform service; (v) the invulnerability of the Platform and/or the impossibility of breaching the security measures adopted therein; (vi) the lack of usefulness or performance of the contents of the Platform; (vii) damage or harm of any kind caused to the user as a result of failures or disconnections in the telecommunications networks that lead to the suspension, cancellation or interruption of the service during the provision of the same or beforehand, and (viii) damage or harm caused, to themselves or to a third party, by any person who infringes the conditions, rules and instructions that TIDI establishes in relation to the Platform or through the violation of its security systems.<br/><br/>
    Nevertheless, TIDI declares that it has taken all necessary measures, within its possibilities and the state of the art, to ensure the operation of the Platform and to minimise system errors, both from a technical point of view and in terms of the content published on the Platform.<br/><br/>
    If the user becomes aware of the publication of any inaccurate, out-of-date or false information or of the existence of any content that is illicit, illegal, contrary to the law or that could involve an infringement of intellectual or industrial property rights, of the applicable regulations on the protection of personal data and/or of any other right, he/she must notify TIDI immediately so that it can proceed to adopt the appropriate measures.<br/><br/>

<br/><b>8. PRIVACY AND COOKIES POLICY</b><br/><br/>
    Registration and access to the Platform requires the user to provide certain information about themselves, in accordance with the provisions of the {buttonPrivacy}. In addition, certain functionalities of the website depend on the use of Cookies, as informed in the {buttonCookiesPolicy}.<br/><br/>
    Whenever the user provides personal data, he/she shall be solely responsible for the truthfulness and accuracy of such data, so he/she shall inform TIDI of any updates to such data as soon as possible. <br/><br/>

<br/><b>9. NULLITY AND INEFFECTIVENESS OF THE CLAUSES</b><br/><br/>
    If any clause included in this Legal Notice is declared totally or partially null or ineffective, such nullity or ineffectiveness will only affect said provision or the part of it that is null or ineffective, with this Legal Notice remaining in force in all other respects, and said provision being considered totally or partially as not included.<br/><br/>

<br/><b>10. APPLICABLE LAW AND COMPETENT JURISDICTION</b><br/><br/>
    The provisions of this Legal Notice are governed by Spanish law. The parties expressly submit, for the resolution of conflicts and renouncing any other jurisdiction, to the courts and tribunals of the city of Madrid, unless otherwise provided by law, in which case, it will be subject to its content.<br/><br/>
    If you have any complaints regarding the use of our services, you can contact us by mail at the e-mail or physical address indicated at the beginning of this page. TIDI undertakes to always seek an amicable solution in the event of a dispute.<br/><br/>

<div className='AlignToRight'> Last update: September 2021 </div><br/><br/>


</span>

    </div>)
}

var legalNoticeTitle = <b>LEGAL NOTICE</b>

export {legalNoticeText, legalNoticeTitle};