import {nonAuthPostData} from "../apis/rest_api";
import {validate_value_is_neither_empty_nor_null_nor_undefined, checkIfDeviceIsAMobile} from "../common/utilities";
import {get_user_location_IPFind} from "./location_API";
import {get_Session_ID_cookies} from "./Session_cookies";
import {getUserType} from  "../common/utilities"

//////////////////////////////////////////// Methods to execute application services ////////////////////////////////////////////////////////////////////////////////////////

//////////////////// Base wrapper for creating or updating the permanent cookies on the DB ////////////////////

async function wrapper_send_permanent_cookie() {

	// get_analytics_acceptance_cookie();
	// get_language_cookie();

	var IP = get_IP_address_cookie();
	var User_ID = get_User_ID_permanent_cookies();
	var User_type =  getUserType();
	var Analytics_acceptance = get_analytics_acceptance_cookie();
	var Language = get_language_cookie();
		
	
	var request_json = create_permanent_cookies_json(IP, User_ID, User_type, Analytics_acceptance, Language);
	
	// console.log(request_json);
	
	let response = await nonAuthPostData(request_json);
	
	return response;
	
}

function create_permanent_cookies_json(IP, User_ID, User_type, Analytics_acceptance, Language) {
	
	var ID = 102;
	
	var objeto_json_cookie = {
		"IP_Address": IP,
		"Analytics_acceptance": Analytics_acceptance,
		"Language": Language
	};

	var objeto_json_contenido = { 
		"Permanent_cookie": objeto_json_cookie,
		"User_ID": User_ID,
		"User_type": User_type,
		'Session_ID': get_Session_ID_cookies()
	};

	var json_object = {
		"ID": ID,
		"contenido": objeto_json_contenido,
		'Session_ID': get_Session_ID_cookies(),
		'Received_via': localStorage.getItem('Received_via')
	};
	
	return(json_object);
	
}






//////////////////////////////////////////// Methods to set cookie values on localStorage ////////////////////////////////////////////////////////////////////////////////////////

function set_analytics_acceptance_cookie(Accepted) {

	if (checkIfDeviceIsAMobile()) {
		window.localStorage.setItem('Analytics_acceptance', Accepted);
	} else {
		// console.log(Accepted);
		// window.localStorage.setItem('Analytics_acceptance', Accepted);

		var now = new Date();
		var time = now.getTime();
		let two_years = 2 * 365 * 24 * 3600 * 1000; // This is in milliseconds
		var expireTime = time + two_years;
		now.setTime(expireTime);
		document.cookie = 'Analytics_acceptance=' + Accepted + ';expires=' + now.toUTCString();

		return false;
	}
}


// function set_privacy_policy_acceptance_cookie(Accepted) {
// 	// console.log(Accepted);
// 	window.localStorage.setItem('Privacy_policy_accepted', Accepted);
// 	return false;
// }


function set_language_cookie(Language) {
	// console.log(Language);
	window.localStorage.setItem('Language', Language);
	return false;
}







//////////////////////////////////////////// Methods to get cookies' values from localStorage ////////////////////////////////////////////////////////////////////////////////////////


function get_analytics_acceptance_cookie() {

	if (checkIfDeviceIsAMobile()) {
		return localStorage.getItem('Analytics_acceptance');
	}
	else {
		var match = document.cookie.match(new RegExp('(^| )' + 'Analytics_acceptance' + '=([^;]+)'));
		if (match) return match[2];
	}
}


function get_language_cookie() {
	return window.localStorage.getItem('Language');
}

function get_IP_address_cookie() {
	return window.localStorage.getItem('IP_Address');
}

function get_User_ID_permanent_cookies() {
	return window.localStorage.getItem('User_ID');
}


export {wrapper_send_permanent_cookie, set_analytics_acceptance_cookie,
	set_language_cookie, get_analytics_acceptance_cookie};