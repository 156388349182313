import {postData} from "../apis/rest_api"
import {get_Session_ID_cookies} from "./Session_cookies";
import {getIPAddress, getReceivedVia, getUserID, getUserType} from "../common/utilities";

export async function get_my_relations() {
    var ID = 154;

    var objeto_json = {
        "ID": ID,
        "contenido": {
            "User_type": getUserType(),
            "User_ID": getUserID()
        },
        'Session_ID': get_Session_ID_cookies(),
        'IP_Address': getIPAddress(),
        "Received_via": getReceivedVia()
    };

    //console.log(objeto_json);

    let response_Retrieve_information_from_ID = await postData(objeto_json);

    //console.log(response_Retrieve_information_from_ID);

    return response_Retrieve_information_from_ID;

}


