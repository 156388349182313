import {postData} from "../apis/rest_api";
import {get_Session_ID_cookies} from "./Session_cookies";
import {getUserType} from "../common/utilities";

async function Create_json_suboperation_QR(ID_Provider_Operation, Sale_ID, Change_ID, Return_ID, Client_ID, Provider_ID) {

    var ID = 5;

    var objeto_json_contenido = {
        "ID_Provider_Operation": ID_Provider_Operation,
        "Sale_ID": Sale_ID,
        "Change_ID": Change_ID,
        "Return_ID": Return_ID,
        "Client_ID": Client_ID,
        "Provider_ID": Provider_ID
    };

    var objeto_json = {
        "ID": ID,
        "contenido": {
            "Operation_data": objeto_json_contenido,
            "User_type": getUserType(),
            "User_ID": +localStorage.getItem('User_ID')
        },
        'Session_ID': get_Session_ID_cookies(),
        'IP_Address': localStorage.getItem('IP_Address'),
        "Received_via": window.localStorage.getItem('Received_via')
    };

    console.log(objeto_json);
    let response = await postData(objeto_json);

    console.log(response);
    return response;

}



export {Create_json_suboperation_QR};