import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
//import {Link, Redirect } from 'react-router-dom';
import "../functions/Session_cookies";
import {Create_json_change} from "../functions/Changes_submit";
import {get_info_from_ID} from "../functions/Retrieve_Information_From_ID.js";
import Dropdown from "react-bootstrap/Dropdown";
import file from "./Profile_Attributes";
import {
	validate_positive_integers_or_null,
	validate_integers_or_null,
	validate_string_is_not_empty_or_null,
	validate_positive_integers,
	email_validation,
	validate_number,
	validate_characters,
	validate_float_or_empty,
	calculatorOfSinglePrice,
	calculateTaxesInSuboperationForTotals,
	calculateTaxesInSuboperation,
	check_no_product_has_been_selected_on_change_ticket,
	validate_number_non_empty_non_Null, validate_pure_float, isUserACompany, email_validation_or_empty
} from "../common/utilities";
import {loadingPictureCode} from "../common/ajax-loader";
import {updateClientInOperation} from "../functions/Update_client_in_operation";


export class NewChange extends React.Component {

	constructor(props) {
        super(props);
        this.state = this.props.data[0]
		}


	renderChangeTableHeader = () => {
		  var first_line = this.state.SaleHeaders[0];

		first_line = this.renameThePriceWithCurrencyInSaleTableHeader(first_line);

		if (first_line === undefined) {
			  return null;
		  } else {
			  let header = Object.keys(first_line)
			  return header.map((key, index___) => {
					return <th key={index___}>{key}</th>
			  })
		  }
	}

   decisionMakingHowManyRowsForChange = () => {
		//const NoOfRows_Sale = this.state.NoOfRows_Sale;
		var array = [];

		for (let step = 0; step < 1; step++) {
			//console.log(step);
			array.push(this.renderChangeTableData(step));
		}
		//console.log(array);
		return array;
	}


	renderChangeTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {

			// console.log(this.state.PopUpMoreInfo);
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			return Suboperation_data.Info.Changes_info.Change_data.map((row, index) => {

				const {Product_data, Units, Promotion, Discount} = row;
				return (
					<tr>
						<td>{index+1}</td>
						<td title={Product_data.Product}> {Product_data.Product} </td>
						<td title={Product_data.Brand}> {Product_data.Brand} </td>
						<td title={Product_data.Product_Type}> {Product_data.Product_Type} </td>
						<td title={Product_data.Product_Subtype}> {Product_data.Product_Subtype} </td>
						<td title={Product_data.Sellers_ID_Reference}> {Product_data.Sellers_ID_Reference} </td>
						<td title={Product_data.Price}> {Product_data.Price} </td>
						<td title={Product_data.Price_without_VAT}> {Product_data.Price_without_VAT} </td>
						<td title={Product_data.VAT}> {Product_data.VAT} </td>
						<td title={Units}> {Units} </td>
						<td title={Promotion}> {Promotion} </td>
						<td title={Discount}> {Discount} </td>
					</tr>
				)
			})
		}

		else {


			const Change_index = +row_index + 1;
			// console.log(this.state.ChangeContent);
			return this.state.ChangeContent.map((header, index) => {
				// console.log(this.state.ChangeContent);
					const {
						Index, Product, Brand, Type, Subtype, Reference, Price, Price_without_VAT, VAT, Units,
						Promotion, Discount
					} = header //destructuring

					return (
						<tr key={Change_index} data-rowid={Change_index} onClick={this.clickOnTableRow}
							onChange={(e) => this.changeOnTableRow(e)}>
							<td title={Index}>{Index}</td>
							<td title={Product}>{Product}</td>
							<td title={Brand}>{Brand}</td>
							<td title={Type}>{Type}</td>
							<td title={Subtype}>{Subtype}</td>
							<td title={Reference}>{Reference}</td>
							<td title={Price}>{Price}</td>
							<td title={Price_without_VAT}>{Price_without_VAT}</td>
							<td title={VAT}>{VAT}</td>
							<td title={Units}>{Units}</td>
							<td title={Promotion}>{Promotion}</td>
							<td title={Discount}>{Discount}</td>
						</tr>
					)
				}
			)
		}
	}


	changeOnTableRow = (event) => {

		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}




   handleChangeItem = (e, field, indice) => {

	   const Row_JS_index = +indice - 1;
	   //console.log(Row_JS_index);
	   //console.log(this.state.NewChanges[Row_JS_index]);
	   if (field==='Product') {
		    this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Product: e })
			);

			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }

	   if (field==='Brand') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Brand: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='Type') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Type: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='Subtype') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Subtype: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='Reference') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Reference: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='Price') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Price: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='PricewoVAT') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {PricewoVAT: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='VAT') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {VAT: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='Units') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Units: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='Promotion') {
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Promotion: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }
	   if (field==='Discount') {
		   //console.log(this.state.NewChanges);
		   this.setState(
			  Object.assign(this.state.NewChanges[Row_JS_index], {Discount: e })
			);
			//console.log(this.state.NewChanges);
			//Check if a new row should be added to the table
			this.addOneMoreRowToChangesTable();
	   }

	   return null;
   }


   addOneMoreRowToChangesTable= () => {

	   const Row_index = this.state.RoIndex;

	   const NoOfRows = this.state.NoOfRows;

			if (+NoOfRows === +Row_index) {
				// If this is the last row on the table and something has been written, add a new row to the table and to the state.
				var current_rows = this.state.NewChanges;
				const new_row = this.state.NewChange[0];
				const clone = Object.assign({} , new_row);
				current_rows.push(clone);
				this.setState(
					Object.assign(this.state.NewChanges[+Row_index], {No: +Row_index+1  })
				);

				//this.setState({NoOfRows: +NoOfRows+1});
			}
			return null;
   }

	NewChangeID = () => {

		if (this.state.PopUpMoreInfo !== null) {
			return (
				<div className='IdChange'>
				   <span className='IdChangeID1'> {'ID'} </span>
				   <span className='IdChangeID2'> {this.IdChangeOldChange()} </span>
				</div>
			)

		} else {
			return (
			   <div className='IdChange'>
				   <span className='IdChangeID1'> {'ID'} </span>
				   <div className='verticalSeparatorFirstBoxesChangeScreen'> </div>
				   <span className='IdChangeID2'>  {this.retrieveInformationFromIDForm()} </span>

					<div className='horizontalSeparatorFirstBoxesChanges'> </div>
				</div>
			)
		}
	}

   IdChangeOldChange = () => {
	   var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
	   return (
			   <span className='IdChangeID2Previous'> {Relevant_Operation.ID} </span>
	   )
   }

   retrieveInformationFromIDForm = () => {

		let div = "IdChangeID3";
		if (this.state.PressedEndButtonWithoutID === true) {
			div = "IdChangeID3Red";
		}

	   return [
		   this.messageWrongID(),
			<input
				className={div}
				autoFocus
				type="numeric"
				placeholder='Enter ID'
				disabled={this.state.IDInputIsDisabled}
				onChange={(e) => {this.writeIDForInformation(e.target.value)}}
			/>,

			<Button className="ID_Change_button" block size="lg" onClick= {(e) => this.retrieveInformationFrom(e.target) }>
				{'Fetch information'}
			</Button>
	   ]
   }

   messageWrongID = () => {

		if ( this.state.HighLevelErrors.oldSaleID ) {
			return <div className='messageWrongID'> ID must be an integer without spaces</div>;
	   } else { return null;}
   }



   writeIDForInformation = (e) => {

		this.validateOldSaleID(e);
	   //console.log(e);
	   this.setState({IDRequired : e});
	   return null;
   }


	validateOldSaleID = (oldSaleID) => {
		// console.log(oldSaleID);
		// Validates the old sale ID

		if (validate_integers_or_null(oldSaleID) || oldSaleID === undefined) {
			// console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrors, {oldSaleID: false })
			);
		} else {
			// console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrors, {oldSaleID: true })
			);
		}
	}



   retrieveInformationFrom = (e) => {

	   // Render the loading icon
	   this.setState({WaitingForRequest: true});

	    const ID = this.state.IDRequired;

	    //	Calling the API
	    let IDInfo = function() {
			return get_info_from_ID(ID).then(json => {return json}) ;
	    }

	    let idInfo = IDInfo();

	    // Once the response is received
	    idInfo.then( (response) => {

			console.log(response);
			if (response.contenido.Retrieve_ID_available_products.Status === "OK") {
				//console.log('Status OK');

				this.fillInOperationsArrayChooseChangesTable(response.contenido.Retrieve_ID_available_products.Available_products.length);

				this.setState(
					Object.assign(this.state.ID_info, {Available_products: response.contenido.Retrieve_ID_available_products.Available_products })
				);
				// console.log(response.contenido.Retrieve_ID_available_products.Available_products);

				this.setState(
					Object.assign(this.state.ID_info, {Client: response.contenido.Retrieve_ID_available_products.Client })
				);

				this.setEmailFromPreviousSale(response.contenido.Retrieve_ID_available_products.Client.Email);

				this.renderAvailableProduts();

				this.renderClientInformation(response.contenido.Retrieve_ID_available_products.Client.Email);
				this.validateHighLevelErrorsInChooseChangesTable('Units', true);
				this.validateHighLevelErrorsInChooseChangesTable('Reason', true);
				this.setState({ClientConfirmsSendingByEmailEmail: ''});

				this.setState({IDInputIsDisabled: true}, () => this.checkErrorMessageOnButton());
				this.setState({PressedEndButtonWithoutID: false}, () => this.checkErrorMessageOnButton());

				// Set the New_ID_Provider_Operation
				this.setState({New_ID_Provider_Operation: this.state.IDRequired})

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				this.setState(
					Object.assign(this.state.HighLevelErrors, {Non_existing_ID: false})
				);
				this.setState(
					Object.assign(this.state.HighLevelErrors, {Empty_ID: false})
				);

				// this.resetHighLevelErrorsArrayAfterIDIsRetrieved();

				return null;

			} else {
				console.log('Status NOK');

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				if (response.contenido.Retrieve_ID_available_products.Error_code === 'Retrieve_ID_Information_1') {
					this.setState(
						Object.assign(this.state.HighLevelErrors, {Non_existing_ID: true})
					);
				}
				if (response.contenido.Retrieve_ID_available_products.Error_code === 'Retrieve_ID_Information_3') {
					// Reset back that the ID currently exists
					this.setState(
						Object.assign(this.state.HighLevelErrors, {Non_existing_ID: false})
					);
					this.setState(
						Object.assign(this.state.HighLevelErrors, {Empty_ID: true})
					);

				}
				if (response.contenido.Retrieve_ID_available_products.Error_code === 'Retrieve_ID_Information_2') {
					this.setState(
						Object.assign(this.state.HighLevelErrors, {Non_existing_ID: true})
					);
				}

				return null;
			}

	    } )
	   return null;
   }


   resetHighLevelErrorsArrayAfterIDIsRetrieved = () => {

	   this.setState({HighLevelErrors: {
			   Ship_ping: false,
			   Client_Email: false,
			   OldSaleID: false,
			   Sales: {Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
				   PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false},
			   ChooseChange: {Units: true, Reason: true, UnitsBiggerThanExpected: false, CharactersInUnits: false, AllUnits: false},
			   Non_existing_ID: false, Empty_ID: false
		   }});

	   return null;
   }

   setEmailFromPreviousSale = (Email_original) => {
	    this.setState(
					Object.assign(this.state.Client, {Email: Email_original })
				);
		return null;
   }

	renderAvailableProduts = () => {
		const AvProds = this.state.ID_info.Available_products;
		// console.log(AvProds);
		var ChooseChanges = [];
		var ChangeContents = [];

		var Index = 1;

		AvProds.forEach(AvProd => {

				const {Units, Promotion, Discount, Sale_data} = AvProd
				const {Product, Brand, Product_Type, Product_Subtype, Sellers_ID_Reference, Price, Price_without_VAT, VAT} = Sale_data
			console.log(Sale_data);

				var EachSale = {};
				var ChooseEachChange = {};

				EachSale['Index'] = Index;
				EachSale['Product'] = Product;
				EachSale['Brand'] = Brand;
				EachSale['Type'] = Product_Type;
				EachSale['Subtype'] = Product_Subtype;
				EachSale['Reference'] = Sellers_ID_Reference;
				EachSale['Price'] = Price;
				EachSale['Price_without_VAT'] = Price_without_VAT;
				EachSale['VAT'] = VAT;
				EachSale['Units'] = Units;
				EachSale['Promotion'] = Promotion;
				EachSale['Discount'] = Discount;
				EachSale['Units_to_change'] = null
				EachSale['Reason'] = null

				ChangeContents.push(EachSale);

				ChooseEachChange['Choose_change_index'] = Index;
				ChooseEachChange['Units'] = '';
				ChooseEachChange['Reason'] = '';

				ChooseChanges.push(ChooseEachChange);
				Index = +Index +1;
			}

		)

		this.setState({ChangeContent: ChangeContents});
		this.setState({NoOfRows_Change: ChangeContents.length});
		this.setState({ChooseChangesFixed: ChooseChanges});

		// ChooseChangesFixed
		// ChooseChangesContent Era esto antes

		return null;
	}

	fillInOperationsArrayChooseChangesTable = (numberOfRows) => {

		// let originalArray = [{Units: false, Reason: false}];
		let newArray = [];

		while (newArray.length <numberOfRows) {
			newArray.push({Units: false, Reason: false, UnitsBiggerThanExpected: false})
		}

		this.setState(
			Object.assign(this.state.OperationsErrors, {ChooseChange: newArray })
		);

		return null;
	}

	renderClientInformation = (email) => {

		this.setState(
			Object.assign(this.state.Client, {Email: email })
		);

		// this.evaluateInputInSales('ClientEmail', null, null, email);
		this.evaluateEmailInSalesWhenRenderingTheOldEmailAddress();

		return null;
	}


	ChooseChangesTable = () => {

		return (
			<table className='ChooseChangesTable'>
				<thead className='ChooseChangesTableHeader'>
					<tr>{this.renderChooseChangeTableHeader()}</tr>
				</thead>
				<tbody className='ChooseChangesTableBody'>
					{this.decisionMakingHowManyPotentialChanges()}
				</tbody>
			</table>
		)
	}

	renderChooseChangeTableHeader = () => {
		  var first_line = this.state.ChooseChangesHeaders[0];

		  if (first_line === undefined) {
			  return null;
		  } else {
			  let header = Object.keys(first_line)
			  return header.map((key, index__) => {
					return <th key={index__}>{key}</th>
			  })
		  }
	}

	decisionMakingHowManyPotentialChanges = () => {
		// const NoOfRows_Change = this.state.NoOfRows_Change;
		var array = [];
		for (let step = 0; step < 1; step++) {
			//console.log(step);
			array.push(this.renderChooseChangeTableData(step));
		}

		return array;
	}


	renderChooseChangeTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			// console.log(Suboperation_data.Info.Changes_info.Change_data);
			return Suboperation_data.Info.Changes_info.Change_data.map((row, index) => {

				const {Product_data, Units} = row;

				return (
					<tr key={index}>
						<td title={+index+1}>{+index+1}</td>
						<td title={Units}>{Units}</td>
						<td title={Product_data.Reason}>{Product_data.Reason}</td>
					</tr>
				)
			})

		} else {

			const Change_index = +row_index + 1

			return this.state.ChooseChangesFixed.map((header, index) => {
					const {Choose_change_index} = header //destructuring

					return (
						<tr key={Choose_change_index} data-rowid={Change_index} onClick={this.clickOnChooseChangesTableRow}
							onChange={(e) => this.changeOnChooseChangeTableRow(e)}>
							<td>{+index+1}</td>
							<td>
								{this.inputChooseTablefunction('Units', index)}
							</td>
							<td>
								{this.inputChooseTablefunction('Reason', index)}
							</td>
						</tr>
					)
				}
			)
		}
	}

	clickOnChooseChangesTableRow = (event) => {

		//const id = event.currentTarget.getAttribute("data-rowid");
		////console.log(id);
		//this.setState({RoIndex: id});
		//return id;
		return null;
	}

	changeOnChooseChangeTableRow = (event) => {

		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}


	inputChooseTablefunction = (field, indice) => {
		// console.log(field);

		let div = 'inputChooseTablefunctionUnits';
		// console.log(this.state.OperationsErrors.ChooseChange);
		// console.log(this.state.OperationsErrors.ChooseChange[indice]);
		// console.log(indice)
		// console.log(this.state.PressedEndButton);
		// console.log(this.state.OperationsErrors.ChooseChange[indice].Units);
		// console.log(this.state.HighLevelErrors.ChooseChange.AllUnits );
		// console.log(this.state.OperationsErrors.ChooseChange[indice].UnitsBiggerThanExpected);
		if (this.state.PressedEndButton === true &&
			((this.state.OperationsErrors.ChooseChange[indice].Units === true || this.state.HighLevelErrors.ChooseChange.AllUnits === true)
		  || this.state.OperationsErrors.ChooseChange[indice].UnitsBiggerThanExpected)
		) {
			div = 'inputChooseTablefunctionUnitsRed';
		}

		let div2 = 'inputChooseTablefunctionReason';
		// console.log(indice)
		// console.log(this.state.OperationsErrors.ChooseChange[indice].Reason);
		// console.log(this.state.PressedEndButton);
		if (this.state.OperationsErrors.ChooseChange[indice].Reason === true &&
			this.state.PressedEndButton === true) {
			div2 = 'inputChooseTablefunctionReasonRed';
		}

		//const row_no = this.state.RoIndex;
		if (field === 'Units') {
			return (
				<input
					type="text"
					className={div}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Reason') {
			return (
				<input
					type="text"
					// className='inputChooseTablefunctionReason'
					className = {div2}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}
	}

	handleChooseChangeItem = (e, field, indice) => {

		// console.log(this.state.ChangeContent);
		// console.log(indice);
	    if (field==='Units') {
	    	if (e === '') { e = null}
		     this.setState(
		 	  Object.assign(this.state.ChangeContent[indice], {Units_to_change: e }), () => {this.validateInputInChooseChangesTable(field, indice, e)}
		 	);
	    }

	    if (field==='Reason') {
			//console.log('true');
		    this.setState(
		 	  Object.assign(this.state.ChangeContent[indice], {Reason: e }), () => {this.validateInputInChooseChangesTable(field, indice,e)}
				);
	    }

	   return null;
   }


   checkAreChooseChangesUnitsGreaterThanSoldUnits = (indice, input) => {

	   // console.log(+input)
	   // console.log(+this.state.ChangeContent[indice].Units)

	   if (+input <= +this.state.ChangeContent[indice].Units) {
		   this.setState(
			   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {UnitsBiggerThanExpected: false}), () => this.validateHighLevelErrorsInChooseChangesTable('UnitsBiggerThanExpected', true)
		   );
	   } else {
		   this.setState(
			   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {UnitsBiggerThanExpected: true}), () => this.validateHighLevelErrorsInChooseChangesTable('UnitsBiggerThanExpected', true)
		   );
	   }

		return null;
   }

   validateInputInChooseChangesTable = (field, indice, input) => {

	   if (field === 'Units') {
		   console.log(input)
		   console.log(input==='')
	   	this.checkAreChooseChangesUnitsGreaterThanSoldUnits(indice, input);
		   if (validate_positive_integers_or_null(input) || input === undefined) {
		   	console.log(input)
			   console.log(input==='')
			   // There's no error
			   this.setState(
				   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
			   );

			   if (input === '' || input === undefined) {

				   if (this.state.ChangeContent[indice].Reason === null) {
					   // We have deleted the previously existing Units or it never existed. Field Reason is not populated --> Final situation (Units: '', Reason: '')
					   this.setState(
						   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
					   );
				   } else {
				   	if (input === '') {
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
						);
					} else {
						// Reason is not missing and we have deleted Units field --> You must provide the units you want to change
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
						);
					}
				   }

			   } else {
				   if (this.state.ChangeContent[indice].Reason !== null) {
				   	if (this.state.ChangeContent[indice].Reason === ''){
						// User added and then removed the Reason. Assign an error to Reason (if there's no reason provided, the user cannot conclude the transaction)
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
						);
					} else {
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
						);
					}
				   } else {
					   // Assign an error to Reason (if there's no reason provided, the user cannot conclude the transaction)
					   this.setState(
						   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					   );
				   }
			   }

		   } else {
				// There's some error
			   if (input === null) {
			   	// The user has removed the input in Units - you can check up if Units is now null, not ''
				//    this.setState(
				// 	   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
				//    );
				   if (this.state.ChangeContent[indice].Reason === '' || this.state.ChangeContent[indice].Reason === null) {
				   	// Reason is empty
					   this.setState(
						   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					   );
					   this.setState(
						   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', false)
					   );
				   } else {
				   	// Reason should not be provided if Units is not populated
					   this.setState(
						   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					   );
					   this.setState(
						   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					   );
				   }
			   } else {
				   this.setState(
					   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
				   );
			   }
		   }
	   }

	   if (field === 'Reason') {

		   // let units = this.state.OperationsErrors.ChooseChange[indice].Units;
		   // console.log(this.state.OperationsErrors.ChooseChange[indice]);
		   // if (input !== '' && this.state.OperationsErrors.ChooseChange[indice].Units === false) {

		   if (input === '' || input === undefined) {

			   // console.log(this.state.ChangeContent[indice]);
			   if (this.state.ChangeContent[indice].Units_to_change === null || this.state.ChangeContent[indice].Units_to_change === '') {
				   // We have deleted the previously existing Reason or it never existed. Field Units is not populated --> Final situation (Reason: '', Units: '')
				   this.setState(
					   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
				   );
				   this.setState(
					   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
				   );
			   } else {
				   // Units is not missing and we have deleted Reason field --> You must provide the Reason you want to change
				   this.setState(
					   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
				   );
			   }

		   } else {
			   // console.log(this.state.ChangeContent[indice]);
			   if (this.state.ChangeContent[indice].Units_to_change !== null) {
				   this.setState(
					   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
				   );
				   this.setState(
					   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
				   );
			   } else {
				   // Assign an error to Units (if there's no Units provided, the user cannot conclude the transaction)
				   this.setState(
					   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
				   );
				   this.setState(
					   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
				   );
			   }
		   }


	   }

	   // console.log(this.state.OperationsErrors.ChooseChange);
	   return null;

   }


	validateHighLevelErrorsInChooseChangesTable = (field, repeat) => {
		// Assigns the high level errors to Units and Reasons. The input Field is for performance

		let chooseChangesErrors = this.state.OperationsErrors.ChooseChange;
		let changeContentValues = this.state.ChangeContent;
		// console.log(chooseChangesErrors);
		// this.state.ChangeContent[indice], {Units_to_change

		if (field === 'Units') {
			// console.log(changeContentValues);
			// If no Units is selected, there's an error. Also, if there's any true, there's an error.
			let check_empties = [];
			let check_errors = [];
			let check_missing_units = [];
			// let check_misalignment_with_reasons = [];


			for (let step = 0; step < chooseChangesErrors.length; step++) {
				// Check empty --> We want to eliminate the error message if at least 1 Units is populated (there are more conditions to check)
				if (changeContentValues[step].Units_to_change !== null) {
					check_empties.push(true);
				}
				//Check if there is any true in the Units' errors (this corresponds to, for instance, Reason being populated but Units unpopulated)
				if (chooseChangesErrors[step].Units === true) {
					check_missing_units.push(true);
				}

				// // Check the Reason is populated but the Units are not populated --> Error
				// if (changeContentValues[step].Units_to_change === '' && changeContentValues[step].Reason !== '') {
				// 	check_misalignment_with_reasons.push(false);
				// }
				// Check strings and not integers
				if (validate_characters(+changeContentValues[step].Units_to_change) === true) {
					check_errors.push(true);
					// For decision of which input field must be in red
					this.setState(
						Object.assign(this.state.HighLevelErrors.ChooseChange, {CharactersInUnits: true})
					);
				}
			}

			if (check_errors.includes(true)) {
				this.setState(
					Object.assign(this.state.HighLevelErrors.ChooseChange, {CharactersInUnits: true})
				);
			} else {
				this.setState(
					Object.assign(this.state.HighLevelErrors.ChooseChange, {CharactersInUnits: false})
				);
			}
			// console.log(check_errors);
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {AllUnits:
						!check_empties.includes(true) })
			);

			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {Units: check_missing_units.includes(true)})
			);

			if (repeat !== false) {
				this.validateHighLevelErrorsInChooseChangesTable('Reason', false);
			}
			// console.log(!check_empties.includes(true) ||
			// 	check_errors.includes(true));
		}

		if (field === 'Reason') {
			// If there's any true, there's an error.
			let checks = []
			for (let step = 0; step < chooseChangesErrors.length; step++) {
				// Check true
				if (chooseChangesErrors[step].Reason === true) {
					checks.push(true);
				}
			}
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {Reason: checks.includes(true)})
				// , () => console.log(this.state.HighLevelErrors.ChooseChange)
			);
			// console.log(checks.includes(true));

			this.validateHighLevelErrorsInChooseChangesTable('Units', false);
		}

		if (field === 'UnitsBiggerThanExpected') {
			var check_bigUnits = []
			for (let step = 0; step < chooseChangesErrors.length; step++) {
				console.log(chooseChangesErrors[step].UnitsBiggerThanExpected)
				if (chooseChangesErrors[step].UnitsBiggerThanExpected) {
					check_bigUnits.push(true)
				}
			}
			let result = false;
			// console.log(check_bigUnits.includes(true))
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {UnitsBiggerThanExpected: check_bigUnits.includes(true)}),
				() => console.log(this.state.HighLevelErrors.ChooseChange)
			);
		}

		this.checkErrorMessageOnButton();
		this.ErrorMessagesInChangeWindow();

		return null;

	}



	renderSaleTableHeader = () => {
		var first_line = this.state.SaleHeaders[0];

		first_line = this.renameThePriceWithCurrencyInSaleTableHeader(first_line);

		if (first_line === undefined) {
		  return null;
		} else {
		  let header = Object.keys(first_line)
		  return header.map((key, index____) => {
				return <th key={index____}>{key}</th>
		  })
		}
   }

	renameThePriceWithCurrencyInSaleTableHeader = (header) => {

		var ccy = null;
		if (isUserACompany()) {
			ccy = this.state.Profile.Company.Currency;
		} else {
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			ccy = Suboperation_data.Info.Changes_info.Change_data[0].Product_data.Currency;
		}


		// Eliminate the currency header in case it's missing
		let newPrice = 'Price (' + ccy + ')';

		if (ccy === null) {
			newPrice = 'Price';
		}
		let newPriceWoVat = 'Price w/o VAT (' + ccy + ')';
		if (ccy === null) {
			newPriceWoVat = 'Price w/o VAT';
		}

		const newHeader = {};

		newHeader['No'] = header['No'];
		newHeader['Product'] = header['Product'];
		newHeader['Brand'] = header['Brand'];
		newHeader['Type'] = header['Type'];
		newHeader['Subtype'] = header['Subtype'];
		newHeader['Reference'] = header['Reference'];
		newHeader[newPrice] = header['Price'];
		newHeader[newPriceWoVat] = header['Price w/o VAT'];
		newHeader['VAT (%)'] = header['VAT (%)'];
		newHeader['Units'] = header['Units'];
		newHeader['Promotion'] = header['Promotion'];
		newHeader['Discount (%)'] = header['Discount (%)'];

		return newHeader;
	}

	decisionMakingHowManyRowsForSale = () => {
		const NoOfRows_Sale = this.state.NoOfRows_Sale;

		var array = [];
		for (let step = 0; step < NoOfRows_Sale; step++) {
			//console.log(step);
			array.push(this.renderSaleTableData(step));
		}
		//console.log(array);
		return array;
	}

	getSuboperationFromOperation_ID = (row_index) => {

		const Ops = this.state.HistoricalOperationsArray;

		var resultado = null;
		Ops.forEach(holi => {
			if (holi.Operation_id === row_index ) {
				resultado = holi;
				return null;
			}
		} )

		return resultado;
	}

	renderSaleTableData = (row_index) => {
		if (this.state.PopUpMoreInfo !== null) {
			// console.log(this.state.PopUpMoreInfo);
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);


			// console.log(Suboperation_data.Info.Changes_info.Change_data);
			return Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {


				const {Product_data, Units, Promotion, Discount} = row;

				return (
					<tr>
						<td>{index+1}</td>
						<td title={Product_data.Product}> {Product_data.Product} </td>
						<td title={Product_data.Brand}> {Product_data.Brand} </td>
						<td title={Product_data.Product_Type}> {Product_data.Product_Type} </td>
						<td title={Product_data.Product_Subtype}> {Product_data.Product_Subtype} </td>
						<td title={Product_data.Sellers_ID_Reference}> {Product_data.Sellers_ID_Reference} </td>
						<td title={Product_data.Price}> {Product_data.Price} </td>
						<td title={Product_data.Price_without_VAT}> {Product_data.Price_without_VAT} </td>
						<td title={Product_data.VAT}> {Product_data.VAT} </td>
						<td title={Units}> {Units} </td>
						<td title={Promotion}> {Promotion} </td>
						<td title={Discount}> {Discount} </td>
					</tr>
				)
			})


		}
		else {
			const sale_index = +row_index + 1;
			return this.state.SaleHeaders.map((header, index) => {
					 //const {No, Product, Brand, Type, Subtype, Reference, Price, PricewoVAT, VAT, Units, Promotion, Discount} = header //destructuring

						 return (
						<tr key={sale_index} data-rowid={sale_index} onClick={this.clickOnTableRow} onChange= {(e) => this.changeOnTableRow(e)}>
						   <td>{sale_index}</td>
						   <td>{this.inputSaleTablefunction('Product',sale_index)}</td>
							<td>{this.inputSaleTablefunction('Brand',sale_index)}</td>
							<td>{this.inputSaleTablefunction('Type',sale_index)}</td>
							<td>{this.inputSaleTablefunction('Subtype',sale_index)}</td>
							<td>{this.inputSaleTablefunction('Reference',sale_index)}</td>
							<td>{this.inputSaleTablefunction('Price',sale_index)}</td>
							<td>{this.inputSaleTablefunction('PricewoVAT',sale_index)}</td>
							<td>{this.inputSaleTablefunction('VAT',sale_index)}</td>
							<td>{this.inputSaleTablefunction('Units',sale_index)}</td>
							<td>{this.inputSaleTablefunction('Promotion',sale_index)}</td>
							<td>{this.inputSaleTablefunction('Discount',sale_index)}</td>
						</tr>
					 )
				}
			)
		}
	}

	clickOnTableRow = (event) => {
		const id = event.currentTarget.getAttribute("data-rowid");
		this.setState({RoIndex: id});
		return id;
	}

	changeOnTableRow = (event) => {

		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}


	inputSaleTablefunction = (field, indice) => {
		// const indice=field.indice;

		let classN = 'inputSaleTable';
		let field_name = field.valueOf();
		let index = +indice.valueOf() -1;

		// Check if the input field must be red (due to an error in the input introduced).
		if (this.state.PressedEndButton === true &&
			(index+1 !== this.state.NewSales.length || this.state.NewSales.length === 1) ) {
			// After pressing the Button, either:
			// a) This row is not the final one
			// b) It is the final but the table has only one row

			if (this.state.OperationsErrors.Sales[index][field_name] === true) {
				classN = 'inputSaleTable2';
			}
			if (this.state.OperationsErrors.Sales[index]['Reference'] === true && field_name === 'Reference') {
				classN = 'inputSaleTable2';
			}
			//If the error appears on Price field, both Price and PriceWOVAT must be in red. This means an additional check for PriceWOVAT
			if (this.state.OperationsErrors.Sales[index]['Price'] === true && field_name === 'PricewoVAT') {
				classN = 'inputSaleTable2';
			}
			if (this.state.OperationsErrors.Sales[index]['PricewoVAT'] === true && field_name === 'Price') {
				classN = 'inputSaleTable2';
			}
		}


		if (field === 'Product') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Brand') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Type') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Subtype') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Reference') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Price') {
			return (
				<input
					type="text"
					className={classN}
					disabled = {this.disableInputField(index, field) || this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'PricewoVAT') {
			return (
				<input
					type="text"
					className={classN}
					disabled = {this.disableInputField(index, field) || this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'VAT') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Units') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Promotion') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Discount') {
			return (
				<input
					type="text"
					className={classN}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleSaleItem(e.target.value, field, indice)}
				/>
			)
		}
	}


	checkIsRowTotallyEmpty = (index) => {

		let checkProduct = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Product);
		let checkBrand = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Brand);
		let checkType = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Type);
		let checkSubtype = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Subtype);
		let checkReference = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Reference);
		let checkPrice = this.state.Disabling.Sales[index].Price;
		let checkPricewoVAT = this.state.Disabling.Sales[index].PricewoVAT;
		let checkPricesWrapper = checkPrice || checkPricewoVAT;
		let checkVAT = !validate_string_is_not_empty_or_null(this.state.NewSales[index].VAT);
		let checkUnits = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Units);
		let checkPromotion = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Promotion);
		let checkDiscount = !validate_string_is_not_empty_or_null(this.state.NewSales[index].Discount);

		// console.log(checkProduct)
		// console.log(checkBrand)
		// console.log(checkPricesWrapper)
		// console.log(
		// 	checkProduct && checkBrand && checkType && checkSubtype && checkReference && checkVAT && checkUnits &&
		// 	checkPromotion && checkDiscount && !checkPricesWrapper
		// )

		return checkProduct && checkBrand && checkType && checkSubtype && checkReference && checkVAT && checkUnits &&
			checkPromotion && checkDiscount && !checkPricesWrapper;

	}


	evaluateSalesTableBasedOnState = (index, field) => {

		let check_empty_row = this.checkIsRowTotallyEmpty(index);
		console.log(validate_string_is_not_empty_or_null(this.state.NewSales[index].Product))
		console.log(check_empty_row);

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {Product: false});
		} else {
			if (validate_string_is_not_empty_or_null(this.state.NewSales[index].Product)) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Product: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Product: true});
			}
		}

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {Reference: false});
		} else {
			if (validate_string_is_not_empty_or_null(this.state.NewSales[index].Reference)) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Reference: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Reference: true});
			}
		}

		if (check_empty_row && index !== 0) {
			console.log('soy mudo')
			Object.assign(this.state.OperationsErrors['Sales'][index], {Price: false});
			// Also assign to PricewoVAT that it's written, so that the red input field does not come into action
			Object.assign(this.state.OperationsErrors['Sales'][index], {PricewoVAT: false});
		} else {

			if ((validate_number_non_empty_non_Null(this.state.NewSales[index].Price) || validate_number_non_empty_non_Null(this.state.NewSales[index].PricewoVAT))
			) {
				// console.log('soy bobo')
				Object.assign(this.state.OperationsErrors['Sales'][index], {Price: false});
				// Also assign to PricewoVAT that it's written, so that the red input field does not come into action
				Object.assign(this.state.OperationsErrors['Sales'][index], {PricewoVAT: false});
			} else {
				// console.log('soy true')
				Object.assign(this.state.OperationsErrors['Sales'][index], {Price: true});
				Object.assign(this.state.OperationsErrors['Sales'][index], {PricewoVAT: true});
			}
		}

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {VAT: false});
		} else {
			if (validate_number(this.state.NewSales[index].VAT) && this.state.NewSales[index].VAT !== null) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {VAT: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {VAT: true});
			}
		}

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {Units: false});
		} else {
			if (validate_positive_integers(this.state.NewSales[index].Units)) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Units: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Units: true});
			}
		}

		if (check_empty_row && index !== 0) {
			Object.assign(this.state.OperationsErrors['Sales'][index], {Discount: false});
		} else {
			if (validate_float_or_empty(this.state.NewSales[index].Discount)) {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Discount: false});
			} else {
				Object.assign(this.state.OperationsErrors['Sales'][index], {Discount: true});
			}
		}



		return null;


	}

	evaluateEmailInSalesWhenRenderingTheOldEmailAddress = () => {

		this.setState(
			Object.assign(this.state.OperationsErrors, {['ClientEmail']: false })
		);

		this.setState(
			Object.assign(this.state.HighLevelErrors, {Client_Email: false })
		);

		return null;
	}



	evaluateInputInSales = (field, index, table, input) => {
		// console.log('holi')

		if (field === 'Shipping') {
			// console.log(input);

			if (validate_number(input)) {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: false }), () => this.validateSaleRequestInput()
				);

				// Object.assign(this.state.OperationsErrors, {[field]: false});
			} else {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: true }), () => this.validateSaleRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: true});
			}
		}

		if (field === 'ClientEmail') {
			if (email_validation(input)) {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: false }), () => this.validateSaleRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: false});
			} else {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: true }), () => this.validateSaleRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: true});
			}
		}


		if (table !== null) {
			this.evaluateSalesTableBasedOnState(index, field);
			let table_name = table.valueOf();
			let field_name = field.valueOf();

			if (field_name === 'Product') {
				// if (validate_string_is_not_empty_or_null(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'Reference') {
				// if (validate_string_is_not_empty_or_null(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'Price') {
				// if (validate_number(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// 	// Also assign to PricewoVAT that it's written, so that the red input field does not come into action
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {'PricewoVAT': false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'PricewoVAT') {
				// if (validate_number(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// 	// Also assign to Price that it's written, so that the red input field does not come into action
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {'Price': false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'VAT') {
				// if (validate_number(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'Units') {
				// if (validate_integers(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}
			if (field_name === 'Discount') {
				// if (validate_float_or_empty(input)) {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
				// } else {
				// 	Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
				// }
			}


		}

		// this.state.ErrorMessage;
		// console.log(this.state.OperationsErrors);
		return null;
	}

	disableInputField = (index, field_name) => {
		// Disables a price field in case it's required.
		return this.state.Disabling.Sales[index][field_name]
	}


   handleSaleItem = (e, field, indice) => {

	   const Row_JS_index = +indice - 1;
	   //console.log(Row_JS_index);
	   //console.log(this.state.NewSales[Row_JS_index]);

	   // this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);

	   if (field==='Product') {
		    this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Product: e }), () => {
					this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
					this.validateSaleRequestInput(Row_JS_index)
				}
			);

			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }

	   if (field==='Brand') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Brand: e }), () => {
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='Type') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Type: e }), () => {
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='Subtype') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Subtype: e }), () => {
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='Reference') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Reference: e }), () => {
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='Price') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Price: e }), () => {
				   this.setState({LastChanged: 'Price'});
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
		   // Disable PricewoVAT in this row
		   if (e !== '') {
			   this.setState(
				   Object.assign(this.state.Disabling.Sales[Row_JS_index], {PricewoVAT: true})
			   );
		   } else {
			   this.setState(
				   Object.assign(this.state.Disabling.Sales[Row_JS_index], {PricewoVAT: false})
			   );
		   }
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='PricewoVAT') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {PricewoVAT: e }), () => {
				   this.setState({LastChanged: 'Price'});
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
		   // Disable PricewoVAT in this row
		   if (e !== '') {
			   this.setState(
				   Object.assign(this.state.Disabling.Sales[Row_JS_index], {Price: true})
			   );
		   } else {
			   this.setState(
				   Object.assign(this.state.Disabling.Sales[Row_JS_index], {Price: false})
			   );
		   }
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='VAT') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {VAT: e }), () => {
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='Units') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Units: e }), () => {
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='Promotion') {
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Promotion: e }), () => {
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }
	   if (field==='Discount') {
		   //console.log(this.state.NewSales);
		   this.setState(
			  Object.assign(this.state.NewSales[Row_JS_index], {Discount: e }), () => {
				   this.evaluateInputInSales(field, Row_JS_index, 'Sales', e);
				   this.validateSaleRequestInput(Row_JS_index)
			   }
			);
			//console.log(this.state.NewSales);
			//Check if a new row should be added to the table
			this.addOneMoreRowToSalesTable();
	   }

	   return null;
   }


   addOneMoreRowToSalesTable= () => {

	   const Row_index = this.state.RoIndex;

	   const NoOfRows_Sale = this.state.NoOfRows_Sale;

			if (+NoOfRows_Sale === +Row_index) {
				// If this is the last row on the table and something has been written, add a new row to the table and to the state.
				var current_rows = this.state.NewSales;
				console.log(current_rows);
				const new_row = this.state.NewSale[0];
				const clone = Object.assign({} , new_row);
				current_rows.push(clone);
				this.setState(
					Object.assign(this.state.NewSales[+Row_index], {No: +Row_index+1  })
				);

				this.setState({NoOfRows_Sale: +NoOfRows_Sale+1});

				// Add one more row to the Errors array:
				const new_sale_error = {Product: true, Brand: false, Type: false, Subtype: false, Reference: true, Price: true,
					PricewoVAT: true, VAT: true, Units: true, Promotion: false, Discount: false};

				this.setState(
					Object.assign(this.state.OperationsErrors, {
						Sales: this.state.OperationsErrors.Sales.concat(new_sale_error)
					})
				);


				const new_disabling = {Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false};
				this.setState(
					Object.assign(this.state.Disabling, {
						Sales: this.state.Disabling.Sales.concat(new_disabling)
					})
				);

			}
			return null;
   }

   WrapperRenderSaleTable = () => {
		return(
				<table className='NewSaleTable'>
					<thead className='NewSaleTableHeader'>
						<tr>{this.renderSaleTableHeader()}</tr>
					</thead>
					<tbody className='NewSaleTableBody'>
						{this.decisionMakingHowManyRowsForSale()}
					</tbody>
				</table>
   		)
	}

	NewChangeTicket = () => {
		return(
			<div className= 'NewChangeTicket'>
				{this.NewChangeTicketSummary()}
				{/*{this.NewChangeTicketClient()}*/}
				{this.NewChangeTicketButton()}
			</div>
		)
	}

	dashedLineOnTicket2 = () => {
		return (
			<div className='dashedLineOnTicket2'> {}</div>
		)
	}

	dashedLineOnTicket1 = () => {
		return (
			<div className='dashedLineOnTicket1'> {}</div>
		)
	}

	NewChangeTicketSummary = () => {
		return (
			<div className='NewChangeTicketSummary'>
				{this.NewChangeTicketHeader()}
				{this.NewChooseChangesSummaryTable()}
				{this.NewChangeTicketTable()}
				<div className='horizontalDivisionInTableForTotals'> </div>
				{this.NewChangeSummaryTotals()}
			</div>
		)
	}

	NewChooseChangesSummaryTable = () => {
		return(
				<table className='ChangeSummaryAbove'>
					<thead className='ChangeSummaryAboveHead'>
						<tr>{this.renderSummaryTableHeader()}</tr>
					</thead>
					<div className='horizontalDivisionInTable'> </div>
					<tbody className='ChangeSummaryAboveTBody'>
						{this.decisionDrivenHowManyRowsInChangeSummary()}
					</tbody>
				</table>
		)
	}

	decisionDrivenHowManyRowsInChangeSummary = () => {

		var array = [];
		for (let step = 0; step < 1; step++) {
			array.push(this.renderChangeSummaryTableData(step));
		}
		return array;
	}

	renderChangeSummaryTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {

			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			//console.log(Suboperation_data.Info.Changes_info.Change_data);
			return Suboperation_data.Info.Changes_info.Change_data.map((row, index) => {

				var {Product_data, Discount, Units} = row;

				var DiscountPerc = null;

				if (Discount !== "" && Discount !== null) {
					DiscountPerc = Discount + ' %';
				} else {
					DiscountPerc = null;
				}

				return (
					<tr key={index}>
						<td title={Product_data.Product}>{Product_data.Product}</td>
						<td title={Units}>{Units}</td>
						<td title={DiscountPerc}>{DiscountPerc}</td>
						<td title={calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount)}
						>{calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount)}</td>
					</tr>
				)
			})


		} else {

			var sale_data = this.state.ChangeContent;

			let check_no_product_changed = check_no_product_has_been_selected_on_change_ticket(sale_data);

			return sale_data.map((header, index) => {

				var {Index, Product, Price, Price_without_VAT, VAT, Units_to_change, Discount} = header //destructuring

				var DiscountPerc = null;

				if (Discount !== "" && Discount !== null) {
					DiscountPerc = Discount + ' %';
				} else {
					DiscountPerc = null;
				}

				// Always show '0.00' in total on first row if table is empty:
				let totals = null;

				if (check_no_product_changed === true) {
					totals = 'lol';
				} else {
					totals = calculatorOfSinglePrice(Price, Price_without_VAT, VAT, Units_to_change, Discount);
				}

				// console.log(header);
					 if (Units_to_change !== 0 && Units_to_change !== '' && Units_to_change !== null) {
						 return (
						<tr key={Index} data-rowid={'Noo'}>
						   <td title={Product}>{Product}</td>
						   <td title={Units_to_change}>{Units_to_change}</td>
						   <td title={DiscountPerc}>{DiscountPerc}</td>
							<td title={totals}>{totals}</td>
						</tr>
					 )
					 }
				if (Index===1 && check_no_product_changed === true) {
					return (
						<tr key={Index} data-rowid={'Noo'}>
							<td title={Product}>{Product}</td>
							<td title={Units_to_change}>{Units_to_change}</td>
							<td title={DiscountPerc}>{DiscountPerc}</td>
							<td title={totals}>0.00</td>
						</tr>
					)
				}
					 return null;
				}
			)
		}
	}




	NewChangeTicketClient = () => {
		return (
			<div className='NewChangeTicketClient'>
				<div className='NewChangeTicketClientHeader'> {this.NewChangeClientDataHeader()} </div>
				{this.NewChangeClientDataEmail()}
			</div>
		)
	}

	NewChangeTicketButton = () => {
		return (
			this.buttonScanAndPay()
		)
	}


	NewChangeTicketHeader = () => {

		let first_line = this.state.OrderTicketHeader[1];

		return (
			<div>
				<table className='NewChangeTicketSummaryHeader'>
					<tr>
						<th>{first_line.Order_summary}</th>
						{this.renderTicketHeaderChange()}
					</tr>
				</table>
			</div>
		)
	}

	renderTicketHeaderChange = () => {

		if (this.state.PopUpMoreInfo !== null) {
			// console.log(this.state.PopUpMoreInfo);
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			return (
				<th>{Suboperation_data.Info.Changes_info.Change_data[0].Product_data.Screen_datetime}</th>
		)

		} else {
			return (
				<th>{this.state.ScreenDatetime}</th>
			)
		}

	}

	NewChangeTicketTable = () => {
		return(
			<div >
				<table className='ChangeSummaryBelow'>
					<thead className='ChangeSummaryBelowHead'>
						<tr>{this.renderSummaryTableHeader()}</tr>
					</thead>
					<div className='horizontalDivisionInTable'> </div>
					<tbody className='ChangeSummaryBelowTBody'>
							{this.decisionDrivenHowManyRowsInSaleSummary()}
					</tbody>
				</table>
			</div>
		)
	}



	NewChangeSummaryTotals = () => {

		return (
			<div className='NewChangeSummaryTotals'>
				<table className='NewChangeSummaryTotalsTable'>
					<tbody className='NewChangeSummaryTotalsTableBody'>
						<td>{this.subtotalFirstColumn()}</td>
						<td>{this.subtotalSecondColumn()}</td>
					</tbody>
				</table>
			</div>
		)
	}

	subtotalFirstColumn = () => {

		var ccy = null;
		if (isUserACompany()) {
			ccy = this.state.Profile.Company.Currency;
		} else {
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			ccy = Suboperation_data.Info.Changes_info.Change_data[0].Product_data.Currency;
		}

		// Eliminate the currency header in case it's missing
		let total = 'Total (' + ccy + ')'

		if (ccy === null) {
			total = 'Total';
		}

		return ([
			<tr> <td> {'Subtotal'} </td> </tr >,
			<tr className='subtotalFirstColumn2'> <td> {'Shipping'} </td> </tr >,
			<tr > <td> </td> </tr >,
			<tr > <td> {total}</td> </tr >,
			<tr > <td> {'Taxes'}</td> </tr >
			])

	}

	subtotalSecondColumn = () => {
		return [
			<tr><td title={this.calculateWriteSubtotalChange()}> {this.calculateWriteSubtotalChange()} </td></tr >,
			<tr className='subtotalFirstColumn2'><td title={this.NewChangeShipping()}> {this.NewChangeShipping()} </td></tr >,
			<tr > <td> </td> </tr >,
			<tr><td title={this.calculateWriteTotal()}> {this.calculateWriteTotal()} </td></tr >,
			<tr><td title={this.writeTaxesNumber()}> {this.writeTaxesNumber()} </td></tr >
		]
	}

	// writeSubtotal = () => {
	// 	return(
	// 	<div className='writeSubtotal'>
	// 		<span className='writeSubtotal1'>{'Subtotal'} </span>
	// 		<span className='writeSubtotal2'>{this.calculateWriteSubtotalChange()} </span>
	// 	</div>
	// 	)
	// }

	calculateWriteSubtotalChange = () => {
		var Sales = [];
		var Changes = [];
		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			//console.log(Suboperation_data);
			Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {

				const {Product_data, Discount, Units} = row;
				Sales.push(calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount));

				return null;
			})

			Suboperation_data.Info.Changes_info.Change_data.map((row, index) => {

				const {Product_data, Discount, Units} = row;
				Changes.push(calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount));

				return null;
			})

		} else {
			var sale_data = this.state.NewSales;
			var change_data = this.state.ChangeContent;
			// console.log(sale_data);
			// console.log(change_data);

			sale_data.map((header, index) => {

				const {Price, PricewoVAT, VAT, Units, Discount} = header //destructuring
				// console.log(header);
				Sales.push(calculatorOfSinglePrice(Price, PricewoVAT, VAT, Units, Discount));

				return null;
			})

			if (change_data !== undefined && change_data !== null ) {
				change_data.map((header, index) => {

					if (header.Units_to_change !== 0 && header.Units_to_change !== null && header.Units_to_change !== "") {
						// Take only those rows that will effectively be changed.
						// console.log(header);

						const {Price, Price_without_VAT, VAT, Units_to_change, Discount} = header //destructuring

						Changes.push(calculatorOfSinglePrice(Price, Price_without_VAT, VAT, Units_to_change, Discount));
					}

					return null;
				})
			}
		}
		// console.log(Sales);
		// console.log(Changes);
		const rounded = Math.round((this.calculateSubTotal(Sales, Changes) + Number.EPSILON) * 100) / 100;

		return rounded.toFixed(2);
	}

	calculateSubTotal = (Sales, Changes) => {
		// console.log(Sales);
		// console.log(Changes);
		if (Changes.length === 0 || Changes[0] === 'NaN') {
			return 0;
		}

		const sales_array = Sales.map((i) => Number(i));
		const sum_Sales = sales_array.reduce((a, b) => a + b, 0);

		const changes_array = Changes.map((i) => Number(i));
		const sum_Changes = changes_array.reduce((a, b) => a + b, 0);

		return sum_Sales - sum_Changes;
	}

	writeShipping = () => {
		return(
		<div className='writeSubtotal'>
			<span className='writeSubtotal1'>{'Shipping'} </span>
			<span className='writeSubtotal2'>{this.NewChangeShipping()} </span>
		</div>
		)
	}

	writeTotal = () => {
		return(
		<div className='writeSubtotal'>
			<span className='writeSubtotal1'>{'Total'} </span>
			<span className='writeSubtotal2'>{this.calculateWriteTotal()} </span>
		</div>
		)
	}

	calculateWriteTotal = () => {
		const subtotal = this.calculateWriteSubtotalChange();
		const shipping = this.takeShipping();

		const rounded = Math.round((+subtotal + +shipping + Number.EPSILON) * 100) / 100;

		return rounded.toFixed(2);
		// return null;
	}

	writeTaxes = () => {
		return(
		<div className='writeSubtotal'>
			<span className='writeSubtotal1'>{'Taxes'} </span>
			<span className='writeSubtotal2'>{this.writeTaxesNumber()} </span>
		</div>
		)
	}

	writeTaxesNumberForTotals = () => {
		var Sales = [];
		var Changes = [];

		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			//console.log(Suboperation_data);
			Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {
				const {Product_data, Units} = row;
				Sales.push(calculateTaxesInSuboperationForTotals(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units));
				return null;
			})
			Suboperation_data.Info.Changes_info.Change_data.map((row, index) => {
				console.log(row);
				const {Product_data, Units} = row;
				Changes.push(calculateTaxesInSuboperationForTotals(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units));
				return null;
			})

		} else {
			var sale_data = this.state.NewSales;
			var change_data = this.state.NewChanges;

			sale_data.map((header, index) => {
				const {Price, PricewoVAT, VAT, Units} = header //destructuring
				Sales.push(calculateTaxesInSuboperation(Price, PricewoVAT, VAT, Units));
				return null;
			})
			if (change_data !== undefined && change_data !== null ) {
				change_data.map((header, index) => {
					const {Price, PricewoVAT, VAT, Units} = header //destructuring
					Changes.push(calculateTaxesInSuboperation(Price, PricewoVAT, VAT, Units));
					return null;
				})
			}
		}
		const rounded = Math.round((this.calculateSubTotal(Sales, Changes) + Number.EPSILON) * 100) / 100;
		console.log(rounded)
		return rounded.toFixed(2);
	}

	writeTaxesNumber = () => {
		var Sales = [];
		var Changes = [];

		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			//console.log(Suboperation_data);
			Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {

				const {Product_data, Units} = row;
				Sales.push(calculateTaxesInSuboperation(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units));

				return null;
			})

			Suboperation_data.Info.Changes_info.Change_data.map((row, index) => {
				// console.log(row);

				const {Product_data, Units} = row;
				Changes.push(calculateTaxesInSuboperation(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units));

				return null;
			})

		} else {
			var sale_data = this.state.NewSales;
			var change_data = this.state.ChangeContent;

			sale_data.map((header, index) => {

				const {Price, PricewoVAT, VAT, Units} = header //destructuring
				Sales.push(calculateTaxesInSuboperation(Price, PricewoVAT, VAT, Units));

				return null;
			})
			if (change_data !== undefined && change_data !== null ) {
				change_data.map((header, index) => {

					const {Price, Price_without_VAT, VAT, Units_to_change} = header //destructuring
					// console.log(header);
					Changes.push(calculateTaxesInSuboperation(Price, Price_without_VAT, VAT, Units_to_change));

					return null;
				})
			}

		}
		// console.log(Sales)
		// console.log(Changes)
		const rounded = Math.round((this.calculateSubTotal(Sales, Changes) + Number.EPSILON) * 100) / 100;
		// console.log(rounded)

		return rounded.toFixed(2);
	}




	ValidateCorrectOperation = () => {
		return null
	}

	buttonScanAndPay = () => {

		if (this.state.PopUpMoreInfo !== null) {
			return null;
		} else {


			return (
				<div className='buttonScanAndPayChange'>
					{this.errorMessageOnButton()}
					<Button className="buttonScanAndPayButtonChange" block size="lg"
							onClick={this.wrapperButtonScanAndPay2Operations}
							// disabled={this.state.missingCrucialData === true || this.state.IDInputIsDisabled === false}
						>
						{'Scan & Pay'}
					</Button>
				</div>
			)
		}
	}

	errorMessageOnButton = () => {
		if (this.state.missingCrucialData === true) {
			return <div className='errorMessageOnButton'> Please, provide the necessary information on My account. </div>
		}
		if (this.state.errorMessageOnButton === true) {
			return <div className='errorMessageOnButton'> Please, check the errors above before continuing. </div>
		} else { return null; }
	}


	validateSaleRequestInput = () => {
		console.log('holi')


		let isThereAnyErrorInScreen = false;
		let errorsArray = this.state.OperationsErrors;
		let chooseChangesArray = this.state.OperationsErrors.ChooseChange;
		// console.log(chooseChangesArray);

		let products = errorsArray.Sales.map(value => value.Product);
		let references = errorsArray.Sales.map(value => value.Reference);
		let price = errorsArray.Sales.map(value => value.Price);
		let priceWOVAT = errorsArray.Sales.map(value => value.PricewoVAT);
		let vat = errorsArray.Sales.map(value => value.VAT);
		let units = errorsArray.Sales.map(value => value.Units);
		let discounts = errorsArray.Sales.map(value => value.Discount);
		let unitsChoose = chooseChangesArray.map(value => value.Units);
		let reasonChoose = chooseChangesArray.map(value => value.Reason);
		let unitsTooBigChoose = chooseChangesArray.map(value => value.UnitsBiggerThanExpected);
		// console.log(references);

		let countTrueInProducts = products.filter(x => x === true).length;
		let countTrueInReferences = references.filter(x => x === true).length;
		let countTrueInPrices = price.filter(x => x === true).length;
		let countTrueInPriceWOVATs = priceWOVAT.filter(x => x === true).length;
		let countTrueInVAT = vat.filter(x => x === true).length;
		let countTrueInUnits = units.filter(x => x === true).length;
		let countTrueInDiscounts = discounts.filter(x => x === true).length;
		let countTrueInUnitsChoose = unitsChoose.filter(x => x === true).length;
		let countTrueInReasonChoose = reasonChoose.filter(x => x === true).length;
		let countTrueInBigUnitsChoose = unitsTooBigChoose.filter(x => x === true).length;
		// console.log(countTrueInReferences);


		let chooseChangesArrayHighLevel = this.state.HighLevelErrors.ChooseChange;
		// console.log(chooseChangesArrayHighLevel);
		//
		// console.log(this.state.HighLevelErrors);

		if (chooseChangesArrayHighLevel.AllUnits === true || chooseChangesArrayHighLevel.CharactersInUnits === true) {
			isThereAnyErrorInScreen = true;
			// console.log('lol')
		}

		if (chooseChangesArrayHighLevel.Units === true || chooseChangesArrayHighLevel.Reason === true
			|| chooseChangesArrayHighLevel.AllUnits === true) {
			isThereAnyErrorInScreen = true;
		}


		if ( (countTrueInUnitsChoose === 1 && this.state.ChooseChange.length === 1) || countTrueInUnitsChoose > 0) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {Units: true }), () => this.ErrorMessagesInChangeWindow()
			);
		} if ( !((countTrueInUnitsChoose === 1 && this.state.ChooseChange.length === 1) || countTrueInUnitsChoose > 0)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {Units: false }), () => this.ErrorMessagesInChangeWindow()
			);
		}

		if ( (countTrueInReasonChoose === 1 && this.state.ChooseChange.length === 1) || countTrueInReasonChoose > 0) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {Reason: true }), () => this.ErrorMessagesInChangeWindow()
			);
		} if ( !((countTrueInReasonChoose === 1 && this.state.ChooseChange.length === 1) || countTrueInReasonChoose > 0)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {Reason: false }), () => this.ErrorMessagesInChangeWindow()
			);
		}
		if ( (countTrueInBigUnitsChoose === 1 && this.state.ChooseChange.length === 1) || countTrueInBigUnitsChoose > 0) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {UnitsBiggerThanExpected: true }), () => this.ErrorMessagesInChangeWindow()
			);
		} if ( !((countTrueInBigUnitsChoose === 1 && this.state.ChooseChange.length === 1) || countTrueInBigUnitsChoose > 0)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {UnitsBiggerThanExpected: false }), () => this.ErrorMessagesInChangeWindow()
			);
		}


		if ( (countTrueInProducts === 1 && this.state.NewSales.length === 1) || countTrueInProducts > 1) {
			isThereAnyErrorInScreen = true;
			// POLLA
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Product: true }), () => this.checksAfterInputValidation()
			);
		} if (!((countTrueInProducts === 1 && this.state.NewSales.length === 1 ) || countTrueInProducts > 1)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Product: false }), () => this.checksAfterInputValidation()
			);
		}

		if   (  (countTrueInReferences === 1 && this.state.NewSales.length === 1) || countTrueInReferences > 1) {
			isThereAnyErrorInScreen = true;
			// console.log('set to True')
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Reference: true }), () => {this.checksAfterInputValidation()}
			);
		} if (! ((countTrueInReferences === 1 && this.state.NewSales.length === 1) || countTrueInReferences > 1) ) {
			// console.log('set to False')
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Reference: false }), () => this.checksAfterInputValidation()
			);
		}

		if   (  (countTrueInPrices === 1 && this.state.NewSales.length === 1) || countTrueInPrices > 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Price: true }), () => {this.checksAfterInputValidation()}
			);
		} if (! ((countTrueInPrices === 1 && this.state.NewSales.length === 1) || countTrueInPrices > 1) ) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Price: false }), () => this.checksAfterInputValidation()
			);
		}

		if ( (countTrueInPriceWOVATs === 1 && this.state.NewSales.length === 1) || countTrueInPriceWOVATs > 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {PricewoVAT: true }), () => this.checksAfterInputValidation()
			);
		} if ( !((countTrueInPriceWOVATs === 1 && this.state.NewSales.length === 1) || countTrueInPriceWOVATs > 1) ) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {PricewoVAT: false }), () => this.checksAfterInputValidation()
			);
		}


		if ( (countTrueInVAT === 1 && this.state.NewSales.length === 1) || countTrueInVAT > 1) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {VAT: true }), () => this.checksAfterInputValidation()
			);
		} if ( !((countTrueInVAT === 1 && this.state.NewSales.length === 1) || countTrueInVAT > 1)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {VAT: false }), () => this.checksAfterInputValidation()
			);
		}

		if ( (countTrueInUnits === 1 && this.state.NewSales.length === 1) || countTrueInUnits > 1) {
			isThereAnyErrorInScreen = true;
			// console.log('problemas en units')
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Units: true }), () => this.checksAfterInputValidation()
			);
		} if ( !((countTrueInUnits === 1 && this.state.NewSales.length === 1) || countTrueInUnits > 1)) {
			// console.log('todo crema en units')
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Units: false }), () => this.checksAfterInputValidation()
			);
		}

		if ( (countTrueInDiscounts === 1 && this.state.NewSales.length === 1) || countTrueInDiscounts >= 1) {
			isThereAnyErrorInScreen = true;
			// console.log('problemas')
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Discount: true }), () => this.checksAfterInputValidation()
			);
		} if ( !((countTrueInDiscounts === 1 && this.state.NewSales.length === 1) || countTrueInDiscounts >= 1)) {
			// console.log('todo crema')
			this.setState(
				Object.assign(this.state.HighLevelErrors.Sales, {Discount: false }), () => this.checksAfterInputValidation()
			);
		}


		if (errorsArray.ClientEmail === true) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors, {Client_Email: true }), () => this.checksAfterInputValidation()
			);
		} else {
			this.setState(
				Object.assign(this.state.HighLevelErrors, {Client_Email: false }), () => this.checksAfterInputValidation()
			);
		}
		if (errorsArray.Shipping === true) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors, {Ship_ping: true }), () => this.checksAfterInputValidation()
			);
		}else {
			this.setState(
				Object.assign(this.state.HighLevelErrors, {Ship_ping: false }), () => this.checksAfterInputValidation()
			);
		}
		// console.log(isThereAnyErrorInScreen);
		return isThereAnyErrorInScreen;


	}

	checksAfterInputValidation = () => {
		// console.log(this.state.HighLevelErrors.Sales);

		this.checkErrorMessageOnButton();
		this.ErrorMessagesInChangeWindow();
		return null;
	}

	checkErrorMessageOnButton = () => {

		if ( (this.state.PressedEndButton === true && this.checkHighLevelErrors() === true) ||
			this.state.PressedEndButtonWithoutID === true) {
			this.setState({errorMessageOnButton: true});
		} else {
			this.setState({errorMessageOnButton: false});
		}

		return null;
	}




	ErrorMessagesInChangeWindow = () => {
		// Displays the correct error message in the Sale Window

		let anyErrorCheck = this.checkHighLevelErrors();

		if (this.state.PressedEndButtonWithoutID === true) {
			let errorMessages = 'Please, introduce an ID to fetch its past information.';

			return(<div className='ErrorMessagesInChangeWindow'> {errorMessages.split('\n').map(str =>
				<p className='ErrorMessagesInChangeWindowText'>{str}</p>)} </div>)

		} else {
			let errorTitle = 'Please, check the following fields before finishing the transaction:';
			let errorMessages = '';
			let highLevelErrorsArray = this.state.HighLevelErrors;
			console.log(highLevelErrorsArray)

			if (this.state.PressedEndButton === true && anyErrorCheck === true) {
				// console.log(highLevelErrorsArray);

				if (highLevelErrorsArray.ChooseChange.Units === true ||
					highLevelErrorsArray.ChooseChange.CharactersInUnits === true
					|| highLevelErrorsArray.ChooseChange.AllUnits === true) {
					const thisError = "\n - Select which products your client wants to return. Positive integer, i.e. 1, 2, 3,...";
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.ChooseChange.UnitsBiggerThanExpected === true) {
					const thisError = "\n - Units to return cannot exceed units previously sold.";
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.ChooseChange.Reason === true) {
					const thisError = "\n - Provide a reason for the change.";
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.Sales.Product === true) {
					const thisError = '\n - Products involved, i.e. trousers, chewing gums,...';
					errorMessages += thisError;
					// errorMessages.insertAdjacentHTML('beforeend', thisError);
				}

				if (highLevelErrorsArray.Sales.Reference === true) {
					const thisError = '\n - Reference must be provided';
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.Sales.Price === true || highLevelErrorsArray.Sales.PricewoVAT === true) {
					const thisError = '\n - Price or Price without VAT, 2, 10.45';
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.Sales.VAT === true) {
					const thisError = '\n - VAT of your products, i.e. 7, 21.5,...';
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.Sales.Units === true) {
					const thisError = '\n - Units of your products, i.e. 1, 5,...';
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.Sales.Discount === true) {
					const thisError = '\n - Discount made (in %), i.e. 10, 60.25,...';
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.Client_Email === true) {
					const thisError = "\n - Client's email";
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.Ship_ping === true) {
					const thisError = "\n - Shipping cost, i.e. 5, 17.25,...";
					errorMessages += thisError;
				}


				return (
					<div className='ErrorMessagesInChangeWindowContainerHuge'>
						<div className='ErrorMessagesInChangeWindowTitle'> {errorTitle} </div>
						<div className='ErrorMessagesInChangeWindowHuge'> {errorMessages.split('\n').map(str =>
							<p className='ErrorMessagesInChangeWindowText'>{str}</p>)} </div>
					</div>
				)

			} else {
				if (this.state.PressedEndButton === false
					&& this.checkMustPricesBeChecked()
				&& this.state.IDInputIsDisabled === true
				) {

					const thisError = '\n - Price or Price without VAT, i.e. 221.35, 62.5';
					errorMessages += thisError;
					return (

						<div className='ErrorMessagesInChangeWindowContainerHuge'>
							<div className='ErrorMessagesInChangeWindowTitle'> {errorTitle} </div>
							<div className='ErrorMessagesInChangeWindowHuge'> {errorMessages.split('\n').map(str =>
								<p className='ErrorMessagesInChangeWindowText'>{str}</p>)} </div>
						</div>
					)
				}  else {return null}
			}
		}
	}



	checkMustPricesBeChecked = () => {
		// console.log(this.state.LastChanged)

		if (this.state.LastChanged === 'Price' || this.state.LastChanged === 'PricewoVAT') {
			// Checks whether Price or PricewoVAT has been introduced with errors or not

			let salesArray = this.state.NewSales;
			let operationsErrors = this.state.OperationsErrors.Sales;

			let prices = salesArray.map(value => value.Price);
			let pricesWoVAT = salesArray.map(value => value.PricewoVAT);
			let pricesErrors = operationsErrors.map(value => value.Price);
			let pricesWoVATErrors = operationsErrors.map(value => value.PricewoVAT);

			// let countTrueInProducts = prices.filter(x => x === '' || x === null).length;
			// console.log(countTrueInProducts)

			for (let step = 0; step < +salesArray.length - 1; step++) {
				// console.log(pricesErrors[step]);
				// console.log(pricesWoVATErrors[step]);
				// console.log(validate_number(prices[step]))
				// console.log(validate_number(pricesWoVAT[step]))
				// Check Price or PricewoVAT has been written
				if (!(validate_pure_float(prices[step]) &&
					validate_pure_float(pricesWoVAT[step]) ) ) {
					// Check there is some error on the price or priceWoVAT written
					if (pricesErrors[step] === true || pricesWoVATErrors[step] === true) {
						// console.log(true)
						return true;
					}
				}
			}
			// console.log(false)
			return false;
		} else {
			return false;
		}

	}


	ErrorMessageInIncorrectID = () => {

		let highLevelErrorsArray = this.state.HighLevelErrors;

		if (highLevelErrorsArray.Non_existing_ID === true) {
			return(
				<div className='ErrorMessageInIncorrectID'>
					This ID is not linked to your past data
				</div>
			)
		}
		if (highLevelErrorsArray.Empty_ID === true) {
			return(
				<div className='ErrorMessageInIncorrectID'>
					This ID does not contain any available products.
				</div>
			)
		} else {return null;}
	}

	IDInformationExplanation = () => {
		return(
			<div className='IDInformationExplanation'>
				Start by checking your client's ID information
			</div>
		)
	}

	checkHighLevelErrors = () => {
		// Checks if there are any high level errors

		let highLevelErrorsArray = this.state.HighLevelErrors;
		let highLevelErrorsSalesArray = highLevelErrorsArray.Sales;
		let highLevelErrorsArrayChooseChange = this.state.HighLevelErrors.ChooseChange;

		console.log(highLevelErrorsArrayChooseChange)

		return highLevelErrorsSalesArray.Product === true || highLevelErrorsSalesArray.Reference === true ||
			highLevelErrorsSalesArray.Price === true ||
			highLevelErrorsSalesArray.PricewoVAT || highLevelErrorsSalesArray.VAT ||
			highLevelErrorsSalesArray.Units || highLevelErrorsSalesArray.Discount || highLevelErrorsArray.Client_Email ||
			highLevelErrorsArray.Ship_ping || highLevelErrorsArrayChooseChange.Reason ||
			highLevelErrorsArrayChooseChange.Units ||
			highLevelErrorsArrayChooseChange.AllUnits || highLevelErrorsArrayChooseChange.UnitsBiggerThanExpected;
	}


	wrapperButtonScanAndPay2Operations = () => {

		// this.setState({PressedEndButton: true});
		// this.setState({PressedEndButtonWithoutID: true});
		console.log(this.state.HighLevelErrors)

		if (this.state.IDInputIsDisabled === true) {
			this.setState({PressedEndButton: true}, () => this.checkErrorMessageOnButton());
			// this.setState({PressedEndButtonWithoutID: false});

			let check = this.validateSaleRequestInput();

			if (!check === true) {
				// console.log('enviada')
				this.buttonScanAndPaySubmit_ChangeOperation();
			}

		} else {
			this.setState({PressedEndButtonWithoutID: true}, () => this.checkErrorMessageOnButton());
		}


		return null;
	}

	CloseButton = () => {

		return (
			<div className = 'CloseButtonChanges'>
				<Button className="CloseButtonButton" block size="lg" onClick={this.closeNewSuboperationPopUp}>
					<div className='CloseButtonButtonText'>{'X'}</div>
				</Button>
			</div>
		)
	}


	buttonScanAndPaySubmit_ChangeOperation = () => {

		// Display the new background pop-up - only waiting for the QR code.
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.Sale});

		// Uncomment the following lines when the response is required for some check.
		let ExecuteSale = () => {
			return Create_json_change(this.state.New_ID_Provider_Operation, this.state.NewSales, this.state.ChangeContent,
				this.state.Client, this.state.ShippingNewSale, this.state.Employee_ID, this.state.Shop_ID, this.state.ScreenDatetime,
				this.state.Profile.Company.Currency)
		}

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		let execute_Sale = ExecuteSale()

		execute_Sale.then((response) => {
				//console.log(response);
				if (response.contenido.Changes_output.Status === "OK") {
					this.setState({SVG: response.contenido.Changes_output.QR})
					this.setState({SVG_PNG: response.contenido.Changes_output.QR_PNG})
					// this.SalesQRCodeWindow();

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// Render the QR's obscurer when the QR code is received.
					this.setState({ObscurerQRReceived: true});

					// Store in state the Operation_ID
					this.setState({Operation_ID: response.contenido.Changes_output.Operation_ID})

					return null;
				} else {
					console.log('Status NOK');

					this.setState({ErrorMessage: 'SaleCouldNotBeExecuted'})

					//Remove the loading icon
					this.setState({WaitingForRequest: false});
					this.setState({ObscurerQRReceived: true});

					return null;
				}
			}
		)

		this.actionsAfterClosingNewSuboperationPopup();

		return null;
	}


	closeNewSuboperationPopUp = () => {

		// Reset the objects changed during the Sales process
		this.actionsAfterClosingNewSuboperationPopup();

		// //Return to LandingPage view only
		// this.props.data[1]();
		let returnToLandingPage = this.props.data[1];
		returnToLandingPage();

		return null;

	}


	actionsAfterClosingNewSuboperationPopup = () => {

		// Reset the objects changed during the Sales process
		this.setState({
			NewSales: [{ No: 1, Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null,
				PricewoVAT: null, VAT: null, Units: null, Promotion: null, Discount: null}]});

		this.setState({SVG_PNG: null});
		this.setState({ErrorMessage: null});
		this.setState({OperationsErrors: {Sales: [{Product: true, Brand: false, Type: false, Subtype: false, Reference: true, Price: true,
					PricewoVAT: true, VAT: true, Units: true, Promotion: false, Discount: false}],
				ChooseChange: [{Units: 0, Reason: null, UnitsBiggerThanExpected: false}],
				Shipping: true,
				ClientEmail: true}});
		this.setState({HighLevelErrors: {
				Ship_ping: false,
				Client_Email: false,
				OldSaleID: false,
				Sales: {Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false},
				ChooseChange: {Units: true, Reason: true, UnitsBiggerThanExpected: false, CharactersInUnits: false, AllUnits: false},
				Non_existing_ID: false, Empty_ID: false
			}});

		this.setState({NoOfRows: 1});
		this.setState({ShippingNewSale: 0});
		this.setState({Employee_ID: null});
		this.setState({Shop_ID: null});
		this.setState({EmployeeChosen: 'Choose an employee'});
		this.setState({ShopChosen: 'Choose a shop'});
		this.setState({PopUpMoreInfo: null});
		this.setState({NoOfRows_Sale: 1});

		this.setState({ChangeContent: [{
				No: 1, Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null,
				Price_without_VAT: null, VAT: null, Units: null, Promotion: null, Discount: null, UnitsChange: null, Reason: null
			}]});
		this.setState({ChooseChangesContent: [{Choose_change_index: 1, Units: 0, Reason: null}]});
		this.setState({ChooseChangesFixed: [{Choose_change_index: 1, Units: 0, Reason: null}]});

		this.setState({Disabling: {Sales: [{Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false}]}});
		this.setState({PressedEndButton: false});
		this.setState({DropDownAttribute: null});
		this.setState({Operation_ID: null});

		// Antes aquí había un cambio del SecondaryStatus a None.

		return null;
	}





	renderSummaryTableHeader = () => {

		  var first_line = this.state.OrderSummaryHeaders[0];

		  if (first_line === undefined) {
			  return null;
		  } else {

			  let header = Object.keys(first_line)
			  //console.log(header);
			  return header.map((key, index_2) => {
					return <th key={index_2}>{key}</th>
			  })
		  }
   }


   decisionDrivenHowManyRowsInSaleSummary = () => {
	   // In this function, the number of rows is defined automatically by decisionMakingHowManyRowsForSale
		//const NoOfRows_Sale = this.state.NoOfRows_Sale;
		//console.log(this.state.NewSales);
		var array = [];
		for (let step = 0; step < 1; step++) {
			array.push(this.renderSaleSummaryTableData(step));
		}
		//console.log(array);
		return array;
	}


	renderSaleSummaryTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {

			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			//console.log(Suboperation_data);
			return Suboperation_data.Info.Sales_info.Sale_data.map((row, index) => {


				const {Product_data, Discount, Units} = row;

				var DiscountPerc = null;

				if (Discount !== "" && Discount !== null) {
					DiscountPerc = Discount + ' %';
				} else {
					DiscountPerc = null;
				}

				return (
					<tr key={index}>
						<td title={Product_data.Product}>{Product_data.Product}</td>
						<td title={Units}>{Units}</td>
						<td title={DiscountPerc}>{DiscountPerc}</td>
						<td title={calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount)}>
							{calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount)}
						</td>
					</tr>
				)
			})

		} else {


			var sale_data = this.state.NewSales;

			return sale_data.map((header, index) => {
				// console.log(header);

					const {No, Product, Price, PricewoVAT, VAT, Units, Discount} = header //destructuring

				var DiscountPerc = null;

				if (Discount !== "" && Discount !== null) {
					DiscountPerc = Discount + ' %';
				} else {
					DiscountPerc = null;
				}

					return (
						<tr key={No} data-rowid={'Noo'}>
							<td title={Product}>{Product}</td>
							<td title={Units}>{Units}</td>
							<td title={DiscountPerc}>{DiscountPerc}</td>
							<td title={calculatorOfSinglePrice(Price, PricewoVAT, VAT, Units, Discount)}>
								{calculatorOfSinglePrice(Price, PricewoVAT, VAT, Units, Discount)}
							</td>
						</tr>
					)
				}
			)
		}
	}
	//+{Units]*(100-{Discount})/100}



	NewChangeClientDataHeader = () => {
		return (
			<div className= 'NewChangeClientDataHeader'>
				<span> {'Client data'} </span>
			</div>
		)
	}

	NewChangeClientDataEmail = () => {
		return (
			<div className= 'NewChangeClientDataEmail'>
				{this.NewChangeClientDataMessageEmail()}
				{this.NewChangeClientDataFormEmail()}
			</div>
		)
	}


	NewChangeClientDataMessageEmail = () => {

		return (
			<span className= 'NewChangeClientDataMessageEmail'>
				{'E-mail address'}
			</span>
		)
	}

	NewChangeClientDataFormEmail = () => {
		//console.log(this.state.Client);
		if (this.state.PopUpMoreInfo !== null) {

			if (isUserACompany()) {

				const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
				console.log(Suboperation_data);
				return (
					<div className="NewChangeClientDataFormEmailPrevious"
					title={Suboperation_data.Client}> {Suboperation_data.Client}</div>
				)
			} else {
				return (
					<div className="NewChangeClientDataFormEmailPrevious"
						 title={this.state.Profile.Person.Email}> {this.state.Profile.Person.Email}</div>
				)
			}

		} else {

			// The input field in case there's no error
			let classN = 'NewSaleClientDataFormEmail';
			// Check if the input field must be red (due to an error in the input introduced).

			if (this.state.PressedEndButton === true && this.state.OperationsErrors.ClientEmail === true ) {
				classN = 'NewSaleClientDataFormEmail2';
			}

			return (
				<Form.Control
					className={classN}
					autoFocus
					type="email"
					value = {this.state.Client.Email}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.setEmail(e.target.value)}
				/>
			)
		}
	}

	setEmail(event) {
		//console.log(this.state.Client.Email);
		// Sets the e-mail address of the client to the State
		this.evaluateInputInSales('ClientEmail', null, null, event);

		this.setState(
			  Object.assign(this.state.Client, {Email: event })
		);
		return null;
	}

	NewSaleShippingInChange = () => {
		//Need for showing Shipping in previous Sale
		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);

			let ccy = null;
			if (isUserACompany()) {
				ccy =this.state.Profile.Company.Currency;
			} else {
				ccy = Relevant_Operation.Info.Changes_info.Change_data[0].Product_data.Currency;
			}

			// Eliminate the currency header in case it's missing
			let shipping_name = 'Shipping (' + ccy +')   ';

			if (ccy === null) {
				shipping_name = 'Shipping';
			}

			return (
				<div className='NewChangeShippingPrevious'>
					<div className='NewSaleShippingNamePrevious'>{shipping_name} </div>
					<div className='NewSaleShippingValuePrevious' title={Relevant_Operation.Shipping}> {Relevant_Operation.Shipping} </div>
				</div>
			)
		} else {

			let classN = 'NewSaleShippingValue';
			// Check if the input field must be red (due to an error in the input introduced).
			if (this.state.PressedEndButton === true && this.state.OperationsErrors.Shipping === true ) {
				classN = 'NewSaleShippingValue2';
			}

			let ccy =this.state.Profile.Company.Currency;
			// Eliminate the currency header in case it's missing
			let shipping_name = 'Shipping (' + ccy +')   ';

			if (ccy === null) {
				shipping_name = 'Shipping';
			}


			return (

				<div className='NewSaleShippingInChange'>
					<label className="NewSaleShippingInChangeName">{shipping_name}</label>
					<input
						className={classN}
						autoFocus
						type="numeric"

						disabled={this.state.IDInputIsDisabled === false}
						onChange={(e) => this.setShipping(e.target.value)}
					/>
				</div>
			)
		}
	}

	takeShipping = () => {
		// Returns the shipping value
		let rounded = 0

		if (this.state.PopUpMoreInfo !== null) {
			let Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			let shipping = Relevant_Operation.Shipping;
			rounded = Math.round((+shipping + Number.EPSILON) * 100) / 100;
		} else {
			rounded = Math.round((+this.state.ShippingNewSale + Number.EPSILON) * 100) / 100;
		}
		return rounded.toFixed(2);
	}




	NewChangeShipping = () => {

		if (this.state.PopUpMoreInfo !== null) {

			let Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			let shipping = Relevant_Operation.Shipping;

			const rounded = Math.round((+shipping + Number.EPSILON) * 100) / 100;
			const rounded_shipping =  rounded.toFixed(2);

			return (
				<div className='NewChangeShipping'>
					<span> {rounded_shipping}</span>
				</div>
			)
		} else {

			const rounded = Math.round((+this.state.ShippingNewSale + Number.EPSILON) * 100) / 100;

			return rounded.toFixed(2);

		}

	}


	setShipping(event) {

		this.evaluateInputInSales('Shipping', null, null, event);

		this.setState({ShippingNewSale: event});
		return null;
	}

	NewChangeMessage = () => {
		if (this.state.PopUpMoreInfo !== null) {
			return (
				<div className='NewChangeMessage'> <span> {'Previous Change'} </span> </div>
			)
		} else {

			return (
				<div className='NewChangeMessage'> <span> {'New Change'} </span> </div>
			)
		}
	}

	renderDropDownEmployees = () => {
		var array1 = [];
		var array_id = [];

		const Employees = this.state.Profile.Employees;

		//console.log(Employees);

		//Prepare the required fields to be displayed
		Employees.forEach(Employee => {
			let employee_name = Employee.Name;
			if (employee_name === null) { employee_name = ''}
			let employee_surname = ' ' + Employee.Surname;
			if (Employee.Surname === null) { employee_surname = ''}

			var Employee_identity = employee_name + employee_surname;
			var Employee_ID = Employee.ID
			if (Employee_identity !== 'null null') {
				array1.push(Employee_identity);
				array_id.push(Employee_ID);
			}
		})

		//Get the set of elements
		var set_Employees = array1.filter((item, i, ar) => ar.indexOf(item) === i);
		var set_Employee_IDs = array_id.filter((item, i, ar) => ar.indexOf(item) === i);

		var array_joined = [];

		//Join the names and the IDs
		for (let i = 0; i < set_Employee_IDs.length; i++) {
			array_joined.push({Employee_name: set_Employees[i], Employee_ID: set_Employee_IDs[i]});
		}
		console.log(array_joined);

		return array_joined;
	}


	clickOnEmployeeNameDropdown = (event) => {
		const employee_id = event.currentTarget.getAttribute("employeeID");
		const employee_name = event.currentTarget.getAttribute("employeeName");

		//console.log(this.state.Employee_ID);
		this.setState({Employee_ID: employee_id});
		this.setState({EmployeeChosen: employee_name});

		return null;
	}

	renderDropDownShops = () => {
		var array1 = [];
		var array_id = [];

		const Shops = this.state.Profile.Shops;
		// console.log(Shops);

		//Prepare the required fields to be displayed
		Shops.forEach(Shop => {
			var Shop_identity = Shop.Name;
			var Shop_ID = Shop.ID;
			array1.push(Shop_identity);
			array_id.push(Shop_ID);
		})

		//Get the set of elements
		var set_Shops = array1.filter((item, i, ar) => ar.indexOf(item) === i);
		var set_Shops_IDs = array_id.filter((item, i, ar) => ar.indexOf(item) === i);

		var array_joined = [];

		//Join the names and the IDs
		for (let i = 0; i < set_Shops_IDs.length; i++) {
			array_joined.push({Shop_name: set_Shops[i], Shop_ID: set_Shops_IDs[i]});
		}


		return(array_joined);
	}


	clickOnShopNameDropdown = (event) => {
		const shop_ID = event.currentTarget.getAttribute("shopID");
		const Shop_name = event.currentTarget.getAttribute("shopName");

		//console.log(this.state.Shop_ID);
		this.setState({Shop_ID: shop_ID});
		this.setState({ShopChosen: Shop_name})

		return null;
	}

	NewChangeEmployee = () => {

		if (this.state.PopUpMoreInfo !== null) {
			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);
			return (
				// <div className='NewChangeEmployee'>
				// 	<span> {'Employee: ' + Relevant_Operation.Employee} </span>
				// </div>
			<div className='NewChangeEmployee'>
					<span className='NewChangeEmployeeEmployee'>
						{'Employee'}
					</span>
				<span className='NewChangeEmployeeNo' title={Relevant_Operation.Employee}>
						{Relevant_Operation.Employee}
					</span>
			</div>
			)

		} else {
			return (
				<div className='NewChangeEmployee'>
				<span className='NewChangeEmployeeEmployee'>
					{'Employee'}
				</span>
					<div className='verticalSeparatorFirstBoxesChangeScreen'> </div>
					<Button className='NewChangeEmployeeButton' block size="lg"
							onClick={(e) =>
								this.activateDropDown('Employee')}
					> <div className='renderMyCompanyRightInputDropdownText' >{this.state.EmployeeChosen}</div>
						<img className='dropDownInMyProfileNewChange' alt=""/>
					</Button>
					{this.DropDownAttribute('Employee', null, null, null )}
					<div className='horizontalSeparatorFirstBoxesChanges'> </div>
				</div>
			)
		}
	}

	activateDropDown = (field) => {

		if (field === 'Employee') {
			if (this.state.DropDownAttribute === 'Employee') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'Employee'});
			}
		}
		if (field === 'Shop') {
			if (this.state.DropDownAttribute === 'Shop') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'Shop'});
			}
		}
		if (field === 'OperationType') {
			if (this.state.DropDownAttribute === 'OperationType') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'OperationType'});
			}
		}
		// console.log(this.state.DropDownAttribute);
		//
		// this.resetDropDownAttributeInState();

		return null;
	}

	DropDownAttribute = (attribute, profile, table, row_index) => {
		// console.log(this.state.DropDownAttribute);
		// console.log(row_index);

		var Attributes = [];
		var field = null;

		if (attribute === 'OperationChosen' && this.state.DropDownAttribute === 'OperationChosenByUser') {
			Attributes = file.OperationsChosen;
			field = 'Operation';
		}
		if (attribute === 'Shop' && this.state.DropDownAttribute === 'Shop') {
			Attributes = this.renderDropDownShops();
			if (Attributes.length === 0) {Attributes = [{'Shop_name': 'Provide your shops data on My account tab.', 'Shop_ID': null}]}
			field = 'Shop';
		}
		if (attribute === 'Employee' && this.state.DropDownAttribute === 'Employee') {
			Attributes = this.renderDropDownEmployees();
			console.log(Attributes);
			if (Attributes.length === 0) {Attributes = [{'Employee_name': 'Provide your employees data on My account tab.', 'Employee_ID': null}]}
			field = 'Employee';

		}


		var array2 = [];

		Attributes.forEach(Attribute => {
			let attribute_to_show = null;
			if (attribute === 'Shop' && this.state.DropDownAttribute === 'Shop') {
				attribute_to_show = Attribute.Shop_name
				console.log(attribute_to_show)
			}
			if (attribute === 'Employee' && this.state.DropDownAttribute === 'Employee') {
				attribute_to_show = Attribute.Employee_name
				console.log(attribute_to_show)
			}

			array2.push(
				<div className='containerDropDownShopsChangesAttribute'>
					<Dropdown.Item eventKey="1" className='dropDownOperations' Value={Attribute} Shop_name={Attribute.Shop_name}
								   Shop_ID={Attribute.Shop_ID} Employee_name={Attribute.Employee_name} Employee_ID={Attribute.Employee_ID}
								   disabled={this.mustDropdownBeDisabled(attribute)}
								   onClick={(e) => this.clickOnOperationTypeDropdown(e, field)}> {attribute_to_show}
					</Dropdown.Item>
				</div>
			)
		})

		if (field === 'Shop') {
			return (<div className='containerDropDownShopsChanges'>{array2} </div>);
		}
		if (field === 'Employee') {
			return (<div className='containerDropDownShopsChanges'>{array2} </div>);
		}

		else {
			return null;
		}
	}
	mustDropdownBeDisabled = (field) => {
		console.log(this.renderDropDownEmployees().length);

		if (field === 'Shop') {return this.renderDropDownShops().length === 0;}
		if (field === 'Employee') {return this.renderDropDownEmployees().length === 0;}

		return false;
	}

	clickOnOperationTypeDropdown = (event, field) => {
		// console.log(event.currentTarget);
		// console.log('hola');

		const value = event.currentTarget.getAttribute("Value");

		if (field === 'Shop') {
			const shop_id = event.currentTarget.getAttribute("Shop_ID");
			const shop_name = event.currentTarget.getAttribute("Shop_name");
			this.setState({ShopChosen: shop_name});
			this.setState({Shop_ID: shop_id});
		}
		if (field === 'Employee') {
			const employee_id = event.currentTarget.getAttribute("Employee_ID");
			const employee_name = event.currentTarget.getAttribute("Employee_name");
			this.setState({EmployeeChosen: employee_name});
			this.setState({Employee_ID: employee_id});
		}

		this.setState({DropDownAttribute: null});

		//console.log(operation_type_code);

		//console.log(this.state.Employee_ID);

		return null;
	}


	NewChangeShop = () => {
		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);

			return (
				<div className='NewChangeShop'>
					<span className='NewChangeShopShop'>
						{'Shop'}
					</span>
					<span className='NewChangeEmployeeName' title={Relevant_Operation.Shop}>
						{Relevant_Operation.Shop}
					</span>
				</div>
			)
		} else {

			return (
				<div className='NewChangeShop'>
					<span className='NewChangeShopShop'>
						{'Shop'}
					</span>

					<div className='verticalSeparatorFirstBoxesChangeScreen'> </div>

					<Button className='NewChangeEmployeeButton' block size="lg"
							onClick={(e) =>
								this.activateDropDown('Shop')}
					> <div className='renderMyCompanyRightInputDropdownText' >{this.state.ShopChosen}</div>
						<img className='dropDownInMyProfileNewChange' alt=""/>
					</Button>
					{this.DropDownAttribute('Shop', null, null, null )}

				</div>
			)
		}
	}

	OldSaleDate = () => {
		// // TODO:  Get the current datetime

		return null;
	}

	NewChangeDate = () => {

		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);
			return (
				<div className='NewChangeDatePrevious'>
					<span> {Relevant_Operation.Info.Changes_info.Change_data[0].Product_data.Screen_datetime} </span>
				</div>
			)

		} else {
			return (
				<div className='NewChangeDate'>
				<span className='NewChangeDateDate'>
					{this.state.ScreenDatetime}
				</span>
				</div>
			)
		}
	}

	Probando = () => {
		return(
			<div className='LOL'>
				<span> Muy gracioso </span>
			</div>
		)
	}


	WrapperAllTables = () => {
		return(
			<div className={'WrapperAllTables'}>
				{this.OldSaleDate()}
				{this.ChooseChangesTable()}

				<table className='ChangeTable'>
					<thead className='ChangeTableHeader'>
						<tr>{this.renderChangeTableHeader()}</tr>
					</thead>
					<tbody className='ChangeTableBody'>
						{this.decisionMakingHowManyRowsForChange()}
					</tbody>
				</table>

				{this.NewChangeDate()}
				{this.WrapperRenderSaleTable()}
				{this.NewSaleShippingInChange()}
			</div>
		)
	}

	SalesQRCodeWindow = () => {

		if (this.state.showSalesQRCode === this.state.showSalesQRCodeCode.Sale) {

			let error_message_resendQR = null;
			console.log(this.state.ErrorMessage)
			if (this.state.ErrorMessage === 'SaleCouldNotBeExecuted') {
				error_message_resendQR = <div className='errorSendingOperationQR'> An error occurred. Please, contact support </div>
			}

			return (
				<div className='ChangesQRCodeWindow'>
					<div className='SalesQRCodeWindowText'> Your client may scan the following QR to receive his/her
						TIDI on the phone </div>
					{error_message_resendQR}
					{this.render_svg(this.state.SVG_PNG)}
					<div className='SalesQRCodeWindowText2'> Or you may send the TIDI to his/her email address </div>
					{this.messageWrongEmailWhenResendingQRCode()}
					<div className='resendEmail'>
						<input className={'EmailAddresInputAfterOperationCompletion'} type='email'
							   value={this.state.ClientConfirmsSendingByEmailEmail}
							   onChange={(e) => this.validateEmailAddressUpdateClient2Operation(e.target.value)}/>
					</div>
					{this.buttonCloseQRMessageAfterOperationCompleted()}
				</div>
			)
		} else {return null;}
	}


	buttonCloseQRMessageAfterOperationCompleted = () => {

		let client_email_to_send_qr = this.state.ClientConfirmsSendingByEmailEmail;
		let title = 'User has already scanned the QR';
		if (client_email_to_send_qr !== null && client_email_to_send_qr !== '') {
			title = 'Send QR by email & close'
		}
		return (
			<div className='buttonCloseQRCodeSale'>
				<Button className="buttonCloseQRCodeButton" block size="lg" onClick={this.closeQRMessageAfterOperationCompletedButtonAction}>
					{title}
				</Button>
			</div>
		)
	}


	closeQRMessageAfterOperationCompletedButtonAction = () => {

		this.setState({sendButtonPressed: true});
		console.log(this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email)
		// console.log(this.state)

		if (this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === false || this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === null
			|| this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === undefined) {
			// The email address introduced fulfils the format required

			if (this.state.ClientConfirmsSendingByEmailEmail !== '' && this.state.DoesClientWantTheEmail === true) {
				// Perform the web service to update the client's email address in the Operations row
				this.updateClientIDInOperationsRow();
			} else {
				// User has scanned the TIDI (or not received it in any way) --> Refresh the page.
				let returnToLandingPage = this.props.data[1];
				returnToLandingPage();

				// Update the historical table
				let updateHistoricalTable = this.props.data[2];
				updateHistoricalTable();
				this.closeNewSuboperationPopUpAfterQRIsSent();
			}

		}

		return null;
	}

	updateClientIDInOperationsRow = () => {

		this.setState({WaitingForRequest: true});

		let SendingQRByEmail = () => {
			console.log('finalizando')
			return updateClientInOperation(this.state.Operation_ID, +this.state.New_ID_Provider_Operation, this.state.ClientConfirmsSendingByEmailEmail, true);
		}

		let sendingQRByEmail = SendingQRByEmail()

		sendingQRByEmail.then((response) => {
				// console.log(response);
				if (response.contenido.Update_client_in_operation_output.Status === "OK") {

					// this.buttonCloseQRCodeSendToNewAddress();

					let returnToLandingPage = this.props.data[1];
					returnToLandingPage();

					this.setState({ClientConfirmsSendingByEmailEmail: ''})

					// Reset the push onto the button
					this.setState({sendButtonPressed: null});

					// Update the historical table
					let updateHistoricalTable = this.props.data[2];
					updateHistoricalTable();

				} else {
					// TODO Martín - terminar tratamiento de errores
					// let error_code = response.contenido.Resend_QR_data_output.Error_code;
					// if (error_code === 'Resend_QR_1' || error_code === 'Resend_QR_2' || error_code === 'Resend_QR_3') {
					// 	this.setState({ErrorMessage: 'EmailCouldNotBeSent'})
					// }

					console.log('Status NOK');
				}

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				// // Close this suboperation popup
				this.closeNewSuboperationPopUpAfterQRIsSent();

				return null;
			}
		)
		return null;

	}

	closeNewSuboperationPopUpAfterQRIsSent = () => {

		// console.log(this.state.TerciaryStatus)
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.None});

		// Reset the email address to send the email to
		this.setState({ClientConfirmsSendingByEmailEmail: ''})

		// Reset the push onto the button
		this.setState({sendButtonPressed: null});

		// this.setState({ObscurerQRReceived: false});

		this.setState({ObscurerQRReceived: false},
			()=> {this.setState({SecondaryStatus: this.state.SecondaryCode.None},
				() => { this.setState({PrimaryStatus: this.state.PrimaryCode.Load})})
			});

		return null;
	}

	validateEmailAddressUpdateClient2Operation = (value) => {

		this.setState({DoesClientWantTheEmail: true});
		this.setState({ClientConfirmsSendingByEmailEmail: value})
		let validation_of_clientemail = email_validation_or_empty(value);
		console.log(validation_of_clientemail)
		this.setState(
			Object.assign(this.state.HighLevelErrors, {ClientConfirmsSendingByEmail_Email: !validation_of_clientemail })
		);
		return null;
	}


	messageWrongEmailWhenResendingQRCode = () => {
		console.log(this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email)
		console.log(this.state.sendButtonPressed)

		if ((this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === true) && this.state.sendButtonPressed === true) {
			return <div className='messageWrongEmailWhenSendingQRCodeAtEndOfOperation'> Invalid e-mail address</div>
		} else {
			return null;
		}
	}

	render_svg = (SVG_file) => {

		let png_src = "data:image/png;base64," + SVG_file;

		return (
			document.getElementsByName('SalesQRCodeWindow').innerHTML = <img className="QRImageChange" src={png_src} alt=''/>
		)
	}

	buttonCloseQRCode = () => {
		return (
			<div className='buttonCloseQRCode'>
				<Button className="buttonCloseQRCodeButton" block size="lg" onClick={this.buttonCloseQRCodeSubmit}>
					{'Close'}
				</Button>
			</div>
		)
	}

	buttonCloseQRCodeSubmit = () => {

		this.setState({sendButtonPressed: null});
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.None});

		this.closeNewSuboperationPopUp();

		// // Update the TIDI number
		// this.setState({New_ID_Provider_Operation: +this.state.New_ID_Provider_Operation+1});

		// Update the historical table - hence repeat all requests to Back-end
		this.setState({PrimaryStatus: this.state.PrimaryCode.Load});

		// Update the historical table
		let updateHistoricalTable = this.props.data[2];
		updateHistoricalTable();

		return null;
	}

	WaitingForRequest = () => {

		if (this.state.WaitingForRequest === true) {
			return (
				<div className='loading'>
					<img className="loading-image" src={loadingPictureCode} />
				</div>
			)
		} else {return null;}
	}


	ObscurerQRReceived = () => {

		if (this.state.ObscurerQRReceived === true) {

			return (
				<div className='ObscurerQRReceivedChange'>
					{' '}
				</div>
			)
		} else {return null;}
	}


	wrapperRenderChangeTable = () => {

		let div = null;

		if (this.state.PopUpMoreInfo !== null) {
			div = <div className='NewChangeUpperDataContainerNoBorder'>
					{this.NewChangeID()}
					{this.NewChangeEmployee()}
					{this.NewChangeShop()}
				</div>;

		} else {
			div = <div className='NewChangeUpperDataContainer'>
					{this.NewChangeID()}
					{this.NewChangeEmployee()}
					{this.NewChangeShop()}
				</div>;
		}

		if ( this.state.showSalesQRCode === this.state.showSalesQRCodeCode.Sale ) {
			return [
				this.WaitingForRequest(),
				<div className='containerOfChange'>
					{this.ObscurerQRReceived()}
					{this.CloseButton()}
					{this.NewChangeMessage()}
					{div}
					{this.OldSaleDate()}
					{this.WrapperAllTables()}

					{this.NewChangeTicket()}
					{this.SalesQRCodeWindow()}
					{this.IDInformationExplanation()}
				</div>
			]
		} else {
			return [
				this.WaitingForRequest(),
				<div className='containerOfChange'>
					{this.ObscurerQRReceived()}
					{this.CloseButton()}
					{this.NewChangeMessage()}
					{div}
					{this.OldSaleDate()}
					{this.WrapperAllTables()}
					{this.ErrorMessagesInChangeWindow()}
					{this.ErrorMessageInIncorrectID()}
					{this.IDInformationExplanation()}

					{this.NewChangeTicket()}
				</div>
			]
		}
	}


	render() {
			return(
				<this.wrapperRenderChangeTable/>
			)
	}
}