import React from "react";
import {Button} from 'react-bootstrap';

var licensesTitle = <b> THIRD-PARTY OPEN-SOURCE LIBRARIES</b>

var licensesIntroText = <span>
    For a greater ease of navigation, the third-party open-source libraries are grouped by their distribution license. Please, click on the license type you
    are interested in to see what packges are distributed via that license.
</span>

class renderLicensesButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            texto: null
        }
    }

    buttonObjects = (stateChanger) => {
        // Contains all button objects to be rendered
        let licensesButtonApache2 = <Button className='buttonLicenseTextActivator' onClick = {() => {stateChanger('Apache2')} } > Apache 2.0 License  </Button>;
        let licensesButtonBSD2 = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'BSD2'}); stateChanger('BSD2')} } > BSD 2-Clause  </Button>;
        let licensesButtonBSD3 = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'BSD3'}); stateChanger('BSD3')} } > BSD 3-Clause  </Button>;
        let licensesButtonHPND = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'HPND'}); stateChanger('HPND')} } > HPND  </Button>;
        let licensesButtonISC = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'ISC'}); stateChanger('ISC')} } > ISC  </Button>;
        let licensesButtonMIT = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'MIT'}); stateChanger('MIT')} } > MIT  </Button>;
        let licensesButtonPostgreSQL = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'PostgreSQL'}); stateChanger('PostgreSQL')} } > PostgreSQL  </Button>;
        let licensesButtonPython = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'Python'}); stateChanger('Python')} } > Python Software Foundation  </Button>;
        let licensesButtonUnlicense = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'Unlicense'}); stateChanger('Unlicense')} } > The Unlicense  </Button>;
        let licensesButtonZope = <Button className='buttonLicenseTextActivator' onClick = {() => {this.setState({texto: 'Zope'}); stateChanger('Zope')} } > Zope Public License  </Button>;

        return ({'licensesButtonMIT': licensesButtonMIT,
            'licensesButtonBSD2': licensesButtonBSD2,
            'licensesButtonApache2': licensesButtonApache2,
            'licensesButtonBSD3': licensesButtonBSD3,
            'licensesButtonHPND': licensesButtonHPND,
            'licensesButtonISC': licensesButtonISC,
            'licensesButtonPostgreSQL': licensesButtonPostgreSQL,
            'licensesButtonPython': licensesButtonPython,
            'licensesButtonUnlicense': licensesButtonUnlicense,
            'licensesButtonZope': licensesButtonZope, })
    }

    render() {
        return this.buttonObjects()
    }
}

export {licensesTitle, licensesIntroText, renderLicensesButtons};