import React from "react";
import {Redirect } from 'react-router-dom';
import "../functions/Session_cookies";


class ChangeToLogIn extends React.Component {
    constructor(props) {
        super(props);

        let LI = "./LogIn";
        console.log(process.env.REACT_APP_ENVIRONMENT)

        this.state = {
            LI: LI
        };
    }

    Redirect_to_LandingPage = (data) => {
        return <Redirect to={this.state.LI}/>
    }

    render() {
        return (
            <this.Redirect_to_LandingPage/>
        )
    }
}

export default ChangeToLogIn;




