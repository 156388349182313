import React from "react";
import {Link, Redirect} from 'react-router-dom';
import "../functions/Session_cookies";
import "./MyProfile.css";
import "./LandingPage.css";
import {create_json_my_profile, get_my_profile} from "../functions/My_profile";
import {create_json_log_in_account, create_json_log_in_validate_old_password} from "../functions/Log_in_functions.js";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Renderizar from "./LogIn";
import file from "../content/Profile_Attributes";
import {Button, Form, Table} from 'react-bootstrap';
import Company_icon from '../pictures/Company.png';
import Employees_icon from '../pictures/Employees.png';
import Settings_icon from '../pictures/Settings.png';
import Shops_icon from '../pictures/Shops.png';
import {
	validate_password_format, wrapper_send_all_cookies_after_cookies_preferences_made,
	clearCookiesOnLogOut, parseOnlyDateFromPython, isUserACompany, getUserType, isUserAPSI, format_email_address
} from "../common/utilities";
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
	email_validation,
	email_validation_or_empty,
	getCookie,
	remove_profile_and_operations_from_localstorage,
	validate_integers_or_null,
	validate_string_is_not_empty_or_null,
	return_null_if_empty, is_field_always_present_in_array, validate_input_cell_is_not_empty_or_null
} from "../common/utilities";
import {create_initial_JWT_api} from "../functions/JWT";
import {get_analytics_acceptance_cookie, set_analytics_acceptance_cookie} from "../functions/Permanent_cookies";
import {legalNoticeText, legalNoticeTitle} from "../texts/Legal_notice";
import {privacyPolicyText, privacyPolicyTitle} from "../texts/Privacy_Policy";
import {cookiesDetailText, cookiesDetailTitle} from "../texts/Cookies_details";
import {analyticsCookiesText, cookiesSummaryTitle, necessaryCookiesText} from "../texts/Cookies_summary";
import "../texts/textx.css";
import {decideWhichGTCToDisplay} from "../texts/texts_utilities";
import {loadingPictureCode} from "../common/ajax-loader";
import QR_code from "../pictures/Blue_QR.png";
import {licensesIntroText, licensesTitle, renderLicensesButtons} from "../texts/Licenses";
import {licenseMITText} from "../texts/License_MIT";
import {licenseBSD2ext} from "../texts/License_BSD_2_Clause";
import {licenseApache2Text} from "../texts/License_Apache2_0";
import {licenseBSD3Text} from "../texts/License_BSD_3_Clause";
import {licenseISCText} from "../texts/License_ISC";
import {licensePostgreSQLText} from "../texts/License_PostgreSQL";
import {licensePythonSFText} from "../texts/License_Python_Software_Foundation";
import {licenseUnlicenseText} from "../texts/License_The_Unlicense";
import {licenseZopePLText} from "../texts/License_Zope_Public_License";
import {analyticsCookiesHeader, analyticsCookiesTable, technicalCookiesTable} from "../texts/Cookies_tables";
import {Company_Relations_request} from "../functions/Company_relations";
import EyeSlashed from "../pictures/EyeSlashed.png";
import EyeOpened from "../pictures/EyeOpened.png";


export class RenderMyArea extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Page: "MyArea",
			PrimaryStatus: "-4",
			SecondaryStatus: "0",
			TerciaryStatus: "0",
			Email: null,
			Password: null,
			NewPassword: "",
			RepeatNewPassword: "",
			Verification_code: null,
			Reset: false,
			Filters: [],
			PrimaryCode: {IsUserSignedIn: "-4", JWT: "-3", Load: "-2", DataPreparation: "-1", Company: "0", Shop: "1", Employee: "2", Settings: "3", Person: "10", IntermediateToRemoveLocalStorage: '100'},
			SecondaryCode: {Normal: "0", Edit: "1", EditPasswords: "2", EditCurrency: "3", EditEmail: "4",
				DeleteAccount: "5", Delete2Account: "6", ManagePSI: "7"},
			TerciaryCode: {Normal: "0", Confirmed: "1"},
			NoOfRows: 1,
			RoIndex: 0,
			Currency: null,
			Client: [{Email_address: ""}],
			Profile: {},
			NewProfile: {Company: {}, Employees: {}, Shops: {}},
			Free_employee: [{ID: 0, Name: null, Surname: null, Role: null, Email_address: null, Treatment: null}],
			Free_shop: [{ID: 0, Name: null, Address: null, Country: null, Province: null, Region: null}],
			NewEmployees: [],
			X: {},
			OldPassword: "",
			ShopsTableHeaders: {
				'No.': 'No.',
				'Name': 'Name',
				'Address': 'Address',
				'Country': 'Country',
				'Region': 'Region',
				'Province': 'Province',
				'Phone number': 'Phone number'
			},
			EmployeesTableHeaders: {
				'No.': 'No.',
				'Treatment': 'Treatment',
				'Name': 'Name',
				'Surname': 'Surname',
				'Role': 'Role',
				'Email address': 'Email address'
			},
			Nuevo_perfil: {
				Company: {
					Name: null, Address: null, Email: null, Province: null, Region: null, Phone_number: null,
					Phone_prefix: null, Postal_code: null, Country: null, Sector: null, Subsector: null,
					Currency: null, TFN: null
				},
				Employees: [{
					Treatment: null, Name: null, Surname: null, Role: null, Email_address: null
				}],
				Shops: [{
					Name: null, Address: null, Country: null, Region: null, Province: null, Phone_number: null
				}]
			},
			CompanyErrors: {Company: {
					Name: false, Address: false, Email: false, Province: false, Region: false, Phone_number: false,
					Phone_prefix: false, Postal_code: false, Country: false, Sector: false, Subsector: false,
					Currency: false, TFN: false
				},
				Employees: [{
					Treatment: false, Name: false, Surname: false, Role: false, Email_address: false
				}],
				Shops: [{
					Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false
				}],
				Settings: {
					NewEmailError: true, RepeatNewEmailError: true, OldPasswordError: true, PasswordError: true,
					NewPasswordError: true, RepeatNewPasswordError: true
				},
				Person: {
					Email: false, Genre: false, Nickname: false, Phone_number: false, Phone_prefix: false,
					Postal_code: false

				}},
			NewEmail: '',
			RepeatNewEmail: '',
			HighLevelErrorsMyProfile:
				{Employees: {Treatment: false, Name: false, Surname: false, Role: false, Email_address: false },
					Shops: {Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false},
					areNewEmailsTheSame: false,
					areNewPasswordsTheSame: false
				},
			whichLegalTextIsRequired: null,
			CookiesAccepted: {Technical: true, Analytics: get_analytics_acceptance_cookie()==='true'},
			CookiesDetailsRequired: false,
			DeleteMyAccount: false,
			URL_LogIn: "./LogIn",
			ProfileDeleted: false,
			errorMessages: {EmployeeName: false, EmployeeEmailAddres: false, ShopName: false, ShopPhoneNumber: false},
			WaitingForRequest: false,
			analyticsCookiesHeader: analyticsCookiesHeader,
			analyticsCookiesBody: analyticsCookiesTable,
			technicalCookiesBody: technicalCookiesTable,
			PSI_Validation_code: null,
			passwordTypes: {input1: 'password', input2: 'password', input3: 'password', input4: 'password', input5: 'password'}
		};
	}

	RetrieveMyProfile = async () => {

		var retorno = '';

		let Profile = function () {
			return get_my_profile().then(json => {
				return json
			});
		}

		let profile1 = Profile()

		retorno = profile1.then((response) => {
				//console.log(response);
				if (response.contenido.Retrieve_my_profile_output.Status === "OK") {
					console.log('Status OK');
					const Profile_output = response.contenido.Retrieve_my_profile_output.Profile_data;
					console.log(response.contenido);

					localStorage.setItem('Profile_retrieved', JSON.stringify(Profile_output));
					localStorage.setItem('PUN', Profile_output.Update_number);

					retorno = 'OK'
					return retorno;
				} else {
					console.log('Status NOK');


					this.setState({ErrorMessage: 'Retrieve_my_profile_1'});


					retorno = null;
					return retorno;
				}
			}
		)


		return await retorno;
	}

	proceedToShowProfileAndRemoveUnnecessaryItems = ()  => {

		// let ret1 = this.fillInEmployeesCompanyErrors(employees_number);
		// let ret2 = this.fillInShopsCompanyErrors(shops_number);

		this.setState({PrimaryStatus: this.state.PrimaryCode.IntermediateToRemoveLocalStorage})

		return null;
	}

	fillInEmployeesCompanyErrors = async (numberOfRows) => {

		// let originalArray = [{Units: false, Reason: false}];
		let newArray = [];

		while (newArray.length <numberOfRows+1) {
			newArray.push({
				Treatment: false, Name: false, Surname: false, Role: false, Email: false
			})
		}

		return await this.setState(
			Object.assign(this.state.CompanyErrors, {Employees: newArray })
		);

	}

	fillInShopsCompanyErrors = async (numberOfRows) => {

		// let originalArray = [{Units: false, Reason: false}];
		let newArray = [];

		while (newArray.length <numberOfRows+1) {
			newArray.push({
				Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false
			})
		}

		return await this.setState(
			Object.assign(this.state.CompanyErrors, {Shops: newArray })
		);

	}


	parseISODateProfile(dateString) {
		var match = /^(\d{4})-(\d\d)-(\d\d)/.exec(dateString);
		console.log(match);
		console.log(dateString);
		var time = dateString.substring(11, 19);
		// console.log(Number(match[2]));
		var fecha = new Date(Number(match[1]), Number(match[2]), Number(match[3]));
		// console.log(fecha);
		var nueva_fecha = fecha.getDate() + '/' + fecha.getMonth() + '/' + fecha.getFullYear() + ' ' + time;
		var nueva_fecha2 = fecha.getDate() + '/' + fecha.getMonth() + '/' + fecha.getFullYear();
		return [nueva_fecha, nueva_fecha2];
	}

	HeaderLandingPage = () => {
		return (
			<div className='HeaderLandingPageMyArea' >
				{this.LogoLandingPage()}
				{this.navigationButtons()}
				{this.welcomeName()}
				{this.LogOutButton()}
			</div>
		)
	}


	welcomeName = () => {

		if (isUserACompany()) {
			return (
				<div className='welcomeName'>
					<span className='welcomeNameSpan'>Hi, {this.state.Profile.Company.Name}!</span>
				</div>

			)

		} else {
			if (isUserAPSI()) {
				return (
					<div className='welcomeName'>
						<span className='welcomeNameSpan'>Hi, {this.state.Profile.Company.Name}!</span>
					</div>

				)
			} else {
				return (
					<div className='welcomeName'>
						<span>Hi, {this.state.Profile.Person.Nickname}!</span>
					</div>
				)
			}
		}
	}

	LogoLandingPage() {
		return (
			<span className='LogoLandingPageMyArea'>
				{'TIDI'}
			</span>
		)
	}


	navigationButtons = () => {
		return (
			<div className='navigationButtons'>
				{this.HistoricalLandingPage()}
				{this.ButtonLandingPage()}
			</div>
		)
	}

	HistoricalLandingPage() {
		return (
			<Link to='/MyArea'>
				<Button className='HistoricalLandingPage' block size="lg" >
					{'My history'}
				</Button>
			</Link>
		)
	}

	ButtonLandingPage = () => {
		return (
			<Link to='/MyProfile'>
				<Button className='MyProfileLandingPageMyProfile' block size="lg">
					{'My account'}
				</Button>
			</Link>
		)
	}

	LogOutButton = () => {
		return (
			<Link to='/LogIn'>
				<Button className="LogOutButtonMyArea" block size="lg" onClick = {() => clearCookiesOnLogOut()}>
					{'Log out'}
				</Button>
			</Link>
		)
	}


	MyCompanyButton = () => {

		var button_text = 'Profile'
		var icon = <img className='MyCompanyButtonIcon' src={Employees_icon}  alt="" />
		if (isUserACompany() || isUserAPSI()) {
			button_text = 'My company'
			icon = <img className='MyCompanyButtonIcon' src={Company_icon}  alt="" />
		}
		if (this.state.PrimaryStatus === this.state.PrimaryCode.Person || this.state.PrimaryStatus === this.state.PrimaryCode.Company) {
			return (
				<div className="MyCompany">
					<Button className="MyCompanyButtonSelected" block size="lg"
							onClick={(e) => this.MyCompanyButtonAction()}>
						{icon}{button_text}
					</Button>
				</div>
			)
		} else {

			return (
				<div className="MyCompany">
					<Button className="MyCompanyButton" block size="lg" onClick={(e) => this.MyCompanyButtonAction()}>
						{icon}{button_text}
					</Button>
				</div>
			)

		}
	}

	MyCompanyButtonAction = () => {

		// this.cancelEditProfileForm();
		if (isUserACompany() || isUserAPSI()) {
			this.setState({PrimaryStatus: this.state.PrimaryCode.Company});
		} else {
			this.setState({PrimaryStatus: this.state.PrimaryCode.Person});
		}
		return null;
	}


	MyEmployeesButton = () => {
		if (this.state.PrimaryStatus === this.state.PrimaryCode.Employee) {
			return (
				<div className="MyEmployees">
					<Button className="MyEmployeesButtonSelected" block size="lg"
							onClick={(e) => this.MyEmployeesButtonAction()}>
						<img className='MyCompanyButtonIcon' src={Employees_icon}  alt="" />{'My employees'}
					</Button>
				</div>
			)
		}
		else {
			return (
				<div className="MyEmployees">
					<Button className="MyEmployeesButton" block size="lg"
							onClick={(e) => this.MyEmployeesButtonAction()}>
						<img className='MyCompanyButtonIcon' src={Employees_icon}  alt="" />{'My employees'}
					</Button>
				</div>
			)
		}
	}

	MyEmployeesButtonAction = () => {
		// this.cancelEditProfileForm();
		this.setState({PrimaryStatus: this.state.PrimaryCode.Employee});
		return null;
	}

	MyShopsButton = () => {
		if (this.state.PrimaryStatus === this.state.PrimaryCode.Shop) {
			return (
				<div className="MyShops">
					<Button className="MyShopsButtonSelected" block size="lg" onClick={(e) => this.MyShopsButtonAction()}>
						<img className='MyCompanyButtonIcon' src={Shops_icon}  alt="" />{'My shops'}
					</Button>
				</div>
			)
		} else {
			return (
				<div className="MyShops">
					<Button className="MyShopsButton" block size="lg" onClick={(e) => this.MyShopsButtonAction()}>
						<img className='MyCompanyButtonIcon' src={Shops_icon}  alt="" />{'My shops'}
					</Button>
				</div>
			)
		}
	}

	MyShopsButtonAction = () => {
		// this.cancelEditProfileForm();
		this.setState({PrimaryStatus: this.state.PrimaryCode.Shop});
		return null;
	}

	SettingsButton = () => {
		if (this.state.PrimaryStatus === this.state.PrimaryCode.Settings) {
			return (
				<div className="Settings">
					<Button className="SettingsButtonSelected" block size="lg" onClick={(e) => this.MySettingsButtonAction()}>
						<img className='MyCompanyButtonIcon' src={Settings_icon}  alt="" />{'Settings'}
					</Button>
				</div>
			)
		} else {
			return (
				<div className="Settings">
					<Button className="SettingsButton" block size="lg" onClick={(e) => this.MySettingsButtonAction()}>
						<img className='MyCompanyButtonIcon' src={Settings_icon}  alt="" />{'Settings'}
					</Button>
				</div>
			)
		}
	}

	MySettingsButtonAction = () => {
		// this.cancelEditProfileForm();
		this.setState({PrimaryStatus: this.state.PrimaryCode.Settings});
		return null;
	}


	containerNavigation = () => {
		if (isUserACompany()) {
			return (
				<div className='containerNavigation'>
					{this.MyCompanyButton()}
					{this.MyEmployeesButton()}
					{this.MyShopsButton()}
					{this.SettingsButton()}
				</div>
			)
		} else {
			if (isUserAPSI()) {
				return(
					<div className='containerNavigation'>
						{this.MyCompanyButton()}
						{this.SettingsButton()}
					</div>
				)
			} else {
					return (
						<div className='containerNavigation'>
							{this.MyCompanyButton()}
							{this.SettingsButton()}
						</div>
					)
				}
		}
	}

	containerPage = () => {
		return (
			<div className='containerPage'>
				{this.userNameOnLanding()}
				<div className='containerPageMyAccount'>
					{this.containerNavigation()}
					{this.containerInformation()}
					{this.errorMessageWhenUpdatingProfileOrLoading()}
				</div>
			</div>
		)
	}

	userNameOnLanding = () => {

		if (isUserACompany()) {
			return (
				<div className='userNameOnLanding'>{this.state.Profile.Company.Name}</div>
			)
		} else {
			if (isUserAPSI()) {
				return (
					<div className='userNameOnLanding'>{this.state.Profile.Company.Name}</div>
				)
			}
		else
			{
				return (
					<div className='userNameOnLanding'>{this.state.Profile.Person.Nickname}</div>
				)
			}
		}
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//Here begins the Person' sub-tab

	renderMyPersonProfile = () => {
		return (
			<div className='renderMyCompany'>
				<span className='renderMyCompanyName'>Profile details</span>
				<div className='editMyCompanyButtonContainer'>
					<Button className="editMyCompanyButton" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEdit()}>
						{'Edit data'}
					</Button>
				</div>
				<div className='renderMyCompanyData'>
					{this.renderMyProfileLeft()}
					{this.renderMyPersonRight()}
				</div>
			</div>
		)
	}


	editMyPersonProfile = () => {

		return [
			<div className='whiteSpaceInBackground'> </div>,
			<div className='fillingBackgroundContainer'>
				<span className='renderMyCompanyName'>Edit your profile details</span>
				<div className='renderMyCompanyData'>
					{this.renderMyProfileLeft()}
					{this.renderMyPersonRight()}
					{this.buttonsEditMyCompany()}
				</div>
			</div>
		]
	}


	editPersonForm = () => {
		//console.log('lol');
		//console.log(this.state);

		return (
			<div className='editCompanyForm'>
				<Form id='filterForm'>

					<Form.Group size="lg" controlId="Address">
						<Form.Label>Date of birth </Form.Label>
						<Form.Control
							className=""
							type="Date"
							value={this.state.Profile.Person.Birth_date}
							onChange={(e) => this.setNewProfile(e.target.value, 'Person', 'Birth_date', null, 'Person')}
						/>
					</Form.Group>

					<Form.Group size="lg" controlId="Address">
						<Form.Label> Postal code </Form.Label>
						<Form.Control
							className=""
							type="Address"
							value={this.state.Profile.Person.Postal_code}
							onChange={(e) => this.setNewProfile(e.target.value, 'Person', 'Postal_code', null, 'Person')}
						/>
					</Form.Group>

					<Form.Group size="lg" controlId="Address">
						<Form.Label> Genre </Form.Label>
						<DropdownButton title={this.state.Nuevo_perfil.Person.Genre} className="vertical_dropdown">
							{this.DropDownAttribute('Genre', 'Person', 'false', 'Person', 'Person')}
						</DropdownButton>
					</Form.Group>

					<Button className="Clear_button" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
						{'Cancel'}
					</Button>

					<Button className="Filter_button" block size="lg" onClick={(e) => this.submitEditCompanyForm()}>
						{'Save'}
					</Button>

				</Form>
			</div>
		)
	}


	renderMyPersonRight = (row_index) => {
		const to_map = this.state.Profile.Person;
		//console.log(to_map);

		const {Genre, Birth_date, Postal_code, Nickname} = to_map //destructuring
		// console.log(Birth_date)
		if (this.state.SecondaryStatus === this.state.SecondaryCode.Normal) {
			return (
				<div className='renderMyCompanyRight'>
					<div className='renderMyCompanyRightText' title={Nickname}> {Nickname} </div>
					<div className='renderMyCompanyRightText' title={parseOnlyDateFromPython(Birth_date)}> {parseOnlyDateFromPython(Birth_date)} </div>
					<div className='renderMyCompanyRightText' title={Postal_code}> {Postal_code} </div>
					<div className='renderMyCompanyRightText' title={Genre}> {Genre} </div>
					{/*<div className='renderMyCompanyRightText'> {Phone_prefix} {Phone_number}</div>*/}
					{/*<div className='renderMyCompanyLeftTextBlankSpace'> </div>*/}
				</div>
			)
		} else {

			let inputsArrow = 'dropDownInMyProfilePersonGenre';
			if (this.state.Nuevo_perfil.Person.Genre === null) {
				inputsArrow = 'dropDownInMyProfilePersonGenreEmpty';
			}

			return (
				<div className='renderMyCompanyRight'>
					<input
						className="renderMyCompanyRightInput" autoFocus type="text"
						value={ this.state.Nuevo_perfil.Person.Nickname }
						onChange={(e) => this.setNewProfile(e.target.value, 'Person', 'Nickname', null, 'Person')}
					/>

					<input
						className="renderMyCompanyRightDate" autoFocus type="date"
						value={ this.state.Nuevo_perfil.Person.Birth_date }
						onChange={(e) => this.setNewProfile(e.target.value, 'Person', 'Birth_date', null, 'Person')}
					/>

					<input
						className="renderMyCompanyRightInput" autoFocus type="text"
						value={ this.state.Nuevo_perfil.Person.Postal_code }
						onChange={(e) => this.setNewProfile(e.target.value, 'Person', 'Postal_code', null, 'Person')}
					/>

					<div className='renderMyCompanyRightInputDropdownPerson' >
						<Button className='dropdownInSettings' block size="lg"
								onClick={(e) => this.buttonInputFieldForDropdowns('Genre', null)}
						> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Person.Genre}</div>
							<img className={inputsArrow} alt=""/>
						</Button>
						{this.DropDownAttribute('Genre', null, 'false', 'Person', 'Person')}
					</div>
				</div>
			)
		}
	}


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//Here begins the Company' sub-tab

	renderMyCompany = () => {
		// console.log(this.state.Nuevo_perfil);
		return (
			<div className='renderMyCompany'>
				<span className='renderMyCompanyName'>Company details</span>
				<div className='editMyCompanyButtonContainer'>
					<Button className="editMyCompanyButton" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEdit()}>
						{'Edit data'}
					</Button>
				</div>
				<div className='renderMyCompanyData'>
					{this.renderMyProfileLeft()}
					{this.renderMyCompanyRight()}
				</div>
			</div>
		)
	}

	changeSecondaryStatusToEdit = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.Edit) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Edit});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToManagePSI = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.ManagePSI) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.ManagePSI});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToNormal = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.Normal) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Normal});
			return null;
		} else {
			return null;
		}
	}

	editMyCompany = () => {

		return [
			<div className='whiteSpaceInBackground'> </div>,
			<div className='fillingBackgroundContainer'>
				<span className='renderMyCompanyName'>Edit your company details</span>
				<div className='renderMyCompanyData'>
					{this.renderMyProfileLeft()}
					{this.renderMyCompanyRight()}
					{this.buttonsEditMyCompany()}
					{this.disclaimerVeracityInformationCompanyAndSettingsEdit()}
					{this.disclaimerTFN()}
				</div>
			</div>
		]
	}

	disclaimerVeracityInformationCompanyAndSettings = () => {

		if (isUserACompany()) {
			return (
				<div className='disclaimerVeracityInformationCompanyAndSettings'>The person providing the corporate
					information above certifies and is responsible for its veracity.</div>
			)
		} else { return null;}
	}

	disclaimerVeracityInformationCompanyAndSettingsEdit = () => {
		return(
			<div className='disclaimerVeracityInformationCompanyAndSettingsEdit'>The person providing the corporate information above certifies and is responsible for its veracity.</div>
		)
	}

	disclaimerVeracityInformationEmployees = () => {
		return(
			<div className='disclaimerVeracityInformationEmployees'>The person providing the corporate information above certifies its veracity, is responsible for it and acknowledges that the fields present above will exclusively refer to corporate information of employees.</div>
		)
	}

	disclaimerVeracityInformationShops = () => {
		return(
			<div className='disclaimerVeracityInformationShops'>The person providing the corporate information above certifies and is responsible for its veracity.</div>
		)
	}

	disclaimerTFN = () => {
		return(
			<div className='disclaimerTFN'>*Once you have already introduced your company's TFN, you will not be able to change it manually.
				To do so, please write an e-mail to [dirección correo].</div>
		)
	}

	disclaimerVeracityInformationCurrency = () => {
		return(
			<div className='disclaimerVeracityInformationCurrency'>The person providing the corporate information above certifies and is responsible for its veracity.</div>
		)
	}

	decidePlaceHolderCompany = (prop) => {
		//console.log(prop);
		//const no_employees = this.state.Profile.Company.length;
		const company = this.state.Nuevo_perfil.Company;
		if (prop === 'Name') {
			return company.Name;
		}
		if (prop === 'Address') {
			return company.Address;
		}
		if (prop === 'Email') {
			return company.Email;
		}
		if (prop === 'Province') {
			return company.Province;
		}
		if (prop === 'Region') {
			return company.Region;
		}
		if (prop === 'Phone_number') {
			return company.Phone_number;
		}
		if (prop === 'Phone_prefix') {
			return '+' + company.Phone_prefix;
		}
		if (prop === 'Postal_code') {
			return company.Postal_code;
		}
		if (prop === 'Country') {
			return company.Country;
		}
		if (prop === 'Sector') {
			return company.Sector;
		}
		if (prop === 'Subsector') {
			return company.Subsector;
		}
		if (prop === 'TFN') {
			return company.TFN;
		}

	}


	editCompanyForm = () => {
		//console.log('lol');
		//console.log(this.state);

		return (
			<div className='editCompanyForm'>
				<Form id='filterForm'>
					<Form.Group size="lg" controlId="formNamecontrol">
						<Form.Label> Name </Form.Label>
						<br/>
						<Form.Control
							className=""
							autoFocus
							type="formName"
							value={this.decidePlaceHolderCompany('Name')}
							onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Name', null, 'Company')}
						/>
					</Form.Group>

					<Form.Group size="lg" controlId="Address">
						<Form.Label>Address </Form.Label>
						<Form.Control
							className=""
							type="Address"
							value={this.decidePlaceHolderCompany('Address')}
							onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Address', null, 'Company')}
						/>
					</Form.Group>

					<Form.Group size="lg" controlId="Email">
						<Form.Label>Contact email</Form.Label>
						<Form.Control
							className=""
							type="Email"
							value={this.decidePlaceHolderCompany('Email')}
							onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Email', null, 'Company')}
						/>
					</Form.Group>


					<Form.Group size="lg" controlId="Phone_number">
						<Form.Label>Contact phone</Form.Label>
						<Form.Control
							className=""
							type="Phone_number"
							value={this.decidePlaceHolderCompany('Phone_number')}
							onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Phone_number', null, 'Company')}
						/>
					</Form.Group>

					<Form.Group size="lg" controlId="Postal_code">
						<Form.Label>Postal code</Form.Label>
						<Form.Control
							className=""
							type="Postal_code"
							value={this.decidePlaceHolderCompany('Postal_code')}
							onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Postal_code', null, 'Company')}
						/>
					</Form.Group>

					<Form.Group size="lg" controlId="items">
						<Form.Label>Phone prefix</Form.Label>
					</Form.Group>

					<Form.Group size="lg" controlId="TFN">
						<Form.Label>TFN</Form.Label>
						<Form.Control
							className=""
							type="TFN"
							value={this.decidePlaceHolderCompany('TFN')}
							onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'TFN', null, 'Company')}
						/>
					</Form.Group>

					<Button className="Clear_button" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
						{'Cancel'}
					</Button>

					<Button className="Filter_button" block size="lg" onClick={(e) => this.submitEditCompanyForm()}>
						{'Save'}
					</Button>

				</Form>
			</div>
		)
	}



	setNewProfile = (event, part, field, indice, subprofile) => {
		// console.log(this.state.Nuevo_perfil);
		// console.log(this.state.Profile);
		//console.log(this.state.Profile.Company === this.state.Nuevo_perfil.Company);
		// console.log(this.state.Nuevo_perfil);

		if (part === 'Company') {
			if (subprofile === 'Company') {
				if (field === 'Name') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Name: event})
					);
				}
				if (field === 'Address') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Address: event})
					);
				}
				if (field === 'Email') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Email: format_email_address(event)})
					);
				}
				if (field === 'Province') {
					// Check if the empty value has been provided
					if (event === '(Empty)') {
						event = null;
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Province: event})
					);
				}
				if (field === 'Region') {
					// Check if the empty value has been provided
					if (event === '(Empty)') {
						event = null;
						// Remove Province
						this.setState(
							Object.assign(this.state.Nuevo_perfil.Company, {Province: event})
						);
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Region: event})
					);
				}
				if (field === 'Phone_number') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Phone_number: event}), () => this.validateEditMyCompanyInput('Phone_number', 'Company')
					);
				}
				if (field === 'Phone_prefix') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Phone_prefix: event})
					);
				}
				if (field === 'Postal_code') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Postal_code: event})
					);
				}
				if (field === 'Country') {
					// Check if the empty value has been provided
					if (event === '(Empty)') {
						event = null;
						// Remove Province and Region
						this.setState(
							Object.assign(this.state.Nuevo_perfil.Company, {Region: event})
						);
						this.setState(
							Object.assign(this.state.Nuevo_perfil.Company, {Province: event})
						);
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Country: event})
					);
				}
				if (field === 'Sector') {
					// Check if the empty value has been provided
					if (event === '(Empty)') {
						event = null;
						// Remove Subsector
						this.setState(
							Object.assign(this.state.Nuevo_perfil.Company, {Subsector: event})
						);
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Sector: event})
					);
				}
				if (field === 'Subsector') {
					// Check if the empty value has been provided
					if (event === '(Empty)') {
						event = null;
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Subsector: event})
					);
				}
				if (field === 'Currency') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {Currency: event})
					);
				}
				if (field === 'TFN') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Company, {TFN: event}), () => this.validateEditMyCompanyInput('TFN', 'Company')
					);
				}
			}
			if (subprofile === 'Shops') {
				if (field === 'Country') {
					console.log(event);
					if (event !== '(Empty)') {
						this.addOneMoreRowToNewShops(indice);
					} else {
						// Set to null the current row's country, region and province
						event = null;
						this.setState(
							Object.assign(this.state.Nuevo_perfil.Shops[indice], {Region: event})
						);
						this.setState(
							Object.assign(this.state.Nuevo_perfil.Shops[indice], {Province: event})
						);
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Shops[indice], {Country: event}), () => {console.log(this.state.Nuevo_perfil.Shops[indice])}
					);
				}
				if (field === 'Region') {
					if (event !== '(Empty)') {
						this.addOneMoreRowToNewShops(indice);
					} else {
						// Set to null the current row's country, region and province
						event = null;
						this.setState(
							Object.assign(this.state.Nuevo_perfil.Shops[indice], {Province: event})
						);
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Shops[indice], {Region: event})
					);
				}
				if (field === 'Province') {
					if (event !== '(Empty)') {
						this.addOneMoreRowToNewShops(indice);
					} else {
						// Set to null the current row's province
						event = null;
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Shops[indice], {Province: event})
					);
				}
			}
			if (subprofile === 'Employee') {
				if (field === 'Treatment') {
					this.addOneMoreRowToNewEmployees(indice);
					console.log(event)
					if (event === '(Empty)') {
						event = null;
					}
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Employees[indice], {Treatment: event})
					);
				}
			}
		}

		if (part === 'Person') {
			if (subprofile === 'Person') {
				if (field === 'Genre') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Person, {Genre: event})
					);
				}
				if (field === 'Birth_date') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Person, {Birth_date: event})
					);
				}
				if (field === 'Postal_code') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Person, {Postal_code: event})
					);
				}
				if (field === 'Nickname') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Person, {Nickname: event})
					);
				}
				if (field === 'Email') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Person, {Email: format_email_address(event) })
					);
				}
				if (field === 'Phone_prefix') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Person, {Phone_prefix: event})
					);
				}
				if (field === 'Phone_number') {
					this.setState(
						Object.assign(this.state.Nuevo_perfil.Person, {Phone_number: event}), () => this.validateEditMyCompanyInput('Phone_number', 'Person')
					);
				}
			}
		}

		return null;
	}


	validateEditMyCompanyInput = (field, profile) => {

		if (profile === 'Company') {
			if (field === 'Phone_number') {
				if (!validate_integers_or_null(this.state.Nuevo_perfil.Company.Phone_number)) {
					this.setState(
						Object.assign(this.state.CompanyErrors.Company, {Phone_number: true})
					);
				} else {
					this.setState(
						Object.assign(this.state.CompanyErrors.Company, {Phone_number: false})
					);
				}
			}

			if (validate_integers_or_null(this.state.Nuevo_perfil.Company.TFN)) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Company, {TFN: true})
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Company, {TFN: false})
				);
			}
		}
		if (profile === 'Person') {

			if (field === 'Phone_number') {
				if (!validate_integers_or_null(this.state.Nuevo_perfil.Person.Phone_number)) {
					this.setState(
						Object.assign(this.state.CompanyErrors.Person, {Phone_number: true})
					);
				} else {
					this.setState(
						Object.assign(this.state.CompanyErrors.Person, {Phone_number: false})
					);
				}
			}
		}


		return null;

	}

	resetProfileAndErrorsInBackground = (confirmation_do) => {

		if (confirmation_do === true) {
			this.resetNuevoPerfil();
		}
		this.resetContras();
		this.resetCurrency();
		this.resetHighLevelErrors();
		this.resetEmails();
		this.buttonInputFieldForDropdowns('None', null);

		this.setState({PressedSendButton: false});
		this.setState({PSI_Validation_code: null})

		this.setState({SecondaryStatus: this.state.SecondaryCode.Normal});

		return null;
	}

	cancelEditProfileForm = () => {

		if (isUserACompany() || isUserAPSI()) {
			//console.log(this.state.Nuevo_perfil);
			this.resetProfileAndErrorsInBackground(true);
		} else {
			this.resetProfileAndErrorsInBackground(true);
		}
		this.setState({TerciaryStatus: this.state.TerciaryCode.Normal})

		if (this.state.SecondaryStatus !== this.state.SecondaryCode.Normal) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Normal});
			return null;
		} else {
			return null;
		}
	}


	resetEmails = () => {
		this.setState({NewEmail: null});
		this.setState({RepeatNewEmail: null});
		return null;
	}


	resetHighLevelErrors = () => {

		let highLevelErrorsMyProfile = {Employees: {Treatment: false, Name: false, Surname: false, Role: false, Email_address: false },
			Shops: {Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false},
			areNewEmailsTheSame: true,
			areNewPasswordsTheSame: true
		};

		this.setState({HighLevelErrorsMyProfile: highLevelErrorsMyProfile});

		return null;

	}



	resetCurrency = () => {
		this.setState({Currency: null});
		return null;
	}

	resetContras = () => {
		this.setState({Password: null});
		this.setState({NewPassword: null});
		this.setState({RepeatNewPassword: null});
		this.setState({OldPassword: null});
		return null;
	}

	resetNuevoPerfil = () => {
		var Nuevo_perfil = {};
		if (isUserACompany()) {
			let Profile_output = this.state.Profile;
			console.log(Profile_output);

			var null_employee = {};
			var Null_employee = [];
			var False_employee_errors = [];
			// console.log(+Profile_output.Employees.length);
			if (+Profile_output.Employees.length === 0) {
				Null_employee.push({ID: 0, Treatment: null, Name: null, Surname: null, Role: null, Email_address: null});
				False_employee_errors.push({Treatment: false, Name: false, Surname: false, Role: false, Email_address: false});
			} else {
				for (let step = 0; step < +Profile_output.Employees.length; step++) {
					null_employee= Object.assign({}, Profile_output.Employees[step]);
					Null_employee.push(null_employee);
					False_employee_errors.push({Treatment: false, Name: false, Surname: false, Role: false, Email_address: false});
				}
				Null_employee.push({
					ID: null, Treatment: null, Name: null, Surname: null, Role: null, Email_address: null
				});
				False_employee_errors.push({Treatment: false, Name: false, Surname: false, Role: false, Email_address: false});
			}


			var null_shop = {};
			var Null_shop = [];
			var False_shop_errors = [];
			// console.log(+Profile_output.Shops.length);
			if (+Profile_output.Shops.length === 0) {
				Null_shop.push({
					ID: null, Name: null, Address: null, Country: null, Region: null, Province: null, Phone_number: null
				});
				False_shop_errors.push({Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false});
			} else {
				for (let step = 0; step < +Profile_output.Shops.length; step++) {
					null_shop = Object.assign({}, Profile_output.Shops[step]);
					Null_shop.push(null_shop);

					False_shop_errors.push({Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false});
				}
				Null_shop.push({
					ID: null, Name: null, Address: null, Country: null, Region: null, Province: null, Phone_number: null
				});
				False_shop_errors.push({Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false});
			}

			// Initialize the part of the Company profile required to be displayed later on.
			Nuevo_perfil['Company'] = {
				Name: Profile_output.Company.Name,
				Address: Profile_output.Company.Address,
				Email: format_email_address(Profile_output.Company.Email),
				Phone_number: Profile_output.Company.Phone_number,
				Postal_code: Profile_output.Company.Postal_code,
				Country: Profile_output.Company.Country,
				Currency: Profile_output.Company.Currency,
				Region: Profile_output.Company.Region,
				Province: Profile_output.Company.Province,
				Phone_prefix: Profile_output.Company.Phone_prefix,
				Sector: Profile_output.Company.Sector,
				Subsector: Profile_output.Company.Subsector,
				TFN: Profile_output.Company.TFN
			};

			Nuevo_perfil['Shops'] = Object.assign([], Null_shop);
			Nuevo_perfil['Employees'] = Object.assign([], Null_employee);

			console.log(Nuevo_perfil);

			let companyErrors = {
				Company: {
					Name: false, Address: false, Email: false, Province: false, Region: false, Phone_number: false,
					Phone_prefix: false, Postal_code: false, Country: false, Sector: false, Subsector: false,
					Currency: false, TFN: false
				},
				Employees: False_employee_errors,
				Shops: False_shop_errors,
				Settings: {
					NewEmailError: true, RepeatNewEmailError: true, OldPasswordError: true, PasswordError: true,
					NewPasswordError: true, RepeatNewPasswordError: true
				},
				Person: {
					Email: false, Genre: false, Nickname: false, Phone_number: false, Phone_prefix: false,
					Postal_code: false

				}
			};

			this.setState({CompanyErrors: companyErrors}, ( )=> {console.log(this.state.CompanyErrors)});


			//console.log(Nuevo_perfil);

			this.setState({Nuevo_perfil: Nuevo_perfil}, () => {console.log(this.state.Profile);
				console.log(this.state.Nuevo_perfil);});
		} else {
			if (isUserAPSI()) {
				let Profile_output = this.state.Profile;

				// Initialize the part of the Company profile required to be displayed later on.
				Nuevo_perfil['Company'] = {
					Name: Profile_output.Company.Name,
					Address: Profile_output.Company.Address,
					Email: format_email_address(Profile_output.Company.Email),
					Phone_number: Profile_output.Company.Phone_number,
					Postal_code: Profile_output.Company.Postal_code,
					Country: Profile_output.Company.Country,
					Currency: Profile_output.Company.Currency,
					Region: Profile_output.Company.Region,
					Province: Profile_output.Company.Province,
					Phone_prefix: Profile_output.Company.Phone_prefix,
					Sector: Profile_output.Company.Sector,
					Subsector: Profile_output.Company.Subsector,
					TFN: Profile_output.Company.TFN
				};

				let companyErrors = {
					Company: {
						Name: false, Address: false, Email: false, Province: false, Region: false, Phone_number: false,
						Phone_prefix: false, Postal_code: false, Country: false, Sector: false, Subsector: false,
						Currency: false, TFN: false
					},
					Employees: False_employee_errors,
					Shops: False_shop_errors,
					Settings: {
						NewEmailError: true, RepeatNewEmailError: true, OldPasswordError: true, PasswordError: true,
						NewPasswordError: true, RepeatNewPasswordError: true
					},
					Person: {
						Email: false, Genre: false, Nickname: false, Phone_number: false, Phone_prefix: false,
						Postal_code: false

					}
				};

				this.setState({CompanyErrors: companyErrors}, ( )=> {console.log(this.state.CompanyErrors)});

				//console.log(Nuevo_perfil);

				this.setState({Nuevo_perfil: Nuevo_perfil}, () => {console.log(this.state.Profile);
					console.log(this.state.Nuevo_perfil);});

			}
			else {

				let state = this.state.Profile;
				var new_profile = {
					Birth_date: state.Person.Birth_date,
					Email: format_email_address(state.Person.Email),
					Genre: state.Person.Genre,
					Nickname: state.Person.Nickname,
					Phone_number: state.Person.Phone_number,
					Phone_prefix: state.Person.Phone_prefix,
					Postal_code: state.Person.Postal_code
				};

				Nuevo_perfil['Person'] = new_profile;

				let companyErrors = {
					Company: {
						Name: false, Address: false, Email: false, Province: false, Region: false, Phone_number: false,
						Phone_prefix: false, Postal_code: false, Country: false, Sector: false, Subsector: false,
						Currency: false, TFN: false
					},
					Employees: [{
						Treatment: false, Name: false, Surname: false, Role: false, Email_address: false
					}],
					Shops: [{
						Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false
					}],
					Settings: {
						NewEmailError: true, RepeatNewEmailError: true, OldPasswordError: true, PasswordError: true,
						NewPasswordError: true, RepeatNewPasswordError: true
					},
					Person: {
						Email: false, Genre: false, Nickname: false, Phone_number: false, Phone_prefix: false,
						Postal_code: false

					}
				};

				this.setState({CompanyErrors: companyErrors});

				this.setState({Nuevo_perfil: Nuevo_perfil});
			}
		}

		return null;
	}

	renderMyProfileLeft = () => {

		if (isUserACompany()) {

			let tfn = 'TFN';
			if (this.state.SecondaryStatus !== this.state.SecondaryCode.Normal) {
				tfn = <div><span>TFN</span><span className='MandatoryFieldCompany'>*</span></div>;
			}

			return (
				<div className='renderMyCompanyLeft'>
					<div className='renderMyCompanyLeftText'> {'Company name'} </div>
					<div className='renderMyCompanyLeftText'> Country</div>
					<div className='renderMyCompanyLeftText'> Region</div>
					<div className='renderMyCompanyLeftText'> Province</div>
					<div className='renderMyCompanyLeftText'> Address</div>
					<div className='renderMyCompanyLeftText'> {'Postal code'} </div>
					<div className='renderMyCompanyLeftText'>{tfn}</div>
					{/*<div className='renderMyCompanyLeftText'> {'Contact phone'} </div>*/}
					<div className='renderMyCompanyLeftTextBlankSpace'> </div>
					<div className='renderMyCompanyLeftText'> Sector</div>
					<div className='renderMyCompanyLeftText'> Subsector</div>
				</div>
			)
		} else {
			let tfn = 'TFN';
			if (this.state.SecondaryStatus !== this.state.SecondaryCode.Normal) {
				tfn = <div><span>TFN</span><span className='MandatoryFieldCompany'>*</span></div>;
			}
			if (isUserAPSI()) {
				return (
					<div className='renderMyCompanyLeft'>
						<div className='renderMyCompanyLeftText'> {'Company name'} </div>
						<div className='renderMyCompanyLeftText'> Country</div>
						<div className='renderMyCompanyLeftText'> Region</div>
						<div className='renderMyCompanyLeftText'> Province</div>
						<div className='renderMyCompanyLeftText'> Address</div>
						<div className='renderMyCompanyLeftText'> {'Postal code'} </div>
						<div className='renderMyCompanyLeftText'>{tfn}</div>
						{/*<div className='renderMyCompanyLeftText'> {'Contact phone'} </div>*/}
						<div className='renderMyCompanyLeftTextBlankSpace'> </div>
					</div>
				)
			} else {
				return (
					<div className='renderMyCompanyLeft'>
						<div className='renderMyCompanyLeftText'> {'Nickname'} </div>
						<div className='renderMyCompanyLeftText'> {'Date of birth'}</div>
						<div className='renderMyCompanyLeftText'> {'Postal code'}</div>
						<div className='renderMyCompanyLeftText'> {'Genre'}</div>
						{/*<div className='renderMyCompanyLeftText'> {'Contact phone'} </div>*/}
						{/*<div className='renderMyCompanyLeftTextBlankSpace'> </div>*/}
					</div>
				)
			}
		}
	}

	renderMyCompanyRight = (row_index) => {
		const to_map = this.state.Profile.Company;
		// console.log(to_map);

		const {
			Address, Country, Name, Province, Region, Sector, Subsector, TFN,
			Postal_code
		} = to_map //destructuring
		if (this.state.SecondaryStatus === this.state.SecondaryCode.Normal) {
			return (
				<div className='renderMyCompanyRight'>
					<div className='renderMyCompanyRightText' title = {Name}> {Name} </div>
					<div className='renderMyCompanyRightText' title = {Country}> {Country} </div>
					<div className='renderMyCompanyRightText' title = {Region}> {Region} </div>
					<div className='renderMyCompanyRightText' title = {Province}> {Province} </div>
					<div className='renderMyCompanyRightText' title = {Address}> {Address} </div>
					<div className='renderMyCompanyRightText' title = {Postal_code}> {Postal_code} </div>
					<div className='renderMyCompanyRightText' title = {TFN}> {TFN} </div>
					{/*<div className='renderMyCompanyRightText'> {Phone_prefix} {Phone_number}</div>*/}
					<div className='renderMyCompanyLeftTextBlankSpace'> </div>
					<div className='renderMyCompanyRightText' title = {Sector}> {Sector} </div>
					<div className='renderMyCompanyRightText' title = {Subsector}> {Subsector} </div>
				</div>
			)
		} else {

			let inputsArrow = 'dropDownInMyProfileProfile';


			let sector = <div className='renderMyCompanyRightInputDropdown' >
							<Button className='dropdownInSettings' block size="lg"
									onClick={(e) => this.buttonInputFieldForDropdowns('Sector', null)}
							> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Company.Sector}</div>
								<img className={inputsArrow} alt=""/>
							</Button>
							{this.DropDownAttribute('Sector', null, 'false', 'Company', 'Company')}
						</div>;

			let subsector = <div className='renderMyCompanyRightInputDropdown' >
								<Button className='dropdownInSettings' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Subsector', null)}
								> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Company.Subsector}</div>
									<img className={inputsArrow} alt=""/>
								</Button>
								{this.DropDownAttribute('Subsector', null, 'false', 'Company', 'Company')}
							</div>

			if (isUserAPSI()) {
				sector = null;
				subsector = null;
			}


			return (
				<div className='renderMyCompanyRight'>
					{/*<input className='renderMyCompanyRightText'> {Name} </input>*/}
					<input
						className="renderMyCompanyRightInput" autoFocus type="email"
						value={this.decidePlaceHolderCompany('Name')}
						onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Name', null, 'Company')}
					/>



					<div className='renderMyCompanyRightInputDropdown' >
						<Button className='dropdownInSettings' block size="lg"
								onClick={(e) => this.buttonInputFieldForDropdowns('Country', null)}
						> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Company.Country}</div>
							<img className={inputsArrow} alt=""/>
						</Button>
						{this.DropDownAttribute('Country', null, 'false', 'Company', 'Company')}
					</div>

					<div className='renderMyCompanyRightInputDropdown' >
						<Button className='dropdownInSettings' block size="lg"
								onClick={(e) => this.buttonInputFieldForDropdowns('Region', null)}
						> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Company.Region}</div>
							<img className={inputsArrow} alt=""/>
						</Button>
						{this.DropDownAttribute('Region', null, 'false', 'Company', 'Company')}
					</div>

					<div className='renderMyCompanyRightInputDropdown' >
						<Button className='dropdownInSettings' block size="lg"
								onClick={(e) => this.buttonInputFieldForDropdowns('Province', null)}
						> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Company.Province}</div>
							<img className={inputsArrow} alt=""/>
						</Button>
						{this.DropDownAttribute('Province', null, 'false', 'Company', 'Company')}
					</div>

					<input
						className="renderMyCompanyRightInput" autoFocus type="text"
						value={this.decidePlaceHolderCompany('Address')}
						onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Address', null, 'Company')}
					/>

					<input
						className="renderMyCompanyRightInput" autoFocus type="text"
						value={this.decidePlaceHolderCompany('Postal_code')}
						onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Postal_code', null, 'Company')}
					/>

					<input
						className="renderMyCompanyRightInput" autoFocus type="text"
						disabled={(this.state.Profile.Company.TFN !== '' && this.state.Profile.Company.TFN !== null && this.state.Profile.Company.TFN !== undefined)}
						value={this.decidePlaceHolderCompany('TFN')}
						onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'TFN', null, 'Company')}
					/>


					<div className='renderMyCompanyLeftTextBlankSpace'> </div>

					{sector}
					{subsector}
				</div>
			)
		}
	}

	DropDownAttribute = (attribute, row_index, table, profile, subprofile) => {
		// console.log(attribute)
		// console.log(row_index);
		// console.log(table)
		// console.log(profile)
		// console.log(subprofile)

		let Attributes = [];

		if (attribute === 'Genre' && this.state.InputField === 'Genre') {
			Attributes = file.Genre;
		}
		if (attribute === 'Country' && this.state.InputField === 'Country') {
			Attributes = file.Country;
		}
		if (attribute === 'Currency' && this.state.InputField === 'Currency') {
			let Country = this.state.Nuevo_perfil.Company.Country;
			// console.log(Country)
			if (Country === null || Country === '(Empty)') {
				// Country has not been chosen but user selects Currency first
				Country = 'Unselected';
			}
			var Currency = file.Currency;
			Attributes = Currency[Country.valueOf()];
		}
		if (attribute === 'Region' && this.state.InputField === 'Region') {
			let Country = this.state.Nuevo_perfil.Company.Country;
			// console.log(Country)
			if (subprofile === 'Shops') {
				if (this.state.Nuevo_perfil.Shops[row_index].Country !== null) {
					Country = this.state.Nuevo_perfil.Shops[row_index].Country;
				}
			}
			if (Country === null || Country === 'Country') {
				// Country has not been chosen but user selects Region first
				Country = 'Unselected';
			}
			// console.log(Country)
			var Region = file.Region;
			Attributes = Region[Country.valueOf()];
			// console.log(Attributes)
		}
		if (attribute === 'Province' && this.state.InputField === 'Province') {
			let Region = this.state.Nuevo_perfil.Company.Region;
			if (subprofile === 'Shops') {
				if (this.state.Nuevo_perfil.Shops[row_index].Region !== null) {
					Region = this.state.Nuevo_perfil.Shops[row_index].Region;
				}
			}
			if (Region === null || Region === 'Region') {
				// Region has not been chosen but user selects Province first
				Region = 'Unselected';
			}
			var Province = file.Province;
			Attributes = Province[Region.valueOf()];
		}
		if (attribute === 'Phone_prefix' && this.state.InputField === 'Phone_prefix') {
			if(profile === 'Company') {
				const Country = this.state.Nuevo_perfil.Company.Country;
				// console.log(this.state.Nuevo_perfil);
				var Phone_prefix = file.Phone_prefix;
				Attributes = Phone_prefix[Country.valueOf()];
			} else {
				Attributes = file.Plain_phone_prefix;
			}
		}
		if (attribute === 'Sector' && this.state.InputField === 'Sector') {
			Attributes = file.Sector;
		}
		if (attribute === 'Subsector' && this.state.InputField === 'Subsector') {
			let Sector = this.state.Nuevo_perfil.Company.Sector;
			if (Sector === null) {
				// Sector has not been chosen but user selects Subsector first
				Sector = 'Unselected';
			}
			var Subsectors = file.Subsector;
			Attributes = Subsectors[Sector.valueOf()];
		}
		if (attribute === 'Treatment' && this.state.InputField === 'Treatment') {
			Attributes = file.Treatment;
		}


		var array2 = [];
		console.log(Attributes)

		Attributes.forEach(Attribute => {
			array2.push(
				<div className=''>
					<Dropdown.Item eventKey="1" className='dropDownOperations' Attribute={Attribute}
								   disabled = {this.mustDropdownBeDisabled(attribute, row_index, table)}
								   onClick={(e) => this.assignAttributeFromDropDown(e, profile, attribute, row_index, subprofile)}> {Attribute} </Dropdown.Item>
				</div>
			)
		})

		// // The return has to be: Null if I have not clicked on the button; the dropdown menu otherwise
		if (this.state.InputField === attribute.valueOf()) {
			if (attribute === 'Treatment') {
				if (table === 'true') {
					if (row_index === this.state.Row_index) {
						console.log('lol')
						return (<div className='dropdownMenuSettingsTreatment'>
							{array2}
						</div>);
					} else { return null; }
				}
				if (table === 'false') {
					return (<div className='dropdownMenuSettingsCompany'>
						<div className='dropdownMenuSettingsTreatmentAbsolute'> {array2} </div></div>);
				}
			}
			if (attribute === 'Country') {
				if (table === 'true') {
					if (row_index === this.state.Row_index) {
						return (<div className='dropdownMenuSettingsCountry'>
							{array2}
						</div>);
					} else {return null;}
				}
				if (table === 'false') {
					return ( <div className='dropdownMenuSettingsCompany'> {array2} </div> );
				}
			}
			if (attribute === 'Region') {
				if (table === 'true') {
					if (row_index === this.state.Row_index) {
						return (<div className='dropdownMenuSettingsRegion'>
							{array2}
						</div>);
					} else {return null;}
				}
				if (table === 'false') {
					return ( <div className='dropdownMenuSettingsCompany'> {array2} </div> );
				}
			}
			if (attribute === 'Province') {
				if (table === 'true') {
					if (row_index === this.state.Row_index) {
						return (<div className='dropdownMenuSettingsProvince'> {array2} </div>);
					} else {return null;}
				}
				if (table === 'false') {
					return ( <div className='dropdownMenuSettingsCompany'> {array2} </div> );
				}
			}
			if (attribute !== 'Phone_prefix' && attribute !== 'Currency' ) {
				return ( <div className='dropdownMenuSettingsCompany'> {array2} </div> );
			}
			if (attribute === 'Phone_prefix') {
				return ( <div className='dropdownMenuSettingsPhonePrefix'> {array2} </div> );
			}
			if (attribute === 'Currency') {
				return ( <div className='dropdownMenuSettingsCurrency'> {array2} </div> );
			}
		} else {
			if (this.state.InputField === attribute.valueOf()) {
				return null;
			}
		}
	}


	mustDropdownBeDisabled = (field, row_index, table) => {
		// console.log(row_index)
		// console.log(table)
		// Decides if the dropdown must be disabled due to some prior field not being selected yet.
		if (table === 'true') {
			if (field === 'Region' && this.state.Nuevo_perfil.Shops[row_index].Country === null) {
				return true;
			}
			if (field === 'Province' && this.state.Nuevo_perfil.Shops[row_index].Region === null) {
				return true;
			}
		}
		if (table === 'false') {
			if (field === 'Region' && this.state.Nuevo_perfil.Company.Country === null && table === 'false') {
				return true;
			}
			if (field === 'Province' && this.state.Nuevo_perfil.Company.Region === null && table === 'false') {
				return true;
			}
		}
		if (field === 'Subsector' && this.state.Nuevo_perfil.Company.Sector === null){
			return true;
		}
		return false;
	}

	assignAttributeFromDropDown = (event, profile_type, attribute, indice, subprofile) => {
		// The parameter Attribute is the word Sector, Subsector, Currency,... It's an attribute assigned to the dropdown that calls
		// this function.

		// console.log('hola')
		// Restart the dropdown menu --> Next time the user clicks on any dropdown, the menu displayed will be that
		// of the field
		this.buttonInputFieldForDropdowns('None', null);

		const value = (event.currentTarget.getAttribute('Attribute') );

		this.setNewProfile(value, profile_type, attribute, indice, subprofile)

		if (subprofile === 'Employee') {
			this.inputValidationOfEmployees(null, indice, null)
			// inputShopTablefunction
		}
		if (subprofile === 'Shops') {
			this.inputValidationOfShops(null, indice, null)
			// inputShopTablefunction
		}

		return null;
	}

	buttonInputFieldForDropdowns = (parametro, row_index) => {

		if (parametro === 'None') {
			this.setState({InputField: 'None'});
		}

		if (parametro === 'Genre') {
			this.setState({InputField: 'Genre'});
		}

		if (parametro === 'Country') {
			this.setState({InputField: 'Country'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Region') {
			this.setState({InputField: 'Region'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Province') {
			this.setState({InputField: 'Province'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Currency') {
			this.setState({InputField: 'Currency'});
		}
		if (parametro === 'Phone_prefix') {
			this.setState({InputField: 'Phone_prefix'});
		}
		if (parametro === 'Sector') {
			this.setState({InputField: 'Sector'});
		}
		if (parametro === 'Subsector') {
			this.setState({InputField: 'Subsector'});
		}
		if (parametro === 'Treatment') {
			this.setState({InputField: 'Treatment'});
			this.setState({Row_index: row_index});
		}
		if (parametro === this.state.InputField) {
			this.setState({InputField: 'None'});
		}

		return null;
	}

	buttonsEditMyCompany = () => {
		return(
			<div className='buttonsEditMyCompany'>
				<Button className="Clear_button_edit" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="Save_button_edit" block size="lg" onClick={(e) => this.submitEditCompanyForm()}>
					{'Save'}
				</Button>
			</div>
		)
	}



	submitEditCompanyForm = () => {

		this.setState({PressedSendButton: true});
		// console.log(this.state.HighLevelErrorsMyProfile.Shops.Phone_number);

		// (this.state.CompanyErrors.Company.Phone_number === true || this.state.CompanyErrors.Person.Phone_number === true)
		// && this.state.PressedSendButton === true
		// console.log(is_field_always_present_in_array(this.state.Nuevo_perfil.Shops, 'Name'))
		// console.log(is_field_always_present_in_array(this.state.Nuevo_perfil.Employees, 'Name'))

		console.log(this.state.HighLevelErrorsMyProfile.Employees)

		if (this.state.HighLevelErrorsMyProfile.Employees.Email_address === false &&
			this.state.HighLevelErrorsMyProfile.Shops.Phone_number === false &&
			this.state.CompanyErrors.Company.Phone_number === false &&
			this.state.CompanyErrors.Person.Phone_number === false ) {
			console.log('request enviada')
			this.requestCompanyForm();
		}
		return null;
	}


	submitManageMyPSI = (Action) => {

		// this.setState({PressedSendButton: true});
		// // console.log(this.state.HighLevelErrorsMyProfile.Shops.Phone_number);
		//
		// // (this.state.CompanyErrors.Company.Phone_number === true || this.state.CompanyErrors.Person.Phone_number === true)
		// // && this.state.PressedSendButton === true
		// // console.log(is_field_always_present_in_array(this.state.Nuevo_perfil.Shops, 'Name'))
		// // console.log(is_field_always_present_in_array(this.state.Nuevo_perfil.Employees, 'Name'))
		//
		// console.log(this.state.HighLevelErrorsMyProfile.Employees)
		//
		// if (this.state.HighLevelErrorsMyProfile.Employees.Email_address === false &&
		// 	this.state.HighLevelErrorsMyProfile.Shops.Phone_number === false &&
		// 	this.state.CompanyErrors.Company.Phone_number === false &&
		// 	this.state.CompanyErrors.Person.Phone_number === false ) {
		// 	console.log('request enviada')
			this.sendManageMyPSI(Action);
		// }
		return null;
	}


	submitEditCompanyFormEmployeeAndShop = () => {
		this.setState({PressedSendButton: true});
		this.ErrorMessagesInMyAccount()
		// console.log(this.state.HighLevelErrorsMyProfile.Shops.Phone_number);

		// (this.state.CompanyErrors.Company.Phone_number === true || this.state.CompanyErrors.Person.Phone_number === true)
		// && this.state.PressedSendButton === true
		// console.log(is_field_always_present_in_array(this.state.Nuevo_perfil.Shops, 'Name'))
		// console.log(is_field_always_present_in_array(this.state.Nuevo_perfil.Employees, 'Name'))

		console.log(this.state.HighLevelErrorsMyProfile);
		console.log(this.state.Nuevo_perfil.Employees)

		if (this.state.HighLevelErrorsMyProfile.Employees.Email_address === false &&
			this.state.HighLevelErrorsMyProfile.Employees.Name === false &&
			this.state.HighLevelErrorsMyProfile.Shops.Name === false &&
			this.state.HighLevelErrorsMyProfile.Shops.Phone_number === false &&
			this.state.CompanyErrors.Company.Phone_number === false &&
			this.state.CompanyErrors.Person.Phone_number === false ) {
			console.log('request enviada')
			this.requestCompanyForm();
		}
		return null;
	}


	requestCompanyForm = () => {
		// console.log(this.state.Nuevo_perfil);

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		let Profile = () => {
			return create_json_my_profile(this.state.Profile.Company, this.state.Profile.Person, this.state.Profile.Employees, this.state.Profile.Shops,
				this.state.Profile.Settings, this.state.Nuevo_perfil.Company, this.state.Nuevo_perfil.Person, this.state.Nuevo_perfil.Employees,
				this.state.Nuevo_perfil.Shops, this.state.Nuevo_perfil.Settings, false, null).then(json => {return json});
		}

		let profile1 = Profile();

		profile1.then((response) => {

			if (response.contenido.Update_my_profile_output.Status === "OK") {

				// Reset the update_number
				console.log(response.contenido.Update_my_profile_output);

				localStorage.setItem('PUN', response.contenido.Update_my_profile_output.Update_number);

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				this.updatePageAfterRequestIsSuccessful();

			} else {

				console.log('Status NOK');

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				if (response.contenido.Update_my_profile_output.Error_code === 'Update_my_profile_1') {
					this.setState({ErrorMessage: 'ProfileNotUpdated'});
				}
			}
		})

		return null;

		// this.changeSecondaryStatusToNormal();
	}


	sendManageMyPSI = (Action) => {

		// // Render the loading icon
		// this.setState({WaitingForRequest: true});

		let My_Company_Relations_request = () => {
			return Company_Relations_request(this.state.PSI_Validation_code, Action).then(json => {return json});
		}

		let my_Company_Relations_request = My_Company_Relations_request();

		// my_Company_Relations_request.then((response) => {
		//
		// 	if (response.contenido.Update_my_profile_output.Status === "OK") {
		//
		// 		// Reset the update_number
		// 		console.log(response.contenido.Update_my_profile_output);
		//
		// 		localStorage.setItem('PUN', response.contenido.Update_my_profile_output.Update_number);
		//
		// 		//Remove the loading icon
		// 		this.setState({WaitingForRequest: false});
		//
		// 		this.updatePageAfterRequestIsSuccessful();
		//
		// 	} else {
		//
		// 		console.log('Status NOK');
		//
		// 		//Remove the loading icon
		// 		this.setState({WaitingForRequest: false});
		//
		// 		if (response.contenido.Update_my_profile_output.Error_code === 'Update_my_profile_1') {
		// 			this.setState({ErrorMessage: 'ProfileNotUpdated'});
		// 		}
		// 	}
		// })

		return null;

		// this.changeSecondaryStatusToNormal();
	}


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//Here begins the Employees' sub-tab

	renderMyEmployees = () => {
		// Diseñado para que el scrollbar no robe espacio a la tabla. No se puede hacer dinámicamente sin utilizar
		// JQuery.
		if (this.state.Profile.Employees.length < 11) {

			return [
				<div className='editMyEmployeesButtonContainer'>
					<Button className="editMyEmployeesButton" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEdit()}>
						{'Edit data'}
					</Button>
				</div>,
				<div className='renderMyEmployees'>
					<span className='renderMyCompanyName'>Employees' details</span>
					<div className='containerEmployeesAndShopsTable'>
						<Table responsive='sm' className='myEmployeesTable'>
							<thead className='myEmployeesTableHead'>
							{this.renderMyEmployeesTableHeader()}
							</thead>
							<tbody className='myEmployeesTableBodyWithoutScrollBar'>
							{this.renderMyEmployeesTableContent()}
							</tbody>
						</Table>
					</div>
				</div>
			]
		} else  {

			return [
				<div className='editMyEmployeesButtonContainer'>
					<Button className="editMyEmployeesButton" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEdit()}>
						{'Edit data'}
					</Button>
				</div>,
				<div className='renderMyEmployees'>
					<span className='renderMyCompanyName'>Employees' details</span>
					<div className='containerEmployeesAndShopsTable'>
						<Table responsive='sm'  className='myEmployeesTable'>
							<thead className='myEmployeesTableHead'>
							{this.renderMyEmployeesTableHeader()}
							</thead>
							<tbody className='myEmployeesTableBodyWithScrollBar'>
							{this.renderMyEmployeesTableContent()}
							</tbody>
						</Table>
					</div>
				</div>
			]
		}
	}



	editMyEmployees = () => {

		if (this.state.Profile.Employees.length < 11) {

			return [
				<div className='whiteSpaceInBackgroundTable2'>
					{this.buttonsEditMyEmployees()}
				</div>,
				this.ErrorMessageEmployeeName(),
				this.ErrorMessageEmployeeEmailAddres(),
				<div className='fillingBackgroundContainer2'>
					<span className='renderMyCompanyName'>Edit your employees' details</span>
					<div className='containerEmployeesAndShopsTable'>
						<table className='myEmployeesTableInput'>
							<thead className='myEmployeesTableHeadInput'>
							<tr>{this.renderEmployeesTableHeader()}</tr>
							</thead>
							<tbody className='myEmployeesTableBodyWithoutScrollBarInput'>
							{this.decisionMakingHowManyRowsForEmployees()}
							</tbody>
						</table>
					</div>
					{this.disclaimerVeracityInformationEmployees()}
				</div>
			]
		} else {
			return [
				<div className='whiteSpaceInBackgroundTable2'>
					{this.buttonsEditMyEmployees()}
				</div>,
				this.ErrorMessageEmployeeName(),
				this.ErrorMessageEmployeeEmailAddres(),
				<div className='fillingBackgroundContainer2'>
					<span className='renderMyCompanyName'>Edit your employees' details</span>
					<div className='containerEmployeesAndShopsTable'>
						<table className='myEmployeesTableInput'>
							<thead className='myEmployeesTableHeadInput'>
							<tr>{this.renderEmployeesTableHeader()}</tr>
							</thead>
							<tbody className='myEmployeesTableBodyWithScrollBarInput'>
							{this.decisionMakingHowManyRowsForEmployees()}
							</tbody>
						</table>
					</div>
					{this.disclaimerVeracityInformationEmployees()}
				</div>
			]
		}
	}

	buttonsEditMyEmployees = () => {
		return (
			<div className="buttonsEditMyShops">
				{this.MessageOnSaveButton()}
				<Button className="buttonsEditMyShopsLeft" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="buttonsEditMyShopsRight" block size="lg" onClick={(e) => this.submitEditCompanyFormEmployeeAndShop()}>
					{'Save'}
				</Button>
			</div>
		)
	}


	MessageOnSaveButton = () => {

		if (this.state.PressedSendButton === true && (
			this.state.HighLevelErrorsMyProfile.Employees.Email_address === true || this.state.HighLevelErrorsMyProfile.Employees.Name === true
		)) {
			return ( <div className='MessageOnSaveButton'> Please, check the errors above. </div> )

		}

		if (this.state.PressedSendButton === true && (
			this.state.HighLevelErrorsMyProfile.Shops.Phone_number === true || this.state.HighLevelErrorsMyProfile.Shops.Name === true
		)) {
			return ( <div className='MessageOnSaveButton'> Please, check the errors above. </div> )

		}return null;
	}


	decisionMakingHowManyRowsForEmployees = () => {
		//console.log(this.state);

		const NoOfRows_Sale = +this.state.Nuevo_perfil.Employees.length;
		var array = [];
		// console.log(this.state.CompanyErrors.Employees);
		// console.log(this.state.Nuevo_perfil.Employees);
		for (let step = 0; step < NoOfRows_Sale; step++) {
			//console.log(step);
			array.push(this.renderEmployeesTableData(step));
		}
		//console.log(array);
		return array;
	}


	renderDropdownTextInInputDataTable = (parameter, parent, need) => {
		console.log(parameter);
		console.log(parent);
		console.log(need);
		console.log(this.state.Nuevo_perfil.Shops);
		console.log(this.state.Nuevo_perfil.Employees);
		if (parameter === 'Employee') {
			if (parent !== undefined) {
				return parent.Treatment;
			} else {
				return '';
			}
		}
		if (parameter === 'Shop') {
			if (parent !== undefined) {
				if (need === 'Country') {
					return parent.Country;
				}
				if (need === 'Region') {
					return parent.Region;
				}
				if (need === 'Province') {
					return parent.Province;
				}
			} else {
				return '';
			}
		}
		return null;
	}


	renderEmployeesTableData = (row_index) => {

		const Employee_index = +row_index + 1;

		return this.state.Free_employee.map((header, index) => {
				// console.log(this.state.Nuevo_perfil);
				//const {ID, Email_address, Name, Surname, Role, Treatment} = header //destructuring
				//console.log(Index);

				let inputsArrow = 'dropDownInMyProfileEmployeesTreatment';
				if (this.state.Nuevo_perfil.Employees[row_index].Treatment === null) {
					inputsArrow = 'dropDownInMyProfileEmployeesTreatmentEmpty';
				}

				return (
					<tr key={Employee_index} data-rowid={row_index} onClick={this.clickOnTableRow}
						onChange={(e) => this.changeOnTableRow(e)}>
						{/*<this.lol data={row_index}/>*/}
						<td>
							{row_index + 1}
						</td>
						<td>
							<div className='inputEmployeeTable1' >
								<Button className='dropdownInSettingsEmployees' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Treatment', row_index)}
								> <div className='renderMyCompanyRightInputDropdownText' title={this.fillInShopsHoverOverInformation(row_index, 'Treatment')} >
									<div className='dropdownEditSettings'>
										{this.renderDropdownTextInInputDataTable('Employee', this.state.Nuevo_perfil.Employees[row_index], null)}
									</div>
								</div>
									<img className={inputsArrow} alt=""/>
								</Button>
								<div className='dropdownEditSettingsSticky2Container'> <div className='dropdownEditSettingsSticky2'>{this.DropDownAttribute('Treatment', row_index, 'true', 'Company', 'Employee')}</div></div>
							</div>

						</td>
						<td>
							{this.inputEmployeeTablefunction('Name', row_index)}
						</td>
						<td>
							{this.inputEmployeeTablefunction('Surname', row_index)}
						</td>
						<td>
							{this.inputEmployeeTablefunction('Role', row_index)}
						</td>
						<td>
							{this.inputEmployeeTablefunction('Email_address', row_index)}
						</td>
					</tr>
				)
			}
		)
	}

	decidePlaceHolderEmployee = (indice, prop) => {
		// console.log(indice);
		// console.log(prop);
		const no_employees = this.state.Nuevo_perfil.Employees.length;
		const employees = this.state.Nuevo_perfil.Employees;
		if (no_employees > indice) {
			if (prop === 'Treatment') {
				return employees[indice].Treatment;
			}
			if (prop === 'Name') {
				return employees[indice].Name;
			}
			if (prop === 'Surname') {
				return employees[indice].Surname;
			}
			if (prop === 'Role') {
				return employees[indice].Role;
			}
			if (prop === 'Email_address') {
				return employees[indice].Email_address;
			}
		} else {
			return null;
		}
	}


	inputEmployeeTablefunction = (field, indice) => {

		//console.log(indice);
		//console.log(this.state.Profile.Employees);

		if (field === 'Treatment') {
			return (
				<input
					type="text"
					className='inputEmployeeTable1'
					value={this.decidePlaceHolderEmployee(indice, 'Treatment')}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}
		let div2 = 'inputEmployeeTable2';
		let employees_table = this.state.Nuevo_perfil.Employees
		// console.log(employees_table[indice].Name)
		// console.log(validate_input_cell_is_not_empty_or_null(employees_table[indice].Name, [indice], employees_table.length) && this.state.PressedSendButton)
		if ((validate_input_cell_is_not_empty_or_null(employees_table[indice].Name, indice, employees_table.length) && !this.checkIsRowTotallyEmpty(indice, 'Employees') )
			&& this.state.PressedSendButton === true) {
			div2 = 'inputEmployeeTable22';
		}

		if (field === 'Name') {
			return (
				<input
					type="text"
					className={div2}
					value={this.decidePlaceHolderEmployee(indice, 'Name')}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}

		if (field === 'Surname') {
			return (
				<input
					type="text"
					className='inputEmployeeTable3'
					value={this.decidePlaceHolderEmployee(indice, 'Surname')}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}

		if (field === 'Role') {
			return (
				<input
					type="text"
					className='inputEmployeeTable4'
					value={this.decidePlaceHolderEmployee(indice, 'Role')}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}

		let div = 'inputEmployeeTable5';

		// console.log(this.state.CompanyErrors);
		// console.log(this.state.PressedSendButton);

		if ((this.state.CompanyErrors.Employees[indice].Email_address === true  && !this.checkIsRowTotallyEmpty(indice, 'Employees') )
			&& this.state.PressedSendButton === true) {
			div = 'inputEmployeeTable52';
		}

		if (field === 'Email_address') {
			return (
				<input
					type="text"
					className={div}
					value={this.decidePlaceHolderEmployee(indice, 'Email_address')}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}

	}


	handleChooseChangeItem = (e, field, indice) => {
		//console.log(indice);

		// Modify the state with the new input.
		if (field === 'Treatment') {

			this.addOneMoreRowToNewEmployees(indice);

			this.setState(
				Object.assign(this.state.Nuevo_perfil.Employees[indice], {Treatment: return_null_if_empty(e)}), () => this.inputValidationOfEmployees(null, indice, null)
			);
		}

		if (field === 'Name') {

			this.addOneMoreRowToNewEmployees(indice);

			this.setState(
				Object.assign(this.state.Nuevo_perfil.Employees[indice], {Name: return_null_if_empty(e)}), () => this.inputValidationOfEmployees('Name', indice, e)
			);
		}

		if (field === 'Surname') {

			this.addOneMoreRowToNewEmployees(indice);

			this.setState(
				Object.assign(this.state.Nuevo_perfil.Employees[indice], {Surname: return_null_if_empty(e)}), () => this.inputValidationOfEmployees(null, indice, null)
			);
		}

		if (field === 'Role') {

			this.addOneMoreRowToNewEmployees(indice);

			this.setState(
				Object.assign(this.state.Nuevo_perfil.Employees[indice], {Role: return_null_if_empty(e)}), () => this.inputValidationOfEmployees(null, indice, null)
			);
		}

		if (field === 'Email_address') {

			this.addOneMoreRowToNewEmployees(indice);

			this.setState(
				Object.assign(this.state.Nuevo_perfil.Employees[indice], {Email_address: return_null_if_empty(format_email_address(e) )}), () => this.inputValidationOfEmployees('Email_address', indice, e)
			);
		}
		//console.log(this.state.Nuevo_perfil.Employees);

		return null;
	}


	inputValidationOfEmployees = (field, index, value) => {
		// console.log(value);
		if (field === 'Name') {
			if (validate_string_is_not_empty_or_null(value) || this.checkIsRowTotallyEmpty(index, 'Employees')) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Employees[index], {Name: false}), () => this.validateSaleRequestInput()
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Employees[index], {Name: true}), () => this.validateSaleRequestInput()
				);
			}
		}

		if (field === 'Email_address') {
			this.inputValidationOfEmployees(null, index, null);
			if (email_validation_or_empty(value) || this.checkIsRowTotallyEmpty(index, 'Employees')) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Employees[index], {Email_address: false}), () => this.validateSaleRequestInput()
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Employees[index], {Email_address: true}), () => this.validateSaleRequestInput()
				);
			}
		}
		if (field === null) {
			// console.log(this.state.Nuevo_perfil.Employees);
			// this.checkIsRowTotallyEmpty(index, 'Employee');
			console.log(index)
			if (validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Employees[index].Name) || this.checkIsRowTotallyEmpty(index, 'Employees')) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Employees[index], {Name: false}), () => this.validateSaleRequestInput()
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Employees[index], {Name: true}), () => this.validateSaleRequestInput()
				);
			}
		}

		return null;
	}

	checkIsRowTotallyEmpty = (index, group) => {

		if (group === 'Employees') {
			let checkTreatment = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Employees[index].Treatment);
			let checkName = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Employees[index].Name);
			let checkSurname = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Employees[index].Surname);
			let checkRole = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Employees[index].Role);
			let checkEmail_address = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Employees[index].Email_address);

			// console.log(checkTreatment)
			// console.log(checkName)
			// console.log(checkSurname)
			// console.log(checkRole)
			// console.log(checkEmail_address)

			return checkTreatment && checkName && checkSurname && checkRole && checkEmail_address;
		}

		if (group === 'Shops') {
			let checkName = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Shops[index].Name);
			let checkSurname = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Shops[index].Address);
			let checkRole = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Shops[index].Country);
			let checkRegion = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Shops[index].Region);
			let checkProvince = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Shops[index].Province);
			let checkPhone_number = !validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Shops[index].Phone_number);


			// console.log(checkName)
			// console.log(checkSurname)
			// console.log(checkRole)
			// console.log(checkRegion)
			// console.log(checkProvince)
			// console.log(checkPhone_number)

			return checkName && checkSurname && checkRole && checkRegion && checkProvince && checkPhone_number;
		}

		return null;
	}


	validateSaleRequestInput = () => {

		// Evaluates if there exists any error on the data provided by the user and changes the state to show the error
		// messages.

		let isThereAnyErrorInScreen = false;
		let errorsArray = this.state.CompanyErrors;
		// console.log(errorsArray);


		let employeesTreatments = errorsArray.Employees.map(value => value.Treatment);
		let employeesNames = errorsArray.Employees.map(value => value.Name);
		let employeesEmails = errorsArray.Employees.map(value => value.Email_address);
		let shopsPhoneNames = errorsArray.Shops.map(value => value.Name);
		let shopsPhoneNumbers = errorsArray.Shops.map(value => value.Phone_number);

		let countTrueInEmployeesTreatments = employeesTreatments.filter(x => x === true).length;
		let countTrueInEmployeesNames = employeesNames.filter(x => x === true).length;
		let countTrueInEmployeesEmails = employeesEmails.filter(x => x === true).length;
		let countTrueInShopsPhoneNames = shopsPhoneNames.filter(x => x === true).length;
		let countTrueInShopsPhoneNumbers = shopsPhoneNumbers.filter(x => x === true).length;
		console.log(employeesNames)
		console.log(countTrueInEmployeesNames)


		if (countTrueInEmployeesTreatments > 0) {
			isThereAnyErrorInScreen = true;
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Employees, {Name: true }), () => this.checksAfterInputValidationInMyAccount()
			);
		}
		if (!countTrueInEmployeesTreatments > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Employees, {Name: false }), () => this.checksAfterInputValidationInMyAccount()
			);
		}
		if (countTrueInEmployeesNames > 0) {
			isThereAnyErrorInScreen = true;
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Employees, {Name: true }), () => this.checksAfterInputValidationInMyAccount()
			);
		}
		if (!countTrueInEmployeesNames > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Employees, {Name: false }), () => this.checksAfterInputValidationInMyAccount()
			);
		}

		if (countTrueInEmployeesEmails > 0) {
			isThereAnyErrorInScreen = true;
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Employees, {Email_address: true }), () => this.checksAfterInputValidationInMyAccount()
			);
		}
		if (!countTrueInEmployeesEmails > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Employees, {Email_address: false }), () => this.checksAfterInputValidationInMyAccount()
			);
		}

		if (countTrueInShopsPhoneNames > 0) {
			isThereAnyErrorInScreen = true;
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Shops, {Name: true }), () => this.checksAfterInputValidationInMyAccount()
			);
		}
		if (!countTrueInShopsPhoneNames > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Shops, {Name: false }), () => this.checksAfterInputValidationInMyAccount()
			);
		}

		if (countTrueInShopsPhoneNumbers > 0) {
			isThereAnyErrorInScreen = true;
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Shops, {Phone_number: true }), () => this.checksAfterInputValidationInMyAccount()
			);
		}
		if (!countTrueInShopsPhoneNumbers > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile.Shops, {Phone_number: false }), () => this.checksAfterInputValidationInMyAccount()
			);
		}

		return isThereAnyErrorInScreen;

	}


	checksAfterInputValidationInMyAccount = () => {

		// this.checkErrorMessageOnButton();
		this.ErrorMessagesInMyAccount();
		return null;
	}


	checkIsEmployeeNameAlwaysProvided = () => {

		// console.log(this.state.Nuevo_perfil.Employees);

		return is_field_always_present_in_array(this.state.Nuevo_perfil.Employees, 'Name') &&
			this.state.PrimaryStatus === this.state.PrimaryCode.Employee

	}

	checkIsShopNameAlwaysProvided = () => {

		// console.log(this.state.Nuevo_perfil.Shops);

		return is_field_always_present_in_array(this.state.Nuevo_perfil.Shops, 'Name') &&
			this.state.PrimaryStatus === this.state.PrimaryCode.Shop

	}

	ErrorMessageEmployeeName = () => {
		console.log(this.state.HighLevelErrorsMyProfile.Employees)

		if (this.state.HighLevelErrorsMyProfile.Employees.Name === true && this.state.PressedSendButton === true) {
			return (
				<div className='ErrorMessageEmployeeName'> Employee's name required to assign operations. </div>
			)
		} else { return null;}

	}

	ErrorMessageEmployeeEmailAddres = () => {

		if (this.state.HighLevelErrorsMyProfile.Employees.Email_address === true && this.state.PressedSendButton === true) {
			return (
				<div className='ErrorMessagesInMyAccount'> Wrong e-mail address input. </div>
			)
		} else { return null;}
	}

	ErrorMessageShopName = () => {

		if (this.state.HighLevelErrorsMyProfile.Shops.Name === true && this.state.PressedSendButton === true)
		{
			return (
				<div className='ErrorMessageShopName'> Shop's name required to assign operations. </div>
			)
		} else { return null;}
	}

	ErrorMessageShopPhoneNumber = () => {
		if (this.state.HighLevelErrorsMyProfile.Shops.Phone_number === true && this.state.PressedSendButton === true) {
			return (
				<div className='ErrorMessagesInMyAccountShops'> Wrong phone number input. </div>
			)
		} else { return null;}
	}

	ErrorMessagesInMyAccount = () => {
		// Displays the correct error message in the Sale Window

		// console.log(this.state.HighLevelErrorsMyProfile.Employees);
		console.log(this.state.errorMessages);
		console.log(this.state.PressedSendButton);
		console.log(this.checkIsEmployeeNameAlwaysProvided())
		console.log(this.state.PressedSendButton === true && !this.checkIsEmployeeNameAlwaysProvided())

		if (this.state.PressedSendButton === true && !this.checkIsEmployeeNameAlwaysProvided()) {
			// let highLevelErrorsArray = this.state.HighLevelErrors;


			let errorMessages = 'Employee\'s name required to assign operations.';
			this.setState(
				Object.assign(this.state.errorMessages, {EmployeeName: true})
			);
			console.log('hola')
			// errorMessages: {EmployeeName: false, EmployeeEmailAddres: false, ShopName: false, ShopPhoneNumber: false}

			// return (
			// 	<div className='ErrorMessagesInMyAccount'> {errorMessages.split('\n').map(str =>
			// 		<p className='ErrorMessagesInMyAccountText'>{str}</p>)} </div>
			// )
		}

		if (this.state.PressedSendButton === true && this.state.HighLevelErrorsMyProfile.Employees.Email_address === true) {
			// let highLevelErrorsArray = this.state.HighLevelErrors;
			console.log('holi');


			let errorMessages = 'Wrong e-mail address input.';
			this.setState(
				Object.assign(this.state.errorMessages, {EmployeeEmailAddres: true})
			);

			// return (
			// 	<div className='ErrorMessagesInMyAccount'> {errorMessages.split('\n').map(str =>
			// 		<p className='ErrorMessagesInMyAccountText'>{str}</p>)} </div>
			// )
		}

		if (this.state.PressedSendButton === true && !this.checkIsShopNameAlwaysProvided()) {
			// let highLevelErrorsArray = this.state.HighLevelErrors;
			console.log('holi');

			let errorMessages = 'Shop\'s name required to assign operations.';

			this.setState(
				Object.assign(this.state.errorMessages, {ShopName: true})
			);

			// return (
			// 	<div className='ErrorMessagesInMyAccount'> {errorMessages.split('\n').map(str =>
			// 		<p className='ErrorMessagesInMyAccountText'>{str}</p>)} </div>
			// )
		}

		if (this.state.PressedSendButton === true && this.state.HighLevelErrorsMyProfile.Shops.Phone_number === true) {
			// let highLevelErrorsArray = this.state.HighLevelErrors;
			console.log('holi');

			let errorMessages = 'Wrong phone number input.';

			this.setState(
				Object.assign(this.state.errorMessages, {ShopPhoneNumber: true})
			);

			// return (
			// 	<div className='ErrorMessagesInMyAccountShops'> {errorMessages.split('\n').map(str =>
			// 		<p className='ErrorMessagesInMyAccountShopsText'>{str}</p>)} </div>
			// )

		}

		return null;
	}



	clickOnTableRow = (event) => {
		//console.log(event.currentTarget.getAttribute("data-rowid"));
		const id = event.currentTarget.getAttribute("data-rowid");
		this.setState({RoIndex: id});
		return id;
	}

	changeOnTableRow = (event) => {

		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}


	renderEmployeesTableHeader = () => {
		const first_line = this.state.EmployeesTableHeaders;

		if (first_line === undefined) {
			return null;
		} else {
			let header = Object.keys(first_line)
			return header.map((key, index__) => {
				return <th key={index__}>{key}</th>
			})
		}
	}


	renderMyEmployeesTableHeader = () => {

		const first_line = this.state.EmployeesTableHeaders;

		//console.log(first_line);


		if (first_line === undefined) {
			return null;
		} else {

			let header = Object.keys(first_line)
			//console.log(header);
			return header.map((key, index_2) => {
				return <th key={index_2}>{key}</th>
			})
		}
	}

	renderMyEmployeesTableContent = () => {
		//console.log(this.state);

		const Employees_data = this.state.Profile.Employees;
		// console.log(Employees_data)

		return Employees_data.map((header, index) => {

				const {ID, Treatment, Name, Surname, Role, Email_address} = header
				return (
					<tr key={ID}>
						<td> {index + 1}</td>
						<td title ={Treatment}>{Treatment}</td>
						<td title ={Name}> {Name}</td>
						<td title ={Surname}>{Surname}</td>
						<td title ={Role}> {Role}</td>
						<td title ={Email_address}> {Email_address}</td>
					</tr>
				)
			}
		)
	}


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//Here begins the Shops' sub-tab

	renderMyShops = () => {
		// Diseñado para que el scrollbar no robe espacio a la tabla. No se puede hacer dinámicamente sin utilizar
		// JQuery.
		if (this.state.Profile.Employees.length < 10) {

			return [
				<div className='editMyEmployeesButtonContainer'>
					<Button className="editMyEmployeesButton" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEdit()}>
						{'Edit data'}
					</Button>
				</div>,
				<div className='renderMyEmployees'>
					<span className='renderMyCompanyName'>Shops' details</span>

					<div className='containerEmployeesAndShopsTable'>
						<table className='myShopsTable'>
							<thead className='myShopsTableHead'>
							{this.renderMyShopsTableHeader()}
							</thead>
							<tbody className='myShopsTableBodyWithoutScrollBar'>
							{this.renderMyShopsTableContent()}
							</tbody>
						</table>
					</div>
				</div>
			]
		} else  {

			return [
				<div className='editMyEmployeesButtonContainer'>
					<Button className="editMyEmployeesButton" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEdit()}>
						{'Edit data'}
					</Button>
				</div>,
				<div className='renderMyEmployees'>
					<span className='renderMyCompanyName'>Shops' details</span>

					<div className='containerEmployeesAndShopsTable'>
						<table className='myShopsTable'>
							<thead className='myShopsTableHead'>
							{this.renderMyShopsTableHeader()}
							</thead>
							<tbody className='myShopsTableBodyWithScrollBar'>
							{this.renderMyShopsTableContent()}
							</tbody>
						</table>
					</div>
				</div>
			]
		}
	}


	editMyShops = () => {

		if (this.state.Profile.Employees.length < 11) {

			return [
				<div className='whiteSpaceInBackgroundTable2'>
					{this.buttonsEditMyShops()}
				</div>,
				// this.ErrorMessagesInMyAccount(),
				this.ErrorMessageShopName(),
				this.ErrorMessageShopPhoneNumber(),
				<div className='fillingBackgroundContainer2'>
					<span className='renderMyCompanyName'>Edit your shops' details</span>
					<div className='containerEmployeesAndShopsTable'>
						<table className='myShopsTableInput'>
							<thead className='myShopsTableHeadInput'>
							<tr>{this.renderMyShopsTableHeader()}</tr>
							</thead>
							<tbody className='myShopsTableBodyWithoutScrollBarInput'>
							{this.decisionMakingHowManyRowsForShops()}
							</tbody>
						</table>
					</div>
					{this.disclaimerVeracityInformationShops()}
				</div>
			]
		} else {
			return [
				<div className='whiteSpaceInBackgroundTable2'>
					{this.buttonsEditMyShops()}
				</div>,
				// this.ErrorMessagesInMyAccount(),
				this.ErrorMessageShopName(),
				this.ErrorMessageShopPhoneNumber(),
				<div className='fillingBackgroundContainer2'>
					<span className='renderMyCompanyName'>Edit your shops' details</span>
					<div className='containerEmployeesAndShopsTable'>
						<table className='myShopsTableInput'>
							<thead className='myShopsTableHeadInput'>
							<tr>{this.renderMyShopsTableHeader()}</tr>
							</thead>
							<tbody className='myShopsTableBodyWithScrollBarInput'>
							{this.decisionMakingHowManyRowsForShops()}
							</tbody>
						</table>
					</div>
					{this.disclaimerVeracityInformationShops()}
				</div>
			]
		}
	}






	buttonsEditMyShops = () => {
		return (
			<div className="buttonsEditMyShops">
				{this.MessageOnSaveButton()}
				<Button className="buttonsEditMyShopsLeft" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="buttonsEditMyShopsRight" block size="lg" onClick={(e) => this.submitEditCompanyFormEmployeeAndShop()}>
					{'Save'}
				</Button>
			</div>
		)
	}

	decisionMakingHowManyRowsForShops = () => {

		const NoOfRows_Sale = this.state.Nuevo_perfil.Shops.length;
		var array = [];

		for (let step = 0; step < NoOfRows_Sale; step++) {
			// console.log(step);
			array.push(this.renderShopsTableData(step));
		}
		//console.log(array);
		return array;
	}


	renderShopsTableData = (row_index2) => {

		const Shop_index = +row_index2 + 1;

		// console.log(this.state.Nuevo_perfil.Shops);
		return this.state.Free_shop.map((header, index) => {

				let inputsArrow = 'dropDownInMyProfileEmployeesTreatment';
				if (this.state.Nuevo_perfil.Shops[row_index2].Country === null) {
					inputsArrow = 'dropDownInMyProfileEmployeesTreatmentEmpty';
				}

				// console.log(this.state.Profile.Shops[row_index2]);
				// console.log(this.state.Nuevo_perfil.Shops[row_index2]);

				return (
					<tr key={Shop_index} data-rowid={row_index2} onClick={(e) => this.clickOnEditTableRow(e)}
						onChange={(e) => this.changeOnEditTableRow(e)}>
						<td>
							{row_index2 + 1}
						</td>
						<td>
							{this.inputShopTablefunction('Name', row_index2)}
						</td>
						<td>
							{this.inputShopTablefunction('Address', row_index2)}
						</td>
						<td>
							<div className='inputShopTable3' >
								<Button className='dropdownInSettingsShops' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Country', row_index2)}
								> <div className='renderMyCompanyRightInputDropdownText' title={this.fillInShopsHoverOverInformation(row_index2, 'Country')} >
									<div className='dropdownEditSettings'>
										{this.renderDropdownTextInInputDataTable('Shop', this.state.Nuevo_perfil.Shops[row_index2], 'Country')}
									</div>
								</div>
									<img className={inputsArrow} alt=""/>
								</Button>
								<div className='dropdownEditSettingsSticky2Container'> <div className='dropdownEditSettingsSticky2'>{this.DropDownAttribute('Country', row_index2, 'true', 'Company', 'Shops')}</div></div>
							</div>
						</td>
						<td>
							<div className='inputShopTable4' >
								<Button className='dropdownInSettingsShops' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Region', row_index2)}
								> <div className='renderMyCompanyRightInputDropdownText'  title={this.fillInShopsHoverOverInformation(row_index2, 'Region')}>
									<div className='dropdownEditSettings'> {this.renderDropdownTextInInputDataTable('Shop', this.state.Nuevo_perfil.Shops[row_index2], 'Region')}
									</div>
								</div>
									<img className={inputsArrow} alt=""/>
								</Button>
								{this.DropDownAttribute('Region', row_index2, 'true', 'Company', 'Shops')}
							</div>
						</td>
						<td>
							<div className='inputShopTable5' >
								<Button className='dropdownInSettingsShops' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Province', row_index2)}
								> <div className='renderMyCompanyRightInputDropdownText'  title={this.fillInShopsHoverOverInformation(row_index2, 'Province')}>
									<div className='dropdownEditSettings'> {this.renderDropdownTextInInputDataTable('Shop', this.state.Nuevo_perfil.Shops[row_index2], 'Province')}
									</div>
								</div>
									<img className={inputsArrow} alt=""/>
								</Button>
								{this.DropDownAttribute('Province', row_index2, 'true', 'Company', 'Shops')}
							</div>
						</td>
						<td>
							{this.inputShopTablefunction('Phone_number', row_index2)}
						</td>
					</tr>
				)
			}
		)
	}


	fillInEmployeesHoverOverInformation = (row_index, field_name) => {

		// Shows the information to be rendered on a hover-over method when hovering over the buttons on Edit Employees table
		// console.log(this.state.Profile.Employees[row_index]);
		if (this.state.Nuevo_perfil.Employees[row_index] !== undefined &&
			this.state.Nuevo_perfil.Employees[row_index] !== null) {
			// console.log(this.state.Nuevo_perfil.Employees[row_index][field_name.valueOf()])
			return this.state.Nuevo_perfil.Employees[row_index][field_name.valueOf()];
		} else {return null;}
	}

	fillInShopsHoverOverInformation = (row_index, field_name) => {

		// Shows the information to be rendered on a hover-over method when hovering over the buttons on Edit Shops table
		// console.log(this.state.Profile.Shops[row_index]);
		if (this.state.Nuevo_perfil.Shops[row_index] !== undefined &&
			this.state.Nuevo_perfil.Shops[row_index] !== null) {
			// console.log(this.state.Nuevo_perfil.Shops[row_index][field_name.valueOf()])
			return this.state.Nuevo_perfil.Shops[row_index][field_name.valueOf()];
		} else {return null;}
	}


	decidePlaceHolderShop = (indice, prop) => {
		// console.log(indice);
		// console.log(prop);
		const no_shops = this.state.Nuevo_perfil.Shops.length;
		const shops = this.state.Nuevo_perfil.Shops;
		if (no_shops > indice) {
			if (prop === 'Name') {
				return shops[indice].Name;
			}
			if (prop === 'Address') {
				return shops[indice].Address;
			}
			if (prop === 'Country') {
				return shops[indice].Country;
			}
			if (prop === 'Province') {
				return shops[indice].Province;
			}
			if (prop === 'Region') {
				return shops[indice].Region;
			}
			if (prop === 'Phone_number') {
				return shops[indice].Phone_number;
			}
		} else {
			return null;
		}
	}


	inputShopTablefunction = (field, indice) => {
		//console.log(indice);
		// console.log(this.state.Nuevo_perfil);

		let div2 = 'inputShopTable1';
		let shops_table = this.state.Nuevo_perfil.Shops
		// console.log(shops_table[indice].Name)
		// console.log(validate_input_cell_is_not_empty_or_null(shops_table[indice].Name, indice, shops_table.length))
		if ((validate_input_cell_is_not_empty_or_null(shops_table[indice].Name, indice, shops_table.length) && !this.checkIsRowTotallyEmpty(indice, 'Shops') )
			&& this.state.PressedSendButton === true) {
			div2 = 'inputShopTable11';
		}
		if (field === 'Name') {
			return (
				<input
					type="text"
					className={div2}
					value={this.decidePlaceHolderShop(indice, 'Name')}
					onChange={(e) => this.handleEditShopItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Address') {
			return (
				<input
					type="text"
					className='inputShopTable2'
					value={this.decidePlaceHolderShop(indice, 'Address')}
					onChange={(e) => this.handleEditShopItem(e.target.value, field, indice)}
				/>
			)
		}

		if (field === 'Country') {
			return (
				<input
					type="text"
					className='inputShopTable3'
					value={this.decidePlaceHolderShop(indice, 'Country')}
					onChange={(e) => this.handleEditShopItem(e.target.value, field, indice)}
				/>
			)
		}

		if (field === 'Province') {
			return (
				<input
					type="text"
					className='inputShopTable4'
					value={this.decidePlaceHolderShop(indice, 'Province')}
					onChange={(e) => this.handleEditShopItem(e.target.value, field, indice)}
				/>
			)
		}

		if (field === 'Region') {
			return (
				<input
					type="text"
					className='inputShopTable5'
					value={this.decidePlaceHolderShop(indice, 'Region')}
					onChange={(e) => this.handleEditShopItem(e.target.value, field, indice)}
				/>
			)
		}


		let div = 'inputShopTable6';
		// console.log(this.state.CompanyErrors.Shops);
		// console.log(indice);
		// console.log(field);
		// console.log(this.state.CompanyErrors.Shops[indice]);
		// console.log(this.state.CompanyErrors);
		if (this.state.PressedSendButton === true && this.state.CompanyErrors.Shops[indice].Phone_number === true) {
			div = 'inputShopTable62';
		}

		if (field === 'Phone_number') {
			return (
				<input
					type="text"
					className = {div}
					value={this.decidePlaceHolderShop(indice, 'Phone_number')}
					onChange={(e) => this.handleEditShopItem(e.target.value, field, indice)}
				/>
			)
		}

	}


	addOneMoreRowToNewEmployees = (indice) => {
		// Add a new line to the table and to the errors array in case the user has completed it (completed before
		// appending the new line):
		// Add a new line to the table in case the user has completed it (completed before appending the new line):
		if (+indice + 1 === +this.state.Nuevo_perfil.Employees.length) {
			//console.log('true');

			const new_employee = {
				ID: 0, Name: null, Surname: null, Role: null, Email_address: null, Treatment: null
			};
			this.setState(
				Object.assign(this.state.Nuevo_perfil, {Employees: this.state.Nuevo_perfil.Employees.concat(new_employee)})
			);


			const new_errors_in_Employees ={Treatment: false, Name: false, Surname: false, Role: false, Email_address: false}

			this.setState(
				Object.assign(this.state.CompanyErrors, {Employees: [...this.state.CompanyErrors.Employees, new_errors_in_Employees] })
			);

		}
		return null;
	}

	addOneMoreRowToNewShops = (indice) => {
		// Add a new line to the table and to the errors array in case the user has completed it (completed before
		// appending the new line):
		if (+indice + 1 === +this.state.Nuevo_perfil.Shops.length) {
			console.log('true');

			const new_shop = {ID: +indice + 1,
				Name: null, Address: null, Country: null, Region: null, Province: null, Phone_number: null};

			this.setState(
				Object.assign(this.state.Nuevo_perfil, {Shops: this.state.Nuevo_perfil.Shops.concat(new_shop)})
			);


			const new_errors_in_shops ={Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false}

			this.setState(
				Object.assign(this.state.CompanyErrors, {Shops: [...this.state.CompanyErrors.Shops, new_errors_in_shops] })
			);


		}
		return null;
	}


	handleEditShopItem = (e, field, indice) => {
		//console.log(indice);
		// Modify the state with the new input.
		if (field === 'Address') {

			this.addOneMoreRowToNewShops(indice);
			//console.log(this.state);
			// console.log(this.state.Nuevo_perfil.Shops);
			this.setState(
				Object.assign(this.state.Nuevo_perfil.Shops[indice], {Address: return_null_if_empty(e)}), () => this.inputValidationOfShops(null, indice, null)
			);
		}

		if (field === 'Country') {
			this.addOneMoreRowToNewShops(indice);
			this.setState(
				Object.assign(this.state.Nuevo_perfil.Shops[indice], {Country: return_null_if_empty(e)}), () => this.inputValidationOfShops(null, indice, null)
			);
		}

		if (field === 'Name') {
			this.addOneMoreRowToNewShops(indice);
			this.setState(
				Object.assign(this.state.Nuevo_perfil.Shops[indice], {Name: return_null_if_empty(e)}), () => this.inputValidationOfShops('Name', indice, e)
			);
		}

		if (field === 'Province') {
			this.addOneMoreRowToNewShops(indice);
			this.setState(
				Object.assign(this.state.Nuevo_perfil.Shops[indice], {Province: return_null_if_empty(e)}), () => this.inputValidationOfShops(null, indice, null)
			);
		}

		if (field === 'Region') {
			this.addOneMoreRowToNewShops(indice);
			this.setState(
				Object.assign(this.state.Nuevo_perfil.Shops[indice], {Region: return_null_if_empty(e)}), () => this.inputValidationOfShops(null, indice, null)
			);
		}

		if (field === 'Phone_number') {
			this.addOneMoreRowToNewShops(indice);
			// console.log(this.state.Profile);
			// console.log(this.state.Nuevo_perfil);
			this.setState(
				Object.assign(this.state.Nuevo_perfil.Shops[indice], {Phone_number: return_null_if_empty(e)}), () => this.inputValidationOfShops('Phone_number', indice, e)
			);
		}
		//console.log(this.state.Nuevo_perfil.Employees);

		return null;
	}

	inputValidationOfShops = (field, index, value) => {
		// console.log(this.state.CompanyErrors);
		console.log(value);
		if (field === 'Name') {
			if (validate_string_is_not_empty_or_null(value) || this.checkIsRowTotallyEmpty(index, 'Shops')) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Shops[index], {Name: false}), () => this.validateSaleRequestInput()
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Shops[index], {Name: true}), () => this.validateSaleRequestInput()
				);
			}
		}
		if (field === 'Phone_number') {
			this.inputValidationOfShops(null, index, null);
			if (validate_integers_or_null(value) || this.checkIsRowTotallyEmpty(index, 'Shops')) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Shops[index], {Phone_number: false}), () => this.validateSaleRequestInput()
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Shops[index], {Phone_number: true}), () => this.validateSaleRequestInput()
				);
			}
		}

		if (field === null) {
			// console.log(this.state.Nuevo_perfil.Shops);
			// this.checkIsRowTotallyEmpty(index, 'Employee');
			if (validate_string_is_not_empty_or_null(this.state.Nuevo_perfil.Shops[index].Name) || this.checkIsRowTotallyEmpty(index, 'Shops')) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Shops[index], {Name: false}), () => this.validateSaleRequestInput()
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Shops[index], {Name: true}), () => this.validateSaleRequestInput()
				);
			}
		}

		return null;
	}


	clickOnEditTableRow = (event) => {
		//console.log(event.currentTarget.getAttribute("data-rowid"));
		const id = event.currentTarget.getAttribute("data-rowid");
		console.log(id)
		this.setState({RoIndex: id});
		return id;
	}

	changeOnEditTableRow = (event) => {

		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}


	renderEditShopsTableHeader = () => {
		// const first_line = this.state.ShopsTableHeader;
		const first_line = this.state.ShopsTableHeaders;

		if (first_line === undefined) {
			return null;
		} else {
			let header = Object.keys(first_line)
			return header.map((key, index__) => {
				return <th key={index__}>{key.toUpperCase()}</th>
			})
		}
	}



	renderMyShopsTableHeader = () => {

		// const first_line = this.state.ShopsTableHeader;
		const first_line = this.state.ShopsTableHeaders;

		if (first_line === undefined) {
			return null;
		} else {

			let header = Object.keys(first_line)
			//console.log(header);
			return header.map((key, index_2) => {
				return <th key={index_2}>{key}</th>
			})
		}
	}

	renderMyShopsTableContent = () => {

		const Shops_data = this.state.Profile.Shops;

		return Shops_data.map((header, index) => {

				const {Name, Address, Country, Province, Region, Phone_number} = header
				return (
					<tr key={index}>
						<td> {index + 1}</td>
						<td title ={Name}> {Name}</td>
						<td title ={Address}> {Address}</td>
						<td title ={Country}>{Country}</td>
						<td title ={Region}> {Region}</td>
						<td title ={Province}> {Province}</td>
						<td title ={Phone_number}> {Phone_number}</td>
					</tr>
				)
			}
		)
	}


	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//Here begins the Settings' sub-tab


	renderSettings = () => {
		if (isUserACompany()) {
			return (
				<div className='renderSettings'>
					<span className='renderMyCompanyName'>Settings</span>

					<div className='renderMyCompanyData'>
						{this.renderSettingsLeft()}
						{this.renderSettingsRight()}
						{this.renderSettingsButtons()}
					</div>
				</div>
			)
		} else {
			return (
				<div className='renderSettings'>
					<span className='renderMyCompanyName'>Settings</span>

					<div className='renderMyCompanyData'>
						{this.renderSettingsLeft()}
						{this.renderSettingsRight()}
						{this.renderSettingsButtons()}
					</div>
				</div>
			)
		}
	}

	renderSettingsLeft = (row_index) => {
		if (isUserACompany()) {
			return (
				<div className='renderMyCompanyLeft'>
					<div className='renderMyCompanyLeftText'> {'Contact email'} </div>
					<div className='renderMyCompanyLeftText'> {'Password'} </div>
					<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>
					<div className='renderMyCompanyLeftText'> {'Contact phone'} </div>
					<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>
					{/*<div className='renderMyCompanyLeftText'> {'Software-services company'} </div>*/}
					{/*<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>*/}
					<div className='renderMyCompanyLeftText'> {'Currency'} </div>
				</div>
			)

		} else {
			if (isUserAPSI()) {
				return (
					<div className='renderMyCompanyLeft'>
						<div className='renderMyCompanyLeftText'> {'Contact email'} </div>
						<div className='renderMyCompanyLeftText'> {'Password'} </div>
						<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>
						<div className='renderMyCompanyLeftText'> {'Contact phone'} </div>
						<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>
						<div className='renderMyCompanyLeftText'> {'Currency'} </div>
					</div>
				)
			} else {
				return (
					<div className='renderMyCompanyLeft'>
						<div className='renderMyCompanyLeftText'> {'Contact email'} </div>
						<div className='renderMyCompanyLeftText'> {'Password'} </div>
						<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>
						{/*<div className='renderMyCompanyLeftText'> {'Contact phone'} </div>*/}
					</div>
				)
			}
		}
	}

	renderSettingsRight = (row_index) => {
		if (isUserACompany()) {

			const to_map = this.state.Profile.Company;
			const {Email, Phone_number, Phone_prefix, Currency} = to_map //destructuring

			return (
				<div className='renderMyCompanyRight'>
					<div className='renderMyCompanyRightText' title={Email}> {Email} </div>
					<div className='renderMyCompanyRightText'> {'********'} </div>
					<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>
					<div className='renderMyCompanyRightText' title={Phone_prefix + ' ' + Phone_number}> {Phone_prefix} {Phone_number}</div>
					<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>
					{/*<div className='renderMyCompanyRightText'> Mock-up </div>*/}
					{/*<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>*/}
					<div className='renderMyCompanyRightText'> {Currency} </div>
				</div>
			)

		} else {
			if (isUserAPSI()) {
				const to_map = this.state.Profile.Company;
				const {Email, Phone_number, Phone_prefix, Currency} = to_map //destructuring

				return (
					<div className='renderMyCompanyRight'>
						<div className='renderMyCompanyRightText' title={Email}> {Email} </div>
						<div className='renderMyCompanyRightText'> {'********'} </div>
						<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>
						<div className='renderMyCompanyRightText' title={Phone_prefix + ' ' + Phone_number}> {Phone_prefix} {Phone_number}</div>
						<div className='renderMyCompanyLeftTextBlankSpaceSettings'> </div>
						<div className='renderMyCompanyRightText'> {Currency} </div>
					</div>
				)
			} else {
				const to_map = this.state.Profile.Person;
				// console.log(to_map);
				const {Email} = to_map //destructuring

				return (
					<div className='renderMyCompanyRight'>
						<div className='renderMyCompanyRightText' title={Email}> {Email} </div>
						<div className='renderMyCompanyRightText'> {'********'} </div>
						<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>
						{/*<div className='renderMyCompanyRightText'> {Phone_prefix} {Phone_number}</div>*/}
					</div>
				)
			}
		}
	}


	renderSettingsButtons = () => {
		if (isUserACompany()) {

			return (
				<div className='renderMyCompanySettingsButtons'>

					<Button className="renderMyCompanySettingsSingleButton" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEditEmail()}>
						{'Change e-mail'}
					</Button>

					<Button className="renderMyCompanySettingsSingleButton2" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEditPassword()}>
						{'Change password'}
					</Button>

					<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>

					<Button className="renderMyCompanySettingsSingleButton3" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEdit()}>
						{'Change phone'}
					</Button>

					<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>

					{/*<Button className="renderMyCompanySettingsSingleButton3" block size="lg"*/}
					{/*		onClick={(e) => this.changeSecondaryStatusToManagePSI()}>*/}
					{/*	{'Manage'}*/}
					{/*</Button>*/}

					{/*<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>*/}

					<Button className="renderMyCompanySettingsSingleButton4" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToEditCurrency()}>
						{'Change currency'}
					</Button>

					<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>

					<Button className="renderMyCompanySettingsSingleButton5" block size="lg"
							onClick={(e) => this.changeSecondaryStatusToDeleteAccount()}>
						{'Delete account'}
					</Button>
				</div>
			)
		} else {
			if (isUserAPSI()) {
				return (
					<div className='renderMyCompanySettingsButtons'>

						<Button className="renderMyCompanySettingsSingleButton" block size="lg"
								onClick={(e) => this.changeSecondaryStatusToEditEmail()}>
							{'Change e-mail'}
						</Button>

						<Button className="renderMyCompanySettingsSingleButton2" block size="lg"
								onClick={(e) => this.changeSecondaryStatusToEditPassword()}>
							{'Change password'}
						</Button>

						<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>

						<Button className="renderMyCompanySettingsSingleButton3" block size="lg"
								onClick={(e) => this.changeSecondaryStatusToEdit()}>
							{'Change phone'}
						</Button>

						<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>

						<Button className="renderMyCompanySettingsSingleButton4" block size="lg"
								onClick={(e) => this.changeSecondaryStatusToEditCurrency()}>
							{'Change currency'}
						</Button>

						<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>

						<Button className="renderMyCompanySettingsSingleButton5" block size="lg"
								onClick={(e) => this.changeSecondaryStatusToDeleteAccount()}>
							{'Delete account'}
						</Button>
					</div>
				)
			} else {

				return (
					<div className='renderMyCompanySettingsButtons'>

						<Button className="renderMyCompanySettingsSingleButton" block size="lg"
								onClick={(e) => this.changeSecondaryStatusToEditEmail()}>
							{'Change e-mail'}
						</Button>

						<Button className="renderMyCompanySettingsSingleButton2" block size="lg"
								onClick={(e) => this.changeSecondaryStatusToEditPassword()}>
							{'Change password'}
						</Button>

						<div className='renderMyCompanyLeftTextBlankSpaceSettings'></div>

						<Button className="renderMyCompanySettingsSingleButton5" block size="lg"
								onClick={(e) => this.changeSecondaryStatusToDeleteAccount()}>
							{'Delete account'}
						</Button>
					</div>
				)
			}
		}
	}

	changeSecondaryStatusToDelete2Account = () => {

		if (this.state.SecondaryStatus !== this.state.SecondaryCode.Delete2Account) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.Delete2Account});
			return null;
		} else {
			return null;
		}
	}


	changeSecondaryStatusToDeleteAccount = () => {

		if (this.state.SecondaryStatus !== this.state.SecondaryCode.DeleteAccount) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.DeleteAccount});
			return null;
		} else {
			return null;
		}
	}




	editEmailName = () => {
		return [
			<div className='editSettingName'> {'Password'}  </div>,
			<div className='editSettingName'> {'New email'} </div>,
			<div className='editSettingName'> {'Repeat new email'} </div>
		]
	}

	editPasswordsNames = () => {
		return  [
			<div className='editSettingName'> {'Old password'} </div>,
			<div className='editSettingName'> {'New password'} </div>,
			<div className='editSettingName'>{'Repeat new password'} </div>
		]
	}

	editCurrencyNames = () => {
		return (
			<div className='editSettingName'> {'Currency'} </div>
		)
	}

	deleteAccountNames = () => {
		return (
			<div className='editSettingName'> {'Password'} </div>
		)
	}

	editEmailInputFields = () => {


		// console.log(this.state.CompanyErrors.Settings);
		// console.log(this.state.HighLevelErrorsMyProfile);
		// console.log(this.state.PressedSendButton);

		// Give priority to the missing password

		let div3 = 'inputFieldEditSettings';
		let div = 'inputFieldEditSettings';
		let div2 = 'inputFieldEditSettings';

		//Give priority to first input field
		if ( this.state.CompanyErrors.Settings.PasswordError === true && this.state.PressedSendButton === true) {
			div3 = 'inputFieldEditSettings2'
		}

		if (this.state.CompanyErrors.Settings.PasswordError === false) {

			if ( (this.state.CompanyErrors.Settings.NewEmailError === true || this.state.HighLevelErrorsMyProfile.areNewEmailsTheSame === false)
				&& this.state.PressedSendButton === true) {
				div = 'inputFieldEditSettings2'
			}

			if ( (this.state.CompanyErrors.Settings.RepeatNewEmailError === true || this.state.HighLevelErrorsMyProfile.areNewEmailsTheSame === false)
				&& this.state.PressedSendButton === true) {
				div2 = 'inputFieldEditSettings2'
			}

		}


		// let div = 'inputFieldEditSettings';
		// if ( (this.state.CompanyErrors.Settings.NewEmailError === true || this.state.HighLevelErrorsMyProfile.areNewEmailsTheSame === false)
		// 	&& this.state.PressedSendButton === true) {
		// 	div = 'inputFieldEditSettings2'
		// }
		//
		// let div2 = 'inputFieldEditSettings';
		// if ( (this.state.CompanyErrors.Settings.RepeatNewEmailError === true || this.state.HighLevelErrorsMyProfile.areNewEmailsTheSame === false)
		// 	&& this.state.PressedSendButton === true) {
		// 	div2 = 'inputFieldEditSettings2'
		// }



		return [
			// <input
			// 	type="password"
			// 	className={div3}
			// 	//defaultValue={'********'}
			// 	onChange={(e) => this.handleSettingsFields(e.target.value, 'Password')}
			// />,
			<div className='PasswordContainerWithEyeIcon4'>
				<input
					type={this.state.passwordTypes.input1}
					className={div3}
					disabled={this.state.WaitingForRequest === true}
					//defaultValue={'********'}
					onChange={(e) => this.handleSettingsFields(e.target.value, 'Password')}
				/>
				<img className="eyePasswordMyProfile" src={this.decideEyeIconToShow(this.state.passwordTypes.input1)} alt={''}
					 onClick={() => this.switchTypePasswordToShowContent('input1') }/>
			</div>,

			<input
				type="email"
				className={div}
				//defaultValue={'********'}
				value={this.state.NewEmail}
				onChange={(e) => this.handleSettingsFields(e.target.value, 'NewEmail')}
			/>,

			<input
				type="email"
				className={div2}
				//defaultValue={'********'}
				value={this.state.RepeatNewEmail}
				onChange={(e) => this.handleSettingsFields(e.target.value, 'RepeatNewEmail')}
			/>
		]
	}


	decideEyeIconToShow = (state) => {
		// console.log(state)
		if (state === 'password') {return EyeSlashed}
		else {return EyeOpened}
	}

	switchTypePasswordToShowContent = (whichInput) => {
		let newType = 'text'
		console.log(this.state.passwordTypes)

		if (whichInput === 'input1') {
			if (this.state.passwordTypes.input1 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input1: newType}))
		}
		if (whichInput === 'input2') {
			if (this.state.passwordTypes.input2 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input2: newType}))
		}
		if (whichInput === 'input3') {
			if (this.state.passwordTypes.input3 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input3: newType}))
		}if (whichInput === 'input4') {
			if (this.state.passwordTypes.input4 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input4: newType}))
		}
		if (whichInput === 'input5') {
			if (this.state.passwordTypes.input5 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input5: newType}))
		}

		return null;
	}

	editPasswordsInputFields = () => {

		let div3 = 'inputFieldEditSettings';
		let div = 'inputFieldEditSettings';
		let div2 = 'inputFieldEditSettings';

		//Give priority to first input field
		if ( this.state.CompanyErrors.Settings.OldPasswordError === true && this.state.PressedSendButton === true) {
			div3 = 'inputFieldEditSettings2'
		}

		if (this.state.CompanyErrors.Settings.OldPasswordError === false) {
			if ( (this.state.CompanyErrors.Settings.NewPasswordError === true || this.state.HighLevelErrorsMyProfile.areNewPasswordsTheSame === false)
				&& this.state.PressedSendButton === true) {
				div = 'inputFieldEditSettings2'
			}

			if ( (this.state.CompanyErrors.Settings.RepeatNewPasswordError === true || this.state.HighLevelErrorsMyProfile.areNewPasswordsTheSame === false)
				&& this.state.PressedSendButton === true) {
				div2 = 'inputFieldEditSettings2'
			}

		}


		return [
			// <input
			// 	type="password"
			// 	className={div3}
			// 	//defaultValue={'********'}
			// 	onChange={(e) => this.handleSettingsFields(e.target.value, 'OldPassword')}
			// />,
			<div className='PasswordContainerWithEyeIconMyProfile3'>
				<input
					type={this.state.passwordTypes.input2}
					className={div3}
					disabled={this.state.WaitingForRequest === true}
					//defaultValue={'********'}
					onChange={(e) => this.handleSettingsFields(e.target.value, 'OldPassword')}
				/>
				<img className="eyePasswordMyProfile" src={this.decideEyeIconToShow(this.state.passwordTypes.input2)} alt={''}
					 onClick={() => this.switchTypePasswordToShowContent('input2') }/>
			</div>,

			<div className='PasswordContainerWithEyeIconMyProfile3'>
				<input
					type={this.state.passwordTypes.input3}
					className={div}
					disabled={this.state.WaitingForRequest === true}
					//defaultValue={'********'}
					onChange={(e) => this.handleSettingsFields(e.target.value, 'NewPassword')}
				/>
				<img className="eyePasswordMyProfile" src={this.decideEyeIconToShow(this.state.passwordTypes.input3)} alt={''}
					 onClick={() => this.switchTypePasswordToShowContent('input3') }/>
			</div>,

			<div className='PasswordContainerWithEyeIconMyProfile3'>
				<input
					type={this.state.passwordTypes.input4}
					className={div2}
					disabled={this.state.WaitingForRequest === true}
					//defaultValue={'********'}
					onChange={(e) => this.handleSettingsFields(e.target.value, 'RepeatNewPassword')}
				/>
				<img className="eyePasswordMyProfile" src={this.decideEyeIconToShow(this.state.passwordTypes.input4)} alt={''}
					 onClick={() => this.switchTypePasswordToShowContent('input4') }/>
			</div>
			// <input
			// 	type="password"
			// 	className={div}
			// 	//defaultValue={'********'}
			// 	onChange={(e) => this.handleSettingsFields(e.target.value, 'NewPassword')}
			// />,
			// <input
			// 	type="password"
			// 	className={div2}
			// 	//defaultValue={'********'}
			// 	onChange={(e) => this.handleSettingsFields(e.target.value, 'RepeatNewPassword')}
			// />

		]
	}

	editCurrencyInputFields = () => {
		console.log(this.state.Nuevo_perfil.Company.Currency);

		let inputsArrow = 'dropDownInMyProfileCurrency';
		if (this.state.Nuevo_perfil.Company.Currency === null) {
			inputsArrow = 'dropDownInMyProfileCurrencyEmpty';
		}

		return (
			<div className='renderMyCompanyRightInputDropdownCurrency' >
				<Button className='dropdownInSettings' block size="lg"
						onClick={(e) => this.buttonInputFieldForDropdowns('Currency', null)}
				> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Company.Currency}</div>
					<img className={inputsArrow} alt=""/>
				</Button>
				{this.DropDownAttribute('Currency', null, 'false', 'Company' , 'Company')}
			</div>
		)
	}


	deleteMyAccountInputFields = () => {

		return (
			// <input
			// 	type="password"
			// 	className=''
			// 	//defaultValue={'********'}
			// 	onChange={(e) => this.handleSettingsFields(e.target.value, 'Password')}
			// />

		<div className='PasswordContainerWithEyeIconMyProfile2'>
			<input
				type={this.state.passwordTypes.input5}
				className= 'deleteMyAccountInputFields'
				disabled={this.state.WaitingForRequest === true}
				onChange={(e) => this.handleSettingsFields(e.target.value, 'Password')}
			/>
			<img className="eyePasswordMyProfile2" src={this.decideEyeIconToShow(this.state.passwordTypes.input5)} alt={''}
				 onClick={() => this.switchTypePasswordToShowContent('input5') }/>
			<input className='deleteMyAccountInputFieldsToAccomodateScreen'/>
		</div>

			)
	}

	handleCurrency = (e) => {
		this.setState(
			Object.assign(this.state.Nuevo_perfil.Company, {Currency: e})
		);

		// this.setState({Currency: e});
		return null;
	}


	inputFielfForNewPassword = (field) => {
		//console.log(field.field);
		if (field.field === 'OldPassword') {
			return (
				<input
					type="password"
					className='inputSaleTable'
					//defaultValue={'********'}
					onChange={(e) => this.handleSettingsFields(e.target.value, field.field)}
				/>
			)
		}
		if (field.field === 'Password') {
			return (
				<input
					type="password"
					className='inputSaleTable'
					//defaultValue={'********'}
					onChange={(e) => this.handleSettingsFields(e.target.value, field.field)}
				/>
			)
		}
		if (field.field === 'NewPassword') {
			return (
				<input
					type="password"
					className='inputSaleTable'
					//defaultValue={'********'}
					onChange={(e) => this.handleSettingsFields(e.target.value, field.field)}
				/>
			)
		}
	}


	handleSettingsFields = (e, field) => {
		console.log(field);
		if (field === 'Password') {
			console.log(e);
			this.setState(
				Object.assign(this.state, {Password: e}), () => this.afterIntroducingNewFieldsSettings('Password', e)
			);
		}

		if (field === 'NewPassword') {
			this.setState(
				Object.assign(this.state, {NewPassword: e}), () => this.afterIntroducingNewFieldsSettings('NewPassword', e)
			);
		}

		if (field === 'RepeatNewPassword') {
			this.setState(
				Object.assign(this.state, {RepeatNewPassword: e}), () => this.afterIntroducingNewFieldsSettings('RepeatNewPassword', e)
			);
		}

		if (field === 'OldPassword') {
			console.log(this.state.OldPassword);
			this.setState(
				Object.assign(this.state, {OldPassword: e}), () => this.afterIntroducingNewFieldsSettings('OldPassword', e)
			);
		}

		if (field === 'Email') {
			this.setState(
				Object.assign(this.state, {Email: format_email_address(e)}), () => this.afterIntroducingNewFieldsSettings('Email', e)
			);
		}

		if (field === 'NewEmail') {
			this.setState({NewEmail: format_email_address(e)}, () => this.afterIntroducingNewFieldsSettings('NewEmail', e));
		}

		if (field === 'RepeatNewEmail'){
			this.setState({RepeatNewEmail: format_email_address(e)}, () => this.afterIntroducingNewFieldsSettings('RepeatNewEmail', e));
		}

		return null;
	}

	afterIntroducingNewFieldsSettings = (field, value) => {
		console.log(this.state);
		this.checkAreBothNewFieldsTheSame();
		this.validateEditMySettings(field, value);
		return null;

	}


	checkAreBothNewFieldsTheSame = () => {

		if (this.state.NewEmail === this.state.RepeatNewEmail) {
			// console.log('true')
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile, {areNewEmailsTheSame: true})
			);
		} else {
			// console.log('false')
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile, {areNewEmailsTheSame: false})
			);
		}


		if (this.state.NewPassword === this.state.RepeatNewPassword){
			// console.log('true')
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile, {areNewPasswordsTheSame: true})
			);
		}else {
			// console.log('false')
			this.setState(
				Object.assign(this.state.HighLevelErrorsMyProfile, {areNewPasswordsTheSame: false})
			);
		}
		return null;
	}


	validateEditMySettings = (field, value) => {
		// console.log(this.state.CompanyErrors.Settings);
		// console.log(this.state);
		// console.log(this.state.NewEmail);
		// console.log(this.state.RepeatNewEmail);
		// console.log(email_validation(this.state.NewEmail));
		// console.log(email_validation(this.state.RepeatNewEmail));

		if (field === 'NewEmail') {
			if (email_validation(this.state.NewEmail)) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {NewEmailError: false})
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {NewEmailError: true})
				);
			}
		}

		if (field === 'RepeatNewEmail') {
			if (email_validation(this.state.RepeatNewEmail)) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {RepeatNewEmailError: false})
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {RepeatNewEmailError: true})
				);
			}
		}

		// console.log(validate_string_is_not_empty_or_null(this.state.Password));
		if (field === 'Password') {
			if (validate_string_is_not_empty_or_null(this.state.Password)) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {PasswordError: false})
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {PasswordError: true})
				);
			}
		}

		// console.log(field);
		if (field === 'OldPassword') {
			if (validate_string_is_not_empty_or_null(value)) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {OldPasswordError: false})
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {OldPasswordError: true})
				);
			}
		}
		// console.log(this.state);

		if (field === 'NewPassword') {
			if (validate_string_is_not_empty_or_null(this.state.NewPassword)) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {NewPasswordError: false})
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {NewPasswordError: true})
				);
			}
		}

		if (field === 'RepeatNewPassword') {
			if (validate_string_is_not_empty_or_null(this.state.RepeatNewPassword)) {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {RepeatNewPasswordError: false})
				);
			} else {
				this.setState(
					Object.assign(this.state.CompanyErrors.Settings, {RepeatNewPasswordError: true})
				);
			}
		}
		return null;
	}


	changeEmailContainer = () => {
		return (
			<div className='changeSettingLargeContainer'>
				<div className='changeSettingLargeContainerContent'>
					<span className='EditSettingsMessage'>{'Change email'}</span>

					<div className='changeSettingContainerName'>
						{this.editEmailName()}
					</div>

					<div className='changeSettingContainerInput'>
						{this.editEmailInputFields()}
					</div>

					{this.messageWrongEmails()}
					{this.buttonsEditMyEmail()}
				</div>
			</div>
		)
	}

	changePasswordsContainer = () => {
		return (
			<div className='changeSettingLargeContainerPassword'>
				<div className='changeSettingLargeContainerContent'>
					<span className='EditSettingsMessage'>{'Change password'}</span>
					<div className='changeSettingContainerName'>
						{this.editPasswordsNames()}
					</div>

					<div className='changeSettingContainerInput'>
						{this.editPasswordsInputFields()}
					</div>

					<div className='messageFormatPasswordMyProfile'>Password must contain 8-16 characters, 1 digit and one special symbol: !@#$%^&*</div>

					{this.messageWrongPasswords()}
					{this.buttonsEditMyPassword()}
				</div>
			</div>
		)
	}


	messageWrongEmails = () => {

		// console.log(this.state.HighLevelErrorsMyProfile.areNewEmailsTheSame);
		// console.log(this.state.PressedSendButton);
		// console.log(this.state.CompanyErrors.Settings);
		// console.log(this.state.HighLevelErrorsMyProfile);

		// Always show the message if the button is pressed.
		if( this.state.PressedSendButton === true) {
			// Give priority to the old password field
			if (this.state.CompanyErrors.Settings.PasswordError === true) {
				return <div className='messageWrongEmails'> Password is not provided.</div>
			} else {
				// // Give priority to the input field validation:
				if (this.state.CompanyErrors.Settings.NewEmailError === true || this.state.CompanyErrors.Settings.RepeatNewEmailError === true) {
					return <div className='messageWrongEmails'> Invalid e-mail input.</div>
				} else {
					if (this.state.HighLevelErrorsMyProfile.areNewEmailsTheSame === false) {
						return <div className='messageWrongEmails'> E-mail addresses above are not the same.</div>
					} else {
						if (this.state.CompanyErrors.Settings.WrongPassword === true ) {
							return <div className='messageWrongEmails'> Wrong password.</div>
						}
					}
				}
			}
		}
		return null;
	}

	messageWrongPasswords = () => {

		// console.log(this.state.HighLevelErrorsMyProfile.areNewEmailsTheSame);
		// console.log(this.state.PressedSendButton);

		// Always show the message if the button is pressed.
		if( this.state.PressedSendButton === true) {
			// Give priority to the old password field
			if (this.state.CompanyErrors.Settings.OldPasswordError === true) {
				return <div className='messageWrongPasswords'> Password is not provided.</div>
			} else {
				// Give priority to the input field validation:
				if (this.state.CompanyErrors.Settings.NewPasswordError === true || this.state.CompanyErrors.Settings.RepeatNewPasswordError === true) {
					return <div className='messageWrongPasswords'> Empty field.</div>
				} else {
					if (!validate_password_format(this.state.NewPassword)) {
						return <div className='messageWrongPasswords'> Please, check the password format.</div>
					} else {
						if (this.state.HighLevelErrorsMyProfile.areNewPasswordsTheSame === false) {
							return <div className='messageWrongPasswords'> New passwords above are not the same.</div>
						} else {
							if (this.state.CompanyErrors.Settings.WrongPassword === true) {
								return <div className='messageWrongPasswords'> Wrong old password.</div>
							}
						}
					}
				}
			}
		}
		return null;
	}


	messageWrongPasswordsForDelete = () => {

		// Always show the message if the button is pressed.
		if( this.state.PressedSendButton === true) {
			// Give priority to the input field validation:
			if (this.state.CompanyErrors.Settings.OldPasswordError === true) {
				return <div className='messageWrongEmailsForDelete'> Password is not provided.</div>
			}
			if (this.state.CompanyErrors.Settings.WrongPassword === true) {
				return <div className='messageWrongPasswordsForDeleteWrongEmail'> Wrong password.</div>
			}
		}
		return null;
	}


	changeCurrencyContainer = () => {
		return (
			<div className='changeSettingSmallContainer'>
				<div className='changeSettingSmallContainerContent'>
					<span className='EditSettingsMessage'>{'Change currency'}</span>

					<div className='changeSettingContainerName'>
						{this.editCurrencyNames()}
					</div>

					<div className='changeSettingContainerInput'>
						{this.editCurrencyInputFields()}
					</div>

					{this.disclaimerVeracityInformationCurrency()}
					{this.buttonsEditMyCurrency()}
				</div>
			</div>
		)
	}

	deleteMyAccount = () => {

		// changeSecondaryStatusToDelete2Account
		let deteleConfirmation = null;
		let deletePassword = null;
		let deleteInputForm = null;
		if (this.state.TerciaryStatus === this.state.TerciaryCode.Confirmed) {
			deteleConfirmation = 'Please, to confirm you want to continue, introduce your password below.';
			deletePassword = <div className='changeSettingDeleteMyAccountContainerName'>
				{this.deleteAccountNames()}
			</div>
			deleteInputForm = <div className='changeSettingDeleteMyAccountContainerInput'>
				{this.deleteMyAccountInputFields()}
			</div>

		}
		return (
			<div className='changeSettingDeleteMyAccountContainer'>
				<div className=''>
					<span className='EditSettingsDeleteMyAccountMessage'>{'Delete account'}</span>

					<span className='EditSettingsMessageDelete'> If you delete your account, you will lose all your personal and professional data.
					You will no longer be able to retrieve it in the future. <br/><br/> {deteleConfirmation}</span>

					{deletePassword}

					{deleteInputForm}

					{this.buttonsDeleteMyAccount()}
					{this.messageWrongPasswordsForDelete()}
				</div>
			</div>
		)
	}

	editMyContactPhone = () => {
		return (

			<div className='changeSettingContactPhoneContainer'>
				<div className='changeSettingSmallContainerContent'>
					<span className='EditSettingsMessage'>{'Change contact number'}</span>

					<div className='changeSettingContainerName'>
						{this.renderMyContactPhoneLeft()}
					</div>

					<div className='changeSettingContainerInput'>
						{this.renderMyContactPhoneRight()}
					</div>
					{this.messageWrongContactPhone()}
					{this.buttonsEditMyContactPhone()}
				</div>
			</div>
		)
	}

	manageMyPSI = () => {
		return (

			<div className='manageMyPSIContainer'>
				<div className='manageMyPSIContainerContent'>
					<span className='EditSettingsMessage'>{'Manage software-services company'}</span>

					<div className='manageMyPSIContainerMessage'>
						{this.messageManageYourPSI()}
					</div>

					<div className='manageMyPSIContainerValidationArea'>
						{this.ValidateMyCodePSI()}
					</div>

					{this.buttonsManageMyPSI()}

					{/*<div className='changeSettingContainerName'>*/}
					{/*	{this.renderMyContactPhoneLeft()}*/}
					{/*</div>*/}

					{/*<div className='changeSettingContainerInput'>*/}
					{/*	{this.renderMyContactPhoneRight()}*/}
					{/*</div>*/}
					{/*{this.messageWrongContactPhone()}*/}
					{/*{this.buttonsEditMyContactPhone()}*/}
				</div>
			</div>
		)
	}


	messageWrongContactPhone = () => {

		if ( (this.state.CompanyErrors.Company.Phone_number === true || this.state.CompanyErrors.Person.Phone_number === true)
			&& this.state.PressedSendButton === true) {
			return <div className='messageWrongContactPhone'> Only digits without spaces</div>
		} else {return null;}
	}

	buttonsEditMyCurrency = () => {
		return (
			<div className="buttonsEditMyCurrency">
				<Button className="EditSettingButtonLeft" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="EditSettingButtonRight" block size="lg" onClick={(e) => this.submitEditCompanyForm()}>
					{'Save'}
				</Button>
			</div>
		)
	}


	buttonsEditMyPassword = () => {
		return (
			<div className="buttonsEditMyPassword">
				<Button className="EditSettingPassButtonLeft" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="EditSettingPassButtonRight" block size="lg" onClick={(e) => this.validateInformationForNewPasswordSetUp()}>
					{'Save'}
				</Button>
			</div>
		)
	}

	buttonsEditMyEmail = () => {
		return (
			<div className="buttonsEditMyEmail">
				<Button className="EditSettingButtonLeft" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="EditSettingButtonRight" block size="lg" onClick={(e) => this.validateInformationForNewEmailSetUp()}>
					{'Save'}
				</Button>
			</div>
		)
	}

	buttonsDeleteMyAccount = () => {
		return (
			<div className="buttonsDeleteMyAccount">
				<Button className="EditSettingButtonLeft" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="EditSettingButtonRight" block size="lg" onClick={(e) =>
					this.validateInformationForDeleteMyAccount()
				}>
					{'Delete'}
				</Button>
			</div>
		)
	}

	sendNewEmail = () => {

		let Profile = () => {
			return create_json_my_profile(this.state.Profile.Company, this.state.Profile.Person, this.state.Profile.Employees, this.state.Profile.Shops,
				this.state.Profile.Settings, this.state.Nuevo_perfil.Company, this.state.Nuevo_perfil.Person, this.state.Nuevo_perfil.Employees,
				this.state.Nuevo_perfil.Shops, this.state.Nuevo_perfil.Settings, false, this.state.NewEmail).then(json => {return json});
		}

		let profile1 = Profile();

		profile1.then((response) => {

			if (response.contenido.Update_my_profile_output.Status === "OK") {

				// Reset the update_number
				console.log(response.contenido.Update_my_profile_output);

				localStorage.setItem('PUN', response.contenido.Update_my_profile_output.Update_number);

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				// // Now that the response is obtained and parsed, render the information on screen - Change the code to display it on screen.
				// this.setState({PrimaryStatus: this.state.PrimaryCode.Company});
				this.updatePageAfterRequestIsSuccessful();

			} else {

				console.log('Status NOK');

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				if (response.contenido.Update_my_profile_output.Error_code === 'Update_my_profile_1') {
					this.setState({ErrorMessage: 'ProfileNotUpdated'});
				}
				return null;
			}
		})
	}

	sendNewPassword = () => {

		console.log('provider1@gmail.com');
		const state = this.state;

		var estado = {};
		console.log(estado);

		estado['Email'] = state.Email;
		estado['Password'] = null;
		estado['Reset'] = null;
		estado['Verification_code'] = null;
		estado['NewContra'] = state.NewPassword;

		var usuario_data = {};
		usuario_data.estado = estado;


		// This function submits the new password or new e_mail account
		let NewAccount = function () {
			return create_json_log_in_account({estado}).then(json => {
				return json
			});
		}

		let newAccount = NewAccount()

		newAccount.then((response) => {
				console.log(response);
				if (response.contenido.Log_in_output.Status === "OK") {

					// TODO: Martín - aquí hay que llamar a la función de reset de pantalla.

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// // Now that the response is obtained and parse, render the information on screen - Change the code to display it on screen.
					// this.setState({PrimaryStatus: this.state.PrimaryCode.Company});
					this.updatePageAfterRequestIsSuccessful();

					return null;
				} else {

					console.log('Status NOK');


					// if (response.contenido.Log_in_output.Error_code !== 'Log_in_1') {
					this.setState({ErrorMessage: 'ProfileNotUpdated'});
					// }

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					return null;
				}
			}
		)
		return null;

	}

	validateInformationForNewEmailSetUp = () => {

		console.log('hola');
		this.setState({PressedSendButton: true}, () => {});
		console.log(this.state);

		if (this.state.HighLevelErrorsMyProfile.areNewEmailsTheSame === true && this.state.CompanyErrors.Settings.NewEmailError === false
			&& this.state.CompanyErrors.Settings.RepeatNewEmailError === false && this.state.NewEmail !== ''
			&& this.state.RepeatNewEmail !== '') {
			this.submitNewEmail()
		}

		return null;
	}

	validateInformationForDeleteMyAccount = () => {

		if (this.state.TerciaryStatus === this.state.TerciaryCode.Confirmed) {
			this.setState({PressedSendButton: true}, () =>{
				this.decideWhetherToCallDeleteAccount()})
		}

		if (this.state.TerciaryStatus !== this.state.TerciaryCode.Confirmed) {
			this.setState({TerciaryStatus: this.state.TerciaryCode.Confirmed});
		}

		return null;
	}


	decideWhetherToCallDeleteAccount = () => {

		if ((this.state.Password === '' || this.state.Password === null) && this.state.PressedSendButton === true) {
			this.setState(
				Object.assign(this.state.CompanyErrors.Settings, {OldPasswordError: true })
			);
		} else {
			this.setState(
				Object.assign(this.state.CompanyErrors.Settings, {OldPasswordError: false })
			);
			this.checkOldPasswordToDelete();
		}

		return null;
	}


	validateInformationForNewPasswordSetUp = () => {

		// console.log('hola');
		// console.log(this.state);
		this.setState({PressedSendButton: true}, () => {});

		// console.log(validate_password_format(this.state.NewPassword))
		if (this.state.HighLevelErrorsMyProfile.areNewPasswordsTheSame === true && this.state.CompanyErrors.Settings.NewPasswordError === false
			&& this.state.CompanyErrors.Settings.RepeatNewPasswordError === false && validate_password_format(this.state.NewPassword)) {
			// console.log('Petición enviadad')
			this.submitNewPassword()
		}

		return null;
	}


	submitNewEmail = () => {
		console.log('hola');
		const state = this.state;

		var estado = {};
		console.log(state);

		estado['Email'] = state.Email;
		// estado['Email'] = state.NewEmail;
		estado['Password'] = state.Password;
		estado['Reset'] = null;
		estado['Verification_code'] = null;
		estado['NewContra'] = null;
		estado['DeleteMyAccount'] = state.DeleteMyAccount;

		console.log(estado);
		//Validar que las contraseñas nuevas coincidan

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		// This function submits the old password to check if it's valid.
		let NewAccount = function () {
			return create_json_log_in_validate_old_password({estado}).then(json => {
				return json
			});
		}

		let newAccount = NewAccount()

		newAccount.then((response) => {
				console.log(response);
				if (response.contenido.Log_in_output.Status === "OK") {
					//console.log('Status OK');
					// var Profile_output = response.contenido.Log_in_output.Profile_data;
					// //console.log(Profile_output);
					//
					// this.setState(
					// 	Object.assign(this.state.Profile, {Company: Profile_output.Company })
					// );
					this.sendNewEmail();

					return null;
				} else {
					let error_code = response.contenido.Log_in_output.Error_code;
					if (error_code === 'Log_in_1') {
						this.setState(
							Object.assign(this.state.CompanyErrors.Settings, {WrongPassword: true })
						);
					}

					console.log('Status NOK');

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					if (response.contenido.Log_in_output.Error_code !== 'Log_in_1') {
						this.setState({ErrorMessage: 'ProfileNotUpdated'});
					}

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					return null;
				}
			}
		)
		return null;
	}


	checkOldPasswordToDelete = () => {

		console.log('hola');
		const state = this.state;

		var estado = {};
		console.log(state);

		estado['Email'] = state.Email;
		estado['Password'] = state.Password;
		estado['Reset'] = null;
		estado['Verification_code'] = null;
		estado['NewContra'] = null;
		estado['DeleteMyAccount'] = false;


		console.log(estado);
		//Validar que las contraseñas nuevas coincidan


		// Render the loading icon
		this.setState({WaitingForRequest: true});

		// This function submits the old password to check if it's valid.
		let NewAccount = function () {
			return create_json_log_in_validate_old_password({estado}).then(json => {
				return json
			});
		}

		let newAccount = NewAccount()

		newAccount.then((response) => {
				console.log(response);
				if (response.contenido.Log_in_output.Status === "OK") {
					//Remove the loading icon

					// this.setState({ProfileDeleted: true});

					this.submitDeleteMyAccount();

					return null;
				} else {
					let error_code = response.contenido.Log_in_output.Error_code;
					// TODO: Martín: terminar tratamiento de errores para el caso de Delete my account
					if (error_code === 'Log_in_1') {
						// this.setState(
						// 	Object.assign(this.state.CompanyErrors.Settings, {OldPasswordError: false })
						// );
						this.setState(
							Object.assign(this.state.CompanyErrors.Settings, {WrongPassword: true })
						);
					}

					console.log('Status NOK');

					if (response.contenido.Log_in_output.Error_code !== 'Log_in_1') {
						this.setState({ErrorMessage: 'ProfileNotUpdated'});
					}

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					return null;
				}
			}
		)
		return null;

	}


	submitDeleteMyAccount = () => {
		console.log('hola');
		const state = this.state;

		var estado = {};
		console.log(state);

		estado['Email'] = state.Email;
		estado['Password'] = null;
		estado['Reset'] = null;
		estado['Verification_code'] = null;
		estado['NewContra'] = null;
		estado['DeleteMyAccount'] = true;

		console.log(estado);

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		// This function submits the old password to check if it's valid.
		let NewAccount = function () {
			return create_json_log_in_validate_old_password({estado}).then(json => {
				return json
			});
		}

		let newAccount = NewAccount()

		newAccount.then((response) => {
				console.log(response);
				if (response.contenido.Log_in_output.Status === "OK") {

					this.setState({WaitingForRequest: false});
					this.setState({ProfileDeleted: true});

					clearCookiesOnLogOut();

					return null;
				} else {
					let error_code = response.contenido.Log_in_output.Error_code;
					// TODO: Martín: terminar tratamiento de errores para el caso de Delete my account -- Aquí es el error genérico
					if (error_code === 'Log_in_1') {
						this.setState(
							Object.assign(this.state.CompanyErrors.Settings, {WrongPassword: true })
						);
					}

					console.log('Status NOK');

					if (response.contenido.Log_in_output.Error_code !== 'Log_in_1') {
						this.setState({ErrorMessage: 'ProfileNotUpdated'});
					}

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					return null;
				}
			}
		)
		return null;
	}

	accountDeletionSuccessful = () => {

		if (this.state.ProfileDeleted === true) {

			return (<div className='accountDeletionSuccessful'>
				<span className='accountDeletionSuccessfulText'> We are sorry to say "see you later"!  </span>
				<Link to='/LogIn'>
					<Button className="accountDeletionSuccessfulButton" block size="lg" onClick={() => clearCookiesOnLogOut()}>
						{'See you later'}
					</Button>
				</Link>
			</div>)
		} else {return null;}
	}

	submitNewPassword = () => {
		const state = this.state;

		var estado = {};
		console.log(state);

		estado['Email'] = state.Email;
		estado['Password'] = state.OldPassword;
		estado['Reset'] = null;
		estado['Verification_code'] = null;
		estado['NewContra'] = null;
		estado['DeleteMyAccount'] = state.DeleteMyAccount;

		// var usuario_data = {};
		// usuario_data.estado = estado;
		console.log(estado);
		//Validar que las contraseñas nuevas coincidan

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		// This function submits the old password to check if it's valid.
		let NewAccount = function () {
			return create_json_log_in_validate_old_password({estado}).then(json => {
				return json
			});
		}

		let newAccount = NewAccount()

		newAccount.then((response) => {
				console.log(response);
				if (response.contenido.Log_in_output.Status === "OK") {
					//console.log('Status OK');
					// var Profile_output = response.contenido.Log_in_output.Profile_data;
					// //console.log(Profile_output);
					//
					// this.setState(
					// 	Object.assign(this.state.Profile, {Company: Profile_output.Company })
					// );
					this.sendNewPassword();

					return null;
				} else {
					let error_code = response.contenido.Log_in_output.Error_code;
					if (error_code === 'Log_in_1') {
						this.setState(
							Object.assign(this.state.CompanyErrors.Settings, {WrongPassword: true })
						);
					}

					console.log('Status NOK');

					if (response.contenido.Log_in_output.Error_code !== 'Log_in_1') {
						this.setState({ErrorMessage: 'ProfileNotUpdated'});
					}

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					return null;
				}
			}
		)
		return null;
	}


	changeSecondaryStatusToEditPassword = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.EditPasswords) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.EditPasswords});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToEditEmail = () => {

		if (this.state.SecondaryStatus !== this.state.SecondaryCode.EditEmail) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.EditEmail});
			return null;
		} else {
			return null;
		}
	}

	changeSecondaryStatusToEditCurrency = () => {
		if (this.state.SecondaryStatus !== this.state.SecondaryCode.EditCurrency) {
			this.setState({SecondaryStatus: this.state.SecondaryCode.EditCurrency});
			return null;
		} else {
			return null;
		}
	}


	buttonsEditMyContactPhone = () => {
		return (
			<div className="buttonsEditMyContactPhone">
				<Button className="EditSettingButtonLeft" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="EditSettingButtonRight" block size="lg" onClick={(e) => this.submitEditCompanyForm()}>
					{'Save'}
				</Button>
			</div>
		)
	}

	buttonsManageMyPSI = () => {
		return (
			<div className="buttonsEditMyContactPhone">
				<Button className="EditSettingButtonLeft" block size="lg" onClick={(e) => this.cancelEditProfileForm()}>
					{'Cancel'}
				</Button>

				<Button className="EditSettingButtonRight" block size="lg" onClick={(e) => this.submitManageMyPSI('Accept')}>
					{'Confirm'}
				</Button>
			</div>
		)
	}

	renderMyContactPhoneLeft = (row_index) => {
		//const to_map = this.state.Profile.Company;
		//console.log(to_map);
		return (
			<div className='editSettingName'> {'Contact phone'}  </div>
		)
	}

	renderMyContactPhoneRight = () => {
		//console.log('lol');
		//console.log(this.state);

		let div = 'renderMyCompanySettingRightInputDividedRight';
		// console.log(this.state);
		if ((this.state.CompanyErrors.Company.Phone_number === true || this.state.CompanyErrors.Person.Phone_number === true)
			&& this.state.PressedSendButton === true) {
			div = 'renderMyCompanySettingRightInputDividedRight2';
		}

		let inputsArrow = 'dropDownInMyProfileContactNumber';
		if (this.state.Nuevo_perfil.Company.Phone_number === null) {
			inputsArrow = 'dropDownInMyProfileContactNumberEmpty';
		}

		if (isUserACompany()) {
			return (

				<div className='editCompanyForm'>

					<div className='renderMyCompanyRightInputDivided'>
						<div className='renderMyCompanyRightInputDropdownDividedPhonePrefix' >
							<Button className='dropdownInSettings' block size="lg"
									onClick={(e) => this.buttonInputFieldForDropdowns('Phone_prefix', null)}
							> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Company.Phone_prefix}</div>
								<img className={inputsArrow} alt=""/>
							</Button>
							{this.DropDownAttribute('Phone_prefix', null, 'false', 'Company', 'Company')}
						</div>

						<input
							className={div} autoFocus type="text"
							value={this.decidePlaceHolderCompany('Phone_number')}
							onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Phone_number', null, 'Company')}
						/>
					</div>
				</div>
			)

		} else {
			if (isUserAPSI()) {
				return (

					<div className='editCompanyForm'>

						<div className='renderMyCompanyRightInputDivided'>
							<div className='renderMyCompanyRightInputDropdownDividedPhonePrefix' >
								<Button className='dropdownInSettings' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Phone_prefix', null)}
								> <div className='renderMyCompanyRightInputDropdownText' >{this.state.Nuevo_perfil.Company.Phone_prefix}</div>
									<img className={inputsArrow} alt=""/>
								</Button>
								{this.DropDownAttribute('Phone_prefix', null, 'false', 'Company', 'Company')}
							</div>

							<input
								className={div} autoFocus type="text"
								value={this.decidePlaceHolderCompany('Phone_number')}
								onChange={(e) => this.setNewProfile(e.target.value, 'Company', 'Phone_number', null, 'Company')}
							/>
						</div>
					</div>
				)
			} else {
				return (
					<div className='editCompanyForm'>

						<div className='renderMyCompanyRightInputDivided'>
							<div className='renderMyCompanyRightInputDropdownDividedPhonePrefix'>
								<Button className='dropdownInSettings' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Phone_prefix', null)}
								>
									<div
										className='renderMyCompanyRightInputDropdownText'>{this.state.Nuevo_perfil.Person.Phone_prefix}</div>
								</Button>
								{this.DropDownAttribute('Phone_prefix', null, 'false', 'Person', 'Person')}
							</div>

							<input
								className={div} autoFocus type="text"
								value={this.state.Nuevo_perfil.Person.Phone_number}
								onChange={(e) => this.setNewProfile(e.target.value, 'Person', 'Phone_number', null, 'Company')}
							/>
						</div>
					</div>
				)
			}
		}
	}


	messageManageYourPSI = () => {

		return(
			<span>
				The sofware-services company "MOCK-UP" has sent you a request to be on-boarded...
			</span>
		)
	}

	ValidateMyCodePSI = () => {
		return(
			<div className='ValidateMyCodePSI'>
				<div className='ValidateMyCodePSIText'>
					Verification code
				</div>
				<div className='ValidateMyCodePSIInputPlace'>
					<input className='ValidateMyCodePSIInput' onChange={(e) => {this.setState({'PSI_Validation_code': e.target.value})}}/>
				</div>
			</div>
		)
	}

	errorMessageWhenUpdatingProfileOrLoading = () => {

		if (this.state.ErrorMessage === 'ProfileNotUpdated') {
			return (
				<div className='errorMessageWhenUpdatingProfile'>
					<div className='errorMessageWhenUpdatingProfileText'> An error occurred. Please, contact support </div>
				</div>
			)
		}

		return null;
	}

	containerInformation = () => {

		if (this.state.PrimaryStatus === this.state.PrimaryCode.Person) {
			if (this.state.SecondaryStatus === this.state.SecondaryCode.Normal) {
				return (
					<div className='containerInformationForm'>
						{this.renderMyPersonProfile()}
					</div>
				)
			} else {
				return (
					<div className='containerInformationForm'>
						{this.renderMyPersonProfile()}
						{this.editMyPersonProfile()}
					</div>
				)
			}
		}

		if (this.state.PrimaryStatus === this.state.PrimaryCode.Company) {
			if (this.state.SecondaryStatus === this.state.SecondaryCode.Normal) {
				// console.log(this.state.CompanyErrors);
				return (
					<div className='containerInformationForm'>
						{this.renderMyCompany()}
					</div>
				)
			} else {
				return [
					<div className='containerInformationForm'>
						{this.renderMyCompany()}
						{this.editMyCompany()}
					</div>
				]
			}
		}
		if (this.state.PrimaryStatus === this.state.PrimaryCode.Employee) {
			if (this.state.SecondaryStatus === this.state.SecondaryCode.Normal) {
				return (
					<div className="containerInformationTable">
						{this.renderMyEmployees()}
					</div>
				)
			} else {
				return (
					<div className="containerInformationTable">
						{this.renderMyEmployees()}
						{this.editMyEmployees()}
					</div>
				)
			}
		}
		if (this.state.PrimaryStatus === this.state.PrimaryCode.Shop) {
			if (this.state.SecondaryStatus === this.state.SecondaryCode.Normal) {
				return (
					<div className="containerInformationTable">
						{this.renderMyShops()}
					</div>
				)
			} else {
				return (
					<div className="containerInformationTable">
						{this.renderMyShops()}
						{this.editMyShops()}
					</div>
				)
			}
		}
		if (this.state.PrimaryStatus === this.state.PrimaryCode.Settings) {
			if (this.state.SecondaryStatus === this.state.SecondaryCode.Normal) {
				return (
					<div className='containerSettingsForm'>
						{this.renderSettings()}
					</div>
				)
			}
			if (this.state.SecondaryStatus === this.state.SecondaryCode.EditEmail) {
				//The user wants to change the password
				return (
					<div className='containerSettingsForm'>
						{this.renderSettings()}
						{this.changeEmailContainer()}
					</div>
				)
			}
			if (this.state.SecondaryStatus === this.state.SecondaryCode.EditPasswords) {
				//The user wants to change the password
				return (
					<div className='containerSettingsForm'>
						{this.renderSettings()}
						{this.changePasswordsContainer()}
					</div>
				)
			}
			if (this.state.SecondaryStatus === this.state.SecondaryCode.EditCurrency) {
				//The user wants to change the currency
				return (
					<div className='containerSettingsForm'>
						{this.renderSettings()}
						{this.changeCurrencyContainer()}
					</div>
				)
			}
			if (this.state.SecondaryStatus === this.state.SecondaryCode.Edit) {
				//The user wants to change the contact details
				return (
					<div className='containerSettingsForm'>
						{this.renderSettings()}
						{this.editMyContactPhone()}
					</div>
				)
			}
			if (this.state.SecondaryStatus === this.state.SecondaryCode.ManagePSI) {
				//The user wants to change the contact details
				return (
					<div className='containerSettingsForm'>
						{this.renderSettings()}
						{this.manageMyPSI()}
					</div>
				)
			}

			if (this.state.SecondaryStatus === this.state.SecondaryCode.DeleteAccount) {
				//The user wants to change the contact details
				return (
					<div className='containerSettingsForm'>
						{this.renderSettings()}
						{this.deleteMyAccount()}
						{this.accountDeletionSuccessful()}
					</div>
				)
			}
		}
	}

	previousToRender = async () => {

		// let Rend = new Renderizar();
		// Rend.start_jwt_creation_and_regeneration();

		if (this.state.ErrorMessage !== 'Retrieve_my_historic_operations_1' && this.state.ErrorMessage !== 'Retrieve_my_profile_1') {
			let check_Profile = await this.RetrieveMyProfile();

			//Change to Data Preparation
			// console.log(check_Profile)
			if (check_Profile !== null) {
				this.setState({PrimaryStatus: this.state.PrimaryCode.DataPreparation});
			}
		}

		return null;
	}


	dataPreparation = () => {

		// First assign to the state the current profile
		// The company's profile
		let Profile_output = JSON.parse(localStorage.getItem("Profile_retrieved"));
		// console.log('dos veces por aquí');
		// console.log(Profile_output)

		this.setState(
			Object.assign(this.state.Profile, {Company: Profile_output.Company}), () => {

				this.setState(
					Object.assign(this.state.Profile, {Employees: Profile_output.Employees}), () => {

						this.setState(
							Object.assign(this.state.Profile, {Shops: Profile_output.Shops}), () => {

								// Person's profile
								this.setState(
									Object.assign(this.state.Profile, {Person: Profile_output.Person}) , () => {
										// this.setState({PrimaryStatus: this.state.PrimaryCode.Company});
										this.organizeInputRetrieved(Profile_output)
									}
								)}
						)}
				)}
		);

		return null;
	}

	organizeInputRetrieved = (Profile_output) => {
		var Nuevo_perfil = {};


		// console.log(this.state.Profile);

		if (isUserACompany()) {


			var null_employee = {};
			var Null_employee = [];
			var False_employee_errors = [];
			// console.log(+Profile_output.Employees.length);
			if (+Profile_output.Employees.length === 0) {
				Null_employee.push({ID: 0, Treatment: null, Name: null, Surname: null, Role: null, Email_address: null
				});
				False_employee_errors.push({Treatment: false, Name: false, Surname: false, Role: false, Email_address: false});
			} else {
				for (let step = 0; step < +Profile_output.Employees.length; step++) {
					null_employee = Object.assign({}, Profile_output.Employees[step]);
					Null_employee.push(null_employee);
					False_employee_errors.push({Treatment: false, Name: false, Surname: false, Role: false, Email_address: false});
				}
				Null_employee.push({
					ID: null, Treatment: null, Name: null, Surname: null, Role: null, Email_address: null
				});
				False_employee_errors.push({Treatment: false, Name: false, Surname: false, Role: false, Email_address: false});
			}


			var null_shop = {};
			var Null_shop = [];
			var False_shop_errors = [];
			// console.log(+Profile_output.Shops.length);
			if (+Profile_output.Shops.length === 0) {
				Null_shop.push({
					ID: null, Name: null, Address: null, Country: null, Region: null, Province: null, Phone_number: null
				});
				False_shop_errors.push({Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false});
			} else {
				for (let step = 0; step < +Profile_output.Shops.length; step++) {
					null_shop = Object.assign({}, Profile_output.Shops[step]);
					Null_shop.push(null_shop);
					False_shop_errors.push({Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false});
				}
				Null_shop.push({
					ID: null, Name: null, Address: null, Country: null, Region: null, Province: null, Phone_number: null
				});
				False_shop_errors.push({Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false});
				False_shop_errors.push({Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false});
				False_shop_errors.push({Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false});
			}

			// Initialize the part of the Company profile required to be displayed later on.
			Nuevo_perfil['Company'] = {
				Name: Profile_output.Company.Name,
				Address: Profile_output.Company.Address,
				Email: format_email_address(Profile_output.Company.Email),
				Phone_number: Profile_output.Company.Phone_number,
				Postal_code: Profile_output.Company.Postal_code,
				Country: Profile_output.Company.Country,
				Currency: Profile_output.Company.Currency,
				Region: Profile_output.Company.Region,
				Province: Profile_output.Company.Province,
				Phone_prefix: Profile_output.Company.Phone_prefix,
				Sector: Profile_output.Company.Sector,
				Subsector: Profile_output.Company.Subsector,
				TFN: Profile_output.Company.TFN
			};

			Nuevo_perfil['Shops'] = Null_shop;
			Nuevo_perfil['Employees'] = Null_employee;
			// console.log(Profile_output);
			// console.log(Nuevo_perfil);
			// console.log(False_employee_errors);

			this.setState({Nuevo_perfil: Nuevo_perfil}, () => {
				this.setState({Email: format_email_address(Profile_output.Company.Email)}, () => {
					this.setState(
						Object.assign(this.state.Profile, {Company: Profile_output.Company}), () => {
							this.setState(
								Object.assign(this.state.CompanyErrors, {Employees: False_employee_errors}), () => {
									this.setState(
										Object.assign(this.state.CompanyErrors, {Shops: False_shop_errors}), () => {
											this.setState({PrimaryStatus: this.state.PrimaryCode.Company}, () => {
												this.proceedToShowProfileAndRemoveUnnecessaryItems()
											})
										})
								})
						})
				})
			});

		} else {
			if (isUserAPSI()){

				// Initialize the part of the PSI profile required to be displayed later on.
				Nuevo_perfil['Company'] = {
					Name: Profile_output.Company.Name,
					Address: Profile_output.Company.Address,
					Email: format_email_address(Profile_output.Company.Email),
					Phone_number: Profile_output.Company.Phone_number,
					Postal_code: Profile_output.Company.Postal_code,
					Country: Profile_output.Company.Country,
					Currency: Profile_output.Company.Currency,
					Region: Profile_output.Company.Region,
					Province: Profile_output.Company.Province,
					Phone_prefix: Profile_output.Company.Phone_prefix,
					Sector: Profile_output.Company.Sector,
					Subsector: Profile_output.Company.Subsector,
					TFN: Profile_output.Company.TFN
				};

				this.setState({Nuevo_perfil: Nuevo_perfil}, () => {
					this.setState({Email: format_email_address(Profile_output.Company.Email)}, () => {
						this.setState(
							Object.assign(this.state.Profile, {Company: Profile_output.Company}), () => {
								this.setState({PrimaryStatus: this.state.PrimaryCode.Company}, () => {
									this.proceedToShowProfileAndRemoveUnnecessaryItems()
								})
							})
					})
				});

			} else {

				// The user is a person
				// console.log(Profile_output);
				var new_profile = {
					Birth_date: Profile_output.Person.Birth_date,
					Email: format_email_address(Profile_output.Person.Email),
					Genre: Profile_output.Person.Genre,
					Nickname: Profile_output.Person.Nickname,
					Phone_number: Profile_output.Person.Phone_number,
					Phone_prefix: Profile_output.Person.Phone_prefix,
					Postal_code: Profile_output.Person.Postal_code
				};
				// console.log(new_profile)

				Nuevo_perfil['Person'] = new_profile;

				this.setState({Nuevo_perfil: Nuevo_perfil});
				this.setState({Email: format_email_address(Profile_output.Person.Email)});


				// Now that the response is obtained and parsed, render the information on screen - Change the code to display it on screen.
				this.setState({PrimaryStatus: this.state.PrimaryCode.Person});

			}



		}

		return null;

	}

	ObscurerMyProfile = () => {
		if (this.state.SecondaryStatus === this.state.SecondaryCode.Normal && this.state.whichLegalTextIsRequired === null) {
			return null
		} else {
			return (
				<div className='ObscurerMyProfile'>
					{' '}
				</div>
			)
		}
	}

	ObscurerDeletion = () => {
		if (this.state.ProfileDeleted !== true) {
			return null
		} else {
			return (
				<div className='ObscurerDeletion'>
					{' '}
				</div>
			)
		}
	}




	TimerToJWTRenewal2(expiration) {
		var timer = 0;
		// console.log(expiration === '870');

		if (expiration !== null && +expiration !== 0) {
			timer = expiration;
		} else {
			timer = getCookie('jwt_expiration');
		}

		// console.log(timer);

		function CallToRegenerateJWT() {
			let Rend = new Renderizar();
			console.log('Nueva llamada en MyProfile');
			Rend.regenerate_initial_jwt();
			return null;
		}

		var interval = setInterval(CallToRegenerateJWT, +timer * 1000);
		// console.log(interval)

		// this.setState({JWTIntervalID: interval});

		return interval;
	}

	intermediateJWT = async () => {

		await this.start_jwt_creation_and_regeneration();
		console.log('intermediateJWT');
		return null;
	}


	start_jwt_creation_and_regeneration  = async () => {

		let initial_JWT = function () {
			return create_initial_JWT_api().then(json => {
				return json
			});
		}

		let initialjwt =  initial_JWT();

		let jwtExecution = initialjwt.then((response) => {

				return null;
			}
		)

		await jwtExecution;


		console.log('start_jwt_creation_and_regeneration');

		// console.log(window.localStorage.getItem('Interval_ID')  );

		// If the JWT is not already continuously recreated, start the process now.
		if (window.localStorage.getItem('Interval_ID') !== null ) {
			// console.log(window.localStorage.getItem('Interval_ID'))
			clearInterval(window.localStorage.getItem('Interval_ID'))
			localStorage.removeItem('Interval_ID')
		}

		let jwtIntervalID = this.TimerToJWTRenewal2(getCookie('jwt_expiration'));

		localStorage.setItem('Interval_ID', jwtIntervalID);

		this.setState({PrimaryStatus : this.state.PrimaryCode.Load});

		return null;
	}





	legalTextContainers = () => {

		let divCookiesSelection = 'legalTextContainers22';
		let divPrivacyPolicy = 'legalTextContainers32';
		let divGTC = 'legalTextContainers42';

		let TIDIName = '© 2021 The TIDI Systems GmbH. All rights reserved.'

		return (
			<div className='legalTextContainers'>
				<Button className="legalTextContainers1" block size="lg" onClick={() => {
					this.requiringSomeLegalText('legalNotice')
				}}>
					{'Legal notice'}
				</Button>
				<Button className={divCookiesSelection} block size="lg" onClick={() => {
					this.requiringSomeLegalText('cookiesSelection')
				}}>
					{'Cookies selection'}
				</Button>
				<Button className={divPrivacyPolicy} block size="lg" onClick={() => {
					this.requiringSomeLegalText('privacyPolicy')
				}}>
					{'Privacy policy'}
				</Button>
				<Button className={divGTC} block size="lg" onClick={() => {
					this.requiringSomeLegalText('GTC')
				}}>
					{'General terms and conditions'}
				</Button>

				<Button className='ContactSupport' block size="lg" onClick={() => {
					this.requiringSomeLegalText('ContactSupport');
					this.setState({ErrorMessage: null});
				}}>
					{'Contact support'}
				</Button>


				<Button className='licensesButton' block size="lg" onClick={() => {
					this.requiringSomeLegalText('Licenses')
				}}>
					{'Third-party open source licenses'}
				</Button>

				<Button className='TIDIName' block size="lg"> {TIDIName} </Button>
			</div>
		)
	}


	requiringSomeLegalText = (legalText) => {

		this.setState({whichLegalTextIsRequired: legalText})

		return null;

	}

	showLegalText = () => {

		let legalText = this.state.whichLegalTextIsRequired;
		let thisDiv = 'showLegalTextBigProfile';
		if (legalText === 'cookiesSelection') {
			thisDiv = 'showLegalTextSmallProfile'
		}
		if (legalText === 'ContactSupport') {
			thisDiv = 'showLegalTextDeciderTitleDivSupport'
		}

		if (legalText !== null) {

			return [
				this.ObscurerMyProfile(),
				<div className={thisDiv}>
					{this.showLegalTextDecider()}
					{this.buttonCloseLegalText()}
				</div>
			]
		}
		return null;
	}


	showLegalTextDecider = () => {
		let legalText = this.state.whichLegalTextIsRequired;

		var showLegalTextDeciderTitle = null;
		var showLegalTextDeciderText = null;
		var showLegalTextDeciderTitleDiv = 'showLegalTextDeciderTitle';
		var showLegalTextDeciderTextDiv = 'showLegalTextDeciderTextMyArea';
		var showLegalTextDeciderTextMyAreaTextInterior = 'showLegalTextDeciderTextMyAreaText';

		if (legalText === 'legalNotice') {
			let buttonPrivacy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'})
			}}>
				{'Privacy policy'}
			</span>;

			let buttonCookiesPolicy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>;
			showLegalTextDeciderTitle = legalNoticeTitle;
			showLegalTextDeciderText = legalNoticeText(buttonPrivacy, buttonCookiesPolicy);
		}
		if (legalText === 'cookiesSelection') {
			showLegalTextDeciderTitle = this.CookiesMenuChangePreferences();
			// showLegalTextDeciderText = 'text';
			// showLegalTextDeciderText = this.CookiesMenuChangePreferences();
		}
		if (legalText === 'cookiesDetail') {
			showLegalTextDeciderTitle = 'Cookies';
			showLegalTextDeciderText = 'cookies text';
		}
		if (legalText === 'privacyPolicy') {
			showLegalTextDeciderTitle = privacyPolicyTitle;
			let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>
			showLegalTextDeciderText = privacyPolicyText(privacyPolicyButton1);
		}
		if (legalText === 'GTC') {
			let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>
			let licensesButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'Licenses'})
			}}>
				{'Open Source licenses'}
			</span>
			let buttonPrivacy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'})
			}}>
				{'Privacy policy'}
			</span>

			showLegalTextDeciderTitle = decideWhichGTCToDisplay(licensesButton1, privacyPolicyButton1, buttonPrivacy).GTCTitle;
			showLegalTextDeciderText = decideWhichGTCToDisplay(licensesButton1, privacyPolicyButton1, buttonPrivacy).GTCText;
		}
		if (legalText === 'Licenses') {
			showLegalTextDeciderTitle = licensesTitle;
			showLegalTextDeciderText = this.allLicenseTextsButtons();
		}
		if (legalText === 'ContactSupport') {
			showLegalTextDeciderTitleDiv = 'showLegalTextDeciderTitleDivSupportTitle';
			showLegalTextDeciderTextDiv = 'showLegalTextDeciderTextDivSupport';
			showLegalTextDeciderTitle = <b>CONTACT SUPPORT</b>;
			showLegalTextDeciderText = 'We are more than glad to give you a hand! Please, write an e-mail to hola@tidisystems.com to reach out for help :)';
			showLegalTextDeciderTextMyAreaTextInterior = 'showLegalTextDeciderTextDivSupportInterior';
		}


		return (
			<div className='showLegalTextDecider'>
				<div className={showLegalTextDeciderTitleDiv}>{showLegalTextDeciderTitle}</div>
				<div className={showLegalTextDeciderTextDiv}>
					<div className={showLegalTextDeciderTextMyAreaTextInterior}>{showLegalTextDeciderText}</div>
				</div>
			</div>
		)
	}

	tableAnalyticsCookies = (type) => {
		return(
			<div className='tableAnalyticsCookies'>
				<Table  className='tableAnalyticsCookiesTable'>
					<thead className='tableAnalyticsCookiesTableHeader'>
					<tr>{this.rendertableAnalyticsCookiesTableHeader()}</tr>
					</thead>
					<tbody className='tableAnalyticsCookiesTableTBody'>
					{this.rendertableAnalyticsCookiesTableData(type)}
					</tbody>
				</Table>
			</div>
		)
	}

	rendertableAnalyticsCookiesTableHeader = () => {

		var first_line = this.state.analyticsCookiesHeader[0];

		if (first_line === undefined) {
			return null;
		} else {
			console.log(first_line)
			let header = Object.values(first_line)
			return header.map((key, index) => {
				return <th key={index}>{key}</th>
			})
		}
	}


	rendertableAnalyticsCookiesTableData = (type) => {

		let tableCookiesBody = Object.values(this.state.analyticsCookiesBody);
		if (type === 'technical') {
			console.log('hol')
			tableCookiesBody = Object.values(this.state.technicalCookiesBody);
		}
		return tableCookiesBody.map((eachRow, index) => {
			let values = Object.values(eachRow);
			const [
				ID, Name, Provider, Goal, Duration
			] = values //destructuring

			return (
				<tr key={ID}>
					<td>{Name}</td>
					<td>{Provider}</td>
					<td>{Goal}</td>
					<td>{Duration}</td>
				</tr>
			)
		})
	}

	changeStateLandingPageForLicenses = (value) => {

		this.setState({licenseText: value});

		return null;

	}

	allLicenseTextsButtons = () => {
		// Returns all buttons of licenses texts

		let licensesButtonsClass = new renderLicensesButtons();
		let licensesButtons = licensesButtonsClass.buttonObjects(this.changeStateLandingPageForLicenses);

		let licenseIntroText = <div className='allLicenseTextsButtonsIntro'>{licensesIntroText}</div>;
		let licensesButtonMIT = licensesButtons['licensesButtonMIT'];
		let licensesButtonBSD2 = licensesButtons['licensesButtonBSD2'];
		let licensesButtonApache2 = licensesButtons['licensesButtonApache2'];
		let licensesButtonBSD3 = licensesButtons['licensesButtonBSD3'];
		let licensesButtonHPND = licensesButtons['licensesButtonHPND'];
		let licensesButtonISC = licensesButtons['licensesButtonISC'];
		let licensesButtonPostgreSQL = licensesButtons['licensesButtonPostgreSQL'];
		let licensesButtonPython = licensesButtons['licensesButtonPython'];
		let licensesButtonUnlicense = licensesButtons['licensesButtonUnlicense'];
		let licensesButtonZope = licensesButtons['licensesButtonZope'];

		let allLicenseButtons = <div className='allLicenseTextsButtonsButtons'>
			{licensesButtonApache2}
			{licensesButtonBSD2}
			{licensesButtonBSD3}
			{licensesButtonHPND}
			{licensesButtonISC}
			{licensesButtonMIT}
			{licensesButtonPostgreSQL}
			{licensesButtonPython}
			{licensesButtonUnlicense}
			{licensesButtonZope}
		</div>
		let licenseAnyText = <div className='allLicenseTextsButtonsText'>{this.decideLicenseText()}</div>;

		return <div className='allLicenseTextsButtons'>
			{licenseIntroText}
			{allLicenseButtons}
			{licenseAnyText}
		</div>
	}

	decideLicenseText = () => {

		let text = this.state.licenseText;
		let Text = null;
		if (text === 'MIT') {
			Text = licenseMITText;
		}
		if (text === 'BSD2') {
			Text = licenseBSD2ext;
		}
		if (text === 'Apache2') {
			Text = licenseApache2Text;
		}
		if (text === 'BSD3') {
			Text = licenseBSD3Text;
		}
		if (text === 'HPND') {
			Text = licenseMITText;
		}
		if (text === 'ISC') {
			Text = licenseISCText;
		}
		if (text === 'PostgreSQL') {
			Text = licensePostgreSQLText;
		}
		if (text === 'Python') {
			Text = licensePythonSFText;
		}
		if (text === 'Unlicense') {
			Text = licenseUnlicenseText;
		}
		if (text === 'Zope') {
			Text = licenseZopePLText;
		}

		return Text;
	}


	showCookiesDetails = () => {

		if (this.state.CookiesDetailsRequired === true) {

			var showLegalTextDeciderTitle = cookiesDetailTitle;

			let tablaTecnicas = this.tableAnalyticsCookies('technical');
			let tablaAnaliticas = this.tableAnalyticsCookies('analytics');

			let buttonCookiesSummary = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'cookiesSelection'}, () => {
					this.setState({CookiesDetailsRequired: false})
				})
			}}>
                    {'Cookies configurator'}
                </span>;
			let buttonPrivacyPolicy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'}, () => {
					this.setState({CookiesDetailsRequired: false})
				})
			}}>
                        {'Privacy policy'}
                    </span>;
			var showLegalTextDeciderText = cookiesDetailText(buttonCookiesSummary, buttonPrivacyPolicy, tablaTecnicas, tablaAnaliticas);

			return (
				<div className='showLegalTextBigProfile'>
					<div className='showLegalTextDecider'>
						<div className='showLegalTextDeciderTitle'>{showLegalTextDeciderTitle}</div>
						<div className='showLegalTextDeciderTextMyArea'><div className='showLegalTextDeciderTextMyAreaText'> {showLegalTextDeciderText}</div></div>
					</div>
					<Button className="showCookiesDetailsCloseButton" onClick={() => {
						this.setState({CookiesDetailsRequired: false})
					}}>
						{'X'}
					</Button>
				</div>
			)
		} else {
			return null;
		}
	}



	CookiesPolicyTextContainerClose = () => {
		return (
			<Button className="CookiesPolicyTextContainerClose" onClick = {() => {this.CookiesPolicyTextContainerCloseFunction()}}>
				{'X'}
			</Button>
		)
	}

	CookiesPolicyTextContainerCloseFunction = () => {

		this.setState({CookiesPolicyRequired: false});
		return null;

	}


	buttonCloseLegalText = () => {
		var buttonCloseLegalText = 'buttonCloseLegalText';
		if (this.state.whichLegalTextIsRequired === 'ContactSupport') {
			buttonCloseLegalText = 'buttonCloseLegalTextSupport';
		}
		return(
			<div className={buttonCloseLegalText}>
				<Button className="buttonCloseLegalTextButton" block size="lg" onClick={() => {this.setState({whichLegalTextIsRequired: null})}}>
					{'X'}
				</Button>
			</div>
		)
	}

	CookiesMenuChangePreferences = () => {

		return [
			<div className='CookiesMenuChangePreferencesMyArea'>
				{this.cookiesMenuChangePreferencesTitle()}
				{this.cookiesMenuChangePreferencesMenu()}
				{this.buttonCookiesDetail()}
				{this.buttonAcceptCookies()}
			</div>
		]
	}


	buttonCookiesDetail = () => {
		return(
			<Button className="buttonCookiesDetailMyArea" onClick = {() => {this.setState({CookiesDetailsRequired: true})} } >
				{'Cookies policy'}
			</Button>
		)
	}

	cookiesMenuChangePreferencesTitle = () => {
		return(<div className='cookiesMenuChangePreferencesTitleMyArea'>{cookiesSummaryTitle}</div>)
	}

	cookiesMenuChangePreferencesMenu = () => {
		console.log(this.state.CookiesAccepted);
		return (
			<div className='cookiesMenuChangePreferencesMenuLandingPage'>
				<div className='cookiesMenuChangePreferencesMenuAreaLandingPage'>
					<li className='cookiesMenuChangePreferencesMenuItemMyArea'> Necessary cookies</li>
					<div className='cookiesMenuChangePreferencesMenuSpanMyArea'> {necessaryCookiesText}</div>
					<input className='toggleCookiesTechnicalMyArea' type='checkbox' defaultChecked={true} disabled={true} onChange={(event) => {this.cookiesToggleButtonLandingPage(event.target)}}/>
				</div>
				<div className='cookiesMenuChangePreferencesMenuAreaLandingPage'>
					<li className='cookiesMenuChangePreferencesMenuItemMyArea'> Analytics cookies</li>
					<div className='cookiesMenuChangePreferencesMenuSpanMyArea'> {analyticsCookiesText}</div>
					<input className='toggleCookiesMyArea' type='checkbox' defaultChecked={this.state.CookiesAccepted.Analytics} onChange={(event) => {this.cookiesToggleButtonLandingPage(event.target, 'Analytical')}}/>
				</div>
			</div>
		)
	}


	cookiesToggleButtonLandingPage  = (e, cookiesType) => {


		if (cookiesType === 'Analytical') {
			if (this.state.CookiesAccepted['Analytics'] === true) {
				this.setState(
					Object.assign(this.state.CookiesAccepted, {Analytics: false}));
			} else {
				this.setState(
					Object.assign(this.state.CookiesAccepted, {Analytics: true}));
			}
		}

		return null;
	}


	// buttonAcceptCookies = () => {
	//
	// 	return(
	// 		<Button className="buttonAcceptCookiesMyArea" onClick = {() => {this.manageCookiesPreferences()}}>
	// 			{'Accept and close'}
	// 		</Button>
	// 	)
	//
	// }

	buttonAcceptCookies = () => {

		return(
			<div className='buttonAcceptCookiesDiv'>
				<Button className="ContainerCookiesPolicyButtonRejectAllCMPMyArea" onClick = {() => {this.ContainerCookiesPolicyFunction('Reject')}}>
					{'Decline all'}
				</Button>
				<Button className="buttonAcceptCookiesMyArea" onClick = {() => {this.manageCookiesPreferences()}}>
					{'Accept and close'}
				</Button>
			</div>
		)
	}

	ContainerCookiesPolicyFunction = (cookiesCode) => {

		if (cookiesCode === 'Accept') {
			this.setState(
				Object.assign(this.state.CookiesAccepted, {Analytics: true}), () => {this.manageCookiesPreferences()});


			this.setState({CookiesHaveBeenAccepted: true});
			return null;
		}

		if (cookiesCode === 'Reject') {
			this.setState(
				Object.assign(this.state.CookiesAccepted, {Analytics: false}), () => {this.manageCookiesPreferences()});

			this.setState({CookiesHaveBeenAccepted: true});
			return null;
		}

		if (cookiesCode === 'ChangePreferences') {
			this.setState({CookiesMenuChangePreferences: true});
			return null;
		}

		if (cookiesCode === 'CookiesPolicyRequired') {
			this.setState({CookiesPolicyRequired: true});
			return null;
		}

	}

	manageCookiesPreferences = () => {
		// This function closes the dialogs of the cookies acceptance, retrieves the cookies information in case of
		// acceptance and sends the information to Python.
		console.log(this.state.CookiesAccepted);


		if (this.state.CookiesAccepted['Analytics'] === true) {
			// Assign the localStorage variable's value
			set_analytics_acceptance_cookie(true);
			// Retrieve the user's geography and store it in DB.
			wrapper_send_all_cookies_after_cookies_preferences_made();

		} else {
			// Assign the localStorage variable's value
			set_analytics_acceptance_cookie(false);
			// Store in DB that the user has declined analytics cookies.
			wrapper_send_all_cookies_after_cookies_preferences_made();
		}

		// Close the cookies dialog.
		this.setState({whichLegalTextIsRequired: null})

		return null;
	}

	updatePageAfterRequestIsSuccessful = () => {

		this.resetProfileAndErrorsInBackground(false);

		this.setState({PrimaryStatus: this.state.PrimaryCode.Load});
		return null;
	}

	takeUserBackToLogInIfApplicable = () => {

		let UserID = localStorage.getItem('User_ID');
		let User_type = getUserType();

		if (UserID === null || UserID === undefined || User_type === null || User_type === undefined) {
			// Change to LandingPage
			let URL_LP = this.state.URL_LogIn;
			return <Redirect to={URL_LP}/>;
		} else {
			this.setState({PrimaryStatus: this.state.PrimaryCode.JWT})
		}
		return null;
	}


	WaitingForRequest = () => {

		if (this.state.WaitingForRequest === true) {
			return (
				<div className='loading'>
					<img className="loading-image" src={loadingPictureCode} />
				</div>
			)
		} else {return null;}
	}


	errorWhenLoading = () => {

		if (this.state.ErrorMessage === 'Retrieve_my_historic_operations_1' || this.state.ErrorMessage === 'Retrieve_my_profile_1') {
			console.log('holi')
			return (
				<div className='errorWhenLoading'>
					<div className='errorWhenLoadingText'> An error occurred. Please, contact support  </div>
				</div>
			)
		} else {
			return null;
		}
	}

	render() {
		if (this.state.Page==="MyArea") {

			if (this.state.PrimaryStatus === this.state.PrimaryCode.IsUserSignedIn){
				// Evaluate if user should be redirected to LogIn page.
				return [<div className='BackgroundMyProfile'/>, this.takeUserBackToLogInIfApplicable()];
			}

			if (this.state.PrimaryStatus === this.state.PrimaryCode.JWT){
				this.intermediateJWT();
				return <div className='BackgroundMyProfile'/>;
			}

			if (this.state.PrimaryStatus === this.state.PrimaryCode.Load){
				this.previousToRender();
				return [<div className='BackgroundMyProfile'/>,
					this.errorWhenLoading()
				]
			}
			if (this.state.PrimaryStatus === this.state.PrimaryCode.DataPreparation){
				this.dataPreparation();
				return <div className='BackgroundMyProfile'/>;
			}
			if (this.state.PrimaryStatus === this.state.PrimaryCode.IntermediateToRemoveLocalStorage){
				remove_profile_and_operations_from_localstorage();
				this.setState({PrimaryStatus: this.state.PrimaryCode.Company});
				return <div className='BackgroundMyProfile'/>;
			}
			if ( (this.state.PrimaryStatus !== this.state.PrimaryCode.Load) && (this.state.PrimaryStatus !== this.state.PrimaryCode.DataPreparation) ) {
				// console.log(this.state.CompanyErrors);
				return[
					// <div className='BackgroundMyProfile'/>,
					this.WaitingForRequest(),
					<div className = "My_profile_general_container">
						{this.HeaderLandingPage()}
						{this.containerPage()}
						{this.ObscurerMyProfile()}
						{this.ObscurerDeletion()}
						{this.legalTextContainers()}
						{this.showLegalText()}
						{this.showCookiesDetails()}
					</div>
				]
			}
		}
	}
}


export default RenderMyArea;