import {postData} from "../apis/rest_api"
import {get_Session_ID_cookies} from "./Session_cookies"
import {getUserType, isUserACompany, isUserAnIndividual, isUserAPSI} from "../common/utilities"

async function create_json_my_profile(old_company, old_person, old_employees, old_shops, old_settings, new_company,
									  new_person, new_employees, new_shops, new_settings, Coming_from_sign_up, newEmail) {
	var ID = 153;
	
	console.log(old_company);
	console.log(old_person);
	console.log(old_employees);
	console.log(old_shops);
	//
	console.log(new_company);
	console.log(new_person);
	console.log(new_employees);
	//console.log(new_employees[0]);
	console.log(new_shops);
	console.log(Coming_from_sign_up);
	
	var final_company = [];
	var final_employees = [];
	var final_shops = [];
	
	var objeto_json_employees = null;
	var objeto_json_shops = null;
	var objeto_json_company = null;
	var objeto_json_person = null;
	
	
	if (Coming_from_sign_up === true) {
		// We are creating a new account.
		final_company = new_company;
		final_employees = new_employees;
		final_shops = new_shops;
		
		if (final_employees=== undefined || final_employees=== null) {
			
		} else {
			objeto_json_employees = sub_json_employees(final_employees);
		}
		
		if (final_shops=== undefined || final_shops=== null) {
			
		} else {
			objeto_json_shops = sub_json_shops(final_shops);
		}
		
	}
	if (Coming_from_sign_up !== true && (isUserACompany() || isUserAPSI())){
		
		final_company = validate_company(old_company, new_company, Coming_from_sign_up);
		
		if (isUserACompany()) {
			final_employees = validate_employees(old_employees, new_employees);
			final_shops = validate_shops(old_shops, new_shops);

			objeto_json_employees = sub_json_employees(final_employees);
			//console.log(objeto_json_employees);

			objeto_json_shops = sub_json_shops(final_shops);
			//console.log(objeto_json_shops);
		} else {
			objeto_json_employees = null;
			objeto_json_shops = null;
		}
	}
	
	if (!isUserAnIndividual()) {
		objeto_json_company = sub_json_company(final_company, newEmail);
		
		objeto_json_person = null;
	} else {
		objeto_json_company = null;
		// In company 
		objeto_json_person = sub_json_person(old_person, new_person, Coming_from_sign_up, newEmail);
	}

	var objeto_json_contenido = { 
		"Update_my_profile_input": {
			"Company": objeto_json_company,
			"Employees": objeto_json_employees,
			"Shops": objeto_json_shops,
			"Person": objeto_json_person,
			"Update_number": update_Update_number(),
			"New_profile": detect_new_profile()
		},
		"User_type": getUserType(),
		"User_ID": +localStorage.getItem('User_ID')
	};
	var objeto_json = {
		"ID": ID,
		"contenido": objeto_json_contenido,
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};
	
	console.log(objeto_json);
	
	
	let response_Update_my_profile = await postData(objeto_json);

	console.log(response_Update_my_profile);

	return response_Update_my_profile;

	// return null;
	
}

function detect_new_profile(old_company, old_person, old_employees, old_shops, old_settings) {

	console.log(old_company);
	console.log(old_person);
	console.log(old_employees);
	console.log(old_shops);
	console.log(old_settings);

	return (old_company === null || old_company === undefined) && (old_person === null || old_person === undefined)
		&& (old_employees === null || old_employees === undefined)  && (old_shops === null || old_shops === undefined)
		&& (old_settings === null || old_settings === undefined) ;

}

function update_Update_number() {

	let PUN = '0';
	 if (localStorage.getItem('PUN') === undefined) {
	 	PUN = '1';
		 localStorage.setItem('PUN', PUN);
	 } else {
		 PUN = localStorage.getItem('PUN')
	 }
	 return PUN
}




function validate_shops(old_shops, new_shops) {
	//console.log(old_shops);
	//console.log(new_shops);
	
	// Prepare the initial array (the last row is always redundant)
	var actual_shops= [];
	
	//Remove the last element on new_shops array
	for (var j=0; j< +new_shops.length -1; j++) {
		console.log(new_shops);
			actual_shops.push(new_shops[j]);
	}
	
	console.log(actual_shops);
	
	
	var result_ = [];
	
	const limit_to_old_shops = old_shops.length;
	
	for (var i=0; i< actual_shops.length; i++) {
		var result = {};
		if (new_shops[i].Address === undefined && i < limit_to_old_shops) {
			result['Address'] = old_shops[i].Address;
		} else {
			result['Address'] = new_shops[i].Address;
		}

		if (new_shops[i].Country === undefined && i < limit_to_old_shops) {
			result['Country'] = old_shops[i].Country;
		} else {
			result['Country'] = new_shops[i].Country;
		}

		if (new_shops[i].Province === undefined && i < limit_to_old_shops) {
			result['Province'] = old_shops[i].Province;
		} else {
			result['Province'] = new_shops[i].Province;
		}

		if (new_shops[i].ID === undefined && i < limit_to_old_shops) {
			//console.log(old_shops[i]);
			result['ID'] = old_shops[i].ID;
		} else {
			result['ID'] = new_shops[i].ID;
		}

		if (new_shops[i].Region === undefined && i < limit_to_old_shops) {
			result['Region'] = old_shops[i].Region;
		} else {
			result['Region'] = new_shops[i].Region;
		}

		if (new_shops[i].Phone_number === undefined && i < limit_to_old_shops) {
			result['Phone_number'] = old_shops[i].Phone_number;
		} else {
			result['Phone_number'] = new_shops[i].Phone_number;
		}

		if (new_shops[i].Name === undefined && i < limit_to_old_shops) {
			result['Name'] = old_shops[i].Name;
		} else {
			result['Name'] = new_shops[i].Name;
		}


		console.log(result);
		if (!(result['Address'] === null && result['Country'] === null && result['Province'] === null &&
			result['Region'] === null && result['Phone_number'] === null && result['Name'] === null) ) {
			result_.push(result);
		}
	}
	
	console.log(result_);
	
	return result_;
}


function validate_employees(old_employees, new_employees) {
	/**
	 * This function takes the old employees as a base and decides whether a new field has been provided in
	 * new_employees. If there's a new field, it substitutes the old_employees' correspondent field by the new
	 * information. Also appens newly-joined employees' data and allows for employees' deletion.
	 * @param old_employees - the old employees' information
	 * @param new_employees - the new employees' information
	 * @returns array - with all the relevant information after updating, removing or adding fields.
	 */


	//console.log(old_employees);
	//console.log(new_employees);
	
	// Prepare the initial array (the last row is always redundant)
	var actual_employees= [];
	
	//Remove the last element on new_employees array
	for (var j=0; j< +new_employees.length -1; j++) {
		//if (new_employee.Name !== 0 && new_employee.Name !== null && new_employee.Name !== "" && new_employee !== {}) {
		actual_employees.push(new_employees[j]);
	}
	
	//console.log(actual_employees);
	
	
	var result_ = [];
	
	const limit_to_old_employees = old_employees.length;
	
	for (var i=0; i< actual_employees.length; i++) {
		var result = {};
		//console.log(new_employees[i].Name);
		if (new_employees[i].Name === undefined && i < limit_to_old_employees) {
			result['Name'] = old_employees[i].Name;
		} else {
			result['Name'] = new_employees[i].Name;
		}

		if (new_employees[i].Surname === undefined && i < limit_to_old_employees) {
			result['Surname'] = old_employees[i].Surname;
		} else {
			result['Surname'] = new_employees[i].Surname;
		}

		if (new_employees[i].Email_address === undefined && i < limit_to_old_employees) {
			result['Email_address'] = old_employees[i].Email_address;
		} else {
			result['Email_address'] = new_employees[i].Email_address;
		}

		if (new_employees[i].ID === undefined && i < limit_to_old_employees) {
			//console.log(old_employees[i]);
			result['ID'] = old_employees[i].ID;
		} else {
			result['ID'] = new_employees[i].ID;
		}

		if (new_employees[i].Role === undefined && i < limit_to_old_employees) {
			result['Role'] = old_employees[i].Role;
		} else {
			result['Role'] = new_employees[i].Role;
		}

		if (new_employees[i].Treatment === undefined && i < limit_to_old_employees) {
			result['Treatment'] = old_employees[i].Treatment;
		} else {
			result['Treatment'] = new_employees[i].Treatment;
		}
		console.log(result);
		if (!(result['Name'] === null && result['Surname'] === null && result['Email_address'] === null &&
			result['Role'] === null && result['Treatment'] === '(Empty)') ) {
		result_.push(result);
	}
	}
	
	console.log(result_);
	
	return result_;
}


function validate_company(old_company, new_company, Coming_from_sign_up) {
	console.log(old_company);
	console.log(new_company);
	
	var result = {};
	
	if (new_company.Name === undefined) {
		result['Name'] = old_company.Name;
	} else {
		result['Name'] = new_company.Name;
	}
	
	if (new_company.Address === undefined) {
		result['Address'] = old_company.Address;
	} else {
		result['Address'] = new_company.Address;
	}

	console.log(new_company.Email);
	console.log(Coming_from_sign_up  === true);
	console.log(Coming_from_sign_up);

	if (new_company.Email === undefined && Coming_from_sign_up  === true) {

		result['Email'] = null;
	} else {
		if (new_company.Email === undefined && Coming_from_sign_up  === false) {
			result['Email'] = old_company.Email;
		}  else {
			result['Email'] = new_company.Email;
		}
	}
	
	if (new_company.Province === undefined) {
		result['Province'] = old_company.Province;
	} else {
		result['Province'] = new_company.Province;
	}
	
	if (new_company.Region === undefined) {
		result['Region'] = old_company.Region;
	} else {
		result['Region'] = new_company.Region;
	}
	
	if (new_company.PostalCode === undefined) {
		result['Postal_code'] = old_company.PostalCode;
	} else {
		result['Postal_code'] = new_company.PostalCode;
	}
	
	if (new_company.Country === undefined) {
		result['Country'] = old_company.Country;
	} else {
		result['Country'] = new_company.Country;
	}

	if (new_company.Postal_code === undefined) {
		result['Postal_code'] = old_company.Postal_code;
	} else {
		result['Postal_code'] = new_company.Postal_code;
	}

	if (new_company.Currency === undefined) {
		result['Currency'] = old_company.Currency;
	} else {
		result['Currency'] = new_company.Currency;
	}
	
	if (new_company.Sector === undefined) {
		result['Sector'] = old_company.Sector;
	} else {
		result['Sector'] = new_company.Sector;
	}
	
	if (new_company.Subsector === undefined) {
		result['Subsector'] = old_company.Subsector;
	} else {
		result['Subsector'] = new_company.Subsector;
	}
	
	if (new_company.TFN === undefined) {
		result['TFN'] = old_company.TFN;
	} else {
		result['TFN'] = new_company.TFN;
	}
	
	if (new_company.Phone_number === undefined) {
		result['Phone_number'] = old_company.Phone_number;
	} else {
		result['Phone_number'] = new_company.Phone_number;
	}
	
	if (new_company.Phone_prefix === undefined) {
		result['Phone_prefix'] = old_company.Phone_prefix;
	} else {
		result['Phone_prefix'] = new_company.Phone_prefix;
	}
	
	
	// console.log(result);
	
	
	return result;
}



function sub_json_company(new_company, newEmail) {
	
	//console.log(new_company);
	let Email = new_company.Email;
	if (newEmail !== null) {
		Email = newEmail;
	}
	
	var objeto_json_contenido = { 
			"Name": new_company.Name,
			"Address": new_company.Address,
			"Email": Email,
			"Province": new_company.Province,
			"Region": new_company.Region,
			"Postal_code": new_company.Postal_code,
			"Country": new_company.Country,
			"Currency": new_company.Currency,
			"Sector": new_company.Sector,
			"Subsector": new_company.Subsector,
			"TFN": new_company.TFN,
			"Phone_number": new_company.Phone_number,
			"Phone_prefix": new_company.Phone_prefix
	};
	
	//console.log(objeto_json_contenido);
	
	return objeto_json_contenido;
}

function sub_json_person(old_person, new_person, Coming_from_sign_up, newEmail) {

	// Phone
	let Email = new_person.Email;

	if (newEmail !== null) {
		Email = newEmail;
	}

	var result = {};

	if (Coming_from_sign_up === true) {

		result['IP']= localStorage.getItem('IP_Address');
		result['Nickname'] = new_person.Nickname;
		result['Birth_date']= new_person.Birth_date;
		result['Genre']= new_person.Genre;
		result['Phone_number']= new_person.Phone_number;
		result['Phone_prefix']= new_person.Phone_prefix;
		result['Postal_code']= new_person.Postal_code;
		result['Email']= Email;

	} else {

		if (new_person.IP === undefined) {
			if (old_person.IP === undefined) {
				result['IP'] = null;
			} else {
				result['IP'] = old_person.IP;
			}
		} else {
			result['IP'] = new_person.IP;
		}
		if (new_person.Nickname === undefined) {
			result['Nickname'] = old_person.Nickname;
		} else {
			result['Nickname'] = new_person.Nickname;
		}
		if (new_person.Birth_date === undefined) {
			result['Birth_date'] = old_person.Birth_date;
		} else {
			result['Birth_date'] = new_person.Birth_date;
		}
		if (new_person.Genre === undefined) {
			result['Genre'] = old_person.Genre;
		} else {
			result['Genre'] = new_person.Genre;
		}
		if (new_person.Phone_number === undefined) {
			result['Phone_number'] = old_person.Phone_number;
		} else {
			result['Phone_number'] = new_person.Phone_number;
		}
		if (new_person.Phone_prefix === undefined) {
			result['Phone_prefix'] = old_person.Phone_prefix;
		} else {
			result['Phone_prefix'] = new_person.Phone_prefix;
		}
		if (new_person.Postal_code === undefined) {
			result['Postal_code'] = old_person.Postal_code;
		} else {
			result['Postal_code'] = new_person.Postal_code;
		}
		result['Email'] = Email;

	}
	
	//console.log(result);
	
	return result;
}

function sub_json_employees(new_employees) {
	
	console.log(new_employees);
	var actual_employees = new_employees;

	var employees_json = [];
	
	for (var i = 0; i < actual_employees.length; i++) {
		var Email = actual_employees[i].Email;
		if (Email === undefined) {
			Email = actual_employees[i].Email_address
		}
		var objeto_json_contenido = { 
				"Name": actual_employees[i].Name,
				"Surname": actual_employees[i].Surname,
				"Treatment": actual_employees[i].Treatment,
				"Role": actual_employees[i].Role,
				"Email_address": Email
		};
		employees_json.push(objeto_json_contenido);
	}
	//console.log(employees_json);
	
	return employees_json;
}

function sub_json_shops(new_shops) {
	
	//console.log(new_shops);
	
	// Prepare the initial array (the last row is always redundant)
	var actual_shops= new_shops;
	
	// new_shops.forEach(new_shop => {
	// 	if (new_shop.Address !== 0 && new_shop.Address !== null && new_shop.Address !== "") {
	// 		actual_shops.push(new_shop);
	// 	}
	// })
	
	//console.log(actual_shops);
	
	var shops_json = [];
	
	for (var i = 0; i < actual_shops.length; i++) {
		var objeto_json_contenido = { 
			"Address": actual_shops[i].Address,
			"Country": actual_shops[i].Country,
			"Province": actual_shops[i].Province,
			"Region": actual_shops[i].Region,
			"Name": actual_shops[i].Name,
			"Phone_number": actual_shops[i].Phone_number
		};
		shops_json.push(objeto_json_contenido);
	}
	//console.log(shops_json);
	
	return shops_json;
}


async function get_my_profile() {
	var ID = 152;
	
	var objeto_json = {
		"ID": ID,
		"contenido": {
			"User_type": getUserType(),
			"User_ID": +localStorage.getItem('User_ID')
		},
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};
	
	//console.log(objeto_json);


	let response_Retrieve_my_profile = await postData(objeto_json);

	console.log(response_Retrieve_my_profile);
	// localStorage.setItem('PUN', response_Retrieve_my_profile.UPDATE_NUMBER)

	return response_Retrieve_my_profile;
	
	
}


export {create_json_my_profile, get_my_profile};