import {judge_are_geography_cookies_allowed} from "../functions/Session_cookies";
import {wrapper_send_permanent_cookie, get_analytics_acceptance_cookie} from "../functions/Permanent_cookies";
import {loadingPictureCode} from "./ajax-loader";
import React from "react";


function email_validation(email) {

    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}

function email_validation_or_empty(email) {

    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email) || email === '';
}


function validate_new_password(new_contra, repeat) {

    return new_contra === repeat;
}

function validate_password_format(str)
{
    // Validates there is one digit, one special character (!@#$%^&*) and between 8 and 16 characters overall.
    var re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    return re.test(str);
}


function validate_integers(number) {

    let re = /^[0-9\b]+$/;

    return re.test(number);
}

function validate_positive_integers(number) {

    let re = /^[0-9\b]+$/;

    return re.test(number) && number>0;
}


function validate_integers_or_null(number) {

    let re = /^[0-9\b]+$/;

    return re.test(number) || number === '';
}

function validate_positive_integers_or_null(number) {

    let re = /^[0-9\b]+$/;
    // console.log( (re.test(number) && number>0) || number === '')
    // console.log(number === '')

    return (re.test(number) && number>0) || number === '';
}


function validate_characters(characters) {

    let re = /^[aA-zZ\s]+$/;

    return re.test(characters);
}

function validate_string_is_not_empty_or_null(characters) {

    return characters !== null && characters !== '';
}

function validate_string_is_not_empty_or_null_treatment(characters) {

    return characters !== null && characters !== '' && characters !== ' ';
}

function validate_value_is_neither_empty_nor_null_nor_undefined(characters) {

    return characters !== null && characters !== '' && characters !== undefined;
}


function validate_float(float) {

    return Number(float) === float && float % 1 !== 0;
}

function validate_float_or_empty(float) {

    return (!isNaN(float)) || float === '';
}

function validate_number(float) {

    return !isNaN(float) && float !== '';
}

function validate_number_non_empty_non_Null(float) {

    return !isNaN(float) && float !== '' && float !== null;
}

function validate_pure_float(float) {
    return Number(float) === +float;
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function remove_profile_and_operations_from_localstorage() {

    // Removes those 2 (key, pair) values from the localStorage

    localStorage.removeItem('Profile_retrieved');
    localStorage.removeItem('Operations_output');

    return null;
}

// function return_current_datetime() {
//
//     var currentdate = new Date();
//
//     var timestamp = currentdate.getDate() + "/"
//         + (currentdate.getMonth()+1)  + "/"
//         + currentdate.getFullYear() + " "
//         + currentdate.getHours() + ":"
//         + currentdate.getMinutes() + ":"
//         + currentdate.getSeconds();
//
//     return timestamp;
// }

function are_cookies_preferences_made() {

    // Returns true if the selection to allow or decline analytical cookies has been made.

    return get_analytics_acceptance_cookie() === 'true' || get_analytics_acceptance_cookie() === 'false';

}


function wrapper_send_all_cookies_after_cookies_preferences_made() {
    // Wraps the function to evaluate if the geography cookies are allowed, send them in case yes and send the new cookies preferences made on the permanent cookie.

    judge_are_geography_cookies_allowed();
    wrapper_send_permanent_cookie();

    return null;
}


function return_null_if_empty(value) {
    // Transforms the input to null in case it's empty
    if (value === '') {
        return null;
    } else {
        return value;
    }
}

function removeUserTypeAndUserID() {

    localStorage.removeItem('User_type');
    localStorage.removeItem('User_ID');
    localStorage.removeItem('jwt');
    localStorage.removeItem('jwt_expiration');

    return null;
}

function clearCookiesOnLogOut() {

    // Stop requesting the JWT
    clearInterval(localStorage.getItem('Interval_ID'));

    // Remove the user identification localStorage.
    removeUserTypeAndUserID();
    localStorage.removeItem('PUN');
    localStorage.removeItem('Interval_ID');
    dont_remember_me_anymore();

    return null;
}


function dont_remember_me_anymore() {

    // Remove the local storage item that keeps remembering the user always.
    localStorage.removeItem('RememberMe');

    return null;
}

function parseDateTimeFromPython(dateString) {

    let day = dateString.substr(8, 2);
    let month = dateString.substr(5, 2);
    let year = dateString.substr(0, 4);
    let hour = dateString.substr(11, 2);
    let minute = dateString.substr(14, 2);
    let second = dateString.substr(17, 2);
    let time = dateString.substr(11, 8);
    // console.log(day)
    // console.log(month)
    // console.log(year)
    // console.log(hour)
    // console.log(minute)
    // console.log(second)
    // console.log(time)
    return day + '/' + month + '/' + year + ' ' + time;
}

function parseOnlyDateFromPython(dateString) {
    if (dateString !== null) {
        let day = dateString.substr(8, 2);
        let month = dateString.substr(5, 2);
        let year = dateString.substr(0, 4);
        // console.log(day + '/' + month + '/' + year)

        return day + '/' + month + '/' + year;
    } else { return null;}
}

function parseISODate(dateString) {
    var match = /^(\d{4})-(\d\d)-(\d\d)/.exec(dateString);
    var time = dateString.substring(11, 19);
    var fecha = new Date(Number(match[1]), Number(match[2]) - 1, Number(match[3]));
    var nueva_fecha = fecha.getDate() + '/' + fecha.getMonth() + 1 + '/' + fecha.getFullYear() + ' ' + time;
    return nueva_fecha;
}



function calculatorOfSinglePrice(price, pricewovat, vat, Units, Discount) {

    var retorno = 0;

    if (Discount === null) { Discount = 0}

    if (price === null || price === undefined || price === 0 || price === '' || price === "0") {
        retorno = Number(pricewovat)* (1+ vat/100) *Number(Units)*(100-Number(Discount))/100;
    } else {
        retorno = Number(price) * Number(Units)*(100-Number(Discount))/100;
    }
    if (price === null && pricewovat === null) {
        // Row recently created
        retorno = 0;
    }

    const rounded = Math.round((retorno + Number.EPSILON) * 100) / 100;

    return rounded.toFixed(2);
}


function calculateTaxesInSuboperationForTotals (Price, Price_without_VAT, VAT, Units) {

    //Evaluates if the taxes are already included in the subtotal or not - if Price if given, tax is included, don't count it for Totals.
    var taxForTotals = 0;
    const Vat_Percentage = VAT / 100;
    const price = Number(Price);
    const pricewovat = Number(Price_without_VAT);
    const units = Number(Units);

    if (Price_without_VAT === null || Price_without_VAT === undefined || Price_without_VAT === 0 ||
        Price_without_VAT === '' || Price_without_VAT === "0") {
        // The price without VAT is not provided

        if (Price === null || Price === undefined || Price === 0 ||
            Price === '' || Price === "0") {
            // The price is not provided either => Empty row in the Sales table
            taxForTotals = 0;
            // The price without VAT is not provided
        } else {
            taxForTotals = 0;
        }

    } else {
        // The price without VAT is provided
        taxForTotals = pricewovat * Vat_Percentage * units;
    }

    return taxForTotals;
}


function calculatorOfSingleRowTotalsHistoricOperationsTable (price, pricewovat, vat, Units, Discount) {

    let subtotal = calculatorOfSinglePrice(price, pricewovat, vat, Units, Discount);
    let taxes = calculateTaxesInSuboperationForTotals(price, pricewovat, vat, Units);

    if (pricewovat === null || pricewovat === undefined || pricewovat === 0 ||
        pricewovat === '' || pricewovat === "0") {
        subtotal += taxes
    }
    return +subtotal;
}


function calculateTaxesInSuboperation (Price, Price_without_VAT, VAT, Units) {
    var tax = 0;
    const Vat_Percentage = VAT / 100;
    const price = Number(Price);
    const pricewovat = Number(Price_without_VAT);
    const units = Number(Units);

    if (Price_without_VAT === null || Price_without_VAT === undefined || Price_without_VAT === 0 ||
        Price_without_VAT === '' || Price_without_VAT === "0") {
        // The price without VAT is not provided

        if (Price === null || Price === undefined || Price === 0 ||
            Price === '' || Price === "0") {
            // The price is not provided either => Empty row in the Sales table
            tax = 0;
            // The price without VAT is not provided
        } else {
            tax = +price * (Vat_Percentage / (+1 + +Vat_Percentage)) * (+units);
        }

    } else {
        // The price without VAT is provided
        tax = pricewovat * Vat_Percentage * units;
    }

    return tax;
}


function check_no_product_has_been_selected_on_change_ticket(changes_chosen) {

    let check = true;

    for (let step = 0; step < changes_chosen.length; step++) {
        if (!(changes_chosen[step].Units_to_change === null && changes_chosen[step].Reason === null) ) {
            check = false
        }
    }

    return check;
}


function is_field_always_present_in_array(array, field) {

    // console.log(array);
    // console.log(array[0][field])

    let check = true;
    let limit = +array.length;
    if (limit > 1) {limit -= 1}

    for (let step = 0; step < limit; step++) {
        // console.log(array[step][field])
        if (!validate_string_is_not_empty_or_null(array[step][field]) ) {
            check = false
        }
    }

    // console.log(check);
    return check;

}

function validate_input_cell_is_not_empty_or_null(characters, index, length) {
    // console.log(index)
    // console.log(length)
    // console.log(+index === +length -1)
    if (+index === +length -1 && +length !== 1) {
        // console.log(index)

        return false;
    }
    // console.log(characters)
    // console.log( characters === null || characters === '')
    return characters === null || characters === '';
}




function reformat_datetime_to_US_format(dateString) {
    if (dateString !== null) {
        let day = dateString.substr(0, 2);
        let month = dateString.substr(3, 2);
        let year = dateString.substr(6, 4);
        // let hour = dateString.substr(11, 2);
        // let minute = dateString.substr(14, 2);
        // let second = dateString.substr(17, 2);
        let time = dateString.substr(11, );
        // console.log(hour)
        // console.log(minute)
        // console.log(second)
        // console.log(day)
        // console.log(month)
        // console.log(year)
        // console.log(month + '/' + day + '/' + year + ' ' + time)

        return month + '/' + day + '/' + year + ' ' + time;
    } else { return null;}
}


function getOperatingSystem() {

    let operatingSystem = 'Unknown'

    console.log(navigator.appVersion)
    console.log(navigator.platform)

    if (navigator.appVersion.indexOf('Win') !== -1) {
        operatingSystem = 'Windows OS';
    }
    if (navigator.appVersion.indexOf('Mac') !== -1) {
        operatingSystem = 'MacOS';
    }
    if (navigator.appVersion.indexOf('X11') !== -1) {
        operatingSystem = 'UNIX OS';
    }
    // if (navigator.appVersion.indexOf('Linux') !== -1) {
    //     operatingSystem = 'Linux OS';
    // }

    if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        operatingSystem = 'iPad';
    }
    // if (navigator.appVersion.indexOf('Safari') !== -1) {
    //     operatingSystem = 'MacOS';
    // }
    if (navigator.appVersion.indexOf('iPhone') !== -1) {
        operatingSystem = 'iPhone';
    }
    // if (navigator.appVersion.indexOf('iPad') !== -1) {
    //     operatingSystem = 'iPad';
    // }
    if (navigator.appVersion.indexOf('Android') !== -1) {
        operatingSystem = 'Android';
    }

    console.log(operatingSystem)

    return operatingSystem;
}


function isOperativeSystemAPhone() {

    console.log(getOperatingSystem())

    return getOperatingSystem() === 'Android' || getOperatingSystem() === 'iPhone' || getOperatingSystem() === 'iPad';
}


function isDeviceAMobile() {

    localStorage.setItem('Mobile', isOperativeSystemAPhone());
    return false;
}

function checkIfDeviceIsAMobile() {
    return localStorage.getItem('Mobile') === 'true';
}

function getUserType() {
    return parseInt(localStorage.getItem('User_type'));
}

function isUserACompany() {
    return getUserType() === 200;
}

function isUserAnIndividual() {
    return getUserType() === 100;
}

function isUserAPSI() {
    return getUserType() === 300;
}

function fixUserType(value) {
    localStorage.setItem('User_type', value);
    return null;
}

function fixUserTypeFromCompany(boolean) {
    if (boolean === true) {
        fixUserType(200)
    } else {fixUserType(100)}
    return null;
}

function fixUserTypeToPSI(boolean) {
    if (boolean === true) {
        fixUserType(300)
    } else {fixUserType(200)}
    return null;
}

function fixUserTypeToCompanyOnOpeningSignUp() {
    console.log('fixUserTypeToCompanyOnOpeningSignUp')
    fixUserType(200)
    return null;
}

function fixUserTypeToCompanyOnlyIfUserIsInSignUp() {
    if (window.location.pathname === '/SignUp') {
        fixUserTypeToCompanyOnOpeningSignUp()
    }
    return null;
}

function nonExistingUserType() {

    let user_type = getUserType();
    return user_type === null || user_type === undefined;
}

function getUserID() {
    return +localStorage.getItem('User_ID');
}

function getIPAddress() {
    return localStorage.getItem('IP_Address');
}

function getReceivedVia() {
    return window.localStorage.getItem('Received_via');
}

function getPSIID() {
    return localStorage.getItem('PSI');
}


function are_we_running_on_cordova_app() {
    console.log(window.cordova)
    return !window.cordova;
}

function classify_received_via() {

    let cordova = !are_we_running_on_cordova_app();
    console.log(cordova)
    let operativeSystem = getOperatingSystem();
    let ReceivedVia = null;

    if (operativeSystem === 'Windows OS') {
        ReceivedVia = 1;
    }
    if (operativeSystem === 'MacOS') {
        ReceivedVia = 2;
    }
    if (operativeSystem === 'UNIX OS') {
        ReceivedVia = 3;
    }
    if (operativeSystem === 'iPhone') {
        if (cordova === true) {
            ReceivedVia = 4;
        } else {
            ReceivedVia = 5;
        }
    }
    if (operativeSystem === 'iPad') {
        if (cordova === true) {
            ReceivedVia = 6;
        } else {
            ReceivedVia = 7;
        }
    }
    if (operativeSystem === 'Android') {
        if (cordova === true) {
            ReceivedVia = 8;
        } else {
            ReceivedVia = 9;
        }
    }

    window.localStorage.setItem('Received_via', ReceivedVia);
    return null;
}


function to_lower_letters(the_string) {
    // Transforms some string to lower letters - especially used in email address
    // console.log(the_string)
    // console.log(typeof the_string === 'string')
    if (the_string !== null && the_string !== undefined && typeof the_string === 'string') {
        return the_string.toLowerCase();
    } else {
        return the_string;
    }
}

function remove_spaces_at_start_or_end(the_string) {
    // Removes the spaces at the end of start of the string - especially used in email address
    // console.log(the_string)
    // console.log(typeof the_string === 'string')
    if (the_string !== null && the_string !== undefined && typeof the_string === 'string') {
        return the_string.trim();
    } else {
        return the_string;
    }
}

function format_email_address(the_string) {
    // Formats the string received as to what an email address would be expected to be - without spaces and in lower letters
    return remove_spaces_at_start_or_end(to_lower_letters(the_string))
}


export {validate_number, email_validation, validate_new_password, validate_integers, validate_characters,
    validate_float, validate_integers_or_null, email_validation_or_empty, validate_string_is_not_empty_or_null,
    validate_positive_integers_or_null, getCookie, validate_password_format, validate_float_or_empty,
    remove_profile_and_operations_from_localstorage, validate_value_is_neither_empty_nor_null_nor_undefined,
    are_cookies_preferences_made, wrapper_send_all_cookies_after_cookies_preferences_made,
    return_null_if_empty, clearCookiesOnLogOut, dont_remember_me_anymore, parseDateTimeFromPython,
    calculatorOfSinglePrice,
    calculateTaxesInSuboperationForTotals, calculatorOfSingleRowTotalsHistoricOperationsTable,
    calculateTaxesInSuboperation, check_no_product_has_been_selected_on_change_ticket, is_field_always_present_in_array,
    validate_input_cell_is_not_empty_or_null, validate_number_non_empty_non_Null, parseOnlyDateFromPython,
    reformat_datetime_to_US_format, validate_pure_float, validate_string_is_not_empty_or_null_treatment,
    removeUserTypeAndUserID, validate_positive_integers, getUserType, isUserACompany, fixUserType, fixUserTypeFromCompany,
    nonExistingUserType, getOperatingSystem, isOperativeSystemAPhone, isDeviceAMobile, checkIfDeviceIsAMobile,
    fixUserTypeToPSI, isUserAnIndividual, isUserAPSI, getUserID, getIPAddress, getReceivedVia, getPSIID,
    classify_received_via, fixUserTypeToCompanyOnOpeningSignUp, to_lower_letters, format_email_address,
    fixUserTypeToCompanyOnlyIfUserIsInSignUp
}