import {postData} from "../apis/rest_api";
import {get_Session_ID_cookies} from "./Session_cookies";
import {getUserType} from  "../common/utilities";

async function Resend_QR(Operation_ID, Requested_ID_Provider_Operation, NewEmailAddressQR) {

    // console.log(NewEmailAddressQR);
    // console.log(Operation_ID);

    var ID = 6;

    var objeto_json_contenido = {
        "Operation_ID": Operation_ID,
        "ID_Provider_Operation": Requested_ID_Provider_Operation,
        "New_email": NewEmailAddressQR
    };

    var objeto_json = {
        "ID": ID,
        "contenido": {
            "Resend_QR_data_input": objeto_json_contenido,
            "User_type": getUserType(),
            "User_ID": +localStorage.getItem('User_ID')
        },
        'Session_ID': get_Session_ID_cookies(),
        'IP_Address': localStorage.getItem('IP_Address'),
        "Received_via": window.localStorage.getItem('Received_via')
    };

    console.log(objeto_json);
    let response = await postData(objeto_json);

    console.log(response);
    return response;

}



export {Resend_QR};