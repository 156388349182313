let licenseApache2Text = <span>
> web-vitals: Copyright 2020 Google LLC<br/><br/>

> pyinstaller-hooks-contrib: Copyright 2021 PyInstaller Development Team<br/><br/>

> bcrypt: https://github.com/pyca/bcrypt<br/><br/>

> etelemetry: Copyright 2019, Senseable Intelligence Group<br/><br/>

> jwt: Copyright 2017 Gehirn Inc.<br/><br/>

> pymongo: © Copyright MongoDB, Inc. 2008-present<br/><br/>

> python-dateutil: Copyright 2017 - Paul Ganssle, Dateutil Contributors<br/><br/>

> requests: Copyright 2019 Kenneth Reitz<br/><br/>

> selenium: Copyright 2021 Software Freedom Conservancy + NOTICE<br/><br/>

> tornado: © Copyright The Tornado Authors Revision 2047e7ae<br/><br/>

LICENSE TEXT:<br/><br/>

Licensed under the Apache License, Version 2.0 (the "License"); you may not use this file except in compliance with the License. You may obtain a copy of the License at<br/><br/>

https://www.apache.org/licenses/LICENSE-2.0<br/><br/>

Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions and limitations under the License.<br/><br/>

</span>

export {licenseApache2Text}