import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Link, Redirect} from 'react-router-dom';
import "./SignUp.css";
import "../functions/Session_cookies";
import {create_json_sign_up_account} from "../functions/Sign_up_functions.js";
import {create_json_my_profile} from "../functions/My_profile";
// import Renderizar from "./LogIn";
import Dropdown from "react-bootstrap/Dropdown";
import file from "../content/Profile_Attributes";
import {
	email_validation,
	validate_integers_or_null,
	validate_new_password,
	validate_integers,
	email_validation_or_empty,
	validate_password_format,
	dont_remember_me_anymore,
	validate_string_is_not_empty_or_null,
	validate_string_is_not_empty_or_null_treatment,
	getUserType,
	isUserACompany,
	fixUserTypeFromCompany, fixUserTypeToPSI, isUserAnIndividual, isUserAPSI,
	format_email_address, fixUserTypeToCompanyOnlyIfUserIsInSignUp
} from "../common/utilities";
import {privacyPolicyText, privacyPolicyTitle} from "../texts/Privacy_Policy";
import {cookiesDetailText, cookiesDetailTitle} from "../texts/Cookies_details";
import {decideWhichGTCToDisplay} from "../texts/texts_utilities";
import {legalNoticeText, legalNoticeTitle} from "../texts/Legal_notice";
import {loadingPictureCode} from "../common/ajax-loader";
import {wrapper_send_permanent_cookie} from "../functions/Permanent_cookies";
import {wrapper_send_session_cookie} from "../functions/Session_cookies";
import {licensesIntroText, licensesTitle, renderLicensesButtons} from "../texts/Licenses";
import {licenseMITText} from "../texts/License_MIT";
import {licenseBSD2ext} from "../texts/License_BSD_2_Clause";
import {licenseApache2Text} from "../texts/License_Apache2_0";
import {licenseBSD3Text} from "../texts/License_BSD_3_Clause";
import {licenseISCText} from "../texts/License_ISC";
import {licensePostgreSQLText} from "../texts/License_PostgreSQL";
import {licensePythonSFText} from "../texts/License_Python_Software_Foundation";
import {licenseUnlicenseText} from "../texts/License_The_Unlicense";
import {licenseZopePLText} from "../texts/License_Zope_Public_License";
import {Table} from "react-bootstrap";
import {analyticsCookiesHeader, analyticsCookiesTable, technicalCookiesTable} from "../texts/Cookies_tables";
import EyeSlashed from "../pictures/EyeSlashed.png";
import EyeOpened from "../pictures/EyeOpened.png";

// // Fix the user is a company every time the user is opening the page.
fixUserTypeToCompanyOnlyIfUserIsInSignUp();

class SignUpClass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// Need: "0",
			Need: "0",
			NeedCodes: {Landing: "0", ValidateCode: "1", NewCode: "2", MyProfile: "3", Employees: "4", New_shops: "5",
				NonCritical: "6", Critical: "7", Complete: "8"},
			Secondary: "0",
			Email:"",
			Contra: null,
			RepeatContra: "",
			LP: "./MyArea",
			LI: "./",
			ChangePage: '',
			checkboxCompanyDefault: false,
			checkboxTACDefault: false,
			// checkboxCompany: false,
			checkboxCompany: true,
			checkboxPSI: false,
			checkboxTAC: false,
			Verification_code: null,
			New_code: false,
			ErrorCode: 0,
			CompanyProfile: {Company: {'Name': null, 'Email': null, 'Address': null, 'Province': null, 'Region': null, 'Country': null, 'Currency': null, 'Sector': null, 'Subsector': null, 'Phone_prefix': null, 'Phone_number': null, 'TFN': null, 'Postal_code': null},
				Employees: [{ID: 0, Name: null, Surname: null, Role: null,  Email: null, Treatment:' '}],
				New_shops: [{ID: 0, Name: null, Address: null, Country: null, Region: null, Province: null, Phone_number: null}]},
			CompanyProfileErrors: {Company: {'Name': false, 'Email': false, 'Address': false, 'Country': false, 'Region': false, 'Province': false, 'Currency': false, 'Sector': false, 'Subsector': false, 'Phone_prefix': false, 'Phone_number': false, 'TFN': false, 'Postal_code': false},
				Employees: [{Name: false, Surname: false, Role: false,  Email: false, Treatment: false}],
				New_shops: [{Name: false, Address: false, Country: false, Region: false, Province: false, Phone_number: false}]},
			CompanyHighLevelError: {Company: false, Employees:{Name: false, Email: false}, New_shops: {Name: false, Phone_number: false}, Client: false},
			PSIProfile: {Company: {'Name': null, 'Email': null, 'Address': null, 'Province': null, 'Region': null, 'Country': null, 'Currency': null, 'Phone_prefix': null, 'Phone_number': null, 'TFN': null, 'Postal_code': null}},
			PSIProfileErrors: {Company: {'Name': false, 'Email': false, 'Address': false, 'Country': false, 'Region': false, 'Province': false, 'Currency': false, 'Phone_prefix': false, 'Phone_number': false, 'TFN': false, 'Postal_code': false}},
			PersonProfile: {IP: 'IP', Nickname: null, Genre: null, Phone_number: null,
				Phone_prefix: null, Postal_code: null, Email: null, Birth_date: null},
			EmployeesTableHeader: {'No.': 0, Treatment: 0, Name: 0, Surname: 0, Role: 0, 'Email address': 0},
			ShopsTableHeader: {'No.': 0, Name: 0, Address: 0, Country: 0, Region: 0, Province: 0, 'Phone number':0},
			Free_employee : [{ID: 0, Name: null, Surname: null, Role: null,  Email: null, Treatment:null}],
			Free_shop: [{ID: 0, Address: null, Country: null, Province: null, Region: null}],
			Need_for: ['Code_validation'],
			Coming_from_Log_In: false,
			TermsAndConditionsRequired: 'false',
			Genres: [false, false, false],
			InputField: 'None',
			popupConfirmSaveAndContinue: "False",
			popupCodeInLandingPage: {'Critical': 1, 'Non-critical': 2},
			hasUserEnteredAllRequiredFields: false,
			popupUserMissingInformation: false,
			ErrorMessage: null,
			PrivacyPolicy: false,
			GeneralTermsAndConditions: false,
			legalText: null,
			whichLegalTextIsRequired: null,
			WaitingForRequest: false,
			analyticsCookiesHeader: analyticsCookiesHeader,
			analyticsCookiesBody: analyticsCookiesTable,
			technicalCookiesBody: technicalCookiesTable,
			passwordTypes: {input1: 'password', input2: 'password'}
		};
	}

	prevalidation = () => {
		// If we have come from Log_in service (use case: the user has created the account but not validated it), we
		// need to go directly to the code_validation screen
		// console.log(this.props);
		if (this.props.location.Need_for === this.state.Need_for[0]) {
			this.setState({Coming_from_Log_In: true});
			this.setState({Need: this.state.NeedCodes.ValidateCode});
			this.setState({checkboxCompany: this.props.location.props.data[1]});
			this.setState({Email: format_email_address(this.props.location.props.data[0]) });

			return null;
		}
	}


	SignUpTitle = () => {
		return (
			<div className="Sign_up_title_container">
				<span className='Sign_up_title_text'>{'TIDI'}</span>
			</div>
		);
	}

	SignUpTitleBackButton = () => {
		if (this.state.Need === this.state.NeedCodes.Landing || (this.state.Need === this.state.NeedCodes.MyProfile //&& this.state.checkboxCompany === false
		) || (this.state.Coming_from_Log_In === true && this.state.Need === this.state.NeedCodes.ValidateCode) ) {
			return (
				<div className="Sign_up_back_title_container"/>
			);
		} else {

			return (
				<div className="Sign_up_back_title_container">
					<Button className="Back_button_sign_up" block size="lg"
							onClick={() => this.deciderWhichScreenToReturnTo()}>
						{'Back'}
					</Button>
				</div>
			);
		}
	}

	deciderWhichScreenToReturnTo = () => {
		this.setState({ShowErrorMessage: null});
		if (this.state.Need === this.state.NeedCodes.ValidateCode) {
			this.setState({Need: this.state.NeedCodes.Landing});
			return null;
		}
		if (this.state.Need === this.state.NeedCodes.ValidateCode.NewCode) {
			this.setState({Need: this.state.NeedCodes.ValidateCode});
			return null;
		}
		if (this.state.Need === this.state.NeedCodes.MyProfile) {
			this.setState({Need: this.state.NeedCodes.ValidateCode.NewCode});
			return null;
		}
		if (this.state.Need === this.state.NeedCodes.Employees) {
			this.setState({Need: this.state.NeedCodes.MyProfile});
			return null;
		}
		if (this.state.Need === this.state.NeedCodes.New_shops) {
			this.setState({Need: this.state.NeedCodes.Employees});
			return null;
		}

		return null;
	}


	Redirect_to_page = (data) =>  {
		console.log(data);
		// let URL = data.data.LP;

		return <Redirect to={data}/>
	}


	SignUpForm = () => {
		return(
			<div className="Sign_up_form">

				<label className= "Sign_up_field_name">Email</label>
				<input
					className="Sign_up_input_field"
					autoFocus
					type="email"
					defaultValue={this.state.Email}
					onChange={(e) => this.changeEmailOrContra(e.target.value, 'Email')}
				/>

				<label className="Sign_up_field_name">Password</label>
				{/*<input*/}
				{/*	className="Sign_up_input_field"*/}
				{/*	type="password"*/}
				{/*	onChange={(e) => this.changeEmailOrContra(e.target.value, 'Contra')}*/}
				{/*/>*/}
				<div className='PasswordContainerWithEyeIcon'>
					<input
						className="Sign_up_input_field"
						type={this.state.passwordTypes.input1}
						disabled={this.state.WaitingForRequest === true}
						onChange={(e) => this.changeEmailOrContra(e.target.value, 'Contra')}
					/>
					<img className="eyePassword" src={this.decideEyeIconToShow(this.state.passwordTypes.input1)} alt={''}
						 onClick={() => this.switchTypePasswordToShowContent('input1') }/>
				</div>

				<label className="Sign_up_field_name">Repeat password</label>
				{/*<input*/}
				{/*	className="Sign_up_input_field"*/}
				{/*	type="password"*/}
				{/*	onChange={(e) => this.changeEmailOrContra(e.target.value, 'RepeatContra')}*/}
				{/*/>*/}
				<div className='PasswordContainerWithEyeIcon'>
					<input
						className="Sign_up_input_field"
						type={this.state.passwordTypes.input2}
						disabled={this.state.WaitingForRequest === true}
						onChange={(e) => this.changeEmailOrContra(e.target.value, 'RepeatContra')}
					/>
					<img className="eyePassword" src={this.decideEyeIconToShow(this.state.passwordTypes.input2)} alt={''}
						 onClick={() => this.switchTypePasswordToShowContent('input2') }/>
				</div>

				<div className='messageFormatPassword'>Password must contain 8-16 characters, 1 digit and one special symbol: !@#$%^&*</div>

			</div>
		)
	}


	decideEyeIconToShow = (state) => {
		// console.log(state)
		if (state === 'password') {return EyeSlashed}
		else {return EyeOpened}
	}


	switchTypePasswordToShowContent = (whichInput) => {
		let newType = 'text'
		console.log(this.state.passwordTypes)

		if (whichInput === 'input1') {
			if (this.state.passwordTypes.input1 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input1: newType}))
		}
		if (whichInput === 'input2') {
			if (this.state.passwordTypes.input2 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input2: newType}))
		}
		if (whichInput === 'input3') {
			if (this.state.passwordTypes.input3 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input3: newType}))
		}if (whichInput === 'input4') {
			if (this.state.passwordTypes.input4 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input4: newType}))
		}
		if (whichInput === 'input5') {
			if (this.state.passwordTypes.input5 === 'text'){newType = 'password'}
			this.setState(Object.assign(this.state.passwordTypes, {input5: newType}))
		}

		return null;
	}

	changeEmailOrContra = (event, field) => {
		//console.log(field);
		//console.log(event);
		if (field === 'Email') {
			this.setState({Email: format_email_address(event)});
		}
		if (field === 'Contra') {
			this.setState({Contra: event});
		}
		if (field === 'RepeatContra') {
			this.setState({RepeatContra: event});
		}
		if (field === 'Verification_code') {

			console.log(event);
			console.log(validate_integers_or_null(event));

			this.setState({Verification_code: event});

			// let check_verification_code = validate_integers_or_null(this.state.Verification_code)
			if (validate_integers_or_null(event) ) {
				this.setState({ErrorMessage: null});
				return true;

			} else {

				this.setState({ErrorMessage: 'StringInVerificationCode'});
				return false;
			}
		}
		return null;
	}

	signUpCheckboxes = () => {
		return(
			<div className='signUpCheckboxes'>
				{this.checkboxCompany()}
				{/*{this.checkboxPSI()}*/}
				{this.checkboxPrivacyPolicy()}
				{this.checkboxGeneralTermsAndConditions()}
			</div>
		)
	}


	checkboxCompany =() => {
		return (
			<div className= 'checkboxCompany'>
				<input type="checkbox" defaultChecked={this.state.checkboxCompany} onChange={(e) => this.handleSignUpCheckboxes(e.target.checked, 'Company')}/>
				<span className= 'checkboxCompanyText'> I am a company</span>
			</div>
		)
	}

	checkboxPSI =() => {
		return (
			<div className= 'checkboxCompany'>
				<input type="checkbox" defaultChecked={this.state.checkboxPSI} onChange={(e) => this.handleSignUpCheckboxes(e.target.checked, 'PSI')}
					   disabled={!this.state.checkboxCompany}/>

				<div className='checkboxTextSignUp'><span className= 'checkboxCompanyText'> I am a software-services company acting on behalf of third-parties.</span></div>
			</div>
		)
	}



	checkboxPrivacyPolicy = () => {
		return (
			<div className= 'checkboxCompany'>
				<input type="checkbox" checked={this.state.checkboxPrivacyPolicy} onChange={(e) => this.handleSignUpCheckboxes(e.target.checked, 'PrivacyPolicy')}/>
				<span className= 'checkboxCompanyText'> I agree to the </span><span className='checkboxCompanyTextBlue' onClick={() => {
				this.setState({whichLegalTextIsRequired : 'privacyPolicy'})
			}} > Privacy policy</span>
			</div>
		)
	}

	checkboxGeneralTermsAndConditions = () => {
		return (
			<div className= 'checkboxCompany'>
				<input type="checkbox" checked={this.state.checkboxGeneralTermsAndConditions} onChange={(e) => this.handleSignUpCheckboxes(e.target.checked, 'GeneralTermsAndConditions')}/>
				<div className='checkboxTextSignUp'><span className= 'checkboxCompanyTextGTC'> I agree to the </span><span className='checkboxCompanyTextBlue' onClick={() => {
					this.setState({whichLegalTextIsRequired : 'GTC'})
				}} > General terms and conditions</span></div>
			</div>
		)
	}




	SignUpButton = () => {
		return (
			<Button className="Sign_up_button" block size="lg" type="submit"
					onClick={(e) => this.validateSignUp("1", "0", true)}>
				{'Sign up'}
			</Button>
		);
	}

	SendingCodeAgain = () => {
		// Return to Need = 1, this is, I need to validate my code
		this.validateSignUp("1", "0", false);
		return null;
	}


	sendAgainButton = () => {

		if (this.state.Coming_from_Log_In === false) {
			return (
				<Button className="sendAgainButtonSignUp" block size="lg" type="submit"
						onClick={(e) => this.changeStatusInNewCode()}>
					{'Send again'}
				</Button>
			);

		} else {

			return (
				<Button className="sendAgainButtonSignUpLogIn" block size="lg" type="submit"
						onClick={(e) => this.changeStatusInNewCode()}>
					{'Send again'}
				</Button>
			);
		}
	}

	validateMyCodeButton = () =>{
		console.log('validate my code button');
		this.setState({New_code: false}, () => {this.setState({Contra: null}, () => {
			this.validateSignUp("3", "0", false);
		})});
		// this.setState({Contra: null});
		//
		// this.validateSignUp("3", "0");
		//this.setState({Need: "3"});
		//this.setState({Secondary: "1"});
		return null;
	}

	validateCodeButton = () => {
		//this.setState({New_code: true});
		return (
			<Button className="validateCodeButton" disabled={this.disableAllButtons()} block size="lg" type="submit" onClick={(e) => this.validateMyCodeButton()}>
				{'Next'}
			</Button>
		);
	}

	disableAllButtons = () => {

		if (this.state.Need === this.state.NeedCodes.ValidateCode) {
			// We are in the validation screen - disable the button if the code introduced is not integer-based.
			let check_verification_code_only_integers = validate_integers(this.state.Verification_code);

			return check_verification_code_only_integers === false
		}

		if (this.state.Need === this.state.NeedCodes.Landing) {

			return this.state.ErrorMessage !== null;
		}


		// return errorMessage !== null;
	}

	changeStatusInNewCode = () => {
		this.setState({New_code: true}, () => {
			this.setState({Contra: null}, () => {
				this.setState({Verification_code: null}, () => {
					this.sendAgainAction()
				})
			})
		})

		// //Send me a new code - Need = 2
		// this.setState({Need: this.state.NeedCodes.ValidateCode.NewCode})
		// return null;
	}


	sendAgainAction = () => {

		this.setState({WaitingForRequest: true});


		let Validating = () =>  {
			return create_json_sign_up_account(this.state.Email, this.state.Contra, this.state.Verification_code, true).then(json => {return json}) ;
		}

		let validating = Validating()

		let retorno = null;
		validating.then((response) => {
				console.log(response);
				let output =response.contenido.Sign_up_output;
				if (response.contenido.Sign_up_output.Status === "OK") {
					//console.log('Status OK');

					// //We can proceed to the following page:
					// this.setState({Need: parametro});
					//
					// if (secondary !== null) {
					// 	this.setState({Secondary: secondary});
					// }

					retorno = "OK";

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// this.setState({Need: parametro});

					this.updateProfileWithEmail();

					// this.wrapperSignUpCookies();

					// let LogInInstance = new Renderizar();
					// LogInInstance.start_jwt_creation_and_regeneration();

					// Remove the Remember_Me from the localStorage (edge situation motivating this removal: the user has logged in
					// the usual account and then created a new account). Remove the cookie corresponding to the previous loging.
					dont_remember_me_anymore();

					return null;
				} else {

					let error_code = response.contenido.Sign_up_output.Error_code;

					if (error_code === 'Sign_up_3') {
						this.setState({ErrorMessage: 'IncorrectVerificationCode'});
					}
					if (error_code === 'Sign_up_1') {
						this.setState({ErrorMessage: 'AlreadyExistingAccount'});
					}
					if (error_code === 'Sign_up_2') {
						this.setState({ErrorMessage: 'UnknownErrorInSignUp1'});
					}
					if (error_code === 'Sign_up_4') {
						this.setState({ErrorMessage: 'UnknownErrorInSignUp2'});
					}

					// Generic exception
					if (error_code === 'Sign_up_6' || error_code === 'Sign_up_2') {
						this.setState({ErrorMessage: 'GenericError'})
					}

					//Remove the loading icon
					this.setState({WaitingForRequest: false});


					console.log('Status NOK');
					this.setState({ErrorCode: response.contenido.Sign_up_output.Error_code});
					retorno = null;
					return retorno;
				}
			}
		)
	}



	setUserID = (user_ID) => {
		//console.log(user_ID);
		localStorage.setItem('User_ID', user_ID);

		return null;
	}


	validate_checkboxes = () => {

		// If the SignUp button has been pressed and the errorMessage is not the one of the email or passwords, show this validation's message.
		return this.state.checkboxGeneralTermsAndConditions === true && this.state.checkboxPrivacyPolicy === true;

	}



	evaluateErrorMessage = (parametro) => {

		// console.log(parametro);

		if (parametro === '1') {

			let check_email = email_validation(this.state.Email);
			let check_format_of_password = validate_password_format(this.state.Contra);
			let check_passwords = validate_new_password(this.state.Contra, this.state.RepeatContra);
			let check_checkboxes = this.validate_checkboxes();

			console.log(check_format_of_password);


			if (check_email !== true) {
				this.setState({ErrorMessage: 'InvalidEmail'});
			} else {
				if (this.state.Contra === '' || this.state.Contra === null) {
					this.setState({ErrorMessage: 'PasswordNotProvided'});
				} else {
					if (check_format_of_password !== true) {
						this.setState({ErrorMessage: 'IncorrectPasswordFormat'});
					} else {
						if (check_passwords !== true) {
							this.setState({ErrorMessage: 'PasswordsToBeTheSame'});
						} else {
							if (check_checkboxes !== true) {
								this.setState({ErrorMessage: 'UntickedCheckboxes'});
							} else {
								return true;
							}
						}
					}
				}
			}
		}

		if (parametro === '3') {

			console.log(this.state.CompanyHighLevelError);
			console.log(this.state.CompanyHighLevelError.Company);
			this.setState({ShowErrorMessage: this.state.CompanyHighLevelError.Company !== false});

			return this.state.ErrorMessage !== 'WrongContactPhone';

		}
		if (parametro === '4') {
			// console.log(this.state.CompanyHighLevelError.Employees)

			let employeeErrors = this.state.CompanyHighLevelError.Employees.Name === false && this.state.CompanyHighLevelError.Employees.Email === false;

			this.setState({ShowErrorMessage: !employeeErrors});

			this.checkHighLevelErrors();
			this.checkHighLevelErrorsEmployeeOrShopsName();

			return employeeErrors;

		}
		if (parametro === '5') {

			let shopErrors = this.state.CompanyHighLevelError.New_shops.Name === false && this.state.CompanyHighLevelError.New_shops.Phone_number === false;
			// console.log(shopErrors)

			this.setState({ShowErrorMessage: !shopErrors});

			this.checkHighLevelErrors();
			this.checkHighLevelErrorsEmployeeOrShopsName();

			return shopErrors;

		}

	}

	validateSignUp = (parametro, secondary, creating_account) => {
		console.log(parametro);

		let check = this.evaluateErrorMessage(parametro);
		console.log(check);
		console.log(this.state.Contra);

		if (check === true) {

			// Render the loading icon
			this.setState({WaitingForRequest: true});

			let Validating = () =>  {
				return create_json_sign_up_account(this.state.Email, this.state.Contra, this.state.Verification_code, this.state.New_code).then(json => {return json}) ;
			}

			let validating = Validating()

			let retorno = null;
			validating.then((response) => {
					console.log(response);
					let output =response.contenido.Sign_up_output;
					if (response.contenido.Sign_up_output.Status === "OK") {
						//console.log('Status OK');

						// If the user_id has been provided, store it on localStorage
						if (output.User_ID !== null) {
							this.setUserID(output.User_ID)
						}

						//We can proceed to the following page:
						this.setState({Need: parametro});

						if (secondary !== null) {
							this.setState({Secondary: secondary});
						}

						retorno = "OK";

						//Remove the loading icon
						this.setState({WaitingForRequest: false});

						this.updateProfileWithEmail();

						if (creating_account === true) {
							this.wrapperSignUpCookies();
						}

						// let LogInInstance = new Renderizar();
						// LogInInstance.start_jwt_creation_and_regeneration();

						// Remove the Remember_Me from the localStorage (edge situation motivating this removal: the user has logged in
						// the usual account and then created a new account). Remove the cookie corresponding to the previous login.
						dont_remember_me_anymore();

						return null;
					} else {

						let error_code = response.contenido.Sign_up_output.Error_code;

						if (error_code === 'Sign_up_3') {
							this.setState({ErrorMessage: 'IncorrectVerificationCode'});
						}
						if (error_code === 'Sign_up_1') {
							this.setState({ErrorMessage: 'AlreadyExistingAccount'});
						}
						if (error_code === 'Sign_up_2') {
							this.setState({ErrorMessage: 'UnknownErrorInSignUp1'});
						}
						if (error_code === 'Sign_up_4') {
							this.setState({ErrorMessage: 'UnknownErrorInSignUp2'});
						}

						// Generic exception
						if (error_code === 'Sign_up_6' || error_code === 'Sign_up_2') {
							this.setState({ErrorMessage: 'GenericError'})
						}

						//Remove the loading icon
						this.setState({WaitingForRequest: false});


						console.log('Status NOK');
						this.setState({ErrorCode: response.contenido.Sign_up_output.Error_code});
						retorno = null;
						return retorno;
					}
				}
			)

		}

	}


	wrapperSignUpCookies = () => {
		// Updates the permanent and session cookies with the user_id and the company boolean

		// Update the permanent cookie with the user_id and the company
		wrapper_send_permanent_cookie();

		// Update the session cookie with the user_id and the company
		wrapper_send_session_cookie(null, false, false, false);

		return null;
	}



	handleSignUpCheckboxes = (event, field) => {

		console.log(event);
		console.log(field);

		if (field === 'Company') {
			this.setState({checkboxCompany: event});
			fixUserTypeFromCompany(event);
			// Required to untick the privacy policy check and the GTC check - otherwise, user could tick the particular's GTC but user is a company
			this.setState({checkboxPrivacyPolicy: false});
			this.setState({checkboxGeneralTermsAndConditions: false});
		}
		if (field === 'PSI') {
			this.setState({checkboxPSI: event});
			fixUserTypeToPSI(event);
		}
		if (field === 'PrivacyPolicy') {
			this.setState({checkboxPrivacyPolicy: event});
		}
		if (field === 'GeneralTermsAndConditions') {
			this.setState({checkboxGeneralTermsAndConditions: event});
		}
		return null;
	}

	// sentenceTermsAndConditions =() => {
	//
	// 	let TermsAndConditionsRequest = <span style={{color: 'blue', cursor: 'pointer'}}
	// 									 onClick={() => this.Terms_and_conditions_required()}>{' Terms and Conditions'}</span>
	//
	// 	return (
	// 		<div className= 'sentenceTermsAndConditions'>
	// 			<span> By creating an account you are accepting our
	// 				{TermsAndConditionsRequest}.</span>
	// 		</div>
	// 	)
	// }
	//
	//
	// Terms_and_conditions_required = () => {
	//
	// 	// console.log( this.state.TermsAndConditionsRequired );
	// 	this.setState({TermsAndConditionsRequired: 'true'});
	// 	return null;
	// }
	//
	// TermsAndConditionsTextContainer = () => {
	//
	// 	if (this.state.TermsAndConditionsRequired === 'true') {
	//
	// 		return (
	// 			<div className='TermsAndConditionsTextContainer'>
	// 				{this.TermsAndConditionsText}
	// 				{this.TermsAndConditionsTextContainerClose}
	// 			</div>
	// 		)
	// 	} else {
	// 		return null;
	// 	}
	// }
	//
	// TermsAndConditionsText = () => {
	// 	return (
	// 		<span className='TermsAndConditionsText'>Texto de Terms and Conditions</span>
	// 	)
	// }
	//
	// TermsAndConditionsTextContainerClose = () => {
	// 	return (
	// 		<Button className="TermsAndConditionsTextContainerClose" onClick = {() => {this.TermsAndConditionsTextContainerCloseFunction()}}>
	// 			{'X'}
	// 		</Button>
	// 	)
	// }
	//
	// TermsAndConditionsTextContainerCloseFunction = () => {
	//
	// 	this.setState({TermsAndConditionsRequired: 'false'});
	// 	return null;
	//
	// }


	TermsAndConditions = () => {

	}

	handleCheckboxTAC = (event) => {
		console.log(event);
		this.setState({checkboxTAC: true});
		return null;
	}

	VerifyCode = () => {
		return (
			<div className='Sign_up_form_verification' >

				<label className= "Sign_up_field_name">Verification code</label>
				<input
					className="Sign_up_input_field"
					autoFocus
					type="email"
					onChange={(e) => this.changeEmailOrContra(e.target.value, 'Verification_code')}
				/>

			</div>
		);
	}

	containerRowOfNumberOfCurrentState = () => {
		return (
			<div className='containerRowOfNumberOfCurrentState'>
				{this.rowOfNumberOfCurrentState()}
			</div>
		)
	}

	rowOfNumberOfCurrentState = () => {
		// Show the current step of the company data submission
		let num1 = <span className='numberInsideFlag'>1</span>
		let num2 = <span className='numberInsideFlag'>2</span>
		let num3 = <span className='numberInsideFlag'>3</span>

		if (this.state.checkboxCompany === true) {
			if (this.state.Need === this.state.NeedCodes.MyProfile) {
				return ([
						<div className='Flag_current_step'>
							<div className='numberInsideCircleContainer'>{num1}</div>
						</div>,
						<div className='Flag_incomplete_step'>
							<div className='numberInsideCircleContainer'>{num2}</div>
						</div>,
						<div className='Flag_incomplete_step'>
							<div className='numberInsideCircleContainer'>{num3}</div>
						</div>
					]
				)
			}
			if (this.state.Need === this.state.NeedCodes.Employees) {
				return ([
						<div className='Flag_complete_step'>
							<div className='numberInsideCircleContainer'>{num1}</div>
						</div>,
						<div className='Flag_current_step'>
							<div className='numberInsideCircleContainer'>{num2}</div>
						</div>,
						<div className='Flag_incomplete_step'>
							<div className='numberInsideCircleContainer'>{num3}</div>
						</div>
					]
				)
			}
			if (this.state.Need === this.state.NeedCodes.New_shops) {
				return (
					[
						<div className='Flag_complete_step'>
							<div className='numberInsideCircleContainer'>{num1}</div>
						</div>,
						<div className='Flag_complete_step'>
							<div className='numberInsideCircleContainer'>{num2}</div>
						</div>,
						<div className='Flag_current_step'>
							<div className='numberInsideCircleContainer'>{num3}</div>
						</div>
					]
				)
			}
		}
	}

	RenderCompanyProfileCompany = () => {

		let divDisclaimersCompanyProfile = "";
		if (isUserAPSI()) {
			divDisclaimersCompanyProfile = 'divDisclaimersCompanyProfile'
		}

		return(
			<div className="RenderCompanyProfileCompanyForm">
				<Form >

					<label className= "Sign_up_field_name">Company name</label><span className='MandatoryFieldCompany'>*</span>
					<Form.Control
						className="Sign_up_input_field_company_profile"
						autoFocus
						type="text"
						value = {this.returnCompanyProfile().Company.Name}
						onChange={(e) => this.registerCompanyCompany(e.target.value, 'Name')}
					/>


					<table className='SignUpCompanyTable'>
						<colgroup>
							<col className='SignUpCompanyCol1'/>
							<col className='SignUpCompanyCol2'/>
						</colgroup>
						<tbody>
						<tr>
							<td>{this.companySignUpLeft()}</td>
							<td>{this.companySignUpRight()}</td>
						</tr>
						</tbody>
					</table>

					<div className={divDisclaimersCompanyProfile}>
						{this.disclaimerConfirmationInformation()}
						<span className='MandatoryFieldCompanySecondAsterisk'>*</span> <span className='MandatoryFieldCompany2'> This field is required for the correct behaviour of the tool, though you may provide it later.</span>
						{this.disclaimerTFN()}
					</div>
				</Form>
			</div>
		)
	}

	disclaimerConfirmationInformation = () => {
		return(
			<em className='disclaimerConfirmationInformationSignUp'>The person providing the corporate information above certifies and is responsible for its veracity.</em>
		)
	}

	disclaimerTFN = () => {
		return[
			<div className='disclaimerTFNSignUp'>**</div>,
			<div className='disclaimerTFNSignUp2'> Once you have already introduced your company's TFN, you will not be able to change it manually.
				To do so at a later stage, please write an e-mail to [dirección correo].</div>
		]
	}

	DropDownAttribute = (attribute, row_index, table, profile) => {

		let Attributes = [];

		if (attribute === 'Country' && this.state.InputField === 'Country') {
			// console.log(this.returnCompanyProfile());
			Attributes = file.Country;
		}
		if (attribute === 'Currency' && this.state.InputField === 'Currency') {
			let Country = this.returnCompanyProfile().Company.Country;
			if (Country === null) {
				// Country has not been chosen but user selects Currency first
				Country = 'Unselected';
			}
			var Currency = file.Currency;
			Attributes = Currency[Country.valueOf()];
		}
		if (attribute === 'Region' && this.state.InputField === 'Region') {
			let Country = null;
			if (table === 'true') {Country = this.returnCompanyProfile().New_shops[row_index].Country;}
			else {Country = this.returnCompanyProfile().Company.Country;}

			if (Country === null) {
				// Country has not been chosen but user selects Region first
				Country = 'Unselected';
			}
			var Region = file.Region;
			Attributes = Region[Country.valueOf()];
		}
		if (attribute === 'Province' && this.state.InputField === 'Province') {

			let Region = null;
			if (table === 'true') {Region = this.returnCompanyProfile().New_shops[row_index].Region;}
			else {Region = this.returnCompanyProfile().Company.Region;}

			if (Region === null) {
				// Region has not been chosen but user selects Province first
				Region = 'Unselected';
			}
			// console.log(Region);
			var Province = file.Province;
			Attributes = Province[Region.valueOf()];
		}
		if (attribute === 'Phone_prefix' && this.state.InputField === 'Phone_prefix') {
			let Country = this.returnCompanyProfile().Company.Country;
			if (Country === null) {
				// Sector has not been chosen but user selects Subsector first
				Country = 'Unselected';
			}
			var Phone_prefix = file.Phone_prefix;
			Attributes = Phone_prefix[Country.valueOf()];
		}
		if (attribute === 'Sector' && this.state.InputField === 'Sector') {
			Attributes = file.Sector;
		}
		if (attribute === 'Subsector' && this.state.InputField === 'Subsector') {
			let Sector = this.returnCompanyProfile().Company.Sector;
			if (Sector === null) {
				// Sector has not been chosen but user selects Subsector first
				Sector = 'Unselected';
			}
			var Subsectors = file.Subsector;
			Attributes = Subsectors[Sector.valueOf()];
		}
		if (attribute === 'Treatment' && this.state.InputField === 'Treatment') {
			Attributes = file.Treatment;
		}

		var array2 = [];

		Attributes.forEach(Attribute => {
			array2.push(
				<div className='elementInEmployeeDropDown'>
					<Dropdown.Item eventKey="1" className='dropDownOperations' Attribute={Attribute}
								   disabled={this.mustDropdownBeDisabled(attribute, row_index)}
								   onClick={(e) => this.assignAttributeFromDropDown(e, 'Company', attribute, row_index)}> {Attribute} </Dropdown.Item>
				</div>
			)
		})

		// // The return has to be: Null if I have not clicked on the button; the dropdown menu otherwise
		if (this.state.InputField === attribute.valueOf()) {

			if (attribute === 'Phone_prefix') {
				return (<div className='dropdownMenuPhonePrefix'> {array2} </div>);
			}

			if (attribute === 'Treatment') {
				if (row_index === this.state.Row_index) {
					return (<div className='dropdownMenuTreatment'> {array2} </div>);
				} else { return null; }
			}

			if (attribute === 'Currency') {
				if (row_index === this.state.Row_index) {
					return (<div className='dropdownMenuSettingsCompanySignUp'> {array2} </div>);
				} else { return null; }
			}

			if (attribute === 'Sector') {
				if (row_index === this.state.Row_index) {
					return (<div className='dropdownMenuSettingsCompanySignUp'> {array2} </div>);
				} else { return null; }
			}

			if (attribute === 'Subsector') {
				if (row_index === this.state.Row_index) {
					return (<div className='dropdownMenuSettingsCompanySignUp'> {array2} </div>);
				} else { return null; }
			}

			if (attribute === 'Phone_prefix') {
				if (row_index === this.state.Row_index) {
					return (<div className='dropdownMenuPhonePrefix'> {array2} </div>);
				} else { return null; }
			}

			if (attribute === 'Country') {
				if (table === 'true') {
					if (row_index === this.state.Row_index) {
						return (<div className='dropdownMenuCountry'> {array2} </div>);
					} else { return null; }
				}
				if (table === 'false') {
					return (<div className='dropdownMenuSettingsCompanySignUp'>{array2} </div>);
				}
			}

			if (attribute === 'Region') {
				if (table === 'true') {
					if (row_index === this.state.Row_index) {
						return (<div className='dropdownMenuRegion'> {array2} </div>);
					} else { return null; }
				}
				if (table === 'false') {
					return (<div className='dropdownMenuSettingsCompanySignUp'> {array2} </div>);
				}
			}

			if (attribute === 'Province') {
				if (table === 'true') {
					if (row_index === this.state.Row_index) {
						return (<div className='dropdownMenuProvince'> {array2} </div>);
					} else { return null; }
				}
				if (table === 'false') {
					return (<div className='dropdownMenuSettingsCompanySignUp'> {array2} </div>);
				}
			}

			else {
				return (<div className='dropdownMenuPhonePrefix'> {array2} </div>);
			}
		}
		else {
			if (this.state.InputField === attribute.valueOf()) {
				return null;
			}
		}
	}

	mustDropdownBeDisabled = (field, index) => {
		// Decides if the dropdown must be disabled due to some prior field not being selected yet.

		// console.log(index);
		if (index === null) {
			// This is not a table, but the Company profile (signup code = 3)
			if (field === 'Region' && this.returnCompanyProfile().Company.Country === null){
				return true;
			}
			if (field === 'Province' && this.returnCompanyProfile().Company.Region === null){
				return true;
			}

		} else {
			// This data is on the Shops table
			if (field === 'Region' && this.returnCompanyProfile().New_shops[index].Country === null){
				return true;
			}
			if (field === 'Province' && this.returnCompanyProfile().New_shops[index].Region === null){
				return true;
			}
		}

		if (field === 'Subsector' && this.returnCompanyProfile().Company.Sector === null){
			return true;
		}
		return false;
	}

	assignAttributeFromDropDown = (event, profile_type, attribute, row_index) => {
		// The parameter Attribute is the word Sector, Subsector, Currency,... It's an attribute assigned to the dropdown that calls
		// this function.

		// Restart the dropdown menu
		this.buttonInputFieldForDropdowns('None', null);

		const value = (event.currentTarget.getAttribute('Attribute') );

		console.log(value);
		console.log(profile_type);
		console.log(attribute);
		console.log(row_index);

		// let field = {data: attribute, indice: row_index};


		if (this.state.Need === this.state.NeedCodes.Employees) {
			this.inputEmployeeTablefunction(attribute, row_index, value);
		}

		if (this.state.Need === this.state.NeedCodes.New_shops) {
			this.inputShopTablefunction(attribute, row_index, value);
		}

		if (this.state.Need === this.state.NeedCodes.MyProfile) {
			this.registerCompanyCompany(value, attribute.valueOf(), row_index);
		}
		return null;
	}

	buttonInputFieldForDropdowns = (parametro, row_index) => {

		if (parametro === 'None') {
			this.setState({InputField: 'None'});
		}
		if (parametro === 'Country') {
			this.setState({InputField: 'Country'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Region') {
			this.setState({InputField: 'Region'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Province') {
			this.setState({InputField: 'Province'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Currency') {
			this.setState({InputField: 'Currency'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Phone_prefix') {
			this.setState({InputField: 'Phone_prefix'});
		}
		if (parametro === 'Sector') {
			this.setState({InputField: 'Sector'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Subsector') {
			this.setState({InputField: 'Subsector'});
			this.setState({Row_index: row_index});
		}
		if (parametro === 'Treatment') {
			this.setState({InputField: 'Treatment'});
			this.setState({Row_index: row_index});
		}
		if (parametro === this.state.InputField) {
			this.setState({InputField: 'None'});
		}

		return null;
	}


	companySignUpLeft = () => {

		let inputContactPhone = 'Sign_up_input_field_partitioned_phone_2';
		if (this.state.ErrorMessage === 'WrongContactPhone') {
			inputContactPhone = 'Sign_up_input_field_partitioned_phone_2Red'
		}
		let country = this.returnCompanyProfile().Company.Country;
		if (this.returnCompanyProfile().Company.Country === null) {
			country = ' Choose ';
		}
		let province = this.returnCompanyProfile().Company.Province;
		if (this.returnCompanyProfile().Company.Province === null) {
			province = ' Choose ';
		}
		let phone_prefix = this.returnCompanyProfile().Company.Phone_prefix;
		if (this.returnCompanyProfile().Company.Phone_prefix === null) {
			phone_prefix = ' +00 ';
		}
		let sector = this.returnCompanyProfile().Company.Sector;
		if (this.returnCompanyProfile().Company.Sector === null) {
			sector = ' Choose ';
		}
		// console.log(this.returnCompanyProfile().Company);

		let sector_container = <div>
					<div>
						<label className= "Sign_up_company_2_columns_field_name_left_column">Sector</label><span className='MandatoryFieldCompany'>*</span>
					</div>
					<div className='Sign_up_company_2_columns_input_field_left_column_no_border_left' >
						<Button className='Sign_up_company_2_columns_input_field_button' block size="lg"
								onClick={(e) => this.buttonInputFieldForDropdowns('Sector', null)}>
							<img className='dropDownInSignUp' alt="" />
							{sector}
						</Button>
						{this.DropDownAttribute('Sector', null, 'false', 'Company')}
					</div>
			</div>
		if (isUserAPSI()) {sector_container= null}

		return [
			<div>
				<label className= "Sign_up_company_2_columns_field_name_left_column">Country</label><span className='MandatoryFieldCompany'>*</span>
			</div>
			,
			<div className='Sign_up_company_2_columns_input_field_left_column_no_border_left' >
				<Button className='Sign_up_company_2_columns_input_field_button' block size="lg"
						onClick={(e) => this.buttonInputFieldForDropdowns('Country', null)}
				>
					<img className='dropDownInSignUp' alt="" />
					{country}
				</Button>
				{this.DropDownAttribute('Country', null, 'false', 'Company')}
			</div>
			,

			<div>
				<label className= "Sign_up_company_2_columns_field_name_left_column">Province</label><span className='MandatoryFieldCompany'>*</span>
			</div>,
			<div className='Sign_up_company_2_columns_input_field_left_column_no_border_left' >
				<Button className='Sign_up_company_2_columns_input_field_button' block size="lg"
						onClick={(e) => this.buttonInputFieldForDropdowns('Province', null)}
				>
					<img className='dropDownInSignUp' alt="" />
					{province}
				</Button>
				{this.DropDownAttribute('Province', null, 'false', 'Company')}
			</div>,

			<div>
				<label className= "Sign_up_company_2_columns_field_name_left_column">Postal code</label><span className='MandatoryFieldCompany'>*</span>
			</div>,
			<div>
				<input
					className="Sign_up_company_2_columns_input_field_left_column"
					autoFocus
					type="text"
					value={this.returnCompanyProfile().Company.Postal_code}
					onChange={(e) => this.registerCompanyCompany(e.target.value, 'Postal_code')}
				/>
			</div>,

			<div>
				<label className= "Sign_up_company_2_columns_field_name_left_column">Contact phone</label>
			</div>,
			<div className='companySignUpLeftPartitioned'>
				<div className='Sign_up_input_field_partitioned_phone_1' >
					<Button className='Sign_up_company_2_columns_input_field_button' block size="lg"
							onClick={(e) => this.buttonInputFieldForDropdowns('Phone_prefix', null)}
					> <img className='dropDownInSignUpPhonePrefix' alt="" />
						{phone_prefix}
					</Button>
					{this.DropDownAttribute('Phone_prefix', null, 'false', 'Company')}
				</div>
				<input
					className={inputContactPhone}
					autoFocus
					type="text"
					value={this.returnCompanyProfile().Company.Phone_number}
					onChange={(e) => this.registerCompanyCompany(e.target.value, 'Phone_number')}
				/>
			</div>,
			sector_container,
			// <this.MessageWrongContactPhone/>
			this.MessageWrongContactPhone()

		]
	}

	MessageWrongContactPhone = () => {

		if (this.state.ErrorMessage === 'WrongContactPhone') {
			return <div className='MessageWrongContactPhone'> Please, introduce only digits</div>
		} else { return null;}
	}


	companySignUpRight= () => {

		let region = this.returnCompanyProfile().Company.Region;
		if (this.returnCompanyProfile().Company.Region === null) {
			region = ' Choose ';
		}
		let currency = this.returnCompanyProfile().Company.Currency;
		if (this.returnCompanyProfile().Company.Currency === null) {
			currency = ' Choose ';
		}
		let subsector = this.returnCompanyProfile().Company.Subsector;
		if (this.returnCompanyProfile().Company.Subsector === null) {
			subsector = ' Choose ';
		}

		let subsector_container = <div>
			<div>
				<label className= "Sign_up_company_2_columns_field_name_right_column">Subsector</label><span className='MandatoryFieldCompany'>*</span>
			</div>
			<div className='Sign_up_company_2_columns_input_field_left_column_no_border_right' >
				<Button className='Sign_up_company_2_columns_input_field_button' block size="lg"
						onClick={(e) => this.buttonInputFieldForDropdowns('Subsector', null)}
				>
					<img className='dropDownInSignUp' alt="" />
					{subsector}
				</Button>
				{this.DropDownAttribute('Subsector', null, 'false', 'Company')}
			</div>
		</div>
		if (isUserAPSI()) {subsector_container= null}

		return [

			<div>
				<label className= "Sign_up_company_2_columns_field_name_right_column">Region</label><span className='MandatoryFieldCompany'>*</span>
			</div>,
			<div className='Sign_up_company_2_columns_input_field_left_column_no_border_right' >
				<Button
					className='Sign_up_company_2_columns_input_field_button' block size="lg"
					onClick={(e) => this.buttonInputFieldForDropdowns('Region', null)}
				>
					<img className='dropDownInSignUp' alt="" />
					{region}
				</Button>
				{this.DropDownAttribute('Region', null, 'false', 'Company')}
			</div>,


			<div>
				<label className= "Sign_up_company_2_columns_field_name_right_column">Address</label><span className='MandatoryFieldCompany'>*</span>
			</div>,
			<div>
				<input
					className="Sign_up_company_2_columns_input_field_right_column"
					autoFocus
					type="text"
					value={this.returnCompanyProfile().Company.Address}
					onChange={(e) => this.registerCompanyCompany(e.target.value, 'Address')}
				/>
			</div>,

			<div>
				<label className= "Sign_up_company_2_columns_field_name_right_column">TFN</label><span className='MandatoryFieldCompany'>*  **</span>
			</div>,
			<div>
				<input
					className="Sign_up_company_2_columns_input_field_right_column"
					autoFocus
					type="text"
					value={this.returnCompanyProfile().Company.TFN}
					onChange={(e) => this.registerCompanyCompany(e.target.value, 'TFN')}
				/>
			</div>,

			<div>
				<label className= "Sign_up_company_2_columns_field_name_right_column">Currency</label><span className='MandatoryFieldCompany'>*</span>
			</div>,

			<div className='Sign_up_company_2_columns_input_field_left_column_no_border_right' >
				<Button
					className='Sign_up_company_2_columns_input_field_button' block size="lg"
					onClick={(e) => this.buttonInputFieldForDropdowns('Currency', null)}
				>
					<img className='dropDownInSignUp' alt="" />
					{currency}
				</Button>
				{this.DropDownAttribute('Currency', null, 'false', 'Company')}
			</div>,

			subsector_container

		]
	}


	RenderCompanyProfileButtons = (parameter) => {
		return (
			<div className='RenderCompanyProfileButtons'>
				{this.RenderCompanyProfileCompanyButton(parameter)}
			</div>
		)
	}

	RenderCompanyProfileCompanyButton = (parameter)=> {
		return([
				this.messageBeforeContinueButtonWorks,
				<div className="RenderCompanyProfileButtons_Left">
					<Button className="RenderCompanyProfileButtons_ButtonSaveAndContinue" block size="lg" type="submit" onClick={(e) => this.saveAndContinueLater()}>
						{'Save and continue later'}
					</Button>
				</div>,
				<div className="RenderCompanyProfileButtons_Right">
					<Button className='RenderCompanyProfileButtons_ButtonContinue' block size="lg" type="submit" onClick={(e) => this.precheckSendCompanyProfileOnShops(parameter, false)}>
						{'Continue'}
					</Button>
				</div>
			]
		)
	}


	messageBeforeContinueButtonWorks = () => {
		if (this.state.ShowErrorMessage === true) {
			return <div className='messageBeforeContinueButtonWorks'> Please, check the errors above before
				continuing. </div>
		} else {return null;}
	}

	saveAndContinueLater = () => {

		this.setState({popupConfirmSaveAndContinue: 'True'})
		return null;
	}

	validateHasUserEnteredAllRequiredFields = () => {

		if (isUserACompany()) {

			let profile = this.returnCompanyProfile().Company;

			if (profile.Name === null || profile.Country === null || profile.Region === null ||
				profile.Province === null || profile.Address === null || profile.Postal_code === null ||
				profile.TFN === null || profile.Currency === null || profile.Sector === null ||
				profile.Subsector === null ||
				profile.Name === '' || profile.Country === '' || profile.Region === '' ||
				profile.Province === '' || profile.Address === '' || profile.Postal_code === '' ||
				profile.TFN === '' || profile.Currency === '' || profile.Sector === '' ||
				profile.Subsector === '' ||
				profile.Name === ' ' || profile.Country === ' ' || profile.Region === ' ' ||
				profile.Province === ' ' || profile.Address === ' ' || profile.Postal_code === ' ' ||
				profile.TFN === ' ' || profile.Currency === ' ' || profile.Sector === ' ' ||
				profile.Subsector === ' ') {
				console.log(this.state.hasUserEnteredAllRequiredFields)
				this.setState({hasUserEnteredAllRequiredFields: false}, () => {console.log(this.state.hasUserEnteredAllRequiredFields)});
			} else {
				this.setState({hasUserEnteredAllRequiredFields: true}, () => {console.log(this.state.hasUserEnteredAllRequiredFields)});
			}
		} else {
			if (isUserAPSI()) {
				// TODO Martin - validación



			} else {
				let profile = this.state.Person;

				if (profile.Nickname !== null && profile.Genre !== null && profile.Postal_code !== null &&
					profile.Birth_date !== null) {
					this.setState({hasUserEnteredAllRequiredFields: true});
				} else {
					this.setState({hasUserEnteredAllRequiredFields: false});
				}
			}
		}
		return null;
	}


	judgeIfPopUpMissingInformationIsRequired = (confirmed) => {
		// The user has clicked on the button Continue when creating the profile
		// Missing information on this step
		if (this.state.hasUserEnteredAllRequiredFields !== true && this.state.Need === this.state.NeedCodes.New_shops) {
			console.log('1')
			if (confirmed === false) {
				// I do not want to finish at this step.
				console.log('2')
				this.isUserMissingInformation();
				return false;
			} else {
				console.log('3')
				// I confirm I want to continue and finish later.
				return true;
			}
		} else {
			console.log('4')
			// Not missing anything on last step or not on last step.
			return true;
		}

	}


	precheckSendCompanyProfileOnShops = (parameter, confirmed) => {

		// Prevalidate before the last message "you are about..."
		let check = this.evaluateErrorMessage(parameter);
		console.log(check);

		if (check === true) {
			let judge = this.judgeIfPopUpMissingInformationIsRequired(confirmed);
			console.log(judge);

			if (judge === true) {
				this.sendCompanyProfile();
			}
		}
		return null;
	}


	sendCompanyProfile = async () => {

		console.log('request enviada')

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		let Profile = () => {
			return create_json_my_profile(null, null, null, null, null,
				this.returnCompanyProfile().Company, null, this.returnCompanyProfile().Employees,
				this.returnCompanyProfile().New_shops, null, true, null).then(json => {
				return json
			});
		}

		let profile1 = Profile();

		profile1.then((response) => {

			if (response.contenido.Update_my_profile_output.Status === "OK") {

				// Reset the update_number
				console.log(response.contenido.Update_my_profile_output);

				localStorage.setItem('PUN', response.contenido.Update_my_profile_output.Update_number);

				// If the user is a PSI, send him/her to the landing page directly
				if (isUserAPSI()) {
					this.acceptFinishRegistration();
				}

				// If user is a company and has filled in the Shops table:
				if (this.state.Need === this.state.NeedCodes.New_shops) {
					// this.isUserMissingInformation();
					this.acceptFinishRegistration();
				}
				// If user is a company and has filled in the Employees table:
				if (this.state.Need === this.state.NeedCodes.Employees) {
					this.setState({Need: this.state.NeedCodes.New_shops})
				}
				// If user is a company and has filled in the Profile table:
				if (this.state.Need === this.state.NeedCodes.MyProfile) {
					this.setState({Need: this.state.NeedCodes.Employees})
				}
				//Remove the loading icon
				this.setState({WaitingForRequest: false});
			} else {

				// Generic exception
				if (response.contenido.Update_my_profile_output.Error_code === 'Update_my_profile_1') {
					this.setState({ErrorMessage: 'GenericError'})
				}
				//Remove the loading icon
				this.setState({WaitingForRequest: false});

			}
		})

		return null;
	}

	isUserMissingInformation = () => {
		this.setState({popupUserMissingInformation: true});
		// Usabilidad: evaluar si el usuario ha escrito toda la información necesaria.
		return null;
	}

	popupUserMissingInformation = () => {
		console.log('holi');
		console.log(this.state.hasUserEnteredAllRequiredFields);
		console.log(this.state.popupUserMissingInformation);

		let info = <span>You are about to leave the registration process <b>without</b> entering all information required for the correct behaviour of the tool. You may provide it later under "My account" tab.</span>;

		if (this.state.hasUserEnteredAllRequiredFields === true) {
			info = <span>You are about to leave the registration process now. You may provide the rest of your company\'s information later under "My account" tab.</span>;
			console.log('LOL');
			// this.whichPopupShouldBeReturned();
		}

		if (this.state.hasUserEnteredAllRequiredFields !== true && this.state.popupUserMissingInformation === true) {
			return (
				<div className='popupContinue'>
					<div className='popupContinueInterior'>
						{info}
						<div className='popupContinueButtons'>
							<Button className="popupContinueButtonsCancel" block size="lg" type="submit"
									onClick={(e) => this.cancelFinishRegistration()}>
								{'Cancel'}
							</Button>

							<Button className="popupContinueButtonsConfirm" block size="lg" type="submit"
									onClick={(e) => this.precheckSendCompanyProfileOnShops('5', true)}>
								{'Confirm'}
							</Button>
						</div>
					</div>
				</div>
			)
		}
		if (this.state.popupUserMissingInformation === false) {
			return null;
		}
		return null;
	}

	cancelFinishRegistration = () => {
		this.setState({popupUserMissingInformation: false});
		return null;
	}

	acceptFinishRegistration = () => {
		this.whichPopupShouldBeReturned();
		return null;
	}



	RenderPersonProfileButton = (parametro)=> {

		return(
			<div className="RenderPersonalProfileButton">
				<Button className="Sign_up_button_person" block size="lg" type="submit" onClick={(e) => this.sendPersonProfile(parametro)}>
					{'Save'}
				</Button>
			</div>
		)
	}

	sendPersonProfile = (Need) => {

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		create_json_my_profile( null, null, null, null, null, null,
			this.state.PersonProfile, null, null, null, true, null);
		if (Need === 6) {
			this.setState({Need: this.state.NeedCodes.NonCritical})
		}
		return null;
	}


	registerCompanyCompany = (event, field, row_index) => {

		// Check if the user has introduced all required fields.
		// console.log(field);
		// console.log(event);
		// console.log(this.returnCompanyProfile().Company);
		// console.log(this.state.CompanyProfile.Company)
		if (field === 'Name') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Name: event })
				// , () => {this.validateHasUserEnteredAllRequiredFields()}
				);
		}
		if (field === 'Contact_email') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Contact_email: format_email_address(event) }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}
		if (field === 'Address') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Address: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}
		if (field === 'Province') {
			if (isUserACompany()) {
				this.replicateCompanyGeographyToShops(event, 'Province');
			}

			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Province: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}
		if (field === 'Region') {
			if (isUserACompany()) {
				this.replicateCompanyGeographyToShops(event, 'Region');
			}

			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Region: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}
		if (field === 'Country') {
			if (isUserACompany()) {
				this.replicateCompanyGeographyToShops(event, 'Country');
			}

			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Country: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}

		if (field === 'Currency') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Currency: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}

		if (field === 'Sector') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Sector: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}
		if (field === 'Subsector') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Subsector: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}
		if (field === 'Phone_prefix') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Phone_prefix: event })
			);
		}
		if (field === 'Phone_number') {

			if (validate_integers_or_null(event) ) {
				this.setState(
					Object.assign(this.returnCompanyProfile().Company, {Phone_number: event })
				);
				this.setState({ErrorMessage: null}, () => {
					this.setState(
						Object.assign(this.state.CompanyHighLevelError, {Company: false} )
					);});
				return true;
			} else {
				this.setState({ErrorMessage: 'WrongContactPhone'}, () => {
					this.setState(
						Object.assign(this.state.CompanyHighLevelError, {Company: true} )
					);});
				return null;
			}


		}
		if (field === 'TFN') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {TFN: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}
		if (field === 'Postal_code') {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Postal_code: event }), () => {this.validateHasUserEnteredAllRequiredFields()}
			);
		}

		return null;
	}

	replicateCompanyGeographyToShops = (value, field, indice) => {

		if (value !== 'Country' && field === 'Country') {
			this.returnCompanyProfile().New_shops.forEach((shop, index) => {
				if (shop.Country === null) {
					this.setState(
						Object.assign(this.returnCompanyProfile().New_shops[index], {Country: value })
					);
				}
			} )
		}
		if (value !== 'Region' && field === 'Region') {
			this.returnCompanyProfile().New_shops.forEach((shop, index) => {
				if (shop.Region === null) {
					this.setState(
						Object.assign(this.returnCompanyProfile().New_shops[index], {Region: value })
					);
				}
			} )
		}
		if (value !== 'Province' && field === 'Province') {
			this.returnCompanyProfile().New_shops.forEach((shop, index) => {
				if (shop.Province === null) {
					this.setState(
						Object.assign(this.returnCompanyProfile().New_shops[index], {Province: value })
					);
				}
			} )
		}
		if (field === 'NewAll') {
			// this.returnCompanyProfile().New_shops.forEach((shop, index) => {
			// 	console.log(shop);
			// 	if (shop.Province === 'Province') {
			this.setState(
				Object.assign(this.returnCompanyProfile().New_shops[+indice+1], {Country: this.returnCompanyProfile().Company.Country })
			);
			this.setState(
				Object.assign(this.returnCompanyProfile().New_shops[+indice+1], {Region: this.returnCompanyProfile().Company.Region })
			);
			this.setState(
				Object.assign(this.returnCompanyProfile().New_shops[+indice+1], {Province: this.returnCompanyProfile().Company.Province })
			);
			// }
			// } )
		}

		return null;

	}

	RenderPersonProfile = () => {

		return(
			<div className="RenderPersonProfile">
				<span className= "Sign_up_field_name">Nickname</span>
				<input
					className="Sign_up_input_field_person"
					autoFocus
					type="email"
					onChange={(e) => this.registerPerson(e.target.value, 'Nickname')}
				/>

				<span className= "Sign_up_field_name">Genre</span>
				{this.checkboxGenres()}

				<span className= "Sign_up_field_name">Date of birth</span>
				<input
					className="Sign_up_input_field_person_date"
					autoFocus
					type="Date"
					onChange={(e) => this.registerPerson(e.target.value, 'Birth_date')}
				/>


				<span className= "Sign_up_field_name">Postal code</span>
				<input
					className="Sign_up_input_field_person"
					autoFocus
					type="email"
					onChange={(e) => this.registerPerson(e.target.value, 'Postal_code')}
				/>

			</div>
		)
	}

	checkboxGenres = () => {
		return (
			<div className='checkboxGenres'>
				<table className='checkboxGenresTable'>
					<colgroup>
						<col className='checkboxGenresCol1'/>
						<col className='checkboxGenresCol2'/>
						<col className='checkboxGenresCol3'/>
					</colgroup>

					<tr>
						<td>{this.checkboxGenresCheckbox('Male', 0)}</td>
						<td>{this.checkboxGenresCheckbox('Female', 1)}</td>
						<td>{this.checkboxGenresCheckbox('Non binary', 2)}</td>
					</tr>

				</table>
			</div>
		)
	}

	checkboxGenresCheckbox = (genre, int) => {
		// className='checkboxCircular'

		return [
			<input type="checkbox"
				// className='checkboxCircular'
				   checked={this.state.Genres[int]} onChange={(e) => this.handleGenres(genre, this.state.Genres[int])} />,
			<span>{genre}</span>
		]
	}

	handleGenres = (genre, valor) => {

		if (genre === 'Male') {
			this.setState({Genres: [true, false, false] });
		}
		if (genre === 'Female') {
			this.setState({Genres: [false, true, false] });
		}
		if (genre === 'Non binary') {
			this.setState({Genres: [false, false, true] });
		}

		this.registerPerson(genre, 'Genre');
		return null;
	}

	registerPerson = (event, field) => {
		// console.log(field);
		// console.log(event);
		if (field === 'Nickname') {
			console.log(this.state.PersonProfile);
			this.setState(
				Object.assign(this.state.PersonProfile, {Nickname: event })
			);
		}
		if (field === 'Phone_prefix') {
			this.setState(
				Object.assign(this.state.PersonProfile, {Phone_prefix: event })
			);
		}
		if (field === 'Phone') {
			this.setState(
				Object.assign(this.state.PersonProfile, {Phone: event })
			);
		}
		if (field === 'Birth_date') {
			this.setState(
				Object.assign(this.state.PersonProfile, {Birth_date: event })
			);
		}
		if (field === 'Genre') {
			this.setState(
				Object.assign(this.state.PersonProfile, {Genre: event })
			);
		}
		if (field === 'Postal_code') {
			this.setState(
				Object.assign(this.state.PersonProfile, {Postal_code: event })
			);
		}

		return null;
	}





	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//Here begins the Employees' sub-tab


	RenderCompanyProfileEmployees = () => {

		if (this.returnCompanyProfile().Employees.length < 12) {

			return(
				<div className='RenderCompanyProfileCompany'>
					{this.stepShopAndEmployees()}
					{this.checkHighLevelErrors()}
					{this.checkHighLevelErrorsEmployeeOrShopsName()}
					<table className='RenderCompanyProfileEmployeesTableLowNumberOfEmployees'>
						<thead className='RenderCompanyProfileEmployeesTableLowNumberOfEmployeesHead'>
						<tr> {this.renderMyEmployeesTableHeader()} </tr>
						</thead>
						<tbody className='RenderCompanyProfileEmployeesTableLowNumberOfEmployeesBody'>
						{this.decisionMakingHowManyRowsForEmployees()}
						</tbody>
					</table>
					{this.disclaimerConfirmationInformationEmployee()}
				</div>
			)

		} else {
			return(
				<div className='RenderCompanyProfileCompany'>
					{this.stepShopAndEmployees()}
					{this.checkHighLevelErrors()}
					{this.checkHighLevelErrorsEmployeeOrShopsName()}
					<table className='RenderCompanyProfileEmployeesTable'>
						<thead className='RenderCompanyProfileEmployeesTableHead'>
						<tr> {this.renderMyEmployeesTableHeader()} </tr>
						</thead>
						<tbody className='RenderCompanyProfileEmployeesTableBody'>
						{this.decisionMakingHowManyRowsForEmployees()}
						</tbody>
					</table>
					{this.disclaimerConfirmationInformationEmployee()}
				</div>
			)
		}
	}

	disclaimerConfirmationInformationEmployee = () => {
		return(
			<em className='disclaimerConfirmationInformationEmployee'>The person providing the corporate information above certifies its veracity, is responsible for it and acknowledges that the fields present above will exclusively refer to corporate information of employees.</em>
		)
	}

	stepShopAndEmployees = () => {
		if (this.state.Need === this.state.NeedCodes.Employees) {
			return (
				<div className='stepShopAndEmployeesDiv'>
					<span className='stepShopAndEmployees'>Add your employees' data</span>
				</div>
			)
		}
		if (this.state.Need === this.state.NeedCodes.New_shops) {
			return (
				<div className='stepShopAndEmployeesDiv'>
					<span className='stepShopAndEmployees'>Add your shops' data</span>
				</div>
			)
		}
	}


	decisionMakingHowManyRowsForEmployees = () => {
		//console.log(this.state);

		const NoOfRows_Sale = +this.returnCompanyProfile().Employees.length ;
		var array = [];

		for (let step = 0; step < NoOfRows_Sale; step++) {
			//console.log(step);
			array.push(this.renderEmployeesTableData(step));
		}
		//console.log(array);
		return array;
	}



	renderEmployeesTableData = (row_index) => {

		const Employee_index = +row_index + 1;

		return this.state.Free_employee.map((header, index) => {

				let inputsArrow = 'dropDownInSignUpTreatment';
				if (this.returnCompanyProfile().Employees[row_index].Treatment === ' ') {
					inputsArrow = 'dropDownInSignUpTreatmentEmpty'
				}
				//POLLA
				return (
					<tr key={Employee_index} data-rowid={row_index} onChange= {(e) => this.changeOnEmployeeTableRow(e)}>
						<td>{row_index+1}</td>

						<div className='inputSaleTableTreatment' >
							<Button className='dropdownInSettingsSignUp' block size="lg"
									value={this.returnCompanyProfile().Employees[row_index].Treatment}
									onClick={(e) => this.buttonInputFieldForDropdowns('Treatment', row_index)}
							> {this.returnCompanyProfile().Employees[row_index].Treatment}
								<div className='renderMyCompanyRightInputDropdownText' >
									{this.renderDropdownTextInInputDataTable('Employee', this.returnCompanyProfile().Employees[row_index].Employee, null)}
								</div>
								<img className={inputsArrow} alt="" />
							</Button>
							{this.DropDownAttribute('Treatment', row_index, 'true', 'Company' )}
						</div>

						<td> {this.inputEmployeeTablefunction('Name', row_index, null)} </td>
						<td> {this.inputEmployeeTablefunction('Surname', row_index, null)} </td>
						<td> {this.inputEmployeeTablefunction('Role', row_index, null)} </td>
						<td> {this.inputEmployeeTablefunction('Email', row_index, null)} </td>
					</tr>
				)
			}
		)
	}

	renderDropdownTextInInputDataTable = (parameter, parent, need) => {
		if (parameter === 'Employee') {
			if (parent !== undefined) {
				return parent.Treatment;
			} else {
				return '';
			}
		}
		if (parameter === 'Shop') {
			if (parent !== undefined) {
				if (need === 'Country') {
					return parent.Country;
				}
				if (need === 'Region') {
					return parent.Region;
				}
				if (need === 'Province') {
					return parent.Province;
				}
			} else {
				return '';
			}
		}
		return null;
	}


	inputEmployeeTablefunction = (data, index, value) => {
		// console.log(value);
		// console.log(this.state.Profile.Employees);

		if (data==='Treatment') {

			this.handleChooseChangeItem(value, data, index);
			return null;
		}

		if (data==='Name') {

			let classname = 'inputSaleTableSignUp';

			if (this.state.ShowErrorMessage === true &&
				this.returnCompanyProfile().Errors.Employees[index].Name === true) {
				// If there is an error on this e-mail address validation and he user has clicked Continue button.
				classname = 'inputSaleTable2';
			}

			return(
				<input
					// Martín
					type="text"
					className={classname}
					value= {this.returnCompanyProfile().Employees[index].Name}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, data, index)}
				/>
			)
		}

		if (data==='Surname') {
			return(
				<input
					type="text"
					className='inputSaleTableSignUp'
					value= {this.returnCompanyProfile().Employees[index].Surname}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, data, index)}
				/>
			)
		}

		if (data==='Role') {
			return(
				<input
					type="text"
					className='inputSaleTableSignUp'
					value= {this.returnCompanyProfile().Employees[index].Role}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, data, index)}
				/>
			)
		}

		if (data==='Email') {
			// console.log(this.returnCompanyProfile().Employees[index].Email);
			// console.log(this.returnCompanyProfile().Employees)

			let classname = 'inputSaleTableSignUp';

			if (this.state.ShowErrorMessage === true &&
				this.returnCompanyProfile().Errors.Employees[index].Email === true) {
				// If there is an error on this e-mail address validation and he user has clicked Continue button.
				classname = 'inputSaleTable2';
			}
			return (
				<input
					type="email"
					value={this.returnCompanyProfile().Employees[index].Email}
					className={classname}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, data, index)}
				/>
			)
		}

	}

	checkIsRowTotallyEmpty = (index, group) => {

		if (group === 'Employees') {
			let checkTreatment = !validate_string_is_not_empty_or_null_treatment(this.returnCompanyProfile().Employees[index].Treatment);
			let checkName = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().Employees[index].Name);
			let checkSurname = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().Employees[index].Surname);
			let checkRole = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().Employees[index].Role);
			let checkEmail_address = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().Employees[index].Email);

			// console.log(this.returnCompanyProfile().Employees)
			// console.log(checkTreatment)
			// console.log(checkName)
			// console.log(checkSurname)
			// console.log(checkRole)
			// console.log(checkEmail_address)

			return checkTreatment && checkName && checkSurname && checkRole && checkEmail_address;
		}

		if (group === 'Shops') {
			let checkName = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().New_shops[index].Name);
			let checkSurname = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().New_shops[index].Address);
			let checkRole = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().New_shops[index].Country);
			let checkRegion = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().New_shops[index].Region);
			let checkProvince = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().New_shops[index].Province);
			let checkPhone_number = !validate_string_is_not_empty_or_null(this.returnCompanyProfile().New_shops[index].Phone_number);

			return checkName && checkSurname && checkRole && checkRegion && checkProvince && checkPhone_number;
		}

		return null;
	}


	handleChooseChangeItem = (e, field, indice) => {
		// console.log('holi');
		this.createNewEmployeeRowInState(indice);
		// POLLA
		console.log(this.state.CompanyProfileErrors.Employees)
		console.log(this.checkIsRowTotallyEmpty(indice, 'Employees'))

		// Modify the state with the new input.
		if (field==='Treatment') {
			if (e === '(Empty)') {
				// Override the input and write Null
				e = null;
			}
			this.setState(
				Object.assign(this.state.CompanyProfile.Employees[indice], {Treatment: e }), () => {
					this.checkIfEmployeeNameErrorShouldBeTrue(indice)
				}
			);
		}

		if (field==='Name') {
			//POLLA
			this.setState(
				Object.assign(this.state.CompanyProfile.Employees[indice], {Name: e }), () => {
					this.checkIfEmployeeNameErrorShouldBeTrue(indice)
				}
			)
		}

		if (field==='Surname') {
			this.setState(
				Object.assign(this.state.CompanyProfile.Employees[indice], {Surname: e }), () => {
					this.checkIfEmployeeNameErrorShouldBeTrue(indice)
				}
			);
		}

		if (field==='Role') {
			this.setState(
				Object.assign(this.state.CompanyProfile.Employees[indice], {Role: e }), () => {
					this.checkIfEmployeeNameErrorShouldBeTrue(indice)
				}
			);
		}

		if (field==='Email') {
			// console.log(e);
			// console.log(this.state.CompanyProfile.Employees);
			// console.log(email_validation_or_empty(e));

			if (email_validation_or_empty(e)) {
				console.log(true);

				// No error
				this.setState(
					Object.assign(this.state.CompanyProfileErrors.Employees[indice], {
						Email: false }), () => {this.checkIfEmployeeNameErrorShouldBeTrue(indice)}
				);
				this.setState(
					Object.assign(this.state.CompanyProfile.Employees[indice], {Email: format_email_address(e) })
				);

			} else {
				console.log(this.state.CompanyProfileErrors);

				this.setState(
					Object.assign(this.state.CompanyProfile.Employees[indice], {Email: format_email_address(e) })
				);

				// Input validation error:
				// 1) Assign the error to the relevant email address
				// 2) Assign a high-level error boolean to the table for validation when clicking on Continue button
				this.setState(
					Object.assign(this.state.CompanyProfileErrors.Employees[indice], {
						Email: true }), () => {this.checkIfEmployeeNameErrorShouldBeTrue(indice)}
				)
			}
		}
		//console.log(this.state.CompanyProfile.Employees);

		return null;
	}


	checkIfEmployeeNameErrorShouldBeTrue = (indice) => {

		if (this.checkIsRowTotallyEmpty(indice, 'Employees')) {
			console.log('holi')
			// There cannot be any error on Name
			this.setState(
				Object.assign(this.state.CompanyProfileErrors.Employees[indice], {Name: false}), () => this.checkThereIsAnyInputValidationErrorInEmployees()
			);

		} else {
			// Row is not empty
			if (validate_string_is_not_empty_or_null(this.state.CompanyProfile.Employees[indice].Name) ) {
				console.log('holi')
				// Name was not provided
				this.setState(
					Object.assign(this.state.CompanyProfileErrors.Employees[indice], {Name: false}), () => this.checkThereIsAnyInputValidationErrorInEmployees()
				);
			} else {
				console.log('holi')
				// Name was provided
				this.setState(
					Object.assign(this.state.CompanyProfileErrors.Employees[indice], {Name: true}), () => this.checkThereIsAnyInputValidationErrorInEmployees()
				);
			}
		}

		return true
	}


	checkIfShopNameErrorShouldBeTrue = (indice) => {

		if (this.checkIsRowTotallyEmpty(indice, 'Shops')) {
			console.log('holi')
			// There cannot be any error on Name
			this.setState(
				Object.assign(this.state.CompanyProfileErrors.New_shops[indice], {Name: false}), () => this.checkThereIsAnyInputValidationErrorInShops()
			);

		} else {
			// Row is not empty
			if (validate_string_is_not_empty_or_null(this.state.CompanyProfile.New_shops[indice].Name) ) {
				console.log('holi')
				// Name was not provided
				this.setState(
					Object.assign(this.state.CompanyProfileErrors.New_shops[indice], {Name: false}), () => this.checkThereIsAnyInputValidationErrorInShops()
				);
			} else {
				console.log('holi')
				// Name was provided
				this.setState(
					Object.assign(this.state.CompanyProfileErrors.New_shops[indice], {Name: true}), () => this.checkThereIsAnyInputValidationErrorInShops()
				);
			}
		}

		return true
	}



	createNewEmployeeRowInState = (indice) => {
		// Adds a new line to the table in case the user has completed it (completed before appending the new line):

		if (+indice +1 === +this.state.CompanyProfile.Employees.length) {

			const new_employee = {ID: 0, Name: null, Surname: null, Role: null, Email: null, Treatment: ' '};
			const new_employee_error = {Name: false, Surname: false, Role: false, Email: false, Treatment: false};

			this.setState(
				Object.assign(this.state.CompanyProfile, {Employees: this.state.CompanyProfile.Employees.concat(new_employee)})
			);
			this.setState(
				Object.assign(this.state.CompanyProfileErrors, {
					Employees: this.state.CompanyProfileErrors.Employees.concat(new_employee_error)
				})
			);
			return null;
		} else { return null; }
	}



	clickOnEmployeeTableRow = (event) => {
		//console.log(event.currentTarget.getAttribute("data-rowid"));
		const id = event.currentTarget.getAttribute("data-rowid");
		this.setState({RoIndex: id});
		return id;
	}

	changeOnEmployeeTableRow = (event) => {

		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}




	renderMyEmployeesTableHeader = () => {

		const first_line = this.state.EmployeesTableHeader;

		//console.log(first_line);


		if (first_line === undefined) {
			return null;
		} else {

			let header = Object.keys(first_line)
			//console.log(header);
			return header.map((key, index_2) => {
				return <th key={index_2}>{key}</th>
			})
		}
	}

	renderMyEmployeesTableContent = () => {
		//console.log(this.state);

		const Employees_data = this.returnCompanyProfile().Employees;

		return Employees_data.map((header, index) => {

				const {ID, Treatment, Name, Surname, Role, Email} = header
				return (
					<tr key={ID} >
						<td>{Treatment}</td>
						<td>{Name}</td>
						<td>{Surname}</td>
						<td>{Role}</td>
						<td>{Email}</td>
					</tr>
				)
			}
		)
	}




	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//Here begins the New_shops' sub-tab

	RenderCompanyProfileShops = () => {

		if (this.returnCompanyProfile().New_shops.length < 12) {


			return (
				<div className='RenderCompanyProfileCompany'>
					{this.stepShopAndEmployees()}
					{this.checkHighLevelErrors()}
					{this.checkHighLevelErrorsEmployeeOrShopsName()}
					<table className='RenderCompanyProfileShopsTableLowNumberOfShops'>
						<thead className='RenderCompanyProfileShopsTableLowNumberOfShopsHead'>
						<tr> {this.renderMyShopsTableHeader()} </tr>
						</thead>
						<tbody className='RenderCompanyProfileShopsTableLowNumberOfShopsBody'>
						{this.decisionMakingHowManyRowsForShops()}
						</tbody>
					</table>
					{this.disclaimerConfirmationInformationShops()}
				</div>
			)
		}

		else {
			return (
				<div className='RenderCompanyProfileCompany'>
					{this.stepShopAndEmployees()}
					{this.checkHighLevelErrors()}
					{this.checkHighLevelErrorsEmployeeOrShopsName()}
					<table className='RenderCompanyProfileShopsTable'>
						<thead className='RenderCompanyProfileShopsTableHead'>
						<tr> {this.renderMyShopsTableHeader()} </tr>
						</thead>
						<tbody className='RenderCompanyProfileShopsTableBody'>
						{this.decisionMakingHowManyRowsForShops()}
						</tbody>
					</table>
					{this.disclaimerConfirmationInformationShops()}
				</div>
			)
		}
	}

	disclaimerConfirmationInformationShops = () => {
		return(
			<em className='disclaimerConfirmationInformationShops'>The person providing the corporate information above certifies and is responsible for its veracity.</em>
		)
	}

	checkHighLevelErrors = () => {

		if (this.state.ShowErrorMessage === true) {

			if (this.state.Need === this.state.NeedCodes.New_shops) {
				if (this.state.CompanyHighLevelError.New_shops.Phone_number === true) {
					return (
						<div className='checkThereIsAnyInputValidationErrorInShops'> Please, introduce a valid phone
							number or leave this field blank.</div>)
				} else {
					return null;
				}
			}

			if (this.state.Need === this.state.NeedCodes.Employees) {
				if (this.state.CompanyHighLevelError.Employees.Email === true) {
					return (
						<div className='checkThereIsAnyInputValidationErrorInEmployees'> Please, introduce a valid email
							address or leave this field blank</div>)
				} else {
					return null;
				}
			}
		} else {return null;}
	}

	checkHighLevelErrorsEmployeeOrShopsName = () => {
		// Renders the message "missing employee/shop name".

		// console.log('holi')
		// console.log(this.state.Need)
		// console.log(this.state.NeedCodes.New_shops)
		// console.log(this.state.Need === this.state.NeedCodes.New_shops)
		// console.log(this.state.Need === this.state.NeedCodes.Employees)

		if (this.state.Need === this.state.NeedCodes.Employees) {
			if (this.state.CompanyHighLevelError.Employees.Name === true) {
				return (
					<div className='checkThereIsAnyInputValidationErrorInEmployeesName'> Employee name must be
						provided.</div>)
			}
		}

		if (this.state.Need === this.state.NeedCodes.New_shops) {
			// console.log('holi')
			if (this.state.CompanyHighLevelError.New_shops.Name === true) {
				console.log('holi')
				return (
					<div className='checkThereIsAnyInputValidationErrorInShopsName'> Shop name must be
						provided.</div>)
			}
		}
		return null;
	}


	checkThereIsAnyInputValidationErrorInShops = () => {
		// LOL

		let array_check = [];
		const shops_errors = this.state.CompanyProfileErrors.New_shops;
		console.log(shops_errors);

		let shopsNames = shops_errors.map(value => value.Name);
		let shopsPhoneNumber = shops_errors.map(value => value.Phone_number);

		let countTrueInShopsNames = shopsNames.filter(x => x === true).length;
		let countTrueInShopsPhoneNumber = shopsPhoneNumber.filter(x => x === true).length;
		console.log(countTrueInShopsNames)

		if (countTrueInShopsNames > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.CompanyHighLevelError.New_shops, {Name: true })
			);
		}
		if (!countTrueInShopsNames > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.CompanyHighLevelError.New_shops, {Name: false })
			);
		}

		if (countTrueInShopsPhoneNumber > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.CompanyHighLevelError.New_shops, {Phone_number: true })
			);
		}
		if (!countTrueInShopsPhoneNumber > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.CompanyHighLevelError.New_shops, {Phone_number: false })
			);
		}
		// let array_check = [];
		// const shops_errors = this.state.CompanyProfileErrors.New_shops;
		//
		// for (let step = 0; step < shops_errors.length; step++) {
		// 	//console.log(step);
		// 	array_check.push(shops_errors[step].Phone_number);
		// }
		//
		// this.setState(
		// 	Object.assign(this.state.CompanyHighLevelError, {New_shops: array_check.includes(true)} )
		// );

		return null;

	}

	checkThereIsAnyInputValidationErrorInEmployees = () => {
		// LOL
		let array_check = [];
		const employees_errors = this.state.CompanyProfileErrors.Employees;
		console.log(employees_errors);

		let employeesNames = employees_errors.map(value => value.Name);
		let employeesEmails = employees_errors.map(value => value.Email);

		let countTrueInEmployeesNames = employeesNames.filter(x => x === true).length;
		let countTrueInEmployeesEmails = employeesEmails.filter(x => x === true).length;
		console.log(countTrueInEmployeesNames)

		if (countTrueInEmployeesNames > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.CompanyHighLevelError.Employees, {Name: true })
			);
		}
		if (!countTrueInEmployeesNames > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.CompanyHighLevelError.Employees, {Name: false })
			);
		}

		if (countTrueInEmployeesEmails > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.CompanyHighLevelError.Employees, {Email: true })
			);
		}
		if (!countTrueInEmployeesEmails > 0) {
			console.log('holi');
			this.setState(
				Object.assign(this.state.CompanyHighLevelError.Employees, {Email: false })
			);
		}

		return null;

	}


	decisionMakingHowManyRowsForShops = () => {

		const NoOfRows_Sale = this.returnCompanyProfile().New_shops.length;
		var array = [];

		for (let step = 0; step < NoOfRows_Sale; step++) {
			//console.log(step);
			array.push(this.renderShopsTableData(step));
		}
		//console.log(array);
		return array;
	}


	renderShopsTableData = (row_index2) => {
		// console.log(this.returnCompanyProfile());

		const Shop_index = +row_index2 + 1;

		//console.log(this.returnCompanyProfile().New_shops);
		return this.state.Free_shop.map((header, index) => {

				let inputsArrowCountry = 'dropDownInSignUpShop';
				let inputsArrowRegion = 'dropDownInSignUpShop';
				let inputsArrowProvince = 'dropDownInSignUpShop';

				if (this.returnCompanyProfile().New_shops[row_index2].Country === null) {
					inputsArrowCountry = 'dropDownInSignUpShopEmpty';
				}
				if (this.returnCompanyProfile().New_shops[row_index2].Region === null) {
					inputsArrowRegion = 'dropDownInSignUpShopEmpty';
				}
				if (this.returnCompanyProfile().New_shops[row_index2].Province === null) {
					inputsArrowProvince = 'dropDownInSignUpShopEmpty';
				}

				return (
					<tr key={Shop_index} data-rowid2={row_index2} onChange= {(e) => this.changeOnShopTableRow(e)}>
						<td>{row_index2+1}</td>
						<td> {this.inputShopTablefunction('Name', row_index2, null)} </td>
						<td> {this.inputShopTablefunction('Address', row_index2, null)} </td>

						<td>
							<div className='inputSaleTableCountry' >
								<Button className='dropdownInSettingsSignUp' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Country', row_index2)}
										title = {this.returnCompanyProfile().New_shops[row_index2].Country}
								>
									<div className='inputSaleTableCountryContent'> {this.returnCompanyProfile().New_shops[row_index2].Country} </div>
									<div className='renderMyCompanyRightInputDropdownText' >{this.renderDropdownTextInInputDataTable('Shop', this.returnCompanyProfile().New_shops[index].New_shops, null)}
									</div>
									<img className={inputsArrowCountry} alt=""/>
									{/*<div className={inputsArrowCountry} >  </div>*/}
								</Button>
								{this.DropDownAttribute('Country', row_index2, 'true', 'Company' )}
							</div>
						</td>

						<td>
							<div className='inputSaleTableRegion' >
								<Button className='dropdownInSettingsSignUp' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Region', row_index2)}
										title = {this.returnCompanyProfile().New_shops[row_index2].Region}
								>

									<div className='inputSaleTableCountryContent'> {this.returnCompanyProfile().New_shops[row_index2].Region} </div>
									<div className='renderMyCompanyRightInputDropdownText' >{this.renderDropdownTextInInputDataTable('New_shops', this.returnCompanyProfile().New_shops[index].New_shops, null)}
									</div>
									<img className={inputsArrowRegion} alt=""/>
									{/*<div className={inputsArrowRegion} >  </div>*/}
								</Button>
								{this.DropDownAttribute('Region', row_index2, 'true', 'Company' )}
							</div>
						</td>

						<td>
							<div className='inputSaleTableProvince' >
								<Button className='dropdownInSettingsSignUp' block size="lg"
										onClick={(e) => this.buttonInputFieldForDropdowns('Province', row_index2)}
										title = {this.returnCompanyProfile().New_shops[row_index2].Province}
								>

									<div className='inputSaleTableCountryContent'> {this.returnCompanyProfile().New_shops[row_index2].Province} </div>
									<div className='renderMyCompanyRightInputDropdownText' >{this.renderDropdownTextInInputDataTable('New_shops', this.returnCompanyProfile().New_shops[index].New_shops, null)}
									</div>
									<img className={inputsArrowProvince} alt=""/>
									{/*<div className={inputsArrowProvince} >  </div>*/}
								</Button>
								{this.DropDownAttribute('Province', row_index2, 'true', 'Company' )}
							</div>
						</td>

						<td> {this.inputShopTablefunction('Phone_number', row_index2, null)} </td>
					</tr>
				)
			}
		)
	}


	// inputShopTablefunction = (field, value) => {
	inputShopTablefunction = (data, index, value) => {
		// console.log(this.returnCompanyProfile().New_shops)
		// console.log(this.returnCompanyProfile().New_shops[index].Address)
		//console.log(indice);
		// POLLA

		if (data === 'Name') {
			// Render correct input field's classname based on input validation.
			let classNameInput = 'inputSaleTableSignUp';
			if (this.state.ShowErrorMessage && this.returnCompanyProfile().Errors.New_shops[index].Name === true) {
				classNameInput = 'inputSaleTable2';
			}
			return (
				<input
					type="text"
					className={classNameInput}
					value = {this.returnCompanyProfile().New_shops[index].Name}
					onChange={(e) => this.handleEditShopItem(e.target.value, data, index)}
				/>
			)
		}

		if (data === 'Address') {
			return (
				<input
					// Martín
					type="text"
					className='inputSaleTableSignUpAddress'
					value = {this.returnCompanyProfile().New_shops[index].Address}
					onChange={(e) => this.handleEditShopItem(e.target.value, data, index)}
				/>
			)
		}

		if (data === 'Country') {

			this.handleEditShopItem(value, data, index);
			return null;
		}

		if (data === 'Province') {
			this.handleEditShopItem(value, data, index);
			return null;
		}

		if (data === 'Region') {
			this.handleEditShopItem(value, data, index);
			return null;
		}

		if (data === 'Phone_number') {

			// Render correct input field's classname based on input validation.
			let classNameInput = 'inputSaleTableSignUp';
			if (this.state.ShowErrorMessage && this.returnCompanyProfile().Errors.New_shops[index].Phone_number === true) {
				classNameInput = 'inputSaleTable2';
			}
			return (
				<input
					type="text"
					className={classNameInput}
					value = {this.returnCompanyProfile().New_shops[index].Phone_number}
					onChange={(e) => this.handleEditShopItem(e.target.value, data, index)}
				/>
			)

			// if (email_validation_or_empty(e)) {
			// 	console.log(true);
			//
			// 	// No error
			// 	this.setState(
			// 		Object.assign(this.returnCompanyProfile().Errors.Employees[index], {
			// 			Email: false }), () => {this.checkThereIsAnyInputValidationErrorInEmployees()}
			// 	);
			// 	this.setState(
			// 		Object.assign(this.returnCompanyProfile().Employees[index], {Email: e })
			// 	);
			//
			// } else {
			// 	console.log(this.returnCompanyProfile().Errors);
			//
			// 	this.setState(
			// 		Object.assign(this.returnCompanyProfile().Employees[index], {Email: e })
			// 	);
			//
			// 	// Input validation error:
			// 	// 1) Assign the error to the relevant email address
			// 	// 2) Assign a high-level error boolean to the table for validation when clicking on Continue button
			// 	this.setState(
			// 		Object.assign(this.returnCompanyProfile().Errors.Employees[index], {
			// 			Email: true }), () => {this.checkThereIsAnyInputValidationErrorInEmployees()}
			// 	)
			// }








		} else {
			return null;
		}


	}


	handleEditShopItem = (e, field, indice) => {
		//console.log(indice);
		this.createNewShopRowInState(indice);

		//console.log(this.state);
		//console.log(this.state.CompanyProfile.New_shops);
		// POLLA


		// Modify the state with the new input.
		if (field==='Address') {
			this.setState(
				Object.assign(this.state.CompanyProfile.New_shops[indice], {Address: e }), () => {
					this.checkIfShopNameErrorShouldBeTrue(indice)
				}
			);
		}

		if (field==='Name') {
			this.setState(
				Object.assign(this.state.CompanyProfile.New_shops[indice], {Name: e }), () => {
					this.checkIfShopNameErrorShouldBeTrue(indice)
				}
			);
		}

		if (field==='Country') {
			console.log(e);
			if (e === '(Empty)') {
				// Override the input and write Null
				e = null;
				// Remove Province and Region
				this.setState(
					Object.assign(this.state.CompanyProfile.New_shops[indice], {Region: e})
				);
				this.setState(
					Object.assign(this.state.CompanyProfile.New_shops[indice], {Province: e})
				);
			}
			this.setState(
				Object.assign(this.state.CompanyProfile.New_shops[indice], {Country: e }), () => {
					this.checkIfShopNameErrorShouldBeTrue(indice)
				}
			);
		}

		if (field==='Province') {
			if (e === '(Empty)') {
				// Override the input and write Null
				e = null;
			}
			this.setState(
				Object.assign(this.state.CompanyProfile.New_shops[indice], {Province: e }), () => {
					this.checkIfShopNameErrorShouldBeTrue(indice)
				}
			);
		}

		if (field==='Region') {
			if (e === '(Empty)') {
				// Override the input and write Null
				e = null;
				// Remove Province
				this.setState(
					Object.assign(this.state.CompanyProfile.New_shops[indice], {Province: e})
				);
			}
			this.setState(
				Object.assign(this.state.CompanyProfile.New_shops[indice], {Region: e }), () => {
					this.checkIfShopNameErrorShouldBeTrue(indice)
				}
			);
		}
		if (field==='Phone_number') {

			if (validate_integers_or_null(e)) {

				// No error
				this.setState(
					Object.assign(this.state.CompanyProfileErrors.New_shops[indice], {
						Phone_number: false }), () => {this.checkIfShopNameErrorShouldBeTrue(indice)}
				);
				this.setState(
					Object.assign(this.state.CompanyProfile.New_shops[indice], {Phone_number: e })
				);

			} else {
				// Input validation error
				this.setState(
					Object.assign(this.state.CompanyProfileErrors.New_shops[indice], {
						Phone_number: true }), () => {this.checkIfShopNameErrorShouldBeTrue(indice)}
				);

				this.setState(
					Object.assign(this.state.CompanyProfile.New_shops[indice], {Phone_number: e })
				);

			}

		}

		return null;
	}


	createNewShopRowInState = (indice) => {
		// Adds a new line to the table in case the user has completed it (completed before appending the new line):

		if (+indice +1 === +this.state.CompanyProfile.New_shops.length) {
			//console.log('true');

			const new_shop = {ID: +indice +1, Address: null,  Country: null, Region: null, Province: null, Name: null,
				Phone_number: null};
			const new_shop_error = {Name: false, Address: false, Country: false, Province: false, Region: false,
				Phone_number: false};

			this.setState(
				Object.assign(this.state.CompanyProfile, {New_shops: this.state.CompanyProfile.New_shops.concat(new_shop) })
				//, () => {this.replicateCompanyGeographyToShops(null, 'NewAll', indice)}
			);
			this.setState(
				Object.assign(this.state.CompanyProfileErrors, {
					New_shops: this.state.CompanyProfileErrors.New_shops.concat(new_shop_error) })
			);

		}
		return null;
	}



	clickOnShopTableRow = (event) => {
		//console.log(event.currentTarget.getAttribute("data-rowid2"));
		const id = event.currentTarget.getAttribute("data-rowid2");
		this.setState({RoIndex: id});
		return id;
	}

	changeOnShopTableRow = (event) => {

		const id = event.currentTarget.getAttribute("data-rowid2");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}




	EditShopsMessage() {
		return (
			<div className= 'NewSaleMessage'>
				<h1 id='title'>{'Edit your shops details'}</h1>

			</div>
		)
	}

	renderMyShopsTableHeader = () => {

		const first_line = this.state.ShopsTableHeader;
		//console.log(first_line);


		if (first_line === undefined) {
			return null;
		} else {

			let header = Object.keys(first_line)
			//console.log(header);
			return header.map((key, index_2) => {
				return <th key={index_2}>{key}</th>
			})
		}
	}

	renderMyShopsTableContent = () => {

		const Shops_data = this.returnCompanyProfile().New_shops;


		return Shops_data.map((header, index) => {

				const {ID, Name, Address, Country, Province, Region} = header
				return (
					<tr key={ID}>
						<td>{Name}</td>
						<td>{Address}</td>
						<td>{Country}</td>
						<td>{Province}</td>
						<td>{Region}</td>
					</tr>
				)
			}
		)
	}

	popupConfirmSaveAndContinue = (parameter) => {

		let info = <span>You are about to leave the registration process <b>without</b> entering all information required for the correct behaviour of the tool. You may provide it later under "My account" tab.</span>;

		if (this.state.hasUserEnteredAllRequiredFields === true) {
			info = <span>You are about to leave the registration process now. You may provide the rest of your company\'s information later under "My account" tab.</span>
		}

		if (this.state.popupConfirmSaveAndContinue === 'True') {
			return [
				<div className='popupConfirmSaveAndContinue'>
					<div className='popupConfirmSaveAndContinueInterior'>
						{info}
						<div className='popupConfirmSaveAndContinueButtons'>
							<Button className="popupConfirmSaveAndContinueCancel" block size="lg" type="submit" onClick={(e) => this.cancelSaveAndContinueLater()}>
								{'Cancel'}
							</Button>

							<Button className="popupConfirmSaveAndContinueConfirm" block size="lg" type="submit" onClick={(e) => this.confirmSaveAndContinueLater(parameter)}>
								{'Confirm'}
							</Button>
						</div>
					</div>
				</div>
			]
		} else {
			return null;
		}
	}

	Obscurer = () => {
		if (this.state.popupConfirmSaveAndContinue === 'True' || (this.state.hasUserEnteredAllRequiredFields !== true && this.state.popupUserMissingInformation === true) || this.state.whichLegalTextIsRequired !== null) {
			return (
				<div className='ObscurerSignUp'>
					{''}
				</div>
			)
		} else {
			return null;
		}
	}

	ObscurerLegalTexts = () => {
		if (this.state.whichLegalTextIsRequired !== null) {
			return (
				<div className='ObscurerLegalTexts'>
					{''}
				</div>
			)
		} else {
			return null;
		}
	}

	cancelSaveAndContinueLater = () => {
		this.setState({popupConfirmSaveAndContinue: 'False'});
		return null;
	}

	confirmSaveAndContinueLater = (parameter) => {

		this.whichPopupShouldBeReturned(parameter);
		return null;
	}

	RedirectToLandingPageWithPopUp = (code) => {
		console.log(code.code);

		console.log(code);
		let LP = this.state.LP;

		// let code = 1; // Insufficient info
		return (
			<Redirect to={{pathname: LP,
				popup: code.code
			}}/>
		)
	}

	 whichPopupShouldBeReturned = (parameter) => {

		let check = this.areAllMandatoryFieldsFilledIn(this.returnMandatoryFields());

		// Update the user information on the background
		this.precheckSendCompanyProfileOnShops(parameter, false);

		if (check === false) {
			// We are missing some critical information for the correct behaviour of the tool.
			// let code = this.state.popupCodeInLandingPage['Critical'];
			this.setState({Need: this.state.NeedCodes.Critical});
		}
		if (check === true) {
			// We are not missing any critical information for the correct behaviour of the tool.
			// let code = this.state.popupCodeInLandingPage['Non-critical'];
			this.setState({Need: this.state.NeedCodes.NonCritical});
		}

		return null;
	}

	returnMandatoryFields = () => {
		if (this.state.checkboxCompany === true) {
			// Check the Company profile fields
			let company_data = this.returnCompanyProfile().Company;

			let country = company_data.Country;
			let region = company_data.Region;
			let province = company_data.Province;
			let address = company_data.Address;
			let postal_code = company_data.Postal_code;
			let tfn = company_data.TFN;
			let currency = company_data.Currency;
			let sector = company_data.Sector;
			let subsector = company_data.Subsector;

			let company_profile = {
				'country': country, 'region': region, 'province': province, 'address': address,
				'postal_code': postal_code, 'tfn': tfn, 'currency': currency, 'sector': sector, 'subsector': subsector
			}

			return company_profile
		} else {
			// Check the Person profile fields
			let particular_data = this.state.PersonProfile;

			let nickname = particular_data.Nickname;
			let Birth_date = particular_data.Birth_date;
			let genre = particular_data.Genre;
			let postal_code = particular_data.Postal_code;

			let particular_profile = {
				'nickname': nickname, 'Birth_date': Birth_date, 'genre': genre, 'postal_code': postal_code
			}

			return particular_profile
		}
	}

	areAllMandatoryFieldsFilledIn = (dictionary) => {

		for (var key in dictionary) {
			if (dictionary[key] === null) {
				return false;
			}
		}
		return true
	}

	RedirectToLandingPage = () => {
		return(
			<Redirect  to='/MyArea'/>
		)
	}

	HR = () => {
		return (
			<div className='Horizontal_lines_container_SignUp'>

				<hr className="HR_line"/>

			</div>
		)
	}

	LogInMessageToRedirect = () => {
		return (
			<span className='Sign_up_log_in_text'>{'Already have an account?'}</span>
		)
	}

	LogInButtonToRedirect = () => {
		return [
			// <Button className="Sign_up_button_log_in" block size="lg" onClick = {() => this.Need_for_changing_page('LogIn')}>
			// 	{'Log in'}
			// </Button>,

			<Link to='/LogIn'>
				<Button className="Sign_up_button_log_in" block size="lg">
					{'Log in'}
				</Button>
			</Link>

		];
	}


	VerificationCodeSentMessage = () => {
		if (this.state.Coming_from_Log_In === false) {
			return (
				<span className='VerificationCodeSentMessage'>{'We have sent a verification code to your e-mail address. Please introduce it below.'} </span>
			)
		} else {
			return (
				<span className='VerificationCodeSentMessage'>{'You have not validated your account yet. We have already sent you a code ' +
				'to your e-mail address to successfully complete your registrarion.'} </span>
			)
		}

	}

	updateProfileWithEmail = () => {
		// console.log('hola');

		// this function passes the Email introduced on the first screen to the profile itself.

		if (this.state.checkboxCompany === true) {
			this.setState(
				Object.assign(this.returnCompanyProfile().Company, {Email: format_email_address(this.state.Email)})
			);
		} else {
			this.setState(
				Object.assign(this.state.PersonProfile, {Email: format_email_address(this.state.Email)})
			);
			console.log(this.state.PersonProfile);
		}
		return null;

	}

	messageEmailIsNotValid = () => {
		if (this.state.ErrorMessage === 'InvalidEmail') {
			return <div className='messageEmailIsNotValid'> Email is not valid. </div>
		} else {return null}
	}


	messagePasswordsToBeTheSame = () => {
		// TODO: Martín - mensajes de error - definir un archivo externo para ponerlos todos
		if (this.state.ErrorMessage === 'PasswordNotProvided') {
			return <div className='messagePasswordNotProvided'> Password is not provided </div>
		}

		if (this.state.ErrorMessage === 'IncorrectPasswordFormat') {
			return <div className='messageIncorrectPasswordFormat'> Please, check the password format. </div>
		}

		if (this.state.ErrorMessage === 'PasswordsToBeTheSame') {
			return <div className='messagePasswordsToBeTheSame'> Passwords have to be the same. </div>
		}

		if (this.state.ErrorMessage === 'AlreadyExistingAccount') {
			return <div className='AlreadyExistingAccount'> This email address is linked to an account. Please, log in. </div>
		}
		if (this.state.ErrorMessage === 'UnknownErrorInSignUp1') {
			return <div className='AlreadyExistingAccount'> Something went wrong. Please, notify support at help@tidisystems.com </div>
		}

		return null;
	}


	messageUntickedCheckboxes = () => {
		// TODO: Martín - mensajes de error - definir un archivo externo para ponerlos todos
		if (this.state.ErrorMessage === 'UntickedCheckboxes') {
			return <div className='messageUntickedCheckboxes'> The account creation requires all terms acceptance </div>
		} else {return null}
	}





	messageIncorrectVerificationCode = () => {
		// TODO: Martín - mensajes de error - definir un archivo externo para ponerlos todos

		if (this.state.ErrorMessage === 'IncorrectVerificationCode') {
			let div = 'messageIncorrectVerificationCodeSignUp';
			if (this.state.Coming_from_Log_In) {
				div = 'messageIncorrectVerificationCodeSignUpFromLogIn'
			}
			return <div className={div}> Incorrect verification code</div>
		}
		if (this.state.ErrorMessage === 'UnknownErrorInSignUp2') {
			// TODO: Martín - ídem correo
			return <div className='messageUnknownErrorInSignUp2SignUp'> Something went wrong. Please, notify at hola@tidisystems.com</div>
		}
		if (this.state.ErrorMessage === 'StringInVerificationCode') {
			console.log('holi');
			console.log(this.state.Coming_from_Log_In)

			if (this.state.Coming_from_Log_In === true) {
				return <div className='messageStringInVerificationCodeFromLogIn'> Please, introduce only digits </div>
			} else {
				return <div className='messageStringInVerificationCode'> Please, introduce only digits </div>
			}
		}
		else {return null}
	}


	// showLegalTextSignUp = () => {
	//
	// 	let legalText = this.state.whichLegalTextIsRequired;
	// 	let thisDiv = 'showLegalTextBigSignUp';
	// 	if (legalText === 'cookiesSelection') {
	// 		thisDiv = 'showLegalTextSmallSignUp'
	// 	}
	//
	// 	if (legalText !== null) {
	//
	// 		return [
	// 			<div className={thisDiv}>
	// 				{this.showLegalTextDecider(legalText)}
	// 				{this.buttonCloseLegalText()}
	// 			</div>
	// 		]
	// 	}
	//
	// 	return null;
	// }
	//
	//
	// showLegalTextDecider = (legalText) => {
	//
	// 	var showLegalTextDeciderTitle = null;
	// 	var showLegalTextDeciderText = null;
	//
	// 	if (legalText === 'legalNotice') {
	// 		showLegalTextDeciderTitle = legalNoticeTitle;
	// 		showLegalTextDeciderText = legalNoticeText;
	// 	}
	// 	if (legalText === 'cookiesSelection') {
	// 		showLegalTextDeciderTitle = this.CookiesMenuChangePreferences();
	// 		// showLegalTextDeciderText = 'text';
	// 		// showLegalTextDeciderText = this.CookiesMenuChangePreferences();
	// 	}
	// 	if (legalText === 'cookiesDetail') {
	// 		showLegalTextDeciderTitle = 'Cookies';
	// 		showLegalTextDeciderText = cookiesDetailText;
	// 	}
	// 	if (legalText === 'privacyPolicy') {
	// 		showLegalTextDeciderTitle = privacyPolicyTitle;
	// 		let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" block size="lg" onClick={() => {this.setState({CookiesDetailsRequired: true})}}>
	// 			{'Política de Cookies'}
	// 		</span>
	// 		showLegalTextDeciderText = privacyPolicyText(privacyPolicyButton1);
	// 	}
	// 	if (legalText === 'GTC') {
	// 		showLegalTextDeciderTitle = decideWhichGTCToDisplay().GTCTitle;
	// 		showLegalTextDeciderText = decideWhichGTCToDisplay().GTCText;
	// 	}
	//
	// 	return(
	// 		<div className='showLegalTextDecider'>
	// 			<div className='showLegalTextDeciderTitle'>{showLegalTextDeciderTitle}</div>
	// 			{/*<div className='showLegalTextDeciderTextSignUp'>{showLegalTextDeciderText}</div>*/}
	// 			<div className='showLegalTextDeciderTextSignUp'><div className='showLegalTextDeciderTextMyAreaText'>{showLegalTextDeciderText}</div></div>
	// 		</div>
	// 	)
	// }

	buttonCloseLegalText = (legalText) => {

		var buttonDiv = 'buttonCloseLegalTextSignUp';
		if (legalText === 'ContactSupport') {
			buttonDiv = 'buttonCloseLegalTextSignUpContactSupport';
		}
		return(
			<div className={buttonDiv}>
				<Button className="buttonCloseLegalTextButton" block size="lg" onClick={() => {this.setState({whichLegalTextIsRequired: null});
					// Make the obscurer appear
					this.setState({ObscurerRequired: false})}}>
					{'X'}
				</Button>
			</div>
		)
	}




	WaitingForRequest = () => {

		if (this.state.WaitingForRequest === true) {
			return (
				<div className='loading'>
					<img className="loading-image" src={loadingPictureCode} alt='' />
				</div>
			)
		} else {return null;}
	}


	genericErrorWhenConnectingToPythonInUserRequest = () => {
		if (this.state.ErrorMessage === 'GenericError') {
			console.log('holi')
			return (
				<div className='errorWhenLoadingLogIn'>
					<div className='errorWhenLoadingLogInText'> An error occurred. Please, contact support  </div>
				</div>
			)
		} else {
			return null;
		}
	}

	legalTextContainers = () => {

		// let divCookiesSelection = 'legalTextContainers22';
		// let divGTC = 'legalTextContainers42';

		let TIDIName = '© 2021 The TIDI Systems GmbH. All rights reserved.'

		return (
			<div className='legalTextContainersSignUp'>
				<div className='legalTextContainersColumn'>
					<Button className="legalTextContainers1" block size="lg" onClick={() => {
						this.requiringSomeLegalText('legalNotice')
					}}>
						{'Legal notice'}
					</Button>
				</div>

				<div className='legalTextContainersColumn'>
					<Button className='licensesButtonLogIn' block size="lg" onClick={() => {
						this.requiringSomeLegalText('Licenses')
					}}>
						{'Third-party open source licenses'}
					</Button>
				</div>

				<div className='legalTextContainersColumn'>
					<Button className='ContactSupportLogIn' block size="lg" onClick={() => {
						this.requiringSomeLegalText('ContactSupport')
					}}>
						{'Contact support'}
					</Button>
				</div>

				<div className='legalTextContainersColumn'>
					<Button className='TIDINameLogIn' block size="lg"> {TIDIName} </Button>
				</div>
			</div>
		)
	}


	requiringSomeLegalText = (legalText) => {

		this.setState({whichLegalTextIsRequired: legalText})

		return null;

	}

	showLegalText = () => {

		let legalText = this.state.whichLegalTextIsRequired;
		let thisDiv = 'showLegalTextBigLogIn';
		if (legalText === 'ContactSupport') {
			thisDiv = 'showLegalTextDeciderTitleDivSupportLogIn'
		}

		if (legalText !== null) {

			return [
				this.Obscurer(),
				<div className={thisDiv}>
					{this.showLegalTextDecider(legalText)}
					{this.buttonCloseLegalText(legalText)}
				</div>
			]
		}
		return null;
	}


	showLegalTextDecider = () => {
		let legalText = this.state.whichLegalTextIsRequired;

		var showLegalTextDeciderTitle = null;
		var showLegalTextDeciderText = null;
		var showLegalTextDeciderTitleDiv = 'showLegalTextDeciderTitle';
		var showLegalTextDeciderTextDiv = 'showLegalTextDeciderTextLogIn';
		var showLegalTextDeciderTextMyAreaTextInterior = 'showLegalTextDeciderTextMyAreaTextLogIn';

		if (legalText === 'legalNotice') {

			let buttonPrivacy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'})
			}}>
				{'Privacy policy'}
			</span>;

			let buttonCookiesPolicy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>;
			showLegalTextDeciderTitle = legalNoticeTitle;
			showLegalTextDeciderText = legalNoticeText(buttonPrivacy, buttonCookiesPolicy);
		}
		if (legalText === 'cookiesSelection') {
			showLegalTextDeciderTitle = this.CookiesMenuChangePreferences();
			// showLegalTextDeciderText = 'text';
			// showLegalTextDeciderText = this.CookiesMenuChangePreferences();
		}
		if (legalText === 'cookiesDetail') {
			showLegalTextDeciderTitle = 'Cookies';
			showLegalTextDeciderText = 'cookies text';
		}
		if (legalText === 'privacyPolicy') {
			showLegalTextDeciderTitle = privacyPolicyTitle;
			let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>
			showLegalTextDeciderText = privacyPolicyText(privacyPolicyButton1);
		}
		if (legalText === 'GTC') {
			let privacyPolicyButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({CookiesDetailsRequired: true})
			}}>
				{'Cookies policy'}
			</span>
			let licensesButton1 = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'Licenses'})
			}}>
				{'Open Source licenses'}
			</span>
			let buttonPrivacy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'})
			}}>
				{'Privacy policy'}
			</span>

			showLegalTextDeciderTitle = decideWhichGTCToDisplay(licensesButton1, privacyPolicyButton1, buttonPrivacy).GTCTitle;
			showLegalTextDeciderText = decideWhichGTCToDisplay(licensesButton1, privacyPolicyButton1, buttonPrivacy).GTCText;
		}
		if (legalText === 'Licenses') {
			showLegalTextDeciderTitle = licensesTitle;
			showLegalTextDeciderText = this.allLicenseTextsButtons();
		}
		if (legalText === 'ContactSupport') {
			showLegalTextDeciderTitleDiv = 'showLegalTextDeciderTitleDivSupportTitle';
			showLegalTextDeciderTextDiv = 'showLegalTextDeciderTextDivSupport';
			showLegalTextDeciderTitle = <b>CONTACT SUPPORT</b>;
			showLegalTextDeciderText = 'We are more than glad to give you a hand! Please, write an e-mail to hola@tidisystems.com to reach out for help :)';
			showLegalTextDeciderTextMyAreaTextInterior = 'showLegalTextDeciderTextDivSupportInterior';
		}


		return (
			<div className='showLegalTextDecider'>
				<div className={showLegalTextDeciderTitleDiv}>{showLegalTextDeciderTitle}</div>
				<div className={showLegalTextDeciderTextDiv}>
					<div className={showLegalTextDeciderTextMyAreaTextInterior}><div className={showLegalTextDeciderTextMyAreaTextInterior}>{showLegalTextDeciderText}</div></div>
				</div>
			</div>
		)
	}



	tableAnalyticsCookies = (type) => {
		return(
			<div className='tableAnalyticsCookies'>
				<Table  className='tableAnalyticsCookiesTable'>
					<thead className='tableAnalyticsCookiesTableHeader'>
					<tr>{this.rendertableAnalyticsCookiesTableHeader()}</tr>
					</thead>
					<tbody className='tableAnalyticsCookiesTableTBody'>
					{this.rendertableAnalyticsCookiesTableData(type)}
					</tbody>
				</Table>
			</div>
		)
	}

	rendertableAnalyticsCookiesTableHeader = () => {

		var first_line = this.state.analyticsCookiesHeader[0];

		if (first_line === undefined) {
			return null;
		} else {
			console.log(first_line)
			let header = Object.values(first_line)
			return header.map((key, index) => {
				return <th key={index}>{key}</th>
			})
		}
	}


	rendertableAnalyticsCookiesTableData = (type) => {

		let tableCookiesBody = Object.values(this.state.analyticsCookiesBody);
		if (type === 'technical') {
			console.log('hol')
			tableCookiesBody = Object.values(this.state.technicalCookiesBody);
		}
		return tableCookiesBody.map((eachRow, index) => {
			let values = Object.values(eachRow);
			const [
				ID, Name, Provider, Goal, Duration
			] = values //destructuring

			return (
				<tr key={ID}>
					<td>{Name}</td>
					<td>{Provider}</td>
					<td>{Goal}</td>
					<td>{Duration}</td>
				</tr>
			)
		})
	}



	showCookiesDetails = () => {

		if (this.state.CookiesDetailsRequired === true) {
			// CookiesPolicyRequired
			// CookiesDetailsRequired

			var showLegalTextDeciderTitle = cookiesDetailTitle;

			let tablaTecnicas = this.tableAnalyticsCookies('technical');
			let tablaAnaliticas = this.tableAnalyticsCookies('analytics');

			let buttonCookiesSummary = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'cookiesSelection'}, () => {
					this.setState({CookiesDetailsRequired: false})
				})
			}}>
                    {'Configurador de Cookies'}
                </span>;
			let buttonPrivacyPolicy = <span className="buttonToRedirectInTexts" onClick={() => {
				this.setState({whichLegalTextIsRequired: 'privacyPolicy'}, () => {
					this.setState({CookiesDetailsRequired: false})
				})
			}}>
                        {'Política de Privacidad'}
                    </span>;
			var showLegalTextDeciderText = cookiesDetailText(buttonCookiesSummary, buttonPrivacyPolicy, tablaTecnicas, tablaAnaliticas);

			return (
				<div className='showLegalTextBigProfile'>
					<div className='showLegalTextDecider'>
						<div className='showLegalTextDeciderTitle'>{showLegalTextDeciderTitle}</div>
						<div className='showLegalTextDeciderTextMyArea'><div className='showLegalTextDeciderTextMyAreaText'> {showLegalTextDeciderText}</div></div>
					</div>
					<Button className="showCookiesDetailsCloseButton" onClick={() => {
						this.setState({CookiesDetailsRequired: false})
					}}>
						{'X'}
					</Button>
				</div>
			)
		} else {
			return null;
		}
	}
	changeStateLandingPageForLicenses = (value) => {

		this.setState({licenseText: value});

		return null;

	}

	allLicenseTextsButtons = () => {
		// Returns all buttons of licenses texts

		let licensesButtonsClass = new renderLicensesButtons();
		let licensesButtons = licensesButtonsClass.buttonObjects(this.changeStateLandingPageForLicenses);

		let licenseIntroText = <div className='allLicenseTextsButtonsIntro'>{licensesIntroText}</div>;
		let licensesButtonMIT = licensesButtons['licensesButtonMIT'];
		let licensesButtonBSD2 = licensesButtons['licensesButtonBSD2'];
		let licensesButtonApache2 = licensesButtons['licensesButtonApache2'];
		let licensesButtonBSD3 = licensesButtons['licensesButtonBSD3'];
		let licensesButtonHPND = licensesButtons['licensesButtonHPND'];
		let licensesButtonISC = licensesButtons['licensesButtonISC'];
		let licensesButtonPostgreSQL = licensesButtons['licensesButtonPostgreSQL'];
		let licensesButtonPython = licensesButtons['licensesButtonPython'];
		let licensesButtonUnlicense = licensesButtons['licensesButtonUnlicense'];
		let licensesButtonZope = licensesButtons['licensesButtonZope'];

		let allLicenseButtons = <div className='allLicenseTextsButtonsButtons'>
			{licensesButtonApache2}
			{licensesButtonBSD2}
			{licensesButtonBSD3}
			{licensesButtonHPND}
			{licensesButtonISC}
			{licensesButtonMIT}
			{licensesButtonPostgreSQL}
			{licensesButtonPython}
			{licensesButtonUnlicense}
			{licensesButtonZope}
		</div>
		let licenseAnyText = <div className='allLicenseTextsButtonsText'>{this.decideLicenseText()}</div>;

		return <div className='allLicenseTextsButtons'>
			{licenseIntroText}
			{allLicenseButtons}
			{licenseAnyText}
		</div>
	}

	decideLicenseText = () => {

		let text = this.state.licenseText;
		let Text = null;
		if (text === 'MIT') {
			Text = licenseMITText;
		}
		if (text === 'BSD2') {
			Text = licenseBSD2ext;
		}
		if (text === 'Apache2') {
			Text = licenseApache2Text;
		}
		if (text === 'BSD3') {
			Text = licenseBSD3Text;
		}
		if (text === 'HPND') {
			Text = licenseMITText;
		}
		if (text === 'ISC') {
			Text = licenseISCText;
		}
		if (text === 'PostgreSQL') {
			Text = licensePostgreSQLText;
		}
		if (text === 'Python') {
			Text = licensePythonSFText;
		}
		if (text === 'Unlicense') {
			Text = licenseUnlicenseText;
		}
		if (text === 'Zope') {
			Text = licenseZopePLText;
		}

		return Text;
	}


	returnCompanyProfile = () => {

		if (isUserACompany()) {return this.state.CompanyProfile}
		if (isUserAPSI()) {return this.state.PSIProfile}
		else return null;
	}




	render() {

		console.log('lolcito')
		if (this.state.ChangePage === 'MyArea') {
			console.log('aquí')
			return (
				<div className='BackgroundSignUp'> <this.Redirect_to_page data={'MyArea'}/> </div>
			)
		}

		if (this.state.ChangePage === 'LogIn') {
			return (
				<div className='BackgroundSignUp'> <this.Redirect_to_page data={'LogIn'}/> </div>
			)
		}

		if (this.state.Need===this.state.NeedCodes.Landing) {
			// Decide if we should go directly to the Code_validation screen.

			// // Evaluate if there's any fixed value for Company checkbox
			// this.RestartAsACompany()

			this.prevalidation();
			return [
				<div className='BackgroundSignUp'/>,
				this.WaitingForRequest(),
				<div className="Sign_up_general_container">
					{this.SignUpTitleBackButton()}
					{this.SignUpTitle()}
					{this.SignUpForm()}
					{this.signUpCheckboxes()}
					{this.messageEmailIsNotValid()}
					{this.messagePasswordsToBeTheSame()}
					{this.messageUntickedCheckboxes()}
					{/*{this.sentenceTermsAndConditions()}*/}
					{this.SignUpButton()}
					{this.HR()}
					{this.LogInMessageToRedirect()}
					{this.LogInButtonToRedirect()}
					{this.ObscurerLegalTexts()}
				</div>,
				this.genericErrorWhenConnectingToPythonInUserRequest(),
				this.legalTextContainers(),
				this.showCookiesDetails(),
				this.showLegalText()
			]
		}
		if (this.state.Need === this.state.NeedCodes.ValidateCode) {
			//I have created the account and want now to validate it by the code received.
			return [
				<div className='BackgroundSignUp'/>,
				this.WaitingForRequest(),
				<div className = "Sign_up_general_container">
					{this.SignUpTitleBackButton()}
					{this.SignUpTitle()}
					{this.VerificationCodeSentMessage()}
					{this.VerifyCode()}
					{this.messageIncorrectVerificationCode()}
					{this.validateCodeButton()}
					{this.sendAgainButton()}
				</div>,
				this.genericErrorWhenConnectingToPythonInUserRequest()
			]
		}
		if (this.state.Need === this.state.NeedCodes.ValidateCode.NewCode) {
			//Here, the user has requested a new validation code. This is a step required when sending the code - it does not perform any business logic
			//Reason: cannot change the state and perform the request at the same time
			return [
				<div className='BackgroundSignUp'/>,
				<div className = "Sign_up_general_container">
					{this.SendingCodeAgain}
				</div>,
				this.genericErrorWhenConnectingToPythonInUserRequest()
			]
		}
		if (this.state.Need === this.state.NeedCodes.MyProfile && this.state.checkboxCompany === false) {
			// User is an individual
			return [
				<div className='BackgroundSignUp'/>,
				this.WaitingForRequest(),
				this.Obscurer(),
				this.popupConfirmSaveAndContinue(),
				<div className = "Sign_up_company_container_Client">
					{this.SignUpTitleBackButton()}
					{this.SignUpTitle()}
					{this.RenderPersonProfile()}
					{this.RenderPersonProfileButton(6)}
				</div>,
				this.genericErrorWhenConnectingToPythonInUserRequest()
			]
		} else {
			if (this.state.Need === this.state.NeedCodes.MyProfile && this.state.checkboxCompany === true) {
				// User is either ar a company or a PSI
				return [
					<div className='BackgroundSignUp'/>,
					this.WaitingForRequest(),
					this.Obscurer(),
					this.popupConfirmSaveAndContinue(this.state.NeedCodes.MyProfile),
					<div className="Sign_up_company_container">
						{this.SignUpTitleBackButton()}
						{this.SignUpTitle()}
						{this.containerRowOfNumberOfCurrentState()}
						{this.RenderCompanyProfileCompany()}
						{this.RenderCompanyProfileButtons(this.state.NeedCodes.MyProfile)}
					</div>,
					this.genericErrorWhenConnectingToPythonInUserRequest()
				]
			}

			if (this.state.Need === this.state.NeedCodes.Employees && this.state.checkboxCompany === true) {
				return [
					<div className='BackgroundSignUp'>
						{this.WaitingForRequest()}
						{this.Obscurer()}
						{this.popupConfirmSaveAndContinue(this.state.NeedCodes.MyProfile)}
						<div className = "Sign_up_company_container">
							{this.SignUpTitleBackButton()}
							{this.SignUpTitle()}
							{this.containerRowOfNumberOfCurrentState()}
							{this.RenderCompanyProfileEmployees()}
							{this.RenderCompanyProfileButtons(this.state.NeedCodes.Employees)}
						</div>
						{this.genericErrorWhenConnectingToPythonInUserRequest()}
					</div>
				]
			}
			if (this.state.Need === this.state.NeedCodes.New_shops && this.state.checkboxCompany === true) {
				return [
					// <div>
					this.WaitingForRequest(),
					this.Obscurer(),
					this.popupConfirmSaveAndContinue(this.state.NeedCodes.MyProfile),
					<div className = "Sign_up_company_container">
						{this.SignUpTitleBackButton()}
						{this.SignUpTitle()}
						{this.containerRowOfNumberOfCurrentState()}
						{this.RenderCompanyProfileShops()}
						{this.RenderCompanyProfileButtons(this.state.NeedCodes.New_shops)}
					</div>,
					this.popupUserMissingInformation(),
					this.genericErrorWhenConnectingToPythonInUserRequest()
					// </div>
				]
			}
			if (this.state.Need === this.state.NeedCodes.NonCritical) {
				console.log('aquí')
				return [
					<div className='BackgroundSignUp'/>,
					<this.RedirectToLandingPageWithPopUp code={2}/>
				]
			}
			if (this.state.Need === this.state.NeedCodes.Critical) {
				console.log('aquí')
				return [
					<div className='BackgroundSignUp'/>,
					<this.RedirectToLandingPageWithPopUp code={1}/>
				]
			}
			if (this.state.Need === this.state.NeedCodes.Complete) {
				console.log('aquí')
				return [
					<div className='BackgroundSignUp'/>,
					<this.RedirectToLandingPage/>
				]
			}
		}
	}
}



export default SignUpClass;

