import {postData} from "../apis/rest_api";
import {get_Session_ID_cookies} from "./Session_cookies";
import {getUserType, getUserID, getIPAddress, getReceivedVia} from "../common/utilities";

async function PSI_Relations_request(Companies_info, Add, Modify, Remove) {

    let Action = null;
    if (Add === true) {Action = 'Add'}
    if (Modify === true) {Action = 'Modify'}
    if (Remove === true) {Action = 'Remove'}

    // console.log(Companies_info);

    var ID = 8;
    var objeto_json_venta = create_json_new_companies(Companies_info);

    var objeto_json_contenido = {
        "User_ID": +getUserID(),
        "New_companies": create_json_new_companies(Companies_info),
        "Action": Action
    };

    var objeto_json = {
        "ID": ID,
        "contenido": {
            "PSI_Relations": objeto_json_contenido,
            "User_type": getUserType(),
            "User_ID": +getUserID()
        },
        'Session_ID': get_Session_ID_cookies(),
        'IP_Address': getIPAddress(),
        "Received_via": getReceivedVia()
    };

    console.log(objeto_json);

    let response = await postData(objeto_json);

    console.log(response);

    return response;

}


function create_json_new_companies(Companies_info) {

    console.log(Companies_info);

    const total_no_of_companies = Companies_info.length;

    var list = [];

    for (var i = 0; i < total_no_of_companies; i++) {
        // By construction, in Companies_info there might be empty rows. There will always be at least one. The reason is that on the screen we always have 1 more spare row.
        // Don't send these rows' information.
        if (Companies_info[i].CompanyName !== null && Companies_info[i].CompanyName !== '' && Companies_info[i].CompanyName !== undefined && Companies_info[i].CompanyName !== '0' && Companies_info[i].CompanyName !== 0) {

            var new_company_json = {
                "CompanyName": Companies_info[i].CompanyName,
                "FiscalAddress": Companies_info[i].FiscalAddress,
                "CompanyTFN": Companies_info[i].CompanyTFN,
                "CompanyEmail": Companies_info[i].CompanyEmail,
                "JoiningField": Companies_info[i].JoiningField
            };

            list.push(new_company_json);
        } else {
            // Continue.
        }

    }

    return list;

}


export {PSI_Relations_request};