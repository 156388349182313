import {create_JWT_api, cookies_API} from "../apis/rest_api";
import {get_Session_ID_cookies} from "./Session_cookies"
import {getUserType} from  "../common/utilities"

async function create_initial_JWT_api() {
    const ID = 54;

    const objeto_json_create_jwt = {
        "User_ID": +localStorage.getItem('User_ID'),
        "User_type": getUserType()
        // "User_ID": +51
    };

    const objeto_json = {
        "ID": ID,
        "contenido": objeto_json_create_jwt,
        'Session_ID': get_Session_ID_cookies(),
        'IP_Address': localStorage.getItem('IP_Address'),
        "Received_via": window.localStorage.getItem('Received_via')
    };

    // console.log(objeto_json);
    await create_JWT_api(objeto_json);

    return null;

}


async function regenerate_JWT() {
    const ID = 55;

    const objeto_json_regenerate_JWT = {
        "User_ID": +localStorage.getItem('User_ID'),
        "User_type": getUserType()
        // "User_ID": +51
    };

    const objeto_json = {
        "ID": ID,
        "contenido": objeto_json_regenerate_JWT,
        'Session_ID': get_Session_ID_cookies(),
        'IP_Address': localStorage.getItem('IP_Address'),
        "Received_via": window.localStorage.getItem('Received_via')
    };

    // console.log(objeto_json);
    await create_JWT_api(objeto_json);

    return null;

}

async function JWT_expiration_cookie() {

    const ID = 101;

    const objeto_json_regenerate_JWT = {
        "User_ID": +localStorage.getItem('User_ID')
        // "User_ID": +51
    };

    const objeto_json = {
        "ID": ID,
        "contenido": objeto_json_regenerate_JWT
    };

    // console.log(objeto_json);
    let response = await cookies_API(objeto_json);

    return response;
}




export {create_initial_JWT_api, regenerate_JWT, JWT_expiration_cookie};