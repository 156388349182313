import {postData} from "../apis/rest_api";
import {get_Session_ID_cookies} from "./Session_cookies";
import {getUserType, getUserID, getIPAddress, getReceivedVia, getPSIID} from "../common/utilities";

async function Company_Relations_request(Verification_code, Action) {

    // let Action = null;
    // if (Accept === true) {Action = 'Accept'}
    // if (Reject === true) {Action = 'Reject'}
    // if (Eliminate === true) {Action = 'Eliminate'}

    // console.log(Companies_info);

    var ID = 9;

    var objeto_json_contenido = {
        "User_ID": +getUserID(),
        "PSI_ID": getPSIID(),
        "Verification_code": Verification_code,
        "Action": Action
    };

    var objeto_json = {
        "ID": ID,
        "contenido": {
            "Company_Relations": objeto_json_contenido,
            "User_type": getUserType(),
            "User_ID": +getUserID()
        },
        'Session_ID': get_Session_ID_cookies(),
        'IP_Address': getIPAddress(),
        "Received_via": getReceivedVia()
    };

    console.log(objeto_json);

    let response = await postData(objeto_json);

    console.log(response);

    return response;

}



export {Company_Relations_request};