import {postData} from '../apis/rest_api.js'
import {return_null_if_empty, getUserType} from '../common/utilities.js'
import {get_Session_ID_cookies} from "./Session_cookies"

function create_subJsons_with_sales(Sales, ScreenDatetime) {

	var list = [];
	// console.log(Sales);
	Sales.forEach(Sale => {
		//console.log(Sale);

		var change_json = {
			"Units": return_null_if_empty(Sale.Units),
			"Sale_data": {
				"Sellers_ID_Reference": return_null_if_empty(Sale.Reference),
				"Purchasers_ID_Reference": null,
				"Product": return_null_if_empty(Sale.Product),
				"Brand": return_null_if_empty(Sale.Brand),
				"Product_Type": return_null_if_empty(Sale.Type),
				"Product_Subtype": return_null_if_empty(Sale.Subtype),
				"Price": return_null_if_empty(Sale.Price),
				"Price_without_VAT": return_null_if_empty(Sale.PricewoVAT),
				"VAT": return_null_if_empty(Sale.VAT),
				"Currency": null,
				"ScreenDatetime": return_null_if_empty(ScreenDatetime)
			},
			"Discount": return_null_if_empty(Sale.Discount),
			"Promotion": return_null_if_empty(Sale.Promotion)
		}

		list.push(change_json);

	})

	return list;

}




export async function Create_json_change(ID_Provider_Operation, Sales, Changes, Client_info, Shipping, Employee_ID, Shop_ID, ScreenDatetime, Currency) {

	var ID = 2;
	console.log(ID_Provider_Operation);   //OK
	console.log(Sales);                   //OK
	console.log(Changes);             	//OK
	console.log(Client_info.Email);             //OK
	console.log(Shipping);                //OK
	console.log(Employee_ID);				//OK
	console.log(Shop_ID);					//OK



	// Detect which products actually require a change.
	var effectiveChanges = [];

	Changes.forEach(change => {
		if (change.Units_to_change !== 0 && change.Units_to_change !== null && change.Units_to_change !== "") {
			effectiveChanges.push(change);
		}
	})

	// Detect which Sales entries are actually a product sold
	var effectiveSales = [];

	Sales.forEach(Sale => {
		if (Sale.Product !== 0 && Sale.Product !== null && Sale.Product !== "") {
			effectiveSales.push(Sale);
		}
	})

	var objeto_json_shop = {
		"Shop_ID": Shop_ID
	};
	var objeto_json_employee = {
		"Employee_ID": Employee_ID
	};

	var objeto_json_sales = create_subJsons_with_sales(effectiveSales, ScreenDatetime);
	var objeto_json_changes = create_subJsons_with_changes(effectiveChanges, ScreenDatetime, Currency);

	// Removing the client data as the client email will be passed by another service after 2nd release.
	var objeto_json_client = {
		// "Email": Client_info.Email
		"Email": null
	}

	var objeto_json_contenido = {
		"ID_Provider_Operation": ID_Provider_Operation,
		"Changes": objeto_json_changes,
		"Sales": objeto_json_sales,
		"Client": objeto_json_client,
		"Employee": objeto_json_employee,
		"Shop": objeto_json_shop,
		"Shipping": Shipping
	};


	var objeto_json = {
		"ID": ID,
		"contenido": {
			"Change_input": objeto_json_contenido,
			"User_type": getUserType(),
			"User_ID": +localStorage.getItem('User_ID')
		},
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};

	console.log(objeto_json);

	let response = await postData(objeto_json);

	console.log(response);

	return response;

	return null;

}



function create_subJsons_with_changes(effectiveChanges, ScreenDatetime, Currency) {
	
	var list = [];
	
	effectiveChanges.forEach(effectiveChange => {
		//console.log(effectiveChange);
		var change_json = {
		"Units": return_null_if_empty(effectiveChange.Units_to_change),
		"Reason": return_null_if_empty(effectiveChange.Reason),
		"Change_data": {
			"Sellers_ID_Reference": return_null_if_empty(effectiveChange.Reference),
			"Purchasers_ID_Reference": null,
			"Product": return_null_if_empty(effectiveChange.Product),
			"Brand": return_null_if_empty(effectiveChange.Brand),
			"Product_Type": return_null_if_empty(effectiveChange.Type),
			"Product_Subtype": return_null_if_empty(effectiveChange.Subtype),
			"Price": return_null_if_empty(effectiveChange.Price),
			"Price_without_VAT": return_null_if_empty(effectiveChange.Price_without_VAT),
			"VAT": return_null_if_empty(effectiveChange.VAT),
			"Currency": Currency,
			"ScreenDatetime": return_null_if_empty(ScreenDatetime)
			},
		"Discount": return_null_if_empty(effectiveChange.Discount),
		"Promotion": return_null_if_empty(effectiveChange.Promotion)
		};
			
		list.push(change_json);
	})
		
	return list;
	
}
