import React from "react";

function generalTermsAndConditionsTextParticular(buttonLicenses, buttonCookiesPolicy, buttonPrivacy) {
    return(
        <span>
<br/><b>1. COMPANY DETAILS</b><br/><br/>
    Proprietor: The TIDI Systems GmbH (hereinafter "<b>TIDI</b>").<br/>
    Registered office: Frohbühlstrasse 3, 8052 Zürich, Switzerland.<br/>
    TFN: CHE-389.068.509<br/>
    Public Register: Commercial Register of Zurich, registration number CHE-1005265706.<br/>
    Phone: +41 79 109 19 23.<br/>
    E-mail: hola@tidisystems.com<br/><br/>

    <br/><b>2. OBJECT</b><br/><br/>
    The Terms and Conditions for Clients (hereinafter, the <b>“Terms and Conditions for Clients”</b>) that will govern access to and use of the
    <b>“TIDI”</b> platform (hereinafter, the <b>“Platform”</b>), including the content and services made available to clients or natural person users
            (hereinafter, the <b>“Client”</b> or the <b>"User"</b> and, where applicable, the <b>“Clients”</b> or the <b>"Users"</b>) through the Platform are hereby established. However, access to and use of certain content and/or services may be subject to certain specific conditions.<br/><br/>
    Any matter not expressly provided for in these Terms and Conditions for Customers shall be understood to be reserved to TIDI, without prejudice to the application of the provisions of the regulations in force.<br/><br/>
    Furthermore, TIDI reserves the right to make modifications and/or updates to these Terms and Conditions for Customers, of which Customers will be informed in advance for their acceptance or rejection in the event that they are substantial. In any event, you will be deemed to have expressly accepted such modifications or updates if you continue to use and make use of the Platform.<br/><br/>
    By accessing and using the Platform, you expressly and unreservedly accept the terms of these Customer Terms and Conditions, which are as valid and effective as any written and signed contract, and if you do not agree to these Customer Terms and Conditions, we suggest that you do not use the Platform. <br/><br/>

    <br/><b>3. WHAT IS TIDI? WHAT SERVICES DOES IT OFFER?</b><br/><br/>
    TIDI is a technology company focused on providing a mixed Customer Relationship Management (CRM) platform, combining customer and supplier relationships through Artificial Intelligence (AI) solutions.<br/><br/>
    The platform provides a ticket manager that collects customer and supplier data, humanising the relationship between the two. In addition, when combined with advanced AI analytical models, it provides a detailed analysis of the market, facilitating optimisation and increasing business opportunities.<br/><br/>
    Among others, TIDI offers:
            <ol className='ulItem' type="1">
                <li>Ticket and Invoice Manager - Easily generate and store purchase tickets.</li>
                <li>Pay and obtain the digital ticket via QR code. Pay and obtain the ticket in a single action (scan QR).</li>
                <li>History of purchases, exchanges and customer returns that facilitates management with your suppliers.</li>
                <li>Cost control for the client.</li>
                <li>Notifications about the expiry of the exchange or return period.</li>
                <li>Make exchange and return requests through the application anytime, anywhere.</li>
                <li>Product recommendation based on their peers.</li>
                <li>Making payments, refunds and exchanges.</li>
                <li>Division of the payment between buyers.</li>
            </ol>


    <br/><b>4. PROFILES AND ROLES</b><br/><br/>
    For the use of the Platform, TIDI may determine roles and profiles for the use of the Platform by the different users. In this regard, the roles and profiles that may be assigned, together with the corresponding permissions, are as follows: <br/><br/>
    <br/><b>4.1. Providers</b>
    <ol className='ulItem' type="1">
                <li>Company</li>
                <li>Employee</li>
                <li>Shop</li>
            </ol>
    <br/><b>4.2. Clients</b><br/><br/>


    <br/><b>5. REGISTRATION AND USE OF THE CLIENT PLATFORM</b><br/>
    <br/><b>5.1. Registration</b><br/><br/>
    Access, registration and use of the Platform are completely free of charge.<br/><br/>
    To register and use the Platform, the User must be at least eighteen (18) years of age and accept these Terms and Conditions, as well as
    the {buttonPrivacy} and the {buttonCookiesPolicy}. TIDI expressly reserves the right to require Users to provide proof of age and other information to verify their identity in order to prevent access to the Platform by unauthorised users.<br/><br/>
    Users will be able to register immediately after accessing the Platform, under the option "Are you new? Register here for free! Currently, registration on the Platform is allowed by entering the following data:<br/>
    <ul className='ulItem'>
        <li><b>Customer</b> (natural person)</li>
        <ol className='ulItem2' type="a">
            <li>User name</li>
            <li>E-mail</li>
            <li>Password</li>
            <li>Sex</li>
            <li>Date of birth</li>
            <li>Postcode</li>
    </ol></ul>

    <br/><b>5.2. Verification of the profile </b><br/><br/>
    In order to verify the Client's profile, the user will be sent an e-mail to the address provided at the time of registration, which will include a verification code that the user must enter on the Platform.<br/><br/>
    If you do not receive the confirmation e-mail, you should check your junk mailbox. In the event that you have not received this e-mail in any of your mailboxes, you should contact TIDI to resolve this incident through the channels provided for this purpose or by e-mail: hola@tidisystems.com. Likewise, the user may request the e-mail to confirm their account from the registration section of the Platform.<br/><br/>

    <br/><b>5.3. Discharge </b><br/><br/>
    The Customer may delete their account from the application/website itself, using the option provided for this purpose. <br/><br/>
    In addition, and in the event that the aforementioned function should present any incident, the user may request cancellation of the services provided by TIDI by sending a written communication to hola@tidisystems.com. TIDI reserves the right to request further information from users in order to demonstrate their link to the required profile and to prevent third parties from requesting the deletion of supplier and client accounts. <br/><br/>
    In any case, users may request a new registration, and TIDI may refuse to accept such registration in cases where the user has contravened the provisions of these Terms and Conditions for Customers or has acted contrary to the law, morality or public order. Likewise, TIDI may refuse to accept such registration in the event of a dispute or controversy between the parties, which has not yet been resolved or which has ended with acknowledgement of fraud or fault on the part of the user and/or to the detriment of TIDI, other users, suppliers or customers. <br/><br/>

    <br/><b>5.4. Authentication </b><br/><br/>
    Once registered, the user can access the platform by entering their email (ID) and password. The authentication method encrypts the ID and password of each user and has a duration of 15 minutes. This means that after 15 minutes, a new ID is generated for the user. This process allows us to protect data and prevent fraud.
    The user is also offered the possibility to save his or her start-up data in the "Remember me" option.<br/><br/>

    <br/><b>5.5. Password recovery </b><br/><br/>
    If the user forgets or loses their password, they can recover it and enter a new one by providing their email address on the login page, in the option "Forgotten your password? TIDI will send an email to the user with a code, which must be entered by the user in the application.<br/><br/>

    <br/><b>5.6. Password change </b><br/><br/>
    For security reasons, the user must change his or her password and choose a new one. This process will require the current password to be entered. <br/><br/>

    <br/><b>5.7. My account</b><br/><br/>
    The user will be able to consult and edit the data entered in the registration in the "My account" section. You can also change your email address, password and telephone number in the "Settings" option. <br/><br/>

    <br/><b>5.8. My history </b><br/><br/>
    Once logged in, the user is directed to your "My History" page. It is in this section that purchases, exchanges and returns are stored. Each one of them is recorded including the following data:
    <ol className='ulItem' type="1">
        <li>ID</li>
        <li>Classification for sale, exchange or return </li>
        <li>Acquired object</li>
        <li>Supplier</li>
        <li>Employee</li>
        <li>Price</li>
        <li>Shop</li>
        <li>Date</li>
        <li>QR code</li>
    </ol>
    The platform also incorporates a search and filter tool, which enables the user to filter and locate different payments by object, supplier, shop or date. <br/><br/>

    <br/><b>6. INTELLECTUAL AND INDUSTRIAL PROPERTY </b><br/><br/>
    TIDI is the owner or, where applicable, has the corresponding licences for the intellectual, industrial, image or any other similar rights over the Platform, as well as all the materials and information that comprise it and the content offered therein, including, but not limited to, texts, documents, databases, photographs, illustrations, logos, trademarks, graphics, designs, trade names, interfaces, services and any other information or content included therein.<br/><br/>
    Under no circumstances shall it be understood that access, browsing and use of the Platform by the user or the use, acquisition and/or contracting of the services offered through the same imply a waiver, transmission or total or partial transfer of said rights by TIDI.<br/><br/>
    In this regard, it is forbidden to modify, copy, reproduce, publicly communicate, transform or distribute, by any means and in any form, all or part of the contents included in the Platform or forming part of the same, for public or commercial purposes, without the prior, express and written authorisation of TIDI, its suppliers or, where appropriate, of the holder of the corresponding rights.<br/><br/>
    The user has only a non-exclusive and revocable licence to use the Platform, its contents and services, including associated software, on a strictly domestic basis, and in accordance with this Terms and Conditions for Clients document, as well as any other documents or policies referenced herein that may be applicable to TIDI's services. The right of use granted to the Client on the Platform is limited to the duration of the contract between TIDI and the Client, and is automatically revoked upon termination of the contract for any reason whatsoever, and in particular in the event of breach by the Client of these Terms and Conditions for Clients.<br/><br/>
    Furthermore, the Client acknowledges and accepts that, insofar as the information that can be accessed via the Platform constitutes a sui generis protection database, TIDI reserves all rights over the same and, in particular, those of extraction and reuse of substantial parts of its contents, evaluated qualitatively or quantitatively. In this regard, the Client may only access and use this information in the Platform environment, for the same purposes as described herein and limited to the duration of the contract between TIDI and the Client, being expressly and automatically revoked at the time of termination of the same for any reason whatsoever.<br/><br/>
    References to trademarks, registered trade names, or any other distinctive signs, whether owned by TIDI, its suppliers or any third parties, imply a prohibition on their use without the consent of TIDI or their legitimate owners. Access to, browsing and use of the Platform does not grant the Client any rights over such elements, including logos.<br/><br/>
    It is forbidden to remove or manipulate the copyright indications or any other element that identifies the owners of the rights of the Platform, as well as any devices, information or protection mechanisms incorporated therein.<br/><br/>
    Any other use of the content of the Platform is also strictly prohibited, with the sole exception of strictly domestic use.<br/><br/>
    With respect to third party elements included in the Platform, the Platform uses third party libraries and components that are licensed to TIDI under open source licenses. TIDI holds a licence for these elements, granted by their respective developers, which allows it to use them in connection with the Platform. TIDI does not hold any intellectual and industrial property rights over these elements, beyond the rights granted to it by the corresponding licences. By accessing the Platform, the Client becomes a user of these licences. For more information on the Open Source components used and the licences that apply to them, please consult the following link: {buttonLicenses}. The use of Open Source licences and software is compatible with TIDI's rights to the Platform as a distinct entity.<br/><br/>
    In the event that the Client publishes or sends information to TIDI via the Platform, the Client guarantees that it has the necessary rights to do so and that such information does not infringe the rights of third parties, nor is it confidential or detrimental to them. The Client will retain the rights to the elements protected by intellectual and industrial property that it provides to TIDI in the course of its use of the Platform, and grants TIDI a licence to use them to the extent necessary to provide the services requested. The Client will be responsible for the information provided, including its accuracy and correctness, and will hold TIDI harmless in the event of any claim arising from the use thereof.<br/><br/>


    <br/><b>7. E-PROCUREMENT</b><br/><br/>
    In accordance with the provisions of the applicable regulations on information society services and electronic commerce, contracts concluded electronically shall produce all the effects provided for by the legal system, when consent and the other requirements necessary for their validity are met.<br/><br/>
    In any case, the electronic medium containing these terms and conditions of contract concluded by electronic means shall be admissible as documentary evidence in the event of a dispute between the parties.<br/><br/>
    For these purposes, it shall be understood that the monitoring of all the phases of the contracting process and, where applicable, the payment of the corresponding economic amount, if applicable, shall necessarily imply the provision of the consent required for contracting. <br/><br/>
    Likewise, and in accordance with the provisions of the aforementioned regulations, all information relating to the contracting procedure is made available to Users prior to the start of the contracting procedure, which will only be applicable in the event that the User decides to proceed with the contracting through the Platform. <br/><br/>


    <br/><b>8. TIDI'S RESPONSIBILITIES</b><br/><br/>
    TIDI undertakes to ensure that the content, data or information relating to the Platform and the services offered therein are reliable, truthful and accurate. However, TIDI shall not be liable for any information or content that has been entered, displayed or modified by third parties outside TIDI. Therefore, TIDI is exempt from any liability for damages that may be due to the information and/or services provided or supplied by third parties outside the Platform.<br/><br/>
    TIDI also declares that it has taken all necessary measures, within its capabilities and the state of the art, to ensure the proper functioning of the Platform and to minimise system errors from a technical point of view. <br/><br/>
    However, TIDI shall not be liable in the event of unavailability of the Platform due to circumstances beyond TIDI's control, force majeure or error in the registration process due to inaccurate information.  <br/><br/>
    TIDI will use commercially and technically reasonable efforts to keep the services offered through the Platform available, which is an obligation that does not, however, apply to any lack of availability or performance caused by:
        <ol className='ulItem' type="1">
            <li>Temporary downtime of the Platform due to an upgrade and/or technical maintenance, of which we will try to inform you as far in advance as possible; and </li>
            <li>Causes beyond TIDI's control: force majeure, Internet access problems, technological problems beyond the diligent and reasonable control of the Platform owner, actions or omissions of third parties, etc. </li>
        </ol>
    In all the aforementioned cases, beyond the control and reasonable expectation of TIDI, TIDI shall not be liable to compensate the user for any damages that may arise.<br/><br/>
    Consequently, TIDI does not guarantee and is not liable for:
        <ol className='ulItem' type="1">
            <li>the continuity of the content included in the Platform;</li>
            <li>the absence of errors in these contents; </li>
            <li>the absence of viruses, Trojan horses, worms, logic bombs and/or other malicious or technologically harmful components on the Platform or the server that provides it;</li>
            <li>the invulnerability of the Platform and/or the impossibility of breaching the security measures adopted on the Platform;</li>
            <li>the lack of usefulness or performance of the contents of the Platform;</li>
            <li>failures of the Platform caused by any kind of attack on its servers or those of third party TIDI service providers, as well as technical or security failures of the system of any such providers that prevent the Platform from functioning;</li>
            <li>any technical failure of any kind that hinders, delays or prevents the proper functioning of the Platform;</li>
            <li>any damage or harm caused, to themselves or to a third party, by any person who infringes the conditions, rules and instructions that TIDI establishes on the Website or through the violation of security systems.</li>
        </ol>
    In the event of closure or suspension of the Platform for reasons beyond the control of the parties, the user will be informed of this circumstance, e.g. the transfer of the service to a new domain. <br/><br/>


    <br/><b>9. SUSPENSION OF THE PLATFORM </b><br/><br/>
    TIDI may at any time suspend, modify, restrict or terminate access to the Platform, with or without prior notice to those who contravene these Terms and Conditions for Customers, without the possibility of claiming any compensation. <br/><br/>


    <br/><b>10. RULES OF USE </b><br/><br/>
    It is not permitted to access or use the Platform for illegal or unauthorised purposes, with or without economic purpose, and the user shall be solely responsible for any actions that give rise to such purposes. In particular, and without the list being absolute or limitative in nature, it is prohibited:
        <ol className='ulItem' type="1">
            <li>use the Platform in any way that may cause damage, interruptions, inefficiencies or defects in the operation of the Platform or third party devices;</li>
            <li>use the Platform for the transmission, installation or publication of any viruses, Trojan horses, worms, logic bombs, malicious code or other harmful programs or files;</li>
            <li>use the Platform to transmit material for advertising or promotional purposes, including spam, chain letters or similar;</li>
            <li>use the Platform in a way that constitutes an infringement of the rights of TIDI or any third party;</li>
            <li>use the Platform to transmit or post any material of a defamatory, offensive, racist, denigrating, or threatening nature or that is likely to annoy, harm or otherwise injure any person;</li>
            <li>use the Platform illegally, against good faith, morality and/or public order;</li>
            <li>gain unauthorised access to any section of the Platform, other systems or networks connected to the Platform, any TIDI server or the services offered through the Platform by hacking or forgery, password mining or any other illegitimate means;</li>
            <li>take any action that causes disproportionate or unnecessary saturation of the Platform's infrastructure or TIDI's systems or networks, as well as the systems and networks connected to the Platform itself.</li>
        </ol>
    Failure to comply with any of the above obligations on the part of the user may lead to TIDI adopting the appropriate measures in accordance with the law and in the exercise of its rights or obligations, which may include the deletion or blocking of the offending user's account, without the possibility of any compensation for the damages caused. <br/><br/>


    <br/><b>11. RESPONSIBILITY OF THE CUSTOMER </b><br/><br/>
    The Client shall at all times respect the Terms and Conditions for Clients. In this regard, the Client expressly declares that he/she will use the Platform diligently and assumes any liability that may arise from a breach of these Terms and Conditions for Clients. Likewise, the use of the Platform for illegal or unauthorised purposes, with or without economic purpose, shall not be permitted. Both the services offered and the information contained in the Platform may not be used for commercial or advertising purposes without the prior and express authorisation of TIDI.<br/><br/>
    Furthermore, the Customer shall be responsible for any communication, content, personal data (beyond the provision of the service by TIDI), opinions and suggestions for new functionalities and filters that you provide to TIDI. This responsibility covers the accuracy, legality, originality and ownership of such communications, and you must hold TIDI harmless. If you become aware of the existence of any content that is illicit, illegal, contrary to the law or that could infringe the rights of third parties, you must immediately notify TIDI so that it can proceed to take the appropriate measures.<br/><br/>
    In the event that the Client carries out any action that could be considered illicit, illegal, contrary to the law or that could involve the infringement or violation of third party rights, he/she shall do so at his/her own risk, and shall hold TIDI harmless for his/her actions.<br/><br/>


    <br/><b>12. DEVICES AND SYSTEMS</b><br/><br/>
    In order to use the Platform, the User must have an Internet connection and a device with a browser compatible with the Platform. The user is responsible for all costs associated with such connection, including those costs associated with data traffic, regardless of where the user uses the Platform.<br/><br/>
    The current version of the Platform is available for the following browsers:
        <ul className='ulItem'>
            <li>Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Opera</li>
            <li>Microsoft Bing</li>
        </ul>
    We may stop supporting any of the browsers at any time at our discretion. Where a browser is no longer supported, we will cease to deal with problems that may arise for different users. Your browser settings must allow the use of cookies and other similar devices to at least allow the insertion of technical and functional cookies. Likewise, you must not have any pop-up blockers.<br/><br/>
    For a good user experience, a connection speed of at least 3MB/s is required. In no event will TIDI be liable for Internet overload or failures due to problems related to users' equipment or networks. <br/><br/>

    <br/><b>13. SAFEGUARD CLAUSE</b><br/><br/>
    All clauses or terms of these Terms and Conditions for Customers must be interpreted independently and autonomously, and the rest of the stipulations shall not be affected in the event that one of them has been declared null and void by a court ruling or final arbitration decision. The affected clause or clauses shall be replaced by one or more other clauses that preserve the intended effects of these Terms and Conditions for Customers.<br/><br/>


    <br/><b>14. PRIVACY AND COOKIES POLICY</b><br/><br/>
    Registration and access to the Platform requires the user to provide certain information about himself/herself, in accordance with the provisions of the {buttonPrivacy}, which must be read and accepted. In addition, certain functionalities of the website depend on the use of Cookies, as reported in the {buttonCookiesPolicy}.<br/><br/>
    Whenever the user provides personal data, he/she shall be solely responsible for the truthfulness and accuracy of such data, so he/she shall inform TIDI of any updates to such data as soon as possible. <br/><br/>


    <br/><b>15. GROUNDS FOR TERMINATION, JURISDICTION AND COMPETENCE</b><br/><br/>
    For any litigious matter related to this Platform or any of those on which TIDI depends, and provided that the legislation so permits, the Spanish legislation in force at the time of the litigation shall be applicable, and the Courts and Tribunals of Madrid shall be competent to resolve all disputes arising from or related to the use of this Platform.<br/><br/>
    In any case, in order to file complaints regarding the use of the services offered through the Platform, the user may contact us by mail at the following address: hola@tidisystems.com, undertaking to seek an amicable solution to the conflict at all times. <br/><br/>

<div className='AlignToRight'> Last update: September 2021 </div><br/><br/>

        </span>
    )
}

var generalTermsAndConditionsTitleParticular = <b>GENERAL TERMS AND CONDITIONS</b>

export {generalTermsAndConditionsTextParticular, generalTermsAndConditionsTitleParticular};