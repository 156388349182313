import {postData} from "../apis/rest_api"
import {get_Session_ID_cookies} from "./Session_cookies";
import {getUserType} from  "../common/utilities";

export async function get_info_from_ID(TIDI_ID) {
	var ID = 4;

	var objeto_json = {
		"ID": ID,
		"contenido": {
			"Retrieve_information_from_ID": +TIDI_ID,
			"User_type": getUserType(),
			"User_ID": +localStorage.getItem('User_ID')
		},
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};
	
	//console.log(objeto_json);	
	
	let response_Retrieve_information_from_ID = await postData(objeto_json);
	
	//console.log(response_Retrieve_information_from_ID);
	
	return response_Retrieve_information_from_ID;
		
}
	

