import {nonAuthPostData} from "../apis/rest_api";
import {get_Session_ID_cookies} from "./Session_cookies"

async function create_json_log_in_account(usuario_data) {
	const ID = 52;
	
	console.log(usuario_data);
	const state = usuario_data.estado;

	const objeto_json_info_log_in = {
		"Email": state.Email,
		"Contra": state.Password,
		"Reset": state.Reset,
		"Verification_code": state.Verification_code,
		"New_password": state.NewContra,
		"Delete_my_account": false
	};
	const objeto_json_contenido = {
		"Log_in_input": objeto_json_info_log_in
	};
	const objeto_json = {
		"ID": ID,
		"contenido": objeto_json_contenido,
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};

	console.log(objeto_json);
	const response_log_in = await nonAuthPostData(objeto_json);

	console.log(response_log_in);
	return response_log_in;

}


async function create_json_log_in_validate_old_password(usuario_data) {
	const ID = 52;

	console.log(usuario_data);
	const state = usuario_data.estado;

	const objeto_json_info_log_in = {
		"Email": state.Email,
		"Contra": state.Password,
		"Reset": state.Reset,
		"Verification_code": state.Verification_code,
		"New_password": state.NewContra,
		"Delete_my_account": state.DeleteMyAccount
	};
	const objeto_json_contenido = {
		"Log_in_input": objeto_json_info_log_in
	};
	const objeto_json = {
		"ID": ID,
		"contenido": objeto_json_contenido,
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};

	console.log(objeto_json);
	const response_log_in = await nonAuthPostData(objeto_json);

	console.log(response_log_in);
	return response_log_in;

}

export {create_json_log_in_account, create_json_log_in_validate_old_password};