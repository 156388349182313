import React from "react";

function cookiesDetailText(buttonCookiesSummary, buttonPrivacyPolicy, tablaTecnicas, tablaAnaliticas) {
    return(
    <span>This Cookie Policy is an integral part of the Legal Notice and Privacy Policy of the TIDI Platform and website (hereinafter, the <b>“Website”</b> and the <b>“Plataform”</b>).<br/><br/>
    In order to facilitate navigation of the Website and the application, The TIDI Systems GmbH (hereinafter, <b>“TIDI”</b>), as the owner of the Website, with registered office at Frohbühlstrasse 3, 8052 Zürich, Switzerland, informs you that it uses Cookies or other data storage and retrieval devices with similar functionality (hereinafter, <b>“Cookies”</b>).<br/><br/>
    In any case, we inform you that the provider is responsible for the Cookies and the processing of the data obtained through its own Cookies, deciding on the purpose, content and use of the processing of the information collected.<br/><br/>
    In this sense, and with the aim of guaranteeing the user all the necessary information for correct browsing, we provide the following information on what Cookies are, what type of Cookies are used on our Website and how they can be configured or disabled.<br/><br/>

    <br/><b>1. WHAT IS A COOKIE?</b><br/><br/>
    Cookies are files containing small amounts of information that are downloaded to the user's device when visiting a website.<br/><br/>
    Cookies are essential for the functioning of the Internet, as they offer technical solutions that allow the user to browse the different websites; they cannot damage the user's equipment/device and can be used to identify and resolve possible errors in the functioning of the Website. They may also be used for advertising or analytical purposes.<br/><br/>

    <br/><b>2. USE OF COOKIES</b><br/><br/>
    Specifically, TIDI uses its own Cookies generated directly by this domain for the specific purposes described below. If in the future TIDI uses other Cookies for the purpose of providing more and better services, the user will be informed of this. <br/><br/>

    <br/><b>2.1. Own or necessary technical cookies.</b><br/><br/>
    These are those that allow the user to browse through the Website and the application and use the different options or services that exist therein, such as, for example, controlling traffic and data communication, identifying the session, accessing restricted access areas, controlling fraud linked to the security of the service, counting visits for the purposes of billing licences for the software with which the service (website, platform or application) operates, using security elements during browsing, storing content for the broadcasting of videos or sound or enabling dynamic content (e.g. text or image loading animation).<br/><br/>
    TIDI does not need your consent to install Cookies on your device that are necessary to activate the functionalities of the Website and/or application, and their use is based on the need to provide the services requested. Therefore, as they are necessary for the operation of the site, these Cookies will be enabled by default and cannot be denied or disabled. <br/><br/>

        <br/>{tablaTecnicas}

    <br/><b>2.2. Personalisation cookies. </b><br/><br/>
    These are those that allow information to be remembered so that the user can access the service with certain characteristics that may differentiate their experience from other users, such as, for example, the language, the number of results to be shown when the user performs a search, the appearance or content of the service depending on the type of browser through which the user accesses the service or the region from which they access the service, etc.<br/><br/>
    If it is the user himself who chooses these features (for example, if he selects the language of a website by clicking on the icon of the flag of the corresponding country), Cookies will be considered as a service expressly requested by the user, and will therefore be used exclusively for the selected purpose and without requesting his express consent. <br/><br/>
    TIDI does not currently use this type of cookie.<br/><br/>

    <br/><b>2.3. Analytical or measurement cookies.</b><br/><br/>
    Analytical Cookies help website and/or application owners understand how visitors interact with the Platform by gathering and providing information in aggregate form. <br/><br/>
    The information obtained by these Cookies is unlikely to represent a risk to the privacy of users, as they are analysed in aggregate form, for the purposes of obtaining statistics and reports that allow the operation of the Website and the application to be improved.<br/><br/>
    The user can accept or reject the installation of these Cookies. <br/><br/>
    TIDI currently uses the following analytical or measurement cookies: <br/>

        <br/>{tablaAnaliticas}

    <br/><b>2.4. Advertising cookies.</b><br/><br/>
    These Cookies are used to display advertisements that are relevant to the user. They are also used to limit the number of times a user sees an advertisement, as well as to help measure the effectiveness of advertising campaigns. They remember that you have visited a website and this information is shared with other organisations such as advertisers.<br/><br/>
    You can accept or reject the installation of these Cookies. These Cookies are only installed on the user's device when the user accepts them. <br/><br/>
    TIDI does not currently use advertising cookies.  <br/><br/>

    <br/><b>3. CONSERVATION PERIODS </b><br/><br/>
    Cookies that are not necessary for TIDI to operate will be kept on your device for the time necessary for the purpose for which they were installed and for a maximum of 2 years, unless you decide to delete them through the configuration options of your browser (see section 5 of this policy). <br/><br/>

    <br/><b>4. INTERNATIONAL TRANSFERS</b><br/><br/>
    Some of the personal data obtained through Cookies will be subject to international transfers, in which case we guarantee that the necessary guarantees will be adopted to protect the confidentiality, integrity and availability of the same. For more information on the international transfers made by each third party, please access their privacy policies. <br/><br/>

    <br/><b>5. HOW TO ACCEPT, REJECT, BLOCK OR DELETE COOKIES </b><br/><br/>
    You can block or delete Cookies through the configuration tools of the {buttonCookiesSummary}, or you can set your browser to notify you when a server wants to save a Cookie:<br/>
        <ul className='ulItem'>
            <li>If you use <b>Microsoft Internet Explorer</b> you will find the option in the menu Tools > Internet Options > Privacy > Settings. To find out more visit: <br/>
    http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies y http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9.</li>
            <li>If you use <b>Firefox</b>, you will find the option in the menu Tools > Options > Privacy > Cookies. To find out more visit: <br/>
    https://support.mozilla.org/es/kb/cookies-informacion-que-los-sitios-web-guardan-en-#w_configurar-las-cookies</li>
            <li>If you use <b>Chrome</b>, in the section Options > Advanced options > Privacy. Find out more:<br/>
    https://support.google.com/accounts/answer/61416?hl=es </li>
            <li>If you use <b>Opera</b>, in the Security and Privacy option, you can configure the browser. To find out more visit:<br/>
    http://help.opera.com/Windows/11.50/es-ES/cookies.html</li>
            <li>If you use <b>Safari</b> you will find the option in the Preferences/Privacy menu. More information can be found at:<br/>
    http://support.apple.com/kb/HT1677?viewlocale=es_ES</li>
        </ul>

    <br/><b>6. USERS' RIGHTS AND ADDITIONAL INFORMATION </b><br/><br/>
    You can obtain more information about the processing of your data by TIDI, as well as how to exercise your rights in our {buttonPrivacyPolicy}.<br/><br/>
    You can also contact TIDI by email: datos@tidisystems.com as reference in the subject line: "Data Protection".<br/><br/>

    <div className='AlignToRight'> Last update: September 2021 </div><br/><br/>
 </span>
    )}

var cookiesDetailTitle = <span><b>COOKIES POLICY</b></span>

export {cookiesDetailText, cookiesDetailTitle};