import {postData} from "../apis/rest_api";
import {get_Session_ID_cookies} from "./Session_cookies";
import {getUserType} from "../common/utilities";

async function Create_json_sales(ID_Provider_Operation, Sales_data, Client_data, Shipping, Employee_ID, Shop_ID, ScreenDatetime, Currency) {
	
	//console.log(Employee_ID);
	// console.log(Sales_data);
	console.log(Shipping);
	console.log(Sales_data);
	
	var ID = 1;
	var objeto_json_venta = create_json_one_sale(Sales_data, ScreenDatetime, Currency);
	var objeto_json_cliente = {
		"Email": Client_data[0].Email_address
	};
	var objeto_json_shop = {
		"Shop_ID": Shop_ID
	};
	var objeto_json_employee = {
		"Employee_ID": Employee_ID
	};
	var objeto_json_contenido = { 
		"ID_Provider_Operation": ID_Provider_Operation,
		"Sales": objeto_json_venta,
		"Client": objeto_json_cliente,
		"Employee": objeto_json_employee,
		"Shop": objeto_json_shop,
		"Shipping": Shipping
	};

	var objeto_json = {
		"ID": ID,
		"contenido": {
			"Sales_input": objeto_json_contenido,
			"User_type": getUserType(),
			"User_ID": +localStorage.getItem('User_ID')
		},
		'Session_ID': get_Session_ID_cookies(),
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};
	
	console.log(objeto_json);
	
	let response = await postData(objeto_json);

	console.log(response);
	
	return response;
	
}


function create_json_one_sale(Sales_data, ScreenDatetime, Currency) {
	
	const total_no_of_sales = Sales_data.length;
	
	var list = [];
	
	for (var i = 0; i < total_no_of_sales; i++) {
		// By construction, in Sales_data there might be empty rows. There will always be at least one. The reason is that on the screen we always have 1 more spare row.
		// Don't send these rows' information.
		if (Sales_data[i].Product !== null && Sales_data[i].Product !== '' && Sales_data[i].Product !== undefined && Sales_data[i].Product !== '0' && Sales_data[i].Product !== 0) { 
		
			var sale_json = {
				"Units": Sales_data[i].Units,
				'Sale_data': {
					"Sellers_ID_Reference": Sales_data[i].Reference,
					"Purchasers_ID_Reference": null,
					"Product": Sales_data[i].Product,
					"Brand": Sales_data[i].Brand,
					"Product_Type": Sales_data[i].Type,
					"Product_Subtype": Sales_data[i].Subtype,
					"Price": Sales_data[i].Price,
					"Price_without_VAT": Sales_data[i].PricewoVAT,
					"VAT": Sales_data[i].VAT,
					"Currency": Currency,
					"ScreenDatetime": ScreenDatetime
				},
				"Discount": Sales_data[i].Discount,
				"Promotion": Sales_data[i].Promotion
			};
				
			list.push(sale_json);
		} else {
			// Continue.
		}
			
	}
	
	return list;
	
}


export {Create_json_sales};