import https from 'https';

let URL_operatividad = null;
let URL_credenciales = null;

if (process.env.REACT_APP_BACKEND_ADDRESS_SERVER === undefined || process.env.REACT_APP_BACKEND_ADDRESS_SERVER === null) {
    URL_operatividad = process.env.REACT_APP_BACKEND_ADDRESS;
    URL_credenciales =  process.env.REACT_APP_BACKEND_ADDRESS_LOGIN;
} else {
    URL_operatividad = process.env.REACT_APP_BACKEND_ADDRESS_SERVER;
    URL_credenciales =  process.env.REACT_APP_BACKEND_ADDRESS_LOGIN_SERVER;

}
// const URL_credenciales = process.env.REACT_APP_BACKEND_ADDRESS_LOGIN;

// console.log([URL_operatividad, URL_credenciales]);

// const cert_file = require('./localhost.crt');
// const key_file = require('./localhost.key');


const options = {
    cert: process.env.REACT_APP_SSL_CRT_FILE,
    key: process.env.REACT_APP_SSL_CRT_FILE,
    passphrase: 'Tidimolamucho',
    rejectUnauthorized: true
};

async function getData(data) {
    // console.log(URL_operatividad);
    // console.log(URL_credenciales);
    // Default options are marked with *
    const response = await fetch(URL_operatividad, {
            method: 'GET',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                Accept: 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            agent: new https.Agent(options),

            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer'//, // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            //body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(response => response.json() )
            .then(response => response['response'] )
    ;
    return await response; // parses JSON response into native JavaScript objects
}


async function nonAuthPostData(data) {
    // console.log(URL_operatividad);
    // console.log(URL_credenciales);
    // Default options are marked with *
    const response = await fetch(URL_operatividad, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                Accept: 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            agent: new https.Agent(options),
            //     insecure: true,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer',//, // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            //body: JSON.stringify(data) // body data type must match "Content-Type" header

            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(response => response.json() )
            .then(response => response['response'] )
    ;
    // console.log(response);
    return await response;
    //await return response.json(); // parses JSON response into native JavaScript objects
}


async function postData(data) {
    // console.log(URL_operatividad);
    // console.log(URL_credenciales);
    // Default options are marked with *
    const response = await fetch(URL_operatividad, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                Accept: 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            agent: new https.Agent(options),
            //     insecure: true,
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer',//, // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            //body: JSON.stringify(data) // body data type must match "Content-Type" header

            // redirect: 'follow', // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(response => response.json() )
            .then(response => response['response'] )
    ;
    // console.log(response);
    return await response;
    //await return response.json(); // parses JSON response into native JavaScript objects
}


async function create_JWT_api(data) {
    // console.log(URL_operatividad);
    // console.log(URL_credenciales);
    // API call to create the first JWT. It goes via the Login URL and does not require the authentication header
    // console.log(data);
    // Default options are marked with *
    const response = await fetch(URL_credenciales, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                Accept: 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            agent: new https.Agent(options),
            //     insecure: true,

            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(response => response.json() )
            .then(response => response['response'] )
    ;
    // console.log(response);
    return await response;
    //await return response.json(); // parses JSON response into native JavaScript objects
}

// async function regenerate_JWT_api(data) {
//     // API call to create the first JWT. It goes via the Login URL and does not require the authentication header
//
//     // Default options are marked with *
//     // API call to get the JWT
//     const response = await fetch(URL_credenciales, {
//             method: 'POST',
//             mode: 'cors', // no-cors, *cors, same-origin
//             cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//             credentials: 'include', // include, *same-origin, omit
//             headers: {
//                 Accept: 'application/json'
//                 // 'Content-Type': 'application/x-www-form-urlencoded',
//             },
//             agent: new https.Agent(options),
//
//             redirect: 'follow', // manual, *follow, error
//             referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//             body: JSON.stringify(data) // body data type must match "Content-Type" header
//         }).then(response => response.json() )
//             .then(response => response['response'] )
//     ;
//     return await response;
// }


async function cookies_API(data) {

    // API call to get the cookies created by the server
    const response2 = await fetch(URL_credenciales, {
            method: 'POST',
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit
            headers: {
                Accept: 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            agent: new https.Agent(options),
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data)
        }).then(response2 => response2.json() )
            .then(response2 => response2['response'] )
    ;
    return await response2; // parses JSON response into native JavaScript objects
    // return null;
}

export {postData, getData, create_JWT_api, nonAuthPostData, cookies_API};