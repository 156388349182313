import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
//import {Link, Redirect } from 'react-router-dom';
import "../functions/Session_cookies";
import "../pages/LandingPage.css";
import "./New_Changes.css";
import "./New_Returns.css";
import {Create_json_return} from "../functions/Returns_submit";
import {get_info_from_ID} from "../functions/Retrieve_Information_From_ID.js";
import Dropdown from "react-bootstrap/Dropdown";
import file from "./Profile_Attributes";
import {
	email_validation,
	validate_characters,
	validate_number,
	validate_positive_integers_or_null,
	calculatorOfSinglePrice,
	calculateTaxesInSuboperationForTotals,
	calculateTaxesInSuboperation,
	check_no_product_has_been_selected_on_change_ticket,
	validate_integers_or_null,
	isUserACompany, email_validation_or_empty
} from "../common/utilities";
import {loadingPictureCode} from "../common/ajax-loader";
import {updateClientInOperation} from "../functions/Update_client_in_operation";


export class NewReturn extends React.Component {
	
	constructor(props) {
        super(props);
        this.state = this.props.data[0]
		}


	renderChangeTableHeader = () => {
		var first_line = this.state.SaleHeaders[0];

		first_line = this.renameThePriceWithCurrencyInSaleTableHeader(first_line);

		if (first_line === undefined) {
			return null;
		} else {
			let header = Object.keys(first_line)
			return header.map((key, index__) => {
				return <th key={index__}>{key}</th>
			})
		}
	}

	renameThePriceWithCurrencyInSaleTableHeader = (header) => {

		var ccy = null;
		if (isUserACompany()) {
			ccy = this.state.Profile.Company.Currency;
		} else {
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			ccy = Suboperation_data.Info.Returns_info.Return_data[0].Product_data.Currency;
		}


		// Eliminate the currency header in case it's missing
		let newPrice = 'Price (' + ccy + ')';
		// console.log(ccy);
		if (ccy === null) {
			newPrice = 'Price';
		}
		let newPriceWoVat = 'Price w/o VAT (' + ccy + ')';
		if (ccy === null) {
			newPriceWoVat = 'Price w/o VAT';
		}

		const newHeader = {};

		newHeader['No'] = header['No'];
		newHeader['Product'] = header['Product'];
		newHeader['Brand'] = header['Brand'];
		newHeader['Type'] = header['Type'];
		newHeader['Subtype'] = header['Subtype'];
		newHeader['Reference'] = header['Reference'];
		newHeader[newPrice] = header['Price'];
		newHeader[newPriceWoVat] = header['Price w/o VAT'];
		newHeader['VAT (%)'] = header['VAT (%)'];
		newHeader['Units'] = header['Units'];
		newHeader['Promotion'] = header['Promotion'];
		newHeader['Discount (%)'] = header['Discount (%)'];

		return newHeader;
	}
   
   decisionMakingHowManyRowsForChange = () => {
		//const NoOfRows_Sale = this.state.NoOfRows_Sale;
		var array = [];
		
		for (let step = 0; step < 1; step++) {
			//console.log(step);
			array.push(this.renderChangeTableData(step));
		}		
		//console.log(array);
		return array;
	}
		
		
	renderChangeTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {
			// console.log(this.state.PopUpMoreInfo);
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			//console.log(Suboperation_data.Info.Returns_info.Return_data);
			return Suboperation_data.Info.Returns_info.Return_data.map((row, index) => {

				const {Product_data, Units, Promotion, Discount} = row;

				return (
					<tr>
						<td>{index+1}</td>
						<td title={Product_data.Product}> {Product_data.Product} </td>
						<td title={Product_data.Brand}> {Product_data.Brand} </td>
						<td title={Product_data.Product_Type}> {Product_data.Product_Type} </td>
						<td title={Product_data.Product_Subtype}> {Product_data.Product_Subtype} </td>
						<td title={Product_data.Sellers_ID_Reference}> {Product_data.Sellers_ID_Reference} </td>
						<td title={Product_data.Price}> {Product_data.Price} </td>
						<td title={Product_data.Price_without_VAT}> {Product_data.Price_without_VAT} </td>
						<td title={Product_data.VAT}> {Product_data.VAT} </td>
						<td title={Units}> {Units} </td>
						<td title={Promotion}> {Promotion} </td>
						<td title={Discount}> {Discount} </td>
					</tr>
				)
			})


		} else {
			const Change_index = +row_index + 1;

			// console.log(this.state.ChangeContent);
			return this.state.ChangeContent.map((header, index) => {
					const {
						Index,
						Product,
						Brand,
						Type,
						Subtype,
						Reference,
						Price,
						Price_without_VAT,
						VAT,
						Units,
						Promotion,
						Discount
					} = header //destructuring
					//console.log(Index);
					return (
						<tr key={Change_index} data-rowid={Change_index} onClick={this.clickOnTableRow}
							onChange={(e) => this.changeOnTableRow(e)}>
							<td title={Index}>{Index}</td>
							<td title={Product}>{Product}</td>
							<td title={Brand}>{Brand}</td>
							<td title={Type}>{Type}</td>
							<td title={Subtype}>{Subtype}</td>
							<td title={Reference}>{Reference}</td>
							<td title={Price}>{Price}</td>
							<td title={Price_without_VAT}>{Price_without_VAT}</td>
							<td title={VAT}>{VAT}</td>
							<td title={Units}>{Units}</td>
							<td title={Promotion}>{Promotion}</td>
							<td title={Discount}>{Discount}</td>
						</tr>
					)
				}
			)
		}
	}
	
	
	
	clickOnTableRow = (event) => {
		const id = event.currentTarget.getAttribute("data-rowid");
		this.setState({RoIndex: id});
		return id;
	}
	
	changeOnTableRow = (event) => {
		
		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}


	NewChangeID = () => {

		if (this.state.PopUpMoreInfo !== null) {
			return (
				<div className='IdChange'>
					<span className='IdChangeID1'> {'ID'} </span>
					<span className='IdChangeID2'> {this.IdChangeOldReturn()} </span>
				</div>
			)

		} else {
			return (
				<div className='IdChange'>
					<span className='IdChangeID1'> {'ID'} </span>
					<div className='verticalSeparatorFirstBoxesChangeScreen'> </div>
					<span className='IdChangeID2'>  {this.retrieveInformationFromIDForm()} </span>

					<div className='horizontalSeparatorFirstBoxesChanges'> </div>
				</div>
			)
		}
	}

	IdChangeOldReturn = () => {
		var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
		return (
			<span className='IdChangeID2Previous'> {Relevant_Operation.ID} </span>
		)
	}

	retrieveInformationFromIDForm = () => {

		let div = 'IdChangeID3';
		if (this.state.PressedEndButtonWithoutID === true) {
			div = 'IdChangeID3Red';
		}

		return [
			this.messageWrongID(),
			<input
				className={div}
				autoFocus
				type="numeric"
				placeholder='Enter ID'
				disabled={this.state.IDInputIsDisabled}
				onChange={(e) => {this.writeIDForInformation(e.target.value)}}
			/>,

			<Button className="ID_Change_button" block size="lg" onClick= {(e) => this.retrieveInformationFrom(e.target) }>
				{'Fetch information'}
			</Button>
		]
	}

	messageWrongID = () => {

		if ( this.state.HighLevelErrors.oldSaleID ) {
			return <div className='messageWrongID'> ID must be an integer without spaces</div>;
		} else { return null;}
	}

   
   writeIDForInformation = (e) => {

	   this.validateOldSaleID(e);
	   //console.log(e);
	   this.setState({IDRequired : e});
	   return null;
   }

	validateOldSaleID = (oldSaleID) => {
		// console.log(oldSaleID);
		// Validates the old sale ID

		if (validate_integers_or_null(oldSaleID) || oldSaleID === undefined) {
			// console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrors, {oldSaleID: false })
			);
		} else {
			// console.log('holi');
			this.setState(
				Object.assign(this.state.HighLevelErrors, {oldSaleID: true })
			);
		}
	}
   
   retrieveInformationFrom = (e) => {

	   // Render the loading icon
	   this.setState({WaitingForRequest: true});
	   
	    const ID = this.state.IDRequired;
	   
	    //	Calling the API 
	    let IDInfo = function() {
			return get_info_from_ID(ID).then(json => {return json}) ;
	    }
	   
	    let idInfo = IDInfo()
	    
	    // Once the response is received
	    idInfo.then( (response) => {
	       
			//console.log(response);
			if (response.contenido.Retrieve_ID_available_products.Status === "OK") {
				//console.log('Status OK');

				this.fillInOperationsArrayChooseChangesTable(response.contenido.Retrieve_ID_available_products.Available_products.length);
				
				this.setState(
					Object.assign(this.state.ID_info, {Available_products: response.contenido.Retrieve_ID_available_products.Available_products })
				);
				
				
				this.setState(
					Object.assign(this.state.ID_info, {Client: response.contenido.Retrieve_ID_available_products.Client })
				);
				
				this.setEmailFromPreviousSale(response.contenido.Retrieve_ID_available_products.Client.Email);

				this.renderAvailableProduts();
				
				this.renderClientInformation(response.contenido.Retrieve_ID_available_products.Client.Email);
				this.validateHighLevelErrorsInChooseChangesTable('Units', true);
				this.validateHighLevelErrorsInChooseChangesTable('Reason', true);
				this.setState({ClientConfirmsSendingByEmailEmail: ''});

				this.setState({IDInputIsDisabled: true}, () => this.checkErrorMessageOnButton());
				this.setState({PressedEndButtonWithoutID: false}, () => this.checkErrorMessageOnButton());
				
				// Set the New_ID_Provider_Operation
				this.setState({New_ID_Provider_Operation: this.state.IDRequired})

				//Remove the loading icon
				this.setState({WaitingForRequest: false});

				this.setState(
					Object.assign(this.state.HighLevelErrors, {Non_existing_ID: false})
				);
				this.setState(
					Object.assign(this.state.HighLevelErrors, {Empty_ID: false})
				);
				
				return null;
				
			} else {
				console.log('Status NOK');

				//Remove the loading icon
				this.setState({WaitingForRequest: false});


				if (response.contenido.Retrieve_ID_available_products.Error_code === 'Retrieve_ID_Information_1') {
					this.setState(
						Object.assign(this.state.HighLevelErrors, {Non_existing_ID: true})
					);
				}
				if (response.contenido.Retrieve_ID_available_products.Error_code === 'Retrieve_ID_Information_3') {
					// Reset back that the ID currently exists
					this.setState(
						Object.assign(this.state.HighLevelErrors, {Non_existing_ID: false})
					);
					this.setState(
						Object.assign(this.state.HighLevelErrors, {Empty_ID: true})
					);

				}
				if (response.contenido.Retrieve_ID_available_products.Error_code === 'Retrieve_ID_Information_2') {
					this.setState(
						Object.assign(this.state.HighLevelErrors, {Non_existing_ID: true})
					);
				}

				return null;
			}
			
	    } )
	   return null;
   }


	fillInOperationsArrayChooseChangesTable = (numberOfRows) => {

		// let originalArray = [{Units: false, Reason: false}];
		let newArray = [];

		while (newArray.length <numberOfRows) {
			newArray.push({Units: false, Reason: false, UnitsBiggerThanExpected: false})
		}

		this.setState(
			Object.assign(this.state.OperationsErrors, {ChooseChange: newArray })
		);

		return null;
	}

   
   setEmailFromPreviousSale = (Email_original) => {
	   
	    this.setState(
					Object.assign(this.state.Client, {Email: Email_original })
				);
		return null;
   }
	
	renderAvailableProduts = () => {
		const AvProds = this.state.ID_info.Available_products;
		//console.log(AvProds);
		var ChooseChanges = [];
		var ChangeContents = [];
		
		var Index = 1;
		
		AvProds.forEach(AvProd => {

			const {Units, Promotion, Discount, Sale_data} = AvProd
				const {Product, Brand, Product_Type, Product_Subtype, Sellers_ID_Reference, Price, Price_without_VAT, VAT} = Sale_data
				
				var EachSale = {};
				var ChooseEachChange = {};
				
				EachSale['Index'] = Index;
				EachSale['Product'] = Product;
				EachSale['Brand'] = Brand;
				EachSale['Type'] = Product_Type;
				EachSale['Subtype'] = Product_Subtype;
				EachSale['Reference'] = Sellers_ID_Reference;
				EachSale['Price'] = Price;
				EachSale['Price_without_VAT'] = Price_without_VAT;
				EachSale['VAT'] = VAT;
				EachSale['Units'] = Units;
				EachSale['Promotion'] = Promotion;
				EachSale['Discount'] = Discount;
				EachSale['Units_to_change'] = null;
				EachSale['Reason'] = null;
				
				ChangeContents.push(EachSale);
				
				ChooseEachChange['Choose_change_index'] = Index;
				ChooseEachChange['Units'] = '';
				ChooseEachChange['Reason'] = '';
				
				ChooseChanges.push(ChooseEachChange);
				Index = +Index +1;		
			}
		
		)
		this.setState({ChangeContent: ChangeContents})
		this.setState({NoOfRows_Change: ChangeContents.length});
		this.setState({ChooseChangesFixed: ChooseChanges})
		
		return null;
	}
	
	renderClientInformation = (email) => {

		this.setState(
			Object.assign(this.state.Client, {Email: email })
		);

		this.evaluateInputInSales('ClientEmail', null, null, email);

		return null;

	}

	getSuboperationFromOperation_ID = (row_index) => {

		const Ops = this.state.HistoricalOperationsArray;

		var resultado = null;
		Ops.forEach(holi => {
			if (holi.Operation_id === row_index ) {
				resultado = holi;
				return null;
			}
		} )

		return resultado;
	}

	ChooseChangesTable = () => {

		return (
			<table className='ChooseReturnsTable'>
				<thead className='ChooseChangesTableHeader'>
				<tr>{this.renderChooseChangeTableHeader()}</tr>
				</thead>
				<tbody className='ChooseChangesTableBody'>
				{this.decisionMakingHowManyPotentialChanges()}
				</tbody>
			</table>
		)
	}
	
	renderChooseChangeTableHeader = () => {
		  var first_line = this.state.ChooseReturnsHeaders[0];
		  
		  if (first_line === undefined) {
			  return null;
		  } else {
			  let header = Object.keys(first_line)
			  return header.map((key, index__) => {
					return <th key={index__}>{key}</th>
			  })
		  }
	}
	
	decisionMakingHowManyPotentialChanges = () => {
		// const NoOfRows_Change = this.state.NoOfRows_Change;
		var array = [];
		// for (let step = 0; step < NoOfRows_Change; step++) {
		for (let step = 0; step < 1; step++) {
			//console.log(step);
			array.push(this.renderChooseChangeTableData(step));
		}
		
		return array;
	}
	
	
	renderChooseChangeTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			// console.log(Suboperation_data.Info.Returns_info.Return_data);
			return Suboperation_data.Info.Returns_info.Return_data.map((row, index) => {

				const {Product_data, Units} = row;

				return (
					<tr key={index}>
						<td title={+index+1}>{+index+1}</td>
						<td title={Units}>{Units}</td>
						<td title={Product_data.Reason}>{Product_data.Reason}</td>
					</tr>
				)
			})

		} else {

			const Change_index = +row_index + 1;
			return this.state.ChooseChangesFixed.map((header, index) => {
					const {Choose_change_index} = header //destructuring

					return (
						<tr key={Choose_change_index} data-rowid={Change_index} onClick={this.clickOnChooseChangesTableRow}
							onChange={(e) => this.changeOnChooseChangeTableRow(e)}>
							<td>{+index+1}</td>
							<td>
								{this.inputChooseTablefunction('Units', index )}
							</td>
							<td>
								{this.inputChooseTablefunction('Reason', index)}
							</td>
						</tr>
					)
				}
			)
		}
	}
	
	clickOnChooseChangesTableRow = (event) => {
		
		//const id = event.currentTarget.getAttribute("data-rowid");
		////console.log(id);
		//this.setState({RoIndex: id});
		//return id;
		return null;
	}
	
	changeOnChooseChangeTableRow = (event) => {
		
		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}

	
	inputChooseTablefunction = (field, indice) => {
		//console.log(field);

		let div = 'inputChooseTablefunctionUnits';
		// console.log(this.state.HighLevelErrors.ChooseChange);
		// console.log(this.state.OperationsErrors.ChooseChange);
		// console.log(this.state.OperationsErrors.ChooseChange[indice]);
		if (this.state.PressedEndButton === true &&
			((this.state.OperationsErrors.ChooseChange[indice].Units === true || this.state.HighLevelErrors.ChooseChange.AllUnits === true)
				|| this.state.OperationsErrors.ChooseChange[indice].UnitsBiggerThanExpected)
		) {
			div = 'inputChooseTablefunctionUnitsRed';
		}

		let div2 = 'inputChooseTablefunctionReason';
		// console.log(indice)
		// console.log(this.state.OperationsErrors.ChooseChange[indice].Reason);
		// console.log(this.state.PressedEndButton);
		if (this.state.OperationsErrors.ChooseChange[indice].Reason === true &&
			this.state.PressedEndButton === true) {
			div2 = 'inputChooseTablefunctionReasonRed';
		}

		//const row_no = this.state.RoIndex;
		if (field === 'Units') {
			return (
				<input
					type="text"
					className={div}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}
		if (field === 'Reason') {
			return (
				<input
					type="text"
					// className='inputChooseTablefunctionReason'
					className = {div2}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.handleChooseChangeItem(e.target.value, field, indice)}
				/>
			)
		}
	}
   
	handleChooseChangeItem = (e, field, indice) => {

		// console.log(this.state.ChangeContent);
		// console.log(indice);

		if (field==='Units') {
			if (e === '') { e = null}
			this.setState(
				Object.assign(this.state.ChangeContent[indice], {Units_to_change: e }), () => {this.validateInputInChooseChangesTable(field, indice, e)}
			);
		}

		if (field==='Reason') {
			//console.log('true');
			this.setState(
				Object.assign(this.state.ChangeContent[indice], {Reason: e }), () => {this.validateInputInChooseChangesTable(field, indice,e)}
			);
		}
	   
	   return null;
   }


	checkAreChooseChangesUnitsGreaterThanSoldUnits = (indice, input) => {

		console.log(+input)
		console.log(+this.state.ChangeContent[indice].Units)

		if (+input <= +this.state.ChangeContent[indice].Units) {
			console.log('menor')
			this.setState(
				Object.assign(this.state.OperationsErrors.ChooseChange[indice], {UnitsBiggerThanExpected: false}), () => this.validateHighLevelErrorsInChooseChangesTable('UnitsBiggerThanExpected', true)
			);
		} else {
			console.log('mayor')
			this.setState(
				Object.assign(this.state.OperationsErrors.ChooseChange[indice], {UnitsBiggerThanExpected: true}), () => this.validateHighLevelErrorsInChooseChangesTable('UnitsBiggerThanExpected', true)
			);
		}

		return null;
	}


	validateInputInChooseChangesTable = (field, indice, input) => {

		if (field === 'Units') {
			console.log(input)
			console.log(input==='')
			this.checkAreChooseChangesUnitsGreaterThanSoldUnits(indice, input);
			if (validate_positive_integers_or_null(input) || input === undefined) {
				console.log(input)
				console.log(input==='')
				// There's no error
				this.setState(
					Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
				);

				if (input === '' || input === undefined) {

					if (this.state.ChangeContent[indice].Reason === null) {
						// We have deleted the previously existing Units or it never existed. Field Reason is not populated --> Final situation (Units: '', Reason: '')
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
						);
					} else {
						if (input === '') {
							this.setState(
								Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
							);
						} else {
							// Reason is not missing and we have deleted Units field --> You must provide the units you want to change
							this.setState(
								Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
							);
						}
					}

				} else {
					if (this.state.ChangeContent[indice].Reason !== null) {
						if (this.state.ChangeContent[indice].Reason === ''){
							// User added and then removed the Reason. Assign an error to Reason (if there's no reason provided, the user cannot conclude the transaction)
							this.setState(
								Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
							);
						} else {
							this.setState(
								Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
							);
						}
					} else {
						// Assign an error to Reason (if there's no reason provided, the user cannot conclude the transaction)
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
						);
					}
				}

			} else {
				// There's some error
				if (input === null) {
					// The user has removed the input in Units - you can check up if Units is now null, not ''
					//    this.setState(
					// 	   Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					//    );
					if (this.state.ChangeContent[indice].Reason === '' || this.state.ChangeContent[indice].Reason === null) {
						// Reason is empty
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
						);
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', false)
						);
					} else {
						// Reason should not be provided if Units is not populated
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
						);
						this.setState(
							Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
						);
					}
				} else {
					this.setState(
						Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					);
				}
			}
		}

		if (field === 'Reason') {

			// let units = this.state.OperationsErrors.ChooseChange[indice].Units;
			// console.log(this.state.OperationsErrors.ChooseChange[indice]);
			// if (input !== '' && this.state.OperationsErrors.ChooseChange[indice].Units === false) {

			if (input === '' || input === undefined) {

				// console.log(this.state.ChangeContent[indice]);
				if (this.state.ChangeContent[indice].Units_to_change === null || this.state.ChangeContent[indice].Units_to_change === '') {
					// We have deleted the previously existing Reason or it never existed. Field Units is not populated --> Final situation (Reason: '', Units: '')
					this.setState(
						Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					);
					this.setState(
						Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
					);
				} else {
					// Units is not missing and we have deleted Reason field --> You must provide the Reason you want to change
					this.setState(
						Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
					);
				}

			} else {
				// console.log(this.state.ChangeContent[indice]);
				if (this.state.ChangeContent[indice].Units_to_change !== null) {
					this.setState(
						Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					);
					this.setState(
						Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: false}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
					);
				} else {
					// Assign an error to Units (if there's no Units provided, the user cannot conclude the transaction)
					this.setState(
						Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Units: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Units', true)
					);
					this.setState(
						Object.assign(this.state.OperationsErrors.ChooseChange[indice], {Reason: true}), () => this.validateHighLevelErrorsInChooseChangesTable('Reason', true)
					);
				}
			}


		}

		// console.log(this.state.OperationsErrors.ChooseChange);
		return null;

	}


	validateHighLevelErrorsInChooseChangesTable = (field, repeat) => {
		// Assigns the high level errors to Units and Reasons. The input Field is for performance

		let chooseChangesErrors = this.state.OperationsErrors.ChooseChange;
		let changeContentValues = this.state.ChangeContent;
		// console.log(chooseChangesErrors);
		// this.state.ChangeContent[indice], {Units_to_change

		if (field === 'Units') {
			// console.log(changeContentValues);
			// If no Units is selected, there's an error. Also, if there's any true, there's an error.
			let check_empties = [];
			let check_errors = [];
			let check_missing_units = [];
			// let check_misalignment_with_reasons = [];


			for (let step = 0; step < chooseChangesErrors.length; step++) {
				// Check empty --> We want to eliminate the error message if at least 1 Units is populated (there are more conditions to check)
				if (changeContentValues[step].Units_to_change !== null) {
					check_empties.push(true);
				}
				//Check if there is any true in the Units' errors (this corresponds to, for instance, Reason being populated but Units unpopulated)
				if (chooseChangesErrors[step].Units === true) {
					check_missing_units.push(true);
				}

				// // Check the Reason is populated but the Units are not populated --> Error
				// if (changeContentValues[step].Units_to_change === '' && changeContentValues[step].Reason !== '') {
				// 	check_misalignment_with_reasons.push(false);
				// }
				// Check strings and not integers
				if (validate_characters(+changeContentValues[step].Units_to_change) === true) {
					check_errors.push(true);
					// For decision of which input field must be in red
					this.setState(
						Object.assign(this.state.HighLevelErrors.ChooseChange, {CharactersInUnits: true})
					);
				}
			}

			if (check_errors.includes(true)) {
				this.setState(
					Object.assign(this.state.HighLevelErrors.ChooseChange, {CharactersInUnits: true})
				);
			} else {
				this.setState(
					Object.assign(this.state.HighLevelErrors.ChooseChange, {CharactersInUnits: false})
				);
			}
			// console.log(check_errors);
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {AllUnits:
						!check_empties.includes(true) })
			);

			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {Units: check_missing_units.includes(true)})
			);

			if (repeat !== false) {
				this.validateHighLevelErrorsInChooseChangesTable('Reason', false);
			}
			// console.log(!check_empties.includes(true) ||
			// 	check_errors.includes(true));
		}

		if (field === 'Reason') {
			// If there's any true, there's an error.
			let checks = []
			for (let step = 0; step < chooseChangesErrors.length; step++) {
				// Check true
				if (chooseChangesErrors[step].Reason === true) {
					checks.push(true);
				}
			}
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {Reason: checks.includes(true)})
				// , () => console.log(this.state.HighLevelErrors.ChooseChange)
			);
			// console.log(checks.includes(true));

			this.validateHighLevelErrorsInChooseChangesTable('Units', false);
		}

		if (field === 'UnitsBiggerThanExpected') {
			var check_bigUnits = []
			for (let step = 0; step < chooseChangesErrors.length; step++) {
				console.log(chooseChangesErrors[step].UnitsBiggerThanExpected)
				if (chooseChangesErrors[step].UnitsBiggerThanExpected) {
					check_bigUnits.push(true)
				}
			}
			let result = false;
			// console.log(check_bigUnits.includes(true))
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {UnitsBiggerThanExpected: check_bigUnits.includes(true)}),
				() => console.log(this.state.HighLevelErrors.ChooseChange)
			);
		}

		this.checkErrorMessageOnButton();
		this.ErrorMessagesInChangeWindow();

		return null;

	}



	
	clickOnTableRow = (event) => {
		const id = event.currentTarget.getAttribute("data-rowid");
		this.setState({RoIndex: id});
		return id;
	}
	
	changeOnTableRow = (event) => {
		
		const id = event.currentTarget.getAttribute("data-rowid");
		//console.log(id);
		this.setState({RoIndex: id});
		return id;
	}
	
   
	
	
	NewReturnTicket = () => {
		return(
			<div className= 'NewReturnTicket'>
				{this.NewReturnTicketSummary()}
				{/*{this.NewReturnTicketClient()}*/}
				{this.NewReturnTicketButton()}
			</div>
		)
	}
	
	NewReturnTicketSummary = () => {
		return (
			<div className='NewReturnTicketSummary'>
				{this.NewReturnTicketHeader()}
				{this.NewChooseChangesSummaryTable()}
				<div className='horizontalDivisionInTableForTotalsReturn'> </div>
				{this.NewReturnSummaryTotals()}
			</div>
		)
	}

	NewChooseChangesSummaryTable = () => {
		return(
			<table className='ReturnSummaryAbove'>
				<thead className='ReturnSummaryAboveHead'>
				<tr>{this.renderSummaryTableHeader()}</tr>
				</thead>
				<div className='horizontalDivisionInTable'> </div>
				<tbody className='ReturnSummaryAboveBody'>
				{this.decisionDrivenHowManyRowsInChangeSummary()}
				</tbody>
			</table>
		)
	}
	
	decisionDrivenHowManyRowsInChangeSummary = () => {
		
		var array = [];
		for (let step = 0; step < 1; step++) {
			array.push(this.renderChangeSummaryTableData(step));
		}
		return array;
	}


	renderChangeSummaryTableData = (row_index) => {

		if (this.state.PopUpMoreInfo !== null) {

			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			return Suboperation_data.Info.Returns_info.Return_data.map((row, index) => {
				const {Product_data, VAT, Discount, Units} = row;
				console.log(Product_data)
				console.log(Suboperation_data)

				var DiscountPerc = null;

				if (Discount !== "" && Discount !== null) {
					DiscountPerc = Discount + ' %';
				} else {
					DiscountPerc = null;
				}
				console.log(calculatorOfSinglePrice(Product_data.Price,
					Product_data.Price_without_VAT, VAT, Units, Discount))
				return (
					<tr key={index}>
						<td title={Product_data.Product}>{Product_data.Product}</td>
						<td title={Units}>{Units}</td>
						<td title={DiscountPerc}>{DiscountPerc}</td>
						<td title={calculatorOfSinglePrice(Product_data.Price,
							Product_data.Price_without_VAT, Product_data.VAT, Units, Discount)}>{calculatorOfSinglePrice(Product_data.Price,
							Product_data.Price_without_VAT, Product_data.VAT, Units, Discount)}</td>
					</tr>
				)
			})


		} else {
			var sale_data = this.state.ChangeContent;
			let check_no_product_changed = check_no_product_has_been_selected_on_change_ticket(sale_data);

			return sale_data.map((header, index) => {
				// console.log(index)
					const {Index, Product, Price, Price_without_VAT, VAT, Units_to_change, Discount} = header //destructuring

				var DiscountPerc = null;

				if (Discount !== "" && Discount !== null) {
					DiscountPerc = Discount + ' %';
				} else {
					DiscountPerc = null;
				}
					if (Units_to_change !== 0 && Units_to_change !== '' && Units_to_change !== null) {
						return (
							<tr key={Index} data-rowid={'Noo'}>
								<td title={Product}>{Product}</td>
								<td title={Units_to_change}>{Units_to_change}</td>
								<td title={DiscountPerc}>{DiscountPerc}</td>
								<td title={calculatorOfSinglePrice(Price,
									Price_without_VAT, VAT, Units_to_change, Discount)}>{calculatorOfSinglePrice(Price,
									Price_without_VAT, VAT, Units_to_change, Discount)}</td>
							</tr>
						)
					}

				if (Index===1 && check_no_product_changed === true) {
					return (
						<tr key={Index} data-rowid={'Noo'}>
							<td title={Product}>{Product}</td>
							<td title={Units_to_change}>{Units_to_change}</td>
							<td title={DiscountPerc}>{DiscountPerc}</td>
							<td title={calculatorOfSinglePrice(Price,
								Price_without_VAT, VAT, Units_to_change, Discount)}>0.00</td>
						</tr>
					)
				}


					return null;
				}
			)
		}
	}
	
	
	
	
	NewReturnTicketClient = () => {

		return (
			<div className='NewReturnTicketClient'>
				<div className='NewReturnTicketClientHeader'> {this.NewReturnClientDataHeader()} </div>
				{this.NewReturnClientDataEmail()}
			</div>
		)
	}

	NewReturnClientDataHeader = () => {
		return (
			<div className= 'NewReturnClientDataHeader'>
				<span> {'Client data'} </span>
			</div>
		)
	}

	NewReturnTicketButton = () => {
		return (
			this.buttonScanAndPayReturn()
		)
	}

	NewReturnTicketHeader = () => {

		let first_line = this.state.OrderTicketHeader[1];

		return (
			<div>
				<table className='NewChangeTicketSummaryHeader'>
					<tr>
						<th>{first_line.Order_summary}</th>
						{this.renderTicketHeaderChange()}
					</tr>
				</table>
			</div>
		)
	}


	renderTicketHeaderChange = () => {

		if (this.state.PopUpMoreInfo !== null) {
			// console.log(this.state.PopUpMoreInfo);
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			return (
				<th>{Suboperation_data.Info.Returns_info.Return_data[0].Product_data.Screen_datetime}</th>
			)

		} else {
			return (
				<th>{this.state.ScreenDatetime}</th>
			)
		}

	}

	NewReturnSummaryTotals = () => {
		return (
			<div className='NewReturnSummaryTotals'>
				<table className='NewChangeSummaryTotalsTable'>
					<tbody className='NewChangeSummaryTotalsTableBody'>
					<td>{this.subtotalFirstColumn()}</td>
					<td>{this.subtotalSecondColumn()}</td>
					</tbody>
				</table>
			</div>
		)
	}

	subtotalFirstColumn = () => {

		var ccy = null;
		if (isUserACompany()) {
			ccy = this.state.Profile.Company.Currency;
		} else {
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			ccy = Suboperation_data.Info.Returns_info.Return_data[0].Product_data.Currency;
		}

		// Eliminate the currency header in case it's missing
		let total = 'Total (' + ccy + ')'
		// console.log(ccy);
		if (ccy === null) {
			total = 'Total';
		}

		return ([
			<tr> <td> {'Subtotal'} </td> </tr >,
			<tr > <td> {'Shipping'} </td> </tr >,
			<tr > <td> </td> </tr >,
			<tr > <td> {total}</td> </tr >,
			<tr > <td> {'Taxes'}</td> </tr >
		])
	}

	subtotalSecondColumn = () => {
		return [
			<tr><td title={this.calculateWriteSubtotalReturn()}> {this.calculateWriteSubtotalReturn()} </td></tr >,
			<tr><td title={this.NewReturnShipping()}> {this.NewReturnShipping()} </td></tr >,
			<tr > <td> </td> </tr >,
			<tr><td title={this.calculateWriteTotal()}> {this.calculateWriteTotal()} </td></tr >,
			<tr><td title={this.writeTaxesNumber()}> {this.writeTaxesNumber()} </td></tr >
		]
	}
	

	calculateWriteSubtotalReturn = () => {
		var Changes = [];
		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Suboperation_data);
			Suboperation_data.Info.Returns_info.Return_data.map((row, index) => {

				const {Product_data, Discount, Units} = row;
				Changes.push(calculatorOfSinglePrice(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units, Discount));

				return null;
			})

		} else {

			var change_data = this.state.ChangeContent;
			// console.log(sale_data);
			// console.log(change_data);

						if (change_data !== undefined && change_data !== null ) {
				change_data.map((header, index) => {

					if (header.Units_to_change !== 0 && header.Units_to_change !== null && header.Units_to_change !== "") {
						// Take only those rows that will effectively be changed.
						// console.log(header);

						const {Price, Price_without_VAT, VAT, Units_to_change, Discount} = header //destructuring

						Changes.push(calculatorOfSinglePrice(Price, Price_without_VAT, VAT, Units_to_change, Discount));
					}

					return null;
				})
			}
		}
		// console.log(Sales);
		// console.log(Changes);
		const rounded = Math.round((this.calculateSubTotal([], Changes) + Number.EPSILON) * 100) / 100;

		return rounded.toFixed(2);
	}

	calculateSubTotal = (Sales, Changes) => {
		// console.log(Sales);
		// console.log(Changes);

		if (Changes.length === 0 || Changes[0] === 'NaN') {
			return 0;
		}

		const sales_array = Sales.map((i) => Number(i));
		const sum_Sales = sales_array.reduce((a, b) => a + b, 0);

		const changes_array = Changes.map((i) => Number(i));
		const sum_Changes = changes_array.reduce((a, b) => a + b, 0);

		return sum_Sales - sum_Changes;
	}









	NewReturnShipping = () => {
		var shipping = 0;

		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			shipping = Relevant_Operation.Shipping;

			const rounded = Math.round((+shipping + Number.EPSILON) * 100) / 100;
			const rounded_shipping =  rounded.toFixed(2);

			return (
				<div className='NewChangeShipping'>
					<span> {rounded_shipping}</span>
				</div>
			)
		} else {
			const rounded = Math.round((+this.state.ShippingNewSale + Number.EPSILON) * 100) / 100;

			return rounded.toFixed(2);

		}

	}


	calculateWriteTotal = () => {
		const subtotal = this.calculateWriteSubtotalReturn();
		const shipping = this.takeShipping();

		// const taxes = this.writeTaxesNumberForTotals();
		// console.log(taxes)

		const rounded = Math.round((+subtotal + +shipping + Number.EPSILON) * 100) / 100;

		return rounded.toFixed(2);
	}



	writeTaxesNumberForTotals = () => {
		var Sales = [];
		var Changes = [];
		var Returns = [];

		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			//console.log(Suboperation_data);
			Suboperation_data.Info.Returns_info.Return_data.map((row, index) => {
				console.log(row);
				const {Product_data, Units} = row;
				Returns.push(calculateTaxesInSuboperationForTotals(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units));
				return null;
			})

		} else {
			var sale_data = this.state.NewSales;
			var return_data = this.state.NewReturns;

			sale_data.map((header, index) => {
				const {Price, PricewoVAT, VAT, Units} = header //destructuring
				Sales.push(calculateTaxesInSuboperation(Price, PricewoVAT, VAT, Units));
				return null;
			})
			if (return_data !== undefined && return_data !== null ) {
				return_data.map((header, index) => {
					const {Price, PricewoVAT, VAT, Units} = header //destructuring
					Returns.push(calculateTaxesInSuboperation(Price, PricewoVAT, VAT, Units));
					return null;
				})
			}
		}
		const rounded = Math.round((this.calculateSubTotal([],Returns) + Number.EPSILON) * 100) / 100;
		console.log(rounded)
		return rounded.toFixed(2);
	}


	writeTaxesNumber = () => {
		var Changes = [];

		if (this.state.PopUpMoreInfo !== null) {
			//This is a popup from + Info field.
			const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			Suboperation_data.Info.Returns_info.Return_data.map((row, index) => {
				const {Product_data, Units} = row;
				Changes.push(calculateTaxesInSuboperation(Product_data.Price, Product_data.Price_without_VAT, Product_data.VAT, Units));
				return null;
			})

		} else {
			var change_data = this.state.ChangeContent;
			if (change_data !== undefined && change_data !== null ) {
				change_data.map((header, index) => {
					// console.log(change_data);
					const {Price, Price_without_VAT, VAT, Units_to_change} = header //destructuring
					Changes.push(calculateTaxesInSuboperation(Price, Price_without_VAT, VAT, Units_to_change));
					return null;
				})
			}
		}


		// console.log(Changes);
		const rounded = Math.round((this.calculateSubTotal([], Changes) + Number.EPSILON) * 100) / 100;
		return rounded.toFixed(2);
	}
	
	ValidateCorrectOperation = () => {
		return null
	}
	
	buttonScanAndPayReturn = () => {

		if (this.state.PopUpMoreInfo !== null) {
			return null;
		} else {

			return (
				<div className='buttonScanAndPayReturn'>
					{this.errorMessageOnButton()}
					<Button className="buttonScanAndPayButtonChange" block size="lg"
							onClick={this.wrapperButtonScanAndPay2Operations}
							// disabled={this.state.missingCrucialData === true || this.state.IDInputIsDisabled === false}
						>
						{'Scan & pay'}
					</Button>
				</div>
			)
		}
	}

	errorMessageOnButton = () => {
		if (this.state.missingCrucialData === true) {
			return <div className='errorMessageOnButton'> Please, provide the necessary information on My account. </div>
		}
		if (this.state.errorMessageOnButton === true) {
			return <div className='errorMessageOnButton'> Please, check the errors above before continuing. </div>
		} else { return null; }
	}


	validateChangeRequestInput = () => {

		let isThereAnyErrorInScreen = false;
		let errorsArray = this.state.OperationsErrors;
		let chooseChangesArray = this.state.HighLevelErrors.ChooseChange;
		let unitsTooBigChoose = errorsArray.ChooseChange.map(value => value.UnitsBiggerThanExpected);

		let countTrueInBigUnitsChoose = unitsTooBigChoose.filter(x => x === true).length;


		// console.log(chooseChangesArray);
		//
		// console.log(this.state.HighLevelErrors);

		if (chooseChangesArray.AllUnits === true || chooseChangesArray.CharactersInUnits === true) {
			isThereAnyErrorInScreen = true;
			console.log('lol')
		}

		if (this.state.HighLevelErrors.ChooseChange.Units === true || this.state.HighLevelErrors.ChooseChange.Reason === true
			|| this.state.HighLevelErrors.ChooseChange.AllUnits === true) {
			isThereAnyErrorInScreen = true;
		}

		if ( (countTrueInBigUnitsChoose === 1 && this.state.ChooseChange.length === 1) || countTrueInBigUnitsChoose > 0) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {UnitsBiggerThanExpected: true }), () => this.ErrorMessagesInChangeWindow()
			);
		} if ( !((countTrueInBigUnitsChoose === 1 && this.state.ChooseChange.length === 1) || countTrueInBigUnitsChoose > 0)) {
			this.setState(
				Object.assign(this.state.HighLevelErrors.ChooseChange, {UnitsBiggerThanExpected: false }), () => this.ErrorMessagesInChangeWindow()
			);
		}

		// if (errorsArray.ClientEmail === true) {
		// 	console.log('holi')
		// 	isThereAnyErrorInScreen = true;
		// 	this.setState(
		// 		Object.assign(this.state.HighLevelErrors, {Client_Email: true }), () => this.checksAfterInputValidation()
		// 	);
		// } else {
		// 	console.log('holi')
		// 	this.setState(
		// 		Object.assign(this.state.HighLevelErrors, {Client_Email: false }), () => this.checksAfterInputValidation()
		// 	);
		// }

		if (errorsArray.Shipping === true) {
			isThereAnyErrorInScreen = true;
			this.setState(
				Object.assign(this.state.HighLevelErrors, {Ship_ping: true }), () => this.checksAfterInputValidation()
			);
		}else {
			this.setState(
				Object.assign(this.state.HighLevelErrors, {Ship_ping: false }), () => this.checksAfterInputValidation()
			);
		}


		// this.checkErrorMessageOnButton();
		console.log(isThereAnyErrorInScreen);
		return isThereAnyErrorInScreen;

	}


	checksAfterInputValidation = () => {

		this.checkErrorMessageOnButton();
		this.ErrorMessagesInChangeWindow();
		return null;
	}



	checkErrorMessageOnButton = () => {

		if ( (this.state.PressedEndButton === true && this.checkHighLevelErrors() === true) ||
			this.state.PressedEndButtonWithoutID === true) {
			this.setState({errorMessageOnButton: true});
		} else {
			this.setState({errorMessageOnButton: false});
		}

		return null;
	}



	ErrorMessagesInChangeWindow = () => {
		// Displays the correct error message in the Sale Window

		let anyErrorCheck = this.checkHighLevelErrors();

		if (this.state.PressedEndButtonWithoutID === true) {
			let errorMessages = null;
			if (this.state.IDRequired === null) {
				errorMessages = 'Please, introduce an ID to fetch its past information.';
			} else {
				errorMessages = 'This ID is not linked to your past data.';
			}

			return(<div className='ErrorMessagesInChangeWindow'> {errorMessages.split('\n').map(str =>
				<p className='ErrorMessagesInChangeWindowText'>{str}</p>)} </div>)

		} else {
			let errorTitle = 'Please, check the following fields before finishing the transaction:';
			let errorMessages = '';
			let highLevelErrorsArray = this.state.HighLevelErrors;
			// console.log(highLevelErrorsArray);

			if (this.state.PressedEndButton === true && anyErrorCheck === true) {
				// console.log(highLevelErrorsArray);


				if (highLevelErrorsArray.ChooseChange.Units === true ||
					highLevelErrorsArray.ChooseChange.CharactersInUnits === true
					|| highLevelErrorsArray.ChooseChange.AllUnits === true) {
					const thisError = "\n - Select which products your client wants to return. Positive integer, i.e. 1, 2, 3,...";
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.ChooseChange.UnitsBiggerThanExpected === true) {
					const thisError = "\n - Units to return cannot exceed units previously sold.";
					errorMessages += thisError;
				}

				if (highLevelErrorsArray.ChooseChange.Reason === true) {
					const thisError = "\n - Provide a reason for the return.";
					errorMessages += thisError;
				}


				// if (highLevelErrorsArray.Client_Email === true) {
				// 	const thisError = "\n - Client's email";
				// 	errorMessages += thisError;
				// }

				if (highLevelErrorsArray.Ship_ping === true) {
					const thisError = "\n - Shipping cost, i.e. 5, 17.25,...";
					errorMessages += thisError;
				}



				return (
					<div className='ErrorMessagesInChangeWindowContainerHuge'>
						<div className='ErrorMessagesInChangeWindowTitle'> {errorTitle} </div>
						<div className='ErrorMessagesInChangeWindowHuge'> {errorMessages.split('\n').map(str =>
							<p className='ErrorMessagesInChangeWindowText'>{str}</p>)} </div>
					</div>
				)

			} else {
				return null;
			}
		}

	}


	ErrorMessageInIncorrectID = () => {

		let highLevelErrorsArray = this.state.HighLevelErrors;

		if (highLevelErrorsArray.Non_existing_ID === true) {
			return(
				<div className='ErrorMessageInIncorrectID'>
					This ID is not linked to your past data
				</div>
			)
		}
		if (highLevelErrorsArray.Empty_ID === true) {
			return(
				<div className='ErrorMessageInIncorrectID'>
					This ID does not contain any available products.
				</div>
			)
		} else {return null;}
	}

	IDInformationExplanation = () => {
		return(
			<div className='IDInformationExplanation'>
				Start by checking your client's ID information
			</div>
		)
	}


	checkHighLevelErrors = () => {
		// Checks if there are any high level errors

		let highLevelErrorsArray = this.state.HighLevelErrors;
		// let highLevelErrorsSalesArray = highLevelErrorsArray.Sales;
		let highLevelErrorsArrayChooseChange = this.state.HighLevelErrors.ChooseChange;

		// return highLevelErrorsSalesArray.Product === true || highLevelErrorsSalesArray.Price === true ||
		// 	highLevelErrorsSalesArray.PricewoVAT || highLevelErrorsSalesArray.VAT ||
		// 	highLevelErrorsSalesArray.Units || highLevelErrorsArray.ClientEmail ||
		// 	highLevelErrorsArray.Ship_ping || highLevelErrorsArrayChooseChange.Reason ||
		// 	highLevelErrorsArrayChooseChange.Units;

		return highLevelErrorsArray.Ship_ping ||
			highLevelErrorsArrayChooseChange.Reason ||
			highLevelErrorsArrayChooseChange.Units ||
			highLevelErrorsArrayChooseChange.AllUnits || highLevelErrorsArrayChooseChange.UnitsBiggerThanExpected;

	}


	wrapperButtonScanAndPay2Operations = () => {


		if (this.state.IDInputIsDisabled === true) {
			this.setState({PressedEndButton: true}, () => this.checkErrorMessageOnButton());
			// this.setState({PressedEndButtonWithoutID: false});

		} else {
			this.setState({PressedEndButtonWithoutID: true}, () => this.checkErrorMessageOnButton());
		}
		let check = this.validateChangeRequestInput();

		if (!check === true) {
			// console.log('enviada')
			this.buttonScanAndPaySubmit_ChangeOperation();
		}

		return null;
	}


	CloseButton = () => {

		return (
			<div className = 'CloseButtonReturn'>
				<Button className="CloseButtonButton" block size="lg" onClick={this.closeNewSuboperationPopUp}>
					<div className='CloseButtonButtonText'>{'X'}</div>
				</Button>
			</div>
		)
	}

	closeNewSuboperationPopUp = () => {

		this.actionsAfterClosingNewSuboperationPopup();

		// //Return to LandingPage view only
		// this.props.data[1]();
		let returnToLandingPage = this.props.data[1];
		returnToLandingPage();

		return null;
	}


	actionsAfterClosingNewSuboperationPopup = () => {
		// Reset the objects changed during the Sales process
		this.setState({
			NewSales: [{ No: 1, Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null,
				PricewoVAT: null, VAT: null, Units: null, Promotion: null, Discount: null}]});

		this.setState({SVG_PNG: null});
		this.setState({ErrorMessage: null});
		this.setState({OperationsErrors: {Sales: [{Product: true, Brand: false, Type: false, Subtype: false, Reference: false, Price: true,
					PricewoVAT: true, VAT: true, Units: true, Promotion: false, Discount: false}],
				ChooseChange: [{Units: 0, Reason: null, UnitsBiggerThanExpected: false}],
				Shipping: true,
				ClientEmail: true}});
		this.setState({HighLevelErrors: {
				Ship_ping: false,
				Client_Email: false,
				OldSaleID: false,
				Sales: {Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false},
				ChooseChange: {Units: false, Reason: false, UnitsBiggerThanExpected: false},
				Non_existing_ID: false, Empty_ID: false
			}});

		this.setState({NoOfRows: 1});
		this.setState({ShippingNewSale: 0});
		this.setState({Employee_ID: null});
		this.setState({Shop_ID: null});
		this.setState({EmployeeChosen: 'Choose an employee'});
		this.setState({ShopChosen: 'Choose a shop'});
		this.setState({PopUpMoreInfo: null});
		this.setState({NoOfRows_Sale: 1});

		this.setState({ChangeContent: [{
				No: 1, Product: null, Brand: null, Type: null, Subtype: null, Reference: null, Price: null,
				Price_without_VAT: null, VAT: null, Units: null, Promotion: null, Discount: null, UnitsChange: null, Reason: null
			}]});
		this.setState({ChooseChangesContent: [{Choose_change_index: 1, Units: 0, Reason: null}]});
		this.setState({ChooseChangesFixed: [{Choose_change_index: 1, Units: 0, Reason: null}]});

		this.setState({Disabling: {Sales: [{Product: false, Brand: false, Type: false, Subtype: false, Reference: false, Price: false,
					PricewoVAT: false, VAT: false, Units: false, Promotion: false, Discount: false}]}});
		this.setState({PressedEndButton: false});
		this.setState({DropDownAttribute: null});

		return null;
	}


	buttonScanAndPaySubmit_ChangeOperation = () => {
		
		// Display the new background pop-up - only waiting for the QR code.
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.Sale});

		// Uncomment the following lines when the response is required for some check.
		let ExecuteSale = () => {
			return Create_json_return(this.state.New_ID_Provider_Operation, this.state.ChangeContent, this.state.Client,
				this.state.ShippingNewSale, this.state.Employee_ID, this.state.Shop_ID, this.state.ScreenDatetime,
				this.state.Profile.Company.Currency)
		}

		// Render the loading icon
		this.setState({WaitingForRequest: true});

		let execute_Sale = ExecuteSale()

		execute_Sale.then((response) => {
				console.log(response);
				if (response.contenido.Returns_output.Status === "OK") {
					this.setState({SVG: response.contenido.Returns_output.QR})
					this.setState({SVG_PNG: response.contenido.Returns_output.QR_PNG})
					// this.SalesQRCodeWindow();

					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// Render the QR's obscurer when the QR code is received.
					this.setState({ObscurerQRReceived: true});

					// Store in state the Operation_ID
					this.setState({Operation_ID: response.contenido.Returns_output.Operation_ID})

					return null;
				} else {
					console.log('Status NOK');

					this.setState({ErrorMessage: 'SaleCouldNotBeExecuted'})

					//Remove the loading icon
					this.setState({WaitingForRequest: false});
					this.setState({ObscurerQRReceived: true});

					return null;
				}
			}
		)

		this.actionsAfterClosingNewSuboperationPopup();

		return null;
	}
	
	
	
	
	
	renderSummaryTableHeader = () => {
	   		   
		  var first_line = this.state.OrderSummaryHeaders[0];
		  
		  if (first_line === undefined) {
			  return null;
		  } else {
		   
			  let header = Object.keys(first_line)
			  //console.log(header);
			  return header.map((key, index_2) => {
					return <th key={index_2}>{key}</th>
			  })
		  }
   }
   




	NewReturnClientDataEmail = () => {
		return (
			<div className= 'NewReturnClientDataEmail'>
				{this.NewReturnClientDataMessageEmail()}
				{this.NewReturnClientDataFormEmail()}
			</div>
		)
	}


	NewReturnClientDataMessageEmail = () => {

		return (
			<span className= 'NewReturnClientDataMessageEmail'>
				{'E-mail address'}
			</span>
		)
	}

	NewReturnClientDataFormEmail = () => {
		//console.log(this.state.Client);
		if (this.state.PopUpMoreInfo !== null) {

			if (isUserACompany()) {

				const Suboperation_data = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
				console.log(Suboperation_data);
				return (
					<div className="NewChangeClientDataFormEmailPrevious" title={Suboperation_data.Client}> {Suboperation_data.Client}</div>
				)
			} else {
				return (
					<div className="NewChangeClientDataFormEmailPrevious" title={this.state.Profile.Person.Email}> {this.state.Profile.Person.Email}</div>
				)
			}

		} else {

			// The input field in case there's no error
			let classN = 'NewSaleClientDataFormEmail';
			// Check if the input field must be red (due to an error in the input introduced).

			if (this.state.PressedEndButton === true && this.state.OperationsErrors.ClientEmail === true ) {
				classN = 'NewSaleClientDataFormEmail2';
			}

			return (
				<Form.Control
					// className="NewChangeClientDataFormEmail"
					className = {classN}
					autoFocus
					type="email"
					value={this.state.Client.Email}
					disabled={this.state.IDInputIsDisabled === false}
					onChange={(e) => this.setEmail(e.target.value)}
				/>
			)
		}
	}
	
	setEmail(event) {
		//console.log(this.state.Client.Email);
		// Sets the e-mail address of the client to the State
		this.evaluateInputInSales('ClientEmail', null, null, event);

		this.setState(
			  Object.assign(this.state.Client, {Email: event })
		);
		return null;		
	}

	evaluateInputInSales = (field, index, table, input) => {

		if (field === 'Shipping') {
			// console.log(input);

			if (validate_number(input)) {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: false }), () => this.validateChangeRequestInput()
				);

				// Object.assign(this.state.OperationsErrors, {[field]: false});
			} else {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: true }), () => this.validateChangeRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: true});
			}
		}

		if (field === 'ClientEmail') {
			if (email_validation(input)) {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: false }), () => this.validateChangeRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: false});
			} else {
				this.setState(
					Object.assign(this.state.OperationsErrors, {[field]: true }), () => this.validateChangeRequestInput()
				);
				// Object.assign(this.state.OperationsErrors, {[field]: true});
			}
		}


		// if (table !== null) {
		// 	let table_name = table.valueOf();
		// 	let field_name = field.valueOf();
		//
		// 	if (field_name === 'Product') {
		// 		if (validate_string_is_not_empty_or_null(input)) {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
		// 		} else {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
		// 		}
		// 	}
		// 	if (field_name === 'Price') {
		// 		if (validate_number(input)) {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
		// 			// Also assign to PricewoVAT that it's written, so that the red input field does not come into action
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {'PricewoVAT': false});
		// 		} else {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
		// 		}
		// 	}
		// 	if (field_name === 'PricewoVAT') {
		// 		if (validate_number(input)) {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
		// 			// Also assign to Price that it's written, so that the red input field does not come into action
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {'Price': false});
		// 		} else {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
		// 		}
		// 	}
		// 	if (field_name === 'VAT') {
		// 		if (validate_number(input)) {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
		// 		} else {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
		// 		}
		// 	}
		// 	if (field_name === 'Units') {
		// 		if (validate_integers(input)) {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: false});
		// 		} else {
		// 			Object.assign(this.state.OperationsErrors[table_name][index], {[field_name]: true});
		// 		}
		// 	}
		//
		//
		// }

		// this.state.ErrorMessage;
		// console.log(this.state.OperationsErrors);
		return null;
	}


	setShipping(event) {

		this.evaluateInputInSales('Shipping', null, null, event);
		console.log(event);
		console.log(this.state.ShippingNewSale);

		this.setState({ShippingNewSale: event});
		return null;
	}

	NewReturnMessage = () => {

		if (this.state.PopUpMoreInfo !== null) {
			return (
				<div className='NewReturnMessage'>
			<span>
				{'Previous Return'}
			</span>
				</div>
			)
		} else {

			return (
				<div className='NewReturnMessage'>
			<span>
				{'New Return'}
			</span>
				</div>
			)
		}
	}
   
   NewReturnEmployee() {
		// TODO: Get the Employees names
		return (
			<div className= 'NewReturnEmployee'>
				<span className= 'NewReturnEmployeeEmployee'>
					{'Employee'}
				</span>
			</div>
		)
	}
	
	NewReturnDate = () => {
		if (this.state.PopUpMoreInfo !== null) {
			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);

			return (
				<div className='NewChangeDate'>
					<span> {Relevant_Operation.Info.Returns_info.Return_data[0].Product_data.Screen_datetime} </span>
				</div>
			)

		} else {
			return (
				<div className='NewChangeDate'>
			<span className='NewChangeDateDate'>
				{this.state.ScreenDatetime}
			</span>
				</div>
			)
		}
	}


	renderDropDownEmployees = () => {
		var array1 = [];
		var array_id = [];

		const Employees = this.state.Profile.Employees;

		//console.log(Employees);

		//Prepare the required fields to be displayed
		Employees.forEach(Employee => {
			let employee_name = Employee.Name;
			if (employee_name === null) { employee_name = ''}
			let employee_surname = ' ' + Employee.Surname;
			if (Employee.Surname === null) { employee_surname = ''}

			var Employee_identity = employee_name + employee_surname;
			var Employee_ID = Employee.ID
			if (Employee_identity !== 'null null') {
				array1.push(Employee_identity);
				array_id.push(Employee_ID);
			}
		})

		//Get the set of elements
		var set_Employees = array1.filter((item, i, ar) => ar.indexOf(item) === i);
		var set_Employee_IDs = array_id.filter((item, i, ar) => ar.indexOf(item) === i);

		var array_joined = [];

		//Join the names and the IDs
		for (let i = 0; i < set_Employee_IDs.length; i++) {
			array_joined.push({Employee_name: set_Employees[i], Employee_ID: set_Employee_IDs[i]});
		}
		//console.log(array_joined);

		return (array_joined);
	}
	
	
	clickOnEmployeeNameDropdown = (event) => {		
		const employee_id = event.currentTarget.getAttribute("employeeID");
		const employee_name = event.currentTarget.getAttribute("employeeName");
		
		//console.log(this.state.Employee_ID);
		this.setState({Employee_ID: employee_id});
		this.setState({EmployeeChosen: employee_name});
		
		return null;
	}

	renderDropDownShops = () => {
		var array1 = [];
		var array_id = [];

		const Shops = this.state.Profile.Shops;
		// console.log(Shops);

		//Prepare the required fields to be displayed
		Shops.forEach(Shop => {
			var Shop_identity = Shop.Name;
			var Shop_ID = Shop.ID;
			array1.push(Shop_identity);
			array_id.push(Shop_ID);
		})

		//Get the set of elements
		var set_Shops = array1.filter((item, i, ar) => ar.indexOf(item) === i);
		var set_Shops_IDs = array_id.filter((item, i, ar) => ar.indexOf(item) === i);

		var array_joined = [];

		//Join the names and the IDs
		for (let i = 0; i < set_Shops_IDs.length; i++) {
			array_joined.push({Shop_name: set_Shops[i], Shop_ID: set_Shops_IDs[i]});
		}

		// Return only the names of the Shops
		// console.log(array1);

		return(array_joined);
	}
	
	
	clickOnShopNameDropdown = (event) => {		
		const shop_ID = event.currentTarget.getAttribute("shopID");
		const Shop_name = event.currentTarget.getAttribute("shopName");
		
		//console.log(this.state.Shop_ID);
		this.setState({Shop_ID: shop_ID});
		this.setState({ShopChosen: Shop_name})
		
		return null;
	}

	NewChangeEmployee = () => {

		if (this.state.PopUpMoreInfo !== null) {
			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);
			return (
				// <div className='NewChangeEmployee'>
				// 	<span> {'Employee: ' + Relevant_Operation.Employee} </span>
				// </div>
				<div className='NewChangeEmployee'>
					<span className='NewChangeEmployeeEmployee'>
						{'Employee'}
					</span>
					<span className='NewChangeEmployeeNo' title={Relevant_Operation.Employee}>
						{Relevant_Operation.Employee}
					</span>
				</div>
			)

		} else {
			return (
				<div className='NewChangeEmployee'>
				<span className='NewChangeEmployeeEmployee'>
					{'Employee'}
				</span>
					<div className='verticalSeparatorFirstBoxesChangeScreen'> </div>
					<Button className='NewChangeEmployeeButton' block size="lg"
							onClick={(e) =>
								this.activateDropDown('Employee')}
					> <div className='renderMyCompanyRightInputDropdownText' >{this.state.EmployeeChosen}</div>
						<img className='dropDownInMyProfileNewChange' alt=""/>
					</Button>
					{this.DropDownAttribute('Employee', null, null, null )}
					<div className='horizontalSeparatorFirstBoxesChanges'> </div>
				</div>
			)
		}
	}

	DropDownAttribute = (attribute, profile, table, row_index) => {
		// console.log(this.state.DropDownAttribute);
		// console.log(row_index);

		var Attributes = [];
		var field = null;

		if (attribute === 'OperationChosen' && this.state.DropDownAttribute === 'OperationChosenByUser') {
			Attributes = file.OperationsChosen;
			field = 'Operation';
		}
		if (attribute === 'Shop' && this.state.DropDownAttribute === 'Shop') {
			Attributes = this.renderDropDownShops();
			if (Attributes.length === 0) {Attributes = [{'Shop_name': 'Provide your shops data on My account tab.', 'Shop_ID': null}]}
			field = 'Shop';
		}
		if (attribute === 'Employee' && this.state.DropDownAttribute === 'Employee') {
			Attributes = this.renderDropDownEmployees();
			console.log(Attributes);
			if (Attributes.length === 0) {Attributes = [{'Employee_name': 'Provide your employees data on My account tab.', 'Employee_ID': null}]}
			field = 'Employee';

		}


		var array2 = [];

		Attributes.forEach(Attribute => {
			// console.log(Attribute);
			let attribute_to_show = null;
			if (attribute === 'Shop' && this.state.DropDownAttribute === 'Shop') {
				attribute_to_show = Attribute.Shop_name
				console.log(attribute_to_show)
			}
			if (attribute === 'Employee' && this.state.DropDownAttribute === 'Employee') {
				attribute_to_show = Attribute.Employee_name
				console.log(attribute_to_show)
			}

			array2.push(
				<div className=''>
					<Dropdown.Item eventKey="1" className='dropDownOperations' Value={Attribute} Shop_name={Attribute.Shop_name}
								   Shop_ID={Attribute.Shop_ID} Employee_name={Attribute.Employee_name} Employee_ID={Attribute.Employee_ID}
								   disabled={this.mustDropdownBeDisabled(attribute)}
								   onClick={(e) => this.clickOnOperationTypeDropdown(e, field)}> {attribute_to_show}
					</Dropdown.Item>
				</div>
			)
		})

		if (field === 'Shop') {
			return (<div className='containerDropDownShopsChanges'>{array2} </div>);
		}
		if (field === 'Employee') {
			return (<div className='containerDropDownShopsChanges'>{array2} </div>);
		}

		else {
			return null;
		}
	}


	mustDropdownBeDisabled = (field) => {
		console.log(this.renderDropDownEmployees().length);

		if (field === 'Shop') {return this.renderDropDownShops().length === 0;}
		if (field === 'Employee') {return this.renderDropDownEmployees().length === 0;}

		return false;
	}


	clickOnOperationTypeDropdown = (event, field) => {
		// console.log(event.currentTarget);
		// console.log('hola');

		const value = event.currentTarget.getAttribute("Value");

		if (field === 'Shop') {
			const shop_id = event.currentTarget.getAttribute("Shop_ID");
			const shop_name = event.currentTarget.getAttribute("Shop_name");
			this.setState({ShopChosen: shop_name});
			this.setState({Shop_ID: shop_id});
		}
		if (field === 'Employee') {
			const employee_id = event.currentTarget.getAttribute("Employee_ID");
			const employee_name = event.currentTarget.getAttribute("Employee_name");
			this.setState({EmployeeChosen: employee_name});
			this.setState({Employee_ID: employee_id});
		}

		this.setState({DropDownAttribute: null});

		//console.log(operation_type_code);

		//console.log(this.state.Employee_ID);

		return null;
	}

	activateDropDown = (field) => {

		if (field === 'Employee') {
			if (this.state.DropDownAttribute === 'Employee') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'Employee'});
			}
		}
		if (field === 'Shop') {
			if (this.state.DropDownAttribute === 'Shop') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'Shop'});
			}
		}
		if (field === 'OperationType') {
			if (this.state.DropDownAttribute === 'OperationType') {
				this.setState({DropDownAttribute: null});
			} else {
				this.setState({DropDownAttribute: 'OperationType'});
			}
		}
		// console.log(this.state.DropDownAttribute);
		//
		// this.resetDropDownAttributeInState();

		return null;
	}


	NewChangeShop = () => {
		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);

			return (
				<div className='NewChangeShop'>
					<span className='NewChangeShopShop'>
						{'Shop'}
					</span>
					<span className='NewChangeEmployeeName' title={Relevant_Operation.Shop}>
						{Relevant_Operation.Shop}
					</span>
				</div>
			)
		} else {

			return (
				<div className='NewChangeShop'>
					<span className='NewChangeShopShop'>
						{'Shop'}
					</span>

					<div className='verticalSeparatorFirstBoxesChangeScreen'> </div>

					<Button className='NewChangeEmployeeButton' block size="lg"
							onClick={(e) =>
								this.activateDropDown('Shop')}
					> <div className='renderMyCompanyRightInputDropdownText' >{this.state.ShopChosen}</div>
						<img className='dropDownInMyProfileNewChange' alt=""/>
					</Button>
					{this.DropDownAttribute('Shop', null, null, null )}

				</div>
			)
		}
	}


	WrapperAllTables = () => {

		return(
			<div className={'WrapperAllTables'}>
				{this.OldSaleDate()}
				{this.ChooseChangesTable()}

				<table className='ReturnTable'>
					<thead className='ChangeTableHeader'>
					<tr>{this.renderChangeTableHeader()}</tr>
					</thead>
					<tbody className='ChangeTableBody'>
					{this.decisionMakingHowManyRowsForChange()}
					</tbody>
				</table>

				{this.NewChangeDate()}
				{this.NewSaleShippingInChange()}
			</div>
		)
	}


	NewSaleShippingInChange = () => {
		//Need for showing Shipping in previous Sale
		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			console.log(Relevant_Operation);

			let ccy = null;
			if (isUserACompany()) {
				ccy =this.state.Profile.Company.Currency;
			} else {
				ccy = Relevant_Operation.Info.Returns_info.Return_data[0].Product_data.Currency;
			}

			// Eliminate the currency header in case it's missing
			let shipping_name = 'Shipping (' + ccy +')   ';
			console.log(ccy);
			if (ccy === null) {
				shipping_name = 'Shipping';
			}

			return (
				<div className='NewChangeShippingPrevious'>
					<div className='NewSaleShippingNamePrevious'>{shipping_name} </div>
					<div className='NewSaleShippingValuePrevious' title={Relevant_Operation.Shipping}> {Relevant_Operation.Shipping} </div>
				</div>
			)
		} else {

			let classN = 'NewSaleShippingValue';
			// console.log(this.state.OperationsErrors)
			// Check if the input field must be red (due to an error in the input introduced).
			if (this.state.PressedEndButton === true && this.state.OperationsErrors.Shipping === true ) {
				classN = 'NewSaleShippingValue2';
			}

			let ccy =this.state.Profile.Company.Currency;
			// Eliminate the currency header in case it's missing
			let shipping_name = 'Shipping (' + ccy +')   ';
			// console.log(ccy);
			if (ccy === null) {
				shipping_name = 'Shipping';
			}


			return (

				<div className='NewSaleShippingInChange'>
					<label className="NewSaleShippingInChangeName">{shipping_name}</label>
					<input
						className={classN}
						autoFocus
						type="numeric"
						disabled={this.state.IDInputIsDisabled === false}
						onChange={(e) => this.setShipping(e.target.value)}
					/>
				</div>
			)
		}
	}

	takeShipping = () => {
		// Returns the shipping value
		let rounded = 0

		if (this.state.PopUpMoreInfo !== null) {
			let Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			let shipping = Relevant_Operation.Shipping;
			rounded = Math.round((+shipping + Number.EPSILON) * 100) / 100;
		} else {
			rounded = Math.round((+this.state.ShippingNewSale + Number.EPSILON) * 100) / 100;
		}
		return rounded.toFixed(2);
	}





	OldSaleDate = () => {
		// // TODO:  Get the current datetime

		return null;
	}

	NewChangeDate = () => {

		if (this.state.PopUpMoreInfo !== null) {

			var Relevant_Operation = this.getSuboperationFromOperation_ID(this.state.PopUpMoreInfo);
			// console.log(Relevant_Operation);
			return (
				<div className='NewChangeDatePrevious'>
					<span> {Relevant_Operation.Info.Returns_info.Return_data[0].Product_data.Screen_datetime} </span>
				</div>
			)

		} else {
			return (
				<div className='NewChangeDate'>
				<span className='NewChangeDateDate'>
					{this.state.ScreenDatetime}
				</span>
				</div>
			)
		}
	}


	SalesQRCodeWindow = () => {

		if (this.state.showSalesQRCode === this.state.showSalesQRCodeCode.Sale) {

			let error_message_resendQR = null;
			console.log(this.state.ErrorMessage)
			if (this.state.ErrorMessage === 'SaleCouldNotBeExecuted') {
				error_message_resendQR = <div className='errorSendingOperationQR'> An error occurred. Please, contact support </div>
			}

			return (
				<div className='ChangesQRCodeWindow'>
					<div className='SalesQRCodeWindowText'> Your client may scan the following QR to receive his/her
						TIDI on the phone </div>
					{error_message_resendQR}
					{this.render_svg(this.state.SVG_PNG)}
					<div className='SalesQRCodeWindowText2'> Or you may send the TIDI to his/her email address </div>
					{this.messageWrongEmailWhenResendingQRCode()}
					<div className='resendEmail'>
						<input className={'EmailAddresInputAfterOperationCompletion'} type='email'
							   value={this.state.ClientConfirmsSendingByEmailEmail}
							   onChange={(e) => this.validateEmailAddressUpdateClient2Operation(e.target.value)}/>
					</div>
					{this.buttonCloseQRMessageAfterOperationCompleted()}
				</div>
			)
		} else {return null;}
	}



	buttonCloseQRMessageAfterOperationCompleted = () => {

		let client_email_to_send_qr = this.state.ClientConfirmsSendingByEmailEmail;
		let title = 'User has already scanned the QR';
		if (client_email_to_send_qr !== null && client_email_to_send_qr !== '') {
			title = 'Send QR by email & close'
		}
		return (
			<div className='buttonCloseQRCodeSale'>
				<Button className="buttonCloseQRCodeButton" block size="lg" onClick={this.closeQRMessageAfterOperationCompletedButtonAction}>
					{title}
				</Button>
			</div>
		)
	}


	closeQRMessageAfterOperationCompletedButtonAction = () => {

		this.setState({sendButtonPressed: true});
		// console.log(this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email)

		if (this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === false || this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === null
			|| this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === undefined) {
			// The email address introduced fulfils the format required
			if (this.state.ClientConfirmsSendingByEmailEmail !== '' && this.state.DoesClientWantTheEmail === true) {
				// Perform the web service to update the client's email address in the Operations row
				this.updateClientIDInOperationsRow();
			} else {
				// User has scanned the TIDI (or not received it in any way) --> Refresh the page.
				// User has scanned the TIDI (or not received it in any way) --> Refresh the page.
				let returnToLandingPage = this.props.data[1];
				returnToLandingPage();

				// Update the historical table
				let updateHistoricalTable = this.props.data[2];
				updateHistoricalTable();
				this.closeNewSuboperationPopUpAfterQRIsSent();
			}

		}

		return null;
	}


	updateClientIDInOperationsRow = () => {

		this.setState({WaitingForRequest: true});
		let SendingQRByEmail = () => {
			return updateClientInOperation(this.state.Operation_ID, +this.state.New_ID_Provider_Operation, this.state.ClientConfirmsSendingByEmailEmail, true);
		}

		let sendingQRByEmail = SendingQRByEmail()

		sendingQRByEmail.then((response) => {
				console.log(response);
				if (response.contenido.Update_client_in_operation_output.Status === "OK") {


					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// // Close this suboperation popup
					this.closeNewSuboperationPopUpAfterQRIsSent();

					this.setState({ClientConfirmsSendingByEmailEmail: ''})

					// Reset the push onto the button
					this.setState({sendButtonPressed: null});

					let returnToLandingPage = this.props.data[1];
					returnToLandingPage();

					// Update the historical table
					let updateHistoricalTable = this.props.data[2];
					updateHistoricalTable();

					// this.buttonCloseQRCodeSendToNewAddress();
				} else {
					// TODO Martín - terminar tratamiento de errores
					// let error_code = response.contenido.Resend_QR_data_output.Error_code;
					// if (error_code === 'Resend_QR_1' || error_code === 'Resend_QR_2' || error_code === 'Resend_QR_3') {
					// 	this.setState({ErrorMessage: 'EmailCouldNotBeSent'})
					// }

					console.log('Status NOK');


					//Remove the loading icon
					this.setState({WaitingForRequest: false});

					// // Close this suboperation popup
					this.closeNewSuboperationPopUpAfterQRIsSent();
				}

				return null;
			}
		)

		return null;

	}


	closeNewSuboperationPopUpAfterQRIsSent = () => {

		// console.log(this.state.TerciaryStatus)
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.None});

		// Reset the email address to send the email to
		this.setState({ClientConfirmsSendingByEmailEmail: ''})

		// Reset the push onto the button
		this.setState({sendButtonPressed: null});

		// this.setState({ObscurerQRReceived: false});

		this.setState({ObscurerQRReceived: false},
			()=> {this.setState({SecondaryStatus: this.state.SecondaryCode.None},
				() => { this.setState({PrimaryStatus: this.state.PrimaryCode.Load})})
			});

		return null;
	}


	validateEmailAddressUpdateClient2Operation = (value) => {

		this.setState({DoesClientWantTheEmail: true});
		this.setState({ClientConfirmsSendingByEmailEmail: value})
		let validation_of_clientemail = email_validation_or_empty(value);
		console.log(validation_of_clientemail)
		this.setState(
			Object.assign(this.state.HighLevelErrors, {ClientConfirmsSendingByEmail_Email: !validation_of_clientemail })
		);
		return null;
	}


	messageWrongEmailWhenResendingQRCode = () => {
		console.log(this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email)
		console.log(this.state.sendButtonPressed)

		if ((this.state.HighLevelErrors.ClientConfirmsSendingByEmail_Email === true) && this.state.sendButtonPressed === true) {
			return <div className='messageWrongEmailWhenSendingQRCodeAtEndOfOperation'> Invalid e-mail address</div>
		} else {
			return null;
		}
	}

	render_svg = (SVG_file) => {

		if (SVG_file !== null) {

			let png_src = "data:image/png;base64," + SVG_file;

			return (
				document.getElementsByName('SalesQRCodeWindow').innerHTML =
					<img className='QRImage' src={png_src} alt=''/>
			)
		} else {
			return <div className='QRImage'/>;
		}
	}

	buttonCloseQRCode = () => {
		return (
			<div className='buttonCloseQRCode'>
				<Button className="buttonCloseQRCodeButton" block size="lg" onClick={this.buttonCloseQRCodeSubmit}>
					{'Close'}
				</Button>
			</div>
		)
	}

	buttonCloseQRCodeSubmit = () => {

		this.setState({sendButtonPressed: null});
		this.setState({showSalesQRCode: this.state.showSalesQRCodeCode.None});

		this.closeNewSuboperationPopUp();

		// // Update the TIDI number
		// this.setState({New_ID_Provider_Operation: +this.state.New_ID_Provider_Operation+1});

		// Update the historical table - hence repeat all requests to Back-end
		this.setState({PrimaryStatus: this.state.PrimaryCode.Load});

		// Update the historical table
		let updateHistoricalTable = this.props.data[2];
		updateHistoricalTable();

		return null;
	}


	WaitingForRequest = () => {

		if (this.state.WaitingForRequest === true) {
			return (
				<div className='loading'>
					<img className="loading-image" src={loadingPictureCode} />
				</div>
			)
		} else {return null;}
	}


	ObscurerQRReceived = () => {
		// console.log('obscurer nuevo renderizado');

		if (this.state.ObscurerQRReceived === true) {
			return (
				<div className='ObscurerQRReceivedChange'>
					{' '}
				</div>
			)
		} else {return null;}
	}



	wrapperRenderChangeTable = () => {

		let div = null;

		if (this.state.PopUpMoreInfo !== null) {
			div = <div className='NewChangeUpperDataContainerNoBorder'>
				{this.NewChangeID()}
				{this.NewChangeEmployee()}
				{this.NewChangeShop()}
			</div>;

		} else {
			div = <div className='NewChangeUpperDataContainer'>
				{this.NewChangeID()}
				{this.NewChangeEmployee()}
				{this.NewChangeShop()}
			</div>;
		}

		if ( this.state.showSalesQRCode === this.state.showSalesQRCodeCode.Sale ) {
			return [
				this.WaitingForRequest(),
				<div className='containerOfChange'>
					{this.ObscurerQRReceived()}
					{this.CloseButton()}
					{this.NewReturnMessage()}
					{div}
					{this.WrapperAllTables()}
					{this.IDInformationExplanation()}

					{this.NewReturnTicket()}
					{this.SalesQRCodeWindow()}
				</div>
			]
		} else {

			return [
				this.WaitingForRequest(),
				<div className='containerOfChange'>
					{this.ObscurerQRReceived()}
					{this.CloseButton()}
					{this.NewReturnMessage()}
					{div}
					{this.WrapperAllTables()}
					{this.ErrorMessagesInChangeWindow()}
					{this.ErrorMessageInIncorrectID()}
					{this.IDInformationExplanation()}

					{this.NewReturnTicket()}
				</div>
			]

		}
	}
	

	render() {
		return(
			<this.wrapperRenderChangeTable/>
		)
	}
}
