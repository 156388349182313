import React from "react";

function generalTermsAndConditionsTextProvider(buttonLicenses, buttonCookiesPolicy) {
    return(
        <span>
<br/><b>1. COMPANY DETAILS</b><br/><br/>
    Proprietor: The TIDI Systems GmbH (hereinafter "<b>TIDI</b>").<br/>
    Registered office: Frohbühlstrasse 3, 8052 Zürich, Switzerland.<br/>
    TFN: CHE-389.068.509<br/>
    Public Register: Commercial Register of Zurich, registration number CHE-1005265706.<br/>
    Phone: +41 79 109 19 23.<br/>
    E-mail: hola@tidisystems.com<br/><br/>

    <br/><b>2. OBJECT</b><br/><br/>
    The Terms and Conditions for Providers (hereinafter the <b>“Provider Terms and Conditions”</b>) governing access to and use of the <b>“TIDI”</b> platform (hereinafter the <b>“Plataform”</b>),
        including the content and services made available to employees and providers (hereinafter the <b>“Provider”</b> and, where applicable, the <b>“Providers”</b>) through the Platform are hereby established. However, access to and use of certain content and/or services may be subject to certain specific conditions.<br/><br/>
    Any matter not expressly provided for in these Terms and Conditions for Suppliers shall be understood to be reserved to TIDI, without prejudice to the application of the provisions of the regulations in force.<br/><br/>
    Furthermore, TIDI reserves the right to make modifications and/or updates to these Terms and Conditions for Providers, of which Providers will be informed in advance for their acceptance or rejection in the event that they prove to be substantial. In any event, you will be deemed to have expressly accepted such modifications or updates if you continue to use and make use of the Platform.<br/><br/>
    By accessing and using the Platform, you expressly and unreservedly accept the terms of these Supplier Terms and Conditions, which are as valid and effective as any written and signed contract, so if you do not agree to these Supplier Terms and Conditions, we suggest that you do not use the Platform.<br/><br/>

    <br/><b>3. WHAT IS TIDI? WHAT SERVICES DOES IT OFFER? </b><br/><br/>
    TIDI is a technology company focused on providing a mixed Customer Relationship Management (CRM) platform, combining customer and supplier relationships through Artificial Intelligence (AI) solutions.<br/><br/>
    The platform provides a ticket manager that collects customer and supplier data, humanising the relationship between the two. In addition, when combined with advanced AI analytical models, it provides a detailed analysis of the market, facilitating optimisation and increasing business opportunities.<br/><br/>
    Among others, TIDI offers:
            <ol className='ulItem' type="1">
                <li>Preparation of invoicing documentation for the business.</li>
                <li>Inventory prediction to suggest new orders. Possibility of purchasing stock through the application.</li>
                <li>Referral system for new customers.</li>
                <li>Product recommendation system for customers in the shop itself and in real time.</li>
                <li>Customer loyalty through analytical models based on their previous purchases.</li>
                <li>Improved business management through prior requests for product returns or exchanges via the application.</li>
                <li>Payment and management of sales, exchanges and returns.</li>
                <li>Division of payment between suppliers and customers.</li>
                <li>Cost reduction in the use of ticket paper.</li>
            </ol>


    <br/><b>4. PROFILES AND ROLES</b><br/><br/>
    For the use of the Platform, TIDI may determine roles and profiles for the different users. In this regard, the roles and profiles that may be assigned, together with the corresponding permissions, are as follows: <br/><br/>
    <br/><b>4.1. Suppliers</b>
    <ol className='ulItem' type="1">
                <li>Company</li>
                <li>Employee</li>
                <li>Shop</li>
            </ol>
    <br/><b>4.2. Clients</b><br/><br/>


    <br/><b>5. REGISTRATION AND USE OF THE SUPPLIER PLATFORM</b><br/><br/>
    <br/><b>5.1. Registration</b><br/><br/>
    Access, registration and use of the Platform are completely free of charge. <br/><br/>
    The Supplier will be able to register immediately after accessing the Platform, under the option "Are you new? Register for free here! Currently, registration on the Platform is allowed by entering the following data: <br/>
    <ul className='ulItem'>
        <b>1) Company</b>
        <ol className='ulItem2' type="a">
            <li>Name</li>
            <li>CIF/TFN</li>
            <li>E-mail</li>
            <li>Password</li>
            <li>Address</li>
            <li>Sectoral classification</li>
            <li>Currency used</li>
            <li>Telephone number </li>
    </ol></ul>

            <ul className='ulItem'>
        <b>2) Employees</b>
        <ol className='ulItem2' type="a">
            <li>Treatment (Mr, Mrs,...)</li>
            <li>Name and surname </li>
            <li>Role (manager, salesperson,...)</li>
            <li>Corporate e-mail </li>
    </ol></ul>

            <ul className='ulItem'>
        <b>3) Shops</b>
        <ol className='ulItem2' type="a">
            <li>Nickname</li>
                <li>Address</li>
                <li>Telephone number</li>
    </ol></ul>

    <br/><b>5.2. Verification of the profile </b><br/><br/>
    In order to verify the Provider's profile, the user will be sent an e-mail to the address provided at the time of registration, which will include a verification code that the user must enter on the Platform.<br/><br/>
    If you do not receive the confirmation e-mail, you should check your spam mailbox. In the event that you have not received this e-mail in any of your mailboxes, the Supplier must contact TIDI to resolve this incident through the channels provided for this purpose or by e-mail: hola@tidisystems.com.  Likewise, the user may request the e-mail to confirm their account from the registration section of the Platform. <br/><br/>

    <br/><b>5.3. Deregistration </b><br/><br/>
    The Supplier may request cancellation of the services provided by TIDI and delete their account from the application/website itself, using the option provided for this purpose.<br/><br/>
    In addition, and in the event that the aforementioned function should present any incident, the user may request cancellation of the services provided by TIDI by means of a written communication to hola@tidisystems.com. TIDI reserves the right to request further information from users or the Provider in order to demonstrate their link to the required profile and to prevent third parties not involved in the profiles from requesting the deletion of the accounts of providers and clients.<br/><br/>
    In any case, the Supplier may request a new registration and TIDI may refuse to accept this registration in cases where the Supplier has contravened the provisions of these Terms and Conditions for Suppliers or has acted contrary to the law, morality or public order. Likewise, TIDI may refuse to accept the said registration in the event of a dispute or controversy between the parties, which has not yet been resolved or which has ended with acknowledgement of fraud or fault on the part of the Supplier and/or to the detriment of TIDI, other users, suppliers or customers. <br/><br/>

    <br/><b>5.4. Authentication</b><br/><br/>
    Once registered, the Provider's user can access the platform by entering their email (ID) and password. The authentication method encrypts the ID and password of each user and has a duration of 15 minutes. This means that, after 15 minutes, a new ID is generated for the user. This process allows us to protect data and prevent fraud.<br/><br/>
    The user is also offered the possibility to save his or her start-up data in the "Remember me" option. <br/><br/>

    <br/><b>5.5. Password recovery</b><br/><br/>
    In the event of forgetting or losing their password, the Provider's user may recover their password and enter a new one by providing their email address on the login page, in the option "Forgot your password?" TIDI will send an email to the user with a code, which must be entered by the user in the application. <br/><br/>

    <br/><b>5.6. Password change</b><br/><br/>
    For security reasons, the Supplier must change its password and choose a new one. This process will require the current password to be entered. <br/><br/>

    <br/><b>5.7. My account</b><br/><br/>
    The Supplier can view and edit the data entered in the registration in the "My account" section. Here you can change the data about your company, your employees and your shops. You can also change your email address, password, telephone number and currency under "Options".<br/><br/>
    The Supplier guarantees the veracity of the data provided in this field and, more specifically, those referring to the physical location of the shops, the details of the employees and their corporate addresses. In this regard, the Supplier acknowledges that TIDI is exempt from liability for any breach related to this clause and will hold TIDI harmless against possible claims from third parties as a result of such a breach.<br/><br/>

    <br/><b>5.8. My history</b><br/><br/>
    Once logged in, the Supplier can access his "My History" page. It is in this section where the payments and purchases made are stored. Each one of them is registered in the Platform with the corresponding data.<br/><br/>
    Each of them is recorded including the following data:
    <ol className='ulItem' type="1">
        <li>ID</li>
        <li>Classification for sale, exchange or return</li>
        <li>Acquired object</li>
        <li>Supplier</li>
        <li>Employee</li>
        <li>Price</li>
        <li>Shop</li>
        <li>Date</li>
        <li>QR code</li>
    </ol>
    The Platform also incorporates a search and filter tool, which enables the user to filter and locate the different payments by object, supplier, shop or date.<br/><br/>

    <br/><b>5.9. Sales, exchanges and returns</b><br/><br/>
    The Platform enables the Supplier to enter a sale, exchange or return, as appropriate, in accordance with validation processes for the aforementioned transactions.<br/><br/>

    <br/><b>5.10. Scan</b><br/><br/>
    The "Scan" option enables the user to scan the QR code displayed by the application with their mobile phone. Thus, the user will receive on his mobile phone the ticket or TIDI.<br/><br/>

    <br/><b>6. INTELLECTUAL AND INDUSTRIAL PROPERTY</b><br/><br/>
    TIDI is the owner or, where applicable, has the corresponding licences for the intellectual, industrial, image or any other similar rights over the Platform, as well as all the materials and information that comprise it and the content offered therein, including, but not limited to, texts, documents, databases, photographs, illustrations, logos, trademarks, graphics, designs, trade names, interfaces, services and any other information or content included therein.<br/><br/>
    Under no circumstances shall it be understood that access, browsing and use of the Platform by the user or the use, acquisition and/or contracting of the Platform's services imply a waiver, transmission or total or partial transfer of said rights by TIDI.<br/><br/>
    In this regard, it is forbidden to modify, copy, reproduce, publicly communicate, transform or distribute, by any means and in any form, all or part of the contents included in the Platform or forming part of the same, for public or commercial purposes, without the prior, express and written authorisation of TIDI, its suppliers or, where appropriate, the holder of the corresponding rights.<br/><br/>
    The Provider has only a non-exclusive and revocable licence to use the Platform, its contents and services, including the associated software, strictly limited to its professional activity and in accordance with these Terms and Conditions for Providers, as well as any other documents or policies referenced herein that may be applicable to TIDI's services. The right of use granted to the Supplier on the Platform is limited to the duration of the contract between TIDI and the Supplier and is automatically revoked upon termination of the contract for any reason whatsoever and, in particular, in the event of breach by the Supplier of these Terms and Conditions for Suppliers.<br/><br/>
    Furthermore, the Supplier acknowledges and accepts that, insofar as the information that can be accessed via the Platform constitutes a sui generis protection database, TIDI reserves all rights over the same and, in particular, those of extraction and reuse of substantial parts of its contents, evaluated qualitatively or quantitatively. In this regard, the Provider may only access and use this information in the Platform environment, for the same purposes as described herein and limited to the duration of the contract between TIDI and the Provider, being expressly and automatically revoked at the time of termination of the same for any reason whatsoever.<br/><br/>
    References to trademarks, registered trade names, or any other distinctive signs, whether owned by TIDI, its suppliers or any third parties, imply a prohibition on their use without the consent of TIDI or their legitimate owners. Access to, browsing and use of the Platform does not grant the Provider any rights over such elements, including logos.<br/><br/>
    It is forbidden to remove or manipulate the copyright indications or any other element that identifies the owners of the rights of the Platform, as well as any devices, information or protection mechanisms incorporated therein.<br/><br/>
    With respect to third-party elements included in the Platform, the Platform uses third-party libraries and components that are licensed to TIDI under open source licenses. TIDI holds a licence for these elements, granted by their respective developers, which allows it to use them in connection with the Platform. TIDI does not hold any intellectual and industrial property rights over these elements, beyond the rights granted by the corresponding licences. By accessing and using the Platform, the Provider obtains a right to access and use these components and becomes a legitimate user of them. For more information regarding the Open Source components used and the licences that apply to them, please consult the following link:  {buttonLicenses}. The use of Open Source licences and software is compatible with the rights held by TIDI over the Platform as a distinct entity.<br/><br/>
    In the event that the Supplier publishes or sends information to TIDI via the Platform, the Supplier guarantees that it has the necessary rights to do so and that such information does not infringe the rights of third parties, nor is it confidential or detrimental to the latter. In this regard, the Supplier will retain the rights to the elements protected by intellectual and industrial property that it provides to TIDI in the course of its use of the Platform, including commercial materials, information, brands, images, texts, distinctive signs or any other elements present on the Platform and/or provided to TIDI by decision of the Supplier. For such elements, the Supplier grants TIDI a licence of use to the extent necessary to provide the services requested. The Provider will be responsible for the elements provided, including their accuracy and correctness, and will hold TIDI harmless in the event of any claim arising from their use. <br/><br/>


    <br/><b>7. PRICE AND METHOD OF PAYMENT</b><br/><br/>
    The applicable fare will be the one that appears on the Platform. These may be modified at any time. On the other hand, promotions and other agreements will be applied individually or collectively, so as they are special cases, it is not mandatory to publish them.<br/><br/>
    The price of the Platform's services is indicated next to the description of each one of them, being reflected, unless otherwise indicated, in the official currency of Switzerland, the Swiss Franc (CHF).<br/><br/>
    All prices shown are final prices, expressly including Value Added Tax (VAT). Notwithstanding the foregoing, the final price shall include all applicable increases or discounts, expenses charged to the User and additional expenses for ancillary services, payment methods, commissions, etc. <br/><br/>


    <br/><b>8. E-PROCUREMENT</b><br/><br/>
    In accordance with the provisions of the applicable regulations on information society services and electronic commerce, contracts concluded electronically shall produce all the effects provided for by the legal system, when consent and the other requirements necessary for their validity are met.<br/><br/>
    In any case, the electronic medium containing these terms and conditions of contract concluded electronically shall be admissible as documentary evidence in the event of a dispute between the parties.<br/><br/>
    For these purposes, it shall be understood that the monitoring of all the phases of the contracting process and, where applicable, the payment of the corresponding economic amount, if applicable, shall necessarily imply the provision of the consent required for contracting.<br/><br/>
    Likewise, and in accordance with the provisions of the aforementioned regulations, all information relating to the contracting procedure is made available to Users prior to the start of the contracting procedure, which will only be applicable in the event that the User decides to proceed with the contracting through the Platform. <br/><br/>


    <br/><b>9. TIDI'S RESPONSIBILITIES</b><br/><br/>
    TIDI undertakes to ensure that the content, data or information relating to the Platform and the services offered therein are reliable, truthful and accurate. However, TIDI shall not be liable for any information or content that has been entered, displayed or modified by third parties outside TIDI. Therefore, TIDI is exempt from any liability for damages that may be due to the information and/or services provided or supplied by third parties outside the Platform.<br/><br/>
    TIDI also declares that it has taken all necessary measures, within its capabilities and the state of the art, to ensure the proper functioning of the Platform and to minimise system errors from a technical point of view. <br/><br/>
    However, TIDI shall not be liable in the event of unavailability of the Platform due to circumstances beyond TIDI's control, force majeure or error in the registration process due to inaccurate information. <br/><br/>
    TIDI will use commercially and technically reasonable efforts to keep the services offered through the Platform available, which is an obligation that does not, however, apply to any lack of availability or performance caused by:
        <ol className='ulItem' type="1">
            <li>Temporary downtime of the Platform due to an upgrade and/or technical maintenance, of which we will try to inform you as far in advance as possible; and</li>
            <li>Causes beyond TIDI's control: force majeure, Internet access problems, technological problems beyond the diligent and reasonable management of the Platform owner, actions or omissions of third parties, etc.</li>
        </ol>
    The aforementioned cases, beyond the control and reasonable expectation of TIDI, shall not give rise to compensation to the user for any damages that may arise. <br/><br/>
    Consequently, TIDI does not guarantee and is not liable for:
        <ol className='ulItem' type="1">
            <li>the continuity of the content included in the Platform;</li>
            <li>the absence of errors in these contents;</li>
            <li>the absence of viruses, Trojan horses, worms, logic bombs and/or other malicious or technologically harmful components on the Platform or the server that provides it;</li>
            <li>the invulnerability of the Platform and/or the impossibility of breaching the security measures adopted on the Platform;</li>
            <li>the lack of usefulness or performance of the contents of the Platform;</li>
            <li>failures of the Platform caused by any kind of attack on its servers or those of third party TIDI service providers, as well as technical or security failures of the system of any such providers that prevent the Platform from functioning;</li>
            <li>any technical failure of any kind that hinders, delays or prevents the proper functioning of the Platform;</li>
            <li>any damage or harm caused, to themselves or to a third party, by any person who infringes the conditions, rules and instructions that TIDI establishes on the Website or through the violation of security systems.</li>
        </ol>
    In the event of closure or suspension of the Platform for reasons beyond the control of the parties, the Provider shall be informed of this circumstance, e.g. the transfer of the service to a new domain. <br/><br/>


    <br/><b>10. SUSPENSION OF THE PLATFORM</b><br/><br/>
    TIDI may at any time suspend, modify, restrict or terminate access to the Platform, with or without prior notice to those who contravene these Supplier Terms and Conditions, without the possibility of claiming any compensation. <br/><br/>


    <br/><b>11. RULES OF USE</b><br/><br/>
    It is not permitted to access or use the Platform for illegal or unauthorised purposes, with or without economic purpose, and the Provider shall be solely responsible for any actions that give rise to such purposes. In particular, and without the list being absolute or limitative in nature, it is prohibited:
        <ol className='ulItem' type="1">
            <li>use the Platform in any way that may cause damage, interruptions, inefficiencies or defects in the operation of the Platform or third party devices;</li>
            <li>use the Platform for the transmission, installation or publication of any viruses, Trojan horses, worms, logic bombs, malicious code or other harmful programs or files;</li>
            <li>use the Platform to transmit material for advertising or promotional purposes, including spam, chain letters or similar;</li>
            <li>use the Platform in a way that constitutes an infringement of the rights of TIDI or any third party;</li>
            <li>use the Platform to transmit or post any material of a defamatory, offensive, racist, denigrating, or threatening nature or that is likely to annoy, harm or otherwise injure any person;</li>
            <li>use the Platform illegally, against good faith, morality and/or public order;</li>
            <li>gain unauthorised access to any section of the Platform, other systems or networks connected to the Platform, any TIDI server or the services offered through the Platform by hacking or forgery, password mining or any other illegitimate means;</li>
            <li>take any action that causes disproportionate or unnecessary saturation of the Platform's infrastructure or TIDI's systems or networks, as well as the systems and networks connected to the Platform itself.</li>
        </ol>
    Failure to comply with any of the above obligations on the part of the user may lead to TIDI adopting the appropriate measures in accordance with the law and in the exercise of its rights or obligations, which may include the deletion or blocking of the offending user's account, without the possibility of any compensation for the damages caused.<br/><br/>


    <br/><b>12. SUPPLIER'S LIABILITY</b><br/><br/>
    The Supplier shall at all times respect the Terms and Conditions for Suppliers. In this regard, the Supplier expressly declares that it will use the Platform diligently and assumes any liability that may arise from non-compliance with these Terms and Conditions for Suppliers. Likewise, the use of the Platform for illegal or unauthorised purposes, with or without economic purpose, shall not be permitted. Both the services offered and the information contained in the Platform may not be used for commercial or advertising purposes without the prior and express authorisation of TIDI.
    Furthermore, the Provider shall be responsible for any communication, content, personal data (beyond the provision of the service by TIDI), opinions and suggestions for new functionalities and filters that are provided to TIDI. This responsibility covers the accuracy, legality, originality and ownership of such communications, and TIDI must be held harmless. If the Provider becomes aware of the existence of any content that is illicit, illegal, contrary to the law or that could infringe the rights of third parties, TIDI must be notified immediately so that it can proceed to take the appropriate measures.
    Should the Supplier carry out any action that could be considered illicit, illegal, contrary to the law or that could lead to the infringement or violation of the rights of third parties, it will do so entirely at its own risk, holding TIDI harmless for its actions. <br/><br/>

    <br/><b>12.1. Regulatory Compliance Diligence Measures</b><br/><br/>
    TIDI rejects any breach of the law in the development of its activities. It therefore rejects any kind of irregular practices or actions contrary to the applicable regulations. Likewise, TIDI's commitments extend to all its business partners and/or any relationship with third parties.<br/><br/>
    The Supplier undertakes to act in accordance with TIDI's compliance standards, principles and values, requiring all its employees, directors, managers or other third parties acting on its behalf within the framework of this contractual relationship to strictly observe these parameters, as well as their unavoidable commitment in this area and the avoidance of offences.<br/><br/>
    To this end, the Supplier undertakes to transmit and/or communicate to TIDI any incidents and/or breaches detected in the area of regulatory compliance, insofar as they affect their commercial relationship, via the e-mail address provided for this purpose: hola@tidisystems.com.<br/><br/>


    <br/><b>13. DEVICES AND SYSTEMS</b><br/><br/>
    In order to use the Platform, the Provider must have an Internet connection and a device with a browser compatible with the Platform. The Provider is responsible for all costs associated with such connection, including those costs arising from data traffic, regardless of where the Provider uses the Platform.<br/><br/>
    The current version of the Platform is available for the following browsers:
        <ul className='ulItem'>
            <li>Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Opera</li>
            <li>Microsoft Bing</li>
        </ul>
    TIDI may discontinue support for any of the browsers at any time at its discretion. When a browser is no longer supported, we will cease to deal with problems that may arise for individual users. Your browser settings must allow the use of cookies and other similar devices to at least allow the insertion of technical and functional cookies. Likewise, you must not have any pop-up blockers.<br/><br/>
    For a good user experience, a connection speed of at least 3MB/s is required. In no event will TIDI be liable for Internet overload or failures due to problems related to users' equipment or networks.<br/><br/>


    <br/><b>14. SAFEGUARD CLAUSE</b><br/><br/>
    All the clauses or terms of these Terms and Conditions for Suppliers must be interpreted independently and autonomously, and the rest of the stipulations shall not be affected in the event that one of them has been declared null and void by a court ruling or final arbitration decision. The clause or clauses affected shall be replaced by another or other clauses that preserve the effects pursued by these Terms and Conditions for Suppliers.<br/><br/>


    <br/><b>15. DATA PROTECTION AND COOKIES</b><br/><br/>
    Registration and access to the Platform requires the Provider to provide certain personal data of contact persons. In addition, certain functionalities of the website depend on the use of Cookies, as informed in the {buttonCookiesPolicy}.<br/><br/>
    Whenever the Supplier provides personal data, the Supplier shall be solely responsible for the truthfulness and accuracy of such data, and shall inform TIDI of any updates to such data as soon as possible.<br/><br/>
    The provision of the service provided by TIDI to the Supplier implies that TIDI must access personal data under the responsibility of the Supplier, the regulation of which, in accordance with the provisions of the applicable regulations, is found in the Annex I.<br/><br/>


    <br/><b>16. GROUNDS FOR TERMINATION, JURISDICTION AND COMPETENCE</b><br/><br/>
    For any litigious matter related to this Platform or any of those on which TIDI depends, and provided that the legislation so permits, the Spanish legislation in force at the time of the litigation shall be applicable, and the Courts and Tribunals of Madrid shall be competent to resolve all disputes arising from or related to the use of this Platform.<br/><br/>
    In any case, in order to file complaints regarding the use of the services offered through the Platform, the Provider may contact us by mail at the following address:      hola@tidisystems.com, undertaking to seek an amicable solution to the conflict at all times.<br/><br/>
    In addition to the causes provided for by law and those expressly contemplated in these Terms and Conditions for Suppliers, the non-fulfilment by the Supplier of the material obligations assumed by virtue of these Terms and Conditions for Suppliers shall be cause for termination.<br/><br/>
    The termination of these Terms and Conditions for Suppliers may entail the deletion of all accounts and profiles associated with the Supplier and related data and information, retaining only that information necessary for TIDI to comply with its legal obligations. <br/><br/>

<div className='AlignToRight'> Last update: September 2021. </div><br/><br/>

    <br/><br/><br/><b>ANNEX I</b><br/><br/>
    <br/><b>CONTRACT FOR THE COMMISSIONING OF PROCESSING </b><br/><br/>
    In accordance with the provisions of Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC and the Federal Act on Data Protection, hereinafter collectively referred to as <b>"Applicable Law"</b>, and without prejudice to the provisions of the Privacy Policy, TIDI (hereinafter, throughout this section, the <b>“Processor”</b> or <b>“Controller”</b>) will have access to the personal data under the responsibility of the Supplier (hereinafter, throughout this section, the <b>“Controller”</b>) for the purpose of providing the services covered by these Supplier Terms and Conditions. For this purpose, the terms and conditions of the Processing Assignment Agreement (hereinafter, the <b>“Agreement”</b>) shall be governed by the following provisions:
        <ol className='ulItem' type="1">
            <li>The purpose of the processing of personal data shall include the provision of services through the Platform, for which it shall be necessary to carry out the following processing of personal data: collection, recording, consultation, storage, dissemination, modification and deletion of personal data.</li>
            <li>Personal data will be processed solely for the purpose of providing the contracted services. If the Data Processor considers it necessary to process the data for a different purpose, it must request prior written authorisation from the Data Controller. In the absence of such authorisation, the Processor may not carry out such processing.</li>
            <li>The types of personal data to be processed by the Processor under this Agreement are as follows:</li>
            <ol className='ulItem' type="a">
                <li>Identification data (name and surname, NIF/DNI, Social Security/Mutuality number, address, telephone, signature, fingerprint, image/voice, physical marks, electronic signature, other biometric data).</li>
                <li>Employment details data (profession, job title, non-financial payroll data, employee history). </li>
            </ol>

            <li>The categories of data subjects whose data will be processed by the Data Processor under this Agreement are as follows:</li>
            <ol className='ulItem' type="a">
                <li>Employees</li>
            </ol>

            <li>In particular, the person in charge undertakes to comply with the following obligations:</li>
            <ol className='ulItem' type="a">
                <li>To process the personal data only to carry out the provision of the contracted services, in accordance with the instructions that, at any given time, the Controller may give in writing (unless there is a law that requires additional processing, in which case, the Data Processor shall inform the Controller of this legal requirement prior to processing, unless such law prohibits it for important reasons of public interest). In the event that the Processor considers that any of the Controller's instructions infringes the applicable data protection regulations, the Processor shall immediately notify the Controller by the means and to the contact person indicated by the Controller.</li>
                <li>Maintain the duty of secrecy with regard to the personal data to which it has access, even after the contractual relationship has ended, as well as to ensure that the persons in its charge have undertaken in writing to maintain the confidentiality of the personal data processed.</li>
                <li>Ensure, taking into account the state of the art, the costs of implementation, and the nature, scope, context and purposes of processing, as well as risks of varying likelihood and severity to the rights and freedoms of natural persons, the implementation of appropriate technical and organisational measures to ensure a level of security appropriate to the risk, including, where appropriate, inter alia:</li>
                <ol className='ulItem' type="i">
                    <li>Pseudonymisation and encryption of personal data;</li>
                    <li>The ability to ensure the continued confidentiality, integrity, availability and resilience of processing systems and services;</li>
                    <li>The ability to restore availability and access to personal data quickly in the event of a physical or technical incident;</li>
                    <li>A process of regular verification, evaluation and assessment of the effectiveness of technical and organisational measures to ensure the security of processing.</li>
                </ol>
                <li>In assessing the adequacy of the level of security, it shall take particular account of the risks presented by the processing of data, in particular as a result of accidental or unlawful destruction, loss or alteration of personal data transmitted, stored or otherwise processed, or unauthorised disclosure of or access to such data.</li>
                <li>To keep under its control and custody the personal data to which it has access as a result of the provision of the service and not to disclose, transfer or otherwise communicate them, not even for their conservation to other persons outside the same and the provision of the service that is the object of this Contract.</li>
                <li>The subcontracting by the Processor of third parties for services involving access to and/or processing, in whole or in part, of personal data (hereinafter, "sub-processors") is authorised, unless the Controller expressly and justifiably objects in writing to the contracting of the same. The Processor is obliged to transfer and communicate to the sub-processor all of the obligations for the Processor arising from this Agreement and, in particular, the provision of sufficient guarantees that it will implement appropriate technical and organisational measures, so that the processing complies with the applicable regulations.</li>
                <li>At the Controller's option, delete or return all personal data to which the Controller has had access in order to provide the service. The Processor is also obliged to delete existing copies, unless there is a legal provision requiring the retention of personal data. However, the Processor shall keep the data properly blocked, as long as liabilities may arise from its relationship with the Controller.</li>
                <li>Notify the Controller, as soon as it becomes aware of the existence of any breach of security resulting in the accidental or unlawful destruction, loss or alteration, loss and alteration, unauthorised disclosure or access, of personal data transmitted, stored or otherwise processed or the unauthorised communication of or access to such data. In addition, the Processor undertakes to support the Controller in the event that it is necessary to notify the competent supervisory authority and, where appropriate, the data subjects of any security breaches that may occur.</li>
                <li>Support the Controller, where necessary, in carrying out privacy impact assessments and in prior consultation with the competent Supervisory Authority, where appropriate.</li>
                <li>Assist the Controller in complying with the obligation to respond to requests for the exercise of rights.</li>
                <li>Keep, in writing, a record of all categories of processing activities carried out on behalf of the Controller.</li>
                <li>Cooperate with the competent supervisory authority, at its request, in the fulfilment of its powers.</li>
                <li>Make available to the Controller all information necessary to demonstrate compliance with the obligations set out in this Agreement and to enable and assist in the performance of audits.</li>
            </ol>
            <li>In case of transfer of personal data to a third country outside the EEA, or an international organisation, the Processor undertakes to cooperate with the Controller to ensure an adequate protection framework under the applicable regulations, by applying binding corporate rules, formalising standard contractual clauses adopted by the European Commission or, where appropriate, obtaining authorisation for the transfer from the competent authority.</li>
            <li>If the SUPPLIER or any of its sub-providers infringes this stipulation or any regulation when determining the purposes and means of the processing, it will be considered responsible for such processing.</li>
            <li>Each of the Parties is hereby informed that the contact details of its representatives and employees processed by virtue of this contract will be processed by the other party, in its capacity as Data Controller, for the purpose of enabling the development, fulfilment and control of the agreed service provision relationship, the basis of the processing being the fulfilment of the contractual relationship and the data being kept for as long as this relationship subsists and even afterwards, until the eventual liabilities deriving from it expire. The Parties' data may be communicated to the Public Administrations for the purposes of complying with their respective legal obligations in accordance with the regulations in force. The Parties may request access to the personal data, its rectification, deletion, portability and limitation of its processing, as well as to oppose its processing, at the address and/or means of contact of TIDI, which appear in the heading of the Terms and Conditions. Finally, data subjects may lodge a complaint with the competent supervisory authority if they consider that their rights have been infringed.</li>
        </ol>

        </span>
    )
}

var generalTermsAndConditionsTitleProvider = <b>GENERAL TERMS AND CONDITIONS</b>

export {generalTermsAndConditionsTextProvider, generalTermsAndConditionsTitleProvider};