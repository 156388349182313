import {nonAuthPostData} from "../apis/rest_api";
import {get_user_location_IPFind} from "./location_API";
import {getUserType, validate_value_is_neither_empty_nor_null_nor_undefined, checkIfDeviceIsAMobile} from "../common/utilities";
import {get_analytics_acceptance_cookie} from "./Permanent_cookies";
// import {get_my_profile} from "./My_profile";

//////////////////////////////////////////// Methods to execute application services ////////////////////////////////////////////////////////////////////////////////////////

//////////////////// Kill the Session_ID: the user has closed the tab ////////////////////////////////////////////////////////////

//Finally deprecated.

//////////////////// Start the Session_ID: the user has opened the tab ////////////////////////////////////////////////////////////

async function wrapper_create_all_cookies_on_opening_tab() {
	// Creates the Received_via cookie, Session_id cookie and IP Address cookie.

	// Get the Received_via cookie. If it's not created, assign value 1 to it for React app connections
	let Received_via = get_Received_via_cookies();

	// if (Received_via === null || Received_via === undefined) {
	// 	set_Received_via_cookie();
	// }


	// Get the IP Address. If it's not populated, get it by the API call
	let IP = get_IP_address_cookie();
	// console.log(IP)

	if (IP === null || IP === undefined) {
		let api_call_data = await get_api_call_data();
		window.localStorage.setItem('IP_Address', api_call_data['ip_address']);
	}


	// Get the Session ID . If it's not populated, create a new session_id on backend
	let Session_ID = get_Session_ID_cookies();

	// console.log(Session_ID);

	if (Session_ID === null || Session_ID === undefined) {
		create_and_retrieve_session_ID();
	}

	return null;
}


function judge_are_geography_cookies_allowed() {

	if (get_analytics_acceptance_cookie() === 'true') {
		get_and_store_in_DB_users_geography();
	} else {
		// For security reasons.
		window.localStorage.removeItem('User_region');
		window.localStorage.removeItem('User_country_code');

		// Store on the DB one row that shows that the user's geography has not been recorded - for audit purposes.
		wrapper_send_session_cookie(null, false, false, false);
	}

	return null;

}


async function get_and_store_in_DB_users_geography() {

	// Get the Geography of the user. If it's not populated, get it by the API call
	let region = get_cookie_region_value();
	let User_country_code = get_cookie_Country_code_value

	if (region === null || region === undefined || User_country_code === null || User_country_code === undefined) {
		let api_call_data = await get_api_call_data();
		console.log(api_call_data);

		// Store the cookies on localStorage
		window.localStorage.setItem('User_region', api_call_data['region']);
		window.localStorage.setItem('User_country_code', api_call_data['country_code']);

	}

	// Store the cookie either in case the cookies already existed previously or not.
	wrapper_send_session_cookie(null, false, false, false);

	return null;
}




//////////////////// Base wrapper for creating the Session_ID, killing it or writing custom cookies to the DB ////////////////////


async function wrapper_send_session_cookie(Object_ID, Request_Session_ID, Kill_Session_ID, mock_up) {

	var Session_ID = get_Session_ID_cookies();
	var IP = get_IP_address_cookie();
	var User_ID = get_User_ID_session_cookies();
	var User_type = getUserType();
	var Location = get_location_cookie();
	// var HTML_file = get_File_name_file();
	var HTML_file = null;

	var Object_data = null;

	if (mock_up === true) {
		Object_data = mock_up_object_type_Object_ID_and_object_action(Object_ID);
	}
	else {
		Object_data = get_object_type_Object_ID_and_object_action(Object_ID);
	}

	var TimeStamp = get_current_datetime();

	var request_json = create_session_cookies_json(Session_ID, IP, User_ID, User_type, Location, HTML_file, Object_data, TimeStamp, Request_Session_ID, Kill_Session_ID);

	// console.log(request_json);

	let response = await nonAuthPostData(request_json);

	// console.log(response);

	return response;

}



function create_session_cookies_json(Session_ID, IP, User_ID, User_type, Location, HTML_file, Object_data, TimeStamp, Request_Session_ID, Kill_Session_ID) {

	var ID = 101;
	// console.log(Location);

	if (User_type === undefined) {
		User_type = null;}
	if (User_ID === undefined) {
		User_ID = null;}

	var objeto_json_cookie = {
		"Session_ID": Session_ID,
		"IP_Address": IP,
		"Region": Location[0],
		"Country_code": Location[1],
		"HTML_file": HTML_file,
		"Object_type": Object_data[2],
		"Object_ID": Object_data[0],
		"Action": Object_data[1],
		"TimeStamp": TimeStamp
	};

	var objeto_json_contenido = {
		"Session_cookie": objeto_json_cookie,
		"Request_Session_ID": Request_Session_ID,
		"Kill_Session_ID": Kill_Session_ID,
		"User_ID": User_ID,
		"User_type": User_type
	};
	var json_object = {
		"ID": ID,
		"contenido": objeto_json_contenido,
		'IP_Address': localStorage.getItem('IP_Address'),
		"Received_via": window.localStorage.getItem('Received_via')
	};

	return(json_object);

}


//////////////////////////////////////////// Methods to set cookie values on localStorage ////////////////////////////////////////////////////////////////////////////////////////

function create_and_retrieve_session_ID() {

	let Session_ID = function () {
		return wrapper_send_session_cookie(null, true, false, true).then(json => {
			return json
		});
	}

	let session_id = Session_ID();

	session_id.then((response) => {
			console.log(response);
			// // Set the session_ID
			set_Session_ID(response['contenido']['Session_cookies_output']['Session_ID']);
		}
	)

	return null;
}

async function get_api_call_data() {

	let ip_address_getter = function () {
		return get_user_location_IPFind().then(json => {
			return json
		});
	}

	let ipAddressGetter = ip_address_getter();

	let test = ipAddressGetter.then((response) => {
		// console.log(response);

		return response;
	})

	// let lol = await test;


	return await test;

}



// function set_page_name_cookie() {
// 	var path = window.location.pathname;
// 	var page = path.split("/").pop();
//
// 	window.localStorage.setItem('HTML_file', page);
// }
//
// function set_object_type_Object_ID_and_object_action_cookie(Object_ID) {
//
// 	window.localStorage.setItem('Object_ID', Object_ID.attributes[3].value);
// 	window.localStorage.setItem('Action', Object_ID.attributes[1].value);
// 	window.localStorage.setItem('Object_type', Object_ID.attributes[0].value);
//
// }


function set_Session_ID(Session_ID) {

	console.log(Session_ID);

	if (checkIfDeviceIsAMobile()) {

		localStorage.setItem('Session_ID', Session_ID );

	} else {

		var now = new Date();
		var time = now.getTime();
		let two_years = 2 * 365 * 24 * 3600 * 1000; // This is in milliseconds
		var expireTime = time + two_years;
		now.setTime(expireTime);
		document.cookie = 'Session_ID=' + Session_ID + ';';

	}
	return null;
}




//////////////////////////////////////////// Methods to get cookies' values from localStorage ////////////////////////////////////////////////////////////////////////////////////////


function get_IP_address_cookie() {
	// window.localStorage.setItem('IP_Address', '178.197.227.88');
	return window.localStorage.getItem('IP_Address');
}


function get_location_cookie() {
	return [ window.localStorage.getItem('User_region'), window.localStorage.getItem('User_country_code') ];
}


//
// function get_file_name_cookie() {
// 	return window.localStorage.getItem('HTML_file');
// }
//
//
// function get_object_type_Object_ID_and_object_action_cookie(Object_ID) {
// 	return [window.localStorage.getItem('Object_ID'), window.localStorage.getItem('Action'), window.localStorage.getItem('Object_type')];
// }


function get_User_ID_session_cookies() {
	return window.localStorage.getItem('User_ID');
}

function get_Session_ID_cookies() {
	// Retrieves the SessionID cookie value.

	if (checkIfDeviceIsAMobile()) {
		return localStorage.getItem('Session_ID');
	} else {
		// console.log(getCookie('Session_ID'));
		let session_ID_value = getCookie('Session_ID');
		if (session_ID_value === null) {
			return null;
		}

		var match = document.cookie.match(new RegExp('(^| )' + 'Session_ID' + '=([^;]+)'));

		return match[2];
	}
}

function getCookie(name) {
	var dc = document.cookie;
	var prefix = name + "=";
	var begin = dc.indexOf("; " + prefix);
	if (begin == -1) {
		begin = dc.indexOf(prefix);
		if (begin != 0) return null;
	}
	else
	{
		begin += 2;
		var end = document.cookie.indexOf(";", begin);
		if (end == -1) {
			end = dc.length;
		}
	}
	// because unescape has been deprecated, replaced with decodeURI
	//return unescape(dc.substring(begin + prefix.length, end));
	return decodeURI(dc.substring(begin + prefix.length, end));
}

function get_Received_via_cookies() {
	return window.localStorage.getItem('Received_via');
}

// function set_Received_via_cookie() {
// 	// TODO: Analizar las formas de saber si las peticiones son del front-end, de pantalla móvil,...
// 	window.localStorage.setItem('Received_via', 1);
//
// 	return null;
// }

function check_all_API_cookies_are_set() {

	let cookie1 = get_IP_address_cookie();
	let cookie3 = get_cookie_region_value();
	let cookie4 = get_cookie_Country_code_value();
	let cookie5 = get_cookie_IP_Address_value();


	let check1 = validate_value_is_neither_empty_nor_null_nor_undefined(cookie1);
	let check3 = validate_value_is_neither_empty_nor_null_nor_undefined(cookie3);
	let check4 = validate_value_is_neither_empty_nor_null_nor_undefined(cookie4);
	let check5 = validate_value_is_neither_empty_nor_null_nor_undefined(cookie5);


	return check1 && check3 && check4 && check5;

}






//////////////////////////////////////////// Methods to get cookies' values directly ////////////////////////////////////////////////////////////////////////////////////////

function get_File_name_file() {
	var path = window.location.pathname;
	var page = path.split("/").pop();

	return page;
}

function get_object_type_Object_ID_and_object_action(Object_ID) {

	if (Object_ID != null) {
		return [Object_ID.attributes[3].value, Object_ID.attributes[1].value, Object_ID.attributes[0].value];
	} else {
		return [null, null, null];
	}
}

function mock_up_object_type_Object_ID_and_object_action(Object_ID) {

	if (Object_ID !== null) {
		return [Object_ID[0], Object_ID[1], Object_ID[2]];
	} else {
		return [null, null, null];
	}
}

function get_cookie_region_value() {

	return window.localStorage.getItem('User_region');
}

function get_cookie_Country_code_value() {

	return window.localStorage.getItem('User_country_code');
}

function get_cookie_IP_Address_value() {

	return window.localStorage.getItem('IP_Address');
}






//////////////////////////////////////////// Methods to remove cookies' values directly ////////////////////////////////////////////////////////////////////////////////////////

// function delete_Session_ID() {
// 	localStorage.removeItem('Session_ID');
// }

//////////////////Helpers
function get_current_datetime() {

	var currentdate = new Date();
	var timestamp = currentdate.getDate() + "/"
		+ (currentdate.getMonth()+1)  + "/"
		+ currentdate.getFullYear() + " "
		+ currentdate.getHours() + ":"
		+ currentdate.getMinutes() + ":"
		+ currentdate.getSeconds();

	return timestamp;
}


export {get_cookie_region_value, get_cookie_Country_code_value, get_cookie_IP_Address_value,
	check_all_API_cookies_are_set, create_and_retrieve_session_ID,
	get_and_store_in_DB_users_geography, judge_are_geography_cookies_allowed, wrapper_send_session_cookie,
	wrapper_create_all_cookies_on_opening_tab, get_Session_ID_cookies};