import {postData} from "../apis/rest_api"
import {get_Session_ID_cookies} from "./Session_cookies";
import {getIPAddress, getReceivedVia, getUserID, getUserType, isUserAnIndividual} from "../common/utilities";

export async function updateClientInOperation(Operation_id, ID_Provider_Operation, Client_email, Wish_for_update) {

    var ID = 8;

    // Client_ID is known only if the user is the client himself.
    let client_id = null
    if (isUserAnIndividual()) {client_id = getUserID()}

    let client_and_data_operation = {
        "Client_ID": client_id,
        "Operation_id": Operation_id,
        "Client_email": Client_email,
        "ID_Provider_Operation": ID_Provider_Operation,
        "Wish_for_update": Wish_for_update
    }

    var objeto_json = {
        "ID": ID,
        "contenido": {
            "Update_client_in_operation_input": client_and_data_operation,
            "User_type": getUserType(),
            "User_ID": getUserID()
        },
        'Session_ID': get_Session_ID_cookies(),
        'IP_Address': getIPAddress(),
        "Received_via": getReceivedVia()
    };

    console.log(objeto_json);

    let response_Update_client_in_operation = await postData(objeto_json);

    //console.log(response_Retrieve_information_from_ID);

    return response_Update_client_in_operation;

}


